import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { isBlank, isPresent } from '@ember/utils';
import { service } from '@ember/service';
import _intersection from 'lodash/intersection';
import { tracked } from '@glimmer/tracking';
import { cached, localCopy } from 'tracked-toolbox';
import { filterOptions } from 'garaje/utils/decorators/filter-options';
import { RoleId } from 'garaje/utils/custom-roles';

export const NAME_FOR_FIELD_TYPE = {
  location: 'nameWithCompanyName',
  zone: 'name',
};

/**
 * List of selectable locations or Zones (`Checkboxes`) with search capabilities.
 *
 * @todo add grouping support that isn't strictly tied to locations
 *
 * @param {string}                    currentRoleName     Name of the selected role (role to be created)
 * @param {Role}                      currentRole         Selected role object
 * @param {Array<Group>}              groups              List of available groups
 * @param {Array<Location | Zone>}    items               List of items to be rendered
 * @param {Array<string>}             type                type of entity attached to user role
 * @param {Function}                  onToggle            Function triggered when a row is clicked
 * @param {Array<Location | Zone>}    selected            List of already selected items
 * @param {Array<Role>}               userRoles           List of existing roles for the selected user
 * @param {Array<RoleAssignment}      roleAssignments     List of existing roles for the selected user including custom roles
 */
export default class AdminRolesMultiItemsListComponent extends Component {
  @service authz;

  /**
   * @type {Array<object>}
   */
  @localCopy('args.selected', []) selected;
  /**
   * @type {Array<Role>}
   */
  @localCopy('args.userRoles', []) userRoles;

  @tracked searchFilter = '';

  /**
   * This CP filters the `this.options` using the `this.searchFilter`
   *
   * @returns {Array<Location | Zone>}
   */
  @cached
  @filterOptions()
  filteredOptions;

  /**
   * This CP builds an array of `options` with a structure easier to loop through.
   *
   * @returns {Array<{ isParent: boolean, item: Location | Zone, group: Group, state: string, name: string }>}
   */
  @cached
  get options() {
    const options = [];
    const { items, type } = this.args;

    // All the items that do not belong to a group
    const itemsWithoutGroup = items.filter((item) => !item.group || isBlank(this.args.groups));
    itemsWithoutGroup.forEach((item) =>
      options.push(this._generateOption({ item, nameField: NAME_FOR_FIELD_TYPE[type], type }))
    );

    if (isPresent(this.args.groups)) {
      // All the available groups with their states
      this.args.groups.forEach((group) => {
        if (group.totalLocations > 0) {
          const option = { isParent: true, name: group.name, group, state: this._groupState(group) };

          option.children = group.locations
            .filter((groupItem) => items.includes(groupItem))
            .map((groupItem) => this._generateOption({ item: groupItem, nameField: NAME_FOR_FIELD_TYPE[type], type }));
          if (option.children.length > 0) {
            options.push(option);
          }
        }
      });
    }
    options.sort((op1, op2) => op1.name - op2.name);
    return options;
  }

  /**
   * Handles the `group` selected action. If the `group` is in an `indeterminate` state, we only send the already
   * selected locations as we want to clear the group. Otherwise we send all locations for that group.
   *
   * @param {object}  optionGroup
   */
  @action
  onGroupSelected(optionGroup) {
    const { state, children } = optionGroup;
    let toSelect = children;
    if (state === 'indeterminate') {
      toSelect = children.filter(({ item }) => {
        return this.selected.findIndex((selected) => selected.id === item.id) > -1;
      });
    }
    toSelect.forEach((option) => {
      if (!option.roleName) {
        this.args.onToggle(option.item);
      }
    });
  }

  /**
   * An option has an `Item`, a `name`  and a `roleName` if the `user` already has a role
   * for that location or zone and it's different from the current selected role.
   *
   * @returns {object}          `{ item <Location | Zone>, roleName <String>, name <String> }`
   */
  _generateOption(options) {
    const { item } = options;
    const nameField = options.nameField ?? 'name';

    const option = { name: item[nameField], item, roleName: null, isParent: false };

    const { currentRole, roleAssignments } = this.args;
    const role = (roleAssignments || []).find(
      (roleAssignment) =>
        roleAssignment.roleScopeType === currentRole.roleScope &&
        roleAssignment.roleScopeId.toString() === item.id &&
        roleAssignment.roleId !== currentRole.id &&
        roleAssignment.roleId !== RoleId.EMPLOYEE
    );

    if (role) {
      const authzRole = this.authz.roles.find((authzRole) => authzRole.id === role.roleId);
      option.roleName = authzRole.name;
    }

    return option;
  }

  /**
   * Determines the state of a given `group` based on the `selected`
   *
   * @param {Group}            group
   * @returns {string | null}
   *                           `checked`       if all the locations of this group are selected
   *                           `indeterminate` if at least one location is selected
   */
  _groupState(group) {
    const groupLocationIds = get(group, 'locations').map((location) => location.id);
    const selectedLocationIds = this.selected.map((selected) => selected.id);
    const totalIntersected = _intersection(groupLocationIds, selectedLocationIds).length;
    // If the difference is empty it means all locations are selected
    if (totalIntersected === group.totalLocations) {
      return 'checked';
    } else if (totalIntersected > 0) {
      return 'indeterminate';
    }
  }
}
