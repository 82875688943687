import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { TrackedArray } from 'tracked-built-ins';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { dropTask } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { filterBy, sortBy, notEmpty } from 'macro-decorators';

/**
 * @param {Array}                      employeeLocations            List of locations an employee belongs to
 * @param {Array}                      locations                    List of company locations
 * @param {Model<Employee>}            employee                     Current employee
 * @param {Task}                       deleteEmployeeLocationsTask
 * @param {Boolean}                    canEdit                      Has permissions to edit employee
 */
export default class LocationsList extends Component {
  @service flashMessages;
  @service store;

  @sortBy('filteredEmployeeLocations', 'manuallyAdded') sortedEmployeeLocations;
  @filterBy('args.employeeLocations', 'isNew', false) filteredEmployeeLocations;
  @notEmpty('selectedLocations') anySelected;

  @tracked selectedLocations = new TrackedArray();
  @tracked showModal;

  get availableLocations() {
    const employeeLocationIds = this.filteredEmployeeLocations.map((employeeLocation) =>
      get(employeeLocation, 'location.id')
    );

    const employeeLocations = [];
    this.args.locations.map((location) => {
      // only show locations that employee doesn't belong to yet
      if (!employeeLocationIds.includes(get(location, 'id'))) {
        // only inactive locations should be disabled
        const disabled = !isEmpty(location.disabledToEmployeesAt);
        let nameWithCompanyName = location.nameWithCompanyName;
        if (disabled) {
          nameWithCompanyName += ' (Disabled)';
        }
        employeeLocations.push({
          location,
          disabled,
          nameWithCompanyName,
        });
      }
    });

    return employeeLocations;
  }

  get anyManuallyAdded() {
    return this.filteredEmployeeLocations.isAny('manuallyAdded');
  }

  @action
  async deleteEmployeeLocations() {
    await this.args.deleteEmployeeLocationsTask.perform(this.selectedLocations, () => this.resetSelectedLocations());
    this.showModal = false;
  }

  @action
  clearAll() {
    this.selectedLocations = new TrackedArray();
  }

  @action
  toggleLocation(employeeLocation) {
    const selectedLocations = this.selectedLocations;
    if (selectedLocations.includes(employeeLocation)) {
      selectedLocations.splice(selectedLocations.indexOf(employeeLocation), 1);
    } else {
      selectedLocations.push(employeeLocation);
    }
  }

  addToLocationTask = dropTask(async ({ location }) => {
    const newEmployeeLocation = this.store.createRecord('employee-location', {
      location,
      employee: this.args.employee,
      manuallyAdded: true,
    });

    try {
      await newEmployeeLocation.save();
      this.flashMessages.showAndHideFlash('success', 'Employee added');
    } catch (error) {
      const errorText = parseErrorForDisplay(error);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  resetSelectedLocations() {
    this.selectedLocations = new TrackedArray();
  }
}
