import Controller from '@ember/controller';
import { action, get } from '@ember/object';
import updateAndSaveTask from 'garaje/utils/decorators/update-and-save-task';

@updateAndSaveTask
class LocationOverviewSignInFlowsShowBadgeController extends Controller {
  @action
  save(badge, e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    return this.updateAndSaveTask.perform(badge);
  }

  rollback() {
    get(this.model, 'flow.badge').rollbackAttributes();
  }

  @action
  _redirectToEnvoy(path) {
    this.send('redirectToEnvoy', path);
  }
}

export default LocationOverviewSignInFlowsShowBadgeController;
