import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';
import { hasCorrectPlanForGlobalViews } from 'garaje/utils/global-abilities';
import { GLOBAL_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_VISIT_ROLES = [GLOBAL_ADMIN];

export default class GlobalAdminAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;

  get canVisit(): boolean {
    const { vrSubscription, workplaceSubscription, vfdSubscription } = this.state;
    const isOnCorrectPlan = hasCorrectPlanForGlobalViews({ vrSubscription, workplaceSubscription, vfdSubscription });
    const hasCorrectRoles = isPresent(_intersection(CAN_VISIT_ROLES, this.currentAdmin.roleNames));
    return hasCorrectRoles && isOnCorrectPlan;
  }
}
