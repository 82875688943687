/* eslint-disable no-console */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';

export default class PlansCostEstimateModal extends Component {
  @service store;

  @tracked closed = false;
  @tracked content = this.args.content;

  @tracked nextRenewalTimeUnit = null;
  @tracked nextRenewalTimeAmount = null;
  @tracked nextRenewalDateStr = moment(this.args.planEstimates.nextBillingAt).format('MMM DD, YYYY');

  @tracked allCurrentPlans = this.args.planEstimates.currentInvoiceEstimate.lineItems;
  @tracked allCurrentDiscounts = this.args.planEstimates.currentInvoiceEstimate.discounts;

  @tracked allNextPlans = this.args.planEstimates.nextInvoiceEstimate?.lineItems;
  @tracked allNextDiscounts = this.args.planEstimates.nextInvoiceEstimate?.discounts;

  @tracked currentPlanPeriod = null;

  constructor() {
    super(...arguments);
    this.calculateNextRenewalTime();
    this.updateCurrentPlanPeriod();
  }

  updateCurrentPlanPeriod() {
    switch (this.args.planEstimates.currentInvoiceEstimate.period) {
      case 'monthly':
        this.currentPlanPeriod = 'month';
        break;
      case 'yearly':
        this.currentPlanPeriod = 'year';
        break;
      case 'quarterly':
        this.currentPlanPeriod = 'quarter';
        break;
      default:
        this.currentPlanPeriod = null;
    }
  }

  calculateNextRenewalTime = () => {
    const nextBillingAt = moment(this.args.planEstimates.nextBillingAt);
    let nextRenewalTimeAmount = nextBillingAt.diff(moment(), 'months');
    if (nextRenewalTimeAmount < 1) {
      nextRenewalTimeAmount = nextBillingAt.diff(moment(), 'days');
      this.nextRenewalTimeUnit = nextRenewalTimeAmount === 1 ? 'Day' : 'Days';
    } else {
      this.nextRenewalTimeUnit = nextRenewalTimeAmount === 1 ? 'Month' : 'Months';
    }
    this.nextRenewalTimeAmount = nextRenewalTimeAmount;
  };
}
