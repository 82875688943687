import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { service } from '@ember/service';
import RSVP from 'rsvp';

const SORT_COL_TO_FIELD_NAME = {
  Device: 'model',
  Name: 'name',
  Mode: 'connection_type',
  'Printer IP address': 'ip',
  Location: 'device_config.location.name',
};

const ORDER_DESC_VALS = ['Z-A'];
const DEFAULT_PAGE_SIZE = 30;

export default class LocationOverviewGlobalDevicesPrintersRoute extends Route {
  @service abilities;
  @service router;
  @service store;

  queryParams = {
    sortBy: {
      refreshModel: true,
    },
    sortDirection: {
      refreshModel: true,
    },
    pageNumber: {
      refreshModel: true,
    },
    locationId: {
      refreshModel: true,
    },
    printerMode: {
      refreshModel: true,
    },
    printerStatus: {
      refreshModel: true,
    },
    printerModel: {
      refreshModel: true,
    },
  };

  beforeModel() {
    if (this.abilities.cannot('view-nav-item global-printers')) {
      this.router.transitionTo('unauthorized');
    }
  }

  model({ sortBy, sortDirection, pageNumber, locationId, printerMode, printerStatus, printerModel }) {
    const params = {};
    const pageSize = DEFAULT_PAGE_SIZE;
    const orderDescending = ORDER_DESC_VALS.includes(sortDirection);
    const sortOrder = orderDescending ? '-' : '';
    const sortProp = SORT_COL_TO_FIELD_NAME[sortBy];
    const offset = pageSize * pageNumber - pageSize;

    if (sortBy) {
      params.sort = `${sortOrder}${sortProp}`;
    }

    params.filter = {};

    if (locationId) {
      params.filter.location = locationId;
    }

    if (printerMode && printerMode !== '') {
      params.filter.connectionType = printerMode;
    }
    if (printerStatus && printerStatus !== '') {
      params.filter.status = printerStatus;
    }
    if (printerModel && printerModel !== '') {
      params.filter.model = printerModel;
    }

    params.page = {
      offset,
      limit: pageSize,
    };

    params.include = 'location.devices';

    const printers = this.store.query('printer', params);

    return RSVP.hash({
      printers,
      params,
    });
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
