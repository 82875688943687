import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { alias } from 'macro-decorators';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { isPresent } from '@ember/utils';
import { subDays, isWithinInterval } from 'date-fns';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';

export default class WorkplaceTrialEndModal extends Component {
  @service abilities;
  @service metrics;
  @service state;
  @service impressions;
  @service subscriptions;

  @tracked closed = false;
  @tracked hasClosedImpression = false;

  @alias('state.workplaceSubscription') subscription;

  constructor() {
    super(...arguments);
    this.fetchDataToConditionallyRenderTask.perform();
  }

  get isBillingAdmin() {
    return this.abilities.can('visit billing');
  }

  get canDismiss() {
    // users who can't visit billing can dismiss this, since they can't take any action.
    if (!this.isBillingAdmin) return true;

    return !this.subscription.onExpiredTrial;
  }

  get contentComponent() {
    if (!this.isBillingAdmin) {
      return 'workplace/trial-end-modal/non-billing-admin';
    }

    return `workplace/trial-end-modal/billing-admin`;
  }

  get ctaEventProperties() {
    return {
      cta_id: this.ctaId || 'growth-workplace-trial-end-interstitial',
      cta_type: 'modal',
      growth_team_project: true,
    };
  }

  get ctaId() {
    if (!this.isBillingAdmin) {
      return 'growth-workplace-trial-end-interstitial_non-billing-admin';
    }

    return `growth-workplace-trial-end-interstitial`;
  }

  get modalSizeClass() {
    if (!this.isBillingAdmin) {
      return '!max-w-xl';
    }

    return '!max-w-2xl';
  }

  get shouldDisplay() {
    // don't display modal if the user is not authorized
    if (this.abilities.cannot('view trial-end-modal')) return false;

    if (!this.subscription?.onTrial || this.args.realSubscription) return false;

    const doesTrialExpireWithinThreeDays = isWithinInterval(new Date(), {
      start: subDays(this.subscription.trialEndDate, 3),
      end: this.subscription.trialEndDate,
    });

    return doesTrialExpireWithinThreeDays;
  }

  get showModal() {
    return (
      this.shouldDisplay &&
      this.fetchDataToConditionallyRenderTask.lastSuccessful &&
      !this.hasClosedImpression &&
      !this.closed
    );
  }

  @action
  dismiss() {
    this.metrics.trackEvent('CTA Dismissed', this.ctaEventProperties);

    // only log billing admin dismiss the modal on the first step
    if (this.isBillingAdmin) {
      this.logToGrowthServiceTask.perform('closed');
    }

    this.close();
  }

  @action
  close() {
    this.closed = true;
  }

  @action
  logCTAClicked(event) {
    const buttonText = event.target.textContent.trim();
    this.metrics.trackEvent('CTA Clicked', {
      ...this.ctaEventProperties,
      cta_clickable_type: 'button',
      cta_clickable_text: buttonText,
    });

    return event;
  }

  @action
  logCTAViewed() {
    this.metrics.trackEvent('CTA Viewed', this.ctaEventProperties);
  }

  logToGrowthServiceTask = task({ drop: true }, async (action) => {
    await this.impressions.postImpression.perform(IMPRESSION_NAMES.WORKPLACE_TRIAL_END_MODAL[action.toUpperCase()]);
  });

  fetchDataToConditionallyRenderTask = task({ drop: true }, async () => {
    if (!this.shouldDisplay || this.subscription?.onExpiredTrial) return;

    const closedImpressions = await this.impressions.getImpressions.perform(
      IMPRESSION_NAMES.WORKPLACE_TRIAL_END_MODAL['CLOSED']
    );

    this.hasClosedImpression = isPresent(closedImpressions);
  });
}
