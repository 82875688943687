import Route from '@ember/routing/route';
import { service } from '@ember/service';

class PreRegistrationUpgradeRoute extends Route {
  @service router;
  @service state;

  titleToken = 'Pre-Registration Upgrade';

  beforeModel() {
    const { currentLocation } = this.state;
    if (currentLocation.preRegistrationEnabled) {
      return this.router.transitionTo('visitors.settings.invites');
    }
  }

  model() {
    const { currentLocation } = this.state;
    return currentLocation;
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller._buildChangeset(model);
  }
}

export default PreRegistrationUpgradeRoute;
