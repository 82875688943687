import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import EmberObject, { action, set, setProperties, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { scheduleOnce } from '@ember/runloop';
import { dropTask } from 'ember-concurrency';
import { isNone } from '@ember/utils';
import $ from 'jquery';

import urlBuilder from 'garaje/utils/url-builder';
import { loadSrcDoc } from 'garaje/utils/script-loader';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import NotificationMessage from 'garaje/models/notification-message';

import { and } from 'macro-decorators';

const FEATURE_NAME = 'custom-notifications';

/**
 * Creates notifications objects used do define notification types for the UI
 *
 * @returns {Array} array of notification objects
 */
function createCustomTextNotifications() {
  return [
    { name: 'hostMessage', description: 'Host notification' },
    { name: 'delegatedMessage', description: 'Assistant notification' },
    { name: 'message', description: 'Fallback notification: sent when a visitor does not select a host' },
  ];
}

/**
 * Creates notifications group objects used do define notification groups for the UI
 *
 * @returns {Array} array of notification group objects
 */
function createMessageGroups() {
  return [
    {
      type: 'individual',
      data: [],
      description: 'Host notification for single visitor sign-in',
      variables: ['%{visitor_name}', '%{host_name}', '%{company_name}', '%{location_name}'],
    },
    {
      type: 'bulk',
      data: [],
      description: 'Host notification for group sign-in',
      variables: ['%{visitor_name}', '%{host_name}', '%{company_name}', '%{location_name}', '%{number_of_visitors}'],
    },
  ];
}

/**
 * @param {Class<Location>}               model
 * @param {Class<Subscription>}           vrSubscription
 * @param {String}                        feature
 * @param {Function}                      closeFeature
 * @param {Function}                      openFeature
 */
export default class CustomText extends Component {
  @service flashMessages;
  @service currentLocation;
  @service ajax;
  @service abilities;

  @tracked isEditingCustomNotification = true;
  @tracked previewEmailObject = {};
  @tracked emailPreviewTemplate = null;
  @tracked previewFor = 'individual';
  @tracked previewNotificationType = 'hostMessage';
  @tracked activeTab = 'Push';
  @tracked showUnsavedEditsWarning = false;
  @tracked isOpen;

  @and('isOpen', 'isCustomNotificationSubscriber') isSettingsPanelOpen;

  constructor() {
    super(...arguments);

    this.isOpen = this.args.feature ? this.args.feature === FEATURE_NAME : false;
  }

  get isCustomNotificationSubscriber() {
    const { vrSubscription } = this.args;

    // Panel cannot be opened if subscription not sufficient
    if (!vrSubscription) return false;
    if (!vrSubscription.canAccessCustomNotifications) return false;
    if (this.abilities.cannot('customize sms for external-notifications')) return false;

    return true;
  }

  get isAssistantNotificationPreview() {
    return this.previewNotificationType === 'delegatedMessage';
  }

  get hasDirtyAttributes() {
    return 'customNotifications' in this.args.model.changedAttributes();
  }

  get messages() {
    const customNotifications = this.args.model.customNotifications || [];
    const defaultNotifications = this.args.model.defaultNotifications || [];

    return createMessageGroups().map((messageGroup) => {
      const { type } = messageGroup;
      const defaultNotification = defaultNotifications.findBy('for', type);
      const customNotification = customNotifications.findBy('for', type);

      messageGroup.data = createCustomTextNotifications().map((base) => {
        const notification = {
          ...base,
          for: type,
          template: defaultNotification ? get(defaultNotification, base.name) : '',
          default: defaultNotification ? get(defaultNotification, base.name) : '',
          optionName: base.description.split(':')[0],
          variables: messageGroup.variables.slice(0),
        };

        if (type === 'bulk') {
          notification.optionName = `Group ${notification.optionName.toLowerCase()}`;
        }

        if (base.name === 'message') {
          notification.variables.splice(notification.variables.indexOf('%{host_name}'), 1);
        }

        // NOTE message could be '' which is falsey
        if (customNotification && !isNone(get(customNotification, base.name))) {
          notification.template = get(customNotification, base.name);
          notification.custom = true;
        }

        const placeholders = this.variablePlaceholders;

        notification.preview = notification.template
          .replace(/%{visitor_name}/g, placeholders.visitorName)
          .replace(/%{host_name}/g, placeholders.hostName)
          .replace(/%{carrier}/g, placeholders.carrier)
          .replace(/%{company_name}/g, placeholders.companyName)
          .replace(/%{location_name}/g, placeholders.modelName)
          .replace(/%{number_of_visitors}/g, placeholders.numberOfVisitors);

        return EmberObject.create(notification);
      });
      return messageGroup;
    });
  }

  get variablePlaceholders() {
    return {
      visitorName: 'Sophia Fitzroy',
      visitorEmail: 'sophia@email.com',
      assistantName: 'Harry Potter',
      assistantEmail: 'harry@hogwarts.com',
      hostName: get(this.args.model, 'locationAdmin.fullName') ?? 'Alexa Beckfield',
      hostEmail: get(this.args.model, 'locationAdmin.email') ?? 'alexa@email.com',
      companyName: get(this.args.model, 'company.name'),
      modelName: get(this.args.model, 'name'),
      numberOfVisitors: 7,
    };
  }

  get currentPreviewMessage() {
    const { previewFor, previewNotificationType, messages } = this;
    const messageGroup = messages.findBy('type', previewFor);
    const { data } = messageGroup;
    if (data && data.length) {
      const previewMessage = data.findBy('name', previewNotificationType);
      return previewMessage.preview;
    }
    return undefined;
  }

  renderAllNotificationTextPreviews() {
    return this.messages;
  }

  updateCustomNotification(message = {}, attrs) {
    const { model } = this.args;
    const shouldUpdateMessage = Object.keys(attrs).reduce((acc, key) => acc || attrs[key] !== message[key], false);
    if (shouldUpdateMessage) {
      const updatedMessage = Object.freeze(new NotificationMessage({ ...message, ...attrs }));
      const customNotifications = [...(model.customNotifications || [])];
      if (customNotifications.includes(message)) {
        customNotifications.splice(customNotifications.indexOf(message), 1, updatedMessage);
      } else {
        customNotifications.push(updatedMessage);
      }
      set(this.args.model, 'customNotifications', Object.freeze(customNotifications));
    }
  }

  @action
  customNotificationTextUpdated(notification, template) {
    const props = { ...notification, template, custom: notification.default !== template };
    const customMessage = this.args.model.customNotifications.findBy('for', props.for);
    this.updateCustomNotification(customMessage, {
      [props.name]: props.custom ? template : null,
      for: props.for,
    });
    setProperties(notification, props);
  }

  @dropTask
  *saveCustomNotificationsTask() {
    try {
      yield this.args.model.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @action
  setCustomNotificationPreviewActive() {
    this.fetchEmailPreviewTemplate();
    this.renderAllNotificationTextPreviews();
    this.previewFor = 'individual';
    this.previewNotificationType = 'hostMessage';
    this.isEditingCustomNotification = false;
  }

  loadSrcDoc() {
    return loadSrcDoc().then((didLoad) => {
      if (didLoad) {
        const iframe = document.getElementsByClassName('notificationPreviewIframe')[0];
        if (iframe) {
          set(window.srcDoc, iframe, this.previewEmailObject.body);
        }
      }
    });
  }

  fetchEmailPreviewTemplate() {
    if (this.emailPreviewTemplate) {
      return;
    }
    this.ajax
      .request(urlBuilder.v2.emailNotificationPreviewUrl(this.args.model.id), {
        type: 'GET',
      })
      .then((response) => {
        const data = response.data.attributes;
        const $srcdoc = $('<div>').html($.parseHTML(data.body));
        const fieldCssSelector = 'table table table table table td[align="center"] table';

        const $fieldTemplate = $srcdoc.find(fieldCssSelector).first();
        $fieldTemplate.find('.text-name').text('{{field_value}}');
        $fieldTemplate.find('.text-label').text('{{field_name}}');
        data.field = $fieldTemplate.html() || '';

        $srcdoc.find('.notification-message-text').text('{{preview_message}}');
        $srcdoc.find(fieldCssSelector).first().addClass('email-fields').empty();
        data.body = $srcdoc.html();

        this.emailPreviewTemplate = data;
      });
  }

  @action
  onContinue() {
    if (this.hasDirtyAttributes) {
      this.args.model.rollbackAttributes();
    }
    this.showUnsavedEditsWarning = false;
    this.changeIsOpen(false);
  }

  @action
  cancel() {
    if (!this.hasDirtyAttributes) {
      this.onContinue();
    } else {
      this.showUnsavedEditsWarning = true;
    }
  }

  // When the user changes the notification type in the preview dropdown
  @action
  changePreviewText(typeVal) {
    const [type, notificationType] = typeVal.split('-');
    this.previewFor = type;
    this.previewNotificationType = notificationType;
  }

  @action
  changeIsOpen(isOpen) {
    if (isOpen && this.args.openFeature) {
      this.args.openFeature(FEATURE_NAME);
    } else if (!isOpen && this.hasDirtyAttributes) {
      this.showUnsavedEditsWarning = true;
    }
    this.isOpen = isOpen;
  }

  @action
  handleEmailPreviewDidChange() {
    const previewTemplate = this.emailPreviewTemplate;
    if (!previewTemplate) {
      return;
    }
    const fields = [
      { name: 'Your Full Name', value: this.variablePlaceholders.visitorName },
      { name: 'Your Email Address', value: this.variablePlaceholders.visitorEmail },
      { name: 'Host', value: this.variablePlaceholders.hostName },
    ];
    const $email = $('<div>').html($.parseHTML(previewTemplate.body));
    $email.find('.notification-message-text').text(this.currentPreviewMessage);
    $email.find('.email-fields').html(
      fields
        .map((field) => {
          const fieldName = $('<div>').text(field.name).html();
          const fieldValue = $('<div>').text(field.value).html();
          return previewTemplate.field.replace('{{field_name}}', fieldName).replace('{{field_value}}', fieldValue);
        })
        .join('')
    );
    this.previewEmailObject = {
      subject: this.currentPreviewMessage,
      body: $email.html(),
    };
    scheduleOnce('afterRender', this, this.loadSrcDoc);
  }
}
