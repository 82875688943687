import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type FeatureFlagsService from 'garaje/services/feature-flags';

export default class CommunicationsRoute extends Route {
  @service declare router: RouterService;
  @service declare featureFlags: FeatureFlagsService;

  redirect(): void {
    const commsRoute = this.featureFlags.isEnabled('emergency-notifications-dashboard-parity')
      ? 'location-overview.communications.message-log'
      : 'location-overview.communications.templates';
    return void this.router.transitionTo(commsRoute);
  }
}
