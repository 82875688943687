import type ArrayProxy from '@ember/array/proxy';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { task } from 'ember-concurrency';
import { pluralize } from 'ember-inflector';
import type PrinterConnectionModel from 'garaje/models/printer-connection';
import type PrinterConnectionOptionModel from 'garaje/models/printer-connection-option';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { all } from 'rsvp';

import type { PrinterConnectionsRouteModel } from '../route';

export default class VisitorsDevicesPrintersConnectionsShowController extends Controller {
  @service declare flashMessages: FlashMessagesService;
  @service declare router: RouterService;
  @service declare store: Store;

  model: PrinterConnectionsRouteModel | null = null;

  @action
  async gotoConnection(printerConnection: PrinterConnectionModel): Promise<void> {
    await this.router.transitionTo('visitors.devices.printers.connections.show', printerConnection.id);
    await this.fetchPrinterConnectionOptionsTask.perform();
  }

  @action
  gotoNewConnectionSetup(): void {
    void this.router.transitionTo('visitors.devices.printers.connections.new');
  }

  @action
  async destroyPrinterConnection(printerConnection: PrinterConnectionModel): Promise<void> {
    await this.destroyPrinterConnectionTask.perform(printerConnection);
  }

  @action
  async createPrinters(
    printerConnection: PrinterConnectionModel,
    options: Array<PrinterConnectionOptionModel>
  ): Promise<void> {
    const { model, store, router, flashMessages } = this;

    if (!model?.location) return;

    const somethingWentWrong: string[] = [];

    await all(
      options.map((option) => {
        const printer = store.createRecord('printer', {
          location: model.location,
          printerConnection,
          connectionType: 'printnode',
          name: option.name,
          model: 'Envoy Print',
          externalResourceUid: option.id,
        });

        return printer.save().catch(() => somethingWentWrong.push(option.name));
      })
    );

    if (somethingWentWrong.length) {
      const errorMessage = `Something went wrong while pairing ${pluralize(somethingWentWrong.length, 'printer')}`;

      flashMessages.showFlash('error', errorMessage);
      await this.fetchPrinterConnectionOptionsTask.perform();
    } else {
      const successMessage = `Paired ${pluralize(options.length, 'Printer', { withoutCount: true })}`;

      flashMessages.showAndHideFlash('success', successMessage);
      void router.transitionTo('visitors.devices.printers');
    }
  }

  fetchPrinterConnectionOptionsTask = task({ drop: true }, async () => {
    const { model, store } = this;
    const locationId: string = model?.location?.id ?? '';
    const printerConnectionId: string = model?.printerConnection?.id ?? '';

    const printerConnectionOptions: ArrayProxy<PrinterConnectionOptionModel> = await store.query(
      'printer-connection-option',
      {
        filter: {
          'exclude-in-use': true,
          'location-id': locationId,
          'printer-connection-id': printerConnectionId,
        },
      }
    );

    return printerConnectionOptions;
  });

  destroyPrinterConnectionTask = task(async (printerConnection: PrinterConnectionModel) => {
    const { flashMessages, router } = this;

    try {
      await printerConnection.destroyRecord();

      this.flashMessages.showAndHideFlash('success', 'Printer connection unlinked!');

      void router.transitionTo('visitors.devices.printers.connections.index');
    } catch (e) {
      flashMessages.showFlash('error', 'Deletion failed', parseErrorForDisplay(e));
    }
  });
}
