import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type StateService from 'garaje/services/state';

import type AttendanceController from './controller';

interface AttendanceModel {
  view?: string;
}

export default class AttendanceRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service declare router: RouterService;

  title = 'Attendance · Analytics · Envoy';

  queryParams = {
    view: {
      refreshModel: true,
      defaultValue: 'overview',
    },
  };

  beforeModel(): void {
    if (this.abilities.cannot('visit attendance in global-analytics')) {
      void this.router.transitionTo('unauthorized');
    }
  }

  model(params: AttendanceModel): AttendanceModel {
    const view = params.view;
    return { view };
  }

  setupController(controller: AttendanceController, model: AttendanceModel, transition: Transition): void {
    super.setupController(controller, model, transition);
    controller.view = model.view;
  }
}
