/* eslint-disable ember/no-computed-properties-in-native-classes */
import type { AsyncHasMany } from '@ember-data/model';
import Model, { attr, hasMany } from '@ember-data/model';
import type MailerSectionBlockModel from 'garaje/models/mailer-section-block';
import type MailerTemplateSectionModel from 'garaje/models/mailer-template-section';

export default class MailerSectionModel extends Model {
  // Relationships
  @hasMany('mailer-template-section') declare mailerTemplateSection: AsyncHasMany<MailerTemplateSectionModel>;
  @hasMany('mailer-section-block') declare mailerSectionBlocks: AsyncHasMany<MailerSectionBlockModel>;
  // Attributes
  @attr('immutable', { defaultValue: () => ({}) }) declare settings: Record<string, unknown>;
  @attr('string') declare title: string;
  @attr('boolean') declare sortable: boolean;
  @attr('boolean') declare editable: boolean;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'mailer-section': MailerSectionModel;
  }
}
