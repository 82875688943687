import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { dropTask, restartableTask } from 'ember-concurrency';
import { alias, filterBy } from 'macro-decorators';
import { tracked } from '@glimmer/tracking';
import { RULE_OPERATION_OPTIONS, RULE_ACTION_OPTIONS } from 'garaje/utils/enums';
import urlBuilder from 'garaje/utils/url-builder';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import employeesSearcherTask from 'garaje/utils/employees-searcher';

/**
 * @param {Model<Flow>}         flow              Required. The flow this new rule will belong
 * @param {Model<Rule>}         rule              Optional. When passed, signifies editing. If not passed, a new rule is initiated
 * @param {Function}            cancel
 * @param {Function}            saveRuleTask      Required. Task for saving the rule
 * @param {Function}            handleDeleteRule  Required. Method for invoking the delete confirmation modal
 * @param {Boolean}             isEditing         Required. Whether we're adding or editing
 * @param {Boolean}             isLocationAdmin
 * @param {Boolean}             isProtect
 */
export default class ManageRule extends Component {
  @service ajax;
  @service flashMessages;
  @service store;
  @service state;

  @action
  onInsert() {
    if (this.args.rule.isNew) {
      this.addCondition();

      // For v1 we are automatically setting this value
      if (this.ruleActionOptions.length === 1) {
        this.args.rule.actions = [RULE_ACTION_OPTIONS.DENY];
      }
    }
  }

  /**
   * @type {Array.string} Operation choices for the dropdown
   */
  ruleOperationOptions = [RULE_OPERATION_OPTIONS.IS];

  /**
   * @type {Array.string} Action choices for the dropdown
   */
  ruleActionOptions = [RULE_ACTION_OPTIONS.DENY, RULE_ACTION_OPTIONS.ALERT];

  /**
   * @type {Array.string} actions mapped with their id's to more easily map with MultiPowerSelect
   */
  @tracked selectedActions = [];

  @alias('args.flow.signInFieldPage.signInFields') signInFields;
  @filterBy('singleSelectionFields', 'isNew', false) availableFields;

  /**
   * @type {Array.SignInField} list of fields
   */
  get singleSelectionFields() {
    // Want to filter out host, email, phone, etc. for v1
    return this.signInFields.filter((field) => !field.isHost && !!field.isSingleSelection);
  }

  /**
   * @type {Array.int} list of active employee ids to cross reference against the search
   */
  get activeContactIds() {
    return this.args.rule.signInFieldActionsContacts
      .filterBy('isDeleted', false)
      .map((contact) => get(contact.employee, 'id'));
  }

  /**
   * @type {boolean} determines if form is valid
   */
  get canSaveRule() {
    const { signInFieldActions, actions } = this.args.rule;

    if (!signInFieldActions.length) {
      return false;
    }

    if (!actions.length) {
      return false;
    }

    if (actions.includes(RULE_ACTION_OPTIONS.ALERT) && !this.activeContactIds.length) {
      return false;
    }

    return signInFieldActions.every((condition) => {
      return get(condition.signInField, 'isValid') && !!condition.matchOperator && !!condition.signInFieldValue;
    });
  }

  /**
   * @type {boolean} determines if can delete extra conditions
   */
  get canDeleteConditions() {
    return this.args.rule.signInFieldActions.length > 1;
  }

  /**
   * @type {Array.Employee} the contacts showing in the multiselect-dropdown
   */
  get selectedEmployees() {
    const contacts = this.args.rule.signInFieldActionsContacts.filterBy('isDeleted', false);

    return contacts.map((contact) => contact.employee);
  }

  @action
  addCondition() {
    this.args.rule.signInFieldActions.createRecord({
      action: RULE_ACTION_OPTIONS.DENY,
      flow: this.args.flow,
      matchOperator: RULE_OPERATION_OPTIONS.IS,
      signInFieldActionRuleGroup: this.currentRule,
    });
  }

  @action
  deleteCondition(condition) {
    if (this.canDeleteConditions) {
      condition.deleteRecord();
    }
  }

  /**
   * @param {object.Employee} selectedEmployee employee selected from dropdown
   * @returns {object.SignInFieldActionsContact} Converts an employee to a `contact`. Returns null if removing
   * This result of this function is passed to `setAlertContacts` via ember-power-select's onChange
   */
  @action
  handleBuildSelection(selectedEmployee) {
    const contactToRemove = this.args.rule.signInFieldActionsContacts.find(
      (contact) => get(contact.employee, 'id') === get(selectedEmployee, 'id')
    );

    if (contactToRemove) {
      // the user is adding back a person they previously removed, prior to saving
      if (contactToRemove.isDeleted && !contactToRemove.isNew) {
        contactToRemove.rollbackAttributes();
        return null;
      }

      contactToRemove.deleteRecord();
      return null;
    }

    const modelType = this.actionContactModelType();
    return this.store.createRecord(modelType, {
      employee: selectedEmployee,
      actionType: RULE_ACTION_OPTIONS.ALERT,
      signInFieldActionRuleGroup: this.args.rule,
    });
  }

  actionContactModelType() {
    if (this.args.isProtect) {
      return 'employee-sign-in-field-actions-contact';
    } else if (this.args.flow.isGlobal) {
      return 'global-sign-in-field-actions-contact';
    }

    return 'sign-in-field-actions-contact';
  }

  @action
  handleKeydown(dropdown, ev) {
    if (ev.code === 'Enter') {
      ev.preventDefault();
    }
  }

  @action
  setAlertContacts(newContact) {
    if (!newContact) {
      return null;
    }

    this.args.rule.signInFieldActionsContacts.pushObject(newContact);
  }

  @restartableTask
  *searchContactsTask(term) {
    const results = yield this.searchEmployeesTask.perform(
      term,
      {},
      { withoutLocation: this.args.flow.isGlobal || this.args.flow.isProtect }
    );

    return results.filter(({ id }) => !this.activeContactIds.includes(id));
  }

  @(employeesSearcherTask({
    filter: {
      deleted: false,
      excludeHidden: false,
    },
    prefix: true,
  }).restartable())
  searchEmployeesTask;

  /**
   * @returns {void} sends a test email to the current user
   */
  @dropTask
  *sendTestEmailAlertTask() {
    const url = urlBuilder.v3.sendTestNotificationUrl();
    const { currentLocation } = this.state;

    try {
      yield this.ajax.request(url, {
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          data: {
            location_id: currentLocation.id,
          },
        }),
      });
      this.flashMessages.showAndHideFlash('success', 'Sent!');
    } catch (err) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(err));
    }
  }

  @action
  onDestroy() {
    if (!this.args.rule?.isDestroying) this.args.rule?.rollbackAttributes();
  }
}
