// just pre-load all the stuff that index.js lazily exports
const internalRe = require('./internal/re')
module.exports = {
  re: internalRe.re,
  src: internalRe.src,
  tokens: internalRe.t,
  SEMVER_SPEC_VERSION: require('./internal/constants').SEMVER_SPEC_VERSION,
  SemVer: require('./classes/semver'),
  compareIdentifiers: require('./internal/identifiers').compareIdentifiers,
  rcompareIdentifiers: require('./internal/identifiers').rcompareIdentifiers,
  parse: require('./functions/parse'),
  valid: require('./functions/valid'),
  clean: require('./functions/clean'),
  inc: require('./functions/inc'),
  diff: require('./functions/diff'),
  major: require('./functions/major'),
  minor: require('./functions/minor'),
  patch: require('./functions/patch'),
  prerelease: require('./functions/prerelease'),
  compare: require('./functions/compare'),
  rcompare: require('./functions/rcompare'),
  compareLoose: require('./functions/compare-loose'),
  compareBuild: require('./functions/compare-build'),
  sort: require('./functions/sort'),
  rsort: require('./functions/rsort'),
  gt: require('./functions/gt'),
  lt: require('./functions/lt'),
  eq: require('./functions/eq'),
  neq: require('./functions/neq'),
  gte: require('./functions/gte'),
  lte: require('./functions/lte'),
  cmp: require('./functions/cmp'),
  coerce: require('./functions/coerce'),
  Comparator: require('./classes/comparator'),
  Range: require('./classes/range'),
  satisfies: require('./functions/satisfies'),
  toComparators: require('./ranges/to-comparators'),
  maxSatisfying: require('./ranges/max-satisfying'),
  minSatisfying: require('./ranges/min-satisfying'),
  minVersion: require('./ranges/min-version'),
  validRange: require('./ranges/valid'),
  outside: require('./ranges/outside'),
  gtr: require('./ranges/gtr'),
  ltr: require('./ranges/ltr'),
  intersects: require('./ranges/intersects'),
  simplifyRange: require('./ranges/simplify'),
  subset: require('./ranges/subset'),
}
