import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { routeEvent } from 'garaje/utils/decorators/route';
import { isPresent } from '@ember/utils';

export default class LocationOverviewEmployeesDirectoryEmployeeRoute extends Route {
  @service abilities;
  @service router;
  @service state;
  @service store;

  async model({ employee_id }) {
    const employee = await this.store.findRecord('employee', employee_id);
    const employeeLocations = await employee.employeeLocations;
    const user = await employee.user;

    const { userDocumentTemplates, userDocumentTemplateConfigurations } = this.modelFor(
      'location-overview.employees.directory'
    );
    const templateIds = userDocumentTemplateConfigurations?.mapBy('userDocumentTemplate.id') ?? [];
    const model = { employee, employeeLocations, userDocumentTemplates, userDocumentTemplateConfigurations };

    if (this.abilities.can('review user-document-links') && isPresent(user) && templateIds.length) {
      model.userDocuments = await this.store.query('user-document', {
        include: 'user-document-template,user-document-links,user-document-location-contexts',
        filter: {
          user: user.id,
          'user-document-template': templateIds,
        },
      });
    }

    return model;
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.setupChangeset();
  }

  @routeEvent
  routeWillChange(transition) {
    if (
      this.controller.hasDirtyAttributes &&
      !window.confirm('You will lose any unsaved changes. Are you sure you want to continue?')
    ) {
      transition.abort();
    } else {
      this.controller.isEditModeEnabled = false;
      const { employee } = this.controller.model;
      // In some conditions, the model is destroyed in the controller
      // and it returns error. This is related to:
      // https://github.com/emberjs/data/issues/5006#issuecomment-339967711
      if (!employee.isDestroyed) {
        employee.rollbackAttributes();
      }
      return true;
    }
  }
}
