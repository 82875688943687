import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { debounce } from '@ember/runloop';
import { service } from '@ember/service';
import type { DetailedChangeset } from 'ember-changeset/types';
import { all, dropTask, keepLatestTask } from 'ember-concurrency';
import type MailerTemplateVersionModel from 'garaje/models/mailer-template-version';
import type SubscriptionModel from 'garaje/models/subscription';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { union, reads } from 'macro-decorators';
import { Promise as EmberPromise } from 'rsvp';

import type SettingsEmailsTemplatesController from '../controller';

import type { SettingsEmailsTemplatesVersionsRouteModel } from './route';

// These are the types of email notification that should *always* use
// Company Name in the "From" headers.
const IDENTIFIERS_ALWAYS_FROM_COMPANY = Object.freeze(['registration']);

export default class SettingsEmailsTemplatesVersionsController extends Controller {
  declare model: SettingsEmailsTemplatesVersionsRouteModel;

  @controller('visitors.settings.emails.templates') emailTemplateController!: SettingsEmailsTemplatesController;

  @service declare flashMessages: FlashMessagesService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare transitionConfirm: TransitionConfirmService;

  transitionConfirmed = false;

  @reads('state.vrSubscription.hasWhiteglovedFromEmailName')
  hasWhiteglovedFromEmailName!: SubscriptionModel['hasWhiteglovedFromEmailName'];
  @union('model.mailerTemplateVersionsByCompany', 'model.mailerTemplateVersionsByLocation')
  mailerTemplateVersions!: MailerTemplateVersionModel[];

  get shouldUseCompanyName(): boolean {
    const {
      hasWhiteglovedFromEmailName,
      model: { mailerTemplate },
    } = this;
    const isAlwaysFromCompany = IDENTIFIERS_ALWAYS_FROM_COMPANY.includes(mailerTemplate?.identifier);

    return hasWhiteglovedFromEmailName || isAlwaysFromCompany;
  }

  get shouldShowSidebar(): boolean {
    const { isEditing } = this.emailTemplateController;
    const { mailerTemplateVersion } = this.model;
    return isEditing && !mailerTemplateVersion.isDefault && !mailerTemplateVersion.isDeleted;
  }

  get hasChanges(): boolean {
    const { mailerTemplateVersion, mailerSectionBlockOverrides } = this.model;
    return (
      <boolean>(<unknown>mailerTemplateVersion.hasDirtyAttributes) || mailerSectionBlockOverrides?.isAny('isDirty')
    );
  }

  rollbackAll(): void {
    const { mailerTemplateVersion, mailerSectionBlockOverrides } = this.model;
    if (!mailerTemplateVersion.isDestroyed) mailerTemplateVersion.rollbackAttributes();
    mailerSectionBlockOverrides?.filterBy('hasDirtyAttributes').forEach((o) => o.rollbackAttributes());
  }

  confirmTransition(transition: Transition): void {
    if (!this.transitionConfirmed && this.hasChanges) {
      void this.transitionConfirm.displayConfirmTask.perform(transition, {
        continue: () => (this.transitionConfirmed = true),
      });
    }
  }

  loadEmailPreviewHTMLTask = keepLatestTask(async () => {
    const { flashMessages } = this;
    const { mailerTemplateVersion } = this.model;
    try {
      return await mailerTemplateVersion.getEmailPreview();
    } catch (e) {
      flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      throw e;
    }
  });

  sendTestEmailTask = dropTask(async () => {
    const { flashMessages } = this;
    const { mailerTemplateVersion } = this.model;
    try {
      await mailerTemplateVersion.sendTestEmail();
      flashMessages.showAndHideFlash('success', 'Test email sent!');
    } catch (e) {
      flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      throw e;
    }
  });

  saveBlockOverridesTask = dropTask(
    async ({
      mailerTemplateVersionChangeset,
    }: {
      mailerTemplateVersionChangeset: DetailedChangeset<MailerTemplateVersionModel>;
    }) => {
      const { flashMessages } = this;
      const { mailerSectionBlockOverrides } = this.model;
      try {
        await all(mailerSectionBlockOverrides.filterBy('isDirty').map((o) => o.save()));
        await mailerTemplateVersionChangeset.save();
        flashMessages.showAndHideFlash('success', 'Saved!');
      } catch (e) {
        flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
        throw e;
      }
    }
  );

  @dropTask deleteConfirmationTask = {
    *perform(this: {
      context: SettingsEmailsTemplatesVersionsController;
      continue: () => Promise<void>;
      abort: () => void;
    }): Generator<Promise<unknown>, void, unknown> {
      const { context: controller } = this;
      yield new EmberPromise((resolve, reject) => {
        this.continue = async () => {
          await controller.deleteTemplateVersion();
          resolve();
        };
        this.abort = () => reject();
      });
    },
  };

  @action
  async deleteTemplateVersion(): Promise<void> {
    const { flashMessages, model } = this;
    const { mailerTemplate, mailerTemplateVersion, mailerTemplateVersionsByLocation } = model;
    try {
      await mailerTemplateVersion.destroyRecord();
      await mailerTemplateVersionsByLocation.update();
      flashMessages.showAndHideFlash('success', 'Template deleted!');
      void this.router.replaceWith('visitors.settings.emails.templates', mailerTemplate.id);
    } catch (e) {
      flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      throw e;
    }
  }

  @action
  loadEmailPreviewHTML(): void {
    debounce(this.loadEmailPreviewHTMLTask, 'perform', 500);
  }

  @action
  transitionToVersion(mailerTemplateVersion: MailerTemplateVersionModel): void {
    const { mailerTemplate } = this.model;
    this.transitionToRoute('visitors.settings.emails.templates.versions', mailerTemplate.id, mailerTemplateVersion.id);
  }
}
