/* eslint-disable ember/no-get */
import { inject as service } from '@ember/service';
import type Model from '@ember-data/model';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type CompanyModel from 'garaje/models/company';
import type DeliveryAreaModel from 'garaje/models/delivery-area';
import type LocationModel from 'garaje/models/location';
import type AjaxService from 'garaje/services/ajax';
import type LocationsService from 'garaje/services/locations';
import urlBuilder from 'garaje/utils/url-builder';
import type { SingleResponse } from 'jsonapi/response';
import moment from 'moment-timezone';
import type { Constructor } from 'type-fest';

import type SessionService from '../session';
import type StateServiceInUse from '../state';

import type { CompanyMeta } from './user-state';

export interface LocationDeliveryAreaGrouping {
  location: LocationModel;
  deliveryAreas: DeliveryAreaModel[];
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
function withCompanyStateFunctionality<TBase extends Constructor<any>>(Base: TBase) {
  class withCompanyStateFunctionality extends Base {
    declare currentCompany: CompanyModel | null;
    declare companyId: string;

    @service('locations') declare locationService: LocationsService;
    @service declare ajax: AjaxService;
    @service declare store: StoreService;
    @service declare state: StateServiceInUse;
    @service declare session: SessionService;

    forceActiveReload(lastLoaded: moment.Moment | null) {
      if (!lastLoaded) {
        return true;
      }
      const nowMinus30 = moment().subtract(60, 'seconds');
      return lastLoaded.isBefore(nowMinus30);
    }

    initCompanyState = task({ drop: true }, async () => {
      this.state._companyId = this.session['companyId'] as string | null;

      // Not all valid logins will have company IDs in the JWT since they could just be a Passport user

      if (!this.state._companyId) {
        return;
      }

      this.currentCompany = await this.store.findRecord('company', this.state._companyId, { reload: true });
    });

    fetchCompanyConfigMeta = task({ drop: true }, async () => {
      const companyId = this.state._companyId;

      if (!companyId) {
        return;
      }

      const adapter = this.store.adapterFor('company');
      try {
        const url = `${adapter.buildURL('company', companyId)}?counts-meta=1`;
        const data: { meta: CompanyMeta } = await this.ajax.request(url);
        this.state._companyMeta = data?.meta;
      } catch (e) {
        // do nothing
      }
    });

    fetchCompanyBilling = task({ drop: true }, async () => {
      const companyId = this.state._companyId;

      if (!companyId) {
        return;
      }

      this.state.billingCompany = await this.store.findRecord('billing-company', companyId);
    });

    /*
      VISITORS
    */

    // LOCATIONS COUNT - ACROSS COMPANY
    @tracked _fetchActiveLocationsLoaded: moment.Moment | null = null;

    activeLocationsCount = task(async () => {
      const { fetchActiveLocationsCount, _fetchActiveLocationsLoaded } = this;
      let count = 0;
      if (this.forceActiveReload(_fetchActiveLocationsLoaded)) {
        count = await fetchActiveLocationsCount.perform();
      } else {
        count = this.fetchActiveLocationsCount.last!.value!;
      }
      return count;
    });

    fetchActiveLocationsCount = task(async () => {
      const { locationService } = this;
      let temp = 0;
      let count = 0;
      try {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        temp = await locationService.active.length;
      } finally {
        count = temp || 0;
        this._fetchActiveLocationsLoaded = moment();
      }
      return count;
    });

    // VISITOR TYPES COUNT - ACROSS COMPANY
    @tracked _locationsWithMultipleVisitorTypesCountLoaded: moment.Moment | null = null;

    locationsWithMultipleVisitorTypesCount = task(async () => {
      const { fetchLocationsWithMultipleVisitorTypesCount, _locationsWithMultipleVisitorTypesCountLoaded } = this;
      let count = 0;
      if (this.forceActiveReload(_locationsWithMultipleVisitorTypesCountLoaded)) {
        count = await fetchLocationsWithMultipleVisitorTypesCount.perform();
      } else {
        count = this.fetchLocationsWithMultipleVisitorTypesCount.last!.value!;
      }
      return count;
    });

    fetchLocationsWithMultipleVisitorTypesCount = task(async () => {
      const { locationService } = this;
      let temp = 0;
      let count = 0;
      try {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        temp = await locationService.active.filter((location) => location.hasMany('flows').ids().length > 1).length;
      } finally {
        count = temp || 0;
        this._locationsWithMultipleVisitorTypesCountLoaded = moment();
      }
      return count;
    });

    /*
      ROOMS
    */

    // ACTIVE ROOMS COUNT - ACROSS COMPANY && ACTIVE ROOMS BY LOCATION
    @tracked _activeRoomsCountLoaded: moment.Moment | null = null;
    @tracked _activeRoomsPerLocation: unknown[] | null = null;

    activeRoomsCount = task(async () => {
      const { fetchActiveRoomsCount, _activeRoomsCountLoaded } = this;
      let count = 0;
      if (this.forceActiveReload(_activeRoomsCountLoaded)) {
        count = await fetchActiveRoomsCount.perform();
        this._activeRoomsCountLoaded = moment();
      } else {
        const results = this.fetchActiveRoomsCount.last!.value;
        count = results || 0;
      }
      return count;
    });

    activeRoomsPerLocation = task(async () => {
      const { fetchActiveRoomsCount, _activeRoomsCountLoaded } = this;
      if (this.forceActiveReload(_activeRoomsCountLoaded)) {
        await fetchActiveRoomsCount.perform();
      }
      return this._activeRoomsPerLocation;
    });

    fetchActiveRoomsCount = task(async () => {
      const { companyId, roomsSubscription } = this;
      let temp = 0;
      let count = 0;
      let perLocation: unknown[] = [];
      try {
        if (roomsSubscription) {
          const url = urlBuilder.v2.roomsActiveCountUrl(companyId);
          const { num_active_rooms, num_active_rooms_per_location } = await this.ajax.request<{
            num_active_rooms?: number;
            num_active_rooms_per_location?: unknown[];
          }>(url);
          temp = num_active_rooms || 0;
          perLocation = num_active_rooms_per_location || [];
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('this doesnt work locally', err);
      } finally {
        count = temp;
        this._activeRoomsPerLocation = perLocation;
      }
      return count;
    });

    /*
      DELIVERIES
    */

    // ACTIVE DELIVERY AREAS COUNT - ACROSS COMPANY && ACTIVE DELIVERY AREAS BY LOCATION
    @tracked _activeDeliveryAreasCountLoaded = null;
    @tracked _activeDeliveryAreasPerLocation: LocationDeliveryAreaGrouping[] | null = null;

    activeDeliveryAreasCount = task(async () => {
      const { fetchActiveDeliveryAreasCount, _activeDeliveryAreasCountLoaded } = this;
      let count = 0;
      if (this.forceActiveReload(_activeDeliveryAreasCountLoaded)) {
        count = await fetchActiveDeliveryAreasCount.perform();
        this._activeRoomsCountLoaded = moment();
      } else {
        const results = this.fetchActiveRoomsCount.last!.value;
        // @ts-ignore - results is a number
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        count = results.count || 0;
      }
      return count;
    });

    activeDeliveryAreasPerLocation = task(async () => {
      const { fetchActiveRoomsCount, _activeDeliveryAreasCountLoaded } = this;
      if (this.forceActiveReload(_activeDeliveryAreasCountLoaded)) {
        await fetchActiveRoomsCount.perform();
      }
      return this._activeDeliveryAreasPerLocation;
    });

    fetchActiveDeliveryAreasCount = task(async () => {
      const { deliveriesSubscription } = this;
      let temp = 0;
      let count = 0;
      let perLocation: LocationDeliveryAreaGrouping[] = [];
      try {
        if (deliveriesSubscription) {
          const deliveryAreas = await this.store.findAll('delivery-area');
          if (deliveryAreas && deliveryAreas.length) {
            const activeDeliveryAreas = deliveryAreas.filter((deliveryArea) => deliveryArea.enabled);
            temp = activeDeliveryAreas.length;
            perLocation = this.getDeliveryAreasByLocation(activeDeliveryAreas);
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('this doesnt work locally', err);
      } finally {
        count = temp;
        this._activeDeliveryAreasPerLocation = perLocation;
      }
      return count;
    });

    getDeliveryAreasByLocation(deliveryAreas: DeliveryAreaModel[]): LocationDeliveryAreaGrouping[] {
      const { locationService } = this;
      const deliveryAreasByLocation = [];
      if (!deliveryAreas || !deliveryAreas.length) {
        return [];
      }
      for (const location of locationService.active) {
        const localdeliveryAreas = deliveryAreas.filter((da) => da.belongsTo('location').id() === location.id);
        const obj = {
          location,
          deliveryAreas: localdeliveryAreas,
        };
        deliveryAreasByLocation.push(obj);
      }
      return deliveryAreasByLocation;
    }

    async fetchCompanyConfigId() {
      const companyId = this.currentCompany?.id;
      if (companyId) {
        const url = urlBuilder.v3.getCompanyConfigurationUrl(companyId);
        const companyConfiguration = await this.ajax.request<SingleResponse<Model>>(url, {
          type: 'GET',
          headers: { Accept: 'application/vnd.api+json' },
        });
        const companyConfigurationId = companyConfiguration?.data?.id;
        return companyConfigurationId;
      }

      return '';
    }
  }

  return withCompanyStateFunctionality;
}

export default withCompanyStateFunctionality;
