import Component from '@glimmer/component';
import { v4 as uuid } from 'uuid';
import { action } from '@ember/object';
import { cached } from 'tracked-toolbox';
import { next } from '@ember/runloop';

export default class SlideToggleComponent extends Component {
  @cached
  get id() {
    return this.args.id ?? uuid();
  }

  @action
  handleKeyUp(event) {
    if (event.key === ' ' || event.key === 'Spacebar') {
      event.preventDefault();
      event.stopImmediatePropagation();
      const input = document.getElementById(this.id);
      const toggled = !input.checked;
      this.args.onClick(toggled);
    }
  }

  @action
  toggle(event) {
    event.preventDefault();
    next(() => this.args.onClick(!this.args.isChecked));
  }
}
