import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

export default class LocationOverviewSignInFlowsLegalDocumentsEditController extends Controller {
  @service router;
  @service metrics;
  @service flashMessages;

  trackDeleteClicked(id) {
    this.metrics.trackEvent('Legal Docs - Delete Legal Document Button Clicked', {
      legal_doc_id: id,
      location_id: this.model.tracking.currentLocationId,
    });
  }

  @action
  handleDelete() {
    const id = this.model.agreement.id;
    try {
      this.model.agreement.destroyRecord();
      this.trackDeleteClicked(id);
      this.router.transitionTo('location-overview.sign-in-flows.show.legal-documents.index', this.model.flow.id);
    } catch (e) {
      this.flashMessages.showFlash('error', parseErrorForDisplay(e));
    }
  }
}
