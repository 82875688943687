import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { Changeset } from 'ember-changeset';
import { type DetailedChangeset } from 'ember-changeset/types';
import type DeviceConfigStaticPageAttributeModel from 'garaje/models/device-config-static-page-attribute';
import PropertyInvitedFinalScreenModel from 'garaje/models/property-invited-final-screen';
import PropertyWalkupFinalScreenModel from 'garaje/models/property-walk-up-final-screen';
import type ZoneModel from 'garaje/models/zone';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';

import { type PropertySettingsKioskRouteModel } from '../route';

export interface PropertySettingsKioskFinalScreenRouteModel {
  walkupPageAttributeChangeset?: DetailedChangeset<DeviceConfigStaticPageAttributeModel>;
  invitedPageAttributeChangeset?: DetailedChangeset<DeviceConfigStaticPageAttributeModel>;
  property: ZoneModel;
}

export default class PropertySettingsKioskFinalScreenRoute extends Route {
  @service declare transitionConfirm: TransitionConfirmService;

  model(): PropertySettingsKioskFinalScreenRouteModel {
    const { deviceConfigStaticPages } = <PropertySettingsKioskRouteModel>this.modelFor('property.settings.kiosk');
    const property = <ZoneModel>this.modelFor('property');

    const model = deviceConfigStaticPages.reduce<PropertySettingsKioskFinalScreenRouteModel>(
      (acc, page) => {
        if (page instanceof PropertyWalkupFinalScreenModel) {
          acc['walkupPageAttributeChangeset'] = Changeset(page.deviceConfigStaticPageAttributes.firstObject);
        }
        if (page instanceof PropertyInvitedFinalScreenModel) {
          acc['invitedPageAttributeChangeset'] = Changeset(page.deviceConfigStaticPageAttributes.firstObject);
        }

        return acc;
      },
      { property }
    );

    return model;
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    // don't do anything when entering this route
    if (transition.to.name === this.routeName) return;

    const { walkupPageAttributeChangeset, invitedPageAttributeChangeset } = <
      PropertySettingsKioskFinalScreenRouteModel
    >this.modelFor(this.routeName);

    const attributePageDirty =
      invitedPageAttributeChangeset?.isDirty ||
      invitedPageAttributeChangeset?.deviceConfigStaticPageAttributeTranslations.any(
        (page) => !!page.hasDirtyAttributes
      );

    if (!walkupPageAttributeChangeset?.isDirty && !attributePageDirty) return;

    // otherwise, prompt user for confirmation before leaving the page
    void this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue() {
        walkupPageAttributeChangeset?.rollback();
        invitedPageAttributeChangeset?.rollback();
        invitedPageAttributeChangeset?.deviceConfigStaticPageAttributeTranslations.invoke('rollbackAttributes');
      },
    });
  }
}
