import Component from '@glimmer/component';
import { format, subMinutes } from 'date-fns';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class ReservationApprovalScreen extends Component {
  @service state;

  get formattedDate() {
    const { successfulReservations } = this.args;
    return format(
      subMinutes(successfulReservations[0].startTime, this.state.minutesBetweenTimezones),
      'eeee, MMMM do yyyy'
    );
  }

  @action
  formattedMultiDate(res) {
    return format(subMinutes(res.startTime, this.state.minutesBetweenTimezones), 'eee, MMM dd, yyyy');
  }

  @action
  formattedPartialDate(res) {
    const startTime = format(subMinutes(res.startTime, this.state.minutesBetweenTimezones), 'h:mmaaa');
    const endTime = format(subMinutes(res.endTime, this.state.minutesBetweenTimezones), 'h:mmaaa');
    const date = format(subMinutes(res.startTime, this.state.minutesBetweenTimezones), 'eee, MMM dd, yyyy');
    return `${date} from ${startTime} to ${endTime}`;
  }
}
