import Changeset from 'ember-changeset';
import FlowValidations from 'garaje/validations/flow';
import Route from '@ember/routing/route';
import lookupValidator from 'ember-changeset-validations';
import { get } from '@ember/object';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';
import { routeEvent } from 'garaje/utils/decorators/route';
// import { NON_ASSIGNABLE_FLOWS } from 'garaje/utils/enums';

export default class VisitorTypesNewRoute extends Route {
  @service state;
  @service store;
  @service transitionConfirm;

  async model() {
    const { currentLocation, vrSubscription } = this.state;
    const allFlows = await this.store.query('copy-flow', {});
    const newFlow = this.store.createRecord('flow', { location: currentLocation });
    const changeset = this._buildChangeset(newFlow);
    const globalFlows = this.store.findAll('global-flow');

    // All flows is a collection of "Copy Flow" records which do not have a type. This filter serves no purpose!
    // allFlows = allFlows.filter(({ employeeCentric, type }) => !NON_ASSIGNABLE_FLOWS.includes(type) && !employeeCentric);

    return hash({
      allFlows,
      changeset,
      currentLocation,
      newFlow,
      vrSubscription,
      globalFlows,
    });
  }

  _buildChangeset(flow) {
    const validator = lookupValidator(FlowValidations);
    return new Changeset(flow, validator, FlowValidations);
  }

  @routeEvent
  routeWillChange(transition) {
    const { changeset, newFlow } = get(this.controller, 'model');

    if (get(changeset, 'isDirty')) {
      this.transitionConfirm.displayConfirmTask.perform(transition, {
        continue() {
          changeset.rollback();
        },
      });
    } else {
      if (get(newFlow, 'isNew')) {
        newFlow.destroyRecord();
      }
    }
  }
}
