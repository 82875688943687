import Controller from '@ember/controller';
import updateAndSaveTask from 'garaje/utils/decorators/update-and-save-task';
import { inject as service } from '@ember/service';

@updateAndSaveTask
class VisitorsSettingsVisitorTypesFlowPlusOneController extends Controller {
  @service currentAdmin;
}

export default VisitorsSettingsVisitorTypesFlowPlusOneController;
