import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';
import { hasCorrectPlanForGlobalViews } from 'garaje/utils/global-abilities';
import { Permission } from 'garaje/utils/ui-permissions';

export default class GlobalLocationAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare skinnyLocations: SkinnyLocationModel;
  @service declare state: StateService;
  @service declare authz: AuthzService;

  get canVisit(): boolean {
    const { vrSubscription, workplaceSubscription, vfdSubscription } = this.state;
    const isOnCorrectPlan = hasCorrectPlanForGlobalViews({ vrSubscription, workplaceSubscription, vfdSubscription });
    const hasPermission = this.authz.hasPermissionAtCurrentCompany(Permission.ACCOUNT_MANAGEMENT_LOCATION_UPDATE);

    return isOnCorrectPlan && hasPermission;
  }
}
