import Controller from '@ember/controller';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import type UserModel from 'garaje/models/user';
import type FlashMessagesService from 'garaje/services/flash-messages';

export default class ProfileOnboardingController extends Controller {
  @service declare flashMessages: FlashMessagesService;

  saveTask = dropTask(async <T extends keyof UserModel>(model: UserModel, property: T, value: UserModel[T]) => {
    model[property] = value;

    try {
      await model.save();

      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      model.rollbackAttributes();
    }
  });
}
