import { isBlank } from '@ember/utils';
import type LocationSubscriptionModel from 'garaje/models/location-subscription';
import type SubscriptionModel from 'garaje/models/subscription';

/**
 * Shared logic around a lot of abilities related to the "global overview" pages in garaje.
 *
 * @returns if false, users should *not* be able to see the global views. If true, there should still be some other checks probably.
 */
export function hasCorrectPlanForGlobalViews({
  vrSubscription,
  workplaceSubscription,
  vfdSubscription,
}: {
  vrSubscription: LocationSubscriptionModel | SubscriptionModel | null;
  workplaceSubscription: LocationSubscriptionModel | SubscriptionModel | null;
  vfdSubscription: LocationSubscriptionModel | SubscriptionModel | null;
}): boolean {
  if (isBlank(vrSubscription) && isBlank(workplaceSubscription) && isBlank(vfdSubscription)) {
    return false;
  }

  return !vrSubscription?.isBasicUser || !!workplaceSubscription || !!vfdSubscription;
}
