import Route from '@ember/routing/route';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { routeEvent } from 'garaje/utils/decorators/route';
import { isPresent } from '@ember/utils';

class EmployeesDirectoryEmployeeRoute extends Route {
  @service state;
  @service abilities;
  @service store;

  async model({ employee_id }) {
    const employee = await this.store.findRecord('employee', employee_id);
    const employeeLocations = await employee.employeeLocations;
    const user = await employee.user;
    const { userDocumentTemplates, userDocumentTemplateConfigurations } = this.modelFor('employees.directory');
    const templateIds = userDocumentTemplateConfigurations?.mapBy('userDocumentTemplate.id') ?? [];
    const model = { employee, employeeLocations, userDocumentTemplates, userDocumentTemplateConfigurations };

    if (this.abilities.can('review user-document-links') && isPresent(user) && templateIds.length) {
      model.userDocuments = await this.store.query('user-document', {
        include: 'user-document-template,user-document-links,user-document-location-contexts',
        filter: {
          user: user.id,
          'user-document-template': templateIds,
        },
      });
    }

    return model;
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.setupChangeset();
  }

  @routeEvent
  routeWillChange(transition) {
    if (
      get(this.controller, 'hasDirtyAttributes') &&
      !window.confirm('You will lose any unsaved changes. Are you sure you want to continue?')
    ) {
      transition.abort();
    } else {
      set(this.controller, 'isEditModeEnabled', false);
      const { employee } = get(this.controller, 'model');
      // @yininge
      // In some condition, the model is destroy in the controller
      // and it returns error. This is related to:
      // https://github.com/emberjs/data/issues/5006#issuecomment-339967711
      if (!get(employee, 'isDestroyed')) {
        employee.rollbackAttributes();
      }
      return true;
    }
  }
}

export default EmployeesDirectoryEmployeeRoute;
