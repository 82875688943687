import AbstractSignInFieldPageModel from 'garaje/models/abstract/abstract-sign-in-field-page';
import { belongsTo, hasMany } from '@ember-data/model';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { sort } from '@ember/object/computed';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('sign-in-field-page')
class GlobalSignInFieldPageModel extends AbstractSignInFieldPageModel {
  @belongsTo('global-flow') flow;
  @hasMany('global-sign-in-field') signInFields;

  sortPosition = ['position'];
  @sort('signInFields', 'sortPosition') topLevelFields;
}

export default GlobalSignInFieldPageModel;
