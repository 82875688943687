import $ from 'jquery';
import { later, run } from '@ember/runloop';
import { Promise as EmberPromise } from 'rsvp';
import ENV from 'garaje/config/environment';

export default function tzlookup(latitude, longitude) {
  return new EmberPromise(function (resolve, reject) {
    if (ENV.environment === 'test') {
      later(null, resolve, { timeZoneId: 'UTC' }, 10);
    } else {
      $.ajax({
        url: `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${Math.floor(
          new Date().getTime() / 1000
        )}&key=${ENV.googleApiKey}`,
        success(data) {
          if (data.status === 'OK') {
            run(null, resolve, data);
          } else {
            run(null, resolve, { timeZoneId: 'America/Los_Angeles' });
          }
        },
        error(data) {
          run(null, reject, data);
        },
      });
    }
  });
}
