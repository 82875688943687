import { isArray, A } from '@ember/array';
import DS from 'ember-data';

// eslint-disable-next-line ember/no-classic-classes
export default DS.Transform.extend({
  deserialize(value) {
    if (isArray(value)) {
      const _val = value.map((v) => {
        return { userId: v['user-id'], fullName: v['full-name'] };
      });
      return A(_val);
    } else {
      return A();
    }
  },
});
