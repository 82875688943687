import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { hash } from 'rsvp';

export default class LocationOverviewSignInFlowsShowVisitorPhotosRoute extends Route {
  model() {
    const { globalFlow } = this.modelFor('location-overview.sign-in-flows.show');
    const photoPage = get(globalFlow, 'photoPage');
    const badge = get(globalFlow, 'badge');

    return hash({ flow: globalFlow, photoPage, badge });
  }
}
