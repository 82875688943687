import Component from '@glimmer/component';
import { get, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { or, reads } from 'macro-decorators';
import { addDays, parse, subSeconds, isValid, isAfter, format } from 'date-fns';
import { zonedTimeToUtc, formatInTimeZone } from 'date-fns-tz';
import { DATE_FORMAT } from 'garaje/models/abstract/abstract-document';
import { service } from '@ember/service';

// ^ UDTC = UserDocumentTemplateConfiguration

/**
 * @param {VisitorDocument}  visitorDocument                    VistorDocument record (required)
 * @param {UDTC^}            userDocumentTemplateConfiguration  configs to indicate validity duration of document (optional)
 * @param {string}           timezone                           time zone to adjust to for date computations (optional)
 * @param {action}           onReset                            respond to click on reset button ("garbage" icon)
 * @param {action}           onFileSelected                     action triggered when a file is selected
 * @param {action}           onFilePreview                      action triggered when a file preview is computed
 * @param {action}           onInputFieldChange                 action triggered when an input value changes
 * @param {Boolean}          disableReset                       option to disable reset button
 */
export default class VisitorDocumentManagerComponent extends Component {
  validFileTypes = /image\/(gif|jpe?g|png)/;

  @service featureFlags;

  @tracked lightboxAttachment = null;

  @reads('args.visitorDocument.hasAttachedFile') hasAttachedFile;
  @or('args.visitorDocument.id', 'hasAttachedFile') isResettable;

  get sortedUserDocumentTemplateAttachments() {
    return (
      get(this.args, 'visitorDocument.userDocumentTemplate.userDocumentTemplateAttachments')?.sortBy('position') ?? []
    );
  }

  get sortedUserDocumentAttachmentData() {
    const { visitorDocument } = this.args;

    // Assemble pairs of template attachments and document attachments
    const sortedUserDocumentAttachmentData = this.sortedUserDocumentTemplateAttachments.map(
      (userDocumentTemplateAttachment) => {
        const userDocumentAttachment = visitorDocument?.getAttachment(userDocumentTemplateAttachment.id);
        return {
          userDocumentTemplateAttachment,
          userDocumentAttachment,
          fileUrl: userDocumentAttachment?.fileUrl,
          title: userDocumentTemplateAttachment.title,
        };
      }
    );

    if (this.featureFlags.isEnabled('visitors-id-scan-images')) {
      visitorDocument.externalAttachments.forEach((externalAttachment) => {
        sortedUserDocumentAttachmentData.push({
          externalAttachment,
          fileUrl: externalAttachment.url,
          title: externalAttachment.type,
        });
      });
    }

    return sortedUserDocumentAttachmentData;
  }

  get issueDate() {
    const { visitorDocument } = this.args;
    const issueDateStr = visitorDocument?.issueDate;
    const issueDate = parse(issueDateStr, DATE_FORMAT, new Date());

    return isValid(issueDate) ? issueDate : null;
  }

  get issueDateValidityOffsetInDays() {
    return this.args.userDocumentTemplateConfiguration?.issueDateValidityOffsetInDays ?? 0;
  }

  get expirationDate() {
    const { issueDate, issueDateValidityOffsetInDays } = this;

    if (!(issueDate && issueDateValidityOffsetInDays)) return null;

    const expirationDate = addDays(issueDate, issueDateValidityOffsetInDays);
    const adjustedExpDate = this.timezoneAdjustedExpirationDate(expirationDate);

    const result = subSeconds(adjustedExpDate, 1);

    return isValid(result) ? result : null;
  }

  get expirationDateInTimeZone() {
    return this.formatDateForTimeZone(this.expirationDate);
  }

  get expirationTimeInTimeZone() {
    return this.formatDateForTimeZone(this.expirationDate, 'MMM d, yyyy, h:mm:ss aa (zzzz)');
  }

  get isExpired() {
    const { expirationDate } = this;

    if (!isValid(expirationDate)) return false;

    return isAfter(new Date(), expirationDate);
  }

  // Account for customer being in a time zone (browser time zone) other than
  // the specified time zone (@timezone). Also, Daylight Saving Time.
  timezoneAdjustedExpirationDate(expirationDate) {
    const { timezone } = this.args;

    return timezone ? zonedTimeToUtc(format(expirationDate, DATE_FORMAT), timezone) : expirationDate;
  }

  formatDateForTimeZone(date, dateFormat = 'MMM d, yyyy') {
    const { timezone } = this.args;

    return timezone ? formatInTimeZone(date, timezone, dateFormat) : format(date, dateFormat);
  }

  @action
  onReset() {
    const { onReset, visitorDocument } = this.args;

    onReset?.(visitorDocument);
  }

  @action
  onFileSelected(templateAttachment, file) {
    const { onFileSelected, visitorDocument } = this.args;

    onFileSelected?.(visitorDocument, templateAttachment, file);
  }

  @action
  onFilePreview(templateAttachment, src) {
    const { onFilePreview, visitorDocument } = this.args;

    onFilePreview?.(visitorDocument, templateAttachment, src);
  }

  @action
  onInputFieldChange(inputField) {
    const { onInputFieldChange, visitorDocument } = this.args;

    onInputFieldChange?.(visitorDocument, inputField);
  }
}
