import Model, { attr, belongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('visual-compliance-configuration')
class GlobalVisualComplianceConfiguration extends Model {
  @belongsTo('global-flow') flow;

  // location attrs
  @attr('array', { defaultValue: () => [] }) contactIds;
  @attr('string') secno;
  @attr('string') password;
  @attr('boolean', { defaultValue: true }) credentialsInvalid;

  // flow attrs
  @attr('array', { defaultValue: () => [] }) fieldsToCheck;
}

export default GlobalVisualComplianceConfiguration;
