import Component from '@glimmer/component';
import { set, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import Changeset from 'ember-changeset';
import { next } from '@ember/runloop';

const DEFAULT_VALIDITY_OFFSET_IN_DAYS = 7;

/**
 * @param {object} templateConfiguration - user-document-template-configuration instance linked to location or flow
 * @param {boolean} isDisabled - disables inputs
 */
export class FlowsTestTrackingBase extends Component {
  @service flashMessages;

  @tracked confirmationModalToShow;
  @tracked templateConfigurationChangeset;
  @tracked issueDateValidityOffsetInDays;

  changeset = null;
  defaultValidityOffsetInDays = DEFAULT_VALIDITY_OFFSET_IN_DAYS;
  rollbackProperties = ['active'];

  constructor() {
    super(...arguments);
    this.templateConfigurationChangeset = this._setupTemplateConfigurationChangeset();
    this._updateOffset();
  }

  @action
  _updateOffset() {
    // need a local value of issueDateValidityOffsetInDays, because Input will mut the value
    // and in saveTask we're setting the same property again
    this.issueDateValidityOffsetInDays = this.templateConfigurationChangeset?.issueDateValidityOffsetInDays;
  }

  _setupTemplateConfigurationChangeset() {
    const { templateConfiguration } = this.args;

    if (!templateConfiguration) return null;

    this.changeset = this.changeset ?? new Changeset(templateConfiguration);

    return this.changeset;
  }

  get isValidValidityOffsetInDays() {
    if (!this.templateConfigurationChangeset) return true;

    const { issueDateValidityOffsetInDays } = this.templateConfigurationChangeset;

    return issueDateValidityOffsetInDays && issueDateValidityOffsetInDays > 0;
  }

  get isInputDisabled() {
    return this.saveTask.isRunning || this.args.isDisabled;
  }

  @action
  toggleStatus() {
    if (!this.templateConfigurationChangeset) return;

    if (this.templateConfigurationChangeset.active) {
      this.confirmationModalToShow = 'disable';
    } else {
      this.confirmationModalToShow = 'enable';
    }
  }

  @action
  cancel() {
    this.confirmationModalToShow = null;

    if (!this.templateConfigurationChangeset) return;

    this.templateConfigurationChangeset.rollback();
  }

  @action
  configurationActive() {
    const { templateConfigurationChangeset } = this;

    if (!templateConfigurationChangeset) return;
    let newState = templateConfigurationChangeset.active;

    switch (this.confirmationModalToShow) {
      case 'enable':
        newState = true;
        break;
      case 'disable':
        newState = false;
        break;
    }

    set(templateConfigurationChangeset, 'active', newState);
    this.saveTask.perform();
  }

  @action
  save() {
    // on the blur event we need to use runloop to avoid 'You attempted to update `isRunning` on `<Task:saveTask>`'
    // because after the blur event fires, this.isInputDisabled will affect the input state again
    next(() => {
      this.saveTask.perform();
    });
  }

  @dropTask
  *saveTask() {
    const { templateConfigurationChangeset, defaultValidityOffsetInDays } = this;

    if (typeof templateConfigurationChangeset.save !== 'function') return;

    set(
      templateConfigurationChangeset,
      'issueDateValidityOffsetInDays',
      parseInt(`${this.issueDateValidityOffsetInDays ?? defaultValidityOffsetInDays}`, 10)
    );

    if (!this.isValidValidityOffsetInDays) {
      templateConfigurationChangeset.rollbackProperty('issueDateValidityOffsetInDays').execute();
      return;
    }

    try {
      // Since save task may be triggered by input blur, do not want to trigger save by "tabbing"
      // around inputs on the page. Do not call .save() if no dirty attributes detected.
      if (templateConfigurationChangeset.isDirty) {
        yield templateConfigurationChangeset.save();
        this.args.flow?.syncActiveUserDocumentTemplateConfigurations?.();
        this.flashMessages.showAndHideFlash('success', 'Saved!');
      }
    } catch {
      this.flashMessages.showAndHideFlash('error', 'Error saving changes.');
      const changeset = templateConfigurationChangeset.unexecute();
      this.rollbackProperties.forEach((prop) => changeset.rollbackProperty(prop));
    } finally {
      this.confirmationModalToShow = null;
    }
  }
}
