import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';

export default class extends Route {
  @service abilities;
  @service store;
  @service infinity;

  model() {
    let groups = [];

    if (this.abilities.can('manage locations-grouping')) {
      groups = this.store.findAll('group');
    }

    return {
      groups,
    };
  }

  @action
  loading(transition, _originRoute) {
    const controller = this.controllerFor('location-overview/sign-in-flows/index');
    set(controller, 'loadingData', true);
    transition.promise.finally(function () {
      set(controller, 'loadingData', false);
    });
  }
}
