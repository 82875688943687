import config from 'garaje/config/environment';
import $ from 'jquery';

const testing = config.environment === 'test' || config.isCypress;

export default function () {
  if (testing) {
    return;
  }

  this.transition(
    this.includingInitialRender(),
    this.hasClass('init-toLeft-transition'),
    this.use('toLeft', { duration: 300 })
  );

  this.transition(this.hasClass('toLeft-transition'), this.use('toLeft', { duration: 300 }));

  this.transition(this.hasClass('animate-if-vertical'), this.toValue(true), this.use('toDown'), this.reverse('toUp'));

  this.transition(this.hasClass('animate-fade-300'), this.use('fade', { duration: 300 }));

  this.transition(this.hasClass('animate-fade-300-with-autofocus'), this.use('fadeWithAutofocus', { duration: 300 }));

  this.transition(
    this.hasClass('liquid-disabled-location'),
    this.toModel(false),
    this.use('toUp'),
    this.reverse('toDown')
  );

  this.transition(this.hasClass('password-field'), this.toModel(true), this.use('toDown'), this.reverse('toUp'));

  this.transition(this.hasClass('config-box'), this.use('toDown'), this.reverse('toUp'));

  this.transition(
    this.fromValue('login/login-wizard/email-search'),
    this.use('toLeft', { duration: 100 }),
    this.reverse('toRight', { duration: 100 })
  );

  this.transition(
    this.hasClass('login-details'),
    this.toValue(true),
    this.use(
      'with-callback',
      'toLeft',
      (c) => {
        $(c.newElement).find('input').focus();
      },
      {
        duration: 200,
      }
    ),
    this.reverse(
      'with-callback',
      'toRight',
      (c) => {
        $(c.newElement).find('input').focus();
      },
      {
        duration: 200,
      }
    )
  );

  this.transition(this.hasClass('email-sidebar'), this.toValue(true), this.use('toLeft'), this.reverse('toRight'));
  this.transition(
    this.hasClass('sidebar sidebar--right'),
    this.toValue(true),
    this.use('toLeft'),
    this.reverse('toRight')
  );
  this.transition(
    this.hasClass('sidebar sidebar--left'),
    this.toValue(true),
    this.use('toRight'),
    this.reverse('toLeft')
  );
  this.transition(
    this.hasClass('slide-from-right-to-left'),
    this.toValue(true),
    this.use('toLeft'),
    this.reverse('toRight')
  );
}
