import { parse } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

const DATE_FNS_YYYY_MM_DD: string = 'yyyy-MM-dd';

const modifyDateInTimeZone = function (
  date: Date,
  tz: string,
  fn: (d: Date, opts?: { [key: string]: unknown }) => Date,
  options?: { [key: string]: unknown }
): Date {
  const zonedDate: Date = utcToZonedTime(date, tz);
  const modifiedDate: Date = options ? fn(zonedDate, options) : fn(zonedDate);

  return zonedTimeToUtc(modifiedDate, tz);
};

const parseYyyyMmDdInTimeZone = function (
  yyyyMmDdStr: string, // e.g. 2018-10-04 => October 4, 2018
  date: Date,
  timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone
): Date {
  const zonedDate: Date = utcToZonedTime(date, timezone);
  const parsedDate: Date = parse(yyyyMmDdStr, DATE_FNS_YYYY_MM_DD, zonedDate);

  return zonedTimeToUtc(parsedDate, timezone);
};

export { modifyDateInTimeZone, parseYyyyMmDdInTimeZone, DATE_FNS_YYYY_MM_DD };
