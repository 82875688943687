import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AttendanceRoute extends Route {
  @service abilities;
  @service state;
  @service router;

  title = 'Attendance · Analytics · Envoy';

  queryParams = {
    view: {
      refreshModel: true,
      defaultValue: 'overview',
    },
  };

  beforeModel() {
    if (this.abilities.cannot('visit-attendance analytic')) {
      this.router.transitionTo('unauthorized');
    }
  }

  model(params) {
    const view = params.view;
    return { view };
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.view = model.view;
  }
}
