import type ArrayProxy from '@ember/array/proxy';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type FlowModel from 'garaje/models/flow';
import type CurrentAdminService from 'garaje/services/current-admin';
import type SessionService from 'garaje/services/session';
import type StateService from 'garaje/services/state';
import type StatsigService from 'garaje/services/statsig';

export interface KioskDemoStep {
  imageSrc: string;
  imageAlt: string;
  infoComponentName: string;
}

// Types for each page within the provider
export interface KioskDemoPage {
  currentStep: number;
  totalStepCount: number;
  isUserLoggedIn: boolean;
  isDemoInstance: boolean;
  firstFlowId?: string;
  demoName: string;
}

export default class KioskDemo extends Component<{
  stepArray: Array<KioskDemoStep>;
  startingStep?: number;
  showModal: boolean;
  isDemoInstance: boolean;
  demoName: string;
  onExternalClose?: () => void;
  onComplete?: () => void;
}> {
  @service declare currentAdmin: CurrentAdminService;
  @service declare session: SessionService;
  @service declare statsig: StatsigService;
  @service declare state: StateService;

  @tracked currentStep: number = this.args.startingStep || 0;
  @tracked totalStepCount: number = this.args.stepArray.length;

  @tracked isSharing = false;
  @tracked showModal = true;

  @tracked visitorTypes: ArrayProxy<FlowModel> | undefined;

  get isNextStep(): boolean {
    return this.currentStep + 1 < this.totalStepCount;
  }

  get isPreviousStep(): boolean {
    return this.currentStep >= 1;
  }

  get isLastStep(): boolean {
    return this.currentStep + 1 === this.totalStepCount;
  }

  get progressBarStyle(): string {
    const percentFilled = ((this.currentStep + 1) / this.totalStepCount) * 100;
    return `width: ${percentFilled}%;`;
  }

  get isUserLoggedIn(): boolean {
    return this.session?.isAuthenticated;
  }

  get isDemoInstance(): boolean {
    return this.args.isDemoInstance;
  }

  get canSendAnalytics(): boolean {
    return this.session?.isAuthenticated && !this.args.isDemoInstance;
  }

  get firstFlowId(): string | null {
    if (this.isUserLoggedIn) {
      const flowId = this.state?.currentLocation?.flows?.firstObject?.id;

      return flowId || null;
    }
    return null;
  }

  @action
  onNextStep(): void {
    if (this.currentStep + 1 < this.totalStepCount) {
      if (this.canSendAnalytics) {
        this.statsig.logEvent(`kiosk-demo-${this.args.demoName}-step-advanced`, (this.currentStep + 1).toString());
      }
      this.currentStep = this.currentStep + 1;
    }

    if (this.currentStep + 1 === this.totalStepCount && this.args.onComplete) {
      if (this.canSendAnalytics) {
        this.statsig.logEvent(`kiosk-demo-${this.args.demoName}-last-step-reached`);
      }
      this.args.onComplete();
    }
  }

  @action
  onPreviousStep(): void {
    if (this.currentStep >= 1) {
      if (this.canSendAnalytics) {
        this.statsig.logEvent(`kiosk-demo-${this.args.demoName}-step-backwards`, (this.currentStep - 1).toString());
      }
      this.currentStep = this.currentStep - 1;
    }
  }

  @action
  toggleShowModal(): void {
    if (this.canSendAnalytics) {
      this.statsig.logEvent(`kiosk-demo-${this.args.demoName}-modal-closed`);
    }
    if (this.args.onExternalClose) {
      this.args.onExternalClose();
    }
    this.showModal = !this.showModal;
  }

  @action
  toggleShareModal(): void {
    this.isSharing = !this.isSharing;
  }
}
