import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { equal, or } from '@ember/object/computed';
import { dropTask } from 'ember-concurrency';
import { reject } from 'rsvp';

/**
 * @param {Object}                        translationLanguagesAvailable
 * @param {Object}                        translationEnabledLocales
 * @param {Object}                        defaultLocale
 * @param {Object}                        bccChangeset
 * @param {String}                        flowName
 * @param {Class<Flow>}                   flow
 * @param {Class<Agreement>}              model
 * @param {Boolean}                       isAppUpdateRequired
 * @param {Object}                        ndaChangeset
 * @param {Object}                        signInFields
 * @param {Object}                        tracking
 * @param {Class<Subscription>}           vrSubscription
 * @param {Boolean}                       disabled
 * @param {Boolean}                       isSaved
 */
export default class LegalDocument extends Component {
  @service router;
  @service metrics;
  @service flashMessages;
  @service featureFlags;

  @tracked editingEmail = false;

  @equal('router.currentRouteName', 'visitors.settings.visitor-types.flow.legal-documents.edit')
  onEditLegalDocumentRoute;
  @equal('router.currentRouteName', 'visitors.settings.visitor-types.flow.legal-documents.new') onNewLegalDocumentRoute;
  @or('onEditLegalDocumentRoute', 'onNewLegalDocumentRoute') onNewOrEditLegalDocumentRoute;

  @action
  trackLegalDocument(agreement) {
    const { jobId, eventName } = this.args.tracking || {};
    if (eventName) {
      let buttonText = 'save document';
      if (eventName.match(/Previewed/) !== null) {
        buttonText = 'preview';
      }
      const duration = get(agreement, 'duration');
      this.metrics.trackEvent(eventName, {
        allow_decline_signing: get(agreement, 'optional'),
        button_text: buttonText,
        document_name: get(agreement, 'name'),
        document_character_count: get(agreement, 'body').length,
        email_signed_document: get(agreement, 'bccEmail'),
        job_id: jobId || null,
        legal_doc_id: get(agreement, 'id'),
        resign_for_every_visit: duration === null,
        resign_required_after: duration && duration > 0 ? duration : null,
        video_url: get(agreement, 'videoUrl'),
      });
      if (eventName.match('Add Legal Document') !== null) {
        set(this.args.tracking, 'eventName', 'Legal Docs - Legal Document Edited');
      }
    }
  }

  @dropTask
  *updateAndSaveTask(model, field = null, value = null, propagable = true) {
    if (field) {
      set(model, field, value);
    }

    try {
      yield model.save({ propagable });
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      let message = 'Server error. Please try again.';

      if (e.isAdapterError) {
        message = e.errors.mapBy('detail').join(', ');
      }

      this.flashMessages.showFlash('error', message);

      /*
       Make task finish in an err state, this is useful if you are
       using the task as a promise in code like the following:

         task.perform().then((succ) => { }, (err) => { });

       For more info read https://ember-concurrency.com/docs/error-vs-cancelation/
       */
      yield reject();
    }
  }
}
