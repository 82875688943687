import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';

export default class VirtualFrontDeskRoute extends Route {
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare currentAdmin: CurrentAdminService;

  title = 'Virtual Front Desk';

  async beforeModel(): Promise<void> {
    if (!this.currentAdmin.isGlobalAdmin && !this.currentAdmin.isLocationAdmin) {
      void this.router.transitionTo('unauthorized');
      return;
    }

    const vfdConfiguration = await this.state.currentLocation.getVfdConfiguration();
    if (!vfdConfiguration?.enabled) {
      void this.router.transitionTo('virtual-front-desk.landing-page');
    }
  }
}
