import Component from '@glimmer/component';
import { action } from '@ember/object';
import { loadGoogleMaps } from 'garaje/utils/script-loader';

/**
 * @param {String}              value
 * @param {Function}            onSelect
 */
export default class AddressInput extends Component {
  autocomplete;
  element;

  constructor() {
    super(...arguments);
    this.installAutocomplete();
  }

  @action
  handleInsert(el) {
    this.element = el;
  }

  installAutocomplete() {
    loadGoogleMaps().then(() => {
      if (!window.google) {
        return;
      }

      this.autocomplete = new window.google.maps.places.Autocomplete(this.element, { types: ['geocode'] });

      window.google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
        this.args.onSelect(this.autocomplete.getPlace().formatted_address);
      });
    });
  }

  willDestroy() {
    super.willDestroy(...arguments);
    if (this.autocomplete) {
      this.autocomplete.unbindAll();
    }
  }
}
