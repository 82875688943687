import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import { all, task } from 'ember-concurrency';
import _countBy from 'lodash/countBy';
import { filter, mapBy, unique } from 'macro-decorators';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

/**
 * @param {ApprovalStatus}  approvalStatus
 * @param {Task}            approveTask
 * @param {Task}            denyTask
 * @param {Boolean}         isBulkReview
 * @param {Boolean}         canReview
 */
export default class ApprovalReview extends Component {
  @service flashMessages;
  @service store;

  @filter('uniqueBlocklistFilterIdsFromReports', Boolean) blocklistFiltersIdsThatMatched;
  @mapBy('args.approvalStatus.blocklistReport', 'blacklistFilterId') blocklistFilterIdsFromReports;
  @mapBy('currentBlocklistFilterReports', 'blacklistKeyword') currentBlocklistFilterMatchedValues;
  @unique('blocklistFilterIdsFromReports') uniqueBlocklistFilterIdsFromReports;

  @tracked blocklistFilters;
  @tracked currentBlocklistFilterIndex;

  constructor() {
    super(...arguments);
    this.blocklistFilters = [];
    this.currentBlocklistFilterIndex = 0;
    this.loadBlocklistFiltersTask.perform();
  }

  get currentBlocklistFilter() {
    return this.prioritizedBlocklistFilters[this.currentBlocklistFilterIndex];
  }

  get prioritizedBlocklistFilters() {
    // if filter A has more reports than filter B - then filter A comes first
    const count = this.blocklistFilterReportCounts;
    return this.blocklistFilters.sort((a, b) => count[b.id] - count[a.id]);
  }

  get blocklistFilterReportCounts() {
    return _countBy(this.blocklistFilterIdsFromReports);
  }

  get currentBlocklistFilterReports() {
    return this.args.approvalStatus.blocklistReport.filter(
      (report) => parseInt(report.blacklistFilterId) === parseInt(this.currentBlocklistFilter.id)
    );
  }

  @task
  *loadBlocklistFiltersTask() {
    try {
      const blocklistFilters = yield all(
        this.blocklistFiltersIdsThatMatched.map((id) => this.store.findRecord('blacklist-filter', id))
      );
      set(this, 'blocklistFilters', blocklistFilters);
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error', parseErrorForDisplay(e));
    }
  }
}
