import { isPresent, isEmpty } from '@ember/utils';
import { next } from '@ember/runloop';
import { dependentKeyCompat } from '@ember/object/compat';
import _xor from 'lodash/xor';
import { dedupeTracked, cached } from 'tracked-toolbox';

/**
 * Changing relationships on ED Model does not update `hasDirtyAttributes` property.
 * This decorator is a workaround for that.
 *
 * @returns {*}
 */
export default function (Class) {
  const DecoratedClass = class extends Class {
    /**
     * This array contains the persisted relationships.
     * Updated when Model initialized or invoked save
     */
    @dedupeTracked _storedRelationships = [];

    /**
     * When the Model class is initialized, we store the current relationships
     * using `next` to do this in a separate run loop
     */
    constructor() {
      super(...arguments);
      next(() => this._storeRelationships());
    }

    /**
     * Extending the save function to update the stored relationship
     * after successful save
     * @public
     * @method save
     * @returns Promise
     */
    save() {
      const promise = super.save(...arguments);

      promise.then(
        () => this._storeRelationships(),
        () => super.save(...arguments)
      );
      return promise;
    }

    /**
     * `dependentKeyCompat` for exposing this getter to Ember's classic computed property and observer systems,
     * so they can watch it for changes
     *
     * If the Model is in isDestroyed or isDestroying state, this returns false to stop checking for relationship changes
     * If the Model is in isNew state, we return true (same as `hasDirtyAttributes`)
     *
     * @returns Boolean
     */
    @cached
    @dependentKeyCompat
    get hasDirtyRelationships() {
      const { isDestroyed, isDestroying, isNew, _storedRelationships } = this;

      if (isDestroyed || isDestroying) return false;
      if (isNew || isEmpty(_storedRelationships)) return true;

      /**
       * Populating this array with relationships to trigger recompute on this getter
       * when a relationship is changed
       * e.g. [this.locations, this.company, this.user]
       */
      const _relationshipReferences = [];

      /**
       * Populating this array with objects that contains
       * the current relationships by
       * - name: String
       * - kind: String<'belongsTo' || 'hasMany'>
       * - state: Object
       */
      const _currentState = [];

      this.eachRelationship((name, { kind }) => {
        _relationshipReferences.push(this[name]);

        _currentState.push({ name, kind, state: this._getState(name, kind) });
      });

      /**
       * Compare current relationships to the stored relationships,
       * if the relationship type is:
       * - `belongsTo`: we do a simple not-eq check
       * - `hasMany`: we do a xor check, to eliminate ids order
       *  - ['1', '2', '3'] should be eq to ['2', '3', '1'], this should not be dirty
       *  - ['2', '3'] or [] should be different than ['1'] or []
       */
      const isDirty = _currentState.any(({ kind, state, name: relationshipName }) => {
        const storedRelationship = _storedRelationships.find(({ name }) => name === relationshipName);

        // if current relationship is not in the stored relationships array, return true as dirty
        if (!storedRelationship) return true;

        // if the remoteType on relationship ref returned 'link', compare the links
        if (state?.type === 'link') {
          return storedRelationship.state?.value !== state?.value;
        }

        // type is null, because the ref type is 'id' or 'ids'
        return kind === 'belongsTo'
          ? storedRelationship.state?.value !== state?.value
          : isPresent(_xor(storedRelationship.state?.value, state?.value));
      });

      return isDirty;
    }

    /**
     * This function stores the persisted relationships
     * Called during initialization or after save
     * @private
     * @method _storeRelationships
     */
    _storeRelationships() {
      this._storedRelationships = [];
      if (this.isDestroyed || this.isDestroying) return;

      this.eachRelationship((name, { kind }) => {
        this._storedRelationships = [...this._storedRelationships, { name, kind, state: this._getState(name, kind) }];
      });
    }

    /**
     * This method is for getting the reference value and type
     * type is `null` if the relationship reference `remoteType` is 'id' or 'ids'
     * type is `link` if the relationship reference `remoteType` is 'link'
     *
     * @param {String} name relationship name
     * @param {String} kind relationship type - hasMany || belongsTo
     * @private
     * @returns Object
     * @method _getState
     */
    _getState(name, kind) {
      let state;

      if (kind === 'hasMany') {
        const hasManyRef = this.hasMany(name);
        if (!hasManyRef.value()) return { type: null, value: null };

        if (hasManyRef.remoteType() === 'ids') {
          state = {
            type: null,
            value: hasManyRef.ids(),
          };
        } else if (hasManyRef.remoteType() === 'link') {
          state = {
            type: 'link',
            value: hasManyRef.link(),
          };
        }
      }

      if (kind === 'belongsTo') {
        const belongsToRef = this.belongsTo(name);

        if (belongsToRef.remoteType() === 'id') {
          state = {
            type: null,
            value: belongsToRef.id(),
          };
        } else if (belongsToRef.remoteType() === 'link') {
          state = {
            type: 'link',
            value: belongsToRef.link(),
          };
        }

        if (!state?.value) {
          state = {
            type: null,
            value: null,
          };
        }
      }

      return state;
    }
  };

  // reassign the original class name as the name of the decorated class
  Object.defineProperty(DecoratedClass, 'name', { value: Class.name });

  return DecoratedClass;
}
