import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class VisitorsSettingsSignOutRoute extends Route {
  @service abilities;
  @service state;
  @service('global-setting-batch') globalSettingBatchService;
  @service transitionConfirm;

  titleToken = 'Self sign-out';

  model() {
    const { currentLocation, vrSubscription } = this.state;
    const config = get(currentLocation, 'config');

    if (this.abilities.can('propagate global-setting-batch')) {
      const globalSettingBatchService = this.globalSettingBatchService;
      globalSettingBatchService.createGlobalSettingBatch({ parent: currentLocation });
    }

    return hash({ config, currentLocation, vrSubscription });
  }

  @routeEvent
  routeWillChange(transition) {
    const controller = this.controller;
    const { model } = controller;
    const { currentLocation } = model;
    // no need to display confirmation modal when nothing is changed
    if (!currentLocation.hasDirtyAttributes) {
      return;
    }
    // display confirmation modal if settings are dirty
    this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue() {
        if (currentLocation.hasDirtyAttributes) {
          currentLocation.rollbackAttributes();
        }
      },
    });
  }
}
