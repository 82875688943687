import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import tenantInviteChangeset from 'garaje/changesets/connection-request-invite';
import type TenantConnectionRequestModel from 'garaje/models/tenant-connection-request';
import type ZoneModel from 'garaje/models/zone';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { getStatusCode } from 'garaje/utils/parse-error';

interface InviteFormArgs {
  /**
   * tenant model
   */
  tenantConnectionRequest: TenantConnectionRequestModel;
  /**
   * zone model of property
   */
  property: ZoneModel;
  /**
   * closure to call when close button is clicked
   */
  onClose?: () => unknown;
  /**
   * closure to call when invite is sent up
   */
  onSave?: () => unknown;
  /**
   * render skip button instead of cancel button
   */
  showSkip?: boolean;
}

export default class PropertySettingsTenantsInviteFormComponent extends Component<InviteFormArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare store: Store;
  @tracked inviteChangeset: ReturnType<typeof tenantInviteChangeset>;
  @tracked emailError?: string;

  get isDisabled(): boolean {
    return this.inviteChangeset.isInvalid || this.inviteChangeset.isPristine || this.inviteTask.isRunning;
  }

  constructor(owner: unknown, args: InviteFormArgs) {
    super(owner, args);

    this.inviteChangeset = tenantInviteChangeset(
      this.store.createRecord('connection-request-invite', {
        tenantConnectionRequest: this.args.tenantConnectionRequest,
      })
    );
  }

  inviteTask = task(async () => {
    this.emailError = undefined;

    await this.inviteChangeset.validate();
    if (this.inviteChangeset.isInvalid) return;

    try {
      await this.inviteChangeset.save();

      this.flashMessages.showAndHideFlash('success', 'Invitation sent');

      this.args.onSave?.();
      this.args.onClose?.();
    } catch (e) {
      const error = parseErrorForDisplay(e);

      if (Number(getStatusCode(e)) === 422) {
        // show BE error as error for email
        this.emailError = error;
      } else {
        //otherwise toast
        this.flashMessages.showAndHideFlash('error', error);
      }
    }
  });

  willDestroy(...args: Parameters<Component['willDestroy']>): void {
    super.willDestroy(...args);
    // clean up

    if (!!this.inviteChangeset.isNew && !this.inviteChangeset.isDestroying) {
      void this.inviteChangeset._content.rollbackAttributes();
    }
  }
}
