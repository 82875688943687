import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';

export default class CostEstimateDetailsComponent extends Component {
  @tracked plan = this.args.plan;

  @tracked totalPrice = null;

  @tracked periodStr = () => {
    switch (this.plan.planPeriod) {
      case 'monthly':
        return 'mo';
      case 'yearly':
        return 'yr';
      case 'quarterly':
        return 'qr';
      default:
        return '';
    }
  };

  @tracked renewsOrCancels = null;
  @tracked renewalOrCancelledDateStr = null;
  @tracked licenseOrLicenses = this.args.plan.quantity === 1 ? 'license' : 'licenses';

  constructor() {
    super(...arguments);
    this.checkRenewOrCancelled();
  }

  checkRenewOrCancelled = () => {
    if (this.plan.nextBillingAt) {
      this.renewsOrCancels = 'Renews';
      this.renewOrCancelledDateStr = moment(this.plan.nextBillingAt).format('MMM DD, YYYY');
    } else if (this.plan.cancelledAt) {
      this.renewsOrCancels = 'Cancels';
      this.renewOrCancelledDateStr = moment(this.plan.cancelledAt).format('MMM DD, YYYY');
    }
  };
}
