import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type DS from 'ember-data';
import type { GetCompanyQuery, GetRoomsLocationWithConfigsQuery } from 'garaje/graphql/generated/roomba-types';
import type PluginModel from 'garaje/models/plugin';
import type FeatureConfigService from 'garaje/services/feature-config';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import type RoombaMetricsService from 'garaje/services/roomba-metrics';
import type StateService from 'garaje/services/state';
import { SLACK_V2_PLUGIN_KEY, MSTEAMS_V2_PLUGIN_KEY } from 'garaje/utils/enums';
import urlBuilder from 'garaje/utils/url-builder';

const ACCEPTABLE_CHAT_APP_PLUGINS = [SLACK_V2_PLUGIN_KEY, MSTEAMS_V2_PLUGIN_KEY];

interface RoombaOnsiteRoomRecaptureComponentArgs {
  company: GetCompanyQuery['company'];
  location: GetRoomsLocationWithConfigsQuery['location'];
  plugins: DS.RecordArray<PluginModel>;
}

export default class RoombaSettingsOnsiteRoomRecaptureComponent extends Component<RoombaOnsiteRoomRecaptureComponentArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare roombaGraphql: RoombaGraphqlService;
  @service declare roombaMetrics: RoombaMetricsService;
  @service declare state: StateService;
  @service declare featureConfig: FeatureConfigService;

  @tracked isDialogOpen = false;
  @tracked isOnsiteRoomRecaptureDisabled: boolean;
  @tracked disabledMessage?: string;
  @tracked disabledMessageLink?: string;

  constructor(owner: unknown, args: RoombaOnsiteRoomRecaptureComponentArgs) {
    // eslint-disable-next-line prefer-rest-params
    super(owner, args);
    const { isOnsiteRoomRecaptureDisabled, disabledMessage, disabledMessageLink } = this.initializeDisabledState();
    this.disabledMessage = disabledMessage;
    this.isOnsiteRoomRecaptureDisabled = isOnsiteRoomRecaptureDisabled;
    this.disabledMessageLink = disabledMessageLink;
  }

  @action
  openDialog(): void {
    this.isDialogOpen = true;
  }

  @action
  closeDialog(): void {
    this.isDialogOpen = false;
  }

  initializeDisabledState(): {
    isOnsiteRoomRecaptureDisabled: boolean;
    disabledMessage?: string;
    disabledMessageLink?: string;
  } {
    const { location, plugins } = this.args;

    if (!this.featureConfig.isEnabled('rooms')) {
      throw new Error('No valid rooms subscription in state');
    }

    if (!location.onsiteRoomRecaptureEnabled && !this.featureConfig.isEnabled('roomMeetings.onSiteRoomRecapture')) {
      return {
        disabledMessage: 'Upgrade to the Standard Plan to access this feature.',
        isOnsiteRoomRecaptureDisabled: true,
        disabledMessageLink: urlBuilder.billingDashboardUrl(),
      };
    }

    if (!this.state.currentCompany) {
      throw new Error('No valid current company in state');
    }
    const isChatAppInstalled = plugins.any((plugin: PluginModel) => ACCEPTABLE_CHAT_APP_PLUGINS.includes(plugin.key));
    if (!location.onsiteRoomRecaptureEnabled && !isChatAppInstalled) {
      return {
        isOnsiteRoomRecaptureDisabled: true,
        disabledMessage: 'Install the Slack or Microsoft Teams app to enable this feature.',
        disabledMessageLink: urlBuilder.appStoreUrl(),
      };
    }

    if (!this.state.currentLocation) {
      throw new Error('No valid current location in state');
    }

    const isProtectEnabled = this.state.currentLocation.isProtectSetup;

    if (!location.onsiteRoomRecaptureEnabled && !isProtectEnabled) {
      return {
        disabledMessage:
          'Onsite room recapture requires Envoy Protect to track which employees have signed in for the day. Upgrade your Envoy Visitors subscription to include Envoy Protect.',
        isOnsiteRoomRecaptureDisabled: true,
        disabledMessageLink: urlBuilder.billingDashboardUrl(),
      };
    }

    return { disabledMessage: undefined, disabledMessageLink: undefined, isOnsiteRoomRecaptureDisabled: false };
  }

  /**
   * @task `toggleOnsiteRoomRecaptureLocationEnabledTask`
   */
  toggleOnsiteRoomRecaptureLocationEnabledTask = task({ drop: true }, async () => {
    const { company, location } = this.args;
    try {
      const onsiteRoomRecaptureEnabledSetting = !location.onsiteRoomRecaptureEnabled;
      this.roombaMetrics.trackEvent(
        `ROOMS:onsite_room_recapture_${onsiteRoomRecaptureEnabledSetting ? 'enabled' : 'disabled'}`,
        {
          location_id: location.id,
          company_id: company.id,
        }
      );

      await this.roombaGraphql.updateRoomsLocationOnsiteRoomRecaptureEnabledOverride(
        location.id,
        onsiteRoomRecaptureEnabledSetting
      );
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error saving location settings');
    }
    this.closeDialog();
  });

  /**
   * @task `toggleOnsiteRoomRecaptureCompanyEnabledTask`
   */
  toggleOnsiteRoomRecaptureCompanyEnabledTask = task({ drop: true }, async () => {
    const { company, location } = this.args;
    try {
      const onsiteRoomRecaptureEnabledSetting = !location.onsiteRoomRecaptureEnabled;
      this.roombaMetrics.trackEvent(
        `ROOMS:onsite_room_recapture_company_${onsiteRoomRecaptureEnabledSetting ? 'enabled' : 'disabled'}`,
        {
          location_id: location.id,
          company_id: company.id,
        }
      );

      await this.roombaGraphql.updateRoomsCompanyOnsiteRoomRecaptureEnabled(
        company.id,
        onsiteRoomRecaptureEnabledSetting
      );
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error saving company setting');
    }
    this.closeDialog();
  });
}
