/* eslint-disable ember/no-computed-properties-in-native-classes */
import { readOnly } from '@ember/object/computed';
import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';

export default class GlobalCommunicationAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;
  @service declare featureFlags: FeatureFlagsService;

  @readOnly('state.workplaceSubscription') workplaceSubscription!: StateService['workplaceSubscription'];
  @readOnly('state.visitorsSubscription') visitorsSubscription!: StateService['visitorsSubscription'];
  @readOnly('state.emnoSubscription') emnoSubscription!: StateService['emnoSubscription'];

  // TODO: Incorporate FC instead here
  get canVisit(): boolean {
    return this.canSeeFeatures && this.featureFlags.isEnabled('emergency-notifications-templates-garaje');
  }

  get canSeeFeatures(): boolean {
    if (!this.currentAdmin.isGlobalAdmin) {
      return false;
    }

    return canUseEmergencyNotifications({
      workplaceSubscription: this.workplaceSubscription,
      visitorsSubscription: this.visitorsSubscription,
      emnoSubscription: this.emnoSubscription,
      emNoRolloutFeatureFlag: this.featureFlags.isEnabled('emergency-notification-garaje'),
      emNoAllowlistFeatureFlag: this.featureFlags.isEnabled('emergency-notification-allowlist'),
    });
  }

  get canEditFeatures(): boolean {
    return this.canSeeFeatures;
  }
}

/**
 * @param emNoRolloutFeatureFlag Usually: `this.featureFlags.isEnabled('emergency-notification-garaje')`
 * @param emNoAllowlistFeatureFlag Usually: `this.featureFlags.isEnabled('emergency-notifcation-allowlist')`
 */
export function canUseEmergencyNotifications({
  workplaceSubscription,
  visitorsSubscription,
  emnoSubscription,
  emNoRolloutFeatureFlag,
  emNoAllowlistFeatureFlag,
}: {
  workplaceSubscription: StateService['workplaceSubscription'];
  visitorsSubscription: StateService['visitorsSubscription'];
  emnoSubscription: StateService['emnoSubscription'];
  emNoRolloutFeatureFlag: boolean;
  emNoAllowlistFeatureFlag: boolean;
}): boolean {
  return (
    ((workplaceSubscription?.canAccessEmergencyNotifications ||
      visitorsSubscription?.canAccessEmergencyNotifications ||
      emnoSubscription?.canAccessEmergencyNotifications) &&
      emNoRolloutFeatureFlag) ||
    emNoAllowlistFeatureFlag
  );
}
