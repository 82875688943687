import ApplicationSerializer from './application';

export default class InviteSerializer extends ApplicationSerializer {
  attrs = {
    createdAt: {
      serialize: false,
    },
    creator: {
      serialize: false,
    },
    visitorDocuments: {
      serialize: false,
    },
    locationNames: {
      serialize: false,
    },
    approvalStatus: {
      serialize: false,
    },
  };
}
