import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default class VisitorsSettingsVisitorTypesFlowVisitorPhotosRoute extends Route {
  @service state;

  model() {
    const { flow, vrSubscription } = this.modelFor('visitors.settings.visitor-types.flow');
    const photoPage = get(flow, 'photoPage');
    const badge = get(flow, 'badge');
    const { currentLocation } = this.state;
    const config = get(currentLocation, 'config');

    return hash({ config, flow, badge, photoPage, vrSubscription });
  }
}
