import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import type FlowModel from 'garaje/models/flow';
import type LocationModel from 'garaje/models/location';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('visual-compliance-configuration')
class VisualComplianceConfigurationModel extends Model {
  @belongsTo('location') declare location: AsyncBelongsTo<LocationModel>;
  @belongsTo('flow') declare flow: AsyncBelongsTo<FlowModel>;

  // location attrs
  @attr('array', { defaultValue: () => [] }) declare contactIds: number[];
  @attr('string') declare secno: string;
  @attr('string') declare password: string;
  @attr('boolean', { defaultValue: true }) declare credentialsInvalid: boolean;

  // flow attrs
  @attr('array', { defaultValue: () => [] }) declare fieldsToCheck: string[];
}

export default VisualComplianceConfigurationModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'visual-compliance-configuration': VisualComplianceConfigurationModel;
  }
}
