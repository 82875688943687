import Route from '@ember/routing/route';
import { hash } from 'rsvp';

export default class LocationOverviewSignInFlowsShowPlusOneRoute extends Route {
  model() {
    const { globalFlow } = this.modelFor('location-overview.sign-in-flows.show');

    return hash({ flow: globalFlow });
  }
}
