import { DeliveryMethod } from './delivery-method';

export interface TemplateCategory {
  id: string;
  name: string;
  templates: ThinTemplate[];
}

export interface ThinTemplate {
  id: string;
  name: string;
}

export interface Template {
  deliveryMethods: DeliveryMethod[];
  visitorGroup?: AudienceType;
  employeeGroup?: AudienceType;
  title: string;
  message: string;
  markAsSafe: boolean;
}

export interface Audiences {
  employees: Audience[];
  visitors: Audience[];
}

export interface Audience {
  id: AudienceType;
  count: number;
}

export interface SentMessage {
  id: string;
  sentCount: number;
}

export interface Location {
  id: string;
  name: string;
}

export enum AudienceType {
  Self = 'SELF',
  AllEmployeesWithReservation = 'ALL_EMPLOYEES_WITH_RESERVATION',
  AllEmployeesCheckedIn = 'ALL_EMPLOYEES_CHECKED_IN',
  AllEmployeesAtLocation = 'ALL_EMPLOYEES_AT_LOCATION',
  AllEmployeesAtDefaultLocation = 'ALL_EMPLOYEES_AT_DEFAULT_LOCATION',
  AllVisitorsCheckedIn = 'ALL_VISITORS_CHECKED_IN',
  AllVisitorsWithReservation = 'ALL_VISITORS_WITH_RESERVATION',
}

export interface MessageInput {
  locationId: string;
  title: string;
  message: string;
  deliveryMethods: DeliveryMethod[];
  employeeGroup?: AudienceType;
  visitorGroup?: AudienceType;
  critical: boolean;
  markAsSafe: boolean;
}

export interface ICommunicationDataAccess {
  getTemplateCategories(locationId: string): Promise<TemplateCategory[]>;
  getTemplate(locationId: string, templateId: string): Promise<Template>;
  getAudiences(locationId: string): Promise<Audiences>;
  getLocations(): Promise<Location[]>;
  sendMessage(input: MessageInput): Promise<SentMessage>;
}
