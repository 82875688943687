import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type AbilitiesService from 'ember-can/services/abilities';
import type EmptyStatePageService from 'garaje/services/empty-state-page';
import type StateService from 'garaje/services/state';
import type StatsigService from 'garaje/services/statsig';

export default class VisitorsSingleEmployee extends Component {
  @service declare router: RouterService;
  @service declare statsig: StatsigService;
  @service declare emptyStatePage: EmptyStatePageService;
  @service declare abilities: AbilitiesService;
  @service declare state: StateService;

  get hasAccessToAddIntegrations(): boolean {
    return this.abilities.can('visit settings');
  }

  get isGlobalOverview(): boolean {
    return this.router.currentRouteName?.includes('location-overview');
  }

  @action
  pageViewEvent(): void {
    this.statsig.logEvent('add-employee-empty-page-viewed');
  }

  @action
  addEmployee(): void {
    this.statsig.logEvent('add-employee-empty-page-add-clicked');
    this.emptyStatePage.closeEmployeeDirectoryEmptyPage();
    const routeName = this.isGlobalOverview ? 'location-overview.employees.new' : 'employees.new';
    this.router.transitionTo(routeName).catch(() => null);
  }

  @action
  routeToDirectory(): void {
    this.statsig.logEvent('add-employee-empty-page-import-clicked');
    this.router
      .transitionTo('employees.import')
      .then(() => this.emptyStatePage.closeEmployeeDirectoryEmptyPage())
      .catch(() => null);
  }

  @action
  routeToIntegrations(): void {
    this.statsig.logEvent('add-employee-empty-page-directory-sync-clicked');
    const routeName = this.isGlobalOverview ? 'location-overview.employees.integrations' : 'employees.integrations';
    this.router
      .transitionTo(routeName)
      .then(() => this.emptyStatePage.closeEmployeeDirectoryEmptyPage())
      .catch(() => null);
  }
}
