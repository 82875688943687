import Component from '@glimmer/component';
import { action, get, set } from '@ember/object';
import { filter } from 'macro-decorators';
import _uniqWith from 'lodash/uniqWith';
import { STORE_RESPONSE_CONFIG_OPTIONS, SIGN_IN_FIELD_KINDS } from 'garaje/utils/enums';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { localCopy } from 'tracked-toolbox';

const configurableFieldsFilterFn = (field) => {
  return get(field, 'identifier') !== 'host' && get(field, 'kind') === SIGN_IN_FIELD_KINDS.SINGLE_SELECTION;
};

/**
 * @param {Function}           onClose              Required. Pass through of onClose function
 * @param {Array<SignInField>} signInFields         Required. Sign in fields
 * @param {Task}               saveFieldsTask       Required. Pass through of task to save fields
 * @param {Flow}               flow                 Required. Used to read storeResponseConfig
 * @param {Boolean}            defaultStoreResponse Required. Used to fallback if storeResponseConfig isn't set on the flow
 */
export default class SignInFieldsResponseOptionsModalComponent extends Component {
  @service store;
  @tracked isPristine = true;

  @localCopy('args.signInFields') signInFields;
  @localCopy('args.flow') flow;
  @localCopy('args.defaultStoreResponse') defaultStoreResponse;

  @filter('signInFields', configurableFieldsFilterFn)
  configurableDropdownFields;

  @action
  storeField(field) {
    if (!get(field, 'storeResponse')) {
      this.isPristine = false;
    }

    set(field, 'storeResponse', true);
  }

  @action
  discardField(field) {
    if (get(field, 'storeResponse')) {
      this.isPristine = false;
    }

    set(field, 'storeResponse', false);
  }

  @action
  handleClose() {
    this.rollbackResponseOptions();
    this.flow.rollbackAttributes();
    this.args.onClose();
  }

  @action
  handleStorageTypeChange(storageType) {
    if (storageType !== this.flow.storeResponseConfig) {
      this.isPristine = false;
    }

    this.flow.storeResponseConfig = storageType;
    this.rollbackResponseOptions();
  }

  @task
  *saveResponseOptionsTask() {
    yield this.flow.save();

    if (this.flow.storeResponseConfig === STORE_RESPONSE_CONFIG_OPTIONS.MANAGED) {
      yield this.args.saveFieldsTask.perform();
    } else {
      this.updateSignInFieldsInStore(this.flow.storeResponseConfig === STORE_RESPONSE_CONFIG_OPTIONS.STORED);
    }
  }

  rollbackResponseOptions() {
    const filteredSignInFields = this.signInFields.toArray().filter(configurableFieldsFilterFn);
    for (const signInField of filteredSignInFields) {
      signInField.rollbackAttributes();
    }
  }

  @action
  updateSignInFieldsInStore(storeResponse) {
    const filteredSignInFields = this.signInFields.toArray().filter(configurableFieldsFilterFn);
    for (const signInField of filteredSignInFields) {
      this.store.push(
        {
          data: {
            id: get(signInField, 'id'),
            type: get(signInField, 'constructor.modelName'),
            attributes: {
              storeResponse,
            },
          },
        },
        true
      );
    }
  }
}
