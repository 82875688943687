import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action, get } from '@ember/object';
import { all, defer } from 'rsvp';
import { dropTask } from 'ember-concurrency';
import ticketCategoryEmailChangeset from 'garaje/changesets/ticket-category-email';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

/**
 * @param {Class<Location>} location       Required - Location model
 */
export default class ProtectTicketingComponent extends Component {
  @service store;
  @service metrics;
  @service flashMessages;
  @service workplaceMetrics;

  @tracked isOpen = false;
  @tracked newTicketEmail;
  @tracked ticketConfiguration;
  @tracked ticketCategoryEmailChangesets = [];
  @tracked newTicketCategoryEmailChangesets = [];

  get saveButtonEnabled() {
    const allValid = this.allEmailChangesets.every((changeset) => changeset.isValid);
    const anyDirtyOrDeleted = this.allEmailChangesets.some(
      (changeset) => changeset.isDirty || changeset.data.isDeleted
    );
    return allValid && anyDirtyOrDeleted;
  }

  get allEmailChangesets() {
    return [...this.ticketCategoryEmailChangesets, ...this.newTicketCategoryEmailChangesets];
  }

  get ticketCategories() {
    return this.ticketConfiguration.ticketCategories.filter((category) => !category.isNew);
  }

  @action
  onDeleteTicketCategory(changesetToDelete) {
    if (changesetToDelete.data.isNew) {
      this.newTicketCategoryEmailChangesets = this.newTicketCategoryEmailChangesets.without(changesetToDelete);
    } else {
      changesetToDelete.data.deleteRecord();
    }
  }

  @action
  addAnotherEmail() {
    const newTicketEmail = this.store.createRecord('ticket-category-email', {
      ticketConfiguration: this.ticketConfiguration,
    });
    this.newTicketCategoryEmailChangesets = [
      ...this.newTicketCategoryEmailChangesets,
      ticketCategoryEmailChangeset(newTicketEmail),
    ];
  }

  @action
  didInsertPanel() {
    this.loadTicketingConfigurationTask.perform();
  }

  @action
  didInsertContent() {
    this.ticketCategoryEmailChangesets = this.ticketConfiguration.ticketCategoryEmails.toArray().map((ticketEmail) => {
      return ticketCategoryEmailChangeset(ticketEmail);
    });
    if (this.ticketCategoryEmailChangesets.length === 0) {
      this.addAnotherEmail();
    }
  }

  @action
  willDestroyContent() {
    this.ticketCategoryEmailChangesets = [];
    this.newTicketCategoryEmailChangesets = [];
    this.ticketConfiguration.ticketCategoryEmails.toArray().forEach((ticketEmail) => {
      if (ticketEmail?.isNew) {
        this.store.unloadRecord(ticketEmail);
      }
    });
  }

  @action
  toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  @action
  edit() {
    this.workplaceMetrics.trackEvent('WORKPLACE_SETTING_EDIT_WORKPLACE_TICKETING_BUTTON_CLICKED');
    if (!this.ticketConfiguration || !this.ticketConfiguration.enabled) {
      this.enableTask.perform();
    } else {
      this.toggleOpen();
    }
  }

  @dropTask
  *deleteTicketCategoryTask(ticketCategory) {
    try {
      const location = this.args.location;
      yield ticketCategory.destroyRecord();
      yield this.flashMessages.showAndHideFlash('success', 'Success!', `"${ticketCategory.name}" category was deleted`);

      this.metrics.trackEvent('Ticketing category deleted', {
        locationId: location.id,
      });
    } catch (error) {
      this.flashMessages.showFlash('error', parseErrorForDisplay(error));
    }
  }

  @dropTask
  *saveEmailsTask() {
    try {
      const changesetsToSave = this.allEmailChangesets.filter(
        (changeset) => changeset.isDirty || changeset.data.isDeleted
      );

      yield all(changesetsToSave.map((changeset) => changeset.save()));

      yield this.flashMessages.showAndHideFlash('success', 'Success!');

      this.toggleOpen();
    } catch (e) {
      this.flashMessages.showFlash('error', parseErrorForDisplay(e));
    }
  }

  @dropTask
  *enableTask() {
    try {
      const location = this.args.location;
      this.ticketConfiguration =
        this.ticketConfiguration ||
        this.store.createRecord('ticket-configuration', {
          location,
        });
      this.ticketConfiguration.enabled = true;
      yield this.ticketConfiguration.save();
      this.flashMessages.showAndHideFlash('success', 'Ticketing enabled successfully!');

      this.metrics.trackEvent('Ticketing enabled', {
        locationId: location.id,
      });

      this.toggleOpen();
    } catch (e) {
      this.flashMessages.showFlash('error', parseErrorForDisplay(e));
    }
  }

  @dropTask
  *disableTask() {
    try {
      const location = this.args.location;

      this.ticketConfiguration.enabled = false;
      yield this.ticketConfiguration.save();
      this.flashMessages.showAndHideFlash('success', 'Ticketing disabled!');

      this.metrics.trackEvent('Ticketing disabled', {
        locationId: location.id,
      });

      this.toggleOpen();
    } catch (e) {
      this.flashMessages.showFlash('error', parseErrorForDisplay(e));
    }
  }

  @dropTask
  *loadTicketingConfigurationTask() {
    const locationId = this.args.location.id;
    const filter = { location: locationId };
    const response = yield this.store.query('ticket-configuration', {
      filter,
      include: 'ticket-categories,ticket-category-emails.ticket-category',
    });
    this.ticketConfiguration = get(response, 'firstObject');
  }

  @dropTask
  confirmTicketDeletionModalTask = {
    *perform(category) {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = async () => {
        const { deleteTicketCategoryTask, ticketCategoryEmailChangesets, newTicketCategoryEmailChangesets } =
          this.context;

        // Remove the category from the list of ticket categories
        const changesetsToDelete = ticketCategoryEmailChangesets.filter(
          (changeset) => changeset.ticketCategory?.id === category.id
        );

        const newChangesetsToDelete = newTicketCategoryEmailChangesets.filter(
          (changeset) => changeset.ticketCategory?.id === category.id
        );

        await deleteTicketCategoryTask.perform(category);

        changesetsToDelete.forEach((changeset) => {
          this.context.ticketCategoryEmailChangesets = this.context.ticketCategoryEmailChangesets.without(changeset);
        });
        newChangesetsToDelete.forEach((changeset) => {
          this.context.newTicketCategoryEmailChangesets =
            this.context.newTicketCategoryEmailChangesets.without(changeset);
        });

        deferred.resolve(true);
      };

      return yield deferred.promise;
    },
  };

  @dropTask
  showCreateTicketModalTask = {
    *perform() {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = async () => {
        deferred.resolve(true);
      };

      return yield deferred.promise;
    },
  };
}
