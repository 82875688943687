import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { set, action } from '@ember/object';
import { task, dropTask } from 'ember-concurrency';
import { formatMinutesAsTime } from 'garaje/utils/format-minutes-as-time';
import { typeOf } from '@ember/utils';

export default class VisitorsSettingsSignOutController extends Controller {
  @service flashMessages;
  @service metrics;

  @dropTask
  *toggleSelfSignOut(value) {
    const { config } = this.model;

    set(config, 'selfSignOut', value);

    try {
      yield config.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      config.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @task
  *saveTask() {
    const { currentLocation } = this.model;
    if (currentLocation.hasDirtyAttributes === false) {
      return;
    }
    const { autoSignOutAtMinutesSinceMidnight } = currentLocation.changedAttributes();
    const [original, changed] = autoSignOutAtMinutesSinceMidnight;
    let action = original === null ? 'Enabled' : 'Edited';
    action = changed === null ? 'Disabled' : action;
    try {
      yield currentLocation.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this._trackAutoSignOutEvent(action, currentLocation.id, autoSignOutAtMinutesSinceMidnight[1]);
    } catch (e) {
      currentLocation.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @action
  updateAutoSignOutAtMinutesSinceMidnight(mins) {
    this.model.currentLocation.autoSignOutAtMinutesSinceMidnight = mins;
    this.model.currentLocation.autoSignOutAtMidnight = typeOf(mins) === 'number';
  }

  _trackAutoSignOutEvent(action, locationId, autoSignOutAtMinutesSinceMidnight) {
    this.metrics.trackEvent(`Auto Sign Out ${action}`, {
      location_id: locationId,
      sign_out_at:
        autoSignOutAtMinutesSinceMidnight === null ? null : formatMinutesAsTime(autoSignOutAtMinutesSinceMidnight),
    });
  }
}
