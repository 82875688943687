import { Button, FormLayout, Select, SelectOption } from '@envoy/polarwind-react';
import { Analytics, FormCheckbox, FormMultiselect, FormSelect, FormTextField } from '@envoy/react-rich-components-core';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  Audiences,
  MessageInput,
  ICommunicationDataAccess,
  TemplateCategory,
  AudienceType,
  SentMessage,
  Location,
} from './data-access';
import './send-message.css';
import { DeliveryMethod } from './delivery-method';

export interface SendMessageProps {
  dataAccess: ICommunicationDataAccess;
  analytics: Analytics;
  locationId?: string;
  onSent(message: SentMessage): void;
  onError(errorMessage: string, error: unknown): void;
  showEmployeeGroup: boolean;
  showVisitorGroup: boolean;
  createTemplateUrl: string;
}

const maxMessageLength = 320;

const audienceTypeNameMap: Record<AudienceType, string> = {
  [AudienceType.Self]: 'Just you',
  [AudienceType.AllEmployeesCheckedIn]: 'Employees checked in',
  [AudienceType.AllEmployeesWithReservation]: 'Employees with reservations',
  [AudienceType.AllEmployeesAtDefaultLocation]: 'Employees with Primary Location set',
  [AudienceType.AllEmployeesAtLocation]: 'Employees in Employee Directory',
  [AudienceType.AllVisitorsCheckedIn]: 'Visitors checked in',
  [AudienceType.AllVisitorsWithReservation]: 'Visitors invited',
};

interface MessageForm {
  title: string;
  message: string;
  deliveryMethods: DeliveryMethod[];
  employeeGroup: AudienceType | '';
  visitorGroup: AudienceType | '';
  critical: boolean;
  markAsSafe: boolean;
}

export const SendMessage = ({
  dataAccess,
  analytics,
  locationId,
  onSent,
  onError,
  showEmployeeGroup,
  showVisitorGroup,
  createTemplateUrl,
}: SendMessageProps) => {
  const formMethods = useForm<MessageForm>({
    defaultValues: {
      title: '',
      message: '',
      deliveryMethods: [DeliveryMethod.Sms, DeliveryMethod.Email, DeliveryMethod.MobilePush],
      employeeGroup: '',
      visitorGroup: '',
      critical: true,
      markAsSafe: true,
    },
    mode: 'onChange',
  });
  const [templateCategories, setTemplateCategories] = useState<TemplateCategory[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [selectedLocationId, setSelectedLocationId] = useState<string | undefined>(locationId);
  const [locations, setLocations] = useState<Location[]>([]);
  const [audiences, setAudiences] = useState<Audiences>();
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (locationId) {
      return;
    }

    const asyncFn = async () => {
      const returnedLocations = await dataAccess.getLocations();

      setLocations(returnedLocations);
    };

    asyncFn().catch((error) => {
      onError('Could not load locations', error);
    });
  }, [locationId]);

  useEffect(() => {
    if (!selectedLocationId) {
      return;
    }

    const asyncFn = async () => {
      const [returnedTemplateCategories, returnedAudiences] = await Promise.all([
        dataAccess.getTemplateCategories(selectedLocationId),
        dataAccess.getAudiences(selectedLocationId),
      ]);

      setTemplateCategories(returnedTemplateCategories);
      setAudiences(returnedAudiences);
    };

    asyncFn().catch((error) => {
      onError('Could not load data.', error);
    });
  }, [selectedLocationId]);

  const onTemplateSelected = (templateId: string) => {
    setSelectedTemplateId(templateId);
  };

  useEffect(() => {
    if (!selectedTemplateId || !selectedLocationId) {
      return;
    }

    void dataAccess
      .getTemplate(selectedLocationId, selectedTemplateId)
      .then((template) => {
        formMethods.reset({
          ...template,
          visitorGroup: template.visitorGroup ?? '',
          employeeGroup: template.employeeGroup ?? '',
          critical: template.markAsSafe,
        });
      })
      .catch((error) => onError('Could not load template.', error));
  }, [selectedTemplateId, selectedLocationId]);

  const onSubmit = async (data: MessageForm) => {
    try {
      setIsSending(true);

      const sentMessage = await dataAccess.sendMessage({
        ...data,
        employeeGroup: (showEmployeeGroup && data.employeeGroup) || undefined,
        visitorGroup: (showVisitorGroup && data.visitorGroup) || undefined,
        locationId: selectedLocationId!,
        markAsSafe: data.critical && data.markAsSafe,
      });

      analytics.trackAnalytics('COMMUNICATIONS_SEND_MESSAGE_CLICKED', {
        messageId: sentMessage.id,
        locationId: selectedLocationId,
        announcementTemplateId: selectedTemplateId,
        criticalChecked: data.critical,
        markAsSafeChecked: data.markAsSafe,
        employeeGroup: data.employeeGroup,
        visitorGroup: data.visitorGroup,
        deliveryMethods: data.deliveryMethods,
        titleUsed: !!data.title,
      });

      onSent(sentMessage);
    } catch (error) {
      setIsSending(false);

      onError('Could not send message, please try again.', error);
    }
  };

  const templateOptions = useMemo(
    () =>
      templateCategories.map<SelectOption>((category) => ({
        title: category.name,
        options: category.templates.map((template) => ({ label: template.name, value: template.id })),
      })),
    [templateCategories],
  );

  const employeeGroupOptions = useMemo(
    () =>
      (audiences?.employees || []).map<SelectOption>((employeeGroup) => ({
        label: `${audienceTypeNameMap[employeeGroup.id]} (${employeeGroup.count})`,
        value: employeeGroup.id,
      })),
    [audiences],
  );

  const visitorGroupOptions = useMemo(
    () =>
      (audiences?.visitors || []).map<SelectOption>((visitorGroup) => ({
        label: `${audienceTypeNameMap[visitorGroup.id]} (${visitorGroup.count})`,
        value: visitorGroup.id,
      })),
    [audiences],
  );

  const locationOptions = useMemo(
    () => locations.map((location) => ({ label: location.name, value: location.id })),
    [locations],
  );

  const formDisabled = useMemo(() => !locationId && !selectedLocationId, [locationId, selectedLocationId]);

  const [messageValue, criticalChecked] = formMethods.watch(['message', 'critical']);

  /* eslint-disable @typescript-eslint/no-misused-promises */
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <FormLayout>
          {!locationId && (
            <Select
              label="Location to send"
              required={true}
              options={locationOptions}
              onChange={(locId: string) => setSelectedLocationId(locId)}
              preselectValue={false}
              placeholder="Select a location"
              value={selectedLocationId}
            />
          )}
          <Select
            label="Template (optional)"
            options={templateOptions}
            disabled={formDisabled}
            onChange={onTemplateSelected}
            preselectValue={false}
            dropdownClassName="send-message__template-dropdown"
            placeholder="Select a template"
            emptyNode={
              <>
                No templates to choose from.{' '}
                <a className="send-message__link" href={createTemplateUrl}>
                  Create template
                </a>
              </>
            }
          />

          <FormTextField<MessageInput>
            name="title"
            label="Message title (optional)"
            disabled={formDisabled}
            placeholder="e.g. Urgent: Immediate Evacuation Required"
          />
          <div className="send-message__message">
            <FormTextField<MessageInput>
              name="message"
              label="Message"
              required="Write a message to send"
              disabled={formDisabled}
              maxLength={{ value: 320, message: 'Reduce the length of your message' }}
              multiline={6}
              placeholder="Write your message"
            />
            <div className="send-message__message-text">
              <div className="send-message__message-error">{formMethods.formState.errors.message?.message}</div>
              <div className="send-message__message-length">
                {messageValue.length}/{maxMessageLength}
              </div>
            </div>
          </div>
          <FormMultiselect<MessageInput>
            name="deliveryMethods"
            label="Delivery method(s)"
            required="You must select at least 1 delivery method"
            disabled={formDisabled}
            options={[
              {
                label: 'SMS',
                value: DeliveryMethod.Sms,
              },
              {
                label: 'Email',
                value: DeliveryMethod.Email,
              },
              {
                label: 'Push Notification',
                value: DeliveryMethod.MobilePush,
              },
            ]}
            placeholder="Select how to deliver this message"
          />
          {showEmployeeGroup && (
            <FormSelect<MessageInput>
              name="employeeGroup"
              label="Employee group"
              required="You must select an employee group to receive this message"
              disabled={formDisabled}
              options={employeeGroupOptions}
              preselectValue={false}
              placeholder="Select who should receive this message"
            />
          )}
          {showVisitorGroup && (
            <FormSelect<MessageInput>
              name="visitorGroup"
              label="Visitor group"
              required={!showEmployeeGroup ? 'You must select a visitor group to receive this message' : undefined}
              disabled={formDisabled}
              options={visitorGroupOptions}
              preselectValue={false}
              placeholder="Select who should receive this message"
            />
          )}
          <FormCheckbox<MessageInput> name="critical" label="Critical or priority message" disabled={formDisabled} />
          {criticalChecked && (
            <div className="send-message__mark-as-safe">
              <FormCheckbox<MessageInput>
                name="markAsSafe"
                label="Ask recipients to mark themselves as safe"
                disabled={formDisabled}
              />
            </div>
          )}
          <Button disabled={formDisabled || isSending}>{isSending ? 'Sending' : 'Send now'}</Button>
        </FormLayout>
      </form>
    </FormProvider>
  );
};
