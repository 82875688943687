import { service } from '@ember/service';
import { Ability } from 'ember-can';
import { isProductDisabled } from 'garaje/helpers/product-disabled';
import type AuthzService from 'garaje/services/authz';
import type StateService from 'garaje/services/state';
import { Permission } from 'garaje/utils/ui-permissions';

export default class OccupancyGlobalAnalyticAbility extends Ability {
  @service declare state: StateService;
  @service declare authz: AuthzService;

  get canVisit(): boolean {
    if (isProductDisabled('occupancy')) {
      return false;
    }

    const canAccessOccupancyGlobalAnalytics = Boolean(this.state.workplaceSubscription?.canAccessOccupancyAnalytics);

    return (
      canAccessOccupancyGlobalAnalytics &&
      this.authz.hasPermissionAtCurrentCompany(Permission.ANALYTICS_GLOBAL_OCCUPANCY_DASH_READ)
    );
  }
}
