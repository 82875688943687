import Route from '@ember/routing/route';
import { action } from '@ember/object';
import RSVP from 'rsvp';
import { service } from '@ember/service';
import { IPAD_LAST_SEEN_THRESHOLD } from 'garaje/utils/decorators/device-status';

const SORT_COL_TO_FIELD_NAME = {
  'iPad type': 'deviceType',
  Location: 'location.name',
  'Wi-Fi network': 'wifiNetwork',
  'iOS version': 'osVersion',
  'Visitors version': 'appVersion',
};

const ORDER_DESC_VALS = ['Z-A', 'Oldest to newest'];

const PAGE_SIZE = 25;

export default class LocationOverviewGlobalDevicesIpadsRoute extends Route {
  @service abilities;
  @service router;
  @service skinnyLocations;
  @service store;

  queryParams = {
    sortBy: {
      refreshModel: true,
    },
    sortDirection: {
      refreshModel: true,
    },
    sortOrder: {
      refreshModel: true,
    },
    locationId: {
      refreshModel: true,
    },
    wifiNetwork: {
      refreshModel: true,
    },
    deviceType: {
      refreshModel: true,
    },
    pageNumber: {
      refreshModel: true,
    },
    status: {
      refreshModel: true,
    },
  };

  beforeModel() {
    if (!this.abilities.can('read global-devices')) {
      this.router.transitionTo('unauthorized');
    }
  }

  model({ sortBy, sortDirection, locationId, wifiNetwork, deviceType, status, pageNumber }) {
    const params = { filter: {} };
    const iPadThresholdSeconds = Math.floor(IPAD_LAST_SEEN_THRESHOLD / 1000);

    if (locationId) {
      params.filter.location = locationId;
    } else {
      const locations = this.skinnyLocations.readableByCurrentAdmin;
      params.filter.location = locations.mapBy('id');
    }

    if (status === 'operational') {
      params.filter['heartbeat-seconds-ago'] = `>${iPadThresholdSeconds}`;
    }

    if (status === 'offline') {
      params.filter['heartbeat-seconds-ago'] = `<${iPadThresholdSeconds}`;
    }

    if (wifiNetwork) {
      params.filter['wifi-network'] = wifiNetwork;
    }

    if (deviceType) {
      // This query param has to be wrapped an array.
      // Given values like `iPad4,5` and `iPad5,3`, the backend
      // will interpret as an array `['iPad4', '5']` without the quotes
      params.filter['device-type'] = [deviceType];
    }

    const orderDescending = ORDER_DESC_VALS.includes(sortDirection);
    const sortOrder = orderDescending ? '-' : '';
    const sortProp = SORT_COL_TO_FIELD_NAME[sortBy];

    if (sortBy) {
      params.sort = `${sortOrder}${sortProp}`;
    }

    const offset = PAGE_SIZE * pageNumber - PAGE_SIZE;

    params.page = {
      offset,
      limit: PAGE_SIZE,
    };

    params.include = 'location,printer';

    const iPads = this.store.query('device', params);

    return RSVP.hash({
      iPads,
    });
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
