import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type LocationModel from 'garaje/models/location';
import type MailerTemplateModel from 'garaje/models/mailer-template';
import type StateService from 'garaje/services/state';
import { hash } from 'rsvp';

export interface SettingsEmailsRouteModel {
  currentLocation: LocationModel;
  mailerTemplates: ArrayProxy<MailerTemplateModel>;
}

export default class SettingsEmailsRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  titleToken = 'Emails';

  beforeModel(): void {
    if (this.abilities.cannot('visit settings')) {
      void this.router.transitionTo('dashboard');
    } else if (this.abilities.cannot('customize email visitor-emails')) {
      void this.router.transitionTo('visitors.settings');
    }
  }

  model(): Promise<SettingsEmailsRouteModel> {
    const { currentLocation } = this.state;
    const mailerTemplates = this.store.findAll('mailer-template');

    return hash({
      currentLocation,
      mailerTemplates,
    });
  }
}
