import Component from '@glimmer/component';
import { action } from '@ember/object';
import { get } from '@ember/object';

export default class EmailSidebarMailerSectionComponent extends Component {
  overrideFor(mailerSectionBlock) {
    const { mailerSectionBlockOverrides } = this.args;
    return mailerSectionBlockOverrides.findBy('mailerSectionBlock.id', mailerSectionBlock.id);
  }

  get mailerSectionBlockOverrides() {
    const { mailerSection } = this.args;
    return get(mailerSection, 'mailerSectionBlocks')
      .map((mailerSectionBlock) => this.overrideFor(mailerSectionBlock))
      .compact();
  }

  @action
  reorderSection(mailerSectionBlockOverrides) {
    mailerSectionBlockOverrides.forEach(
      (mailerSectionBlockOverride, position) => (mailerSectionBlockOverride.position = position)
    );
    this.args.onUpdate();
  }
}
