import Component from '@glimmer/component';
import { service } from '@ember/service';
import { get } from '@ember/object';
import { isBlank } from '@ember/utils';
import { task, timeout } from 'ember-concurrency';
import zft from 'garaje/utils/zero-for-tests';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

/**
 * @param {Array<Object>}       selectedGroup
 * @param {Function}            onChange
 */
export default class EmployeeGroupLiveSearch extends Component {
  @service store;
  @service flashMessages;

  searchEmployeeGroup = task({ restartable: true }, async (displayNameFilter) => {
    if (isBlank(displayNameFilter) || displayNameFilter?.length < 2) {
      return [];
    }

    await timeout(zft(250));

    try {
      let employeeGroups = await this.store.query('employee-group', {
        filter: { fuzzy_display_name: displayNameFilter },
        page: { limit: 10 },
        include: 'employee-group-role',
      });

      employeeGroups = employeeGroups.map((group) => {
        group.disabled = group.employeeGroupRole && get(group.employeeGroupRole, 'id');
        return group;
      });

      return employeeGroups;
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}
