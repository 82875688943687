import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StatsigService from 'garaje/services/statsig';

export default class WelcomeController extends Controller {
  @service declare currentAdmin: CurrentAdminService;
  @service declare statsig: StatsigService;

  @action
  logEvent(product: string): void {
    void this.statsig.logEvent('dashboard_multiproduct_welcome_page', null, { selected_product: product });
  }
}
