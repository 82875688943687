import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';
import { keepLatestTask } from 'ember-concurrency';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import { RoleType, getLegacyRoleName } from 'garaje/utils/custom-roles';

class EmployeesAdminRolesRoute extends Route {
  @service abilities;
  @service skinnyLocations;
  @service router;
  @service store;
  @service visitorsOnboarding;
  @service currentLocation;

  titleToken = 'Admin Roles';

  queryParams = {
    rolesFilter: {
      refreshModel: true,
    },
    nameFilter: {
      refreshModel: true,
    },
    locationsFilter: {
      refreshModel: true,
    },
  };

  beforeModel() {
    if (this.abilities.cannot('visit-local-admins manage-settings')) {
      this.router.transitionTo('unauthorized');
    }
  }

  async model() {
    await this.skinnyLocations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
    this.modelTask.perform(...arguments);

    // performance tweak to improve responsiveness: intentionally avoid returning
    // rsvp hash so route immediately transitions
    return {};
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.modelTask = this.modelTask;
  }

  deactivate() {
    super.deactivate(...arguments);
    this.controller.nameFilter = '';
    this.controller.rolesFilter = [];
    this.controller.locationsFilter = [];
  }

  @keepLatestTask({
    cancelOn: 'deactivate',
  })
  *modelTask({ rolesFilter, locationsFilter, nameFilter }) {
    // required to display scim admins sync banner
    this.currentLocation.loadIntegrations.perform();
    const employeeGroupRoles = this.store.findAll('employee-group-role');

    const filter = {
      name: nameFilter,
    };

    if (!isEmpty(rolesFilter)) {
      filter.roles = rolesFilter
        .map((role) => (role.type === RoleType.CUSTOM ? role.id : getLegacyRoleName(role.name)))
        .join(',');
    }

    if (!isEmpty(locationsFilter)) {
      filter.location = locationsFilter.join(',');
    }

    const userRolesDigest = yield this.store.query('user-roles-digest', { filter });
    userRolesDigest.forEach((digest) => {
      this.store.push({
        data: {
          id: digest.id,
          type: 'user',
          attributes: {
            email: digest.email,
            fullName: digest.fullName,
            avatar: digest.avatar,
            userPhotoUrl: digest.userPhotoUrl,
          },
        },
      });
    });

    return { userRolesDigest, employeeGroupRoles };
  }

  @action
  refreshModel() {
    const params = this.paramsFor(this.routeName);
    this.modelTask.perform(params);

    if (this.visitorsOnboarding.showVideoWalkthrough) {
      this.visitorsOnboarding.loadUserRolesTask.perform();
    }
  }
}

export default EmployeesAdminRolesRoute;
