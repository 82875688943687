import Route from '@ember/routing/route';
import { action, get } from '@ember/object';
import { hash } from 'rsvp';
import { service } from '@ember/service';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class VisitorsSettingsVisitorTypesFlowLegalDocumentsNewRoute extends Route {
  @service router;
  @service store;
  @service transitionConfirm;

  model() {
    const { agreementPage, flow, signInFields, vrSubscription } = this.modelFor(
      'visitors.settings.visitor-types.flow.legal-documents'
    );
    const agreement = this.store.createRecord('agreement', { agreementPage });
    const { ipads } = this.modelFor('visitors.settings');
    const { tracking } = this.modelFor('visitors.settings.visitor-types.flow.legal-documents');

    return hash({ agreement, flow, ipads, signInFields, tracking, vrSubscription });
  }

  redirect({ flow }) {
    if (!flow.canEdit) {
      this.router.transitionTo('unauthorized');
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.setupChangeset();
  }

  @routeEvent
  routeWillChange(transition) {
    const agreementChangeset = this.controller.changeset;
    const { agreement } = this.controller.model;
    const confirmTask = this.transitionConfirm.displayConfirmTask;

    if (get(agreementChangeset, 'isDirty')) {
      // This confirmTask is for confirming whether the user wants to discard unsaved changes
      // when transitioning away from the edit form.
      confirmTask.perform(transition, {
        continue() {
          agreementChangeset.rollback();
        },
      });
    } else if (get(agreement, 'isNew')) {
      // If there are no changes and the agreement is unsaved, we destroy the record when
      // the user navigates away.
      agreement.destroyRecord();
    }
  }
}
