import Model, { attr, belongsTo } from '@ember-data/model';
import type InvoiceEstimateModel from 'garaje/models/invoice-estimate';
import adapter from 'garaje/utils/decorators/adapter';

interface Discount {
  amount: number;
  description: string;
}

@adapter('billing-v1')
class EstimateLineItemModel extends Model {
  @belongsTo('invoice-estimate', { async: false }) declare invoiceEstimate: InvoiceEstimateModel;

  @attr('number') declare amount: number;
  @attr('string') declare description: string;
  @attr('number') declare discountAmount: number;
  @attr('string') declare entityType: string;
  @attr('number') declare quantity: number;
  @attr('number') declare unitAmount: number;
  @attr('date') declare nextBillingAt: Date;
  @attr('date') declare cancelledAt: Date;
  @attr('string') declare planPeriod: string;
  @attr('array', { defaultValue: () => [] }) declare discounts: Discount[];
}

export default EstimateLineItemModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'estimate-line-item': EstimateLineItemModel;
  }
}
