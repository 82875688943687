import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action, get, set } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import flow from '@prosperstack/flow';

export default class ProductPlansIndexController extends Controller {
  queryParams = ['viaLabel', 'viaObject'];

  @tracked isEditingCC = false;
  @tracked showRoomsDowngradeWarningModal = false;
  @tracked upgradeApp = null;
  @tracked upgradePlan = null;
  viaLabel = null;
  viaObject = null;

  @service flashMessages;
  @service metrics;

  @action
  handleRoomsDowngrade() {
    if (this.model.exceedsRoomsBasicLimit) {
      this.showRoomsDowngradeWarningModal = true;
    } else {
      this.transitionToRoute('billing.product-plans.index.downgrade', 'rooms', 'basic', {
        queryParams: { downgradeViaLabel: 'Downgrade to Basic', downgradeViaObject: 'button' },
      });
      this.showRoomsDowngradeWarningModal = false;
    }
  }

  @action
  handleVisitorsDowngrade() {
    this.transitionToRoute('billing.product-plans.index.downgrade', 'visitors', 'basic', {
      queryParams: { downgradeViaLabel: 'Downgrade to Basic →', downgradeViaObject: 'text_link' },
    });
  }

  @action
  onClosePaymentMethodModal() {
    this.isEditingCC = false;
    this.upgradeApp = null;
    this.upgradePlan = null;
  }

  @action
  onUpgrade(upgradeApp, upgradePlan) {
    this.upgradeApp = upgradeApp;
    this.upgradePlan = upgradePlan;

    if (this.model.paymentSource.exists) {
      this.transitionToUpgrade();
    } else {
      this.isEditingCC = true;
    }
  }

  @action
  transitionToRooms() {
    this.transitionToRoute('roomba');
  }

  @action
  transitionToUpgrade() {
    this.transitionToRoute('billing.product-plans.upgrade', this.upgradeApp, this.upgradePlan);
  }

  @action
  prosperStack(requestType = undefined) {
    const {
      payloadOptions: { payloadCancelRequest, payloadDowngradeRequest },
      signatureOptions: { signatureDowngrade, signatureCancel },
    } = this.model.subscription.flowPayload;

    let selectedPayload;
    let selectedSignature;
    if (requestType === 'downgrade') {
      selectedPayload = payloadDowngradeRequest;
      selectedSignature = signatureDowngrade;
    } else if (requestType === 'cancel') {
      selectedPayload = payloadCancelRequest;
      selectedSignature = signatureCancel;
    }

    return flow(
      { payload: selectedPayload, signature: selectedSignature },
      {
        onCompleted: (result) => {
          const { status: prosperStackResult } = result;
          switch (prosperStackResult) {
            case 'canceled': {
              this.flashMessages.showFlash(
                'Sorry to see you go!',
                'You will receive a confirmation when your cancellation request has been processed. Please note that all changes will go into effect at the end of your current billing period.'
              );
              break;
            }
            case 'saved': {
              this.flashMessages.showFlash(
                'Thank you!',
                'You will be notified as soon as your account has been updated.'
              );
              break;
            }
            case 'incomplete':
            default: {
              break;
            }
          }
        },
      }
    );
  }

  @dropTask
  *cancelDowngradeTask() {
    try {
      const oldDowngradePlan = get(this.model.subscription, 'downgradePlan.normalizedPlanLevel');
      set(this.model.subscription, 'downgradePlan', null);
      yield this.model.subscription.save();
      const flashMessageTitle = 'Downgrade canceled';
      this.flashMessages.showAndHideFlash('success', flashMessageTitle);

      this.metrics.trackEvent('Account modified', {
        product: this.model.subscription.app,
        account_change_type: 'subscription',
        account_change_object: 'plan',
        account_change_action: 'cancel_downgrade',
        object_change_from: this.model.subscription.normalizedPlanName,
        object_change_to: oldDowngradePlan,
      });
      this.metrics.trackEvent(`Viewed Flash Message`, {
        type: 'success',
        message_title: flashMessageTitle,
        message_code: 'cancel_downgrade_success',
        message_code_type: 'cancel_downgrade',
      });
      this.transitionToRoute('billing.index');
    } catch (e) {
      this.model.subscription.rollbackAttributes();
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
      this.metrics.trackEvent(`Viewed Flash Message`, {
        type: 'error',
        message_title: parseErrorForDisplay(e),
        message_code: 'cancel_downgrade_error',
        message_code_type: 'cancel_downgrade',
      });
    }
  }
}
