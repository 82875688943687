import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import _chunk from 'lodash/chunk';
import _compact from 'lodash/compact';
import { inject as service } from '@ember/service';
import { dropTask, all } from 'ember-concurrency';

export default class DeliveriesSettingsNicknamesController extends Controller {
  @service featureFlags;
  @service store;

  @tracked recipients;
  @tracked refreshModel;
  @tracked addingNewNickname;

  // To date we cannot retreive a list of employees
  // with aliases, group by employee.
  //
  // Instead we have to manually build the list
  // of recipients with aliases and associate them after
  //
  // We also need to manually query individual employees instead of relying on
  // `coalesceFindRequests`, because deliveries returns nicknames for deleted employees
  // https://github.com/emberjs/data/issues/4463
  @dropTask
  *buildEmployeeRecipients({ aliases }) {
    const employees = yield all(
      aliases.map((alias) =>
        this.store
          .query('employee', { filter: { id: alias.belongsTo('recipientEmployee').id() } })
          .then((items) => items.firstObject)
      )
    );
    const recipients = _compact(employees).uniq();
    this.recipients = recipients;
  }
}
