import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { type GetCompanyQuery } from 'garaje/graphql/generated/roomba-types';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import type RoombaMetricsService from 'garaje/services/roomba-metrics';

export default class RoombaSettingsCancelRecurringMeeting extends Component<{ company: GetCompanyQuery['company'] }> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @service declare flashMessages: FlashMessagesService;
  @service declare roombaGraphql: RoombaGraphqlService;
  @service declare roombaMetrics: RoombaMetricsService;

  toggleCancelRecurringMeetingTask = task({ drop: true }, async () => {
    const { company } = this.args;

    try {
      const cancelRecurringMeetingSetting = !company.cancelUnattendedRecurringMeeting;
      this.roombaMetrics.trackEvent(
        `cancel_recurring_meeting_${cancelRecurringMeetingSetting ? 'enabled' : 'disabled'}`,
        {
          company_id: company.id,
        }
      );
      await this.roombaGraphql.updateCompanyConfig({
        cancelUnattendedRecurringMeeting: cancelRecurringMeetingSetting,
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      this.flashMessages.showAndHideFlash('error', 'Error saving settings changes');
    }
  });
}
