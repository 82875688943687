/* eslint-disable ember/no-computed-properties-in-native-classes */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { readOnly } from '@ember/object/computed';
import { A } from '@ember/array';
import { action, computed, get, setProperties, set } from '@ember/object';
import { isEmpty, isPresent } from '@ember/utils';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import moment from 'moment-timezone';
import { timeout, task, dropTask, restartableTask } from 'ember-concurrency';
import zft from 'garaje/utils/zero-for-tests';
import { pluralize } from 'ember-inflector';
import urlBuilder from 'garaje/utils/url-builder';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import Papa from 'papaparse';
import { v4 as uuid } from 'uuid';
import { localCopy } from 'tracked-toolbox';
import { PURPOSE_OF_VISIT } from 'garaje/utils/enums';
import { guidFor } from '@ember/object/internals';
import DropdownOption from 'garaje/models/dropdown-option';

// In other places, `@ember/string/htmlSafe` is sufficient,
// but because we are forwarding these strings to another
// javascript library, we need to physically escape the string
const escapeHTML = (unsafeStr) => {
  return unsafeStr
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/\"/g, '&quot;')
    .replace(/\'/g, '&#39;')
    .replace(/\./g, '&#46;');
};

const DATE_FORMAT = 'MM/DD/YYYY';
const TIME_FORMAT = 'h:mm a';
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

const textValidator = function (text, cb) {
  cb(this.allowEmpty || text ? text.length > 0 : false);
};

const positiveIntegerValidator = function (value, cb) {
  const number = Number(value);

  if (number > -1 && Math.floor(number) === number) {
    cb(true);
  } else {
    cb(false);
  }
};

const STATIC_COL_HEADERS = ['Arrival date (MM/DD/YYYY)', 'Arrival time', 'Full name', 'Visitor email address'];

// We use functions instead of static lists here because the columns and date validator both rely on
// handsontable, which is passed in as a component property and therefore not available until
// the component is actually rendered.
const createDateValidator = (handsontable) => {
  return function (date, cb) {
    handsontable.validators.DateValidator.call(this, date, (isValid) => {
      if (!isValid) {
        cb(false);
        return;
      }

      const mDate = moment(date, DATE_FORMAT);
      cb(mDate.isSameOrAfter(new Date(), 'day'));
    });
  };
};

const createStaticColumns = (handsontable, isEmailRequired = false) => {
  return [
    {
      allowEmpty: false,
      correctFormat: true,
      csvHeader: 'expected_arrival_time', // eslint-disable-line camelcase
      data: 'arrivalDate',
      dateFormat: DATE_FORMAT,
      datePickerConfig: {
        minDate: new Date(),
      },
      type: 'date',
      validator: createDateValidator(handsontable),
    },
    {
      allowEmpty: false,
      correctFormat: true,
      csvHeader: 'expected_arrival_time', // eslint-disable-line camelcase
      data: 'arrivalTime',
      timeFormat: TIME_FORMAT,
      type: 'time',
    },
    {
      allowEmpty: false,
      csvHeader: 'invitee_name', // eslint-disable-line camelcase
      data: 'fullName',
      type: 'text',
      validator: textValidator,
    },
    {
      allowEmpty: !isEmailRequired,
      csvHeader: 'invitee_email', // eslint-disable-line camelcase
      data: 'email',
      type: 'text',
      validator(val, cb) {
        if (isBlank(val)) {
          cb(isEmailRequired ? false : true);
        } else {
          cb(/@/.test(val));
        }
      },
    },
  ];
};

/**
 * @param {Changeset<Any>}              changeset
 * @param {Array<Flow>}                 flows
 * @param {Array<Location>}             locations
 * @param {Function}                    onLocationSelectedTask
 * @param {Flow}                        selectedFlow
 * @param {Object}                      handsontable
 * @param {Function}                    onFlowChanged
 * @param {Class<Subscription>}         vrSubscription
 */
export default class SpreadsheetPrereg extends Component {
  @service ajax;
  @service currentLocation;
  @service flashMessages;
  @service featureFlags;
  @service locationFeatureFlags;
  @service metrics;
  @service router;
  @service store;

  @tracked bulkInviteId = null; // Id used to track batches across failed uploads
  @tracked errorRows;
  @tracked flowNameToChangeTo;
  @tracked groupName = '';
  @tracked hasEmail = false;
  @tracked hotInstance;
  @tracked includeGroupNameInEmail = true;
  @tracked notifyVisitor = false;
  @tracked rowLength = 0;
  @tracked selectedLocation;
  @tracked showConfirmVisitorTypeChangeModal = false;
  @tracked showPreregistrationEmailConfirmation = false;
  @tracked staticColumns;

  @localCopy('args.flows', []) flows;

  constructor() {
    super(...arguments);
    this.selectedLocation = this.currentLocation.location;
    this.staticColumns = createStaticColumns(this.args.handsontable, this.isEmailRequired);
  }

  @readOnly('selectedFlow.signInFieldPage.signInFields') signInFields;

  get secondaryLocations() {
    return this.args.changeset.childInviteLocations || [];
  }

  get shouldShowSecondaryLocations() {
    return (
      this.featureFlags.isEnabled('multi-location-group-invites') &&
      this.args.vrSubscription.canAccessMultiLocationInvites &&
      this.args.locations.length > 1
    );
  }

  get locationSelectorId() {
    return `${guidFor(this)}-location-select`;
  }

  get flowSelectorId() {
    return `${guidFor(this)}-flow-select`;
  }

  get secondaryLocationSelectorId() {
    return `${guidFor(this)}-secondary-locations-select`;
  }

  get showMultiLocationToolTip() {
    return !this.selectedFlow?.isGlobalChild && !this.args.isEditInvite;
  }

  @computed(
    'flows.@each.name',
    'purposeOfVisit.value',
    'args.changeset.flowName',
    'state.currentLocation.employeeScreeningFlow.id'
  )
  get selectedFlow() {
    // the flow rel is always correct and might not match the flowName or purpose of visit
    // eslint-disable-next-line ember/use-ember-get-and-set
    const flowId = this.args.changeset.get('flow.id');
    if (!flowId) {
      return null;
    }

    if (this.purposeOfVisit.value === PURPOSE_OF_VISIT.EMPLOYEE_REGISTRATION) {
      return this.state.currentLocation.employeeScreeningFlow;
    }

    return this.flows.findBy('id', flowId);
  }

  @computed('selectedFlow.globalFlow.locations.[]')
  get optionsForSecondaryLocations() {
    if (!this.selectedFlow?.globalFlow) {
      return [];
    }
    const primaryLocationId = this.args.changeset.location.id;
    const locationsRel = get(this.selectedFlow.globalFlow, 'locations');
    if (!locationsRel) return [];

    return locationsRel.then((skinnyLocations) => {
      return skinnyLocations
        .filter((loc) => loc.id !== primaryLocationId)
        .map((location) => this.store.peekRecord('location', location.id));
    });
  }

  @computed('signInFields.[]')
  get customFields() {
    return A(this.signInFields)
      .filter((field) => !['name', 'host', 'email'].includes(field.identifier))
      .sortBy('position');
  }

  @computed('signInFields.[]')
  get hostField() {
    return A(this.signInFields).findBy('identifier', 'host');
  }

  @computed('hostField', 'selectedFlow.additionalGuests', 'customFields.[]')
  get colHeaders() {
    const colHeaders = STATIC_COL_HEADERS.slice();

    const hostField = this.hostField;
    if (hostField) {
      const { name, localized, required } = hostField;
      if (required) {
        colHeaders.push(localized || name);
      } else {
        colHeaders.push(`${localized || name} (optional)`);
      }
    }

    if (this.selectedFlow.additionalGuests) {
      colHeaders.push('Additional visitors');
    }

    this.customFields.forEach((field) => {
      const { name, localized, kind } = field;
      if (kind === 'phone') {
        colHeaders.push('Visitor phone number');
      } else {
        colHeaders.push(localized || name);
      }
    });

    colHeaders.push('Private notes');
    if (this.canSetPropertyNotes) {
      colHeaders.push('Shared notes');
    }
    return colHeaders.map((h) => escapeHTML(h));
  }

  @computed('hostField', 'selectedFlow.additionalGuests', 'customFields.[]')
  get columns() {
    const columns = this.staticColumns.slice();

    const hostField = this.hostField;
    if (hostField) {
      columns.push({
        allowEmpty: !hostField.required,
        csvHeader: 'host_name',
        data: 'host',
        type: 'autocomplete',
        source: (term, cb) => this.searchEmployeesTask.perform(term, cb),
        validator: textValidator,
      });
    }

    if (this.selectedFlow.additionalGuests) {
      columns.push({
        allowEmpty: true,
        csvHeader: 'additional_guests',
        data: 'additionalGuests',
        type: 'text',
        validator: positiveIntegerValidator,
      });
    }

    this.customFields.forEach((field) => {
      const { name, required, kind } = field;
      let options = field.options;
      if (typeof options === 'object' && options.length && options[0] instanceof DropdownOption) {
        options = options.mapBy('value');
      }
      columns.push({
        allowEmpty: !required,
        csvHeader: name,
        data: escapeHTML(name),
        source: kind === 'single-selection' ? options : [],
        type: kind === 'single-selection' ? 'dropdown' : 'text',
      });
    });

    columns.push({
      allowEmpty: true,
      csvHeader: 'private_notes',
      data: 'privateNotes',
      type: 'text',
    });

    if (this.canSetPropertyNotes) {
      columns.push({
        allowEmpty: true,
        csvHeader: 'property_notes',
        data: 'propertyNotes',
        type: 'text',
      });
    }

    return columns;
  }

  @computed('rowLength', 'submit.isRunning')
  get isSubmitDisabled() {
    return this.rowLength === 0 || this.submit.isRunning;
  }

  @computed('rowLength', 'hasEmail', 'submit.isRunning')
  get isSendPreRegEmailDisabled() {
    const hasNoRows = this.rowLength === 0;
    const isRunning = this.submit.isRunning;
    const hasNoEmail = !this.hasEmail;
    return hasNoRows || isRunning || hasNoEmail;
  }

  @computed('args.changeset.{userData.@each.field,flowName}')
  get purposeOfVisit() {
    const flow = this.args.changeset.flow;
    const userData = this.args.changeset.userData || A();
    const flowName = flow?.name || this.args.changeset.flowName;

    return userData.findBy('field', 'Purpose of visit') || { field: 'Purpose of visit', value: flowName };
  }

  get canSetPropertyNotes() {
    return this.args.locationIsConnectedToProperty;
  }

  get emailSignInField() {
    return this.signInFields?.findBy('isEmail');
  }

  get flowOptions() {
    return this.flows.rejectBy('isProtect').sortBy('position');
  }

  get isEmailRequired() {
    if (
      !(
        this.featureFlags.isEnabled('visitors-required-email-field') ||
        this.locationFeatureFlags.isEnabled('visitors-required-email-field-by-location')
      )
    )
      return false;

    return !!this.emailSignInField?.required;
  }

  @(employeesSearcherTask({
    filter: {
      deleted: false,
      excludeHidden: true,
    },
    prefix: true,
  }).restartable())
  _searchEmployeesTask;

  @dropTask
  *submit() {
    this.flashMessages.hideAll();

    const hotInstance = this.hotInstance;

    const headers = this.columns.mapBy('csvHeader').uniq();
    headers.unshift('Purpose of visit');

    const flowName = this.selectedFlow.name;

    const data = hotInstance
      .getData()
      .map((row, i) => {
        if (hotInstance.isEmptyRow(i)) {
          return;
        }

        const additionalGuestsIndex = this.colHeaders.indexOf('Additional visitors');
        if (additionalGuestsIndex > -1 && isBlank(row[additionalGuestsIndex])) {
          row[additionalGuestsIndex] = 0;
        }
        row.unshift(flowName);

        const [flow, date, time, ...rest] = row;

        const dateAndTime = time ? `${date} ${time}` : date;
        let iso8601 = moment(dateAndTime, DATE_TIME_FORMAT);
        iso8601 = iso8601.isValid() ? dateAndTime : '';

        return [flow, iso8601, ...rest];
      })
      .compact();
    const csv = Papa.unparse([headers, ...data]);

    const job_id = uuid();
    const invite_count = data.length;
    const visitor_type = flowName;
    const notify_invitees = this.notifyVisitor;
    this.metrics.trackEvent('Bulk Invite Creation Requested', { invite_count, visitor_type, notify_invitees, job_id });
    const childInviteLocations = this.args.changeset.childInviteLocations.map((location) => {
      return { id: location.id.toString(), type: 'locations' };
    });
    const url = urlBuilder.v3.invites.bulkUrl();
    let result;
    try {
      result = yield this.ajax.request(url, {
        type: 'POST',
        headers: { accept: 'application/vnd.api+json' },
        contentType: 'application/vnd.api+json',
        data: JSON.stringify({
          data: {
            type: 'invites',
            attributes: {
              csv,
              'job-id': job_id,
              'notify-visitor': this.notifyVisitor,
              'group-name': this.groupName,
              'include-group-name-in-email': isPresent(this.groupName) && this.includeGroupNameInEmail,
              'bulk-invite-id': this.bulkInviteId,
            },
            relationships: {
              location: {
                data: {
                  type: 'locations',
                  id: this.selectedLocation.id.toString(),
                },
              },
              'child-invite-locations': {
                data: childInviteLocations,
              },
            },
          },
        }),
      });
    } catch (error) {
      const msg = `Failed to add ${pluralize(data.length, 'invite')}!`;
      this.flashMessages.showAndHideFlash('error', msg, error && error.message ? error.message : '');
      this.metrics.trackEvent('Viewed Flash Message', {
        type: 'error',
        message_title: msg,
        message_codes: ['error_group_invites'],
        job_id,
      });
      return;
    }

    const successRows = result.data.attributes['success-rows'];
    const errorRows = result.data.attributes['error-rows'];
    this.errorRows = errorRows;
    this.bulkInviteId = result.data.attributes['bulk-invite-id'];

    if (successRows.length > 0) {
      const msg = `${pluralize(successRows.length, 'invite')} added!`;
      this.flashMessages.showAndHideFlash('success', msg);
      this.metrics.trackEvent('Viewed Flash Message', {
        type: 'success',
        message_title: msg,
        message_codes: [],
        job_id,
      });
    }

    successRows.reverse().forEach((row) => hotInstance.alter('remove_row', row));

    if (errorRows.length === 0) {
      this.notifyVisitor = false;
      this.groupName = '';
      this.includeGroupNameInEmail = true;
      this.bulkInviteId = null;

      this.args.changeset.rollback();
      const firstInvite = data[0];
      let date = firstInvite[1].split(' ')[0];
      date = moment(date).format('YYYY-MM-DD');
      this.router.transitionTo('visitors.invites.index', { queryParams: { date } });
    }
  }

  @dropTask
  *onRowUpdate(hotInstance) {
    yield timeout(zft(1));
    const nonEmptyRowCount = hotInstance
      .getData()
      .reduce((acc, _, i) => (!hotInstance.isEmptyRow(i) ? acc + 1 : acc), 0);
    this.rowLength = nonEmptyRowCount;
    const changeset = this.args.changeset;
    if (nonEmptyRowCount > 0) {
      set(changeset, '_dirtyMarker', true);
      if (this.pluckEmails(hotInstance).length > 0) {
        this.hasEmail = true;
      } else {
        this.hasEmail = false;
        this.notifyVisitor = false;
      }
    } else {
      changeset.rollback();
      this.hasEmail = false;
      this.notifyVisitor = false;
    }
  }

  pluckEmails(hotInstance) {
    const emailColumnIndex = this.columns.findIndex((c) => c.csvHeader === 'invitee_email');
    const data = hotInstance.getData().reduce((acc, row, i) => {
      if (!hotInstance.isEmptyRow(i) && !isEmpty(row[emailColumnIndex])) {
        acc.pushObject(row[emailColumnIndex]);
      }
      return acc;
    }, []);
    return data;
  }

  @restartableTask
  *searchEmployeesTask(term, cb) {
    const extraFilters = {
      locations: this.selectedLocation.id,
    };
    const employees = yield this._searchEmployeesTask.perform(term, extraFilters);
    cb(employees.mapBy('name'));
  }

  @task
  *onLocationSelected(location) {
    this.selectedLocation = location;
    yield this.args.onLocationSelectedTask.perform(location);
  }

  @action
  confirmPreregistrationEmailYes() {
    this.showPreregistrationEmailConfirmation = false;
    this.notifyVisitor = true;
    this.submit.perform();
  }

  @action
  confirmPreregistrationEmailNo() {
    this.showPreregistrationEmailConfirmation = false;
    this.submit.perform();
  }

  @action
  modifySecondaryLocations(locations) {
    this.args.changeset.childInviteLocations = locations;
  }

  @action
  prepareToSubmit() {
    if (this.notifyVisitor) {
      this.submit.perform();
    } else if (this.hasEmail) {
      this.showPreregistrationEmailConfirmation = true;
    } else {
      this.submit.perform();
    }
  }

  @action
  repairDataFormat(hotInstance, changes) {
    changes.forEach((change) => {
      const [row, prop, _oldValue, newValue] = change;

      if (prop === 'arrivalTime') {
        const timeNoColonRegEx = /^\d{3,4}$/gi;

        if (timeNoColonRegEx.test(`${newValue}`)) {
          const column = hotInstance.propToCol(prop);
          const correctedValue = `${newValue.slice(0, -2)}:${newValue.slice(-2)}`;

          hotInstance.setDataAtCell(row, column, correctedValue, 'arrivalTime');
        }
      }
    });
  }

  @action
  showOrHideConfirmVisitorTypeChangeModal(showOrHide) {
    this.showConfirmVisitorTypeChangeModal = showOrHide;
  }

  @action
  hideConfirmVisitorTypeChangeModalAndClear() {
    this.modifySecondaryLocations(null);
    this.updateFlow(this.flowNameToChangeTo);
    this.showOrHideConfirmVisitorTypeChangeModal(false);
  }

  @action
  updateFlow(flow) {
    // Handles reseting fields for Multi-Location Invite option
    if (!this.showConfirmVisitorTypeChangeModal && this.secondaryLocations.length) {
      this.showOrHideConfirmVisitorTypeChangeModal(true);
      this.flowNameToChangeTo = flow;
      return;
    }
    setProperties(this, { 'purposeOfVisit.value': flow.name, 'args.changeset.flowName': flow.name });
    this.args.changeset.flow = flow;

    this.args.onFlowChanged?.(flow.name, this.args.changeset);
  }
}
