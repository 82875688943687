/* eslint-disable ember/no-computed-properties-in-native-classes */
import { readOnly } from '@ember/object/computed';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, LOCATION_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_VISIT_ROLES = Object.freeze([GLOBAL_ADMIN, LOCATION_ADMIN]);

export default class DbeamAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare featureConfig: FeatureFlagsService;

  @readOnly('state.workplaceSubscription') workplaceSubscription!: StateService['workplaceSubscription'];
  @readOnly('state.visitorsSubscription') visitorsSubscription!: StateService['visitorsSubscription'];

  get canVisit(): boolean {
    const { roleNames: roles } = this.currentAdmin;
    // TODO: Update with feature config and subscription.feature
    return (
      this.canSeeFeatures &&
      (isPresent(_intersection(CAN_VISIT_ROLES, roles)) ||
        this.featureConfig.isEnabled('adminConfig.canViewAllReservations'))
    );
  }

  get canSeeFeatures(): boolean {
    return canUseDbeam({
      workplaceSubscription: this.workplaceSubscription,
      visitorsSubscription: this.visitorsSubscription,
      dbeamEaRolloutFeatureFlag: this.featureFlags.isEnabled('dbeam-ea-rollout'),
      dbeamAllowlistFeatureFlag: this.featureFlags.isEnabled('dbeam-allowlist'),
    });
  }
}

/**
 * @param dbeamEaRolloutFeatureFlag Usually: `this.featureFlags.isEnabled('dbeam-ea-rollout')`
 * @param dbeamAllowlistFeatureFlag Usually: `this.featureFlags.isEnabled('dbeam-allowlist')`
 */
export function canUseDbeam({
  workplaceSubscription,
  visitorsSubscription,
  dbeamEaRolloutFeatureFlag,
  dbeamAllowlistFeatureFlag,
}: {
  workplaceSubscription: StateService['workplaceSubscription'];
  visitorsSubscription: StateService['visitorsSubscription'];
  dbeamEaRolloutFeatureFlag: boolean;
  dbeamAllowlistFeatureFlag: boolean;
}): boolean {
  if ((workplaceSubscription?.isPremiumPlan || workplaceSubscription?.canUseDbeam) && dbeamEaRolloutFeatureFlag) {
    return true;
  }

  return !!visitorsSubscription?.canAccessProtectLegacyFeatures && dbeamAllowlistFeatureFlag;
}
