import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask, restartableTask, timeout } from 'ember-concurrency';
import type ZoneModel from 'garaje/models/zone';
import type FlashMessagesService from 'garaje/services/flash-messages';
import zft from 'garaje/utils/zero-for-tests';
import { defer } from 'rsvp';

import type { ConnectPropertiesIndexRouteModel } from './route';

interface SortOption {
  displayValue: string;
  sortKey: string;
  sortDirection: string;
}

const DEBOUNCE_TIMEOUT = 250;

export default class ConnectPropertiesIndexController extends Controller {
  @service declare flashMessages: FlashMessagesService;

  queryParams = ['query', 'sortKey', 'sortDirection'];

  declare model: ConnectPropertiesIndexRouteModel;

  sortOptions = [
    { displayValue: 'Name (A–Z)', sortKey: 'name', sortDirection: 'asc' },
    { displayValue: 'Name (Z–A)', sortKey: 'name', sortDirection: 'desc' },
  ];

  @tracked total = 0;

  @tracked _query = '';
  @tracked query = '';
  @tracked sortKey = this.sortOptions[0]?.sortKey;
  @tracked sortDirection = this.sortOptions[0]?.sortDirection;

  get sortOption(): SortOption {
    return this.sortOptions.find(
      (sortOption) => sortOption.sortKey === this.sortKey && sortOption.sortDirection === this.sortDirection
    )!;
  }

  get showClearButton(): boolean {
    return this.query !== '';
  }

  @action
  selectSortOption(sortOption: SortOption): void {
    this.sortDirection = sortOption.sortDirection;
    this.sortKey = sortOption.sortKey;
  }

  searchTask = restartableTask(async (query: string) => {
    await timeout(zft(DEBOUNCE_TIMEOUT));
    this.query = query;
  });

  @dropTask
  confirmDeletePropertyTask: {
    perform(): Generator<Promise<unknown>, unknown, unknown>;
  } = {
    *perform(this: { context: ConnectPropertiesIndexController; abort?: () => void; continue?: () => void }) {
      const deferred = defer();

      this.abort = () => deferred.resolve(false);
      this.continue = () => deferred.resolve(true);

      return yield deferred.promise;
    },
  };

  deletePropertyTask = dropTask(async (property: ZoneModel) => {
    // eslint-disable-next-line @typescript-eslint/await-thenable -- encapsulated tasks do not have great TS support
    const confirm = await this.confirmDeletePropertyTask.perform();
    if (!confirm) return;

    const propertyName = property.name;
    try {
      await property.destroyRecord();
      this.total -= 1;
      this.model.removeObject(property);
      this.flashMessages.showAndHideFlash('success', `"${propertyName}" has been deleted`);
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      this.flashMessages.showAndHideFlash('error', 'Something went wrong, please try again.');
    }
  });
}
