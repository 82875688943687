import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

export default class AnnouncementsShowController extends Controller {
  @service store;
  @service flashMessages;
  @service featureFlags;

  @dropTask
  *archiveAnnouncementTask() {
    try {
      this.model.announcement.isArchived = !this.model.announcement.isArchived;
      yield this.model.announcement.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.transitionToRoute('protect.announcements.list');
    } catch (err) {
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(err));
    }
  }
}
