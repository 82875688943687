import Controller from '@ember/controller';
import updateAndSaveTask from 'garaje/utils/decorators/update-and-save-task';
import { action } from '@ember/object';

@updateAndSaveTask
class LocationOverviewSignInFlowsShowSecurityController extends Controller {
  idScanChangeset;

  @action
  handleIdScanPageChanges(changeset) {
    this.idScanChangeset = changeset;
  }
}

export default LocationOverviewSignInFlowsShowSecurityController;
