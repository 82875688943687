import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { isAfter, parseISO } from 'date-fns';
import { task, timeout } from 'ember-concurrency';
import type { DevicePairingStatus, Room } from 'garaje/graphql/generated/roomba-types';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import type RoombaMetricsService from 'garaje/services/roomba-metrics';
import { APP } from 'garaje/utils/enums';

interface PairingCodeInputParams {
  room: Room;
  shouldAutofocusInput: boolean;
  onDevicePaired: () => Promise<void>;
}
type PairingStatus = Omit<DevicePairingStatus, 'device'>;

export default class PairingCodeInput extends Component<PairingCodeInputParams> {
  @service declare roombaGraphql: RoombaGraphqlService;
  @service declare roombaMetrics: RoombaMetricsService;

  @tracked inputValue = '';
  @tracked showErrorOnPair = false;

  @action
  focusInput(element: HTMLInputElement): void {
    if (!this.args.shouldAutofocusInput || !element) {
      return;
    }
    element && element.focus();
  }

  @action
  async onInputCode(event: { target: HTMLInputElement }): Promise<void> {
    const value = event.target && 'value' in event.target ? event.target['value'] : null;
    if (!value?.trim()) {
      return;
    }
    this.inputValue = value?.slice(0, 6).toUpperCase();
    if (value.length < 6) {
      return;
    }
    await this.pairDeviceTask.perform(this.inputValue);
  }

  pairDeviceTask = task(this, { restartable: true }, async (code: string) => {
    this.showErrorOnPair = false;
    let devicePairingStatus;
    try {
      devicePairingStatus = await this.roombaGraphql.approveDeviceUserCode(code, this.args.room.id);
    } catch (error) {
      // TODO: we should update the displayed error message based on the this error
      this.showErrorOnPair = true;
    }
    if (!devicePairingStatus) {
      return;
    }
    this.roombaMetrics.trackEvent('pairing_code_approved', {
      user_code: code,
      room_id: this.args.room.id,
      product: APP.ROOMS,
      company_id: this.args.room.companyId,
    });
    await this.pollForDevicePairingStatus.perform(devicePairingStatus);
  });

  pollForDevicePairingStatus = task(this, { restartable: true }, async (devicePairingStatus: PairingStatus) => {
    let pairingStatus: PairingStatus | null;
    this.roombaMetrics.trackEvent('start_polling_for_device_pairing_status', {
      device_pairing_status_id: devicePairingStatus.id,
      device_id: devicePairingStatus.deviceId,
      room_id: this.args.room.id,
      product: APP.ROOMS,
      company_id: this.args.room.companyId,
    });
    do {
      pairingStatus = await this.fetchDevicePairingStatusTask.perform(devicePairingStatus.id);
      await timeout(devicePairingStatus.interval);
    } while (
      (!pairingStatus || !pairingStatus.deviceId) &&
      isAfter(parseISO(devicePairingStatus.expiresAt), new Date())
    );
    await this.args.onDevicePaired();
    this.roombaMetrics.trackEvent('device_paired', {
      device_pairing_status_id: devicePairingStatus.id,
      device_id: devicePairingStatus.deviceId,
      room_id: this.args.room.id,
      product: APP.ROOMS,
      company_id: this.args.room.companyId,
    });
    this.inputValue = '';
  });

  fetchDevicePairingStatusTask = task(this, { restartable: true }, async (id: number) => {
    try {
      return await this.roombaGraphql.getDevicePairingStatus(id);
    } catch (error) {
      return null;
    }
  });
}
