import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { dropTask } from 'ember-concurrency';
import { gte } from 'macro-decorators';
import { APP } from 'garaje/utils/enums';
export default class VisitorTypesNewController extends Controller {
  @service flashMessages;
  @service metrics;
  @service featureFlags;
  @service chameleon;
  @service store;
  @service('setupGuideStepper') setupGuideStepperService;

  copyFlowsSortProperties = ['companyName', 'locationName', 'name'];
  copyGlobalFlowsSortProperties = ['name'];

  @tracked copyFlowType = null;

  // allFlows is a Class, not a plain JS array. @notEmpty does not work as expected
  @gte('model.allFlows.length', 1) hasAnyVisitorFlows;

  get sortedFlows() {
    return this.model.allFlows.sortBy(...this.copyFlowsSortProperties);
  }

  get sortedGlobalFlows() {
    return this.model.globalFlows.sortBy(...this.copyGlobalFlowsSortProperties);
  }

  get groupedCopyFlows() {
    return [
      {
        groupName: 'Global sign-in flows',
        options: this.sortedGlobalFlows,
      },
      {
        groupName: 'Location sign-in flows',
        options: this.sortedFlows.filter((flow) => flow.constructor.modelName !== 'copy-global-flow'),
      },
    ];
  }

  get isAllowedMoreVisitorTypes() {
    const isAllowedMultipleVisitorTypes = this.model.vrSubscription?.canAccessMultipleVisitorTypes;
    const { hasAnyVisitorFlows } = this;

    return isAllowedMultipleVisitorTypes || !hasAnyVisitorFlows;
  }

  locationAndFlowNameMatcher(copyFlow, term) {
    const companyName = get(copyFlow, 'companyName');
    const locationName = get(copyFlow, 'locationName');
    const flowName = get(copyFlow, 'name');
    const string = `${companyName} ${locationName}: ${flowName}`.toLowerCase();

    return string.indexOf(term.toLowerCase());
  }

  get selectedCopyFlow() {
    const blueprintId = get(this.model, 'changeset.blueprint');
    if (this.copyFlowType === 'global') {
      return this.model.globalFlows.find((flow) => flow.id === blueprintId);
    }

    return this.model.allFlows.find((flow) => flow.id === blueprintId);
  }

  /**
   * @task `saveTask`
   */
  @dropTask
  *saveTask() {
    const changeset = get(this.model, 'changeset');
    try {
      yield changeset.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.chameleon.trackDashboardPageEvent('New Visitor Type Saved');

      if (this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper')) {
        this.setupGuideStepperService.loadSetupStepsTask.perform(APP.VISITORS);
      }

      this.transitionToRoute('visitors.settings.visitor-types.flow', get(changeset, 'id'));
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
      changeset.rollback();
    }
  }

  @action
  updateBlueprint(flow) {
    const changeset = get(this.model, 'changeset');

    if (flow) {
      this.copyFlowType = flow.isGlobal ? 'global' : 'local';
      set(changeset, 'blueprint', get(flow, 'id'));
    } else {
      set(changeset, 'blueprint', null);
    }
  }

  @action
  logUpsellViewed() {
    this.metrics.trackEvent('CTA Viewed', this.ctaEventProperties);
  }

  @action
  logUpsellClicked() {
    this.metrics.trackEvent('CTA Clicked', this.ctaEventProperties);
  }

  get ctaEventProperties() {
    return {
      cta_id: 'multiple_visitor_types_upsell',
      cta_type: 'page',
      cta_clickable_type: 'button',
      cta_clickable_text: 'Upgrade to Premium',
      cta_title: this.upsellHeader,
      cta_body: this.upsellBody,
      cta_intent: 'upgrade',
    };
  }

  upsellHeader = 'Add visitor types';
  upsellBody =
    'Customize the sign-in experience based on a guest’s purpose of visit. Present different sign-in fields or a different NDA; configure photo taking, badge printing and more.';
}
