import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class VisitorTypesDefaultRoute extends Route {
  @service store;
  @service state;
  @service router;

  async beforeModel(transition) {
    super.beforeModel(transition);
    const currentLocation = this.state.currentLocation;
    const allFlows = await this.store.query('flow', { filter: { location: currentLocation.id } });
    const defaultFlow = allFlows.firstObject;
    if (defaultFlow?.id) {
      this.router.transitionTo('visitors.settings.visitor-types.flow', defaultFlow.id);
    } else {
      this.router.transitionTo('visitors.settings.visitor-types.index');
    }
  }
}
