import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

/**
 * @param {Boolean}               hasPrinters                               set to true if there are no printer at the location
 * @param {Boolean}               isEnabled                                 set to true if printer notification is enabled
 * @param {Array<User>}           printerContacts                           list of users who will receive notifications
 * @param {Function}              savePrinterContactsTask                   task to save printer notifications contacts
 * @param {Function}              searchUsersTask                           task to search for users with keywords
 * @param {Function}              togglePrinterNotificationsEnabledTask     task to enable/disable printer notifications
 */
export default class PrinterNotifications extends Component {
  @service currentLocation;

  @tracked isOpen = false;
  @tracked hasChangedPrinterContacts = false;
  @tracked newPrinterContacts;

  constructor() {
    super(...arguments);
    const printerContacts = this.args.printerContacts;
    this.newPrinterContacts = printerContacts.toArray();
  }

  @task
  *searchTask(term) {
    return yield this.args.searchUsersTask.perform(term, this.newPrinterContacts);
  }

  @action
  addPrinterContacts(users) {
    this.hasChangedPrinterContacts = true;
    this.newPrinterContacts = users;
  }
}
