import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
import type ArrayProxy from '@ember/array/proxy';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { dropTask, restartableTask } from 'ember-concurrency';
import type ZoneModel from 'garaje/models/zone';
import type { CurrentZoneRouteModel } from 'garaje/pods/current-zone/route';
import type ContextSwitcherService from 'garaje/services/context-switcher';
import type CurrentZoneService from 'garaje/services/current-zone';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { getStatusCode } from 'garaje/utils/parse-error';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import type { Constructor } from 'type-fest';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
function withZoneStateFunctionality<TBase extends Constructor<any>>(Base: TBase) {
  class withZoneStateFunctionality extends Base {
    @service declare store: Store;
    @service declare state: StateService;
    @service declare featureFlags: FeatureFlagsService;
    @service declare contextSwitcher: ContextSwitcherService;
    @service declare skinnyLocations: SkinnyLocationsService;
    @service declare metrics: MetricsService;
    @service declare abilities: AbilitiesService;
    @service declare currentZone: CurrentZoneService;
    @service declare flashMessages: FlashMessagesService;
    @service declare router: RouterService;

    @tracked transition: Transition | undefined = undefined;

    initZoneState = dropTask(async () => {
      const zoneState: CurrentZoneRouteModel = {
        currentZone: undefined,
        zones: undefined,
      };

      const cacheLocationId = this.contextSwitcher.locationId;
      let zoneId = this.contextSwitcher.zoneId;

      // load zones, returns no zones if not global admin

      zoneState.zones = <NativeArray<ZoneModel> | ArrayProxy<ZoneModel>>(
        await this.fetchZones.perform().catch(throwUnlessTaskDidCancel)
      );

      // serialize zones as JSON to avoid circular JSON errors

      this.metrics.trackEvent('Fetching zones for company', {
        zones: zoneState.zones?.map((zone) => zone.serialize()).toString(),
      });

      // do not load zone if no zones "found"

      if (!zoneState.zones?.toArray()?.length) {
        this.state.zoneState = zoneState;
        return zoneState;
      }

      // do not load zone if location is only item cached

      if (cacheLocationId && !zoneId) {
        this.state.zoneState = zoneState;
        return zoneState;
      }

      if (zoneId) {
        // check to make sure the cache contains a valid zone only when a zone is not currently set. If it is not valid, invalidate it
        try {
          const foundZone = await this.store.findRecord('zone', zoneId);
          // do not default to zone if it does not belong to the current company
          if (foundZone.belongsTo('company').id() === this.state._companyId) {
            zoneState.currentZone = foundZone;
          } else {
            zoneId = null;
          }
        } catch (error) {
          zoneId = null;
        }
      }

      // redirect with proper zone id if no zone has been selected
      if (this.router.currentURL === '/' || this.router.currentURL.startsWith('/login')) {
        const firstZone = this.getFirstZone(zoneState.zones);

        if (firstZone) {
          zoneId = firstZone.id;
          this.contextSwitcher.zoneId = firstZone.id;
        }
      }

      if (zoneId && !zoneState.currentZone) {
        zoneState.currentZone = await this.store.findRecord('zone', zoneId);
      }

      this.state.zoneState = zoneState;

      return zoneState;
    });

    fetchZones = restartableTask(async (): Promise<CurrentZoneRouteModel['zones']> => {
      let zones: CurrentZoneRouteModel['zones'] = A();

      if (!this.currentZone.zonesAreStale && this.fetchZones.lastSuccessful?.value) {
        return this.fetchZones.lastSuccessful.value;
      }

      this.currentZone.zonesAreStale = false;

      try {
        zones = await this.store.query('zone', {
          filter: { parent: null },
        });
      } catch (e) {
        // will return 403 if not global admin
        if (Number(getStatusCode(e)) !== 403) {
          const errorText = parseErrorForDisplay(e);
          this.flashMessages.showAndHideFlash('error', errorText);
        }
      }

      return zones;
    });

    getFirstZone(zones: ArrayProxy<ZoneModel> | NativeArray<ZoneModel>): ZoneModel | undefined {
      // return first zone that belongs to the current company
      return zones.sortBy('name').find((zone) => zone.belongsTo('company').id() === this.state._companyId);
    }
  }

  return withZoneStateFunctionality;
}

export default withZoneStateFunctionality;
