import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { all } from 'rsvp';
import { isPresent } from '@ember/utils';

export default class DesksIndexRoute extends Route {
  @service abilities;
  @service state;
  @service metrics;
  @service router;
  @service store;

  title = 'Desks · Envoy';

  beforeModel() {
    if (this.abilities.cannot('visit desks')) {
      this.router.transitionTo('unauthorized');
    } else {
      this.metrics.trackEvent('Entering desks tab');
    }

    const { workplaceSubscription, realDesksSubscription } = this.state;

    if (!isPresent(realDesksSubscription) && workplaceSubscription?.cancelled) {
      this.router.transitionTo('workplace.trial-ended');
    }
  }

  async model() {
    const { currentLocation, currentLocationEnabledDesks, billingCompany } = this.state;
    const currentLocationId = await currentLocation.id;
    const floors = await this.store.query('floor', { filter: { 'location-id': currentLocationId }, include: 'desks' });
    await this.store.findAll('neighborhood', { adapterOptions: { locationId: currentLocationId } }); // Load neighborhoods for desk relationship
    const floorDesks = floors.map((floor) => floor.desks);
    await all(floorDesks);
    const locationDesks = [];
    floorDesks.forEach((deskArray) => deskArray.forEach((desk) => locationDesks.push(desk)));

    return { floors, billingCompany, currentLocationEnabledDesks, locationDesks };
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
