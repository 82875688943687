import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
import { setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import { didCancel, all } from 'ember-concurrency';
import type FlowModel from 'garaje/models/flow';
import type InviteModel from 'garaje/models/invite';
import type LocationModel from 'garaje/models/location';
import type LocationsCapacityModel from 'garaje/models/locations-capacity';
import type PrinterModel from 'garaje/models/printer';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import type SignInFieldPageModel from 'garaje/models/sign-in-field-page';
import type SubscriptionModel from 'garaje/models/subscription';
import type TenantModel from 'garaje/models/tenant';
import type UserModel from 'garaje/models/user';
import type StateService from 'garaje/services/state';
import { fetchCapacity } from 'garaje/utils/locations-capacity';
import type { RecordArray } from 'garaje/utils/type-utils';
import moment from 'moment-timezone';
import { hash } from 'rsvp';

import type { VisitorsInvitesRouteModel } from '../route';

import type VisitorsInvitesIndexController from './controller';

export interface VisitorsInvitesIndexRouteModelParams {
  date: string;
  sort: string;
  selectedFlow: string;
}

export interface VisitorsInvitesIndexRouteModel {
  connectedTenants: RecordArray<TenantModel>;
  flows: FlowModel[];
  invites: RecordArray<InviteModel>;
  printers: RecordArray<PrinterModel>;
  vrSubscription: SubscriptionModel;
  currentLocation: LocationModel;
  blocklistContacts: RecordArray<UserModel>;
  date: string;
  sort: string;
  locationsCapacity: LocationsCapacityModel;
  selectedFlow: string;
  signInFieldPages: SignInFieldPageModel[];
  customFields: NativeArray<SignInFieldModel>;
}

class VisitorsInvitesIndexRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  queryParams = {
    date: {
      refreshModel: true,
    },
    query: {
      refreshModel: true,
    },
    filter: {
      refreshModel: true,
    },
    sort: {
      refreshModel: true,
    },
    selectedFlows: {
      as: 'visitor_types',
      refreshModel: true,
    },
    selectedFlow: {
      // DEPRECATED - remove once "visitors-filter-logs-by-multiple-flows" feature flag is 100% rolled out
      as: 'visitor_type',
      refreshModel: true,
    },
  };

  beforeModel(transition: Transition): void {
    const transitionFrom = transition.from?.name;
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const controller = this.controllerFor('visitors.invites.index');

    if (controller && transitionFrom && !transitionFrom.includes('invites')) {
      controller.send('clearSearch');
      void this.transitionTo({ queryParams: { query: '', visitor_type: '' } });
    }
  }

  async model({
    date,
    sort,
    selectedFlow,
  }: VisitorsInvitesIndexRouteModelParams): Promise<VisitorsInvitesIndexRouteModel | Transition> {
    const { vrSubscription, currentLocation } = this.state;
    const { capacityLimitEnabled } = currentLocation;
    const blocklistContacts = currentLocation.blocklistContacts;

    if (date && !moment(date).isValid()) {
      return this.router.transitionTo('visitors.invites', { queryParams: { date: null } });
    }

    let locationsCapacity = null;
    if (capacityLimitEnabled) {
      locationsCapacity = fetchCapacity(this.store, currentLocation, date);
    }

    // Printers endpoint returns 403 for unauthorized employees which end up on users
    // getting sad cloud upon sign in
    let printers: PrinterModel[] | Promise<RecordArray<PrinterModel>> = [];
    if (this.abilities.can('visit device')) {
      printers = this.store.query('printer', {
        filter: { location: currentLocation?.id, enabled: true },
        sort: 'status',
      });
    }
    const locationFlows = await currentLocation.flows;
    const signInFieldPages = await all(locationFlows.map((flow) => flow.signInFieldPage));
    const customFields = await all(signInFieldPages.map((signInFieldPage) => signInFieldPage?.customFields));
    const { connectedTenants } = <VisitorsInvitesRouteModel>this.modelFor('visitors.invites');

    const isConnectedToProperty = <number>connectedTenants.length > 0;
    return hash({
      connectedTenants,
      flows: this.state.loadFlows({
        includePropertyFlows: isConnectedToProperty, // if this location is connected to a property, include flows added by the property
        locationId: currentLocation.id,
        reload: false,
      }),
      invites: [],
      printers,
      vrSubscription,
      currentLocation,
      blocklistContacts,
      date,
      sort,
      locationsCapacity,
      selectedFlow,
      signInFieldPages,
      customFields,
    });
  }

  async setupController(
    controller: VisitorsInvitesIndexController,
    model: VisitorsInvitesIndexRouteModel,
    transition: Transition
  ): Promise<void> {
    super.setupController(controller, model, transition);

    setProperties(controller, {
      page: 0,
      limit: 25,
      invitesCount: 0,
      sort: model.sort,
      date: model.date,
      selectedFlow: model.selectedFlow,
    });

    // Trigger load reviewable task. No need to wait
    void controller.loadReviewableInvitesTask.perform();

    await controller.loadBosses.perform().catch((e) => {
      if (!didCancel(e)) throw e;
    });

    const validFilter = A(controller.filterOptions).findBy('filter', controller.filter);

    if (!validFilter) {
      void this.transitionTo({ queryParams: { filter: A(controller.filterOptions).firstObject!.filter } });

      return;
    }

    return controller.loadInvites.perform().catch((e) => {
      if (!didCancel(e)) throw e;
    });
  }
}

export default VisitorsInvitesIndexRoute;
