import Controller, { inject as controller } from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class LocationOverviewSignInFlowsController extends Controller {
  @controller('protected') protectedController;

  @tracked showGlobalFlowCreationModal = false;

  @action
  handleLocationTransition(location) {
    this.protectedController.send('switchLocation', location.id, 'visitors.settings.visitor-types');
  }

  @action
  async handleFlowTransition(flow) {
    if (flow.data.type === 'Flows::GlobalChild') {
      return this.transitionToRoute('location-overview.sign-in-flows.show.index', flow.data.globalFlow.id);
    }

    this.protectedController.send(
      'switchLocation',
      flow.data.location.id,
      'visitors.settings.visitor-types.flow.index',
      flow.id
    );
  }
}
