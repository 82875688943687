import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type { GetRoomsQuery } from 'garaje/graphql/generated/roomba-types';
import { DEVICE_STATUS } from 'garaje/pods/components/roomba/status-badge/component';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type RoomsPollingService from 'garaje/services/rooms-polling';
import { getDeviceStatus } from 'garaje/utils/device-status';
import countBy from 'lodash/countBy';
import orderBy from 'lodash/orderBy';

const ROOMS_PER_PAGE = 25;

type OverallDevicesStatus = { [status in DEVICE_STATUS]?: number };
export default class RoombaListController extends Controller {
  @service declare roomsPolling: RoomsPollingService;
  @service declare featureFlags: FeatureFlagsService;

  @tracked roomsSearchInput = '';
  @tracked pageNumber = 1;
  @tracked pageSize = ROOMS_PER_PAGE;
  @tracked sortField = 'name';
  @tracked sortDirection: 'asc' | 'desc' = 'asc';

  get DEVICE_STATUS(): typeof DEVICE_STATUS {
    return DEVICE_STATUS;
  }

  get roomsWithDeviceStatuses(): (GetRoomsQuery['rooms'][number] & {
    overallDevicesStatus?: OverallDevicesStatus;
  })[] {
    return orderBy(
      this.roomsPolling.roomsList
        .map((room) => ({
          ...room,
          overallDevicesStatus: this.getOverallDevicesStatus(room),
        }))
        .filter((room) => room.name.toLowerCase().includes(this.roomsSearchInput.toLowerCase())),
      this.sortField,
      this.sortDirection
    );
  }

  get paginatedRoomsWithDeviceStatuses(): (GetRoomsQuery['rooms'][number] & {
    overallDevicesStatus?: OverallDevicesStatus;
  })[] {
    return this.roomsWithDeviceStatuses.slice(
      this.pageNumber * ROOMS_PER_PAGE - ROOMS_PER_PAGE,
      this.pageNumber * ROOMS_PER_PAGE
    );
  }

  @action
  mutateRoomsSearchInput(value: string): void {
    this.roomsSearchInput = value;
    this.pageNumber = 1;
  }

  @action
  clearRoomsSearchInput(): void {
    this.roomsSearchInput = '';
  }

  getOverallDevicesStatus(room: GetRoomsQuery['rooms'][number]): OverallDevicesStatus | undefined {
    if (!room?.devices.length) {
      return;
    }
    return countBy(
      room.devices.map((device) => ({
        status: getDeviceStatus(device),
      })),
      'status'
    );
  }
  @action
  sortAction(sortField: string, direction: 'asc' | 'desc'): void {
    this.sortField = sortField;
    this.sortDirection = direction;
  }

  @action
  setPageNumber(pageNumber: number): void {
    this.pageNumber = pageNumber;
  }
}
