import Controller from '@ember/controller';
import { action, get } from '@ember/object';
import { all } from 'rsvp';

export default class BillingDowngradeController extends Controller {
  get hasMultipleFlowsAtAnyLocation() {
    return all(this.model.locations.map((location) => location.hasNonProtectFlows)).then((res) => {
      return res.some(Boolean);
    });
  }

  @action
  cancel() {
    this.transitionToRoute('billing.plans');
  }

  @action
  confirm() {
    this.transitionToRoute('billing.confirm-downgrade', get(this.model, 'targetPlan.id'));
  }
}
