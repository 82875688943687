import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import urlBuilder from 'garaje/utils/url-builder';

export default class GlobalAnalyticsDesksIndexController extends Controller {
  @service state;

  /**
   * @returns {String} the iframe url
   */
  get iframeUrl() {
    const companyId = this.state.currentCompany.id;
    return urlBuilder.embeddedDesksGlobalAnalyticsUrl(companyId);
  }
}
