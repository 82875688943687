import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import lookupValidator from 'ember-changeset-validations';
import { dropTask } from 'ember-concurrency';
import type AnnouncementTemplateCategoryModel from 'garaje/models/announcement-template-category';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import announcementTemplateCategoryValidations from 'garaje/validations/announcement-template-category';
import { defer } from 'rsvp';

interface AnnouncementTemplateCategorySettingsArgs {
  categories: AnnouncementTemplateCategoryModel[];
}

export default class AnnouncementTemplateCategorySettings extends Component<AnnouncementTemplateCategorySettingsArgs> {
  @service declare store: Store;
  @service declare state: StateService;
  @service declare flashMessages: FlashMessagesService;

  @tracked categoryChangeset!: DetailedChangeset<AnnouncementTemplateCategoryModel>;

  get categories(): AnnouncementTemplateCategoryModel[] {
    return this.args.categories.filter((c) => !c.isNew);
  }

  @dropTask
  confirmDeletionModalTask = {
    *perform(
      this: {
        context: AnnouncementTemplateCategorySettings;
        abort?: () => void;
        continue?: () => Promise<void>;
      },
      category: AnnouncementTemplateCategoryModel
    ): Generator<Promise<boolean>, void, void> {
      const deferred = defer<boolean>();

      this.abort = () => {
        deferred.resolve(false);
      };

      this.continue = async () => {
        try {
          await category.destroyRecord();
          this.context.flashMessages.showAndHideFlash('success', 'Deleted!');
          deferred.resolve(true);
        } catch (e) {
          this.context.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
        }
      };

      return yield deferred.promise;
    },
  };

  @dropTask
  showCreateModalTask = {
    *perform(this: {
      context: AnnouncementTemplateCategorySettings;
      abort?: () => void;
      continue?: () => Promise<void>;
    }): Generator<Promise<boolean>, void, void> {
      const deferred = defer<boolean>();

      const announcementTemplateCategory = this.context.store.createRecord('announcement-template-category', {
        name: '',
        company: this.context.state.currentCompany,
      });

      const validations = announcementTemplateCategoryValidations;

      this.context.categoryChangeset = Changeset(
        announcementTemplateCategory,
        lookupValidator(validations),
        validations
      );

      this.abort = () => {
        this.context.categoryChangeset.rollback();
        void announcementTemplateCategory.unloadRecord();
        deferred.resolve(false);
      };

      this.continue = async () => {
        try {
          await this.context.categoryChangeset.validate();

          if (this.context.categoryChangeset.isInvalid) {
            return;
          }

          await this.context.categoryChangeset.save();

          this.context.flashMessages.showAndHideFlash('success', 'Saved!');

          deferred.resolve(true);
        } catch (e) {
          this.context.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
        }
      };

      return yield deferred.promise;
    },
  };
}
