import Component from '@glimmer/component';
import { action } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { and, reads, oneWay, or, notEmpty } from '@ember/object/computed';
import { inject as service } from '@ember/service';

/**
 * @param {Class}       company
 * @param {Boolean}     isHubPreferencesEnabled
 * @param {Class}       model
 * @param {Object}      pluginPreferences
 * @param {Function}    saveTask
 * @param {Class}       subscription
 * @param {Boolean}     isReadOnly
 * @param {Boolean}     showAsSP                  Show settings items as SettingsPanel
 */
export default class NotificationSettings extends Component {
  @service currentLocation;

  @reads('currentLocation.slackNotificationEnabled') hasSlack;
  @oneWay('args.company.callEnabled') hasCalls;
  @or('hasSlack', 'hasNotificationPreferences') hasSlackOrNoticationPreferences;
  @notEmpty('args.pluginPreferences') pluginPreferencesPresent;
  @and('pluginPreferencesPresent', 'args.isHubPreferencesEnabled') hasNotificationPreferences;
  @or('hasSlack', 'hasCalls', 'hasNotificationPreferences') hasOthers;

  @action
  changeValue(model, property, value) {
    this.args.saveTask.perform(model, property, value);

    return true;
  }
}
