import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import lookupValidator from 'ember-changeset-validations';
import { dropTask, task } from 'ember-concurrency';
import type { Task } from 'ember-concurrency';
import { pluralize } from 'ember-inflector';
import type ConfigModel from 'garaje/models/config';
import type PaperDeviceModel from 'garaje/models/paper-device';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { APP } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import PaperDeviceValidations from 'garaje/validations/paper-device';
import { filterBy, or, union } from 'macro-decorators';
import { defer } from 'rsvp';
interface StaticQrSettingsPanelArgs {
  /**
   * The Config to view settings for
   */
  config: ConfigModel;

  isSubscriptionUpgradeRequired: boolean;
}
interface SetupGuideStepperService {
  loadSetupStepsTask: Task<void, [string]>;
}

export default class StaticQrSettingsPanel extends Component<StaticQrSettingsPanelArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare store: Store;
  @service('setup-guide-stepper') declare setupGuideStepperService: SetupGuideStepperService;
  @service declare featureFlags: FeatureFlagsService;

  @tracked createdPaperDevices: PaperDeviceModel[];
  @tracked editingDevice: DetailedChangeset<PaperDeviceModel> | null = null;
  @tracked deletingDevices: PaperDeviceModel[] | null = null;
  @tracked regeneratingDevices: PaperDeviceModel[] | null = null;

  @filterBy('allPaperDevices', 'isDeleted', false) paperDevices!: PaperDeviceModel[];
  @union('createdPaperDevices', 'fetchPaperDevicesTask.lastSuccessful.value') allPaperDevices!: PaperDeviceModel[];
  @or('createPaperDeviceTask.isRunning', 'isMaximumQrCodesForCurrentPlan') isNewButtonDisabled!: boolean;

  // Disable the FocusTrapper in the details modal when a confirmation renders
  // From https://github.com/steveszc/ember-focus-trapper#things-to-be-aware-of
  @or(
    'confirmCloseDetailsTask.isRunning',
    'confirmToggleStaticQrCodeTask.isRunning',
    'deletingDevices',
    'regeneratingDevices'
  )
  isConfirmationModalVisible!: boolean;

  constructor(owner: unknown, args: StaticQrSettingsPanelArgs) {
    super(owner, args);
    this.createdPaperDevices = [];
  }

  get isLoading(): boolean {
    const { performCount, isRunning } = this.fetchDataTask;

    return performCount === 0 || isRunning;
  }

  get isSettingsOpen(): boolean {
    const { isSubscriptionUpgradeRequired, config } = this.args;

    if (isSubscriptionUpgradeRequired) return false;

    return config.staticQrEnabled ?? false;
  }

  // For this iteration, every location gets 10 maximum
  get maximumQrCodesForCurrentPlan(): number {
    return 10;
  }

  get isMaximumQrCodesForCurrentPlan(): boolean {
    return this.paperDevices.length >= this.maximumQrCodesForCurrentPlan;
  }

  @action
  onClipboardSuccess(): void {
    this.flashMessages.showAndHideFlash('success', 'Sign-in link copied');
  }

  @action
  viewDetailsForDevice(device: PaperDeviceModel): void {
    this.editingDevice = Changeset(device, lookupValidator(PaperDeviceValidations), PaperDeviceValidations);
    this.closeAllConfirmationDialogs();
  }

  closeDetailModal(): void {
    this.editingDevice = null;
    this.closeAllConfirmationDialogs();
  }

  closeAllConfirmationDialogs(): void {
    this.deletingDevices = null;
    this.regeneratingDevices = null;
    void this.closeDetailsTask.cancelAll();
    void this.toggleStaticQrCodeTask.cancelAll();
  }

  toggleStaticQrCodeTask = task({ drop: true }, async (value: boolean): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/await-thenable -- encapsulated tasks do not have great TS support
    const confirmed = value || this.paperDevices.length === 0 || (await this.confirmToggleStaticQrCodeTask.perform());
    const { config } = this.args;

    if (!confirmed) return;

    config.staticQrEnabled = value;

    try {
      const flash = `Static QR Codes ${value ? 'enabled' : 'deactivated'}!`;

      await config.save();
      this.flashMessages.showAndHideFlash('success', flash);
    } catch (e) {
      config.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  fetchDataTask = task({ drop: true }, async (): Promise<void> => {
    await Promise.all([this.fetchPrintersTask.perform(), this.fetchPaperDevicesTask.perform()]);
  });

  fetchPaperDevicesTask = task({ drop: true }, async (): Promise<PaperDeviceModel[]> => {
    const location = await this.args.config?.location;

    if (!location) return [];

    try {
      const devices = await this.store.query('paper-device', {
        include: 'printer',
        filter: { location: location.id },
        page: { limit: 100 },
        sort: '-created-at',
      });

      return devices.toArray();
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));

      return [];
    }
  });

  fetchPrintersTask = task({ drop: true }, async () => {
    const location = await this.args.config?.location;

    if (!location) return [];

    try {
      return await this.store.query('printer', { filter: { location: location.id } });
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));

      return [];
    }
  });

  createPaperDeviceTask = task(async (): Promise<void> => {
    const location = await this.args.config?.location;
    const device = this.store.createRecord('paper-device', { location });
    let createdPaperDevices = [...this.createdPaperDevices];

    createdPaperDevices.unshift(device);
    this.createdPaperDevices = createdPaperDevices;

    try {
      await device.save();
      if (this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper')) {
        void this.setupGuideStepperService.loadSetupStepsTask.perform(APP.VISITORS);
      }
      this.flashMessages.showAndHideFlash('success', 'Static QR code created!');
    } catch (_) {
      device.rollbackAttributes();

      // Remove the record that failed to create
      createdPaperDevices = [...this.createdPaperDevices];
      createdPaperDevices.splice(createdPaperDevices.indexOf(device), 1);
      this.createdPaperDevices = createdPaperDevices;

      this.flashMessages.showAndHideFlash('error', 'Something went wrong, please try again.');
    }
  });

  saveDeviceTask = task(async (device: DetailedChangeset<PaperDeviceModel>): Promise<void> => {
    if (!device?.isDirty) return;

    try {
      await device.save();

      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  // Using an array here so it is easier to support "bulk deletion" in the future.
  deleteDevicesTask = task(async (devices?: PaperDeviceModel[]): Promise<void> => {
    if (!devices?.length) return;

    try {
      await Promise.all(devices.map((record) => record.destroyRecord()));

      const message = `Static QR ${pluralize(devices.length, 'code', { withoutCount: true })} deleted!`;

      this.flashMessages.showAndHideFlash('success', message);
      this.closeDetailModal();
    } catch (e) {
      devices.map((record) => record.rollbackAttributes());
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  // Using an array here so it is easier to support "bulk regenerate" in the future.
  regenerateDevicesTask = task(async (devices?: PaperDeviceModel[]): Promise<void> => {
    if (!devices?.length) return;

    const currentlyEditing = this.editingDevice?.data;
    const detailIdx = currentlyEditing ? devices.indexOf(currentlyEditing) : -1;

    try {
      const copies: PaperDeviceModel[] = await Promise.all(devices.map((record) => record.regenerate()));
      const message = `Static QR ${pluralize(devices.length, 'code', { withoutCount: true })} regenerated!`;
      const copy = copies[detailIdx];

      // Reload Paper Device data
      this.createdPaperDevices = [];
      await this.fetchPaperDevicesTask.perform();

      if (copy) this.viewDetailsForDevice(copy);

      this.closeAllConfirmationDialogs();
      this.flashMessages.showAndHideFlash('success', message);
    } catch (e) {
      devices.map((record) => record.rollbackAttributes());
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  closeDetailsTask = task({ restartable: true }, async (): Promise<void> => {
    if (!this.editingDevice) return;
    if (this.isConfirmationModalVisible) return this.closeAllConfirmationDialogs();

    // eslint-disable-next-line @typescript-eslint/await-thenable -- encapsulated tasks do not have great TS support
    const confirmed = this.editingDevice.isPristine || (await this.confirmCloseDetailsTask.perform());

    if (confirmed) this.closeDetailModal();
  });

  @dropTask
  confirmCloseDetailsTask: {
    perform(): Generator<Promise<boolean>, void, void>;
  } = {
    *perform(this: { context: StaticQrSettingsPanel; abort?: () => void; continue?: () => void }) {
      const deferred = defer<boolean>();

      this.abort = () => deferred.resolve(false);
      this.continue = () => deferred.resolve(true);

      return yield deferred.promise;
    },
  };

  @dropTask
  confirmToggleStaticQrCodeTask: {
    perform(): Generator<Promise<boolean>, void, void>;
  } = {
    *perform(this: { context: StaticQrSettingsPanel; abort?: () => void; continue?: () => void }) {
      const deferred = defer<boolean>();

      this.abort = () => deferred.resolve(false);
      this.continue = () => deferred.resolve(true);

      return yield deferred.promise;
    },
  };
}
