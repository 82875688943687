import Route from '@ember/routing/route';
import { set, setProperties } from '@ember/object';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { getUnixTime } from 'date-fns';
import { v1 as uuid } from 'uuid';
import { routeEvent } from 'garaje/utils/decorators/route';
import { APP } from 'garaje/utils/enums';
import { WORKPLACE_SEAT_MINIMUM } from 'garaje/utils/plan-details';

export default class BillingIndexConfirmPurchaseRoute extends Route {
  @service locations;
  @service metrics;
  @service state;
  @service router;
  @service store;

  queryParams = {
    viaLabel: {
      replace: true,
    },
    viaObject: {
      replace: true,
    },
  };

  minWorkplaceSeatNumber = WORKPLACE_SEAT_MINIMUM;

  beforeModel() {
    const { paymentSource } = this.modelFor('billing');
    if (paymentSource.isACH) {
      return this.router.transitionTo('billing');
    }
  }

  async model({ product, viaLabel, viaObject }) {
    let plan;
    let productIntent = product;
    let quantity = 0;
    let subscription;
    let activeUnits = 0;
    let planLevel;

    switch (productIntent) {
      case 'deliveries':
        subscription = this.state.realDeliveriesSubscription;
        plan = await this.store.findRecord('plan', 'deliveries-standard');
        planLevel = 'standard';
        quantity = this.modelFor('billing.index').deliveryAreas.filterBy('enabled').length;
        break;
      case 'rooms':
        subscription = this.state.realRoomsSubscription;
        plan = await this.store.findRecord('plan', 'rooms-standard');
        planLevel = 'standard';
        quantity = this.modelFor('billing.index').activeRoomsQuantity;
        break;
      case 'desks':
        subscription = this.state.realDesksSubscription;
        plan = await this.store.findRecord('plan', 'desks-standard');
        planLevel = 'standard';
        quantity = this.modelFor('billing.index').activeDeskLocations.length || 1;
        activeUnits = this.modelFor('billing.index').activeDeskLocations.reduce(
          (acc, dl) => acc + dl.assignableDesksQuantity,
          0
        );
        break;
      case 'vr':
        subscription = this.state.vrSubscription;
        plan = subscription && subscription.plan ? await this.store.findRecord('plan', subscription.plan) : '';
        planLevel = subscription?.plan;
        quantity = this.locations.active.length || 1;
        break;
      case 'workplace':
        subscription = this.state.workplaceSubscription;
        plan =
          subscription && subscription.plan ? await this.store.findRecord('plan', `empxp-${subscription.plan}`) : '';
        planLevel = subscription?.plan;
        quantity = WORKPLACE_SEAT_MINIMUM;
        productIntent = APP.WORKPLACE;
        break;
    }

    return hash({
      plan,
      planLevel,
      productIntent,
      quantity,
      subscription,
      activeUnits,
      title: 'Order confirmation',
      trackingId: uuid(),
      viaLabel,
      viaObject,
    });
  }

  afterModel(model) {
    const { paymentSource } = this.modelFor('billing');
    const isOnTrial = model.subscription && model.subscription.isOnTrial;

    if (!(paymentSource.exists || model.subscription.isDesks) || !isOnTrial) {
      this.router.transitionTo('billing');
    }

    set(model, 'paymentSource', paymentSource);

    if (model.productIntent === 'deliveries' && model.subscription && model.subscription.isWeekly) {
      // set periodStart to now so there is no pro-ration
      set(model, 'subscription.periodStart', getUnixTime(new Date()));
      set(model, 'subscription.periodEnd', null);
    }

    if (model.subscription) {
      set(model, 'subscription.period', 'yearly');
    }

    this.trackModalEnteredTask.perform(model);
  }

  setupController(controller) {
    super.setupController(...arguments);

    setProperties(controller, {
      isEditingCC: false,
      showActivateDesksModal: false,
      lastValidCouponCode: null,
    });
  }

  @task
  *trackModalEnteredTask(model) {
    yield this.metrics.trackPage('Modal entered', {
      action_id: model.trackingId, // Auto-generated ID to link multiple properties firing to a single action
      modal_title: model.title, // title of the modal
      modal_type: 'subscription_change', // modal type
      modal_purpose: 'confirm_purchase', // purpose of modal
      via_object: model.viaObject, // object interacted with that triggered the modal opening
      via_action: 'click', // type of interaction
      via_label: model.viaLabel, // Label of the button or element being interacted with
      num_warnings_shown: 0, // Number of warning boxes displayed in the Modal. Can be 0.
      warning_text: null, // Text list (JSON is ok) of the message displayed in the warning boxes. Useful to track in case we want to optimize these in the future.
    });
  }

  @routeEvent
  routeWillChange() {
    if (this.controller.model.subscription) {
      this.controller.model.subscription.rollbackAttributes();
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      set(controller, 'isPaymentDisabledMinSeats', false);
    }
  }
}
