import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { service } from '@ember/service';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class LocationOverviewSignInFlowsShowSecurityRoute extends Route {
  @service transitionConfirm;

  model() {
    const { globalFlow } = this.modelFor('location-overview.sign-in-flows.show');

    return hash({
      flow: globalFlow,
      idScanPage: globalFlow.idScanPage,
      inviteApprovalContacts: globalFlow.inviteApprovalContacts,
      visualComplianceConfiguration: globalFlow.visualComplianceConfiguration,
    });
  }

  @routeEvent
  routeWillChange(transition) {
    const { idScanChangeset } = this.controller;
    const confirmTask = this.transitionConfirm.displayConfirmTask;
    const isDirty = idScanChangeset?.isDirty;

    if (isDirty) {
      confirmTask.perform(transition, {
        continue() {
          idScanChangeset?.rollback();
        },
      });
    }
  }
}
