import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import urlBuilder from 'garaje/utils/url-builder';
import { alias } from 'macro-decorators';

export default class AnalyticsDesksController extends Controller {
  @service state;
  @service featureFlags;
  @service metrics;

  @alias('state.desksSubscription') desksSubscription;
  @alias('state.desksSubscription.isBasicPlan') isBasicPlan;

  @action
  trackUpgradeDesksPlan() {
    this.metrics.trackEvent('Clicked a button to upgrade plan from hazed analytics modal');
  }

  get iframeUrl() {
    const {
      currentCompany: { id: companyId },
      currentLocation: { id: locationId },
    } = this.state;

    return urlBuilder.embeddedDesksLocationAnalyticsUrl(companyId, locationId);
  }
}
