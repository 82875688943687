import type ArrayProxy from '@ember/array/proxy';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed, action } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { addMinutes } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import { dropTask } from 'ember-concurrency';
import type BillingCompanyModel from 'garaje/models/billing-company';
import type CompanyModel from 'garaje/models/company';
import type DeskLocationModel from 'garaje/models/desk-location';
import type LocationModel from 'garaje/models/location';
import type LocationSubscriptionModel from 'garaje/models/location-subscription';
import type SubscriptionModel from 'garaje/models/subscription';
import type SubscriptionPlanModel from 'garaje/models/subscription-plan';
import type TenantModel from 'garaje/models/tenant';
import type TenantConnectionRequestModel from 'garaje/models/tenant-connection-request';
import type UserModel from 'garaje/models/user';
import type { CurrentZoneRouteModel } from 'garaje/pods/current-zone/route';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type DataDogService from 'garaje/services/datadog';
import type FlowService from 'garaje/services/flow';
import type InactivityService from 'garaje/services/inactivity';
import type LocationFeatureFlagsService from 'garaje/services/location-feature-flags';
import withCompanyStateFunctionality from 'garaje/services/state/company-state';
import withLocationStateFunctionality from 'garaje/services/state/location-state';
import withSubscriptionStateFunctionality from 'garaje/services/state/subscription-state';
import type { CompanyMeta } from 'garaje/services/state/user-state';
import withUserStateFunctionality from 'garaje/services/state/user-state';
import withZoneStateFunctionality from 'garaje/services/state/zone-state';
import type UserDocumentService from 'garaje/services/user-document';
import type { RecordArray } from 'garaje/utils/type-utils';

const Base = withUserStateFunctionality(
  withSubscriptionStateFunctionality(
    withCompanyStateFunctionality(withZoneStateFunctionality(withLocationStateFunctionality(Service)))
  )
);

class StateService extends Base {
  @tracked allPlans: ArrayProxy<SubscriptionPlanModel> | null = null;
  @tracked currentCompany: CompanyModel | null = null;
  @tracked billingCompany: BillingCompanyModel | null = null;
  @tracked currentLocation: LocationModel | null = null;
  @tracked currentUser: UserModel | null = null;
  @tracked _companyMeta: CompanyMeta | null = null;
  @tracked _companyId: string | null = null;
  @tracked zoneState: CurrentZoneRouteModel | null = null;

  @tracked connectSubscription: LocationSubscriptionModel | SubscriptionModel | null = null;
  @tracked deliveriesSubscription: LocationSubscriptionModel | SubscriptionModel | null = null;
  @tracked desksSubscription: LocationSubscriptionModel | SubscriptionModel | null = null;
  @tracked emnoSubscription: LocationSubscriptionModel | SubscriptionModel | null = null;
  @tracked roomsSubscription: LocationSubscriptionModel | SubscriptionModel | null = null;
  @tracked vfdSubscription: LocationSubscriptionModel | SubscriptionModel | null = null;
  @tracked visitorsSubscription: LocationSubscriptionModel | SubscriptionModel | null = null;
  @tracked workplaceSubscription: LocationSubscriptionModel | SubscriptionModel | null = null;

  @tracked showDeliveriesOnboarding = false;

  @tracked tenantConnectionRequests: RecordArray<TenantConnectionRequestModel> | null = null;
  @tracked tenantConnections: RecordArray<TenantModel> | null = null;

  @tracked activeLocations = [];
  @tracked activeDeliveryAreas = [];
  @tracked activeRooms = [];
  @tracked activeEmployees = [];
  @tracked activeDeskLocations: DeskLocationModel[] = [];
  @tracked allDeskLocations: DeskLocationModel[] = [];

  @alias('visitorsSubscription') vrSubscription!: this['visitorsSubscription'];
  @alias('billingCompany.resellerPartner') resellerPartner!: BillingCompanyModel['resellerPartner'];

  @service declare authz: AuthzService;
  @service declare currentAdmin: CurrentAdminService;
  @service('flow') declare employeeScreeningFlowService: FlowService;
  @service declare inactivity: InactivityService;
  @service declare locationFeatureFlags: LocationFeatureFlagsService;
  @service('user-document') declare userDocumentService: UserDocumentService;
  @service declare datadog: DataDogService;

  get currentLocationEnabledDesks() {
    return this.allDeskLocations.find((dl) => dl.belongsTo('location').id() === this.currentLocation?.id);
  }

  get hasSubscriptions(): boolean {
    return Boolean(
      this.deliveriesSubscription ||
        this.roomsSubscription ||
        this.visitorsSubscription ||
        this.desksSubscription ||
        this.vfdSubscription
    );
  }

  get deliveriesOnly(): boolean {
    return Boolean(this.deliveriesSubscription && !this.roomsSubscription && !this.visitorsSubscription);
  }

  get roomsOnly(): boolean {
    return Boolean(!this.deliveriesSubscription && this.roomsSubscription && !this.visitorsSubscription);
  }

  get visitorsOnly(): boolean {
    return Boolean(!this.deliveriesSubscription && !this.roomsSubscription && this.visitorsSubscription);
  }

  @computed('currentLocation.{id,timezone}')
  get minutesBetweenTimezones(): number {
    const locationTimezone = this.currentLocation!.timezone;
    const locationOffset = (getTimezoneOffset(locationTimezone) || 0) / -60000; // offset in minutes
    const userOffset = new Date().getTimezoneOffset(); //offset in minutes
    return locationOffset - userOffset;
  }

  @action
  getOfficeLocationTime(userLocationTime: Date): Date {
    return addMinutes(userLocationTime, this.minutesBetweenTimezones);
  }

  // Initializes the app's state optimally by running requests concurrently
  initializeState = dropTask(async (loadedFrom?: string) => {
    this.datadog.measure();

    /*
      The requests are batched into 3 groups, prioritizing the earliest
      a request can be sent. Each batch is dependent on information
      requested in the batch before it.
    */

    // BATCH #1
    // Request user, company, location(s), zone(s) & subscription
    const batch1 = await Promise.all([
      this.initUserState.perform(),
      this.initCompanyState.perform(),
      this.initZoneState.perform(),
      this.preloadLocations.perform(loadedFrom),
      this.store.query('subscription', { filter: { company_id: this._companyId } }),
    ]);

    // Configures currentLocation using prefetched data
    await this.initLocationState.perform(loadedFrom);

    // BATCH #2
    // Request split flags & feature configuration
    if (this.currentCompany && this.currentUser && this.currentLocation) {
      this.locationFeatureFlags.setProperty(this.currentLocation);

      await Promise.all([
        this._setupUserAndCompanyFeatureFlags(this.currentUser, this.currentCompany), // User & Company feature flags
        this.locationFeatureFlags.ready(), // Location feature flags
        this.featureConfig.fetchFeatureConfigTask.perform(this.currentLocation.id), // Feature config
      ]);
    }

    // Configures subscription info using prefetched data
    await this.initSubscriptionStateTask.perform(undefined, batch1[4].toArray());

    // Request user's roles in background
    void this.authz.fetchRoles();

    // BATCH #3
    // Send remaining requests
    const promises = [
      this.loadTenantConnectionRequests.perform(),
      this.userDocumentService.initialize.perform(),
      this.employeeScreeningFlowService.initService(),
    ];

    if (this.currentCompany) {
      promises.push(
        this.authz.fetchPermissions(),
        this.inactivity.initialize(),
        this.fetchCompanyConfigMeta.perform(),
        this.fetchCompanyBilling.perform()
      );
    }

    if (this.currentLocation) {
      promises.push(this.loadTenantConnections.perform());
    }

    if (this.currentLocation && this.currentUser && this.currentCompany) {
      promises.push(
        this.currentAdmin.setEmployeeTask.perform({
          currentUser: this.currentUser,
          currentCompany: this.currentCompany,
          currentLocation: this.currentLocation,
        }),
        this.currentAdmin.setRolesTask.perform({
          currentUser: this.currentUser,
          currentCompany: this.currentCompany,
          currentLocation: this.currentLocation,
        })
      );
    }

    await Promise.all(promises);
  });
}

class StateServiceInUse extends StateService {
  declare currentCompany: NonNullable<StateService['currentCompany']>;
  declare currentUser: NonNullable<StateService['currentUser']>;
  declare currentLocation: NonNullable<StateService['currentLocation']>;
  declare billingCompany: NonNullable<StateService['billingCompany']>;
}

export default StateServiceInUse;

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    state: StateServiceInUse;
  }
}
