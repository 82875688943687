import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';

export default class ViewLocationsModal extends Component {
  @service locations;
  @service statsig;

  @tracked activeLocations = this.locations.active;
  @tracked paidLicenses = this.args.vrSubscription.quantity;
  @tracked unusedLicensesAmount = this.paidLicenses - this.activeLocations.length;
  @tracked groupExists = this.activeLocations.any((l) => {
    return l.group;
  });

  @tracked namesSorted = false;
  @tracked groupsSorted = false;

  @tracked groupClassExtra = this.activeLocations.length >= 7 ? 'relative' : '';
  @tracked groupStyleExtra = this.activeLocations.length >= 7 ? htmlSafe('right: 7px') : htmlSafe('');

  constructor() {
    super(...arguments);
    this.statsig.logEvent('billing-locations-modal-viewed');
  }

  @action
  toggleSortLocationName() {
    if (!this.namesSorted) {
      this.activeLocations = this.activeLocations.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    } else {
      const nonNullReversed = [...this.activeLocations.filter((l) => l.name).reverse()];
      this.activeLocations = nonNullReversed;
    }
    this.namesSorted = true;
    this.groupsSorted = false;
  }

  @action
  toggleSortGroup() {
    if (!this.groupsSorted) {
      this.activeLocations = this.activeLocations.sort((a, b) => {
        if (!a.group) return 1;
        if (!b.group) return -1;
        return a.group.name.localeCompare(b.group.name);
      });
    } else {
      const nonNullReversed = [
        ...this.activeLocations.filter((l) => l.group?.name).reverse(),
        ...this.activeLocations.filter((l) => !l.group),
      ];
      this.activeLocations = nonNullReversed;
    }
    this.groupsSorted = true;
    this.namesSorted = false;
  }
}
