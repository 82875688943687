import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { dasherize } from '@ember/string';
import { scheduleOnce } from '@ember/runloop';
import { dedupeTracked } from 'tracked-toolbox';

const id = (parentId, child) => `${parentId}-${child.id || dasherize(child)}`;

/**
 * @param {String}          activeOptionClass
 * @param {String}          ariaLabel
 * @param {String}          ariaLabelledby
 * @param {String}          closestOption
 * @param {String}          emptyOption
 * @param {String}          inputClass
 * @param {String}          inputId
 * @param {String}          invalidInputClass
 * @param {String}          invalidLabelClass
 * @param {Boolean}         isDisabled
 * @param {Boolean}         isLoading
 * @param {Boolean}         isReadonly
 * @param {String}          listboxClass
 * @param {Function}        onInput
 * @param {Function}        onFocusOut
 * @param {Function}        onSelect
 * @param {Array<String>}   options
 * @param {String}          optionClass
 * @param {String}          placeholder
 * @param {Boolean}         scrollToSelection
 * @param {String}          searchField
 * @param {String}          selected
 * @param {String}          selectedOptionClass
 * @param {String}          type
 * @param {Function}        validateUserInput
 */
export default class ComboBox extends Component {
  @tracked element;
  @tracked emptyOption;
  @tracked onSelect;
  @tracked onInput;
  @tracked onFocusOut;
  @tracked scrollToSelection;
  @tracked type;
  @tracked validateUserInput;
  @tracked isUserInputValid = true;
  @dedupeTracked shouldShowListbox = false;
  @dedupeTracked activedescendant = null;

  constructor() {
    super(...arguments);

    this.emptyOption = this.args.emptyOption ?? 'no options';
    this.onSelect = this.args.onSelect ?? (() => {});
    this.onInput = this.args.onInput ?? (() => {});
    this.onFocusOut = this.args.onFocusOut ?? (() => {});
    this.scrollToSelection = this.args.scrollToSelection ?? true;
    this.type = this.args.type ?? 'text';
    this.validateUserInput = this.args.validateUserInput ?? (() => true);
  }

  @action
  registerElemet(element) {
    this.element = element;
  }

  // tracks the id of the currently focused option
  get selectedValue() {
    return this.args.selected && this.args.searchField
      ? this.args.selected[this.args.searchField]
      : this.args.selected || '';
  }

  get value() {
    if (!this.args.selected || !this.isUserInputValid) {
      return this.userInput;
    } else {
      return this.selectedValue;
    }
  }

  get optionIds() {
    return (this.args.options || []).map((option) => id(this.element.id, option));
  }

  get selectedId() {
    if (this.args.selected) {
      return id(this.element.id, this.args.selected);
    }
    return null;
  }

  get closestOptionId() {
    if (this.args.closestOption) {
      return id(this.element.id, this.args.closestOption);
    }
    return null;
  }

  @action
  focusIn() {
    this._handleFocusIn.call(this);
  }

  @action
  focusOut({ relatedTarget }) {
    // focusOut fires when focus is moved to the options
    // only handle a focusOut if focus is actual leaving the component
    if (!this.element.contains(relatedTarget)) {
      this.onFocusOut();
      this._handleFocusOut.call(this);
    }
  }

  @action
  keyUp(e) {
    // handle keyboard events. allow user to navigate combobox according to WAI-ARIA Combobox spec
    switch (e.key) {
      case 'Up': // IE/Edge specific value
      case 'ArrowUp':
        this._handleArrowUp.call(this);
        e.stopPropagation();
        e.preventDefault();
        break;

      case 'Down': // IE/Edge specific value
      case 'ArrowDown':
        this._handleArrowDown.call(this);
        e.stopPropagation();
        e.preventDefault();
        break;

      case 'Enter':
        this._handleEnter.call(this);
        e.stopPropagation();
        e.preventDefault();
        break;

      case 'Esc': // IE/Edge specific value
      case 'Escape':
        this._handleEscape.call(this);
        e.stopPropagation();
        e.preventDefault();
        break;
    }
  }

  showListBox() {
    const { isDisabled, isReadonly } = this.args;

    this.shouldShowListbox = !(isDisabled || isReadonly);
  }

  _handleArrowUp() {
    //    select the previous selection
    // OR return focus to the input if there is no previous selection
    const input = this.element.querySelector('[role="combobox"]');
    let activeOptionId = null;
    const activeOptionIndex = this.optionIds.indexOf(this.activedescendant);
    if (activeOptionIndex === 0) {
      input && input.focus();
    } else if (activeOptionIndex !== -1) {
      activeOptionId = this.optionIds[activeOptionIndex - 1];
    } else if (this.closestOptionId) {
      activeOptionId = this.closestOptionId;
    }
    const activeOptionEl = document.getElementById(activeOptionId);
    activeOptionEl && activeOptionEl.focus();
    this.activedescendant = activeOptionId;
  }

  _handleArrowDown() {
    //    noop if at bottom of list and has active option
    // OR focus the next option if has active option
    // OR focus the selected option if no active option and has selection
    // OR focus the first option if no active option and no selection
    let activeOptionId = null;
    if (!this.shouldShowListbox) {
      this.showListBox();
      this._scrollToSelection.call(this);
    }
    if (this.activedescendant) {
      const activeOptionIndex = this.optionIds.indexOf(this.activedescendant);
      if (activeOptionIndex !== this.optionIds.length - 1) {
        activeOptionId = this.optionIds[activeOptionIndex + 1];
      } else {
        activeOptionId = this.activedescendant;
      }
    } else if (this.args.selected) {
      const selectedIndex = this.optionIds.indexOf(this.selectedId);
      // the selected item may not exist in the options
      // if it doesn't use the closest option, otherwise use the first option
      if (selectedIndex !== -1) {
        activeOptionId = this.optionIds[selectedIndex];
      } else if (this.closestOptionId) {
        activeOptionId = this.closestOptionId;
      } else {
        activeOptionId = this.optionIds[0];
      }
    } else {
      activeOptionId = this.optionIds[0];
    }
    const activeOptionEl = document.getElementById(activeOptionId);
    activeOptionEl && activeOptionEl.focus();
    this.activedescendant = activeOptionId;
  }

  _handleEnter() {
    //    select the focused suggestion
    // OR select the first suggestion as a default
    const activeOptionEl = document.getElementById(this.activedescendant || this.optionIds[0]);
    activeOptionEl && activeOptionEl.click();
  }

  _handleEscape() {
    // clear the userInput and suggestions, and return focus to input
    const input = this.element.querySelector('[role="combobox"]');
    this.userInput = '';
    this.activedescendant = null;
    input && input.focus();
    this.shouldShowListbox = false; // hide the listbox after focusIn fires
  }

  _handleFocusIn() {
    if (!this.shouldShowListbox) {
      this.showListBox();
      this._scrollToSelection.call(this);
    }
  }

  _handleFocusOut() {
    if (!this.isUserInputValid) {
      // prevent the input being left in an invalid state
      this.userInput = this.selectedValue;
      this.isUserInputValid = true;
    }
    this.shouldShowListbox = false;
    this.activedescendant = null;
  }

  _scrollToSelection() {
    let scrollToId;
    if (this.optionIds.includes(this.selectedId)) {
      scrollToId = this.selectedId;
    } else if (this.optionIds.includes(this.closestOptionId)) {
      scrollToId = this.closestOptionId;
    }
    if (this.scrollToSelection && scrollToId) {
      // schedule afterRender to make sure the dropdown options are rendered
      /* eslint-disable ember/no-incorrect-calls-with-inline-anonymous-functions */
      scheduleOnce('afterRender', this, function () {
        if (this.isDestroying || this.isDestroyed) return;
        const el = document.getElementById(scrollToId);
        el && el.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'start' });
      });
      /* eslint-enable ember/no-incorrect-calls-with-inline-anonymous-functions */
    }
  }

  @action
  didType(event) {
    this.showListBox();
    let userInput = event.target.value;
    this.userInput = userInput;
    userInput = this.onInput(userInput) || userInput;
    const isUserInputValid = this.validateUserInput(userInput, this.selectedValue);
    this.isUserInputValid = isUserInputValid;
    if (isUserInputValid) {
      this.onSelect(userInput);
    }
  }

  @action
  shouldDisableOption(option) {
    const { shouldDisableOption } = this.args;
    if (shouldDisableOption) {
      return shouldDisableOption(option);
    } else {
      return false;
    }
  }

  @action
  didSelect(option) {
    if (!this.shouldDisableOption(option)) {
      const input = this.element.querySelector('[role="combobox"]');
      this.onSelect(option);
      this.isUserInputValid = true;
      this.activedescendant = null;
      input && input.focus();
      this.shouldShowListbox = false; // hide the listbox after focusIn fires
    }
  }

  @action
  didClickInput() {
    this.showListBox();
    this._scrollToSelection.call(this);
  }
}
