import Service, { inject as service } from '@ember/service';
import { dasherize } from '@ember/string';
import { get } from '@ember/object';
import Evented from '@ember/object/evented';
import moment from 'moment-timezone';

import urlBuilder from 'garaje/utils/url-builder';

// eslint-disable-next-line ember/no-classic-classes
export default Service.extend(Evented, {
  ajax: service(),
  store: service(),
  state: service(),

  sync() {
    const { currentCompany } = this.state;
    const companyId = currentCompany.id;

    return this.ajax
      .request(urlBuilder.v3.employees.synchronizeUrl(companyId), {
        type: 'POST',
        headers: { Accept: 'application/vnd.api+json' },
        data: {},
      })
      .then((response) => {
        const payload = {
          data: {
            id: companyId,
            type: 'company',
            attributes: {
              employeesSyncState: 'synchronizing',
              employeesLastSyncAt: moment().toISOString(),
            },
          },
        };
        this.store.push(payload);

        return response;
      });
  },
  companyFilter(integration, query) {
    const payload = { data: { query } };

    return this.getUsers(integration, payload);
  },
  locationsFilter(integration, query) {
    const payload = { data: { query } };

    return this.getUsers(integration, payload);
  },
  getUsers(integration, payload) {
    return this.ajax
      .request(this.usersUrl(integration), {
        data: payload,
        contentType: 'application/json',
      })
      .then((response) => {
        const users = response.data;

        users.forEach(function (user) {
          const phone = user.phone || (user.phones || []).mapBy('value').join(', ');
          user.phone_number = phone;
        });

        return users;
      });
  },
  usersUrl(integration) {
    let url;
    const id = get(integration, 'id');

    switch (dasherize(integration.constructor.typeKey)) {
      case 'one-login':
        url = urlBuilder.v2.integrations.oneLoginUsersUrl(id);
        break;
      case 'okta-integration':
        url = urlBuilder.v2.integrations.oktaUsersUrl(id);
        break;
    }

    return url;
  },
  syncMessage(data, company) {
    const payload = {
      id: get(company, 'id'),
      type: 'company',
      attributes: {
        employeesSyncState: data.state,
      },
    };

    const store = this.store;

    if (data.last_sync_at && data.state === 'finished') {
      payload.attributes.employeesLastSyncAt = data.last_sync_at;
    }

    store.push({ data: payload });
  },
  updateScimIntegrationState(data, company) {
    const scimIntegration = get(company, 'scimIntegration.content');

    if (scimIntegration && data.state) {
      scimIntegration.store.push({
        data: {
          id: get(scimIntegration, 'id'),
          type: 'scim-integration',
          attributes: {
            state: data.state,
          },
        },
      });
    }
  },
});
