import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
import { setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type LocationModel from 'garaje/models/location';
import type LocationSubscriptionModel from 'garaje/models/location-subscription';
import type SubscriptionModel from 'garaje/models/subscription';
import type TenantModel from 'garaje/models/tenant';
import type LocationsService from 'garaje/services/locations';
import type StateService from 'garaje/services/state';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';
import parseDateFromQP from 'garaje/utils/parse-date-from-qp';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import type { RecordArray } from 'garaje/utils/type-utils';
import handsontable from 'handsontable';
import moment from 'moment-timezone';
import { hash } from 'rsvp';

import type { VisitorsInvitesRouteModel } from '../route';

import type VisitorsInvitesNewController from './controller';

export interface VisitorsInvitesNewRouteParams {
  expectedArrivalTime: string;
  type: string;
  name: string;
  email: string;
  previousInvite: string;
}

export interface VisitorsInvitesNewRouteModel {
  location: LocationModel | null;
  locations: NativeArray<LocationModel>;
  vrSubscription: LocationSubscriptionModel | SubscriptionModel | null;
  handsontable: typeof handsontable;
  connectedTenants: RecordArray<TenantModel>;
}

class VisitorsInvitesNewRoute extends Route {
  declare controller: VisitorsInvitesNewController;

  @service declare locations: LocationsService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare transitionConfirm: TransitionConfirmService;

  titleToken = 'New invite';

  queryParams = {
    expectedArrivalTime: {
      as: 'targetDate',
      replace: true,
    },
    type: {
      refreshModel: true,
      replace: true,
    },
    name: {
      as: 'name',
      replace: true,
    },
    email: {
      as: 'email',
      replace: true,
    },
    previousInvite: {
      as: 'previousInvite',
      replace: true,
    },
  };

  // Prevent model/controller reset while waiting for confirmation.
  // Also prevent infinite loop bug
  // https://github.com/emberjs/ember.js/issues/12473#issuecomment-450166407
  beforeModel(transition: Transition): void {
    if (this.transitionConfirm.displayConfirmTask.isRunning) {
      void transition.abort();
    }
  }

  async model(): Promise<VisitorsInvitesNewRouteModel> {
    try {
      await this.locations.loadAllTask.perform();
    } catch (e) {
      throwUnlessTaskDidCancel(e);
    }
    const { vrSubscription, currentLocation: location } = this.state;
    const locations = A(this.locations.active).filterBy('preRegistrationEnabled', true);
    const { connectedTenants } = <VisitorsInvitesRouteModel>this.modelFor('visitors.invites');

    return hash({ location, locations, vrSubscription, handsontable, connectedTenants });
  }

  isGroupInviteType(type: string): boolean {
    const { vrSubscription } = this.state;

    if (!vrSubscription?.canAccessGroupInviteRecords) return false;

    return type === 'group';
  }

  redirect(): void {
    const { type } = <VisitorsInvitesNewRouteParams>this.paramsFor(this.routeName);
    const { vrSubscription } = this.state;

    if (type === 'multiple') {
      void this.router.transitionTo({ queryParams: { type: 'inline' } });
    }

    if (type === 'group' && !vrSubscription?.canAccessGroupInviteRecords) {
      void this.router.replaceWith('visitors.invites.new', { queryParams: { type: 'inline' } });
    }
  }

  setupController(
    controller: VisitorsInvitesNewController,
    model: VisitorsInvitesNewRouteModel,
    transition: Transition
  ): void {
    super.setupController(controller, model, transition);

    const { location } = model;
    const { name, email, previousInvite, expectedArrivalTime, type } = <VisitorsInvitesNewRouteParams>(
      this.paramsFor(this.routeName)
    );

    let newDateString = moment().format('YYYY-MM-DDTHH:mm');

    if (expectedArrivalTime) {
      const dateFromQP = moment(parseDateFromQP(expectedArrivalTime));
      const today = moment();
      const date = dateFromQP.isBefore(today, 'day') ? today : dateFromQP;
      newDateString = date.format('YYYY-MM-DDTHH:mm');
    }

    setProperties(controller, {
      expectedArrivalTime: newDateString,
      name,
      email,
      previousInvite,
    });

    if (this.isGroupInviteType(type)) {
      void controller.setupGroupInviteTask.perform(location!);
    } else {
      void controller.fetchData.perform(location!);
    }
  }

  resetController(controller: VisitorsInvitesNewController): void {
    setProperties(controller, {
      data: null,
      type: 'single',
      expectedArrivalTime: '',
      name: '',
      email: '',
      previousInvite: '',
    });
  }

  isTabChange(transition: Transition): boolean {
    // @ts-ignore queryParamsOnly is not defined or documented to be in Transition
    if (!transition.queryParamsOnly) return false;
    if (this.transitionConfirm.displayConfirmTask.isRunning) return false;

    const fromType = transition.from?.queryParams?.['type'];
    const toType = transition.to.queryParams?.['type'];

    if (!(fromType || toType)) return false;

    return fromType !== toType;
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const changeset = this.controller.buildInvite.lastSuccessful?.value?.changeset;
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const groupChangeset = this.controller.buildGroupInviteTask.lastSuccessful?.value?.changeset;
    const isDirty = changeset?.isDirty || groupChangeset?.isDirty;
    const isTabChange = this.isTabChange(transition);

    // No need to display confirmation modal when nothing is changed.
    // We also (still) prevent infinite loop bug
    // https://github.com/emberjs/ember.js/issues/12473
    // @ts-ignore queryParamsOnly is not defined or documented to be in Transition
    if (!isDirty || (transition.queryParamsOnly && !isTabChange)) {
      return;
    }

    // display confirmation modal if the form is dirty
    void this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue: () => {
        changeset?.rollback();
        groupChangeset?.rollback();
      },
    });
  }
}

export default VisitorsInvitesNewRoute;
