import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type MailerTemplateVersionModel from 'garaje/models/mailer-template-version';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { union } from 'macro-decorators';
import { all } from 'rsvp';

import type { SettingsEmailsTemplatesRouteModel } from './route';

const ACTIONS = {
  CREATE: 'create',
  MANAGE: 'manage',
  EDIT: 'edit',
};

export default class SettingsEmailsTemplatesController extends Controller {
  declare model: SettingsEmailsTemplatesRouteModel;

  queryParams = ['action'];

  @tracked action: string | null = null;

  @service declare flashMessages: FlashMessagesService;

  @union('model.mailerTemplateVersionsByCompany', 'model.mailerTemplateVersionsByLocation')
  mailerTemplateVersions!: MailerTemplateVersionModel[];

  get isCreateModalOpen(): boolean {
    return this.action === ACTIONS.CREATE;
  }

  set isCreateModalOpen(value: boolean) {
    this.action = value ? ACTIONS.CREATE : null;
  }

  get isManageModalOpen(): boolean {
    return this.action === ACTIONS.MANAGE;
  }

  set isManageModalOpen(value: boolean) {
    this.action = value ? ACTIONS.MANAGE : null;
  }

  get isEditing(): boolean {
    return this.action === ACTIONS.EDIT;
  }

  set isEditing(value: boolean) {
    this.action = value ? ACTIONS.EDIT : null;
  }

  @action
  async saveNewTemplateVersion(mailerTemplateVersion: MailerTemplateVersionModel): Promise<void> {
    const { flashMessages } = this;
    const { mailerTemplateVersionsByLocation, mailerTemplate } = this.model;
    try {
      await mailerTemplateVersion.save();
      await mailerTemplateVersionsByLocation.update();
      flashMessages.showAndHideFlash('success', 'Template created!');
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await this.transitionToRoute(
        'visitors.settings.emails.templates.versions',
        mailerTemplate.id,
        mailerTemplateVersion.id,
        {
          queryParams: {
            action: 'edit',
          },
        }
      );
    } catch (e) {
      flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      mailerTemplateVersion.rollbackAttributes();
      throw e;
    }
  }

  @action
  async saveRecipients(mailerTemplateVersions: MailerTemplateVersionModel[]): Promise<void> {
    const { flashMessages } = this;
    try {
      await all(mailerTemplateVersions.map((mailerTemplateVersion) => mailerTemplateVersion.save()));
      flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      throw e;
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'visitors.settings.emails.templates': SettingsEmailsTemplatesController;
  }
}
