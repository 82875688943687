import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type LocalStorageService from 'garaje/services/local-storage';
import type MessageBusService from 'garaje/services/message-bus';
import type StateService from 'garaje/services/state';
import urlBuilder from 'garaje/utils/url-builder';

interface Message {
  event: string;
  view: string;
}

export default class AttendanceController extends Controller {
  @service declare localStorage: LocalStorageService;
  @service declare state: StateService;
  @service declare messageBus: MessageBusService;
  @service declare featureFlags: FeatureFlagsService;

  @tracked loginRequired = false;
  @tracked view: string | undefined;

  constructor(...args: unknown[]) {
    // @ts-ignore
    super(...args);
    this.messageBus.on('embedded-app-message', this, (message: Message) => {
      this.handleMessage(message);
    });
  }

  @action
  handleMessage(message: Message): void {
    if (message.event === 'change_attendance_dashboard_view') {
      this.updateRouteParameter(message.view);
    }
  }

  updateRouteParameter(newValue: string): void {
    // Use transitionToRoute to navigate to the same route with updated parameters
    this.transitionToRoute({ queryParams: { view: newValue } });
  }

  get iframeUrl(): string {
    const companyId = this.state.currentCompany?.id || '';

    if (this.featureFlags.isEnabled('attendance-v2')) {
      return urlBuilder.embeddedAttendanceGlobalAnalyticsV2Url(this.loginRequired, companyId);
    }

    return urlBuilder.embeddedAttendanceGlobalAnalyticsUrl(this.loginRequired, companyId);
  }

  @action
  trackLoggedIn(): void {
    /**
     * Issue: When user logs out from the Garaje app there is no way Next.js app knows it also needs to logout.
     * Due to this the cookies of the logout user may persist.
     * Solution: After login, force login the user on his first visit to this app. This refreshes all the cookies
     * To know if the user is visiting for the first time we will maintain a global state in localstorage called 'wa_logged_in'
     * On Login -> wa_logged_in does not exists in localstorage -> its first visit
     * On first visit -> SignIn, set wa_logged_in = true in localstorage
     * On Logout -> Remove wa_logged_in
     */

    const waLoggedIn = this.localStorage.getItem('wa_logged_in');

    if (!waLoggedIn) {
      this.loginRequired = true;
      this.localStorage.setItem('wa_logged_in', true);
    } else {
      this.loginRequired = false;
    }
  }
}
