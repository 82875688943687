import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import moment from 'moment-timezone';
import { htmlSafe } from '@ember/template';

/**
 * This component renders two calendars for selecting a range of dates
 * @param {String}      startDate             Date formatted as string
 * @param {String}      endDate               Date formatted as string
 * @param {Function}    [didSelectDateRange]  Function triggered when the start or end date changes
 * @param {Function}    [dateRangeSelected]   Function triggered when complete range (both start and end dates) is selected. Does not fire when only one date is selected (as `onDateRangeSelect` does)
 * @param {Boolean}     noShadow              Whether to show the drop shadow, useful for in modals
 * @param {String}      classNames            Extra class names
 * @param {Function}    setStartDate          Function to set startDate
 * @param {Function}    setEndDate            Function to set endDate
 */

export default class EnvoyTwoUpCalendarComponent extends Component {
  @tracked isSelectingStartDate = true;
  @tracked date;
  @tracked offset = 0;

  get shadowClass() {
    return this.args.noShadow ? 'no-shadow' : '';
  }

  get popoverStyles() {
    return htmlSafe(`left: ${this.offset}px;`);
  }

  get selectedStartDate() {
    return moment(this.args.startDate);
  }

  get selectedEndDate() {
    return moment(this.args.endDate);
  }

  get currentMonthFormatted() {
    return this.currentMonth.format('MMMM');
  }

  get currentMonth() {
    return moment(this.date).startOf('month');
  }

  get nextMonthFormatted() {
    return this.nextMonth.format('MMMM');
  }

  get nextMonth() {
    return moment(this.date).add(1, 'month');
  }

  constructor() {
    super(...arguments);
    this.date = moment(this.args.startDate).startOf('month');
  }

  @action
  goBackOneMonth() {
    this.date = moment(this.date).subtract(1, 'month');
    this.args.handleGoBackOneMonth?.(this.date.toDate());
  }

  @action
  goToNextMonth() {
    this.date = moment(this.date).startOf('month').add(1, 'month');
    this.args.handleGoToNextMonth?.(this.date.toDate());
  }

  @action
  selectDate(selectedDate) {
    if (this.isSelectingStartDate) {
      const startMonth = moment(selectedDate).startOf('month');

      this.date = startMonth;
      this.isSelectingStartDate = false;
      const startOfDay = moment(selectedDate).startOf('day');
      this.args.setStartDate(startOfDay);

      if (this.selectedStartDate.isAfter(this.selectedEndDate)) {
        const endOfDay = moment(selectedDate).endOf('day');
        this.args.setEndDate(endOfDay);
      }
      this.args.didSelectDateRange?.(this.args.startDate, this.args.endDate);
    } else {
      const startMonth = moment(this.args.startDate).startOf('month');
      const selectedStartDate = this.selectedStartDate;

      this.args.setEndDate(selectedDate.endOf('day'));
      this.date = startMonth;
      this.isSelectingStartDate = true;

      if (this.selectedEndDate.isBefore(selectedStartDate)) {
        this.args.setStartDate(selectedDate.startOf('day'));
        this.args.setEndDate(selectedStartDate.endOf('day'));
      }
      this.args.didSelectDateRange?.(this.args.startDate, this.args.endDate);
      this.args.dateRangeSelected?.(this.args.startDate, this.args.endDate);
    }
  }

  @action
  selectMonth(selectedMonth) {
    this.args.setStartDate(moment(selectedMonth).startOf('month'));
    this.args.setEndDate(moment(selectedMonth).endOf('month'));
    this.args.didSelectDateRange?.(this.args.startDate, this.args.endDate);
    this.args.dateRangeSelected?.(this.args.startDate, this.args.endDate);
  }

  @action
  checkPositioning() {
    // fix positioning if popover is offscreen
    const $popover = document.querySelector('.calendar-popover');
    if (!$popover) return;

    const clientRect = $popover.getBoundingClientRect();
    const xRightPosition = clientRect.x + clientRect.width;

    if (xRightPosition > window.innerWidth) {
      this.offset = window.innerWidth - xRightPosition - 20;
    }
  }
}
