import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type AnnouncementTemplateModel from 'garaje/models/announcement-template';
import type AnnouncementTemplateCategoryModel from 'garaje/models/announcement-template-category';
import type StateService from 'garaje/services/state';

import type CommunicationsTemplateListController from './controller';

export interface TemplateLocationMap {
  templateId: string;
  templateName: string;
  locations: { name: string }[];
}

export type CommunicationsTemplateListModel = {
  announcementTemplates: PaginatedRecordArray<AnnouncementTemplateModel>;
  announcementTemplateCategories: ArrayProxy<AnnouncementTemplateCategoryModel>;
};

export default class CommunicationsTemplateListRoute extends Route {
  @service declare store: Store;
  @service declare state: StateService;
  @service declare router: RouterService;

  async model(): Promise<CommunicationsTemplateListModel | void> {
    const announcementTemplates = await this.loadAnnouncementTemplates();
    const announcementTemplateCategories = this.store.peekAll('announcement-template-category');

    return {
      announcementTemplates,
      announcementTemplateCategories,
    };
  }

  redirect(model: CommunicationsTemplateListModel): void {
    if (!model.announcementTemplates.length) {
      return void this.router.transitionTo('location-overview.communications.templates.get-started');
    }
  }

  async loadAnnouncementTemplates(offset = 0, limit = 25): Promise<PaginatedRecordArray<AnnouncementTemplateModel>> {
    try {
      const result = await this.store.query('announcement-template', {
        page: {
          limit: limit,
          offset: offset,
        },
        sort: '-createdAt',
        include: 'locations',
      });

      return result as PaginatedRecordArray<AnnouncementTemplateModel>;
    } catch (error) {
      throw new Error('Failed to load announcement templates');
    }
  }

  setupController(
    controller: CommunicationsTemplateListController,
    model: CommunicationsTemplateListModel,
    transition: Transition
  ): void {
    super.setupController(controller, model, transition);
    void controller.initializeData.perform();
  }
}
