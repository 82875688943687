import { action } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type StoreService from '@ember-data/store';
import type UserDocumentModel from 'garaje/models/user-document';
import type { Identifier } from 'garaje/models/user-document-template';
import type UserDocumentTemplateModel from 'garaje/models/user-document-template';
import type StateService from 'garaje/services/state';
import type UserDocumentService from 'garaje/services/user-document';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

interface ProfileDocumentRouteParams {
  document: Identifier;
}

export interface ProfileDocumentRouteModel {
  userDocumentTemplate?: UserDocumentTemplateModel;
  userDocuments: RecordArray<UserDocumentModel>;
}

export default class ProfileDocumentRoute extends Route {
  @service declare router: RouterService;
  @service declare state: StateService;
  @service('user-document') declare userDocumentService: UserDocumentService;
  @service declare store: StoreService;

  beforeModel(): void {
    const { document: identifier } = <ProfileDocumentRouteParams>this.paramsFor(this.routeName);
    if (!this.userDocumentService.isActive(identifier)) {
      void this.router.replaceWith('profile');
    }
  }

  model({ document: identifier }: ProfileDocumentRouteParams): Promise<ProfileDocumentRouteModel> {
    const userDocumentTemplate = this.userDocumentService.userDocumentTemplates?.findBy('identifier', identifier);
    const userDocuments = this.store.query('user-document', {
      include:
        'user-document-template,user-document-links,user-document-attachments,user-document-location-contexts,user-document-attachments.user-document-template-attachment',
      filter: {
        user: this.state.currentUser.id,
        'user-document-template-identifier': identifier,
      },
      sort: '-created-at',
    });

    return hash({
      userDocumentTemplate,
      userDocuments,
    });
  }

  afterModel(model: ProfileDocumentRouteModel): void {
    if (!isPresent(model.userDocumentTemplate)) {
      throw 'No UserDocumentTemplate found for identifier.';
    }
  }

  @action
  documentListAltered(): void {
    void this.refresh();
  }
}
