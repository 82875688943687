import Route from '@ember/routing/route';
import { hash } from 'rsvp';

export default class VisitorsSettingsVisitorTypesFlowPlusOneRoute extends Route {
  model() {
    const { flow, vrSubscription, currentLocation } = this.modelFor('visitors.settings.visitor-types.flow');

    return hash({
      flow,
      vrSubscription,
      currentLocation,
    });
  }
}
