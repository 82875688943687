import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type DetailedChangeset } from 'ember-changeset/types';
import { dropTask, type Task } from 'ember-concurrency';
import type ZoneModel from 'garaje/models/zone';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

interface PropertyFormCoverPhotoConfigArgs {
  changeset: DetailedChangeset<ZoneModel>;
  property: ZoneModel;
  onPhotoLoading: (isLoading: boolean) => unknown;
  onPendingUpload?: (pendingUpload: PendingUpload | null) => unknown;
  saveTask: Task<void, [DetailedChangeset<ZoneModel>]>;
}

/**
 * @todo move this to user-document-uploader when in TS.
 */
export interface PendingUpload {
  file: File;
  upload: () => Promise<string>;
  reset: () => void;
}

export default class PropertyFormCoverPhotoConfig extends Component<PropertyFormCoverPhotoConfigArgs> {
  @tracked isOpen = <boolean>(<unknown>this.args.property.isNew);
  @tracked isPropertyNew = this.args.property.isNew;
  @tracked pendingUpload: PendingUpload | null = null;

  isEmpty = !this.args.changeset.coverPhotoUrl;

  get showEditButton(): boolean {
    return !this.isPropertyNew && !this.isOpen;
  }

  @action
  onPendingUpload(pendingUpload: PendingUpload): void {
    this.pendingUpload = pendingUpload;
    this.args.onPendingUpload?.(pendingUpload);
    // use file name to mark file dirty
    this.args.changeset.coverPhoto = pendingUpload.file.name;
  }

  @action
  onCancel(): void {
    this.isOpen = false;
    this.args.changeset.rollback();
  }

  saveTask = dropTask(async () => {
    this.args.onPhotoLoading(true);

    if (this.pendingUpload) {
      this.args.changeset.coverPhoto = <string>await this.pendingUpload.upload().catch(throwUnlessTaskDidCancel);
    }
    await this.args.saveTask.perform(this.args.changeset).catch(throwUnlessTaskDidCancel);

    this.args.onPhotoLoading(false);
    this.pendingUpload = null;
    this.isOpen = false;

    this.isEmpty = !this.args.changeset.coverPhotoUrl;
  });

  @action
  reset(): void {
    this.pendingUpload?.reset();

    if (this.isEmpty) {
      this.args.changeset.rollbackProperty('coverPhoto');
    } else {
      this.args.changeset.coverPhoto = null;
    }

    this.pendingUpload = null;
    this.args.onPendingUpload?.(null);
  }
}
