import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import { findAllDeliveryAreasForMultipleLocations } from 'garaje/utils/delivery-area';

export default class DeliveriesIndexRoute extends Route {
  @service abilities;
  @service locations;
  @service state;
  @service router;
  @service store;

  beforeModel() {
    const locations = get(this, 'locations.model');
    const { deliveriesSubscription, workplaceSubscription } = this.state;
    if (!deliveriesSubscription && !workplaceSubscription) {
      return this.router.transitionTo('deliveries.landing-page');
    }

    return findAllDeliveryAreasForMultipleLocations(this.store, locations).then((deliveryAreas) => {
      const hasDeliveryArea = get(deliveryAreas, 'length') > 0;

      if (hasDeliveryArea && this.abilities.can('visit delivery-log')) {
        return this.router.transitionTo('deliveries.log.index');
      }

      return this.router.transitionTo('deliveries.get-started');
    });
  }
}
