import AjaxService from 'ember-ajax/services/ajax';
import { inject as service } from '@ember/service';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';

import config from 'garaje/config/environment';

export default class extends AjaxService {
  @service cookieAuth;
  @service session;

  trustedHosts = config.trustedHosts;

  @computed('session.isAuthenticated')
  get headers() {
    const headers = { Accept: 'application/json; charset=utf-8' };

    if (this.session.isAuthenticated) {
      headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;
    }

    return headers;
  }

  options() {
    const options = super.options(...arguments);

    return this.cookieAuth.jQueryDecorator(options.url, options.type, options);
  }

  normalizeErrorResponse(status, headers, payload) {
    if (payload && typeof payload === 'object') {
      // TODO-FIXME-API
      if (!payload.error && payload.meta && payload.meta.message) {
        payload.errors = [payload.meta.message];
      }

      return payload.errors || payload.error || payload;
    } else {
      return [
        {
          status: `${status}`,
          title: 'The backend responded with an error',
          detail: `${payload}`,
        },
      ];
    }
  }
}
