import { setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type DeviceModel from 'garaje/models/device';
import type LocationModel from 'garaje/models/location';
import type PrinterModel from 'garaje/models/printer';
import type StateService from 'garaje/services/state';
import type VisitorsOnboardingService from 'garaje/services/visitors-onboarding';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

import type { VisitorsDevicesIpadsRouteModel } from '../route';

import type DevicesIpadsNewController from './controller';

interface VisitorsDevicesIpadsNewRouteModel {
  location: LocationModel;
  device: DeviceModel;
  printers: RecordArray<PrinterModel>;
}

export default class VisitorsDevicesIpadsNewRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service declare visitorsOnboarding: VisitorsOnboardingService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  beforeModel(): void {
    if (this.abilities.cannot('create devices')) {
      void this.router.transitionTo('visitors.devices');
    }
  }

  model(): Promise<VisitorsDevicesIpadsNewRouteModel> {
    const { location, printers } = <VisitorsDevicesIpadsRouteModel>this.modelFor('visitors.devices.ipads');
    const device = this.store.createRecord('device', { location });
    return hash({ device, location, printers });
  }

  setupController(
    controller: DevicesIpadsNewController,
    model: VisitorsDevicesIpadsNewRouteModel,
    transition: Transition
  ): void {
    super.setupController(controller, model, transition);
    // manually set visitorsOnboarding values on controller to persist state of the onboarding service
    // at the time that the user entered the route
    const { isEnabled, currentRouteStep, currentStep, nextStep } = this.visitorsOnboarding;
    setProperties(controller, {
      hasPairedIpads: this.areAnyIpadsPaired(),
      isOnboardingEnabled: isEnabled,
      currentRouteStep,
      currentStep,
      nextStep,
    });
  }

  resetController(controller: DevicesIpadsNewController): void {
    setProperties(controller, {
      hasPairedIpads: null,
      isOnboardingEnabled: null,
      currentRouteStep: null,
      currentStep: null,
      nextStep: null,
    });
  }

  areAnyIpadsPaired(): boolean {
    const locationId = this.state.currentLocation.id;
    const pairedIpads = this.store.peekAll('device').filter((device) => {
      return device.isIpad && !device.isNew && device.belongsTo('location').id() === locationId;
    });
    return pairedIpads.length > 0;
  }
}
