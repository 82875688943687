import { warn } from '@ember/debug';
import { registerDestructor } from '@ember/destroyable';
import { set } from '@ember/object';
import Evented from '@ember/object/evented';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { getUnixTime } from 'date-fns';
import { enqueueTask } from 'ember-concurrency';
import config from 'garaje/config/environment';
import type CompanyModel from 'garaje/models/company';
import type LocationModel from 'garaje/models/location';
import type UserModel from 'garaje/models/user';
import type CurrentLocationService from 'garaje/services/current-location';
import type StateService from 'garaje/services/state';
import { EXPERIMENTS } from 'garaje/utils/statsig-config';
import statsig from 'statsig-js';
import type { DynamicConfig } from 'statsig-js';
import type { StatsigUser } from 'statsig-js/dist/StatsigUser';

function shutdown(instance: StatsigService) {
  if (instance.initialized) {
    statsig.shutdown();
  }
}
export default class StatsigService extends Service.extend(Evented) {
  @service declare state: StateService;
  @service declare currentLocation: CurrentLocationService;

  @tracked user: UserModel | null = null;
  @tracked company: CompanyModel | null = null;
  @tracked location: LocationModel | null = null;
  @tracked initialized = false;

  constructor(properties: Record<string, unknown>) {
    super(properties);
    registerDestructor(this, shutdown);
  }

  initSdk = enqueueTask(async () => {
    try {
      if (this.user === null) {
        this.trigger('wait');
        return;
      }

      if (this.initialized) {
        await statsig.updateUser(this.statsigUser);
      } else {
        const localMode = config.statsigApiKey === 'client-sdk-key';
        const options: { environment: { tier: string }; localMode: boolean; initTimeoutMs: number; api?: string } = {
          environment: { tier: config.environment },
          localMode: localMode,
          initTimeoutMs: 9100,
        };
        if (config.statsigApiUrl) {
          options.api = config.statsigApiUrl;
        }
        await statsig.initialize(config.statsigApiKey, this.statsigUser, options);

        statsig.removeGateOverride();
        statsig.removeConfigOverride();

        if (localMode) {
          Object.entries(config.gates).forEach(([key, value]) => {
            statsig.overrideGate(key, value === 'false' ? false : !!value);
          });

          Object.entries(config.experiments).forEach(([key, value]) => {
            if (value === 'on' && EXPERIMENTS[<keyof typeof EXPERIMENTS>key]) {
              statsig.overrideConfig(key, EXPERIMENTS[<keyof typeof EXPERIMENTS>key]);
            }
          });
        }

        this.initialized = true;
      }

      this.trigger('update');
    } catch (e) {
      this.trigger('error');
    }
  });

  setProperty<T extends keyof StatsigService>(prop: T, value: StatsigService[T]): void {
    warn(`Unknown statsig field: ${prop}`, !Object.prototype.hasOwnProperty.call(this, prop), {
      id: 'garaje.statsig-service.unknown-property',
    });
    set(this, prop, value);
    void this.initSdk.perform();
  }

  setPropertiesBatch(props: Partial<StatsigService>): void {
    Object.keys(props).forEach((prop) => {
      if (!Object.prototype.hasOwnProperty.call(this, prop)) {
        warn(`Unknown statsig field: ${prop}`, true, {
          id: 'garaje.statsig-service.unknown-property',
        });
      }
      // @ts-ignore
      set(this, prop, props[prop as keyof StatsigService]);
    });
    void this.initSdk.perform();
  }

  get statsigUser(): StatsigUser {
    const company_created_at = this.company ? getUnixTime(this.company.createdAt) : undefined;
    const now_in_seconds = Math.floor(Date.now() / 1000);
    const company_age = this.company ? now_in_seconds - <number>company_created_at : undefined;
    const employees_count = this.state._companyMeta ? this.state._companyMeta['employees-count'] : undefined;

    const statsigUser: StatsigUser = {
      email: this.user?.email ?? undefined,
      userID: this.user?.id ?? undefined,
      custom: {
        company_created_at,
        company_age,
        employees_count,
      },
      customIDs: {},
    };

    if (this.company) {
      statsigUser.customIDs!['company_id'] = this.company.id;
    }

    if (this.location) {
      statsigUser.customIDs!['location_id'] = this.location.id;
    }

    return statsigUser;
  }

  isEnabled(name: string, checkWithExposure: boolean = true): boolean {
    if (checkWithExposure) {
      return this.initialized && statsig.checkGate(name);
    }
    return this.initialized && statsig.checkGateWithExposureLoggingDisabled(name);
  }

  manuallyLogGateExposure(name: string): void {
    if (this.initialized) {
      statsig.manuallyLogGateExposure(name);
    }
  }

  getExperiment(name: string): boolean | DynamicConfig {
    return this.initialized && statsig.getExperiment(name);
  }

  getConfig(name: string): boolean | DynamicConfig {
    return this.initialized && statsig.getConfig(name);
  }

  logEvent(event_name: string, event_value: string | null = null, metadata = {}): void {
    statsig.logEvent(event_name, event_value, metadata);
  }
}
