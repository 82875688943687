import { A } from '@ember/array';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type ConnectLocationConfigurationModel from 'garaje/models/connect-location-configuration';
import type FlowModel from 'garaje/models/flow';
import type IdScanPageModel from 'garaje/models/id-scan-page';
import type LocationModel from 'garaje/models/location';
import type LocationSubscriptionModel from 'garaje/models/location-subscription';
import type SubscriptionModel from 'garaje/models/subscription';
import type UserModel from 'garaje/models/user';
import type VisitorsSettingsInvitesController from 'garaje/pods/visitors/settings/invites/controller';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type GlobalSettingBatchService from 'garaje/services/global-setting-batch';
import type StateService from 'garaje/services/state';
import type { ModelFrom } from 'garaje/utils/type-utils';
import { hash, all } from 'rsvp';

interface VisitorsSettingsInvitesRouteModel {
  allFlowsForCurrentLocation: FlowModel[];
  currentLocation: LocationModel | null;
  vrSubscription: LocationSubscriptionModel | SubscriptionModel | null;
  currentUser: UserModel | null;
  connectLocationConfiguration?: ConnectLocationConfigurationModel;
}

class VisitorsSettingsInvitesRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare featureFlags: FeatureFlagsService;

  @service('global-setting-batch') declare globalSettingBatchService: GlobalSettingBatchService;

  titleToken = 'Invite Settings';

  beforeModel(transition: Transition): void {
    void super.beforeModel(transition);
    if (!this.state.currentLocation?.preRegistrationEnabled) {
      void this.router.transitionTo('visitors.settings.pre-registration-upgrade');
    }
  }

  async model(): Promise<VisitorsSettingsInvitesRouteModel> {
    const { currentUser, vrSubscription, currentLocation } = this.state;
    let allFlowsForCurrentLocation:
      | VisitorsSettingsInvitesRouteModel['allFlowsForCurrentLocation']
      | Promise<FlowModel[]> = A();

    if (this.abilities.can('propagate global-setting-batch')) {
      this.globalSettingBatchService.createGlobalSettingBatch({ parent: currentLocation });
    }
    if (this.abilities.can('enable touchless-sign-in')) {
      allFlowsForCurrentLocation = this.state.loadFlows({ reload: false });
    }

    const isConnectedToProperty = await currentLocation?.isConnectedToProperty();
    let connectLocationConfiguration: ConnectLocationConfigurationModel | undefined;

    if (this.featureFlags.isEnabled('connect-pre-reg-acs-qr-code') && isConnectedToProperty) {
      connectLocationConfiguration = await currentLocation?.getConnectConfiguration();
    }
    await currentLocation?.locationAdmin;

    return hash({
      allFlowsForCurrentLocation,
      currentLocation,
      vrSubscription,
      currentUser,
      connectLocationConfiguration,
    });
  }

  afterModel(model: VisitorsSettingsInvitesRouteModel): Promise<IdScanPageModel[]> | void {
    if (this.abilities.can('enable touchless-sign-in')) {
      const flows = model.allFlowsForCurrentLocation.filter((flow) => flow.enabled);
      return all(flows.map((vt) => vt.idScanPage));
    }
  }

  setupController(controller: VisitorsSettingsInvitesController, model: ModelFrom<this>, transition: Transition): void {
    super.setupController(controller, model, transition);
    controller.editEmailVisible = true;
  }
}

export default VisitorsSettingsInvitesRoute;
