import Component from '@glimmer/component';
import { action, get, set } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { ZONE_ADMIN, ZONE_SECURITY } from 'garaje/utils/roles';
import { RoleId, RoleScopeType } from 'garaje/utils/custom-roles';
import { formatScimProvider } from 'garaje/utils/format-scim-provider';

/**
 * @param         role
 * @param         confirmRoleTask
 * @param         deleteRoleTask
 * @param         saveRoleTask
 */
export default class RolesListListItem extends Component {
  @service router;
  @service currentLocation;
  @service state;
  @service currentAdmin;
  @service authz;

  tooltipId = `${this.args.role?.id}-edit-admin-synced-icon`;

  get isEditable() {
    const { role } = this.args;
    if (get(this.currentAdmin, 'id') === role?.userId.toString()) {
      if (role.roleId === RoleId.GLOBAL_ADMIN) {
        // users cannot edit their own global admin role
        return false;
      }

      if (
        !this.currentAdmin.isGlobalAdmin &&
        role.roleId === RoleId.LOCATION_ADMIN &&
        role.roleScopeId.toString() === get(this.currentLocation, 'id')
      ) {
        // location admins cannot edit their location admin role at the current location
        return false;
      }
    }

    return role.editable;
  }

  get zoneRoleOptions() {
    const options = [ZONE_SECURITY, ZONE_ADMIN];
    return options;
  }

  get isZoneRole() {
    return this.args.role.roleScope === RoleScopeType.ZONE;
  }

  get roleOptions() {
    const { role } = this.args;
    const authzRoles = this.authz.roles;
    return authzRoles.filter((authzRole) => {
      if (
        !authzRole.deactivated &&
        authzRole.id !== RoleId.EMPLOYEE &&
        authzRole.id !== RoleId.COMPANY_START_FROM_SCRATCH &&
        authzRole.id !== RoleId.LOCATION_START_FROM_SCRATCH
      ) {
        if (authzRole.id === RoleId.ANALYTICS_VIEWER) {
          // only show role for workplace premium accounts
          const { workplaceSubscription } = this.state;
          if (workplaceSubscription && !workplaceSubscription.isBasicPlan) {
            return authzRole.roleScope === role.roleScopeType;
          }
        } else {
          return authzRole.roleScope === role.roleScopeType;
        }
      }
    });
  }

  get selectedRole() {
    return this.authz.roles.find((authzRole) => authzRole.id === this.args.role.roleId);
  }

  get isCurrentLocation() {
    // no current location on global overview
    if (this.router.currentRouteName?.includes('location-overview')) {
      return false;
    }

    const { role } = this.args;
    if (role.roleScope === RoleScopeType.LOCATION && get(this.currentLocation, 'id') === get(role, 'location.id')) {
      return true;
    }

    return false;
  }

  get formattedProvider() {
    const { currentCompany } = this.state;
    const scimIntegration = get(currentCompany, 'scimIntegration');
    if (scimIntegration) {
      const provider = get(scimIntegration, 'provider');
      if (provider) {
        return formatScimProvider(provider);
      }
    }

    return 'your SCIM provider';
  }

  get isManuallyAdded() {
    return isEmpty(this.args.role?.manuallyAdded) ? true : this.args.role.manuallyAdded;
  }

  get roleScopeName() {
    const { role } = this.args;
    if (role.roleScopeType === RoleScopeType.COMPANY) {
      return 'Company role';
    }

    if (
      role.roleScopeType === RoleScopeType.LOCATION &&
      role.roleScopeId?.toString() === get(this.currentLocation, 'id') &&
      !this.router.currentRouteName?.includes('location-overview')
    ) {
      return `${role.roleScopeName} (Current)`;
    }

    return role.roleScopeName;
  }

  @action
  deleteRole(role) {
    if (role.isNew || confirm('Are you sure you want to remove this role?')) {
      this.args.deleteRoleTask.perform(role);
    }
  }

  @action
  onRoleChange(authzRole) {
    const { role } = this.args;
    set(role, 'roleId', authzRole.id);
  }
}
