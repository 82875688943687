import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';

export default class SideBarGlobalMenuComponent extends Component {
  @service declare featureFlags: FeatureFlagsService;
  @service declare router: RouterService;
  @service declare state: StateService;

  get showAnalyticsUpsell(): boolean {
    const { vrSubscription } = this.state;

    return !vrSubscription?.canAccessGlobalAnalytics;
  }

  get isVisitorsOpen(): boolean {
    const routes = ['welcome', 'visitor-log', 'global-devices', 'analytics.dashboard', 'sign-in-flows'];
    return routes.some((routeName) => this.router.isActive(`location-overview.${routeName}`));
  }

  get isVirtualFrontDeskOpen(): boolean {
    return (
      this.router.isActive('location-overview.virtual-front-desk') &&
      !this.router.isActive('location-overview.virtual-front-desk.landing-page')
    );
  }

  get hasScheduledReports(): boolean {
    return this.state.vrSubscription?.canAccessScheduledReports ?? false;
  }

  get isProtectOpen(): boolean {
    return this.router.isActive('location-overview.protect.analytics');
  }

  get isWorkplaceOpen(): boolean {
    return this.router.isActive('location-overview.workplace.analytics');
  }

  get isDesksOpen(): boolean {
    return this.router.isActive('location-overview.desks.analytics');
  }

  get isVisitorAnalyticsOpen(): boolean {
    return this.router.isActive('location-overview.analytics.dashboard');
  }

  get isProtectGlobalAnalyticsOpen(): boolean {
    const routes = ['index', 'reports'];
    return routes.some((routeName) => this.router.isActive(`location-overview.protect.analytics.${routeName}`));
  }

  get isProtectGlobalReportsOpen(): boolean {
    return this.router.isActive('location-overview.protect.analytics.reports');
  }

  get isAnalyticsOpen(): boolean {
    const routes = ['visitors', 'protect', 'desks', 'reports', 'attendance'];
    const occupancyRoutes = ['analytics', 'settings'];
    return (
      routes.some((routeName) => this.router.isActive(`location-overview.analytics.${routeName}`)) ||
      occupancyRoutes.some((routeName) => this.router.isActive(`location-overview.occupancy.${routeName}`))
    );
  }

  get isAnalyticsOccupancyOpen(): boolean {
    return this.router.isActive('location-overview.occupancy.analytics');
  }

  get isAnalyticsAttendanceOpen(): boolean {
    return this.router.isActive('location-overview.analytics.attendance');
  }

  get isAnalyticsVisitorsOpen(): boolean {
    return this.router.isActive('location-overview.analytics.visitors');
  }

  get isAnalyticsProtectOpen(): boolean {
    return this.router.isActive('location-overview.analytics.protect');
  }

  get isAnalyticsDesksOpen(): boolean {
    return this.router.isActive('location-overview.analytics.desks');
  }

  get isAnalyticsReportsOpen(): boolean {
    return this.router.isActive('location-overview.analytics.reports');
  }

  get isAnalyticsSettingsOpen(): boolean {
    return this.router.isActive('location-overview.occupancy.settings');
  }

  get isLocationsOpen(): boolean {
    return this.router.isActive('location-overview.locations');
  }

  get isManageOpen(): boolean {
    return this.router.isActive('location-overview.manage');
  }

  get isCommunicationsOpen(): boolean {
    return this.router.isActive('location-overview.communications');
  }

  get isEmployeesOpen(): boolean {
    const routes = ['directory', 'import', 'sync-settings', 'scim-settings'];
    return routes.some((routeName) => this.router.isActive(`location-overview.employees.${routeName}`));
  }
}
