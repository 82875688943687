import DS from 'ember-data';
import { isNone } from '@ember/utils';

// eslint-disable-next-line ember/no-classic-classes
export default DS.Transform.extend({
  deserialize(serialized) {
    return isNone(serialized) ? null : Object.freeze(serialized);
  },

  serialize(deserialized) {
    return isNone(deserialized) ? null : deserialized;
  },
});
