import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { isEmpty, isBlank } from '@ember/utils';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { RoleId, RoleScopeType } from 'garaje/utils/custom-roles';

/**
 * @param {Array<Object>}       locations
 * @param {Boolean}             showCancelBtn
 * @param {Function}            onFinish
 */
class AddMapping extends Component {
  @service store;
  @service flashMessages;
  @service featureFlags;
  @service authz;
  @service workplaceMetrics;
  @service state;

  @tracked selectedGroup = null;
  @tracked selectedRole = '';
  @tracked selectedLocations = [];
  @tracked disableLocationDropdown = false;

  get roleOptions() {
    const companyRoles = [];
    const locationRoles = [];
    // no support for zone roles through scim right now
    this.authz.roles.forEach((role) => {
      if (role.id !== RoleId.EMPLOYEE && !role.deactivated) {
        if (role.roleScope === RoleScopeType.COMPANY && role.id !== RoleId.COMPANY_START_FROM_SCRATCH) {
          companyRoles.push(role);
        } else if (role.roleScope === RoleScopeType.LOCATION && role.id !== RoleId.LOCATION_START_FROM_SCRATCH) {
          locationRoles.push(role);
        }
      }
    });

    return [
      {
        groupName: 'Company roles',
        options: companyRoles,
      },
      {
        groupName: 'Location roles',
        options: locationRoles,
      },
    ];
  }

  get isAddDisabled() {
    return (
      isEmpty(this.selectedGroup) ||
      isBlank(this.selectedRole) ||
      !(this.selectedLocations && this.selectedLocations.length > 0)
    );
  }

  @action
  updateSelectedRole(role) {
    if (role.roleScope === RoleScopeType.COMPANY) {
      // company roles are not tied to locations, so reset the dropdown
      this.selectedLocations = [{ name: 'All locations' }];
      this.disableLocationDropdown = true;
    } else if (this.selectedRole.roleScope === RoleScopeType.COMPANY && role.roleScope !== RoleScopeType.COMPANY) {
      this.selectedLocations = [];
      this.disableLocationDropdown = false;
    }

    this.selectedRole = role;
  }

  @action
  selectGroup(employeeGroup) {
    if (employeeGroup) {
      this.selectedGroup = employeeGroup;
    }
  }

  @action
  onCancelClick() {
    this.args.onFinish();
    this.reset();
  }

  createMapping = task({ drop: true }, async () => {
    let locations = [];
    if (this.selectedRole.roleScope !== RoleScopeType.COMPANY) {
      locations = this.selectedLocations.map((loc) => loc.content);
    }

    const employeeGroupRole = this.store.createRecord('employee-group-role', {
      customRoleId: this.selectedRole.id,
      employeeGroup: this.selectedGroup,
      locations,
    });

    try {
      await employeeGroupRole.save();
      this.args.onFinish();
      this.flashMessages.showAndHideFlash('success', 'Group mapping created');
      this.trackMappingCreationForWorkplace();
      this.reset();
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  reset() {
    this.selectedGroup = '';
    this.selectedRole = '';
    this.selectedLocations = [];
    this.disableLocationDropdown = false;
  }

  trackMappingCreationForWorkplace() {
    const { workplaceSubscription } = this.state;

    if (workplaceSubscription?.canAccessScimAdminSync) {
      this.workplaceMetrics.trackEvent('SCIM_ADMINS_MAPPING_CREATED');
    }
  }
}

export default AddMapping;
