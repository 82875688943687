import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import type FlashMessagesService from 'garaje/services/flash-messages';

import { type PropertySecuritySettingsModel } from './route';

export default class PropertySecuritySettingsController extends Controller {
  declare model: PropertySecuritySettingsModel;

  @service declare flashMessages: FlashMessagesService;

  @tracked showApprovalsDisableModal = false;
  @tracked showApprovalsAutoCheckInDisableModal = false;

  toggleWalkupVisitorPhotosTask = dropTask(async (): Promise<void> => {
    const { property } = this.model;
    property.visitorPhotosEnabled = !property.visitorPhotosEnabled;

    try {
      await property.save();

      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch {
      this.flashMessages.showAndHideFlash('error', 'Something went wrong.');
      property.rollbackAttributes();
    }
  });

  toggleWalkinApprovalsTask = dropTask(async (bypass = false): Promise<void> => {
    const { connectPropertyConfiguration } = this.model;

    if (!connectPropertyConfiguration) return;

    if (connectPropertyConfiguration.inviteApprovalsEnabled && !bypass) {
      this.showApprovalsDisableModal = true;
      return;
    }

    connectPropertyConfiguration.inviteApprovalsEnabled = !connectPropertyConfiguration.inviteApprovalsEnabled;

    try {
      await connectPropertyConfiguration.save();

      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch {
      this.flashMessages.showAndHideFlash('error', 'Something went wrong.');
      connectPropertyConfiguration.rollbackAttributes();
    }
  });

  toggleWalkinApprovalsAutoCheckInTask = dropTask(async (bypass = false): Promise<void> => {
    const { connectPropertyConfiguration } = this.model;

    if (!connectPropertyConfiguration) return;

    if (connectPropertyConfiguration.autoCheckInOnApprovalEnabled && !bypass) {
      this.showApprovalsAutoCheckInDisableModal = true;
      return;
    }

    connectPropertyConfiguration.autoCheckInOnApprovalEnabled =
      !connectPropertyConfiguration.autoCheckInOnApprovalEnabled;

    try {
      await connectPropertyConfiguration.save();

      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch {
      this.flashMessages.showAndHideFlash('error', 'Something went wrong.');
      connectPropertyConfiguration.rollbackAttributes();
    }
  });

  @action
  confirmDisable(
    key: 'showApprovalsDisableModal' | 'showApprovalsAutoCheckInDisableModal',
    task: this['toggleWalkinApprovalsTask'] | this['toggleWalkinApprovalsAutoCheckInTask']
  ): void {
    this[key] = false;
    void task.perform(true);
  }
}
