import Component from '@glimmer/component';
import { A } from '@ember/array';
import { service } from '@ember/service';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { reads, not, sort } from '@ember/object/computed';
import { isEmpty, isPresent } from '@ember/utils';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import EmberObject, { computed, get, getProperties, set, setProperties, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { dropTask, task, all } from 'ember-concurrency';
import Changeset from 'ember-changeset';
import moment from 'moment-timezone';
import addOrUpdateUserData from 'garaje/utils/user-data';
import { PURPOSE_OF_VISIT } from 'garaje/utils/enums';
import { localCopy } from 'tracked-toolbox';
import { underscore, camelize } from '@ember/string';
import { pluralize } from 'ember-inflector';
import { bool } from 'macro-decorators';

import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import RecurringRule from 'garaje/models/recurring-rule';
import { RECURRING_OPTIONS } from 'garaje/pods/components/invites/select-recurring-invites/component';
import lookupValidator from 'ember-changeset-validations';
import buildFlowFieldValidations from 'garaje/validations/flow-field';
import { buildWaiter } from '@ember/test-waiters';
import config from 'garaje/config/environment';

const testWaiter = buildWaiter('invites-single-prereg-component-waiter');
const isTesting = config.environment === 'test';

/**
 * Form for creating or editing an invite
 *
 * @param {Object}                            config
 * @param {Array<Flow>}                       flows
 * @param {Array<Location>}                   locations
 * @param {Array<User>}                       blocklistContacts
 * @param {Boolean}                           hostFieldIsRequired
 * @param {Class<Invite>}                     invite
 * @param {Boolean}                           isEditInvite
 * @param {Class<InviteChangeset>}            inviteChangeset
 * @param {Class<Subscription>}               vrSubscription
 * @param {Function}                          dateChanged
 * @param {Function}                          modelDidSave
 * @param {Function}                          onLocationSelectedTask
 * @param {Boolean}                           shouldShowRecurrence
 * @param {Boolean}                           shouldShowLegalDocumentDescriptions
 * @param {Object}                            legalDocumentDescriptions
 * @param {Function}                          modelDidPartialSave
 * @param {Boolean}                           locationIsConnectedToProperty
 */
export default class InvitesSinglePreregComponent extends Component {
  @service abilities;
  // ActiveStorage service: https://github.com/algonauti/ember-active-storage
  @service activeStorageExtension;
  @service currentAdmin;
  @service featureFlags;
  @service locationFeatureFlags;
  @service flashMessages;
  @service metrics;
  @service router;
  @service store;
  @service state;

  @localCopy('args.invite.employee') owner;
  @localCopy('args.invite.sendGuestEmail') sendGuestEmail;
  @localCopy('args.config', {}) config;
  @localCopy('args.flows', []) flows;

  @tracked confirmRemovalOfDocument = null;
  @tracked flowToChangeTo;
  @tracked hasAttachments = false;
  @tracked inviteChanges;
  @tracked isShowingEditRecurringInvite = false;
  @tracked isShowingDeleteConfirmation = false;
  @tracked sendNotificationCallback;
  @tracked editAffectsMultipleLocationsModal = false;
  @tracked showConfirmVisitorTypeChangeModal = false;

  @not('args.invite.needsApprovalReview') isNotPendingApproval;
  @reads('args.inviteChangeset.expectedArrivalTime') minRruleUntilDate;
  @reads('args.inviteChangeset.expectedArrivalTime') expectedArrivalTime;
  @reads('args.inviteChangeset.location.timezone') timezone;
  @sort('signInFields', (a, b) => a.position - b.position) sortedSignInFields;
  @reads('selectedFlow.activeUserDocumentTemplateConfigurations') activeUserDocumentTemplateConfigurations;
  @bool('args.inviteChangeset.change.visitorDocuments') isVisitorDocumentsModified;

  get canDelete() {
    return this.abilities.can('delete invite', this.args.invite) && this.isNotPendingApproval;
  }

  get canEdit() {
    const { isEditInvite, invite } = this.args;

    // If editing an existing invite, check permissions
    // If creating an invite, always allow "editing"
    return isEditInvite ? this.abilities.can('edit invite', invite) : true;
  }

  get secondaryLocations() {
    return this.args.inviteChangeset.childInviteLocations ?? [];
  }

  get emailSignInField() {
    return this.signInFields?.findBy('isEmail');
  }

  get isEmailRequired() {
    if (
      !(
        this.featureFlags.isEnabled('visitors-required-email-field') ||
        this.locationFeatureFlags.isEnabled('visitors-required-email-field-by-location')
      )
    ) {
      return false;
    }

    return !!this.emailSignInField?.required;
  }

  get canSetPropertyNotes() {
    // "property notes" field only appears if the current location is connected to a property.
    // It should also only be shown to someone who can edit the invite (e.g. Global Admin, Location Admin,
    // Front Desk Admins, or the employee who created the invite).
    if (!this.args.locationIsConnectedToProperty) {
      return false;
    }
    if (this.args.invite.isNew) {
      return true;
    }
    return this.canEdit;
  }

  get hasRecurringInvite() {
    return isPresent(this.args.invite.belongsTo('recurringInvite').id());
  }

  get hasDatetimeChange() {
    return isPresent(this.args.inviteChangeset.changes.findBy('key', 'expectedArrivalTime'));
  }

  get hasEmailChange() {
    return isPresent(this.args.inviteChangeset.changes.findBy('key', 'email'));
  }

  get shouldSendNotification() {
    return !this.args.invite.isNew && !this.hasEmailChange && this.hasDatetimeChange;
  }

  get disabledRecurringOptions() {
    return this.isVisitorDocumentsModified ? { THIS: true } : null;
  }

  get defaultRecurringOption() {
    return this.isVisitorDocumentsModified ? RECURRING_OPTIONS.THIS_AND_FOLLOWING : RECURRING_OPTIONS.THIS;
  }

  get location() {
    return this.args.invite?.location;
  }

  get shouldShowSecondaryLocations() {
    if (!this.args.vrSubscription?.canAccessMultiLocationInvites) return false;
    if (this.isMultiLocationEdit) return false;
    return this.args.locations?.length > 1;
  }

  get isMultiLocationEdit() {
    return this.args.isEditInvite && this.args.isMultiLocationInvite;
  }

  get showEditAffectsMultipleLocationsModal() {
    return this.args.isEditInvite && this.args.isMultiLocationInvite && this.editAffectsMultipleLocationsModal;
  }

  get disableRecurring() {
    if (this.featureFlags.isEnabled('multi-location-recurring-invites')) return false;
    return this.secondaryLocations.length > 0;
  }

  get locationNames() {
    return this.args.invite.locationNames ?? [];
  }

  get secondaryLocationNames() {
    const childLocationNames = this.locationNames.slice(1);
    return childLocationNames.join(', ');
  }

  get primaryLocationName() {
    const [parentLocationName, ...childLocationNames] = this.locationNames;
    return childLocationNames.length ? parentLocationName : '';
  }

  @computed('selectedFlow.globalFlow.locations')
  get optionsForSecondaryLocations() {
    if (!this.selectedFlow?.globalFlow) {
      return [];
    } else {
      const primaryLocationId = this.args.inviteChangeset.location.id;
      const skinnyLocations = get(this.selectedFlow.globalFlow, 'locations') || [];

      return skinnyLocations
        .filter((location) => location.id !== primaryLocationId)
        .map((location) => this.store.peekRecord('location', location.id));
    }
  }

  @computed('args.invite.creator.id', 'currentAdmin.user.id')
  get isCreator() {
    const creatorId = this.args.invite.creator?.id;
    const currentAdminId = this.currentAdmin.user?.id;

    return creatorId && currentAdminId && creatorId === currentAdminId;
  }

  @computed('args.{vrSubscription.canAccessGroupSignIn,invite.hasAdditionalGuests}', 'selectedFlow.additionalGuests')
  get displayAdditionalGuests() {
    if (get(this, 'args.invite.hasAdditionalGuests')) {
      return true;
    }

    return get(this, 'args.vrSubscription.canAccessGroupSignIn') && get(this, 'selectedFlow.additionalGuests');
  }

  get flowOptions() {
    return this.flows.rejectBy('isProtect').sortBy('position');
  }

  @computed(
    'flows.@each.name',
    'purposeOfVisit.value',
    'args.inviteChangeset.flow',
    'state.currentLocation.employeeScreeningFlow.id'
  )
  get selectedFlow() {
    // the flow rel is always correct and might not match the flowName or purpose of visit
    // eslint-disable-next-line ember/use-ember-get-and-set
    const flowId = this.args.inviteChangeset.get('flow.id');
    if (!flowId) {
      return null;
    }

    if (this.purposeOfVisit.value === PURPOSE_OF_VISIT.EMPLOYEE_REGISTRATION) {
      return this.state.currentLocation.employeeScreeningFlow;
    }

    return this.flows.findBy('id', flowId) ?? this.args.inviteChangeset.flow;
  }

  @computed('selectedFlow.{id,signInFieldPage.signInFields.@each.name,signInFieldPage.signInFields.isSettled}')
  get signInFields() {
    if (!this.selectedFlow) {
      return [];
    }

    return get(this.selectedFlow, 'signInFieldPage.signInFields');
  }

  @computed('selectedFlow.{type,employeeCentric}')
  get isFromEmployeeScreening() {
    if (!this.selectedFlow) {
      return false;
    }
    return get(this.selectedFlow, 'employeeCentric') || get(this.selectedFlow, 'type') === 'Flows::EmployeeScreening';
  }

  @action
  checkForAttachments() {
    this.hasAttachments =
      this.visitorDocuments?.isAny('hasAttachedFile') || this.visitorDocuments?.isAny('hasInputFieldData');
  }

  get visitorDocuments() {
    const {
      activeUserDocumentTemplateConfigurations,
      args: { inviteChangeset },
    } = this;

    return activeUserDocumentTemplateConfigurations?.sortBy('userDocumentTemplate.position').map((config) => {
      const { userDocumentTemplate } = config;

      return (
        inviteChangeset.visitorDocumentForTemplate(userDocumentTemplate) ||
        this.store.createRecord('visitor-document', { userDocumentTemplate })
      );
    });
  }

  get isValidVisitorDocuments() {
    const documentsToCheck = this.visitorDocuments?.filterBy('hasAttachedFile');
    if (isEmpty(documentsToCheck)) return true;

    return documentsToCheck.isEvery('isValidDocument');
  }

  @computed('sortedSignInFields.[]')
  get fieldChangesets() {
    return this.sortedSignInFields.map((field) => {
      return get(this.userDataChangesets, `field-${field.id}`) || this.buildFieldChangeset(field);
    });
  }

  @computed('fieldChangesets.@each.value')
  get renderableFieldChangesets() {
    return this.fieldChangesets.filter((fieldChangeset) => {
      if (get(fieldChangeset, 'isTopLevel')) {
        return true;
      }

      const action = get(fieldChangeset, 'actionableSignInFieldActions.firstObject');

      // Do not attempt to automatically async fetch related signInField
      // If signInField record isn't already fulfilled, it will probably not load
      const isApplicableAction = action && action.belongsTo('signInField').value() && get(action, 'signInField.id');

      if (!isApplicableAction) {
        return false;
      }

      const changesetParent = this.fieldChangesets.findBy('id', get(action, 'signInField.id'));

      return changesetParent && get(changesetParent, 'value') === get(action, 'dropdownOption.value');
    });
  }

  @computed('args.inviteChangeset.email')
  get canSendGuestEmail() {
    const email = get(this, 'args.inviteChangeset.email');
    const validation = get(this, 'args.inviteChangeset.validationMap.email');

    const validate = Array.isArray(validation)
      ? (...args) => {
          return validation.every((v) => v(...args) === true);
        }
      : () => true;

    return email && validate('email', email) === true;
  }

  @computed('args.inviteChangeset.userData.[]')
  get phoneNumber() {
    const userData = get(this, 'args.inviteChangeset.userData') || A();
    return userData.findBy('field', 'Your Phone Number');
  }

  @computed('timezone')
  get minArrivalDate() {
    const timezone = get(this, 'timezone');

    if (get(this, 'args.inviteChangeset.isNew')) {
      return timezone ? moment().tz(timezone) : moment();
    }

    return '';
  }

  @computed('args.inviteChangeset.isNew', 'expectedArrivalTime', 'timezone')
  get minArrivalTime() {
    const timezone = get(this, 'timezone');
    const now = timezone ? moment().tz(timezone).format('h:mm a') : moment().format('h:mm a');

    if (!this.expectedArrivalTime) return now;

    const isNew = get(this, 'args.inviteChangeset.isNew');
    const isForToday = moment(this.expectedArrivalTime).isSame(moment(), 'day');

    return isNew && isForToday ? now : '';
  }

  @computed('expectedArrivalTime', 'timezone')
  get selectedTime() {
    if (!this.expectedArrivalTime) return '';

    const timezone = get(this, 'timezone');
    const time = timezone ? moment(this.expectedArrivalTime).tz(timezone) : moment(this.expectedArrivalTime);
    return time.format('h:mm a');
  }

  // we only care if the model changes since the POV field can't be deleted
  // this is not a Flow model but rather the user-data containing the flow-name
  @computed('args.inviteChangeset.{userData.@each.field,flowName}')
  get purposeOfVisit() {
    const userData = this.args.inviteChangeset.userData || A();
    const flowName = this.args.inviteChangeset.flowName;

    return userData.findBy('field', 'Purpose of visit') || { field: 'Purpose of visit', value: flowName };
  }

  @computed('args.invite', 'signInFields')
  get userDataChangesets() {
    const initialState = EmberObject.create({});

    // bootstrap the initial state for the changeset so it uses whatever is in the invite.userData
    // this is required to show pre-existing data
    const userData = get(this, 'args.invite.userData') || A();
    userData.forEach((option, index) => {
      const fieldName = get(option, 'field');
      const value = get(option, 'value');
      // ignore purpose of visit here
      // eslint-disable-next-line ember/use-ember-get-and-set
      if (value !== get(this.selectedFlow ?? {}, 'name')) {
        let field = this.signInFields.findBy('name', fieldName);

        if (!field) {
          field = {
            id: `${index}-${get(this, 'args.inviteChangeset.location.id')}`, // pseudo-id
            name: fieldName,
            isLoaded: true,
          };
        }

        field = this.buildFieldChangeset(field);
        set(field, 'value', value); // set the initial value

        set(initialState, this.pseudoIdForField(field), field);
      }
    });
    return initialState;
  }

  get shouldShowGroupName() {
    if (!this.args.vrSubscription.canAccessGroupInviteRecords) return false;

    return this.args.invite?.isInGroupInvite;
  }

  isVisitorDocumentPersistable(visitorDocument) {
    const activeIdentifiers = this.activeUserDocumentTemplateConfigurations.mapBy('userDocumentTemplate.identifier');
    const isAssociatedToActiveTemplate = activeIdentifiers.includes(visitorDocument.identifier);
    const hasAttachmentsWithPendingUploads = visitorDocument.userDocumentAttachmentsPendingUpload.length > 0;

    return isAssociatedToActiveTemplate && hasAttachmentsWithPendingUploads;
  }

  @(employeesSearcherTask({
    filter: { deleted: false, excludeHidden: true },
    prefix: false,
  }).restartable())
  searchEmployeesTask;

  @action
  saveAndAddAnother() {
    this.saveTask.perform({ addAnother: true });
  }

  @action
  onFormSubmit(e) {
    e.preventDefault();
    if (this.secondaryLocationNames.length && this.args.isEditInvite) {
      this.toggleMultiLocationsEditModal();
    }

    if (this.editAffectsMultipleLocationsModal) {
      return;
    }
    if (this.hasRecurringInvite) {
      this.isShowingEditRecurringInvite = true;
    } else if (this.shouldSendNotification) {
      this.sendNotificationCallback = () => this.saveTask.perform();
    } else {
      this.saveTask.perform();
    }
  }

  @action
  onSubmitRecurringInvite(whichInvites) {
    return this.saveTask.perform({ whichInvites });
  }

  @action
  buildFieldChangeset(field) {
    const validations = buildFlowFieldValidations(field);
    const validator = lookupValidator(validations);
    return new Changeset(field, validator, validations);
  }

  @action
  async updatePropsRecurringInvite(recurringInvite) {
    const inviteChangeset = this.args.inviteChangeset;
    const newExpectedArrivalTime = inviteChangeset.expectedArrivalTime;
    setProperties(recurringInvite, {
      email: inviteChangeset.email,
      employee: inviteChangeset.employee?.content,
      fullName: inviteChangeset.fullName,
      location: inviteChangeset.location?.content,
      privateNotes: inviteChangeset.privateNotes,
      visitorDocuments: inviteChangeset.visitorDocuments,
      childLocations: await inviteChangeset.childInviteLocations,
    });

    if (newExpectedArrivalTime) {
      const existingStartTime = moment(recurringInvite.startTime);
      const newHour = moment(newExpectedArrivalTime).hour();
      const newMinute = moment(newExpectedArrivalTime).minute();
      const newStartTime = moment(existingStartTime).hour(newHour).minute(newMinute).toDate();

      setProperties(recurringInvite, {
        startTime: newStartTime,
      });
    }

    if (get(inviteChangeset, 'recurringRule')) {
      setProperties(recurringInvite, {
        recurringRule: get(inviteChangeset, 'recurringRule'),
      });
    }

    return recurringInvite;
  }

  @action
  modifySecondaryLocations(location) {
    this.args.inviteChangeset.childInviteLocations = location;
  }

  @dropTask
  *saveRecurringInviteTask(existingRecurringInvite, whichInvites) {
    const inviteChangeset = get(this, 'args.inviteChangeset');
    let recurringInvite;

    if (existingRecurringInvite && existingRecurringInvite.id && whichInvites === 'all') {
      recurringInvite = yield this.updatePropsRecurringInvite(existingRecurringInvite);
    } else {
      set(inviteChangeset, 'skipGuestNotification', !this.sendGuestEmail && this.canSendGuestEmail);

      recurringInvite = yield this.store.createRecord('recurring-invite', {
        employee: inviteChangeset.employee?.content ?? inviteChangeset.employee,
        location: inviteChangeset.location?.content,
        fullName: inviteChangeset.fullName,
        email: inviteChangeset.email,
        privateNotes: inviteChangeset.privateNotes,
        recurringRule: inviteChangeset.recurringRule,
        skipGuestNotification: inviteChangeset.skipGuestNotification,
        visitorDocuments: inviteChangeset.visitorDocuments,
        propertyNotes: inviteChangeset.propertyNotes,
        locality: inviteChangeset.locality,
      });

      recurringInvite.childLocations = yield inviteChangeset.childInviteLocations;
      recurringInvite.startTime = this.expectedArrivalTime;

      if (whichInvites === RECURRING_OPTIONS.THIS_AND_FOLLOWING) {
        setProperties(recurringInvite, {
          parentRecurringInvite: existingRecurringInvite,
        });

        if (!get(recurringInvite, 'recurringRule')) {
          setProperties(recurringInvite, {
            recurringRule: existingRecurringInvite.recurringRule,
          });
        }
      }
    }

    if (!get(this, 'selectedFlow.additionalGuests')) {
      // make additional guests always 0 if selected flow doesn't support it
      set(recurringInvite, 'additionalGuests', 0);
    } else {
      set(recurringInvite, 'additionalGuests', get(this, 'args.inviteChangeset.additionalGuests'));
    }

    setProperties(recurringInvite, {
      userData: get(this, 'args.inviteChangeset.userData'),
      flow: this.selectedFlow,
    });
    inviteChangeset.rollback();
    return recurringInvite.save();
  }

  @dropTask
  *saveTask(options = {}) {
    const defaultOptions = { addAnother: false };

    options = Object.assign({}, defaultOptions, options);

    const inviteChangeset = get(this, 'args.inviteChangeset');
    yield inviteChangeset.validate();

    // Only validate RENDERED / VISIBLE field changesets
    for (const index of this.renderableFieldChangesets.keys()) {
      const changeset = this.renderableFieldChangesets[index];
      yield changeset.validate();
    }

    if (get(inviteChangeset, 'isInvalid')) {
      const specificError = get(inviteChangeset, 'errors.firstObject.validation');
      this.flashMessages.showFlash('error', 'Please fill all the required fields.', specificError);
      return;
    }

    for (const index of this.renderableFieldChangesets.keys()) {
      const changeset = this.renderableFieldChangesets[index];
      if (get(changeset, 'isInvalid')) {
        const specificError = get(changeset, 'errors.firstObject.validation.firstObject');
        this.flashMessages.showFlash('error', 'Please fill all the required fields.', specificError);
        return;
      }
    }

    if (!this.isValidVisitorDocuments) {
      this.flashMessages.showFlash('error', 'Please enter all required information');
      return;
    }

    // If invite has additional hosts added *AND* has multiple locations or is recurring, show an error and don't save.
    // This behavior will be improved in the future; this solution is a stop-gap measure to simply prevent recurring
    // or multi-location invites from being created at all.
    if (inviteChangeset.additionalHosts?.length > 0) {
      const isRecurring = !!inviteChangeset.recurringRule;
      const hasMultipleLocations = inviteChangeset.childInviteLocations.length > 0;
      let error;
      if (isRecurring && hasMultipleLocations) {
        error =
          'Recurring invites and multiple locations are not supported for invites with multiple hosts. Please either remove the additional hosts, or make the invite a one-time invite for a single location.';
      } else if (isRecurring) {
        error =
          'Recurring invites cannot be created with multiple hosts. Please remove additional hosts or create a one-time invite.';
      } else if (hasMultipleLocations) {
        error = 'Please remove either secondary locations or additional hosts to save this invite.';
      }
      if (error) {
        this.flashMessages.showFlash('error', 'Unable to invite / save', error);
        return;
      }
    }

    set(inviteChangeset, 'skipGuestNotification', !this.sendGuestEmail && this.canSendGuestEmail);

    const userDataChangesets = this.userDataChangesets;

    Object.values(userDataChangesets).forEach((fieldChangeset) => {
      if (get(fieldChangeset, 'isDirty')) {
        addOrUpdateUserData(inviteChangeset, fieldChangeset.name, fieldChangeset.value);
      }
    });

    if (!get(this, 'selectedFlow.additionalGuests')) {
      // make additional guests always 0 if selected flow doesn't
      // support it
      set(inviteChangeset, 'additionalGuests', 0);
    }

    const token = isTesting ? testWaiter.beginAsync() : false;

    try {
      const date = moment(this.expectedArrivalTime).format('YYYY-MM-DDTHH:mm');
      const recurringInvite = yield get(this, 'args.invite.recurringInvite');
      const recurringInviteId = recurringInvite ? get(recurringInvite, 'id') : null;
      const whichInvites = options.whichInvites;
      const visitorDocuments = inviteChangeset?.visitorDocuments ?? A();
      // if there's a recurring rule in the invite changeset OR if a recurring rule exists AND
      // we are editing this and following or all invites in a recurring rule series
      const isSavingRecurringInvite =
        get(inviteChangeset, 'recurringRule') ||
        (recurringInviteId &&
          (whichInvites === RECURRING_OPTIONS.THIS_AND_FOLLOWING || whichInvites === RECURRING_OPTIONS.ALL));

      let saveError;

      if (isSavingRecurringInvite) {
        const inviteAttrs = getProperties(
          inviteChangeset,
          'flowName',
          'expectedArrivalTime',
          'employee',
          'inviterName'
        );

        const savedRecurringInvite = yield this.saveRecurringInviteTask.perform(recurringInvite, whichInvites);

        try {
          yield this.saveVisitorDocumentsTask.perform(savedRecurringInvite, visitorDocuments);
        } catch (_) {
          // Recurring invites create "child" invites async on the backend.
          // If a recurring invite saves but its documents do not, there is not
          // much we can do to position the user to retry the upload. For now,
          // proceeding with existing logic with a flash message to report that
          // something went wrong. ~ dana mar.3.2022
          saveError = 'Recurring invite saved! But one or more document uploads failed';
        }

        if (options.addAnother) {
          this.metrics.trackEvent('Dashboard Invite - Add Another', {
            recurring_invite_id: savedRecurringInvite.id,
          });
        }

        const goToIndex = Boolean(saveError) || !options.addAnother;

        this.args.modelDidSave(date, goToIndex, inviteAttrs);
      } else {
        const invite = yield inviteChangeset.save();

        // fixes issue where response from BE differs from POST vs GET and approvalStatus does not get re-serialized properly when reloaded via GET
        this.args.invite.rollbackAttributes?.();

        try {
          yield this.saveVisitorDocumentsTask.perform(invite, visitorDocuments);
        } catch (error) {
          this.flashMessages.showFlash('error', 'Invite saved! But one or more document uploads failed');

          // Handle invite successfully persisted but associated visitor document upload/save failed
          return this.args.modelDidPartialSave?.(invite, error);
        }

        if (options.addAnother) {
          this.metrics.trackEvent('Dashboard Invite - Add Another', { invite_id: invite.id });
        }

        this.args.modelDidSave(date, !options.addAnother, invite);
      }

      // Clean changeset values
      Object.values(userDataChangesets).forEach((fieldChangeset) => {
        set(fieldChangeset, 'value', '');
      });

      if (saveError) {
        this.flashMessages.showFlash('error', saveError);
      } else {
        this.flashMessages.showAndHideFlash('success', 'Saved!');
      }
    } catch (e) {
      this.metrics.trackEvent('Error creating invite', {
        invite: inviteChangeset,
      });

      this.flashMessages.showFlash('error', parseErrorForDisplay(e));

      // NOTE we want to throw the error so rejection can be handled downstream as well
      throw e;
    } finally {
      if (token) testWaiter.endAsync(token);
    }
  }

  @action
  setHost(employee) {
    const inviteChangeset = this.args.inviteChangeset;
    inviteChangeset.inviterName = employee?.name;
    inviteChangeset.employee = employee;
  }

  @dropTask
  *deleteRecurringInviteTask(existingRecurringInvite) {
    // this will be the end date for deleting this and following events
    // it's technically still a modify event, so PATCH request to v3/recurring-invites
    // the end date will be today, local time
    const date = moment(this.expectedArrivalTime).format('YYYY-MM-DD');
    const dayBeforeDate = moment(this.expectedArrivalTime).subtract(1, 'day').format('YYYY-MM-DDTHH:mm');

    // get the existing recurrence rule and change it to be exactly as it is with a new until date
    // use recurrenceRule
    const parsedRule = RecurringRule.parse(existingRecurringInvite.recurringRule);
    parsedRule.until = dayBeforeDate;
    const newRRule = parsedRule.toString();

    setProperties(existingRecurringInvite, {
      recurringRule: newRRule,
    });
    yield existingRecurringInvite.save();
    this.metrics.trackEvent('Recurring Invite Delete Requested', {
      recurring_invite_id: existingRecurringInvite.id,
      invite_id: get(this.args.inviteChangeset, 'id'),
    });
    this.router.transitionTo('visitors.invites', {
      queryParams: { date },
    });
  }

  @dropTask
  *deleteTask(options = {}) {
    try {
      const recurringInvite = yield get(this, 'args.invite.recurringInvite');
      const recurringInviteId = recurringInvite ? get(recurringInvite, 'id') : null;
      const isDeletingRecurringInvite = recurringInviteId && options.thisAndFollowing;

      // check if this is part of a recurring invite
      if (isDeletingRecurringInvite) {
        this.deleteRecurringInviteTask.perform(recurringInvite);
      } else {
        yield get(this, 'args.invite').destroyRecord();

        this.flashMessages.showAndHideFlash('success', 'Deleted');
        this.args.modelDidSave();
      }
    } catch (e) {
      this.flashMessages.showFlash('error', 'Invite cannot be deleted');
    }
  }

  @action
  onDeleteInvite(whichInvite) {
    const thisAndFollowing = whichInvite === RECURRING_OPTIONS.THIS_AND_FOLLOWING;
    return this.deleteTask.perform({ thisAndFollowing });
  }

  @action
  setDate(date) {
    // this is setting a date object, possible cause of bugs at the end of the month
    set(this, 'args.inviteChangeset.expectedArrivalTime', date);
    if (this.args.dateChanged) {
      this.args.dateChanged(date);
      set(this, 'args.inviteChangeset.rruleUntil', null);
      set(this, 'args.inviteChangeset.recurringRule', null);
    }
  }

  @action
  toggleMultiLocationsEditModal() {
    this.editAffectsMultipleLocationsModal = !this.editAffectsMultipleLocationsModal;
  }

  @action
  toggleConfirmVisitorTypeChangeModal() {
    this.showConfirmVisitorTypeChangeModal = !this.showConfirmVisitorTypeChangeModal;
  }

  @action
  toggleConfirmVisitorTypeChangeModalAndClear() {
    this.modifySecondaryLocations();
    this.updateFlow(this.flowToChangeTo);
    this.showConfirmVisitorTypeChangeModal = !this.showConfirmVisitorTypeChangeModal;
  }

  @action
  updateFlow(flow) {
    // Added to handle resetting fields for Multi-Location Invite option
    if (!this.showConfirmVisitorTypeChangeModal && this.args.inviteChangeset.childInviteLocations?.length) {
      this.toggleConfirmVisitorTypeChangeModal();
      this.flowToChangeTo = flow;
      return;
    }
    // TODO merge these in app level, and move the setting to the serializer
    setProperties(this, { 'purposeOfVisit.value': flow.name, 'args.inviteChangeset.flowName': flow.name });
    // assign flow (not sent up via PATCH request, but used by the UI. `flow-name` currently controls what flow gets assigned)
    this.args.inviteChangeset.flow = flow;

    this.args.onFlowChanged?.(flow.name, get(this, 'args.inviteChangeset'));
  }

  @action
  pseudoIdForField(field) {
    // The key provided to set must be a string.
    return `field-${get(field, 'id')}`;
  }

  @action
  updateUserDataField(changeset) {
    set(get(this, 'userDataChangesets'), this.pseudoIdForField(changeset), changeset);
  }

  @action
  setAdditionalHosts(hosts) {
    this.args.inviteChangeset.additionalHosts = hosts;
  }

  @action
  setHostAndUserData(changeset, employee) {
    // Employee is nil when host is cleared
    const employeeName = employee && employee.name;

    changeset.value = employeeName;
    this.updateUserDataField(changeset);

    const inviteChangeset = this.args.inviteChangeset;
    inviteChangeset.employee = employee;
    inviteChangeset.inviterName = employeeName;
  }

  @action
  searchEmployees(term) {
    const extraFilters = {
      locations: this.args.inviteChangeset.location.id,
    };
    return this.searchEmployeesTask.perform(term, extraFilters);
  }

  @action
  setRrule(recurringRule) {
    this.args.inviteChangeset.recurringRule = recurringRule;
  }

  @action
  handleGuestEmail({ target }) {
    this.sendGuestEmail = target.checked;
  }

  @action
  trackReadOnlyClicksOnly(invite_id) {
    if (get(this, 'args.shouldShowRecurrence') && this.args.isEditInvite) {
      this.metrics.trackEvent('Recurring Events - Read Only Expected Arrival Date/Time Clicked', { invite_id });
    }
  }

  @action
  didSelectTime(newTime) {
    const timezone = get(this, 'timezone');
    let date;

    if (this.expectedArrivalTime) {
      date = timezone ? moment(this.expectedArrivalTime).tz(timezone) : moment(this.expectedArrivalTime);
    } else {
      date = timezone ? moment().tz(timezone) : moment();
    }
    const newDate = moment(newTime, 'h:mm a');

    date.hours(newDate.hours());
    date.minutes(newDate.minutes());
    get(this, 'setDate').call(this, date.toDate());
  }

  @action
  attachFileToDocument(visitorDocument, userDocumentTemplateAttachment, update) {
    const { inviteChangeset } = this.args;

    // This updates the changeset with minimal modification to underlying model data
    if (inviteChangeset) {
      const visDocs = A([...inviteChangeset.visitorDocuments.toArray(), visitorDocument]).uniqBy('identifier');

      set(inviteChangeset, 'visitorDocuments', visDocs);
    }

    let userDocumentAttachment = visitorDocument.getAttachment(userDocumentTemplateAttachment.id);

    if (!userDocumentAttachment) {
      userDocumentAttachment = this.store.createRecord('user-document-attachment');
      userDocumentAttachment.userDocumentTemplateAttachment = userDocumentTemplateAttachment;
      userDocumentAttachment.visitorDocument = visitorDocument;
    }

    if (update instanceof File) {
      userDocumentAttachment.file = update;
    }

    if (typeof update === 'string') {
      userDocumentAttachment.fileUrl = update;
    }
  }

  @action
  resetVisitorDocument(visitorDocument) {
    const {
      hasRecurringInvite,
      args: { inviteChangeset },
    } = this;

    if (visitorDocument.isNew || hasRecurringInvite) {
      const visDocs = A([...inviteChangeset.visitorDocuments.toArray()]).without(visitorDocument);

      set(inviteChangeset, 'visitorDocuments', visDocs);

      if (visitorDocument.isNew) visitorDocument.unloadRecord();
    } else {
      this.confirmRemovalOfDocument = visitorDocument;
    }
  }

  @task
  *saveVisitorDocumentsTask(invite, visitorDocuments) {
    return yield all(
      visitorDocuments.map((visitorDocument) => this.saveVisitorDocumentTask.perform(invite, visitorDocument))
    );
  }

  @task
  *saveVisitorDocumentTask(invite, visitorDocument) {
    if (!this.isVisitorDocumentPersistable(visitorDocument)) return visitorDocument;

    // Associate the invite's location to the visitor document (fallback to current location)
    const location = this.store.peekRecord('location', get(invite, 'location.id')) || this.state.currentLocation;
    const association = pluralize(camelize(invite.constructor.modelName));

    visitorDocument.locations = A([location]);
    visitorDocument[association].addObject(invite);

    yield this.uploadVisitorDocumentAttachmentsTask.perform(invite, visitorDocument);
    yield visitorDocument.save();

    // Cleanup attachments
    yield visitorDocument.userDocumentAttachments.filterBy('isNew').invoke('unloadRecord');

    return visitorDocument;
  }

  @task
  *uploadVisitorDocumentAttachmentsTask(invite, visitorDocument) {
    const { activeStorageExtension } = this;
    const directUploadURL = '/a/visitors/api/direct-uploads';
    const userDocumentTemplateId = get(visitorDocument, 'userDocumentTemplate.id');

    if (!(invite?.id && userDocumentTemplateId)) return;

    const pathSegment = underscore(pluralize(invite.constructor.modelName));
    const prefix = `user-documents/${userDocumentTemplateId}/${pathSegment}/${invite.id}`;
    const endpoint = `${directUploadURL}?prefix=${prefix}`;

    return yield all(
      visitorDocument.userDocumentAttachmentsPendingUpload.map((userDocumentAttachment) =>
        this.uploadDocumentAttachmentTask.perform(userDocumentAttachment, endpoint, activeStorageExtension)
      )
    );
  }

  @task uploadDocumentAttachmentTask = {
    progress: 0,

    *perform(userDocumentAttachment, endpoint, activeStorageExtension) {
      const { file } = userDocumentAttachment;

      if (!(file instanceof File)) {
        throw new Error('Upload halted: no file specified');
      }

      if (!endpoint) {
        throw new Error('Upload halted: no direct upload endpoint specified');
      }

      if (typeof activeStorageExtension.upload !== 'function') {
        throw new Error('Upload halted: invalid Active Storage service specified');
      }

      const { signedId } = yield activeStorageExtension.upload(file, endpoint, {
        onProgress: (progress) => {
          this.progress = progress;
          if (!get(userDocumentAttachment, 'isDestroyed') && !get(userDocumentAttachment, 'isDestroying')) {
            set(userDocumentAttachment, 'uploadProgress', progress);
          }
        },
      });

      userDocumentAttachment.file = signedId;

      return signedId;
    },
  };

  @task
  *removeVisitorDocumentFromInviteTask(visitorDocument, invite) {
    const { userDocumentTemplate } = visitorDocument;

    try {
      yield visitorDocument.removeFromInvites([invite]);
      invite.visitorDocuments.removeObject(visitorDocument);
      invite.visitorDocuments.addObject(this.store.createRecord('visitor-document', { userDocumentTemplate }));
      this.flashMessages.showAndHideFlash('success', `${visitorDocument.title} removed from invite`);
    } catch (_) {
      invite.visitorDocuments.removeObject(invite.visitorDocumentForTemplate(userDocumentTemplate));
      invite.visitorDocuments.addObject(visitorDocument);
      this.flashMessages.showFlash('error', `Failed to remove ${visitorDocument.title} from invite`);
    }
  }
}
