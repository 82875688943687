import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

const HOURS = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const MINUTES = [...Array(60).keys()];
const AM = 'am';
const PM = 'pm';

const padMinutes = (minutes) => (minutes <= 9 ? `0${minutes}` : `${minutes}`);
const parseTime = (time) => ({
  hour: parseInt(time.split(':')[0], 10),
  minute: parseInt(time.split(':')[1].split(' ')[0], 10),
  ampm: time.split(' ')[1],
});
const formatTime = (hour, minute, ampm) => `${hour}:${padMinutes(minute)} ${ampm}`;
const isDivisibleBy = (denominator) => (numerator) => numerator % denominator === 0;
const generateTimes = (hours, minutes, ampm) =>
  hours.reduce((times, hour) => times.concat(minutes.map((minute) => formatTime(hour, minute, ampm))), []);

const convertTimeToInt = (time) => {
  const { hour, minute, ampm } = parseTime(time);
  return (hour === 12 ? 0 : hour * 60) + minute + (ampm === PM ? 12 * 60 : 0);
};

const roundTimeDownBy = (increment) => (time) => {
  const { hour, minute, ampm } = parseTime(time);
  const roundedMinute = Math.floor(minute / increment) * increment;
  return formatTime(hour, roundedMinute, ampm);
};

const roundTimeUpBy = (increment) => (time) => {
  const objTime = parseTime(time);
  const { hour, minute } = objTime;
  let { ampm } = objTime;
  let roundedMinute = Math.ceil(minute / increment) * increment;
  let roundedHour = hour;
  // rounding minutes to h:00 of next hour
  if (roundedMinute === 60) {
    roundedHour += 1;
    roundedMinute = 0;
  }
  // rounding from 11am to 12pm
  if (hour === 11 && roundedHour === 12 && ampm === AM) {
    ampm = PM;
  }
  // rounding from 12pm to 1pm
  if (roundedHour === 13 && ampm === PM) {
    roundedHour = 1;
  }
  return formatTime(roundedHour, roundedMinute, ampm);
};

const filterTimes = (time = '', times = []) => {
  let result = [];
  if (time.match(/^([0-9]|1[0-2])/)) {
    let partialTime = time.split(':');
    const hour = partialTime[0].replace(/\D/g, '');
    partialTime = hour + (partialTime[1] ? ':' + partialTime[1].slice(0, 1) : '');
    try {
      result = times.filter((t) => t.match(new RegExp(`^${partialTime}`)));
    } catch (e) {
      // Fail silenty on error with bad input for time
      if (e instanceof SyntaxError) {
        return [];
      } else {
        throw e;
      }
    }
  }
  return result;
};

const expandAbbreviatedTime = (time = '') => {
  let result = time;
  const matched = time.match(/^([0-9]|1[0-2]) ?(a|p)m?$/);
  if (matched !== null) {
    let hour, period;
    [time, hour, period] = matched;
    if (hour && period) {
      result = `${hour}:00 ${period}m`;
    }
  } else {
    result = time.match(/(a|p)$/) !== null ? `${time}m` : time;
    if (result.match(/am|pm/) !== null) {
      result = result.split(/am|pm/)[0].trim() + ' ' + result.slice(-2);
    }
  }
  return result;
};

/**
 * @param {String}                    ariaLabelledBy
 * @param {String}                    ariaLabel
 * @param {String}                    min
 * @param {String}                    max
 * @param {String}                    placeholder
 * @param {Function}                  onSelectTime
 * @param {Boolean}                   isReadonly
 * @param {Boolean}                   isDisabled
 * @param {String}                    time
 * @param {Number}                    increment
 */
export default class TimePicker extends Component {
  @tracked increment;
  @tracked minTime;
  @tracked maxTime;
  @tracked min;
  @tracked max;

  constructor() {
    super(...arguments);
    this.increment = this.args.increment ?? 15;
    this.onUpdate();
  }

  get roundedMin() {
    // for min time, we round up to make sure no time below the min is available
    if (this.minTime) return roundTimeUpBy(this.increment)(this.minTime);
    return null;
  }

  get roundedMax() {
    // for max time, we round down to make sure no time above the max is available
    if (this.maxTime) return roundTimeDownBy(this.increment)(this.maxTime);
    return null;
  }

  get roundedTime() {
    if (this.args.time) return roundTimeDownBy(this.increment)(this.args.time);
    return null;
  }

  get times() {
    const incrementedMinutes = MINUTES.filter(isDivisibleBy(this.increment));
    // Here we generate an array of all the times of the given increment
    let times = [...generateTimes(HOURS, incrementedMinutes, AM), ...generateTimes(HOURS, incrementedMinutes, PM)];
    //Here we find the indexes of the rounded min and max values so that the array can be sliced accordingly
    // if the roundedMin isn't present in the times array, just use the first time
    const minIndex = times.indexOf(this.roundedMin) === -1 ? 0 : times.indexOf(this.roundedMin);
    // if the roundedMax isn't present in the times array, just use the last time
    const maxIndex = times.indexOf(this.roundedMax) === -1 ? times.length - 1 : times.indexOf(this.roundedMax);
    // return the times of the given increment between the given min and max times
    times = times.slice(minIndex, maxIndex + 1);
    if (this.min !== this.minTime) {
      times = times.filter((t) => {
        const hour = this.minTime?.split(':')[0];
        const result = t.match(new RegExp(hour));
        return result !== null && result.index === 0;
      });
    }
    return times;
  }

  @action
  didInput(value) {
    let time = value.trim();
    const filteredTimes = filterTimes(time, this.times);
    this.minTime = filteredTimes.length > 0 ? filteredTimes[0] : this.min;
    this.maxTime = filteredTimes.length > 0 ? filteredTimes[filteredTimes.length - 1] : this.max;
    time = expandAbbreviatedTime(time);
    return time;
  }

  @action
  validateTime(time) {
    const isValidTime = /^([0-9]|1[0-2]):[0-5][0-9] (am|pm)$/.test(time);
    if (!isValidTime) {
      return false;
    } else {
      const timeValue = convertTimeToInt(time);
      const isAfterMin = !this.min || timeValue >= convertTimeToInt(this.min);
      const isBeforeMax = !this.max || timeValue <= convertTimeToInt(this.max);
      return isAfterMin && isBeforeMax;
    }
  }

  @action
  onUpdate() {
    const min = this.args.min ?? '12:00 am';
    const max = this.args.max ?? '11:59 pm';

    this.min = min;
    this.max = max;
    this.minTime = min;
    this.maxTime = max;
  }
}
