import Service, { service } from '@ember/service';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import type DeliveryExport from 'garaje/models/delivery-export';
import type EmployeeModel from 'garaje/models/employee';
import type EntryExportJobModel from 'garaje/models/entry-export-job';
import type LocationModel from 'garaje/models/location';
import type LocationExportJob from 'garaje/models/location-export-job';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type WindowLocationService from 'garaje/services/window-location';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

const POLLING_TIMEOUT = 1000;

export interface EntryExportParams {
  startDate?: Date;
  endDate?: Date;
  employee?: EmployeeModel;
  filter?: string;
  location?: LocationModel;
  query?: string;
  sort?: string;
}

export default class AsyncExportManagerService extends Service {
  @service declare store: Store;
  @service declare windowLocation: WindowLocationService;
  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;

  @tracked activeJob: null | DeliveryExport | EntryExportJobModel | LocationExportJob = null;
  @tracked activeJobType: null | 'deliveries' | 'entries' | 'locations' = null;

  downloadFile(url: string): void {
    this.windowLocation.assign(url);
  }

  exportDeliveriesTask = task(
    {
      drop: true,
    },
    async (params: Record<string, unknown>) => {
      const deliveryExportJob = this.store.createRecord<'delivery-export'>('delivery-export', { filter: params });

      try {
        await deliveryExportJob.save();

        if (!deliveryExportJob.isDone) {
          this.activeJob = deliveryExportJob;
          this.activeJobType = 'deliveries';

          await this.pollForProgressUntilDoneTask.perform(deliveryExportJob);
        }

        this.downloadFile(deliveryExportJob.file);
        this.flashMessages.showAndHideFlash('success', 'File exported!');
      } catch (error) {
        const errorText = parseErrorForDisplay(error);
        this.flashMessages.showAndHideFlash('error', errorText);
      } finally {
        this.activeJob = null;
        this.activeJobType = null;

        this.store.unloadRecord(deliveryExportJob);
      }
    }
  );

  exportEntriesTask = task(
    {
      drop: true,
    },
    async (params: { action_origin?: unknown } & EntryExportParams) => {
      const entryExportJob = this.store.createRecord('entry-export-job', params);

      try {
        this.metrics.trackEvent('Entries Exported', { action_origin: params.action_origin });
        await entryExportJob.save();

        if (!entryExportJob.isDone) {
          this.activeJob = entryExportJob;
          this.activeJobType = 'entries';

          await this.pollForProgressUntilDoneTask.perform(entryExportJob);
        }

        this.downloadFile(entryExportJob.url);
        this.flashMessages.showAndHideFlash('success', 'File exported!');
      } catch (error) {
        const errorText = parseErrorForDisplay(error);
        this.flashMessages.showAndHideFlash('error', errorText);
      } finally {
        this.activeJob = null;
        this.activeJobType = null;

        this.store.unloadRecord(entryExportJob);
      }
    }
  );

  exportLocationsTask = task(
    {
      drop: true,
    },
    async (params: Record<string, unknown>) => {
      const locationExportJob = this.store.createRecord<'location-export-job'>('location-export-job', params);
      try {
        await locationExportJob.save();

        if (!locationExportJob.isDone) {
          this.activeJob = locationExportJob;
          this.activeJobType = 'locations';

          await this.pollForProgressUntilDoneTask.perform(locationExportJob);
        }

        this.downloadFile(locationExportJob.url);
        this.flashMessages.showAndHideFlash('success', 'File exported!');
      } catch (error) {
        const errorText = parseErrorForDisplay(error);
        this.flashMessages.showAndHideFlash('error', errorText);
      } finally {
        this.activeJob = null;
        this.activeJobType = null;
        this.store.unloadRecord(locationExportJob);
      }
    }
  );

  pollForProgressUntilDoneTask = task({}, async (exportJob: NonNullable<AsyncExportManagerService['activeJob']>) => {
    while (true) {
      await exportJob.reload();

      if (exportJob.isDone) {
        break;
      }

      await timeout(POLLING_TIMEOUT);
    }
  });
}
