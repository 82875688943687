import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import config from 'garaje/config/environment';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type ImpressionsService from 'garaje/services/impressions';
import type SessionService from 'garaje/services/session';
import type StateService from 'garaje/services/state';
import type StatsigService from 'garaje/services/statsig';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';
import { tracked } from 'tracked-built-ins';

import { type KioskDemoStep } from '../provider/component';

const SHARE_TEXT = 'Copy share link';
const SHARE_LINK_COPIED_TEXT = 'Link copied';
const LINK_IMG_SRC = '/assets/images/ipad-demo/icons/chain.svg';
const LINK_COPIED_IMG_SRC = '/assets/images/ipad-demo/icons/check.svg';

interface SignInKioskDemoArgs {
  isDemoInstance: boolean;
  onExternalClose?: () => void;
  onComplete?: () => void;
  demoName: string;
}

export default class SignInKioskDemo extends Component<SignInKioskDemoArgs> {
  @service declare state: StateService;
  @service declare statsig: StatsigService;
  @service declare store: Store;
  @service declare session: SessionService;
  @service declare impressions: ImpressionsService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare currentAdmin: CurrentAdminService;

  @tracked copyShareLinkText: string = SHARE_TEXT;
  @tracked copyShareLinkImageSrc: string = LINK_IMG_SRC;

  constructor(owner: unknown, args: SignInKioskDemoArgs) {
    super(owner, args);

    if (this.isUserLoggedIn && !this.args.isDemoInstance) {
      this.store
        .query('homepage-group', {
          include: 'homepage-item-group-mappings.homepage-item.setup-guide-items',
        })
        .then(() => null)
        .catch(() => null);
    }
  }

  get shareLink(): string {
    const { currentCompany } = this.state;
    if (config.environment === 'development') {
      return `https://dashboard.envoy.dev/ipad-demo?cid=${currentCompany?.id}`;
    } else if (config.environment === 'staging') {
      return `https://dashboard.envoy.christmas/ipad-demo?cid=${currentCompany?.id}`;
    } else {
      return `https://dashboard.envoy.com/ipad-demo?cid=${currentCompany?.id}`;
    }
  }

  get isUserLoggedIn(): boolean {
    return this.session?.isAuthenticated;
  }

  get showShareModal(): boolean {
    return this.currentAdmin.isGlobalAdmin && this.featureFlags.isEnabled('growth_preview_bulk_add_admins');
  }

  completeSetupGuideStep = dropTask(async () => {
    if (this.isUserLoggedIn && !this.args.isDemoInstance) {
      try {
        await this.impressions.postImpression.perform(IMPRESSION_NAMES.KIOSK_DEMO_IPAD_SIGNIN);
        const groups = await this.store.query('homepage-group', {
          include: 'homepage-item-group-mappings.homepage-item.setup-guide-items',
        });

        const setupGuideGroup = groups
          ?.find(
            (groupItem) => groupItem.identifier === 'configuration_a' || groupItem.identifier === 'configuration_e'
          )
          ?.sortedMappings.filter((sm) => sm.homepageItem.identifier === 'setup_guide_items');

        if (setupGuideGroup && setupGuideGroup.length > 0) {
          const seeWhatVisitorsSeeTask = setupGuideGroup[0]?.homepageItem?.setupGuideItems?.find(
            (item) => item.identifier === 'visitors-demo'
          );
          if (seeWhatVisitorsSeeTask) {
            seeWhatVisitorsSeeTask.completed = true;
            seeWhatVisitorsSeeTask
              .save()
              .then(() => null)
              .catch(() => null);
          }
        }
      } catch {
        return;
      }
    }
  });

  @action copyShareLink(): void {
    this.statsig.logEvent(`kiosk-demo-${this.args.demoName}-share-link-clicked`);
    this.copyShareLinkText = SHARE_LINK_COPIED_TEXT;
    this.copyShareLinkImageSrc = LINK_COPIED_IMG_SRC;

    setTimeout(() => {
      this.copyShareLinkText = SHARE_TEXT;
      this.copyShareLinkImageSrc = LINK_IMG_SRC;
    }, 2000);
  }

  steps: Array<KioskDemoStep> = [
    {
      imageSrc: '/assets/images/ipad-demo/Step1.svg',
      imageAlt: 'Image of welcome page',
      infoComponentName: 'Homepage::KioskDemo::Pages::Welcome',
    },
    {
      imageSrc: '/assets/images/ipad-demo/Step2.svg',
      imageAlt: 'Image of visitors',
      infoComponentName: 'Homepage::KioskDemo::Pages::VisitorsRecognition',
    },
    {
      imageSrc: '/assets/images/ipad-demo/Step3.svg',
      imageAlt: 'Image of sign in flow',
      infoComponentName: 'Homepage::KioskDemo::Pages::SignInFlow',
    },
    {
      imageSrc: '/assets/images/ipad-demo/Step4.svg',
      imageAlt: 'Image of sign in field',
      infoComponentName: 'Homepage::KioskDemo::Pages::SignInFields',
    },
    {
      imageSrc: '/assets/images/ipad-demo/Step5.svg',
      imageAlt: 'Image of add host',
      infoComponentName: 'Homepage::KioskDemo::Pages::HostSelection',
    },
    {
      imageSrc: '/assets/images/ipad-demo/Step06.png',
      imageAlt: 'Image of NDA',
      infoComponentName: 'Homepage::KioskDemo::Pages::LegalDocs',
    },
    {
      imageSrc: '/assets/images/ipad-demo/Step07.png',
      imageAlt: 'Image of guest photo',
      infoComponentName: 'Homepage::KioskDemo::Pages::Photo',
    },
    {
      imageSrc: '/assets/images/ipad-demo/Step08.png',
      imageAlt: 'Image of party',
      infoComponentName: 'Homepage::KioskDemo::Pages::FinalScreen',
    },
  ];
}
