import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { isBlank } from '@ember/utils';
import Changeset from 'ember-changeset';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { timeout, dropTask, restartableTask } from 'ember-concurrency';
import { alias } from 'macro-decorators';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import config from 'garaje/config/environment';
import zft from 'garaje/utils/zero-for-tests';

/**
 * @param {String}                    title                     Title of the form
 * @param {Function}                  searchEmployees           Action to search employees
 * @param {Function}                  addEmployee               Action to add employee
 * @param {Function}                  updateIsDirty             Action to update isDirty arg
 * @param {Task}                      afterAddEmployeeTask      Task to invoke after add employee
 * @param {Boolean}                   isDirty
 * @param {Model<Employee>}           selectedEmployee          Optional, current selected employee
 * @param {Model<Subcription>}        vrSubscription
 */
export default class AddFromLocationForm extends Component {
  @service state;
  @service flashMessages;
  @service workplaceMetrics;

  @tracked _EPSapi;
  @tracked changeset;
  @tracked disabled = false;
  @tracked searchName;

  defaultCountry = config.defaultCountry;

  @alias('searchEmployeesFromLocationTask.lastSuccessful.value.length') totalResults;

  @action
  registerPowerSelectAPI(publicAPI) {
    this._EPSapi = publicAPI;
  }

  constructor() {
    super(...arguments);
    if (this.args.selectedEmployee) {
      this.updateEmployee(this.args.selectedEmployee);
    }
  }

  @action
  updateEmployee(employee) {
    this.changeset = new Changeset(employee);
    this.disabled = true;
    this.args.updateIsDirty(true);
  }

  @action
  updateSearchName(term) {
    this.searchName = term;
  }

  @restartableTask
  *searchEmployeesFromLocationTask(term) {
    if (isBlank(term) || term.length < 3) {
      return [];
    }
    yield timeout(zft(250));
    const {
      currentLocation: { id: currentLocationId },
    } = this.state;
    const result = yield this.args.searchEmployees(term, true);
    yield result.forEach(async (employee) => {
      const empLocations = await get(employee, 'employeeLocations');
      const locationIds = empLocations.map(({ locationId }) => locationId);
      const hasCurrentLocation = locationIds.any((locationId) => `${locationId}` === currentLocationId);
      set(employee, 'disabled', hasCurrentLocation);
    });
    return result;
  }

  @restartableTask
  *searchEmployeesTask(term) {
    if (isBlank(term) || term.length < 3) {
      return [];
    }

    yield timeout(zft(250));
    const result = yield this.args.searchEmployees(term, true);
    return result;
  }

  @dropTask
  *saveTask(changeset) {
    this.workplaceMetrics.trackEvent('EMPLOYEE_DIRECTORY_NEW_EMPLOYEE_SAVE_BUTTON_CLICKED');
    try {
      const result = yield this.args.addEmployee(changeset);
      this.args.updateIsDirty(false);
      yield this.args.afterAddEmployeeTask.perform(result);
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      changeset.rollback();
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }
}
