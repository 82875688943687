import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import EmployeeFilter from 'garaje/models/employee-filter';

export default class EmployeesSyncSettingsController extends Controller {
  @service currentLocation;
  @service flashMessages;
  @service skinnyLocations;

  queryParams = 'filter-by';
  default = 'unfiltered';

  @tracked filterOptions = [];
  @tracked directoryGroups = [];

  get syncSettingsComponent() {
    const company = get(this.currentLocation, 'company.content');
    let component;

    if (get(company, 'googleApp.content')) {
      component = 'employees/sync-settings/google-apps';
    } else if (get(company, 'oneLogin.content')) {
      component = 'employees/sync-settings/one-login';
    } else {
      component = 'employees/sync-settings/okta-integration';
    }

    return component;
  }

  @action
  handleFailedQuery(response) {
    let msg = 'bad request';

    if (!isBlank(response.payload) && Array.isArray(response.payload)) {
      msg = response.payload.join(', ');
    }

    this.flashMessages.showAndHideFlash('error', `Error: ${msg}`);
  }

  // Workaround to get the actions to bubble up
  // otherwise they won't
  @action
  _hideFlash() {
    this.send('hideFlash');
  }

  @action
  _showFlash() {
    const [type, message] = arguments;
    this.send('showFlash', type, message);
  }

  @action
  addFilter(integrationData, filter, locationId = null) {
    const filters = get(integrationData, 'filters');

    filters.push(
      new EmployeeFilter({
        label: filter.label,
        name: filter.name,
        value: '',
        locationId,
      })
    );
  }

  @action
  removeFilter(integrationData, filter) {
    const filters = get(integrationData, 'filters');
    filters.removeObject(filter);
  }
}
