import Service, { inject as service } from '@ember/service';
import { type AJAXOptions } from 'ember-ajax/-private/types';
import type cookies from 'ember-simple-auth/session-stores/cookies';

const PROTECTED_METHODS = ['POST', 'PUT', 'PATCH', 'DELETE'];

type CsrfToken = string | null | undefined;

export default class CookieAuth extends Service {
  @service declare cookies: cookies;

  // Decorator mean to be used with ember-fetch
  decorate(_url: string, method: string, hash: AJAXOptions): AJAXOptions {
    const csrfToken: CsrfToken = this.cookies.read('csrf_token') as CsrfToken;
    const isProtectedMethod = PROTECTED_METHODS.includes(method);

    if (csrfToken) {
      // explicitly type-checking what was already happening in runtime
      if (!hash.headers) {
        throw Error('No headers in hash object.');
      }

      hash.headers['Authorization'] = '';
      if (isProtectedMethod) {
        hash.headers['X-CSRF-Token'] = csrfToken;
      }
    }

    return hash;
  }

  jQueryDecorator(_url: string, method: string, hash: AJAXOptions): AJAXOptions {
    hash.xhrFields = { withCredentials: true };
    const csrfToken: CsrfToken = this.cookies.read('csrf_token') as CsrfToken;
    const isProtectedMethod = PROTECTED_METHODS.includes(method);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { beforeSend } = hash;
    hash.beforeSend = (xhr: JQueryXHR) => {
      if (csrfToken) {
        xhr.setRequestHeader('Authorization', '');
        if (isProtectedMethod) {
          xhr.setRequestHeader('X-CSRF-Token', csrfToken);
        }
      }
      if (beforeSend) {
        // @ts-ignore to preserve historical runtime code
        beforeSend(xhr);
      }
    };
    return hash;
  }
}
