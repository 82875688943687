import Controller from '@ember/controller';
import { service } from '@ember/service';
import urlBuilder from 'garaje/utils/url-builder';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class LocationOverviewOccupancyAnalyticsIndexController extends Controller {
  @service localStorage;
  @tracked loginRequired = false;
  @service state;

  @service flashMessages;
  @tracked modalIframeUrl;
  @tracked showCopyLinkModal = false;
  @service messageBus;

  /**
   * @return {String}
   */
  get iframeUrl() {
    const companyId = this.state.currentCompany.id;
    return urlBuilder.embeddedOccupancyGlobalAnalyticsUrl(companyId, this.loginRequired);
  }

  constructor() {
    super(...arguments);
    this.messageBus.on('embedded-app-message', this, this.handleMessage);
  }

  @action
  handleMessage(message) {
    const {
      currentCompany: { id: companyId },
    } = this.state;
    if (message.event === 'showToaster') {
      const type = message.type;
      const msg = message.msg;
      this.flashMessages.showAndHideFlash(type, msg);
    } else if (message.event === 'showOccupancyCopyLinkModal') {
      const copyUrl = window.location.href;
      this.modalIframeUrl = urlBuilder.embeddedOccupancyCopyLinkModal(copyUrl, this.loginRequired, companyId);
      this.showCopyLinkModal = true;
    } else if (message.event === 'closeOccupancyCopyLinkModal') {
      this.showCopyLinkModal = false;
    }
  }

  @action
  trackLoggedIn() {
    /**
     * Issue: When user logs out from the Garaje app there is no way Next.js app knows it also needs to logout.
     * Due to this the cookies of the logout user may persist.
     * Solution: After login, force login the user on his first visit to this app. This refreshes all the cookies
     * To know if the user is visiting for the first time we will maintain a global state in localstorage called 'wa_logged_in'
     * On Login -> wa_logged_in does not exists in localstorage -> its first visit
     * On first visit -> SignIn, set wa_logged_in = true in localstorage
     * On Logout -> Remove wa_logged_in
     */

    const waLoggedIn = this.localStorage.getItem('wa_logged_in');

    if (!waLoggedIn) {
      this.loginRequired = true;
      this.localStorage.setItem('wa_logged_in', true);
    } else {
      this.loginRequired = false;
    }
  }
}
