import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type DetailedChangeset } from 'ember-changeset/types';
import type EmployeeModel from 'garaje/models/employee';
import type InviteModel from 'garaje/models/invite';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import { type RecordArray } from 'garaje/utils/type-utils';

const MAX_ADDITIONAL_HOSTS = 9;

type FieldChangeset = DetailedChangeset<SignInFieldModel>;
interface InvitesSinglePreregHostsFieldArgs {
  additionalHosts: EmployeeModel[];
  allowClear: boolean;
  disabled?: boolean;
  errors?: unknown[];
  field: FieldChangeset;
  inviteChangeset?: DetailedChangeset<InviteModel>;
  primaryHost: EmployeeModel | null;
  required: boolean;
  searchEmployees: (term: string) => Promise<RecordArray<EmployeeModel>>;
  updateAdditionalHosts: (hosts: EmployeeModel[]) => unknown;
  updatePrimaryHost: (field: FieldChangeset, host: EmployeeModel) => unknown;
}

export default class InvitesSinglePreregHostsField extends Component<InvitesSinglePreregHostsFieldArgs> {
  @tracked _showingAdditionalHostsSelect = false;

  maximumNumberOfAdditionalHosts = MAX_ADDITIONAL_HOSTS;

  get additionalHostsDisabledTooltip(): string | null {
    if (!this.isAdditionalHostsFieldDisabled) return null;

    const isRecurringInvite = !!this.args.inviteChangeset?.recurringRule;
    const isMultiLocationInvite = <number>this.args.inviteChangeset?.childInviteLocations.length > 0;
    if (isRecurringInvite && isMultiLocationInvite) {
      return 'Additional hosts are not available with recurring invites and multiple locations selected.';
    } else if (isRecurringInvite) {
      return 'Additional hosts are not available with recurring invites.';
    } else if (isMultiLocationInvite) {
      return 'Additional hosts are not available with multiple locations selected.';
    }

    return null;
  }

  get allowPrimaryHostToBeCleared(): boolean {
    // don't allow primary host to be cleared if @allowClear={{false}}
    if (!this.args.allowClear) return false;
    // only allow primary host to be cleared if no additional hosts are selected
    return this.args.additionalHosts.length === 0;
  }

  // Disable "additional hosts" field if any of these conditions are true:
  // * @disabled={{true}}
  // * no primary host is selected
  // * this component is being used with an entry and not an invite
  // * no additional hosts have been added and the invite is recurring
  // * no additional hosts have been added and the invite is multi-location
  get isAdditionalHostsFieldDisabled(): boolean {
    if (this.args.disabled) return true;
    if (!this.args.primaryHost) return true;
    if (this.isForEntry) return true;

    if (this.args.additionalHosts.length === 0 && this.args.inviteChangeset?.recurringRule) return true;
    if (this.args.additionalHosts.length === 0 && <number>this.args.inviteChangeset?.childInviteLocations.length > 0)
      return true;

    return false;
  }

  get hasAdditionalHosts(): boolean {
    return this.args.additionalHosts.length > 0;
  }

  get hasErrors(): boolean {
    return !!this.args.errors && this.args.errors.length > 0;
  }

  get hasMaximumNumberOfAdditionalHosts(): boolean {
    return this.args.additionalHosts.length >= MAX_ADDITIONAL_HOSTS;
  }

  get hostLabel(): string {
    if (this.hasAdditionalHosts || this.showingAdditionalHostsSelect) {
      return 'Primary host';
    }
    return 'Host';
  }

  get isForEntry(): boolean {
    return !this.args.inviteChangeset;
  }

  get showingAdditionalHostsSelect(): boolean {
    return this.isForEntry ? this.hasAdditionalHosts : this._showingAdditionalHostsSelect || this.hasAdditionalHosts;
  }

  get canAddAdditionalHosts(): boolean {
    return !this.showingAdditionalHostsSelect && !this.isForEntry;
  }

  @action
  handleAdditionalHostsSelectOpen(/* select: Select, e: Event */): boolean {
    // called when "additional hosts" select is opened; if there are already the maximum number
    // of allowable additional hosts, prevent opening so no more can be added.
    if (this.hasMaximumNumberOfAdditionalHosts) return false;
    return true;
  }

  @action
  async searchEmployees(searchTerm: string): Promise<EmployeeModel[]> {
    const employees = await this.args.searchEmployees(searchTerm);
    const existingHostIds = this.args.additionalHosts.map((host) => host.id);
    if (this.args.primaryHost) {
      existingHostIds.push(this.args.primaryHost.id);
    }
    // exclude employees who are already added to this invite, whether as primary host or as an additional host
    return employees.toArray().filter((employee) => !existingHostIds.includes(employee.id));
  }

  @action
  showAdditionalHosts(): void {
    this._showingAdditionalHostsSelect = true;
  }

  @action
  updatePrimaryHost(host: EmployeeModel): void {
    this.args.updatePrimaryHost(this.args.field, host);
  }
}
