import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AuthzService from 'garaje/services/authz';
import { adminRolesRoute } from 'garaje/utils/admin-routing';
import { RoleScopeType } from 'garaje/utils/custom-roles';
import type { Role } from 'garaje/utils/custom-roles';

interface CreateRoleDialogArgs {
  closeDialog: (toggle: boolean) => void;
}

class CreateRoleDialogComponent extends Component<CreateRoleDialogArgs> {
  @service declare authz: AuthzService;
  @service declare router: RouterService;

  @tracked roleScope: RoleScopeType | undefined = undefined;
  @tracked selectedRole: Role | undefined = undefined;

  get isBtnDisabled(): boolean {
    return isBlank(this.selectedRole) || isBlank(this.roleScope);
  }

  get companyRoles(): Array<Role> {
    return this.authz.roles.filter(
      (role: Role) => role.roleScope === RoleScopeType.COMPANY && !role.deactivated && role.legacyRole
    );
  }

  get locationRoles(): Array<Role> {
    return this.authz.roles.filter(
      (role: Role) => role.roleScope === RoleScopeType.LOCATION && !role.deactivated && role.name !== 'Employee'
    );
  }

  get roleOptions(): Array<Role> {
    if (this.roleScope === RoleScopeType.COMPANY) {
      return this.companyRoles;
    } else if (this.roleScope === RoleScopeType.LOCATION) {
      return this.locationRoles;
    } else {
      return [];
    }
  }

  get createRoute(): string {
    return adminRolesRoute(this.router.currentRouteName, 'manage.create-role');
  }

  get isCompanyRoleScopeSelected(): boolean {
    return this.roleScope === RoleScopeType.COMPANY;
  }

  get isLocationRoleScopeSelected(): boolean {
    return this.roleScope === RoleScopeType.LOCATION;
  }

  @action
  selectCompanyRole(): void {
    if (this.roleScope !== RoleScopeType.COMPANY) {
      this.roleScope = RoleScopeType.COMPANY;
      this.selectedRole = undefined;
    }
  }

  @action
  selectLocationRole(): void {
    if (this.roleScope !== RoleScopeType.LOCATION) {
      this.roleScope = RoleScopeType.LOCATION;
      this.selectedRole = undefined;
    }
  }
}

export default CreateRoleDialogComponent;
