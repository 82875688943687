import { service } from '@ember/service';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';
import type TenantConnectionRequestModel from 'garaje/models/tenant-connection-request';
import type TenantPermissionsConfigurationModel from 'garaje/models/tenant-permissions-configuration';
import { type SingleResponse } from 'jsonapi/response';

import type ZoneModel from './zone';

type TenantStatus = 'pending' | 'disconnected' | 'connected' | 'invited' | 'not_invited' | 'invite_expired';

class TenantModel extends Model {
  @service declare store;

  @attr('string') declare name: string;
  /**
   * the current status of a tenant
   */
  @attr('string') declare status: TenantStatus;
  @attr('string') declare logo: string;
  @attr('string') declare propertyName: string;
  @attr('string') declare propertyCompanyName: string;
  @attr('string') declare token: string;
  @attr('date', { defaultValue: null }) declare leaseExpirationDate: Date;
  @attr('currency') declare annualizedRent: number;
  @attr('string', { defaultValue: 'USD' }) declare annualizedRentCurrencyCode: string;
  @attr('number') declare expectedEmployeesCount: number;

  @belongsTo('tenant-connection-request', { async: false })
  declare tenantConnectionRequest?: TenantConnectionRequestModel;

  @belongsTo('tenant-permissions-configuration', { async: false })
  declare tenantPermissionsConfiguration: TenantPermissionsConfigurationModel;

  @hasMany('zone', { async: false }) declare suites: ZoneModel[];

  @belongsTo('zone', { async: false }) declare property?: ZoneModel;

  @attr('string') declare locationId: string;

  /**
   * if tenant is not sharing employee directory
   */
  get notSharingEmployees(): boolean {
    return this.tenantPermissionsConfiguration.employeesRead === false;
  }

  /**
   * if tenant can have a token generated
   */
  get canGenerate(): boolean {
    return (
      this.status === 'pending' ||
      this.status === 'disconnected' ||
      this.status === 'invited' ||
      this.status === 'not_invited' ||
      this.status === 'invite_expired'
    );
  }

  /**
   * if a tenant is connected
   */
  get isConnected(): boolean {
    return this.status === 'connected';
  }

  /**
   * if a tenant is invited
   */
  get isInvited(): boolean {
    return this.status === 'invited';
  }

  /**
   * if a tenant invite has expired
   */
  get isInviteExpired(): boolean {
    return this.status === 'invite_expired';
  }

  declare disconnect: (options?: unknown) => Promise<unknown>;
  declare regenerateToken: (options?: unknown) => Promise<unknown>;
}

TenantModel.prototype.disconnect = memberAction({
  type: 'POST',
  path: 'disconnect',
});

TenantModel.prototype.regenerateToken = memberAction({
  type: 'POST',
  path: 'regenerate-token',
  after: function (response: SingleResponse<TenantModel>) {
    const serializer = this.store.serializerFor('tenant');
    const normalized = serializer.normalizeSingleResponse(
      this.store,
      <Model>(<unknown>TenantModel),
      response,
      response.data.id,
      'findRecord'
    );

    return this.store.push(normalized);
  },
});

export default TenantModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    tenant: TenantModel;
  }
}
