import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias, or } from 'macro-decorators';

export default class SettingsWelcomeScreenController extends Controller {
  @service currentAdmin;
  @service featureFlags;
  @service('global-setting-batch') globalSettingBatchService;
  @service linkManager;
  @service router;
  @service state;

  @alias('globalSettingBatchService.currentGlobalSettingBatch') globalSettingBatch;
  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isLocationAdmin') isAdmin;

  params = null;

  get showSetupGuide() {
    return this.isAdmin && this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper');
  }

  get isVirtualFrontDesk() {
    const currentRoute = this.router.currentRouteName;
    return currentRoute.includes('virtual-front-desk');
  }

  get virtualFrontDeskTabs() {
    return [
      {
        label: 'Design',
        link: this.linkManager.createUILink({
          route: 'virtual-front-desk.settings.design',
          query: this.params,
        }),
      },
      {
        label: 'Multiple languages',
        link: this.linkManager.createUILink({
          route: 'virtual-front-desk.settings.multiple-languages',
          query: this.params,
        }),
      },
    ];
  }

  get tabs() {
    const tabs = [
      {
        label: 'Design',
        link: this.linkManager.createUILink({
          route: 'visitors.settings.welcome-screen.design',
          query: this.params,
        }),
      },
      {
        label: 'Multiple languages',
        link: this.linkManager.createUILink({
          route: 'visitors.settings.welcome-screen.multiple-languages',
          query: this.params,
        }),
      },
      {
        label: 'Touchless walk-ins',
        link: this.linkManager.createUILink({
          route: 'visitors.settings.welcome-screen.touchless-walkins',
          query: this.params,
        }),
      },
    ];

    if (
      this.featureFlags.isEnabled('kiosk-host-search-improvements') &&
      this.state.vrSubscription?.canAccessHostSearchOptions
    ) {
      tabs.push({
        label: 'Host search',
        link: this.linkManager.createUILink({
          route: 'visitors.settings.welcome-screen.host-search',
          query: this.params,
        }),
        isNew: true,
      });
    }

    tabs.push(
      {
        label: 'Returning visitors',
        link: this.linkManager.createUILink({
          route: 'visitors.settings.welcome-screen.returning-visitors',
          query: this.params,
        }),
      },
      {
        label: 'Deliveries',
        link: this.linkManager.createUILink({
          route: 'visitors.settings.welcome-screen.deliveries',
          query: this.params,
        }),
      }
    );

    return tabs;
  }
}
