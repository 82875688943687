import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureConfigService from 'garaje/services/feature-config';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { EMPLOYEE, GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST } from 'garaje/utils/roles';
import { Permission } from 'garaje/utils/ui-permissions';
import _intersection from 'lodash/intersection';

const CAN_EXPORT_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST];

const CAN_SEE_DELETED_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST];

const CAN_VISIT_ROLES = [EMPLOYEE, GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST];

const CAN_SEE_DIRECTORY_DETAILS_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN];

const CAN_MANUALLY_ADD = [GLOBAL_ADMIN, LOCATION_ADMIN];

export default class EmployeeAbility extends Ability {
  @service declare authz: AuthzService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare featureConfig: FeatureConfigService;
  @service declare state: StateService;

  get canVisit(): boolean {
    const employeeDirectoryEnabled = !this.featureFlags.isEnabled('disable-employee-directory');

    const { roleNames } = this.currentAdmin;
    const allowed = isPresent(_intersection(CAN_VISIT_ROLES, roleNames));

    if (this.featureFlags.isEnabled('global-employee-directory')) {
      const employeeLocations = this.currentAdmin.employee?.employeeLocations;
      const locationId = this.state.currentLocation?.id;
      let isEmployeeAtLocation = false;
      if (employeeLocations && locationId) {
        employeeLocations.forEach((loc) => {
          if (String(loc.locationId) === locationId) {
            isEmployeeAtLocation = true;
          }
        });
      }

      const hasPermission = this.authz.hasPermissionAtCurrentLocation(Permission.USER_MANAGEMENT_EMPLOYEE_READ);
      if (hasPermission && this.canManage) return true;
      // employees always have access to the directory
      if (isEmployeeAtLocation) return employeeDirectoryEnabled;
      // if the user is not an employee, they need the correct permissions
      if (!hasPermission) return false;
      // if they can view but not manage the directory, treat them like an employee
      if (!this.canManage) return employeeDirectoryEnabled;
      // default case
      return true;
    }

    if (allowed && roleNames.length === 1 && roleNames.includes(EMPLOYEE)) {
      // This scenario covers users with a location-user of type Employee
      return employeeDirectoryEnabled;
    } else if (!allowed) {
      /*
       There can be location users with a role different to Employee
       who still have a matching employee in the db. If
       employeeDirectoryEnabled then they should be allowed to access it.
       */
      return employeeDirectoryEnabled && Boolean(this.currentAdmin.employee);
    } else {
      return allowed;
    }
  }

  managePermissions = [
    Permission.USER_MANAGEMENT_EMPLOYEE_CREATE,
    Permission.USER_MANAGEMENT_EMPLOYEE_UPDATE,
    Permission.USER_MANAGEMENT_EMPLOYEE_DELETE,
    Permission.USER_MANAGEMENT_EMPLOYEE_IMPORT,
  ];

  get canManage(): boolean {
    if (this.featureFlags.isEnabled('global-employee-directory')) {
      return this.authz.hasAllPermissionsAtCurrentLocation(this.managePermissions);
    }

    return isPresent(_intersection(CAN_MANUALLY_ADD, this.currentAdmin.roleNames));
  }

  get canManageAll(): boolean {
    if (this.featureFlags.isEnabled('global-employee-directory')) {
      return this.authz.hasAllPermissionsAtCurrentCompany(this.managePermissions);
    }

    return isPresent(_intersection(CAN_MANUALLY_ADD, this.currentAdmin.roleNames));
  }

  get canExport(): boolean {
    if (this.featureFlags.isEnabled('global-employee-directory')) {
      return this.authz.hasPermissionAtCurrentLocation(Permission.USER_MANAGEMENT_EMPLOYEE_EXPORT);
    }

    return isPresent(_intersection(CAN_EXPORT_ROLES, this.currentAdmin.roleNames));
  }

  get canExportAll(): boolean {
    if (!this.featureFlags.isEnabled('global-employee-directory')) return false;
    return this.authz.hasPermissionAtCurrentCompany(Permission.USER_MANAGEMENT_EMPLOYEE_EXPORT);
  }

  get canSeeDeleted(): boolean {
    return isPresent(_intersection(CAN_SEE_DELETED_ROLES, this.currentAdmin.roleNames));
  }

  get canSeeDirectoryDetailsAtLocation(): boolean {
    if (this.featureFlags.isEnabled('global-employee-directory')) {
      // employeePrivateData is to be interpreted as "Should we show employee private data?";
      return this.canManage || this.featureConfig.isEnabled('employeePrivateData');
    }

    return (
      isPresent(_intersection(CAN_SEE_DIRECTORY_DETAILS_ROLES, this.currentAdmin.roleNames)) ||
      this.featureConfig.isEnabled('employeePrivateData')
    );
  }

  get canSeeDirectoryDetails(): boolean {
    if (this.featureFlags.isEnabled('global-employee-directory')) {
      // employeePrivateData is to be interpreted as "Should we show employee private data?";
      return this.canManageAll || this.featureConfig.isEnabled('employeePrivateData');
    } else {
      return false;
    }
  }
}
