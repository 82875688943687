import Component from '@glimmer/component';
import { get } from '@ember/object';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import { task } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

export default class VisitorsTrialEndModalBillingAdminCouponExperiment extends Component {
  @service statsig;
  @service store;
  @service state;
  @service flashMessages;
  @service router;

  get coupons() {
    const items = [];
    const experiment = this.statsig.getExperiment('visitor_trial_end_coupon_experiment');

    if (!isBlank(experiment.getValue('standard_coupon_set'))) {
      items.push({
        couponSetId: experiment.getValue('standard_coupon_set'),
        percentage: experiment.getValue('standard_coupon_percentage'),
        plan: this.store.findRecord('plan', 'standard-1'),
      });
    }

    if (!isBlank(experiment.getValue('premium_coupon_set'))) {
      items.push({
        couponSetId: experiment.getValue('premium_coupon_set'),
        percentage: experiment.getValue('premium_coupon_percentage'),
        plan: this.store.findRecord('plan', 'premium'),
      });
    }

    return items;
  }

  generateCouponCodeTask = task({ drop: true }, async ({ couponSetId, plan }) => {
    const company = this.state.currentCompany;

    const couponCode = this.store.createRecord('coupon-code', {
      company: company,
      couponSetId: couponSetId,
    });

    try {
      await couponCode.save();

      this.router.transitionTo('billing.subscribe', {
        queryParams: { period: 'yearly', plan: get(plan, 'id'), coupon: couponCode.id },
      });
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });
}
