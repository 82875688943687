import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { get } from '@ember/object';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

export default class LocationOverviewSignInFlowsGeneralController extends Controller {
  @service state;
  @service flashMessages;

  @dropTask
  *deleteTask() {
    try {
      yield this.model.globalFlow.destroyRecord();
      this.showModalDelete = false;
      this.flashMessages.showAndHideFlash('success', 'Global flow deleted');
      this.transitionToRoute('location-overview.sign-in-flows.index');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @dropTask
  *saveTask() {
    const { changeset } = this.model;

    yield changeset.validate();

    if (get(changeset, 'isInvalid')) {
      return;
    }

    try {
      yield changeset.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }
}
