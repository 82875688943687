/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { action, set } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { format, fromUnixTime, subMinutes } from 'date-fns';
import { task, dropTask } from 'ember-concurrency';
import type { Task } from 'ember-concurrency';
import type DraftModel from 'garaje/models/draft';
import type EmployeeModel from 'garaje/models/employee';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { defer } from 'rsvp';

interface TableRow {
  name: string;
  sort: string;
  key: string;
  context: string;
  label: string;
}

interface DraftFeedItemArgs {
  draft: DraftModel;
  fieldOptions: TableRow;
  removeDraft(draft: DraftModel): void;
  hasEmployee: boolean;
  createEmployeeModalTask: Task<void, []>;
}

export default class DraftFeedItem extends Component<DraftFeedItemArgs> {
  @service declare store: StoreService;
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare metrics: MetricsService;

  @tracked showMenu = false;
  @tracked createdBy: EmployeeModel | null = null;
  @tracked lastUpdatedBy: EmployeeModel | null = null;
  @tracked draftName = this.args.draft.name;

  get creationTime(): string {
    return format(
      subMinutes(fromUnixTime(this.args.draft.createdAt), this.state.minutesBetweenTimezones),
      'MMMM do, yyyy - h:mm aa'
    );
  }

  get draftAreaMapId(): string {
    return this.args.draft.belongsTo('draftAreaMap').id();
  }

  getDraftCreatedEmployeeTask = task({ drop: true }, async () => {
    try {
      this.createdBy = await this.store.findRecord('employee', this.args.draft.createdBy);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('employee not found', this.args.draft.createdBy);
    }
  });

  getDraftLastUpdatedEmployeeTask = task({ drop: true }, async () => {
    try {
      this.lastUpdatedBy = await this.store.findRecord('employee', this.args.draft.lastUpdatedBy);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('employee not found', this.args.draft.createdBy);
    }
  });

  getDraftDetailsTask = task({ drop: true }, async () => {
    await this.getDraftCreatedEmployeeTask.perform();
    if (this.args.draft.lastUpdatedBy) {
      await this.getDraftLastUpdatedEmployeeTask.perform();
    }
  });

  @dropTask
  deleteDraftModalTask: {
    perform(): Generator<Promise<boolean>, unknown, void>;
  } = {
    *perform(this: { context: DraftFeedItem; abort?: () => void; continue?: () => Promise<void> }) {
      const deferred = defer<boolean>();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = async () => {
        const { draft, removeDraft } = this.context.args;
        this.context.metrics.trackEvent('Deleting Draft', { id: draft.id });
        await draft.destroyRecord();
        removeDraft(draft);
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };

  @action
  onRenameInputChange(name: string): void {
    this.draftName = name;
  }

  @dropTask
  renameDraftModalTask: {
    perform(): Generator<Promise<boolean>, unknown, void>;
  } = {
    *perform(this: { context: DraftFeedItem; abort?: () => void; continue?: () => Promise<void> }) {
      const deferred = defer<boolean>();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = async () => {
        set(this.context.args.draft, 'name', this.context.draftName);
        await this.context.args.draft.save();
        this.context.metrics.trackEvent('Renaming Draft', {
          name: this.context.draftName,
          id: this.context.args.draft.id,
        });
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };
}
