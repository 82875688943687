import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { restartableTask, timeout, lastValue } from 'ember-concurrency';
import { inject as service } from '@ember/service';

/**
 * @param {String}              searchValue
 * @param {Function}            setQuery
 * @param {Function}            searchValueHasChanged
 * @param {Function}            updateSearchValue
 */
export default class VisitorSearch extends Component {
  @service store;

  @lastValue('doSearchVisitors') visitors;

  get uniqueSearchVisitors() {
    const visitors = this.visitors ?? [];
    return visitors.toArray().reduce((acc, curr) => {
      const name = get(curr, 'fullName');

      if (!acc.includes(name)) {
        acc = [...acc, name];
      }

      return acc;
    }, []);
  }

  @restartableTask
  *doSearchVisitors(val = '') {
    // do not search if input is less than 3 characters
    if (val.length < 3) {
      return [];
    }

    yield timeout(200);

    const params = {
      filter: {
        query: val,
      },
    };

    return yield this.store.query('entry', params);
  }

  @action
  searchVisitors(val) {
    this.doSearchVisitors.perform(val);
  }

  @action
  filterByVisitor(event) {
    // Enter
    if (event.keyCode === 13) {
      const searchVal = this.args.searchValue;
      this.args.setQuery(searchVal);
      event.stopPropagation();
    }

    // Space
    if (event.keyCode === 32) {
      // Prevent prop so we don't lose focus on our trigger
      event.stopPropagation();
    }
  }

  @action
  setSearchValue(val) {
    this.args.updateSearchValue(val);
    this.args.searchValueHasChanged();
  }
}
