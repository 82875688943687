import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class PropertySettingsTenantsEditController extends Controller {
  @action
  onClose(): void {
    // router service transition to unnecessarily reloads the tenants route model
    this.transitionToRoute('property.settings.tenants');
  }
}
