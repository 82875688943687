import Route from '@ember/routing/route';
import moment from 'moment-timezone';
import { all, hash } from 'rsvp';
import { get, set } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';

class WorkplaceEntriesRoute extends Route {
  @service abilities;
  @service state;
  @service router;
  @service store;
  @service workplaceMetrics;
  @service chameleon;

  titleToken = 'Entries';

  memoizedModel = null;

  queryParams = {
    date: {
      refreshModel: true,
    },
    query: {
      refreshModel: true,
    },
    filter: {
      refreshModel: true,
    },
    selectedFlow: {
      as: 'visitor_type',
      refreshModel: true,
    },
    refresh: {
      refreshModel: true,
    },
    sort: {
      refreshModel: true,
    },
  };

  beforeModel(transtion) {
    if (this.abilities.can('start visit start trial for workplace')) {
      this.router.transitionTo('workplace.start-trial');
    }
    const dateParam = get(transtion, 'to.queryParams.date');
    const hasInvalidDateParam = isPresent(dateParam) && !moment(dateParam).isValid();
    if (hasInvalidDateParam) {
      this.router.transitionTo('workplace.entries', { queryParams: { date: null } });
    }
  }

  async model({ date, query, filter, selectedFlow, sort }) {
    const { currentLocation } = this.state;
    const entries = [];

    if (query && this.memoizedModel?.currentLocationId === currentLocation.id) {
      return Object.assign(this.memoizedModel, { date, query, filter, selectedFlow, sort, entries });
    }

    const currentLocationId = currentLocation.id;
    const flows = this.state.loadFlows({ reload: false, locationId: currentLocationId });

    // Parallel Requests
    const { flows: loadedFlows } = await hash({ flows });

    const byLocation = { filter: { location: currentLocationId } };
    const plugins = this.store.query('plugin', byLocation);
    const pluginInstalls = this.store.query('plugin-install', byLocation);
    const locationFlowIds = currentLocation.hasMany('flows').ids();
    const signInFieldPages = await all(
      loadedFlows.filter((flow) => flow.employeeCentric).map((flow) => flow.signInFieldPage)
    );
    const customFields = await all(signInFieldPages.map((signInFieldPage) => signInFieldPage?.customFields));

    this.workplaceMetrics.trackEvent('EMPLOYEE_LOG_VIEWED');
    this.chameleon.trackDashboardPageEvent('EMPLOYEE_LOG_VIEWED');

    return hash({
      currentLocationId,
      date,
      entries,
      filter,
      flows,
      locationFlowIds,
      pluginInstalls,
      plugins,
      query,
      selectedFlow,
      sort,
      customFields,
      signInFieldPages,
    });
  }

  afterModel(model) {
    this.memoizedModel = model;
  }

  async setupController(controller) {
    super.setupController(...arguments);
    await all([controller.loadEmployeesTask.perform()]);
    controller.setStartAndEndTime();
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.connectedTenants = null;
      controller.clearSearch();
      controller.pollEventReports.cancelAll();
      set(controller, 'date', '');
    }
  }
}

export default WorkplaceEntriesRoute;
