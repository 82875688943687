import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type { DetailedChangeset } from 'ember-changeset/types';
import newGroupInviteChangeset from 'garaje/changesets/new-group-invite';
import type GroupInviteModel from 'garaje/models/group-invite';
import type LocationModel from 'garaje/models/location';
import type LocationSubscriptionModel from 'garaje/models/location-subscription';
import type SubscriptionModel from 'garaje/models/subscription';
import type StateService from 'garaje/services/state';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';
import handsontable from 'handsontable';

import type VisitorsGroupInvitesShowController from './controller';

export interface VisitorsGroupInvitesShowRouteModel {
  changeset: DetailedChangeset<GroupInviteModel> | null;
  groupInvite: GroupInviteModel;
  handsontable: unknown;
  location: LocationModel | null;
  vrSubscription: LocationSubscriptionModel | SubscriptionModel | null;
}

export default class VisitorsGroupInvitesShowRoute extends Route {
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare store: StoreService;
  @service declare transitionConfirm: TransitionConfirmService;

  beforeModel(): void {
    const { vrSubscription } = this.state;

    if (!vrSubscription?.canAccessGroupInviteRecords) {
      void this.router.replaceWith('visitors.invites.index');
    }
  }

  async model({
    group_invite_id: groupInviteId,
  }: {
    group_invite_id: string;
  }): Promise<VisitorsGroupInvitesShowRouteModel> {
    const { vrSubscription, currentLocation: location } = this.state;
    const include = this.groupInviteIncludes();
    const reload = this.shouldReloadRecord(groupInviteId);
    const groupInvite = await this.store.findRecord('group-invite', groupInviteId, { include, reload });
    const changeset = groupInvite
      ? (newGroupInviteChangeset(groupInvite) as DetailedChangeset<GroupInviteModel>)
      : null;

    const groupInviteLocation = groupInvite.location;

    if (groupInviteLocation?.id) {
      this.maybeSwitchCurrentLocation(location?.id, groupInviteLocation.id);
    }

    return { groupInvite, changeset, handsontable, vrSubscription, location };
  }

  setupController(
    controller: VisitorsGroupInvitesShowController,
    model: VisitorsGroupInvitesShowRouteModel,
    transition: Transition
  ): void {
    super.setupController(controller, model, transition);

    const { location } = model;

    if (location) void controller.loadAdditionalDataTask.perform(location);
  }

  groupInviteIncludes(): string {
    return ['employee', 'flow', 'location', 'additional-hosts'].join(',');
  }

  shouldReloadRecord(groupInviteId: string): boolean {
    const { store } = this;
    const groupInvite = store.peekRecord('group-invite', groupInviteId);

    if (!groupInvite) return true;

    const employeeId = groupInvite.belongsTo('employee').id();
    const flowId = groupInvite.belongsTo('flow').id();
    const locationId = groupInvite.belongsTo('location').id();

    // If there is associated data that isn't loaded, reload the group invite
    if (employeeId && !store.peekRecord('employee', employeeId)) return true;
    if (flowId && !store.peekRecord('flow', flowId)) return true;
    if (locationId && !store.peekRecord('location', locationId)) return true;

    return false;
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    const { changeset } = this.modelFor(this.routeName) as VisitorsGroupInvitesShowRouteModel;
    const isDirty = changeset?.isDirty;

    if (!isDirty) return;

    void this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue: () => {
        changeset?.rollback();
      },
    });
  }

  maybeSwitchCurrentLocation(currentLocationId?: string, groupInviteLocationId?: string): void {
    if (currentLocationId && groupInviteLocationId && currentLocationId !== groupInviteLocationId) {
      // eslint-disable-next-line ember/no-controller-access-in-routes
      const protectedController = this.controllerFor('protected');

      protectedController.send('switchLocation', groupInviteLocationId);
    }
  }
}
