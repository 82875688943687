import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import ManageAdminUsersEditRoute from 'garaje/pods/manage/admin-users/edit/route';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

class LocationOverviewAdminsEditRoute extends ManageAdminUsersEditRoute {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;
  @service declare skinnyLocations: SkinnyLocationsService;

  controllerName = 'manage/admin-users/edit';
  templateName = 'manage/admin-users/edit';

  async beforeModel(): Promise<void> {
    if (this.abilities.cannot('visit global-admin')) {
      void this.router.transitionTo('unauthorized');
    }
    await this.skinnyLocations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
  }
}

export default LocationOverviewAdminsEditRoute;
