// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { computed, get } from '@ember/object';

// eslint-disable-next-line ember/no-classic-classes
export default Component.extend({
  customFields: computed(
    'model.signInFields.[]',
    'model.signInFields.@each.{isDeleted,isCustom,position,identifier}',
    function () {
      return get(this, 'model.signInFields')
        .filter(function (field) {
          return !get(field, 'isDeleted') && get(field, 'isCustom') && get(field, 'identifier') !== 'host';
        })
        .sortBy('position');
    }
  ),

  nameField: computed('model.signInFields.@each.identifier', function () {
    return get(this, 'model.signInFields').findBy('identifier', 'name');
  }),

  emailField: computed('model.signInFields.@each.kind', function () {
    return get(this, 'model.signInFields').findBy('kind', 'email');
  }),

  phoneField: computed('model.signInFields.@each.kind', function () {
    return get(this, 'model.signInFields').findBy('kind', 'phone');
  }),
});
