import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';

export default class VirtualFrontDeskLandingPageController extends Controller {
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare currentAdmin: CurrentAdminService;

  @action
  redirectToGlobalOverview(): void {
    void this.router.transitionTo('location-overview.virtual-front-desk.locations');
  }

  get isOnGlobalOverview(): boolean {
    return this.router.isActive('location-overview');
  }

  get canAccessVirtualFrontDesk(): boolean | undefined {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
      this.state.vfdSubscription?.canAccessVirtualFrontDesk ||
      this.state.visitorsSubscription?.canAccessVirtualFrontDesk
    );
  }

  get isGlobalAdmin(): boolean {
    return this.currentAdmin.isGlobalAdmin;
  }

  get learnMoreLink(): string {
    return this.isGlobalAdmin && this.canAccessVirtualFrontDesk
      ? 'https://envoy.help/en/articles/9176168-virtual-front-desk'
      : 'https://envoy.com/products/virtual-front-desk';
  }
}
