import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import config from 'garaje/config/environment';

const testing = config.environment === 'test';

/**
 * @param {Boolean}   isBasic
 * @param {Boolean}   isPremium
 */
export default class DeliveriesAppTutorialComponent extends Component {
  @service featureFlags;
  @service metrics;
  @service state;

  @tracked showIosModal = false;
  @tracked showDroidModal = false;

  get hasWorkplaceSubscription() {
    return !!this.state.workplaceSubscription;
  }

  @action
  openModal(storeName) {
    const props = { qr_code_type: '' };
    if (storeName == 'ios') {
      this.showIosModal = true;
      props.qr_code_type = 'iOS';
    } else if (storeName === 'android') {
      this.showDroidModal = true;
      props.qr_code_type = 'Android';
    }
    const eventName = 'Deliveries Set Up - Mobile App QR Code Modal Opened';
    this.metrics.trackEvent(eventName, props);
  }

  @action
  closeModal(storeName) {
    const props = { qr_code_type: '' };
    if (storeName == 'ios') {
      this.showIosModal = false;
      props.qr_code_type = 'iOS';
    } else if (storeName === 'android') {
      this.showDroidModal = false;
      props.qr_code_type = 'Android';
    }
    const eventName = 'Deliveries Set Up - Mobile App QR Code Modal Closed';
    this.metrics.trackEvent(eventName, props);
  }

  @action
  trackStoreLinkClicked(link_text, event) {
    const eventName = 'Deliveries Set Up - Mobile App Link Clicked';
    this.metrics.trackEvent(eventName, { link_text });
    if (testing) {
      // don't follow external link when testing
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
}
