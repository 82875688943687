import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type DeviceModel from 'garaje/models/device';
import type LocationModel from 'garaje/models/location';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import type VisitorsOnboardingService from 'garaje/services/visitors-onboarding';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

interface VisitorsSettingsRouteModel {
  ipads: RecordArray<DeviceModel>;
}

export default class VisitorsSettingsRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare skinnyLocations: SkinnyLocationsService;
  @service declare visitorsOnboarding: VisitorsOnboardingService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  beforeModel(): Promise<unknown> {
    if (this.abilities.cannot('visit settings')) {
      void this.router.transitionTo('unauthorized');
    }
    // skinnyLocations are used by the global-settings-batch ability
    return this.skinnyLocations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
  }

  async model(): Promise<VisitorsSettingsRouteModel> {
    const currentLocation = <LocationModel>this.modelFor('current-location');
    const ipads = this.store.query('device', { filter: { location: currentLocation.id } });
    await this.visitorsOnboarding.loadSteps();
    return hash({ ipads });
  }

  afterModel(): void {
    void this.visitorsOnboarding.loadImpressionsTask.perform();
  }

  redirect(_model: VisitorsSettingsRouteModel, transition: Transition): void {
    this.visitorsOnboarding.gateRoute(this, transition);
  }
}
