import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { arrayToSentence } from 'garaje/helpers/array-to-sentence';
import lookupValidator from 'ember-changeset-validations';
import { service } from '@ember/service';
import { all, dropTask, task } from 'ember-concurrency';
import Changeset from 'ember-changeset';
import { tracked } from '@glimmer/tracking';
import macro, { alias, equal, reads, notEmpty } from 'macro-decorators';
import config from 'garaje/config/environment';
import EmployeeValidations from 'garaje/validations/employee';
import employeesSearcherTask from 'garaje/utils/employees-searcher';

const isUniqueRole = function (role) {
  return macro(function () {
    if (this.currentAdmin.roleNames.length > 1) {
      return false;
    }
    return this.currentAdmin.roleNames.includes(role);
  });
};

export default class LocationOverviewEmployeesDirectoryEmployeeController extends Controller {
  @service abilities;
  @service ajax;
  @service flashMessages;
  @service state;
  @service currentAdmin;
  @service currentLocation;
  @service featureFlags;
  @service userFeatureFlags;
  @service skinnyLocations;
  @service router;

  @controller('location-overview.employees.directory') employeesController;
  @tracked showDeleteEmployeeModal = false;

  @tracked changeset;
  @tracked isEditModeEnabled = false;

  @alias('state.currentCompany') currentCompany;
  @alias('state.vrSubscription') vrSubscription;
  @alias('skinnyLocations.currentCompanyLocations') locations;
  @alias('currentAdmin.isEmployee') isEmployee;
  @reads('changeset.isDirty') hasDirtyAttributes;
  @equal('currentLocation.location.employeesCsvUploadStatus', 'in_progress') employeeUploadInProgress;
  @notEmpty('currentCompany.scimIntegration.content') isScim;

  @isUniqueRole('Receptionist') isReceptionist;
  @isUniqueRole('Security') isSecurity;
  @isUniqueRole('Billing') isBilling;

  @(employeesSearcherTask().restartable())
  searchEmployeesTask;

  blankLocationOption = { id: null, name: '' };

  get notEditable() {
    const cannotManage = this.isGlobalOverview
      ? this.abilities.cannot('manage all employees')
      : this.abilities.cannot('manage employees');

    return (
      cannotManage ||
      !this.isEditModeEnabled ||
      (this.currentCompany.directorySyncProvider &&
        !this.model.employee.doNotSync &&
        !this.model.employee.manuallyAdded)
    );
  }

  get scimProvider() {
    const provider = this.currentCompany.scimIntegration.content?.provider;
    let integration = '';
    if (provider === 'okta') {
      integration = 'Okta';
    } else if (provider === 'onelogin') {
      integration = 'OneLogin';
    }

    return integration;
  }

  get showDoNotSyncToggle() {
    const notManuallyAdded = !this.model.employee.manuallyAdded;
    const notDeleted = !this.model.employee.deleted;
    const hasSync = this.currentCompany.directorySyncProvider;

    return notManuallyAdded && notDeleted && hasSync;
  }

  get defaultCountry() {
    return config.environment === 'test' ? 'us' : 'auto';
  }

  get employeeLocationOptions() {
    const employeeLocationIds = new Set(
      this.model.employeeLocations.map((employeeLocation) => employeeLocation.locationId)
    );

    const employeeLocations = [];
    this.locations.map((location) => {
      if (employeeLocationIds.has(parseInt(location.id, 10))) {
        // only inactive locations should be disabled
        const disabled = !isEmpty(location.disabledToEmployeesAt);
        let name = location.name;
        if (disabled) {
          name += ' (Disabled)';
        }
        employeeLocations.push({
          ...location,
          id: location.id,
          disabled,
          name,
        });
      }
    });

    return [this.blankLocationOption, ...employeeLocations];
  }

  get defaultLocation() {
    const defaultLocationId = this.changeset.defaultLocationId;
    return this.locations.find((location) => location.id == defaultLocationId) || this.blankLocationOption;
  }

  get isGlobalOverview() {
    return this.router.currentRouteName?.includes('location-overview');
  }

  get activeUserDocumentTemplateConfigurations() {
    return this.model.userDocumentTemplateConfigurations?.filterBy('active') || [];
  }

  get hasDocumentStatusEnabled() {
    return this.activeUserDocumentTemplateConfigurations.length > 0;
  }

  @action
  updateDefaultLocation(selectedLocation) {
    this.changeset.defaultLocationId = selectedLocation.id;
  }

  @action
  doSearch(term) {
    const extraFilters = {
      except: this.model.employee.id,
    };
    return this.searchEmployeesTask.perform(term, extraFilters);
  }

  @action
  searchAllEmployees(term) {
    return this.searchEmployeesTask.perform(
      term,
      { except: [this.model.employee.id, this.model.employee.manager?.id] },
      { withoutLocation: true }
    );
  }

  @action
  afterDelete() {
    // refresh model
    this.employeesController.resetEmployeesTask.perform().then(() => {
      this.checkRestrictions().then(() =>
        this.router.transitionTo(`${this.isGlobalOverview ? 'location-overview.' : ''}employees`)
      );
    });
  }

  @action
  closeEmployeeDeleteModal() {
    this.showDeleteEmployeeModal = false;
  }

  @action
  cancel() {
    this.router.transitionTo(`${this.isGlobalOverview ? 'location-overview.' : ''}employees`);
  }

  @action
  toggleDoNotSync() {
    const currentState = this.model.employee.doNotSync;
    if (currentState) {
      this.changeset.rollback();
    }
    this.model.employee.doNotSync = !currentState;
  }

  @action
  toggleEditMode() {
    this.isEditModeEnabled = !this.isEditModeEnabled;
  }

  updateAssistantsTask = task(async (assistants) => {
    this.model.employee.assistants = assistants;
    try {
      await this.model.employee.save();
      this.flashMessages.showAndHideFlash('success', 'Assistants updated');
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(error));
    }
  });

  updateManagerTask = task(async (manager) => {
    this.model.employee.manager = manager;
    try {
      await this.model.employee.save();
      this.flashMessages.showAndHideFlash('success', 'Manager updated');
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(error));
    }
  });

  restoreEmployee = task({ drop: true }, async () => {
    this.model.employee.deleted = false;

    try {
      await this.model.employee.save();

      this.employeesController.incrementProperty('employeesCount');
      this.employeesController.employees.addObject(this.model.employee);

      await this.checkRestrictions();

      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.router.transitionTo(`${this.isGlobalOverview ? 'location-overview.' : ''}employees`);
    } catch (e) {
      this.model.employee.deleted = true;
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  deleteEmployeeLocationsTask = dropTask({ debug: true }, async (selectedLocations, resetSelectedLocations) => {
    const { employee } = this.model;
    const deleteEmployeeCompletely = this.model.employeeLocations.length === selectedLocations.length;
    let actions = [];

    // If the employee doesn`t have any location left then destroy it
    if (deleteEmployeeCompletely) {
      actions.push(employee.destroyRecord());
    } else {
      actions = selectedLocations.map((employeeLocation) => employeeLocation.destroyRecord());
    }

    try {
      await all(actions);
      resetSelectedLocations();
      this.flashMessages.showAndHideFlash('success', 'Employee removed');
      if (deleteEmployeeCompletely) {
        this.router.transitionTo(`${this.isGlobalOverview ? 'location-overview.' : ''}employees.directory`);
        await this.employeesController.resetEmployeesTask.perform();
      }
    } catch (error) {
      const locationIds = selectedLocations.map((location) => location.id);
      const locationNames = this.locations
        .filter((location) => locationIds.includes(location.id))
        .map((location) => location.name)
        .join(', ');
      const errorText = `We were unable to remove the ${employee.name} from ${locationNames}`;
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  saveTask = task(async (changeset) => {
    await changeset.validate();
    const isValid = changeset.isValid;

    if (!isValid) {
      const errorTypes = [];

      if (this.changeset.error.name.validation) {
        errorTypes.push('name');
      }
      if (this.changeset.error.email.validation) {
        errorTypes.push('properly formatted email');
      }
      if (this.changeset.error.phoneNumber.validation) {
        errorTypes.push('properly formatted phone number');
      }

      const error = `Please have a ${arrayToSentence(errorTypes)}`;
      this.flashMessages.showFlash('error', error);
    } else {
      await changeset.save();
      await this.checkRestrictions();

      this.toggleEditMode();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    }
  });

  setupChangeset() {
    const validator = lookupValidator(EmployeeValidations);
    this.changeset = new Changeset(this.model.employee, validator, EmployeeValidations);
  }

  checkRestrictions() {
    return this.state.checkRestrictionsTask.perform(true);
  }
}
