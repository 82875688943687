import Model, { attr } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';

class BannerModel extends Model {
  @attr('string') declare componentName: string;

  declare dismiss: ReturnType<typeof memberAction>;
}

BannerModel.prototype.dismiss = memberAction({
  type: 'POST',
  path: 'dismiss',
});

export default BannerModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    banner: BannerModel;
  }
}
