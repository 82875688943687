import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StateService from 'garaje/services/state';

export default class VirtualFrontDeskLandingPageRoute extends Route {
  @service declare router: RouterService;
  @service declare state: StateService;

  async beforeModel(): Promise<void> {
    const vfdConfiguration = await this.state.currentLocation.getVfdConfiguration();
    if (vfdConfiguration?.enabled) {
      void this.router.transitionTo('virtual-front-desk.devices.ipads');
    }
  }
}
