import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import type EmergencyNotificationConfigurationModel from 'garaje/models/emergency-notification-configuration';
import type CurrentLocationService from 'garaje/services/current-location';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

interface PassivePhoneNumberCollectionToggleArgs {
  emergencyNotificationConfiguration: EmergencyNotificationConfigurationModel;
}

export default class PassivePhoneNumberCollectionToggle extends Component<PassivePhoneNumberCollectionToggleArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare workplaceMetrics: WorkplaceMetricsService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare currentLocation: CurrentLocationService;
  @service declare state: StateService;

  constructor(owner: unknown, args: PassivePhoneNumberCollectionToggleArgs) {
    super(owner, args);
  }

  get isVisible(): boolean {
    return (
      this.featureFlags.isEnabled('emergency-notifications-passive-phone-number-collection') &&
      !this.state.currentCompany?.directorySyncProvider &&
      (!!this.state.workplaceSubscription?.canAccessEmergencyNotifications ||
        !!this.state.emnoSubscription?.canAccessEmergencyNotifications)
    );
  }

  get isPassiveCollectionEnabled(): boolean {
    return !!this.args.emergencyNotificationConfiguration?.passivePhoneNumberCollectionEnabledAt;
  }

  toggleConfigurationTask = task({ drop: true }, async (value: boolean) => {
    const { emergencyNotificationConfiguration } = this.args;

    const props = {
      emergencyNotificationConfiguration: emergencyNotificationConfiguration.id,
      value: value,
    };

    const metricsVerb = this.isPassiveCollectionEnabled ? 'DISABLE' : 'ENABLE';

    this.workplaceMetrics.trackEvent(
      `EMERGENCY_NOTIFICATIONS_PASSIVE_PHONE_NUMBER_COLLECTION_${metricsVerb}D_TOGGLED`,
      props
    );

    try {
      const newValue = !this.isPassiveCollectionEnabled ? new Date(Date.now()) : null;

      set(emergencyNotificationConfiguration, 'passivePhoneNumberCollectionEnabledAt', newValue);
      await emergencyNotificationConfiguration.save();

      this.workplaceMetrics.logMonitorEvent(
        `EMERGENCY_NOTIFICATIONS_PASSIVE_PHONE_NUMBER_COLLECTION_${metricsVerb}D`,
        props
      );

      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      emergencyNotificationConfiguration.rollbackAttributes();

      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      this.workplaceMetrics.logMonitorError({
        event: `FAILED_TO_${metricsVerb}_EMERGENCY_NOTIFICATIONS_PASSIVE_PHONE_NUMBER_COLLECTION`,
        debugExtras: props,
      });
    }
  });
}
