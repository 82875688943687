import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { action, get } from '@ember/object';
import { alias, reads, or, uniqueBy } from 'macro-decorators';

export default class LocationOverviewGlobalDevicesPrintersController extends Controller {
  @service abilities;
  @service flashMessages;
  @service skinnyLocations;
  @service store;

  @controller('protected') protectedController;
  @controller('location-overview.global-devices') globalDevices;

  queryParams = [
    'sortBy',
    'sortDirection',
    'pageNumber',
    'pageSize',
    'locationId',
    'printerMode',
    'printerModel',
    'printerStatus',
  ];

  @tracked selectedPrinters = [];
  @tracked printerMode = '';
  @tracked locationId = '';
  @tracked printerStatus = '';
  @tracked printerModel = '';
  @tracked pageNumber = 1;
  defaultRecordCount = 0;
  defaultLocationFilter = { name: 'All locations', id: '' };

  @alias('globalDevices.showList') showList;
  @reads('model.printers.meta.total') metaRecordCount;
  @reads('model.printers.meta.models') printerModels;
  @or('metaRecordCount', 'defaultRecordCount') recordCount;
  @uniqueBy('skinnyLocations.readableByCurrentAdmin', 'id') uniqLocations;
  @or('printerMode', 'locationId', 'printerStatus', 'printerModel') isFiltered;

  get allowSelect() {
    return this.abilities.can('delete global-printers');
  }

  get printers() {
    // this computed property is created because multi-select does not like
    // model.printers - which is not an array
    return get(this.model, 'printers').toArray();
  }

  get isSelectAllIndeterminate() {
    const selectedCount = this.selectedPrinters.length;
    const isSelectAll = this.isSelectAll;
    return selectedCount > 0 && !isSelectAll;
  }

  get isSelectAll() {
    const selectedCount = this.selectedPrinters.length;
    const printerCount = this.printers.length;
    return printerCount === selectedCount;
  }

  @action
  clearSelectedPrinters() {
    this.selectedPrinters = [];
  }

  @action
  selectAllPrinter() {
    if (this.isSelectAll) {
      this.selectedPrinters = [];
    } else {
      this.selectedPrinters = [...this.printers];
    }
  }

  @action
  clearAllFilters() {
    this.printerMode = '';
    this.locationId = '';
    this.printerStatus = '';
    this.printerModel = '';
  }

  @action
  unselectPrinter(printers) {
    this.selectedPrinters.removeObject(printers);
  }

  @action
  selectPrinter(printers) {
    this.selectedPrinters.addObject(printers);
  }

  @action
  setAndClearPage(setter, val) {
    setter(val);
    this.pageNumber = 1;
  }

  @action
  goToPrinterPage(location) {
    this.protectedController.send('switchLocation', get(location, 'id'), 'visitors.devices.printers');
  }

  bulkActions(selectedPrinters) {
    return selectedPrinters.map((printer) => printer.destroyRecord());
  }

  @action
  refreshList() {
    this.send('refreshModel');
  }

  @action
  goToIPadPage(location) {
    // we need to load location before transition so that pingDevices method is available
    this.store.findRecord('location', get(location, 'id')).then((loadedLocation) => {
      this.protectedController.send('switchLocation', get(loadedLocation, 'id'), 'visitors.devices.ipads');
    });
  }
}
