import Controller from '@ember/controller';
import { action, get } from '@ember/object';
import updateAndSaveTask from 'garaje/utils/decorators/update-and-save-task';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { reads } from 'macro-decorators';

@updateAndSaveTask
class VisitorsSettingsVisitorTypesFlowBadgeController extends Controller {
  @service currentAdmin;

  @reads('model.flow', 'model.config.localizedFields') flow;

  get canConfigureBadgePrinting() {
    return get(this.model.vrSubscription, 'canEnableBadgePrinting') && isPresent(this.model.printer);
  }

  @action
  rollback() {
    this.model.badge.rollbackAttributes();
  }

  @action
  save(badge, e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    return this.updateAndSaveTask.perform(badge);
  }

  @action
  _redirectToEnvoy(path) {
    this.send('redirectToEnvoy', path);
  }
}

export default VisitorsSettingsVisitorTypesFlowBadgeController;
