import Controller from '@ember/controller';
import Changeset from 'ember-changeset';
import DeliveryAreaValidations from 'garaje/validations/delivery-area';
import lookupValidator from 'ember-changeset-validations';
import { action, get } from '@ember/object';
import updateAndSaveTask from 'garaje/utils/decorators/update-and-save-task';
import { dropTask } from 'ember-concurrency';
import { defer } from 'rsvp';

@updateAndSaveTask
class DeliveriesSettingsDeliveryAreasNewController extends Controller {
  get changeset() {
    const validator = lookupValidator(DeliveryAreaValidations);
    return new Changeset(this.model.deliveryArea, validator, DeliveryAreaValidations);
  }

  @action
  didSave(model) {
    this.transitionToRoute('deliveries.settings.delivery-areas.delivery-area.index', get(model, 'id'));
  }

  get modalElement() {
    return document.getElementById('modal');
  }

  @dropTask
  showConfirmationModalTask = {
    *perform() {
      const deferred = defer();

      this.abort = () => deferred.reject();
      this.continue = () => deferred.resolve();

      return yield deferred.promise;
    },
  };
}

export default DeliveriesSettingsDeliveryAreasNewController;
