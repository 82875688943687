import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class EmployeesDirectoryAddEmployeeFromLocationRoute extends Route {
  @service state;
  @service transitionConfirm;
  @service store;

  queryParams = {
    employeeId: null,
  };

  model(params) {
    const vrSubscription = this.state.vrSubscription;
    let employee;
    if (params.employeeId) {
      employee = this.store.find('employee', params.employeeId);
    }
    return hash({ employee, vrSubscription });
  }

  setupController(controller) {
    super.setupController(...arguments);

    if (controller.employeeId) {
      controller.isDirty = true;
      controller.employeeId = null;
    }
  }

  @routeEvent
  routeWillChange(transition) {
    const controller = this.controller;
    const isDirty = controller.isDirty;

    // no need to display confirmation modal when nothing is changed
    if (!isDirty) {
      return;
    }

    // display confirmation modal if the form is dirty
    this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue() {
        controller.isDirty = false;
        controller.model = null;
      },
    });
  }
}
