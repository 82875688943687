import { action } from '@ember/object';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import type AjaxService from 'garaje/services/ajax';
import type FlashMessagesService from 'garaje/services/flash-messages';

interface EmailFoundComponentArgs {
  email: string;
  changeEmail: (email: string) => void;
}

export default class EmailFoundComponent extends Component<EmailFoundComponentArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare ajax: AjaxService;
  @service declare store: StoreService;

  @action
  sendConfirmation(): void {
    void this.resend.perform();
  }

  resend = dropTask(async () => {
    try {
      const path = 'resend-confirmation';
      const adapter = this.store.adapterFor('user');
      const url = adapter.buildURL('user');

      await this.ajax.request(`${url}/${path}`, {
        contentType: 'application/vnd.api+json',
        type: 'POST',
        data: JSON.stringify({
          data: {
            attributes: {
              email: this.args.email,
            },
          },
        }),
      });

      this.flashMessages.showFlash('success', 'Confirmation sent!');
    } catch (e) {
      this.flashMessages.showFlash('error', 'Confirmation email not sent. Try again later.');
    }
  });
}
