import Route from '@ember/routing/route';
import { get, set } from '@ember/object';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class VisitorsSettingsVisitorTypesFlowBadgeRoute extends Route {
  @service featureFlags;
  @service state;
  @service store;
  @service transitionConfirm;

  model() {
    const { flow } = this.modelFor('visitors.settings.visitor-types.flow');
    const { ipads } = this.modelFor('visitors.settings');
    const { currentLocation, vrSubscription } = this.state;
    const config = get(currentLocation, 'config');
    const signInFieldPage = get(flow, 'signInFieldPage');
    const signInFields = signInFieldPage.then((p) => get(p, 'signInFields'));
    const badge = get(flow, 'badge');
    const printer = this.store.query('printer', { filter: { location: get(currentLocation, 'id') } });
    const photoPage = get(flow, 'photoPage');
    const enableBadgeCustomContent = this.featureFlags.isEnabled('badge-custom-content');
    const uiHooks = enableBadgeCustomContent
      ? this.store.query('ui-hook', {
          location: currentLocation.id,
          triggerNames: ['badge_text_field', 'badge_barcode', 'badge_qr_code'],
        })
      : [];

    return hash({
      badge,
      config,
      currentLocation,
      enableBadgeCustomContent,
      flow,
      ipads,
      photoPage,
      printer,
      signInFieldPage,
      signInFields,
      uiHooks,
      vrSubscription,
    });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      set(controller, 'isEditingBadgeCSS', false);
    }
  }

  @routeEvent
  routeWillChange(transition) {
    const { badge } = this.controller.model;
    const confirmTask = this.transitionConfirm.displayConfirmTask;

    if (get(badge, 'hasDirtyAttributes')) {
      confirmTask.perform(transition, {
        continue() {
          badge.rollbackAttributes();
        },
      });
    }
  }
}
