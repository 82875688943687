import Route from '@ember/routing/route';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import { hash } from 'rsvp';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

export default class DeliveriesRoute extends Route {
  @service locations;
  @service state;
  @service store;
  @service router;

  titleToken = 'Deliveries';

  beforeModel() {
    const { workplaceSubscription, realDeliveriesSubscription } = this.state;

    if (!isPresent(realDeliveriesSubscription) && workplaceSubscription?.cancelled) {
      this.router.transitionTo('workplace.trial-ended');
    }
  }

  model() {
    const { currentCompany: company, deliveriesSubscription, vrSubscription } = this.state;
    let deliveryAreas = [];
    let deliveryLimit;
    if (isPresent(deliveriesSubscription)) {
      deliveryAreas = this.store.findAll('delivery-area');
      deliveryLimit = this.store.queryRecord('delivery-limit', {});
    }
    return hash({ company, deliveryAreas, deliveriesSubscription, deliveryLimit, vrSubscription });
  }

  async afterModel() {
    await this.locations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
  }

  setupController(_controller, _model) {
    super.setupController(...arguments);
    set(this, 'state.showDeliveriesOnboarding', false);
  }
}
