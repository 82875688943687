import DS from 'ember-data';

// Converts place-id in the JSON to placeId in the app
// eslint-disable-next-line ember/no-classic-classes
export default DS.Transform.extend({
  deserialize(serialized) {
    serialized.placeId = serialized['place-id'];
    delete serialized['place-id'];
    return serialized;
  },

  serialize(deserialized) {
    deserialized['place-id'] = deserialized.placeId;
    delete deserialized.placeId;
    return deserialized;
  },
});
