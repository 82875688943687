import Route from '@ember/routing/route';
import Changeset from 'ember-changeset';
import { inject as service } from '@ember/service';

export default class extends Route {
  @service store;

  async model() {
    const model = this.modelFor('location-overview.sign-in-flows.show');

    return {
      ...model,
      changeset: new Changeset(model.globalFlow),
    };
  }
}
