/* eslint-disable ember/no-computed-properties-in-native-classes */
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import { isProductDisabled } from 'garaje/helpers/product-disabled';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureConfigService from 'garaje/services/feature-config';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import type WorkplaceRouteConfigService from 'garaje/services/workplace-route-config';
import includesAny from 'garaje/utils/includes-any';
import { GLOBAL_ADMIN, LOCATION_ADMIN } from 'garaje/utils/roles';

import { canUseDbeam } from './dbeam';

export default class WorkplaceAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;
  @service declare featureConfig: FeatureConfigService;
  @service declare workplaceRouteConfig: WorkplaceRouteConfigService;

  @reads('state.workplaceSubscription') subscription!: StateService['workplaceSubscription'];
  @reads('state.vrSubscription') vrSubscription!: StateService['vrSubscription'];
  @reads('currentAdmin.roleNames') roles!: CurrentAdminService['roleNames'];

  /**
   * @returns whether admin can enable/disable the "Capture proof of vaccination" feature
   */
  get canToggleProofOfVaccination(): boolean {
    const { subscription, vrSubscription, roles } = this;
    const canAccessProtectLegacyFeatures = !!vrSubscription?.canAccessProtectLegacyFeatures;
    return (isPresent(subscription) || canAccessProtectLegacyFeatures) && includesAny(roles, GLOBAL_ADMIN);
  }

  /**
   * @returns whether admin can enable/disable the "Capture SARS-CoV-2 test results" feature
   */
  get canToggleProofOfCovidNegativeTest(): boolean {
    const { subscription, vrSubscription, roles } = this;
    const canAccessProtectLegacyFeatures = !!vrSubscription?.canAccessProtectLegacyFeatures;
    return (isPresent(subscription) || canAccessProtectLegacyFeatures) && includesAny(roles, GLOBAL_ADMIN);
  }

  /**
   * @returns whether admin can view SARS-CoV-2 Document Approval Requirements
   */
  get canViewCovidDocumentRequirements(): boolean {
    const { subscription, vrSubscription, roles } = this;
    const canAccessProtectLegacyFeatures = !!vrSubscription?.canAccessProtectLegacyFeatures;
    return (
      (isPresent(subscription) ||
        (canAccessProtectLegacyFeatures && (!!vrSubscription?.isPremiumPlan || !!vrSubscription?.isEnterprisePlan))) &&
      includesAny(roles, GLOBAL_ADMIN)
    );
  }

  /**
   * @returns Admin can visit Workplace Employee settings
   */
  get canVisitSettings(): boolean {
    const routeConfig = this.workplaceRouteConfig.computeWorkplaceRouteConfig();
    return 'canVisitSettings' in routeConfig && routeConfig.canVisitSettings;
  }

  get canToggleEaDelegatedBooking(): boolean {
    const { roles } = this;

    const canUseFeature = this.canUseDbeam;

    return canUseFeature && includesAny(roles, GLOBAL_ADMIN, LOCATION_ADMIN);
  }

  get canVisitEmployeeLog(): boolean {
    const routeConfig = this.workplaceRouteConfig.computeWorkplaceRouteConfig();
    return 'canVisitEmployeeLog' in routeConfig && routeConfig.canVisitEmployeeLog;
  }

  /**
   * @returns whether admin can visit Workplace Announcements
   */
  get canVisitAnnouncements(): boolean {
    const routeConfig = this.workplaceRouteConfig.computeWorkplaceRouteConfig();
    return 'canVisitAnnouncements' in routeConfig && routeConfig.canVisitAnnouncements;
  }

  /**
   * @returns whether user can visit Workplace Landing Page
   */
  get canVisitStartTrial(): boolean {
    const routeConfig = this.workplaceRouteConfig.computeWorkplaceRouteConfig();
    return routeConfig.type === 'start-trial';
  }

  /**
   * @returns whether user can visit Workplace Landing Page
   */
  get canVisitSetupGuide(): boolean {
    const routeConfig = this.workplaceRouteConfig.computeWorkplaceRouteConfig();
    return 'canVisitSetupGuide' in routeConfig && routeConfig.canVisitSetupGuide;
  }

  /**
   * @returns whether user can visit workplace
   */
  get canVisit(): boolean {
    if (isProductDisabled('workplace')) {
      return false;
    }

    const routeConfig = this.workplaceRouteConfig.computeWorkplaceRouteConfig();
    return routeConfig.type !== 'off';
  }

  get canUseDbeam(): boolean {
    return canUseDbeam({
      workplaceSubscription: this.subscription,
      visitorsSubscription: this.vrSubscription,
      dbeamEaRolloutFeatureFlag: this.featureFlags.isEnabled('dbeam-ea-rollout'),
      dbeamAllowlistFeatureFlag: this.featureFlags.isEnabled('dbeam-allowlist'),
    });
  }
}
