import Service, { inject as service } from '@ember/service';
import { later } from '@ember/runloop';
import { set } from '@ember/object';
import { Promise as EmberPromise } from 'rsvp';

import urlBuilder from 'garaje/utils/url-builder';
export default class EmployeeUploadService extends Service {
  @service session;
  @service ajax;
  @service uploaderFactory;
  @service state;

  async uploadEmployeesCSV(file) {
    const { currentLocation } = this.state;
    set(currentLocation, 'employeesCsvUploadStatus', 'in_progress');
    try {
      await this.uploadCSV(file, currentLocation.id);
      set(currentLocation, 'employeesCsvUploadStatus', 'done');
      return;
    } catch (error) {
      set(currentLocation, 'employeesCsvUploadStatus', 'failed');
      throw error;
    }
  }

  getStatus(locationId) {
    const headers = {
      Accept: 'application/vnd.api+json',
    };

    if (this.session.isAuthenticated) {
      headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;
    }

    const url = urlBuilder.v3.employees.uploadStatusUrl();
    const settings = {
      url,
      type: 'GET',
      contentType: false,
      headers,
      data: { filter: { location: locationId } },
    };

    return this.ajax.request(url, settings);
  }

  uploadCSV(file, locationId) {
    const uploader = this.uploaderFactory.createUploader({
      url: urlBuilder.v3.employees.uploadUrl(),
      headers: {
        Accept: 'application/vnd.api+json',
      },
    });
    /* eslint-disable camelcase */
    return uploader.upload(file, { location_id: locationId }).then(
      () => {
        return new EmberPromise((resolve, reject) => {
          return this.waitOnStatus(locationId, resolve, reject);
        });
      },
      (error) => {
        // After we get rid of v1 path, we should probably refactor to propagate the JSON api object
        if (error.responseJSON && error.responseJSON.errors && error.responseJSON.errors[0].detail) {
          throw error.responseJSON.errors[0].detail;
        } else {
          throw error;
        }
      }
    );
    /* eslint-enable camelcase */
  }

  waitOnStatus(locationId, resolve, reject) {
    return this.getStatus(locationId).then((response) => {
      const attrs = response.data.attributes;
      if (attrs['upload-status'] === 'in_progress') {
        return later(this, 'waitOnStatus', locationId, resolve, reject, 1000);
      } else if (attrs['upload-status'] === 'done') {
        return resolve();
      } else {
        return reject(attrs.message);
      }
    });
  }
}
