import type { AsyncBelongsTo } from '@ember-data/model';
import { belongsTo } from '@ember-data/model';
import AbstractAgreementModel from 'garaje/models/abstract/abstract-agreement';
import type GlobalAgreementPageModel from 'garaje/models/global-agreement-page';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('agreement')
class GlobalAgreementModel extends AbstractAgreementModel {
  @belongsTo('global-agreement-page') declare agreementPage: AsyncBelongsTo<GlobalAgreementPageModel>;
}

export default GlobalAgreementModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'global-agreement': GlobalAgreementModel;
  }
}
