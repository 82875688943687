import Route from '@ember/routing/route';
import { hash } from 'rsvp';

export default class LocationOverviewSignInFlowsShowFinalScreenRoute extends Route {
  async model() {
    const { globalFlow } = this.modelFor('location-overview.sign-in-flows.show');
    const summaryPage = await globalFlow.summaryPage;

    return hash({
      flow: globalFlow,
      summaryPage,
    });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.messageType = '';
      controller.isEditingCustomFinalMessage = false;
    }
  }
}
