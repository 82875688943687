import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import type FlowModel from 'garaje/models/flow';
import type InviteModel from 'garaje/models/invite';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import type { RecordArray } from 'garaje/utils/type-utils';
import { filterBy, intersect, reads, sortBy } from 'macro-decorators';
import { localCopy } from 'tracked-toolbox';

interface InvitesTableInviteListArgs {
  flow?: FlowModel;
  invites?: RecordArray<InviteModel>;
  isLoading?: boolean;
  enableSelection?: boolean;
  onSelection?: (selectedInvites: InviteModel[]) => void;
  selectedInvites?: InviteModel[];
}

interface InviteColumnConfig {
  name: string;
  type: string | null;
  label?: string;
}

export default class InvitesTableInviteList extends Component<InvitesTableInviteListArgs> {
  scrollingElementId: string = `${guidFor(this)}-scrolling-list`;

  @localCopy('args.selectedInvites', () => []) selectedInvites!: InviteModel[];

  @intersect('invites', 'selectedInvites') selectedOnCurrentPage!: InviteModel[];
  @reads('args.flow') flow!: FlowModel;
  @reads('args.invites', []) invites!: RecordArray<InviteModel>;
  @reads('invites.length', 0) pageLength!: number;
  @sortBy('flow.signInFieldPage.signInFields', 'position') sortedSignInFields!: SignInFieldModel[];
  @filterBy('columns', 'type') tableColumns!: InviteColumnConfig[];

  get columns(): InviteColumnConfig[] {
    const primaryColumns = [
      { name: 'Name', type: 'name' },
      { name: 'Visitor email', type: 'email' },
    ];
    const standardColumns = [{ name: 'Private notes', type: 'private_notes' }];

    return [...primaryColumns, ...this.flowColumns, ...standardColumns];
  }

  get flowColumns(): InviteColumnConfig[] {
    if (!this.flow) return [];

    return this.sortedSignInFields.map((field: SignInFieldModel): InviteColumnConfig => {
      return this.columnConfigForField(field);
    });
  }

  get isPageSelected(): boolean {
    const { pageLength, selectedOnCurrentPage } = this;

    if (!(pageLength > 0 && selectedOnCurrentPage.length > 0)) return false;

    return selectedOnCurrentPage.length === pageLength;
  }

  get isPagePartiallySelected(): boolean {
    const { isPageSelected, selectedOnCurrentPage } = this;

    if (isPageSelected) return false;

    return selectedOnCurrentPage.length > 0;
  }

  columnConfigForField(field: SignInFieldModel): InviteColumnConfig {
    // Set const individually to avoid "Unsafe array destructuring of a
    // tuple element with an `any` value" lint error
    const name = field?.name ?? '';
    const isPhone = field?.isPhone ?? false;
    const isHost = field?.isHost ?? false;
    const isCustom = field?.isCustom ?? false;

    if (isPhone) return { name: 'Your Phone Number', label: 'Phone number', type: 'phone' };
    if (isHost) return { name: '', type: null };
    if (!isCustom) return { name: '', type: null };

    return { name, type: 'custom' };
  }

  notifySelectionChange(selected: InviteModel[] = this.selectedInvites): void {
    this.args.onSelection?.(selected);
  }

  @action
  selectionDidChange(selected: InviteModel[]): void {
    this.selectedInvites = [...selected];
    this.notifySelectionChange();
  }

  @action
  resetSelection(): void {
    this.selectedInvites = [];
    this.notifySelectionChange();
  }

  @action
  selectAllInvites(value: boolean): void {
    const records = this.invites.toArray();

    if (value) {
      const newSelection = [...this.selectedInvites, ...records].filter(
        (value: InviteModel, index: number, self: InviteModel[]): boolean => {
          return self.indexOf(value) === index;
        }
      );

      this.selectedInvites = newSelection;
    } else {
      const newSelection = this.selectedInvites.filter((invite: InviteModel): boolean => !records.includes(invite));

      this.selectedInvites = newSelection;
    }

    this.notifySelectionChange();
  }

  @action
  handleInvitesUpdate(): void {
    // Scroll the listing back to the top when @invites updated
    const { scrollingElementId } = this;
    const scrollingElement = document?.getElementById(scrollingElementId);

    if (scrollingElement) scrollingElement.scrollTo(0, 0);
  }
}
