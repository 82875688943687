import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { service } from '@ember/service';

export default class VisitorTypesIndexRoute extends Route {
  @service state;
  @service store;

  async model() {
    const { currentLocation } = this.state;
    const emergencyNotificationConfiguration = await currentLocation.getEmergencyNotificationConfiguration();

    const connectedTenants = await currentLocation.getPropertyConnections();
    const filter = { location: currentLocation.id, employee_centric: 'true,false' }; // filter includes both employee screening and regular flows

    // include property owned flows if the location is connected to a property
    if (connectedTenants.length > 0) filter.property_owned = 'true,false';

    const allFlowsForCurrentLocation = this.store.query('flow', {
      include: 'global-flow',
      filter,
    });

    let flowsWithNoPhoneNumberRequired;
    if (emergencyNotificationConfiguration.emergencyNotificationsEnabledAt !== null) {
      flowsWithNoPhoneNumberRequired = this.store.query('flow', {
        include: 'global-flow',
        filter: {
          location: currentLocation.id,
          employee_centric: false,
          phone_number_required: false,
        },
      });
    }

    return hash({
      connectedTenants,
      allFlowsForCurrentLocation,
      currentLocation,
      flowsWithNoPhoneNumberRequired,
    });
  }
}
