import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
import ArrayProxy from '@ember/array/proxy';
import EmberObject, { set, setProperties } from '@ember/object';
import { later } from '@ember/runloop';
import Service, { service } from '@ember/service';
import type { SafeString } from '@ember/template/-private/handlebars';
import config from 'garaje/config/environment';
import type MetricsService from 'garaje/services/metrics';
import flashPromise from 'garaje/utils/flash-promise';
import zft from 'garaje/utils/zero-for-tests';

const { environment } = config;
export type MessageType = 'success' | 'info' | 'error' | 'warning';

type MessageString = string | SafeString;

interface ShowFlashOptions {
  details?: MessageString;
  append?: boolean;
  showAboveModalOverlay?: boolean;
  stack?: boolean;
  icon?: string;
}

export type FlashMessage = EmberObject & {
  type: MessageType;
  message: MessageString;
  details?: MessageString;
  flashVisible: boolean;
  shouldAnimate: boolean;
  showAboveModalOverlay: boolean;
  componentName?: string;
  response?: Record<string, unknown>;
  icon?: string;
  append?: boolean;
  stack?: boolean;
};

export default class FlashMessagesService extends Service {
  @service declare metrics: MetricsService;

  messages = <
    ArrayProxy<FlashMessage> & {
      content: NativeArray<FlashMessage>;
    }
  >ArrayProxy.create({ content: A<FlashMessage>() });

  /**
   *
   * @param type
   * @param message message the heading for the flash notice
   * @param options message options
   * @param options.details details body text of the flash notice
   * @param options.append append use only to create multiple flash notices, default to only one visible notice
   * @param options.showAboveModalOverlay should the flash message appear above modal overlays
   * @param options.stack - allows multiple messages of the same type to be stacked. Will also append
   * @returns
   */
  showFlash(type: MessageType, message: MessageString, options?: ShowFlashOptions): boolean | FlashMessage | undefined;
  /**
   * @deprecated use showFlash(type: MessageType, message: string, options: ShowFlashOptions): boolean | FlashMessage | undefined;
   */
  showFlash(
    type: MessageType,
    message: MessageString,
    details?: MessageString | ShowFlashOptions,
    append?: boolean,
    showAboveModalOverlay?: boolean,
    stack?: boolean
  ): boolean | FlashMessage | undefined;
  showFlash(
    type: MessageType,
    message: MessageString,
    details: MessageString | ShowFlashOptions = '',
    append = false,
    showAboveModalOverlay = false,
    stack = false
  ): boolean | FlashMessage | undefined {
    let icon: string | undefined;
    let _details: MessageString | undefined;

    if (details && typeof details !== 'string' && !('toHTML' in details)) {
      icon = details.icon;
      append = details.append ?? append;
      showAboveModalOverlay = details.showAboveModalOverlay ?? showAboveModalOverlay;
      stack = details.stack ?? stack;
      _details = details.details;
    } else if (typeof details === 'string') {
      _details = details;
    }

    const existingTypes = A(this.messages.mapBy('type')).uniq();
    if (existingTypes.includes(type) && !stack) {
      return;
    }
    const flash = <FlashMessage>EmberObject.create({
      type,
      message,
      details: _details,
      flashVisible: true,
      shouldAnimate: true,
      showAboveModalOverlay,
      icon,
      append,
      stack,
    });

    const appendedOrStacked = this.messages.filter((flash) => flash.append || flash.stack);

    // only clear the messages if no appended or stacked messages have been added which implies that multiple messages are allowed
    if (!append && !stack && appendedOrStacked.length === 0) {
      // clears messages and only shows a single message
      this.messages.replace(0, this.messages.toArray().length, [flash]);
    } else {
      if (type === 'success' && !stack) {
        this.messages.unshiftObject(flash);
      } else {
        this.messages.pushObject(flash);
      }
    }
    later(() => set(flash, 'shouldAnimate', false), zft(500));
    return flash;
  }

  /**
   *
   * @param type
   * @param message message the heading for the flash notice
   * @param options message options
   * @param options.details details body text of the flash notice
   * @param options.append append use only to create multiple flash notices, default to only one visible notice
   * @param options.showAboveModalOverlay should the flash message appear above modal overlays
   * @param options.stack - allows multiple messages of the same type to be stacked. Will also append
   * @returns
   */
  showAndHideFlash(
    type: MessageType,
    message: MessageString,
    options?: ShowFlashOptions
  ): boolean | FlashMessage | undefined;
  /**
   * @deprecated use showAndHideFlash(type: MessageType, message: string, options: ShowFlashOptions): boolean | FlashMessage | undefined;
   */
  showAndHideFlash(
    type: MessageType,
    message: MessageString,
    details?: MessageString | ShowFlashOptions,
    append?: boolean,
    showAboveModalOverlay?: boolean,
    stack?: boolean
  ): boolean | FlashMessage | undefined;
  showAndHideFlash(
    type: MessageType,
    message: MessageString,
    details: MessageString | ShowFlashOptions = '',
    append = false,
    showAboveModalOverlay = false,
    stack = false
  ): FlashMessage | boolean | undefined {
    const duration = 3000;
    const flash = this.showFlash(type, message, details, append, showAboveModalOverlay, stack);
    if (environment !== 'test' && typeof flash === 'object') {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      later(this, this.hideFlash, flash, duration);
    }
    return flash;
  }

  /**
   *
   * @param flash - the flash message object
   */
  hideFlash(flash: FlashMessage): void {
    if (!flash || flash.isDestroying || flash.isDestroyed) {
      return;
    }
    setProperties(flash, {
      flashVisible: false,
      shouldAnimate: true,
    });
    later(() => {
      this.messages.removeObject(flash);
      flash.destroy();
    }, zft(500));
  }

  /**
   * Calls hideFlash for all messages, use when there is no reference for a `flash` object
   */
  hideAll(): void {
    this.messages.map((flash) => this.hideFlash(flash));
  }

  /**
   *
   * @param type
   * @param message message the heading for the flash notice
   * @param componentName componentName a component to render within the flash-message component
   * @param response response data for use with rendering the componentName component
   * @param showAboveModalOverlay should the flash message appear above modal overlays
   * @returns the flash message object
   */
  showFlashComponent(
    type: MessageType,
    message: MessageString,
    componentName: string,
    response: unknown,
    showAboveModalOverlay = false
  ): FlashMessage | undefined {
    const existingTypes = A(this.messages.mapBy('type')).uniq();
    if (existingTypes.includes(type)) {
      return;
    }
    const flash = <FlashMessage>EmberObject.create({
      type,
      message,
      componentName,
      response,
      flashVisible: true,
      shouldAnimate: true,
      showAboveModalOverlay,
    });
    if (type === 'success') {
      this.messages.unshiftObject(flash);
    } else {
      this.messages.pushObject(flash);
    }
    later(() => set(flash, 'shouldAnimate', false), zft(500));
    return flash;
  }

  promise(promise: PromiseLike<unknown>): void {
    return flashPromise(promise, this);
  }
}
