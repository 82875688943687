import ApplicationAdapter from './application';
import { inject as service } from '@ember/service';

export default class MailerTemplateAdapter extends ApplicationAdapter {
  @service state;

  urlForFindRecord() {
    const url = super.urlForFindRecord(...arguments);
    const { currentLocation, currentCompany } = this.state;
    const includes = ['mailer-template-sections.mailer-section.mailer-section-blocks.mailer-block'];
    const includesFormatted = `include=` + includes.join(',');
    // company and location ids are needed because mailer-block settings values are dynamic
    // and because some templates may be returned for certain locations / companies only
    return `${url}?meta[location_id]=${currentLocation.id}&meta[company_id]=${currentCompany.id}&${includesFormatted}`;
  }

  urlForFindAll() {
    const url = super.urlForFindAll(...arguments);
    const { currentLocation, currentCompany } = this.state;
    // company and location ids are needed because mailer-block settings values are dynamic
    // and because some templates may be returned for certain locations / companies only
    return `${url}?meta[location_id]=${currentLocation.id}&meta[company_id]=${currentCompany.id}`;
  }
}
