import { service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import type StoreService from '@ember-data/store';
import { task, dropTask, restartableTask } from 'ember-concurrency';
import type BillingCompanyModel from 'garaje/models/billing-company';
import type CompanyModel from 'garaje/models/company';
import type FlowModel from 'garaje/models/flow';
import type LocationModel from 'garaje/models/location';
import type TenantModel from 'garaje/models/tenant';
import type TenantConnectionRequestModel from 'garaje/models/tenant-connection-request';
import type UserModel from 'garaje/models/user';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import { TenantConnectionStatus } from 'garaje/utils/enums';
import type { RecordArray } from 'garaje/utils/type-utils';
import type { Constructor } from 'type-fest';

export interface CompanyMeta {
  'employees-count': number;
  'active-locations-count': number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
function withUserStateFunctionality<TBase extends Constructor<any>>(Base: TBase) {
  class withUserStateFunctionality extends Base {
    declare currentUser: UserModel | null;
    declare _companyId?: string | null;

    declare currentCompany: CompanyModel | null;
    declare billingCompany: BillingCompanyModel | null;
    declare currentLocation: LocationModel | null;

    declare tenantConnections: RecordArray<TenantModel> | null;
    declare tenantConnectionRequests: RecordArray<TenantConnectionRequestModel> | null;

    declare _companyMeta: CompanyMeta | null;

    @service declare featureFlags: FeatureFlagsService;
    @service declare store: StoreService;

    initUserState = dropTask(async () => {
      this.currentUser = await this._fetchCurrentUser.perform();
    });

    _setupUserAndCompanyFeatureFlags(currentUser: UserModel, currentCompany: CompanyModel) {
      const properties = {
        ['user']: currentUser,
        ['company']: currentCompany,
      };
      // eslint-disable-next-line ember/use-ember-get-and-set
      this.featureFlags.setProperties(properties);
      void this.featureFlags.ready();
    }

    _fetchCurrentUser = restartableTask(async () => {
      return await this.store.queryRecord('user', { id: 'me' });
    });

    loadTenantConnectionRequests = dropTask(async () => {
      this.tenantConnectionRequests = await this.store.query('tenant-connection-request', {
        id: 'my-connection-requests',
        include: 'tenant,connection-request-invites',
      });
    });

    loadTenantConnections = dropTask(async () => {
      this.tenantConnections = await this.store.query('tenant', {
        filter: {
          status: TenantConnectionStatus.CONNECTED,
        },
      });
    });

    // **************************************************************************
    // Visitors specific data
    // **************************************************************************

    loadFlows({
      includePropertyFlows = false,
      locationId = null,
      reload = true,
    }: { includePropertyFlows?: boolean; locationId?: string | null; reload?: boolean } = {}) {
      const lastResponse = this._loadFlowsTask.last;
      // @ts-ignore
      const [lastLocationId, lastIncludedPropertyFlows] = <[string]>lastResponse?.args ?? [];
      const location = isPresent(locationId) ? locationId : this.currentLocation!.id;
      const isSameLocation = isEmpty(locationId) || lastLocationId === location;
      const samePropertyFlows = lastIncludedPropertyFlows === includePropertyFlows;
      const canUseLastTask = lastResponse && isSameLocation && samePropertyFlows;

      if (canUseLastTask && lastResponse.isRunning) {
        // return the already running query task if it's currently running
        return lastResponse;
      } else if (canUseLastTask && !reload) {
        // if the flow task is not running and we're not reloading
        // pull in flows that might be in the store
        const flows = (lastResponse.value ?? []).filter((flow) => !flow.isDestroyed);
        const flowIds = flows?.map((flow) => flow.id);
        const flowsInStore = this.store.peekAll('flow').filter((flow) => flow.belongsTo('location').id() === location);

        flowsInStore.forEach((flow) => {
          // add flows that might've been added in previous ui flows
          if (!flowIds?.includes(flow.id)) {
            flows?.push(flow);
          }
        });

        // return a promise so this function always returns a promise
        // making the function async would mean resolving the task in the other block, might cause issues
        return new Promise<FlowModel[]>((resolve) => {
          return resolve(flows);
        });
      } else {
        return this._loadFlowsTask.perform(location, includePropertyFlows);
      }
    }

    _loadFlowsTask = task(async (locationId: string, includePropertyFlows: boolean) => {
      const includes = ['sign-in-field-actions', 'actionable-sign-in-field-actions', 'actionable-sign-in-fields'];
      const filter: { location: string; property_owned?: string; type: string } = {
        location: locationId,
        type: 'all',
      };
      if (includePropertyFlows) {
        filter['property_owned'] = 'true,false';
      }

      let include = 'agreement-page,';
      include +=
        'active-user-document-template-configurations,active-user-document-template-configurations.user-document-template,';
      include = include + includes.map((include) => `sign-in-field-page.sign-in-fields.${include}`).join(',');

      return (await this.store.query('flow', { include, filter })).slice();
    });
  }

  return withUserStateFunctionality;
}

export default withUserStateFunctionality;
