import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 * @param {Model<Device>}           printer
 * @param {Boolean}                 isSelected
 * @param {Function}                unselectPrinter
 * @param {Function}                selectPrinter
 * @param {Function}                goToPrinterPage
 * @param {Function}                goToIPadPage
 */
export default class GlobalDevicesPrintersPrinterTile extends Component {
  @action
  onPrinterCheckboxClick(event) {
    const printer = this.args.printer;

    if (event.target.checked) {
      // add printer when user select the printer
      this.args.selectPrinter(printer);
    } else {
      // remove printer from selected printer
      this.args.unselectPrinter(printer);
    }
  }
}
