import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import { action } from '@ember/object';

const SORT_COL_TO_FIELD_NAME = {
  Host: 'host',
  'Signed in': 'finalized_at',
  Name: 'full_name',
  Location: 'locations.name',
};

const ORDER_DESC_VALS = ['Z-A', 'Most to least recent'];
const PAGE_SIZE = 30;

export default class LocationOverviewVisitorLogRoute extends Route {
  @service abilities;
  @service currentLocation;
  @service skinnyLocations;
  @service router;
  @service store;
  @service globalOverviewAccess;

  queryParams = {
    pageNumber: {
      refreshModel: true,
    },
    pageSize: {
      refreshModel: true,
    },
    sortBy: {
      refreshModel: true,
    },
    sortDirection: {
      refreshModel: true,
    },
    query: {
      refreshModel: true,
    },
    locationIds: {
      refreshModel: true,
    },
    signedOut: {
      refreshModel: true,
    },
    startDate: {
      refreshModel: true,
    },
    endDate: {
      refreshModel: true,
    },
  };

  beforeModel() {
    if (!this.globalOverviewAccess.canVisitVisitorLog) {
      this.router.transitionTo('unauthorized');
    }
    return this.skinnyLocations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
  }

  model({ endDate, locationIds, pageNumber, query, signedOut, sortBy, sortDirection, startDate }) {
    const params = {};
    const offset = PAGE_SIZE * pageNumber - PAGE_SIZE;
    const store = this.store;
    const orderDescending = ORDER_DESC_VALS.includes(sortDirection);
    const sortOrder = orderDescending ? '-' : '';
    const sortProp = SORT_COL_TO_FIELD_NAME[sortBy];

    if (sortBy) {
      params.sort = `${sortOrder}${sortProp}`;
    }

    params.filter = {};

    if (query) {
      params.filter.query = query;
    }

    if (startDate) {
      params.filter.startDate = startDate;
    }

    if (endDate) {
      params.filter.endDate = endDate;
    }

    params.page = { offset, size: PAGE_SIZE, limit: PAGE_SIZE };

    if (locationIds) {
      params.filter.location = locationIds;
    }

    if (signedOut) {
      params.filter.status = signedOut;
    }
    let groups = [];
    if (this.abilities.can('view locations-grouping')) {
      // Groups have been already loaded by `skinnyLocations` service.
      groups = this.store.peekAll('group');
    }

    // performance tweak: we're specifically not returning an rsvp hash here so we can
    // respond quickly with a loading state in the template.
    return { entries: store.query('entry', params), params, groups };
  }

  @action
  loading(transition, _originRoute) {
    const controller = this.controllerFor('location-overview/visitor-log');
    controller.loadingData = true;
    transition.promise.finally(function () {
      controller.loadingData = false;
    });
  }
}
