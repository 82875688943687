import { action, set } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { readOnly } from '@ember/object/computed';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type EmergencyNotificationConfigurationModel from 'garaje/models/emergency-notification-configuration';
import type FlowModel from 'garaje/models/flow';
import type CurrentLocationService from 'garaje/services/current-location';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

interface EmergencyNotificationsToggleArgs {
  emergencyNotificationConfiguration: EmergencyNotificationConfigurationModel;
}

export default class EmergencyNotificationsToggle extends Component<EmergencyNotificationsToggleArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare workplaceMetrics: WorkplaceMetricsService;
  @service declare store: Store;
  @service declare currentLocation: CurrentLocationService;
  @service declare router: RouterService;
  @service declare state: StateService;

  @readOnly('state.visitorsSubscription') visitorsSubscription!: StateService['visitorsSubscription'];
  @readOnly('state.emnoSubscription') emnoSubscription!: StateService['emnoSubscription'];

  @tracked showUpdateSignInFlows = false;

  VISITOR_SIGN_IN_FLOW_ROUTE = 'visitors.settings.visitor-types';

  constructor(owner: unknown, args: EmergencyNotificationsToggleArgs) {
    super(owner, args);
    if (
      this.visitorsSubscription?.canAccessEmergencyNotifications ||
      (!this.visitorsSubscription?.cancelled && this.emnoSubscription?.canAccessEmergencyNotifications)
    ) {
      void this.shouldShowUpdateSignInFlows.perform();
    }
  }

  get isEmergencyNotificationsEnabled(): boolean {
    return this.args.emergencyNotificationConfiguration.emergencyNotificationsEnabledAt !== null;
  }

  shouldShowUpdateSignInFlows = task({ drop: true }, async () => {
    const queryParams = {
      include: 'global-flow',
      filter: {
        location: this.currentLocation.location.id,
        phone_number_required: false,
        employee_centric: false,
      },
    };
    const flows = <PaginatedRecordArray<FlowModel>>await this.store.query('flow', queryParams);
    this.showUpdateSignInFlows = flows.meta.total > 0;
  });

  toggleConfigurationTask = task({ drop: true }, async (value: boolean) => {
    const { emergencyNotificationConfiguration } = this.args;

    const props = {
      emergencyNotificationConfigurationId: emergencyNotificationConfiguration.id,
      value: value,
    };

    const metricsVerb = this.isEmergencyNotificationsEnabled ? 'DISABLE' : 'ENABLE';

    this.workplaceMetrics.trackEvent(`EMERGENCY_NOTIFICATIONS_${metricsVerb}_TOGGLED`, props);

    try {
      const newValue = !this.isEmergencyNotificationsEnabled ? new Date(Date.now()) : null;

      set(emergencyNotificationConfiguration, 'emergencyNotificationsEnabledAt', newValue);
      await emergencyNotificationConfiguration.save();

      this.workplaceMetrics.logMonitorEvent(`EMERGENCY_NOTIFICATIONS_${metricsVerb}D`, props);

      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      emergencyNotificationConfiguration.rollbackAttributes();

      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      this.workplaceMetrics.logMonitorError({
        event: `FAILED_TO_${metricsVerb}_EMERGENCY_NOTIFICATIONS`,
        debugExtras: props,
      });
    }
  });

  @action
  async routeToUpdateSignInFlows(): Promise<void> {
    this.workplaceMetrics.trackEvent('UPDATE_SIGN_IN_FLOWS_WITH_PHONE_NUMBER_CLICKED');
    await this.router.transitionTo(this.VISITOR_SIGN_IN_FLOW_ROUTE);
  }
}
