import gql from 'graphql-tag';

export default gql`
  query AnnouncementAudienceSizesQuery($locationId: ID!) {
    announcementAudienceSizes(locationId: $locationId) {
      locationId
      employeeAudienceCount {
        allEmployeesAtLocation
        allEmployeesAtDefaultLocation
        allEmployeesCheckedIn
        allEmployeesWithReservation
        self
      }
      visitorAudienceCount {
        allVisitorsCheckedIn
        allVisitorsWithReservation
      }
    }
  }
`;
