/* eslint-disable ember/no-computed-properties-in-native-classes */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { computed, set, get, setProperties } from '@ember/object';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { dropTask } from 'ember-concurrency';

/**
 *
 * @param {VisitorEntrance}         visitorEntrance
 */

export default class VisitorEntranceFormComponent extends Component {
  @service flashMessages;

  updatedName = '';

  // eslint-disable-next-line ember/classic-decorator-hooks
  init() {
    super.init(...arguments);

    setProperties(this, { updatedName: this.visitorEntrance.name });
  }

  /**
   * This CP returns wheter or not `visitorEntrance` has dirty changes
   *
   * @return {Boolean}
   */
  @computed('updatedName', 'visitorEntrance.hasDirtyAttributes')
  get changesOnFly() {
    return get(this.visitorEntrance, 'hasDirtyAttributes') || this.updatedName != this.visitorEntrance.name;
  }

  /**
   * This CP returns wheter or not `visitorEntrance` is in a valid state
   *
   * @return {Boolean}
   */
  @computed('changesOnFly', 'visitorEntrance.{useLocationAddress,addressLineOne}')
  get isValid() {
    if (!this.changesOnFly) {
      return true;
    }
    const { useLocationAddress, addressLineOne } = this.visitorEntrance;
    return isPresent(this.updatedName) && (useLocationAddress || isPresent(addressLineOne));
  }

  /**
   * @task `saveTask`
   */
  @dropTask
  *saveTask(event) {
    event.preventDefault();
    try {
      set(this.visitorEntrance, 'name', this.updatedName.trim());
      yield this.visitorEntrance.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }
}
