// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import type ComputedProperty from '@ember/object/computed';
import { isPresent } from '@ember/utils';
import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import type UserDocumentModel from 'garaje/models/user-document';
import type UserDocumentTemplateAttachmentModel from 'garaje/models/user-document-template-attachment';
import type VisitorDocumentModel from 'garaje/models/visitor-document';

export default class UserDocumentAttachmentModel extends Model {
  @belongsTo('user-document') declare userDocument: AsyncBelongsTo<UserDocumentModel> | UserDocumentModel;
  @belongsTo('user-document-template-attachment')
  declare userDocumentTemplateAttachment:
    | AsyncBelongsTo<UserDocumentTemplateAttachmentModel>
    | UserDocumentTemplateAttachmentModel;
  @belongsTo('visitor-document') declare visitorDocument: VisitorDocumentModel;

  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('string') declare fileUrl: string | null;
  @attr('string') declare file: string | null;

  @tracked uploadProgress = 0;

  // @ts-ignore: fix to not override the model's `isValid` property
  get isValid(): ComputedProperty<boolean> {
    if (!!this.isNew || !!this.hasDirtyAttributes) {
      return <ComputedProperty<boolean>>(<unknown>isPresent(this.file));
    }
    return <ComputedProperty<boolean>>(<unknown>isPresent(this.fileUrl));
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-document-attachment': UserDocumentAttachmentModel;
  }
}
