import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { guidFor } from '@ember/object/internals';
import { TrackedArray } from 'tracked-built-ins';

/**
 * @param {Model<Integration>}            integration
 * @param {Array}                         filterOptions
 * @param {Array}                         directoryGroups
 * @param {Array}                         locations
 * @param {Model<Company>}                company
 * @param {Boolean}                       canEdit
 * @param {Function}                      save
 * @param {Function}                      handleFailedQuery
 * @param {Function}                      hideFlash
 * @param {Function}                      showFlash
 * @param {Function}                      addFilter
 * @param {Function}                      removeFilter
 * @param {Function}                      preview
 * @param {Boolean}                       previewEnabled
 */
export default class SyncSettingsAllFilter extends Component {
  @service syncManager;
  @tracked search;

  elementId = guidFor(this);

  get isActive() {
    return get(this.args.integration ?? {}, 'filterType') === 'unfiltered';
  }

  get neverSynced() {
    return get(this.args.company, 'employeesSyncState') === 'never';
  }

  get isDirty() {
    return get(this.args.integration, 'hasDirtyAttributes') || this.neverSynced;
  }

  @action
  _resetResults() {
    if (!this.isActive) {
      this.search = null;
    }
  }

  @action
  async setFilter() {
    set(this.args.integration, 'filters', new TrackedArray());
    return await this.args.integration.save();
  }

  @dropTask
  *saveTask() {
    this.search = null;
    return yield this.args.integration.save();
  }

  @dropTask
  *cancelTask() {
    yield this.args.integration.rollbackAttributes();
  }

  @dropTask
  *testTask() {
    this.args.hideFlash();

    const promise = yield this.args.preview(this.args.integration).then(
      (data) => {
        this.search = { results: data };
      },
      (response) => {
        this.args.handleFailedQuery(response);
      }
    );

    return promise;
  }

  @action
  activate() {
    if (this.args.canEdit && !this.isActive) {
      set(this.args.integration, 'filterType', 'unfiltered');
    }
  }
}
