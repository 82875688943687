/* eslint-disable ember/no-computed-properties-in-native-classes */
import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
import { computed } from '@ember/object';
import { equal, filterBy, filter, gt, lt, or, readOnly } from '@ember/object/computed';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Model, { attr } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import DropdownOption from 'garaje/models/dropdown-option';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type LocationFeatureFlagsService from 'garaje/services/location-feature-flags';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import type RSVP from 'rsvp';

import type AbstractSignInFieldActionModel from './abstract-sign-in-field-action';

const LOCKED_FIELD_IDENTIFIERS = Object.freeze(['company', 'email', 'host', 'locality', 'name', 'phone']);

export default class AbstractSignInField extends Model {
  @service declare featureFlags: FeatureFlagsService;
  @service declare locationFeatureFlags: LocationFeatureFlagsService;

  @attr('string') declare kind: string;
  @attr('string') declare name: string;
  @attr('string') declare localized: string;
  @attr('boolean') declare required: boolean;
  @attr('number') declare position: number;
  @attr('string') declare identifier: string;
  @attr('immutable', {
    defaultValue: function () {
      return { name: {} };
    },
  })
  declare customTranslations: { name: Record<string, string> };
  @attr('boolean') declare storeResponse: boolean;
  @attr('boolean', { defaultValue: true }) declare allowEmployeeRespondents: boolean;
  @attr('boolean', { defaultValue: true }) declare allowVisitorRespondents: boolean;
  @attr('boolean', { defaultValue: true }) declare autoPopulatePreviousResponse: boolean;

  @attr('string') declare idScanField: string;

  showConditionals = false;

  // @ts-ignore
  @attr('array', {
    serializeItem: (item: DropdownOption) => item.serialize(),
    deserializeItem: (item: Partial<DropdownOption>) => new DropdownOption(item),
    defaultValue: () => A(),
  })
  declare options: NativeArray<DropdownOption>;

  @readOnly('name') label!: this['name'];
  @readOnly('isSingleSelection') dropdown!: this['isSingleSelection'];
  @or('localized', 'name') displayValue!: boolean;
  @filterBy('actionableSignInFields', 'isDeleted', false) nonDeletedActionableSignInFields!: AbstractSignInField[];
  @gt('nonDeletedSignInFieldConditionalActions.length', 0) hasConditionals!: boolean;
  @equal('actionableSignInFieldActions.length', 0) isTopLevel!: boolean;
  @equal('kind', 'single-selection') isSingleSelection!: boolean;
  @equal('identifier', 'host') isHost!: boolean;
  @equal('identifier', 'name') isGuestName!: boolean;
  @equal('kind', 'email') isEmail!: boolean;
  @equal('kind', 'phone') isPhone!: boolean;
  @or('isEmail', 'isPhone') isEmailOrPhoneField!: boolean;
  @or('isHost', 'isGuestName', 'isEmail', 'isPhone') isContactInfo!: boolean;
  @equal('identifier', 'locality') isLocality!: boolean;
  @filter(
    'signInFieldActions',
    (field) =>
      (<AbstractSignInFieldActionModel>field).isConditional && !(<AbstractSignInFieldActionModel>field).isDeleted
  )
  nonDeletedSignInFieldConditionalActions!: AbstractSignInFieldActionModel[];
  @lt('fieldPriority', 0) isHighPriorityField!: boolean;

  @computed('isEmailOrPhoneField', 'isGuestName')
  get isCustom(): boolean {
    return !this.isGuestName && !this.isEmailOrPhoneField;
  }

  @computed('identifier')
  get isAllowEmployeeRespondentsLocked(): boolean {
    return LOCKED_FIELD_IDENTIFIERS.includes(this.identifier);
  }

  @computed('identifier')
  get isAllowVisitorRespondentsLocked(): boolean {
    return LOCKED_FIELD_IDENTIFIERS.includes(this.identifier);
  }

  @computed('identifier')
  get isAutoPopulatePreviousResponseLocked(): boolean {
    return LOCKED_FIELD_IDENTIFIERS.includes(this.identifier);
  }

  /**
   * Is required input when employee creates an Invite
   */
  @computed('isEmail', 'required', 'allowVisitorRespondents')
  get isRequestedFromEmployee(): boolean {
    const { isEmail, required, allowVisitorRespondents } = this;

    if (
      isEmail &&
      (this.featureFlags.isEnabled('visitors-required-email-field') ||
        this.locationFeatureFlags.isEnabled('visitors-required-email-field-by-location'))
    ) {
      return true;
    }

    if (required && !allowVisitorRespondents) return true;

    return false;
  }

  /**
   * For spreadsheet input, move required columns closer to the left
   */
  @computed('isEmail', 'isContactInfo', 'required')
  get fieldPriority(): number {
    const { isEmail, isContactInfo, required, isRequestedFromEmployee } = this;
    const isEmailRequired =
      this.featureFlags.isEnabled('visitors-required-email-field') ||
      this.locationFeatureFlags.isEnabled('visitors-required-email-field-by-location');

    if (isEmail && isEmailRequired) return -3;
    if (required && isContactInfo) return -2;
    if (isContactInfo) return -1;
    if (isRequestedFromEmployee) return 1;

    return 3;
  }

  @computed(
    'initialOptions.@each.{label,value,position,customTranslations}',
    'options.@each.{label,value,position,customTranslations}'
  )
  get hasDirtyOptions(): boolean {
    return !isEqual(this.initialOptions, this.options.toArray());
  }

  constructor(properties: Record<string, unknown>) {
    super(properties);

    next(() => {
      this.initialOptions = cloneDeep(this.options.toArray());
    });
  }

  save(...parameters: Parameters<Model['save']>): RSVP.Promise<this> {
    return super.save(...parameters).then((model) => {
      this.initialOptions = cloneDeep(this.options.toArray());
      this.nonDeletedSignInFieldConditionalActions.forEach((act) => {
        if (!act.id && !act.dropdownOption?.id) {
          act._dropdownOption = this.options.findBy('position', act.dropdownOption?.position);
        }
      });

      return model;
    });
  }

  @tracked initialOptions: DropdownOption[] = [];
}
