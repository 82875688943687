import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action, set, get } from '@ember/object';
import { and, empty, match, not, or } from 'macro-decorators';
import Changeset from 'ember-changeset';

const KB = 1024;
const NAME_REGEX = /(\S+ )+\S+/; // at least 2 non-space characters with a space in between
const PHONE_REGEX = /^.*(\d.*){7,}$/; // at least 7 numbers

/**
 * @param {Class<BlacklistFilter>}    blocklistFilter
 * @param {Function}                  close
 * @param {Function}                  delete
 * @param {boolean}                   isSaving
 * @param {number}                    maxPhotoSizeKb
 * @param {boolean}                   readOnly                     - boolean indicating whether the blocklist should be read-only or not
 * @param {Function}                  save
 */
export default class BlocklistFilterModal extends Component {
  @service metrics;

  nameRegex = NAME_REGEX;
  phoneRegex = PHONE_REGEX;

  @tracked changeset;
  @tracked dataUrl = null;
  @tracked photoThumb = null;
  @tracked maxPhotoSizeKb;
  @tracked photoFile = null;
  @tracked isPhotoFileTooBig = false;
  @tracked didAttemptSubmit = false;
  @tracked uploadPhotoFileInputId = null;
  @tracked updatePhotoFileInputId = null;
  @tracked didEditFullName = false;
  @tracked blocklistFilter;

  // dataUrl comes first so that an updated preview shows instead of an existing thumb
  @or('dataUrl', 'photoThumb') photoPreview;
  @or('fullNameIsValid', 'emptyFullName') fullNameIsValidOrEmpty;
  @or('hasEmptyMatchingFields', 'emptyReason') isMissingRequiredFields;
  @or('isMissingRequiredFields', 'fullNameHasError') canNotSubmit;
  @not('fullNameIsValidOrEmpty') fullNameHasError;
  @not('hasEmptyMatchingFields') hasAtLeastOneMatchingField;
  @not('emptyReason') hasAReason;
  @match('changeset.fullName', NAME_REGEX) fullNameIsValid;
  @empty('changeset.reason') emptyReason;
  @empty('changeset.fullName') emptyFullName;
  @empty('changeset.aliases') emptyAliases;
  @empty('changeset.emails') emptyEmails;
  @empty('changeset.phoneNumbers') emptyPhoneNumbers;
  @empty('changeset.otherKeywords') emptyOtherKeywords;
  @and('emptyFullName', 'emptyAliases', 'emptyEmails', 'emptyPhoneNumbers', 'emptyOtherKeywords')
  hasEmptyMatchingFields;

  constructor() {
    super(...arguments);
    // use a changeset so that the form isn't mutating the underlying model prior to validation/saving
    // otherwise edits show up in the table view behind the modal
    const { blocklistFilter, maxPhotoSizeKb } = this.args;
    this.blocklistFilter = blocklistFilter;
    this.changeset = new Changeset(blocklistFilter);
    this.dataUrl = blocklistFilter?.photo.dataUrl;
    this.photoThumb = blocklistFilter?.photo.thumb;
    this.maxPhotoSizeKb = maxPhotoSizeKb || 500;
  }

  @action
  onInsert() {
    this.metrics.trackJobEvent('Security - Blocked Person Modal Opened', {
      block_list_filter_id: this.blocklistFilter?.id,
    });
  }

  @action
  onDestroy() {
    this.metrics.trackJobEvent('Security - Blocked Person Modal Closed', {
      block_list_filter_id: this.blocklistFilter?.id,
    });
  }

  updatePhoto(attrs) {
    const { changeset } = this;
    const photoCopy = {
      ...get(changeset, 'photo'),
      ...attrs,
    };
    set(changeset, 'photo', photoCopy);
  }

  @action
  setPhotoFile(file) {
    if (file.size < this.maxPhotoSizeKb * KB) {
      this.photoFile = file;
      this.isPhotoFileTooBig = false;
    } else {
      this.isPhotoFileTooBig = true;
    }
  }

  @action
  setPhotoDataUrl(dataUrl) {
    // https://github.com/poteto/ember-changeset#updates
    // we can't do mut in the template because nested changeset mut doesn't work
    // once we upgrade to ember-changeset 3.x we can use the changeset-set template helper to do this
    // this.changeset.set('photo.dataUrl', dataUrl); // eslint-disable-line ember/use-ember-get-and-set
    this.dataUrl = dataUrl;
    this.updatePhoto({ dataUrl });
  }

  @action
  removePhoto() {
    this.updatePhoto({
      dataUrl: null,
      thumb: null,
      original: null,
    });

    this.photoFile = null;
    this.dataUrl = null;
    this.photoThumb = null;
  }

  @action
  handleSubmit() {
    this.didAttemptSubmit = true;
    this.metrics.trackJobEvent(
      `Security - Blocked Person ${this.blocklistFilter?.isNew ? 'Creation' : 'Update'} Button Clicked`,
      {
        button_text: 'Save',
        form_state: this.canNotSubmit ? 'invalid' : 'valid',
      }
    );
    if (this.canNotSubmit) {
      return;
    } else {
      this.changeset.execute();
      this.args.save(this.photoFile);
    }
  }

  @action
  handleDelete() {
    this.metrics.trackJobEvent('Security - Blocked Person Delete Button Clicked', { button_text: 'Delete' });
    this.args.delete();
  }

  @action
  closeModal() {
    this.metrics.trackJobEvent('Security - Blocked Person Close Modal Button Clicked', { button_text: 'X' });
    this.args.close();
  }
}
