import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';

class ManageRoute extends Route {
  @service declare router: RouterService;
  @service declare abilities: AbilitiesService;

  titleToken = 'Admin Settings';

  beforeModel(): void {
    if (this.abilities.cannot('visit-local manage-settings')) {
      void this.router.transitionTo('unauthorized');
    }
  }
}

export default ManageRoute;
