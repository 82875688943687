import Model, { attr } from '@ember-data/model';
import { buildUrn } from 'garaje/utils/urn';

export default class UiHookModel extends Model {
  @attr('boolean') declare isHidden: boolean;
  @attr('string') declare clientId: string;
  @attr('string') declare clientName: string;
  @attr('string') declare icon: string;
  @attr('string') declare invocationType: string;
  @attr('string') declare label: string;
  @attr('string') declare triggerName: string;

  get token(): string {
    return `%{${this.urn}}`;
  }

  get urn(): string {
    return buildUrn(
      {
        triggerName: this.triggerName,
        uiHookId: this.id,
      },
      'HOOKS'
    );
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'ui-hook': UiHookModel;
  }
}
