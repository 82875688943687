import _defaults from 'lodash/defaults';
import { camelize, dasherize } from '@ember/string';
import serializer from 'garaje/utils/decorators/serializer';

@serializer('json')
class NotificationPeriod {
  constructor(attrs) {
    const props = Object.keys(attrs).reduce((acc, key) => Object.assign(acc, { [camelize(key)]: attrs[key] }), {});
    _defaults(props, {
      active: null, // boolean
      day: null, // number
      endAt: null, // string
      startAt: null, // string
    });
    Object.assign(this, props);
  }

  serialize() {
    return Object.getOwnPropertyNames(this).reduce(
      (acc, key) => Object.assign(acc, { [dasherize(key)]: this[key] }),
      {}
    );
  }
}

export default NotificationPeriod;
