import { getProperties } from '@ember/object';
import _defaults from 'lodash/defaults';

export default class Report {
  declare source: string | null;
  declare result: string | null;
  /**
   * reports are reviewable by default, but some types of reports (ex visual compliance) do not actually need to be reviewed.s
   */
  declare reviewable: boolean;

  constructor(attrs: Partial<Report> = {}) {
    const props = { ...attrs };
    _defaults(props, {
      source: null,
      result: null,
      reviewable: true,
    });
    Object.assign(this, props);
  }

  serialize(): Record<string, unknown> {
    return getProperties(this, <Array<keyof Report>>Object.getOwnPropertyNames(this));
  }
}
