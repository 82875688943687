import Controller from '@ember/controller';
import { action } from '@ember/object';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

import { type NewTenantRouteModel } from './route';

export default class PropertySettingsTenantsNewController extends Controller {
  declare model: NewTenantRouteModel;
  @action
  onClose(): void {
    // router service transition to unnecessarily reloads the tenants route model
    this.transitionToRoute('property.settings.tenants');
  }

  @action
  onSave(): void {
    this.model.loadTenantsTask.perform(this.model.property).catch(throwUnlessTaskDidCancel);
  }
}
