import { attr, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import AbstractDocumentModel from 'garaje/models/abstract/abstract-document';

export default class VisitorDocumentModel extends AbstractDocumentModel {
  @service store;
  @service featureFlags;

  // Relationships
  @hasMany('entry') entries;
  @hasMany('invite') invites;
  @hasMany('location') locations;
  @hasMany('recurring-invite') recurringInvites;

  // Attributes
  @attr('boolean') skipped;
  @attr('immutable', { defaultValue: () => [] }) externalAttachments;

  get userDocumentAttachmentsPendingUpload() {
    return this.userDocumentAttachments.filter(
      (userDocumentAttachment) => userDocumentAttachment.isValid && userDocumentAttachment.file instanceof File
    );
  }

  get hasAttachedFile() {
    return super.hasAttachedFile || this.externalAttachments.length > 0;
  }

  get isCapableOfAttachments() {
    const hasExternalAttachments = this.externalAttachments.length > 0;

    return (
      super.isCapableOfAttachments ||
      (this.featureFlags.isEnabled('visitors-id-scan-images') && (hasExternalAttachments || this.hasInputFieldData))
    );
  }

  /**
   * Delete the many-to-many association between this document record and invites
   *
   * @param {Array<Invite>} invites
   * @return {Promise<void>} HTTP DELETE request, expect 204
   */
  async removeFromInvites(invites = []) {
    const adapter = this.store.adapterFor('visitor-document');
    const url = adapter.buildURL('visitor-document', this.id) + '/relationships/invites';

    const data = {
      data: invites.map(({ id }) => {
        return { type: 'invites', id };
      }),
    };

    await adapter.ajax(url, 'DELETE', { data });
  }

  /**
   * Delete the many-to-many association between this document record and entries
   *
   * @param {Array<Entry>} entries
   * @return {Promise<void>} HTTP DELETE request, expect 204
   */
  async removeFromEntries(entries = []) {
    const adapter = this.store.adapterFor('visitor-document');
    const url = adapter.buildURL('visitor-document', this.id) + '/relationships/entries';
    const data = {
      data: entries.map(({ id }) => {
        return { type: 'entries', id };
      }),
    };

    await adapter.ajax(url, 'DELETE', { data });
  }
}
