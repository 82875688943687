import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { routeEvent } from 'garaje/utils/decorators/route';

import type { ProfileDocumentUploadRouteModel } from '../route';

export default class ProfileDocumentUploadConfirmRoute extends Route {
  afterModel({ userDocument, userDocumentTemplate }: ProfileDocumentUploadRouteModel): void {
    const requiredTemplateAttachment = userDocument?.checkRequiredTemplateAttachments();
    if (requiredTemplateAttachment) {
      void this.transitionTo(
        'profile.document.upload.attachment',
        userDocumentTemplate!.identifier,
        requiredTemplateAttachment.identifier
      );
    }
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    // @ts-ignore
    if (transition.targetName === 'profile.document.index') {
      this.send('documentListAltered');
    }
  }
}
