import AjaxService from 'ember-ajax/services/ajax';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import config from 'garaje/config/environment';

export default class extends AjaxService {
  @service cookieAuth;
  @service session;

  namespace = '/a/deliveries/api/v2';

  @computed('session.isAuthenticated')
  get headers() {
    const headers = {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
      'X-Forwarded-Host': config.hostName,
    };

    if (this.session.isAuthenticated) {
      headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;
    }

    return headers;
  }

  options() {
    const options = super.options(...arguments);

    return this.cookieAuth.decorate(options.url, options.type, options);
  }

  bulkUpdateAttribute(deliveries, attribute, value) {
    const attributes = {};
    attributes[attribute] = value;
    const payload = {
      data: deliveries.map((delivery) => {
        return {
          type: 'deliveries',
          id: get(delivery, 'id'),
          attributes,
        };
      }),
    };

    // dataType: 'text' needed because of a bug in ember-ajax https://github.com/ember-cli/ember-ajax/issues/185
    return this.patch('/deliveries/bulk', { data: payload, dataType: 'text' });
  }

  bulkUpdateRelationship(deliveries, relationship, id) {
    const relationships = {};
    relationships[relationship] = {
      data: { id },
    };
    const payload = {
      data: deliveries.map((delivery) => {
        return {
          type: 'deliveries',
          id: get(delivery, 'id'),
          relationships,
        };
      }),
    };

    // dataType: 'text' needed because of a bug in ember-ajax https://github.com/ember-cli/ember-ajax/issues/185
    return this.patch('/deliveries/bulk', { data: payload, dataType: 'text' });
  }

  bulkDelete(deliveries) {
    const payload = {
      ids: this.concatIds(deliveries),
    };

    // dataType: 'text' needed because of a bug in ember-ajax https://github.com/ember-cli/ember-ajax/issues/185
    return this.del('/deliveries/bulk', { data: payload, dataType: 'text' });
  }

  bulkRenotify(deliveries) {
    const payload = {
      ids: this.concatIds(deliveries),
    };

    // dataType: 'text' needed because of a bug in ember-ajax https://github.com/ember-cli/ember-ajax/issues/185
    return this.post('/deliveries/bulk-renotify', { data: payload, dataType: 'text' });
  }

  concatIds(deliveries) {
    return deliveries.reduce(function (ids, delivery) {
      const deliveryId = get(delivery, 'id');
      return ids ? [ids, deliveryId].join(',') : deliveryId;
    }, '');
  }
}
