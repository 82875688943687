import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { cached } from 'tracked-toolbox';
import MailerSectionBlockAttributeArrayProxy from '../lib/mailer-section-block-attribute-array-proxy';

export default class MailerSectionBlock extends Component {
  @cached
  get attributes() {
    const { mailerSectionBlockOverride, mailerSectionBlock } = this.args;
    return MailerSectionBlockAttributeArrayProxy.create({
      defaultAttributes: get(mailerSectionBlock, 'settings.attributes'),
      content: get(mailerSectionBlockOverride, 'settings.attributes'),
    });
  }

  @action
  updateSettings() {
    const { mailerSectionBlockOverride } = this.args;
    mailerSectionBlockOverride.settings = { ...mailerSectionBlockOverride.settings };
    this.args.onUpdate();
  }

  @action
  toggleActive() {
    const { mailerSectionBlockOverride } = this.args;
    mailerSectionBlockOverride.active = !mailerSectionBlockOverride.active;
    this.args.onUpdate();
  }
}
