import { helper } from '@ember/component/helper';

/**
 * The resource map uses leaflet latlng on a 1px equals 1 mapUnit scale
 * The backend saves coordinates on a
 */

/**
 * Converts envoy x/y to leaflet Latlng
 *
 * @returns {object}
 */
export function toLatLngArray([[x, y], mapDimensions]) {
  const [mapWidth, mapHeight] = mapDimensions;
  return [-y * mapHeight, x * mapWidth];
}

export function toLatLngObject([x, y], mapDimensions) {
  const [mapWidth, mapHeight] = mapDimensions;
  return { lat: -y * mapHeight, lng: x * mapWidth };
}

export function toCoords({ lng, lat }, [mapWidth, mapHeight]) {
  return {
    xPos: lng / mapWidth,
    yPos: Math.abs(lat / mapHeight),
  };
}

export default helper(toLatLngArray);
