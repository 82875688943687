import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { set } from '@ember/object';

/**
 *
 * @param {Location} currentLocation
 */
export default class WebRegistrationComponent extends Component {
  @service flashMessages;
  @service metrics;
  @service workplaceMetrics;

  @dropTask
  *toggleEmployeeScheduleVisibilityTask() {
    try {
      const enabled = !this.args.currentLocation.employeeScheduleEnabled;
      set(this.args.currentLocation, 'employeeScheduleEnabled', enabled);
      this.metrics.trackEvent(`Toggle Employee Schedule Visibility`, {
        enabled,
      });
      this.workplaceMetrics.trackEvent(
        `WORKPLACE_SETTINGS_SHARE_WORKPLACE_SCHEDULES_${enabled.toString().toUpperCase()}`
      );
      yield this.args.currentLocation.save();
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }
}
