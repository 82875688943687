import { isArray, A } from '@ember/array';
import DS from 'ember-data';
import _identity from 'lodash/identity';

// eslint-disable-next-line ember/no-classic-classes
export default DS.Transform.extend({
  deserialize(value, options = {}) {
    if (isArray(value)) {
      const deserializeItem = options.deserializeItem || _identity;
      value = (value ?? []).map((item) => deserializeItem(item));

      return A(value);
    } else {
      return A();
    }
  },
  serialize(value, options = {}) {
    if (isArray(value)) {
      const serializeItem = options.serializeItem || _identity;
      value = (value ?? []).map((item) => serializeItem(item));

      return A(value);
    } else {
      return A();
    }
  },
});
