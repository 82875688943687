import Controller from '@ember/controller';
import { set, action } from '@ember/object';
import { dropTask, all } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { FLOW_TYPE } from 'garaje/utils/enums';
import { isBlank } from '@ember/utils';
import { filterBy, or } from 'macro-decorators';
import { APP } from 'garaje/utils/enums';
import { tracked } from '@glimmer/tracking';

export default class VisitorTypesIndexController extends Controller {
  @service currentAdmin;
  @service featureFlags;
  @service flashMessages;

  @filterBy('model.allFlowsForCurrentLocation', 'enabled', true) enabledFlows;
  @filterBy('flows', 'type', FLOW_TYPE.GLOBAL_CHILD) globalChildFlows;

  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isLocationAdmin') isAdmin;

  @tracked showIpadDemoModal = false;

  app = APP.VISITORS;

  get flows() {
    const { allFlowsForCurrentLocation: flows } = this.model;
    return flows.filter(
      ({ type }) => isBlank(type) || type.toLowerCase() === 'flows' || type === FLOW_TYPE.GLOBAL_CHILD
    );
  }

  get walkupFlow() {
    const { allFlowsForCurrentLocation: flows } = this.model;
    return flows.find(({ type }) => type === FLOW_TYPE.PROPERTY_WALKUP);
  }

  get locationIsConnectedToProperty() {
    return this.model.connectedTenants.length > 0;
  }

  get showSetupGuide() {
    return this.isAdmin && this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper');
  }

  get flowsWithNoPhoneNumbersAsArray() {
    const { flowsWithNoPhoneNumberRequired: flows } = this.model;
    if (!flows) {
      return [];
    }
    return flows.toArray();
  }

  get isUserEligibleForModal() {
    return this.featureFlags.isEnabled('growth_ipad_demo');
  }

  @action
  showModal() {
    if (this.featureFlags.isEnabled('growth_ipad_demo')) {
      this.showIpadDemoModal = true;
    }
  }

  @action
  closeModal() {
    if (this.featureFlags.isEnabled('growth_ipad_demo')) {
      this.showIpadDemoModal = false;
    }
  }

  /**
   * @task `updateFlowPositions`
   */
  @dropTask
  *updateFlowPositions(flows) {
    yield all(
      flows.map((flow, index) => {
        set(flow, 'position', index + 1);
        return flow.save();
      })
    );
  }
}
