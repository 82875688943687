import type { AsyncBelongsTo } from '@ember-data/model';
import { belongsTo } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';
import AbstractPrinter from 'garaje/models/abstract/printer';
import type LocationModel from 'garaje/models/location';
import type VisitorEntranceModel from 'garaje/models/visitor-entrance';
import type { CollectionResponse } from 'jsonapi/response';

class PrinterModel extends AbstractPrinter {
  @belongsTo('visitor-entrance', { async: true }) declare visitorEntrance: AsyncBelongsTo<VisitorEntranceModel>;
  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;

  declare reprintBadge: (options: {
    data: Array<{ type: string; id: string }>;
  }) => Promise<CollectionResponse<{ result: string; statuses: string[] }>>;
}

PrinterModel.prototype.reprintBadge = memberAction({
  type: 'post',
  path: 'badge-print-jobs',
});

export default PrinterModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    printer: PrinterModel;
  }
}
