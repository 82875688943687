// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { action, set } from '@ember/object';
import { restartableTask, timeout } from 'ember-concurrency';
import zft from 'garaje/utils/zero-for-tests';

/**
 * Contextual component used for creating and editing basic BlocklistFilter information
 * @param {String}      name          Name of input, used for testing only
 * @param {String}      value         The value of the search-input
 * @param {String}      placeholder   Placeholder text for UI-INPUT component
 * @param {Boolean}     showClear     When to show the clear button
 * @param {Boolean}     enabled       When to show the input itself
 * @param {Task}        onChange      Task to send filterText to parent component
 */
export default class BlocklistFiltersSearchInput extends Component {
  focus() {
    this.delayedFocus.perform();
  }

  /**
   * Needed to ensure search input is focused
   */
  @restartableTask
  *delayedFocus() {
    const blocklistFilterSearchInput = document.getElementById('blocklist-search-input');
    if (blocklistFilterSearchInput) {
      yield timeout(zft(500));
      blocklistFilterSearchInput.focus();
    }
  }

  /**
   * Clears the search text
   */
  @action
  clearSearchText() {
    set(this, 'value', '');
  }
}
