import Route from '@ember/routing/route';
import { get, set } from '@ember/object';
import { service } from '@ember/service';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class EmployeeRegistrationRoute extends Route {
  title = 'Protect · Employee registration · Envoy';

  @service flow;
  @service state;
  @service store;
  @service transitionConfirm;

  async beforeModel(transition) {
    const flowId = transition.to.params.flow_id;
    const employeeScreeningFlow = await this.flow.getFlowWithIncludes(flowId);
    const currentLocationScreeningFlowId = get(this, 'state.currentLocation.skinnyLocation.employeeScreeningFlow.id');

    if (currentLocationScreeningFlowId !== flowId) {
      // If the current location has no associated screening flow,
      // only redirect if transitioning from a screening flow details screen.
      // This avoids redirecting when creating a brand new screening flow (transition from
      // 'protect.settings.employees.registration.index') or reloading details for a
      // screening flow that isn't assigned to any locations.
      if (currentLocationScreeningFlowId) {
        this.replaceWith('protect.settings.employees.registration.edit', currentLocationScreeningFlowId);
      } else if (get(transition, 'from.name') === 'protect.settings.employees.registration.edit') {
        this.replaceWith('protect.settings.employees.registration.index');
      }
    }

    return employeeScreeningFlow;
  }

  async model(params) {
    const model = {
      flow: null,
      signInFieldPage: null,
      signInFields: null,
      agreementPage: null,
      summaryPage: null,
      signInFieldActionRuleGroups: null,
      groups: [],
      ipads: [],
      locations: [],
    };

    model.flow = this.store.peekRecord('employee-screening-flow', params.flow_id);
    model.agreementPage = await model.flow.agreementPage;
    model.summaryPage = await model.flow.summaryPage;
    model.signInFieldPage = await model.flow.signInFieldPage;
    model.signInFields = model.signInFieldPage ? await model.signInFieldPage.signInFields : [];
    model.signInFieldActionRuleGroups = await model.flow.signInFieldActionRuleGroups;
    model.groups = this.store.peekAll('group');
    model.locations = await model.flow.locations;

    this.flow.selectedEmployeeScreeningFlow = model.flow;

    return model;
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    set(controller, 'selectedLocations', model.locations.toArray());
  }

  @routeEvent
  routeWillChange(transition) {
    const { isDirty, rollbackAll } = this.controller;

    if (isDirty()) {
      this.transitionConfirm.displayConfirmTask.perform(transition, {
        continue() {
          rollbackAll();
        },
      });
    }
    return true;
  }
}
