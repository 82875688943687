import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import ManageRoute from 'garaje/pods/manage/route';

class LocationOverviewManageRoute extends ManageRoute {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  beforeModel(): void {
    if (this.abilities.cannot('visit-global manage-settings')) {
      void this.router.transitionTo('unauthorized');
    }
  }
}

export default LocationOverviewManageRoute;
