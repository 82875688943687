import Controller, { inject as controller } from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import Changeset from 'ember-changeset';
import EmployeeValidations from 'garaje/validations/manually-added-employee';
import config from 'garaje/config/environment';
import lookupValidator from 'ember-changeset-validations';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { App } from 'garaje/utils/enums';

export default class LocationOverviewEmployeesDirectoryAddEmployeeNewController extends Controller {
  @service featureFlags;
  @service router;
  @service state;
  @service store;
  @service('setupGuideStepper') setupGuideStepperService;

  @controller('location-overview.employees.directory') employeesController;

  queryParams = ['prefill'];
  defaultCountry = config.defaultCountry;

  @tracked prefill = null;
  @tracked changeset;

  setupChangeset() {
    const validator = lookupValidator(EmployeeValidations);
    const changeset = new Changeset(this.model.employee, validator, EmployeeValidations);
    const prefill = this.prefill;
    if (prefill) {
      changeset.name = this.prefill;
      this.prefill = null;
    }
    this.changeset = changeset;
  }

  @action
  searchEmployees(params, limit) {
    if (limit) {
      params.page = { limit };
    }
    return this.store.query('employee', params);
  }

  @action
  addEmployee(newEmployee) {
    this.model.employee = newEmployee;
    const employee = this.model.employee;
    return employee.save();
  }

  // only at the location level
  @action
  addToLocation(employeeId) {
    const changeset = this.changeset;
    changeset.addError('email', {
      value: changeset.email,
      validation: 'Email found in other location',
    });

    this.router.transitionTo('employees.directory.add-employee.from-location', { queryParams: { employeeId } });
  }

  afterAddEmployeeTask = task(async (employee) => {
    this.employeesController.addEmployee(employee);
    await this.state.checkRestrictionsTask.perform(true);

    if (this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper')) {
      this.setupGuideStepperService.loadSetupStepsTask.perform(App.VISITORS);
    }

    if (this.router.currentRouteName?.includes('location-overview')) {
      this.router.transitionTo('location-overview.employees.directory.employee', employee.id);
    } else {
      this.router.transitionTo('employees.directory.employee', employee.id);
    }
  });
}
