/* eslint-disable ember/no-computed-properties-in-native-classes */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';
import { dropTask } from 'ember-concurrency';
import { sort } from '@ember/object/computed';
import { A } from '@ember/array';
import move from 'ember-animated/motions/move';
import { fadeOut } from 'ember-animated/motions/opacity';
import popupFlow from 'garaje/utils/popup-flow';
import { localCopy } from 'tracked-toolbox';
import { action } from '@ember/object';

export default class DashboardNotificationsComponent extends Component {
  @service ajax;
  @service stream;
  @service state;
  @service router;
  @service statsig;
  @service flashMessages;
  @service('setupGuideSteps') setupGuideStepsService;
  /**
   * @type {Array}
   */
  notifications = A([]);
  /**
   * Groups sorted by `name` to show in the dropdown selector
   */
  sortProperties = ['time:desc'];
  @sort('notifications', 'sortProperties') sortedNotifications;

  @localCopy('args.showEmpty', true) showEmpty;

  /**
   * @return {Boolean}
   */
  @computed('stream.configureFeedsForLocationTask.isRunning', 'getNotificationsTask.{isRunning,performCount}')
  get fetchingFeeds() {
    return (
      this.stream.configureFeedsForLocationTask.isRunning ||
      (this.getNotificationsTask.isRunning && this.getNotificationsTask.performCount === 1)
    );
  }

  // eslint-disable-next-line ember/classic-decorator-hooks
  init() {
    super.init(...arguments);
    const { currentLocation } = this.state;
    this.stream.configureFeedsForLocationTask
      .perform(currentLocation.id)
      .then(() => {
        if (this.stream.notificationsFeed) {
          this.getNotificationsTask.perform();
          // Subscribe to the changes
          set(
            this,
            'notificationSubscription',
            this.stream.notificationsFeed.subscribe(() => this.getNotificationsTask.perform())
          );
        }
      })
      .catch((error) => {
        /* eslint-disable no-console */
        console.log({ error });
        /* eslint-enable no-console */
      });
  }

  // eslint-disable-next-line ember/no-component-lifecycle-hooks
  willDestroyElement() {
    super.willDestroyElement(...arguments);
    if (this.notificationSubscription) {
      this.notificationSubscription.cancel();
    }
  }

  /* eslint-disable require-yield */
  *transition({ _insertedSprites, keptSprites, removedSprites }) {
    removedSprites.forEach(fadeOut);
    keptSprites.forEach(move);
  }
  /* eslint-enable require-yield */

  /**
   * getNotificationsTask
   */
  @dropTask
  *getNotificationsTask() {
    /* eslint-disable ember/use-ember-get-and-set */
    const data = yield this.stream.notificationsFeed.get({ limit: 6, enrich: true });

    const { results } = data;

    if (isBlank(results)) {
      set(this, 'notifications', []);
      return;
    }

    const activities = this.parseActivities(results);
    this.notifications.forEach((activity) => {
      const found = activities.findBy('id', activity.id);
      if (!found || activity.isRead) {
        this.notifications.removeObject(activity);
      }
    });
    // Add new ones
    activities.forEach((activity) => {
      const found = this.notifications.findBy('id', activity.id);
      if (!found && !activity.isRead) {
        this.notifications.pushObject(activity);
      }
    });
  }

  @dropTask
  *openPopup({ url, width = 750, height = 500 }) {
    yield popupFlow(url, width, height);
  }

  /**
   * Because `notification` is an aggregated feed. We need to parse it to make it flat
   */
  parseActivities(results) {
    const activities = results
      .reduce((aggregated, { activities }) => {
        const currentObjIds = aggregated.map(({ object: { id } }) => id);
        const filteredActivities = activities.filter(({ object: { id } }) => {
          if (currentObjIds.includes(id)) {
            return false;
          }
          currentObjIds.push(id);
          return true;
        });
        return [...aggregated, ...filteredActivities];
      }, [])
      .map(({ verb, time, object: objectData, target: { data: targetData } }, index) => {
        const notification = results[index];
        const fullId = notification.id; // we need the fullId to mark as seen/read
        const id = notification.group;
        const isRead = notification.is_read;
        const inviteId = verb === 'blocklist-match' ? notification.activities?.[0]?.object?.id : null;
        return {
          fullId,
          id,
          isRead,
          objectData,
          targetData,
          time,
          verb,
          inviteId,
          actionRoute: this._actionRoute(verb, inviteId),
        };
      });
    return A(activities);
  }

  _actionRoute(verb) {
    switch (verb) {
      case 'blocklist-match':
        return 'visitors.invites.show';
      case 'offline':
        return 'visitors.devices.ipads';
      case 'no-printer':
        return 'visitors.devices.printers';
    }
  }

  @action
  handleNotificationAction(route, inviteId, verb) {
    if (route) {
      this.statsig.logEvent('homepage-notification-clicked', null, { verb });
      if (verb === 'blocklist-match') {
        this.router.transitionTo(route, inviteId);
      } else {
        this.router.transitionTo(route);
      }
    }
  }

  @action
  async handleDismissNotification(notificationId, verb) {
    this.statsig.logEvent('homepage-notification-dismissed', null, { notificationId, verb });
    try {
      // eslint-disable-next-line ember/use-ember-get-and-set
      await this.stream.notificationsFeed.get({ mark_read: [notificationId] });
      this.notifications.forEach((activity) => {
        if (activity.fullId === notificationId) {
          this.notifications.removeObject(activity);
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to dismiss notification:', error);
      this.flashMessages.showAndHideFlash('error', 'Unable to dismiss notification. Please try again later.');
    }
    return false;
  }
}
