import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type IsOpenService from 'garaje/services/is-open';
import type StateService from 'garaje/services/state';

interface VirtualFrontDeskArgs {
  canViewDeviceAlertsNav: boolean;
  trackClickEvent: (trackingString: string) => void;
}

export default class SideBarLocationMenuVirtualFrontDeskComponent extends Component<VirtualFrontDeskArgs> {
  @service declare isOpen: IsOpenService;
  @service declare state: StateService;
  @service declare featureFlags: FeatureFlagsService;

  @tracked vfdEnabled: boolean = false;

  constructor(...args: unknown[]) {
    // @ts-ignore
    super(...args);

    if (this.featureFlags.isEnabled('growth-vfd-ga')) {
      void this.loadVfdEnabled();
    }
  }

  @action
  async loadVfdEnabled(): Promise<void> {
    const vfdConfiguration = await this.state.currentLocation.getVfdConfiguration();
    this.vfdEnabled = vfdConfiguration?.enabled ?? false;
  }
}
