import { belongsTo } from '@ember-data/model';
import AbstractAgreementModel from 'garaje/models/abstract/abstract-agreement';
import OnboardingChangesTracker from 'garaje/utils/decorators/onboarding-changes-tracker';

import type AgreementPageModel from './agreement-page';

@OnboardingChangesTracker({ taskName: 'loadFlowsTask', attributes: ['name'] })
class AgreementModel extends AbstractAgreementModel {
  @belongsTo('agreement-page') declare agreementPage: AgreementPageModel;
}

export default AgreementModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    agreement: AgreementModel;
  }
}
