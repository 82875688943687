/* eslint-disable ember/classic-decorator-hooks */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { set } from '@ember/object';
import { htmlSafe } from '@ember/template';
/**
 * @param {String}          url
 * @param {Boolean}         isLoading
 */

export default class EmbeddedIframeComponent extends Component {
  init() {
    super.init(...arguments);
    set(this, 'isLoading', true);
  }

  // eslint-disable-next-line ember/no-component-lifecycle-hooks
  didInsertElement() {
    super.didInsertElement(...arguments);
    const iframe = this.element.getElementsByTagName('iframe')[0];
    iframe.onload = () => {
      set(this, 'isLoading', false);
    };
  }

  get iframeStyle() {
    let style = 'border:none;height: calc(100vh - 10.5rem);';
    if (this.customizedStyle) {
      style += `${this.customizedStyle}`;
    }
    return htmlSafe(style);
  }
}
