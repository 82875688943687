import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import config from 'garaje/config/environment';

/**
 * @param {Object}                              field
 * @param {Object}                              output
 * @param {Object}                              install
 * @param {Object}                              plugin
 * @param {Object}                              value
 * @param {Service<CurrentLocation>}            currentLocation
 * @param {Function}                            updateValue
 */
export default class Text extends Component {
  @service ajax;
  @service flashMessages;

  @tracked loading = true;
  @tracked error;

  constructor() {
    super(...arguments);
    this.getValue.perform();
  }

  get inputValue() {
    if (this.loading) {
      return 'Loading...';
    }
    return this.error || this.args.value;
  }

  set inputValue(value) {
    this.args.updateValue(value);
  }

  get placeholder() {
    const placeholderKey = this.args.field.placeholder_key;
    return placeholderKey ? this.args.install.config[placeholderKey] : this.args.field.placeholder;
  }

  @task
  *getValue() {
    if (!this.args.field.endpoint) {
      return (this.loading = false);
    }
    try {
      const { value } = yield this.ajax.request(
        `${config.apiHost}/platform/${get(this.args.plugin, 'key')}/${this.args.field.endpoint}`,
        {
          data: {
            location_id: get(this.args, 'currentLocation.id'),
          },
        }
      );
      this.args.updateValue(value);
      this.error = null;
    } catch (e) {
      let msg;
      try {
        msg = e.payload[0].detail.split('\n')[0];
      } catch (e) {
        // continue regardless of error
      }
      this.error = msg ?? 'Error loading value';
      this.flashMessages.showFlash('error', `Error loading value. ${msg}`);
    }
    this.loading = false;
  }
}
