import Controller from '@ember/controller';
import { get, set, action } from '@ember/object';
import { inject as service } from '@ember/service';
import urlBuilder from 'garaje/utils/url-builder';
import { dropTask, all } from 'ember-concurrency';
import { alias } from 'macro-decorators';
import { tracked } from '@glimmer/tracking';
import { DESK_COUNT } from 'garaje/utils/enums';
import { defer } from 'rsvp';

export default class DesksIndexPageController extends Controller {
  @service state;
  @service subscriptions;
  @service featureFlags;
  @service featureConfig;
  @service('flow') employeeScreeningFlow;
  @service metrics;
  @service router;
  @service store;

  @alias('state.desksSubscription') desksSubscription;
  @alias('desksSubscription.isBasicPlan') isBasicPlan;
  @alias('state.visitorsSubscription') visitorsSubscription;
  @alias('employeeScreeningFlow.activeEmployeeScreeningFlow') activeEmployeeScreeningFlow;
  @alias('state.workplaceSubscription') workplaceSubscription;

  @tracked showOvercrowdedModal = true;
  @tracked showDeactivateDesksModal;
  @tracked amenities = [];

  get unlimitedDesksEnabled() {
    return this.featureConfig.isEnabled('desks.unlimitedDesks');
  }

  get maximumDesksForCurrentPlan() {
    return this.featureConfig.getLimit('desks.unlimitedDesks');
  }

  get downloadCsvUrl() {
    return urlBuilder.rms.downloadDesksCsv(this.state.currentLocation.id);
  }

  get desksRemainingInSubscription() {
    return Math.max(this.maximumDesksForCurrentPlan - this.assignableDesksQuantity, 0);
  }

  get showLimitWarning() {
    if (!this.featureConfig.isEnabled('desks') || this.unlimitedDesksEnabled) {
      return false;
    }
    return this.desksRemainingInSubscription < DESK_COUNT.WARNING_THRESHOLD;
  }

  get showDesksCount() {
    return !this.featureConfig.isEnabled('desks.unlimitedDesks');
  }

  get desksCount() {
    return this.workplaceSubscription?.isPremiumPlan
      ? this.assignableDesksQuantity
      : `${this.assignableDesksQuantity} / ${this.maximumDesksForCurrentPlan}`;
  }

  get assignableDesksQuantity() {
    if (this.featureFlags.isEnabled('locationBilling')) {
      return (
        this.state.activeDeskLocations.find((dl) => dl.id === this.state.currentLocation.id)?.assignableDesksQuantity ||
        0
      );
    }
    return this.state.activeDeskLocations.reduce((acc, deskLocation) => acc + deskLocation.assignableDesksQuantity, 0);
  }

  get overcrowded() {
    if (!this.featureConfig.isEnabled('desks') || this.unlimitedDesksEnabled) {
      return false;
    }

    return this.assignableDesksQuantity > this.maximumDesksForCurrentPlan;
  }

  get routeToWorkplace() {
    return this.featureFlags.isEnabled('workplace-garaje-launch');
  }

  get floors() {
    return this.model.floors.filter((floor) => {
      return get(floor.location, 'id') === this.state.currentLocation.id;
    });
  }

  @action
  addAmenity(amenity) {
    this.amenities.unshiftObject(amenity);
  }

  @action
  removeAmenity(toRemove) {
    this.amenities = this.amenities.filter((amenity) => amenity !== toRemove);
  }

  @action
  isDuplicateAmenityName(currentAmenity, name) {
    return this.amenities.some((amenity) => {
      return amenity.name === name.trim() && amenity.id !== currentAmenity?.id;
    });
  }

  @action
  purchaseDesksPlan() {
    this.showOvercrowdedModal = false;
    this.metrics.trackEvent('Clicked a button to upgrade plan from a lockout modal on desks index page');
    this.router.transitionTo('billing.product-plans', 'desks');
  }

  @action
  deactivateDesks() {
    this.showOvercrowdedModal = false;
    this.showDeactivateDesksModal = true;
  }

  @dropTask
  *updateFloorPositionTask(floors) {
    yield all(
      floors.map((floor, index) => {
        set(floor, 'floorNumber', index + 1);
        return floor.save();
      })
    );
  }

  @dropTask
  *fetchAmenitiesTask() {
    const amenities = yield this.store.query('amenity', {
      filter: {
        'location-id': this.state.currentLocation.id,
      },
      meta: 'desk-count',
    });
    this.amenities = amenities.toArray();
  }

  @dropTask
  *updateAmenityPositionTask(amenities) {
    yield all(
      amenities.map((amenity, index) => {
        set(amenity, 'position', index + 1);
        return amenity.save();
      })
    );
  }

  @dropTask
  showAmenityModalTask = {
    *perform() {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = async () => {
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };

  @dropTask
  *onDeactivateTask() {
    this.showDeactivateDesksModal = this.overcrowded;

    if (this.desksSubscription.onExpiredTrial && !this.overcrowded) {
      yield this.subscriptions.downgradeToBasicTask.perform(this.desksSubscription);
    }

    yield this.desksSubscription.reload();
  }
}
