import { hash } from 'rsvp';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class extends Route {
  @service transitionConfirm;
  @service store;

  model({ agreement_id }) {
    const { agreements, agreementPage, flow, tracking, signInFields } = this.modelFor(
      'location-overview.sign-in-flows.show.legal-documents'
    );

    const agreement = this.store.findRecord('global-agreement', agreement_id);

    return hash({
      agreements,
      agreement,
      agreementPage,
      flow,
      tracking,
      signInFields,
    });
  }
}
