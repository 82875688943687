import { action, notifyPropertyChange } from '@ember/object';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
// @ts-ignore
import { cached } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import config from 'garaje/config/environment';
import type AjaxService from 'garaje/services/ajax';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import type StatsigService from 'garaje/services/statsig';
import { RoleScopeType } from 'garaje/utils/custom-roles';
import urlBuilder from 'garaje/utils/url-builder';
import { tracked } from 'tracked-built-ins';
interface ShareKioskDemoArgs {
  onClose: () => void;
  demoName: string;
}

interface NewAdmin {
  name: string;
  email: string;
  exists: boolean;
}

interface EmployeeData {
  attributes: {
    email: string;
    name: string;
  };
  relationships: {
    company: { data: { type: string; id: string } };
    locations: { data: { type: string; id: string }[] };
    'role-assignments': {
      data: {
        type: string;
        attributes: { 'role-id': string; 'role-scope-id': number; 'role-scope-type': RoleScopeType };
      }[];
    };
  };
  type: string;
}

const PREVIEW_TEXT = 'Copy preview link';
const SHARE_PREVIEW_COPIED_TEXT = 'Link copied';
const LINK_IMG_SRC = '/assets/images/ipad-demo/icons/chain.svg';
const LINK_COPIED_IMG_SRC = '/assets/images/ipad-demo/icons/check.svg';

export default class ShareKioskDemo extends Component<ShareKioskDemoArgs> {
  @service declare state: StateService;
  @service declare statsig: StatsigService;
  @service declare store: StoreService;
  @service declare ajax: AjaxService;
  @service declare flashMessages: FlashMessagesService;

  @tracked copyPreviewLinkText: string = PREVIEW_TEXT;
  @tracked copyPreviewLinkImageSrc: string = LINK_IMG_SRC;
  @tracked teamMembersToInvite: Array<NewAdmin> = [{ name: '', email: '', exists: false }];

  @tracked emailErrors: { [key: number]: string } = {};
  @tracked nameErrors: { [key: number]: string } = {};

  @tracked shouldShowSubmitButton = false;
  @tracked showDeleteButtons = false;

  constructor(owner: unknown, args: ShareKioskDemoArgs) {
    super(owner, args);
    this.statsig.logEvent('kiosk-demo-share-modal-viewed', null, { demoName: this.args.demoName });
  }

  @cached
  get teamMembersCount(): number {
    return this.teamMembersToInvite.length;
  }

  get shareLink(): string {
    const { currentCompany } = this.state;
    return `${config.host}/ipad-demo?cid=${currentCompany?.id}`;
  }

  get hasAnyErrors(): boolean {
    return Boolean(Object.keys(this.emailErrors).length) || Boolean(Object.keys(this.nameErrors).length);
  }

  @action errorMessage(index: number, value: 'email' | 'name'): string {
    if (value === 'email') {
      return this.emailErrors[index] || '';
    } else {
      return this.nameErrors[index] || '';
    }
  }

  @action copyShareLink(): void {
    this.statsig.logEvent(`kiosk-demo-${this.args.demoName}-share-link-clicked`, null, { source: 'share-modal' });
    this.copyPreviewLinkText = SHARE_PREVIEW_COPIED_TEXT;
    this.copyPreviewLinkImageSrc = LINK_COPIED_IMG_SRC;

    setTimeout(() => {
      this.copyPreviewLinkText = PREVIEW_TEXT;
      this.copyPreviewLinkImageSrc = LINK_IMG_SRC;
    }, 2000);
  }

  @action handleEmailChange(idx: number, event: Event): void {
    const target = event.target as HTMLInputElement;
    const email = target.value;
    this.teamMembersToInvite[idx]!.email = email;
    this.checkShouldShowSubmit();
    if (this.emailErrors[idx]) {
      delete this.emailErrors[idx];
      notifyPropertyChange(this, 'emailErrors');
    }
    if (!email.length && this.nameErrors[idx] && this.nameErrors[idx] === "Full name can't be blank") {
      delete this.nameErrors[idx];
      notifyPropertyChange(this, 'nameErrors');
    }
    if (this.teamMembersToInvite[idx]!.exists) {
      const newTeamMember = { ...this.teamMembersToInvite[idx]!, exists: false };
      this.teamMembersToInvite = [
        ...this.teamMembersToInvite.slice(0, idx),
        newTeamMember,
        ...this.teamMembersToInvite.slice(idx + 1),
      ];
    }
  }

  @action handleNameChange(idx: number, event: Event): void {
    const target = event.target as HTMLInputElement;
    const name = target.value;
    this.teamMembersToInvite[idx]!.name = name;
    this.checkShouldShowSubmit();
    if (this.nameErrors[idx]) {
      delete this.nameErrors[idx];
      notifyPropertyChange(this, 'nameErrors');
    }
  }

  @action checkShouldShowSubmit(): void {
    if (this.teamMembersToInvite.some((member) => member.name && member.email)) {
      this.shouldShowSubmitButton = true;
    } else {
      this.shouldShowSubmitButton = false;
    }
  }

  isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  @action handleEmailFocusOut(idx: number): void {
    const currentMember = this.teamMembersToInvite[idx]!;
    if (!currentMember.email.length || this.isValidEmail(currentMember.email)) {
      if (
        (currentMember.email.length && !currentMember.exists) ||
        (!currentMember.email.length && currentMember.exists)
      ) {
        void this.findEmployeeTask.perform(idx);
      }
    } else {
      this.emailErrors[idx] = 'Enter a valid email address';
      notifyPropertyChange(this, 'emailErrors');
    }
  }

  @action handleNameFocusOut(idx: number): void {
    const currentMember = this.teamMembersToInvite[idx]!;
    if (currentMember.email.length && !currentMember.name.length) {
      this.nameErrors[idx] = "Full name can't be blank";
      notifyPropertyChange(this, 'nameErrors');
    }
  }

  @action handleAddMoreClicked(): void {
    this.teamMembersToInvite = [...this.teamMembersToInvite, { name: '', email: '', exists: false }];
    this.showDeleteButtons = true;
    this.statsig.logEvent('kiosk-demo-share-modal-add-another-clicked');
  }

  @action deleteRow(idx: number): void {
    this.teamMembersToInvite = this.teamMembersToInvite.filter((_, index) => index !== idx);
    if (this.teamMembersToInvite.length === 1) {
      this.showDeleteButtons = false;
    }
    this.checkShouldShowSubmit();
  }

  @action
  scrollToBottom(element: HTMLElement): void {
    requestAnimationFrame(() => {
      element.scrollTop = element.scrollHeight;
    });
  }

  @action
  focusOnEmail(element: HTMLElement): void {
    if (element) {
      (element as HTMLInputElement).focus();
    }
  }

  findEmployeeTask = task(async (idx: number) => {
    const emailToFind = this.teamMembersToInvite[idx]!.email;
    const employees = await this.store.query('employee', {
      filter: {
        deleted: false,
        email: emailToFind,
      },
    });

    const foundEmployee = employees.find((result) => result.email === emailToFind);
    if (foundEmployee) {
      const newTeamMember = { ...this.teamMembersToInvite[idx]!, name: foundEmployee.name, exists: true };
      this.teamMembersToInvite = [
        ...this.teamMembersToInvite.slice(0, idx),
        newTeamMember,
        ...this.teamMembersToInvite.slice(idx + 1),
      ];
      this.checkShouldShowSubmit();
      this.statsig.logEvent('kiosk-demo-share-modal-employee-found', null, {
        email: emailToFind,
        name: foundEmployee.name,
        id: foundEmployee.id,
      });
    }
  });

  submitTask = task({ drop: true }, async () => {
    const { currentCompany, currentLocation } = this.state;

    let existCount = 0;
    let rowCount = 0;
    const employeesData: EmployeeData[] = [];
    this.teamMembersToInvite.forEach((member) => {
      if (member.email.length && member.name.length) {
        if (member.exists) {
          existCount++;
        }
        rowCount++;
        employeesData.push({
          attributes: {
            email: member.email,
            name: member.name,
          },
          relationships: {
            company: {
              data: {
                type: 'companies',
                id: currentCompany.id,
              },
            },
            locations: {
              data: [
                {
                  type: 'locations',
                  id: currentLocation.id,
                },
              ],
            },
            'role-assignments': {
              data: [
                {
                  type: 'role-assignments',
                  attributes: {
                    'role-id': 'role_globalAdmin',
                    'role-scope-id': parseInt(currentCompany.id),
                    'role-scope-type': RoleScopeType.COMPANY,
                  },
                },
              ],
            },
          },
          type: 'employees',
        });
      }
    });

    this.statsig.logEvent('kiosk-demo-share-modal-submit-clicked', null, {
      demoName: this.args.demoName,
      rowCount,
      existCount,
    });

    const url = urlBuilder.v3.employees.bulkUrl();
    try {
      await this.ajax.request(url, {
        type: 'POST',
        headers: { accept: 'application/vnd.api+json' },
        contentType: 'application/vnd.api+json',
        data: JSON.stringify({
          data: employeesData,
        }),
      });
      this.flashMessages.showAndHideFlash('success', 'Invites sent', 'Admins will receive an email invitation');
      this.args.onClose();
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', 'Error', 'Something went wrong. Please try again');
      this.args.onClose();
    }
  });
}
