import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import updateAndSaveTask from 'garaje/utils/decorators/update-and-save-task';
import { and, or } from 'macro-decorators';
import { action, get } from '@ember/object';
import { task, lastValue, all, restartableTask, timeout } from 'ember-concurrency';
import { TEST_IDENTIFIER, VACCINE_IDENTIFIER } from 'garaje/models/user-document-template';
import { localCopy } from 'tracked-toolbox';
import zft from 'garaje/utils/zero-for-tests';

@updateAndSaveTask
class LocationOverviewSignInFlowsShowLegalDocumentsIndexController extends Controller {
  @service trial;
  @service metrics;
  @service('user-document') userDocumentService;
  @service store;

  @and('model.agreementPage.enabled', 'model.vrSubscription.includesNda') legalDocumentsEnabled;

  @lastValue('setupTrackingConfigurations') trackingConfigurations;
  @localCopy('trackingConfigurations.vaccineConfiguration') vaccineConfiguration;
  @localCopy('trackingConfigurations.testConfiguration') testConfiguration;
  @or('testConfiguration.active', 'vaccineConfiguration.active') trackingEnabled;

  @action
  trackFeatureToggled(isEnabled) {
    let eventName = 'Legal Docs - Legal Documents ';
    eventName += isEnabled ? 'Enabled' : 'Disabled';
    this.metrics.trackEvent(eventName, {
      company_id: this.model.tracking.currentCompanyId,
    });
  }

  @task
  *setupTrackingConfigurations() {
    if (!get(this.userDocumentService.loadTemplates, 'lastSuccessful.value')) {
      yield this.userDocumentService.loadTemplates.perform();
    }

    const [vaccineConfiguration, testConfiguration] = yield all([
      this.userDocumentService.getUserDocumentTemplateConfigurationForFlow(this.model.flow, VACCINE_IDENTIFIER),
      this.userDocumentService.getUserDocumentTemplateConfigurationForFlow(this.model.flow, TEST_IDENTIFIER),
    ]);

    return { vaccineConfiguration, testConfiguration };
  }

  @restartableTask
  *searchUsersTask(term) {
    yield timeout(zft(300));
    const locations = this.store.peekAll('location') ?? [];
    const re = new RegExp(`.*${term}.*`, 'i');
    const currentContactsIds = get(this.model.flow, 'visitorDocumentContacts').mapBy('id');
    const userRoles = yield this.store.query('user-role', {
      filter: {
        query: term,
        location: locations.mapBy('id').join(','),
        roles: ['Global Admin', 'Location Admin', 'Receptionist'].join(','),
      },
    });
    const users = yield all(userRoles.map((role) => get(role, 'user')));

    return users
      .uniqBy('id')
      .reject(({ id }) => currentContactsIds.includes(id))
      .filter(({ fullName }) => fullName.match(re));
  }

  @action
  _trackAddLegalDocStarted() {
    this.send('trackAddLegalDocStarted');
  }

  @action
  _trackEditLegalDocStarted() {
    this.send('trackEditLegalDocStarted');
  }
}

export default LocationOverviewSignInFlowsShowLegalDocumentsIndexController;
