import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';
import type AreaMapModel from 'garaje/models/area-map';
import adapter from 'garaje/utils/decorators/adapter';

import type DeskModel from './desk';

@adapter('maps')
class DraftModel extends Model {
  // Attributes
  @attr('string') declare name: string;
  @attr('number') declare createdBy: number;
  @attr('number') declare lastUpdatedBy: number;
  @attr('number') declare versionNumber: number;
  @attr('number') declare createdAt: number;
  @attr('number') declare publishedAt: number;

  // Relationships
  @belongsTo('area-map') declare originalAreaMap: AreaMapModel;
  @belongsTo('area-map') declare draftAreaMap: AreaMapModel;
  @hasMany('desk') declare desks: DeskModel;

  declare publish: ReturnType<typeof memberAction>;
}

DraftModel.prototype.publish = memberAction({
  type: 'PUT',
  path: 'publish',
});

export default DraftModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    draft: DraftModel;
  }
}
