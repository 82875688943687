import { service } from '@ember/service';
import Component from '@glimmer/component';
import type StateService from 'garaje/services/state';
import { alias } from 'macro-decorators';

export default class AssistantsDropdown extends Component<{
  allowClear: boolean;
  assistants: unknown[];
  changeset: unknown;
  isAssistantsLoaded: boolean;
  isEmployeeDeleted: boolean;
  searchEmployees: (params: unknown) => unknown;
  updateAssistants: (assistants: unknown) => unknown;
}> {
  @service declare state: StateService;
  @alias('state.vrSubscription') declare vrSubscription: StateService['vrSubscription'];
  @alias('state.workplaceSubscription') declare workplaceSubscription: StateService['workplaceSubscription'];

  get isDisabled(): boolean {
    if (this.args.isEmployeeDeleted) {
      return true;
    }

    if (this.vrSubscription?.canAccessAssistants) {
      return false;
    }

    return !(
      this.workplaceSubscription?.isPremiumPlan ||
      this.workplaceSubscription?.canAccessAssistants ||
      this.workplaceSubscription?.isEnterprisePlan
    );
  }
}
