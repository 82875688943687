import { service } from '@ember/service';
import Model, { type AsyncHasMany, attr, belongsTo, hasMany } from '@ember-data/model';
import type Store from '@ember-data/store';
import { type Snapshot } from '@ember-data/store';
import { memberAction } from 'ember-api-actions';
import embeddedBelongsTo from 'garaje/utils/embedded-belongs-to';
import { type SingleResponse } from 'jsonapi/response';
import { equal, gte } from 'macro-decorators';

import type ApplicationAdapter from '../adapters/application';
import type AjaxService from '../services/ajax';

import type ApprovalStatusModel from './approval-status';
import type TenantModel from './tenant';
import type ZoneModel from './zone';

export interface PrintRequest {
  /**
   * defaults to auto-print-setting
   */
  print?: boolean | 'auto-print-setting';
  'badge-id'?: string;
  'printer-id'?: string;
}

export interface CheckInOptions {
  'checked-in-at': Date | null;
  'print-requests'?: PrintRequest[];
}

class ConnectInviteModel extends Model {
  @service declare ajax: AjaxService;
  @service declare store: Store;

  @belongsTo('tenant', { async: false })
  declare tenant: TenantModel;

  @hasMany('zones')
  declare suites: AsyncHasMany<ZoneModel>;

  @attr('string')
  declare fullName: string;

  @attr('string')
  declare email: string;

  @attr('date')
  declare expectedArrivalTime: Date;

  @attr('string')
  declare groupName: string;

  @attr('string')
  declare hostName: string | null;

  @attr('string')
  declare inviterName: string | null;

  @attr('string')
  declare propertyNotes: string;

  @attr('date')
  declare checkedInAt: Date;

  @attr('string')
  declare photoUrl: string;

  @embeddedBelongsTo('approval-status')
  declare approvalStatus: ApprovalStatusModel;

  @gte('approvalStatus.failedReport.length', 1) didFailApprovalCheck!: number;
  @equal('approvalStatus.status', 'denied') approvalWasDenied!: boolean;
  @equal('approvalStatus.status', 'approved') approved!: boolean;
  @equal('approvalStatus.status', 'pending') pending!: boolean;
  @equal('approvalStatus.status', 'review') needsApprovalReview!: boolean;

  /**
   * Identifies if the invite is associated with a property flow, regular flow, or Protect flow.
   */
  @attr('string')
  declare visitorCategory: 'property' | 'visitor' | 'employee';

  /**
   * Unlike the plain `Invite` model, we can't make use of ember-api-actions to implement approveInvite/denyInvite.
   * This is because the `review` endpoint is only implemented on the V2 API, not V3, so we have to manually
   * build/make the request to the V2 endpoint. (ember-api-actions only adds the given `path` to the base URL for the
   * model, so we can't customize it sufficiently.)
   *
   */
  approveInvite(): Promise<void> {
    return this.#review('approve');
  }

  denyInvite(): Promise<void> {
    return this.#review('deny');
  }

  #review(action: 'approve' | 'deny'): Promise<void> {
    return this.ajax.request(this.#reviewUrl, {
      contentType: 'application/json',
      data: { action },
      type: 'POST',
    });
  }

  get suiteList(): string {
    return this.suites.mapBy('name').join(', ');
  }

  get #reviewUrl() {
    const adapter = <ApplicationAdapter>this.store.adapterFor('invite');
    const baseUrl = adapter.buildURL('invite', this.id, this._createSnapshot(), 'POST');
    return `${baseUrl}/review`;
  }

  get isPropertyInvite(): boolean {
    return this.visitorCategory === 'property';
  }

  declare checkIn: (option: CheckInOptions) => Promise<unknown>;

  // @todo how do create snapshots without using private functions?
  declare _createSnapshot: () => Snapshot<'connect-invite'>;
}

ConnectInviteModel.prototype.checkIn = memberAction({
  urlType: 'findRecord',
  type: 'POST',
  path: 'check-in?include=suites,tenant',
  after: function (response: SingleResponse<ConnectInviteModel>) {
    // Perform serialization/push manually because we need to use the ConnectInviteSerializer.
    // The API returns data with type "invites", which gets resolved as a regular
    // Invite model and not a ConnectInvite.
    const serializer = this.store.serializerFor('connect-invite');
    const normalized = serializer.normalizeSingleResponse(
      this.store,
      <Model>(<unknown>ConnectInviteModel),
      response,
      response.data.id,
      'findRecord'
    );

    return this.store.push(normalized);
  },
});

export default ConnectInviteModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'connect-invite': ConnectInviteModel;
  }
}
