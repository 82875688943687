import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { alias, equal, or } from 'macro-decorators';

export default class LocationOverviewSignInFlowsShowController extends Controller {
  @service state;
  @service router;

  @alias('state.vrSubscription') vrSubscription;
  @equal('router.currentRouteName', 'location-overview.sign-in-flows.show.legal-documents.new') onNewLegalDocumentRoute;
  @equal('router.currentRouteName', 'location-overview.sign-in-flows.show.legal-documents.edit')
  onEditLegalDocumentRoute;
  @or('onEditLegalDocumentRoute', 'onNewLegalDocumentRoute') onNewOrEditLegalDocumentRoute;
}
