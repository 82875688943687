/* eslint-disable ember/no-computed-properties-in-native-classes */
import { set } from '@ember/object';
import { equal, notEmpty } from '@ember/object/computed';
import Model, { type AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import type EmployeeModel from 'garaje/models/employee';
import type LocationModel from 'garaje/models/location';
import type UserModel from 'garaje/models/user';
import adapter from 'garaje/utils/decorators/adapter';
import displayRoleName from 'garaje/utils/decorators/display-role-name';
import { isCompanyRole } from 'garaje/utils/roles';

@displayRoleName
@adapter('v2')
class LocationRoleModel extends Model {
  @belongsTo('location') declare location: AsyncBelongsTo<LocationModel> | LocationModel;
  @belongsTo('user') declare user: AsyncBelongsTo<UserModel>;
  // This will return the employee record associated with the current user.

  @belongsTo('employee') declare employee: AsyncBelongsTo<EmployeeModel>;

  @attr('date') declare confirmedAt: Date;
  @attr('date') declare createdAt: Date;
  @attr('date') declare lastLogin: Date;
  @attr('date') declare lastSeenAt: Date;
  @attr('string') declare phoneNumber: string;
  @attr('string') declare roleName: string;
  @attr('boolean') declare manuallyAdded: boolean;

  @notEmpty('confirmedAt') isConfirmed!: boolean;
  @equal('roleName', 'Location Admin') isLocationAdmin!: boolean;

  updateRole(roleName: string, location: LocationModel): Promise<unknown> {
    if (isCompanyRole(roleName)) {
      return this.promoteToCompanyRole(roleName, location);
    } else {
      set(this, 'roleName', roleName);
      if (location) {
        void set(this, 'location', location);
      }
      return this.save().then(
        (model) => {
          return model;
        },
        (err) => {
          this.rollbackAttributes();
          throw err;
        }
      );
    }
  }

  promoteToCompanyRole(roleName: string, location: LocationModel): Promise<unknown> {
    return this.user
      .then((user) => {
        return user.setRole(roleName, location);
      })
      .then((member) => {
        return this.destroyRecord().catch((err) => {
          void member.destroyRecord();
          throw err;
        });
      });
  }
}

export default LocationRoleModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'location-role': LocationRoleModel;
  }
}
