import Route from '@ember/routing/route';
import { next } from '@ember/runloop';
import { hash } from 'rsvp';
import { service } from '@ember/service';
import urlBuilder from 'garaje/utils/url-builder';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class LocationOverviewEmployeesSyncSettingsRoute extends Route {
  @service abilities;
  @service ajax;
  @service state;
  @service integrationsManager;
  @service router;
  @service store;

  titleToken = 'Sync settings';

  beforeModel() {
    if (this.abilities.cannot('visit sync-settings')) {
      this.router.transitionTo('unauthorized');
    }
  }

  model() {
    const integrationsHash = {
      integrations: this.integrationsManager.loadIntegration(this.state.currentCompany.id),
    };

    return hash(integrationsHash);
  }

  afterModel(model) {
    const { currentCompany } = this.state;

    const directorySyncProvider = currentCompany.directorySyncProvider;
    if (!directorySyncProvider) {
      if (this.router.currentRouteName?.includes('location-overview')) {
        this.router.transitionTo('location-overview.employees.directory');
      } else {
        this.router.transitionTo('employees.directory');
      }
    } else {
      let url;

      if (currentCompany.oneLogin?.content) {
        url = urlBuilder.v2.integrations.oneLoginCustomAttributesUrl(currentCompany.oneLogin.id);

        model.filterOptions = this.ajax.request(url).then(
          function (response) {
            return response.data;
          },
          function () {
            return [];
          }
        );
      } else if (currentCompany.oktaIntegration?.content) {
        url = urlBuilder.v2.integrations.oktaCustomAttributesUrl(currentCompany.oktaIntegration.id);

        model.filterOptions = this.ajax.request(url).then(function (response) {
          return response.data;
        });

        const groupsUrl = urlBuilder.v2.integrations.oktaGroupsUrl(directorySyncProvider.id);

        model.directoryGroups = this.ajax.request(groupsUrl).then(
          function (response) {
            return response.data;
          },
          function () {
            return [];
          }
        );
      }
    }

    return hash(model);
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    controller.filterOptions = [];
    if (model.filterOptions) {
      controller.filterOptions = model.filterOptions._result;
    }

    controller.directoryGroups = [];
    if (model.directoryGroups) {
      controller.directoryGroups = model.directoryGroups._result;
    }
  }

  @routeEvent
  routeWillChange(transition) {
    let employeesFilter;
    const { currentCompany } = this.state;

    if (currentCompany.oneLogin?.content || currentCompany.oktaIntegration?.content) {
      employeesFilter = currentCompany.directorySyncProvider;
    } else {
      const employeesFilters = this.store.peekAll('employees-filter');
      employeesFilter = employeesFilters?.firstObject;
    }

    let rollback;
    let keepBubbling = true;

    if (employeesFilter && employeesFilter.hasDirtyAttributes) {
      rollback = window.confirm('You will lose any unsaved changes. Are you sure you want to continue?');

      if (rollback) {
        next(function () {
          employeesFilter.rollbackAttributes();
        });
      } else {
        transition.abort();
        keepBubbling = false;
      }
    }

    return keepBubbling;
  }
}
