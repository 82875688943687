import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type AnnouncementTemplateCategoryModel from 'garaje/models/announcement-template-category';

export default class CommunicationsSettingsRoute extends Route {
  @service declare store: Store;

  model(): ArrayProxy<AnnouncementTemplateCategoryModel> {
    return this.store.findAll('announcement-template-category');
  }
}
