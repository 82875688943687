import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type AbilitiesService from 'ember-can/services/abilities';
import type FeatureConfigService from 'garaje/services/feature-config';
import type StateService from 'garaje/services/state';

export default class RoombaWrapperRoute extends Route {
  title = 'Rooms · Envoy';
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare featureConfig: FeatureConfigService;

  beforeModel(): void {
    const { workplaceSubscription, realRoomsSubscription } = this.state;

    if (this.abilities.cannot('visit roomba')) {
      void this.router.transitionTo('unauthorized');
    } else if (!isPresent(realRoomsSubscription) && workplaceSubscription?.cancelled) {
      void this.router.transitionTo('workplace.trial-ended');
    } else if (!this.featureConfig.isEnabled('rooms.accessRoomsTab')) {
      void this.router.replaceWith('roomba.landing-page');
    }
  }
}
