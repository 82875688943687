import { A } from '@ember/array';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type GroupInviteModel from 'garaje/models/group-invite';
import type InviteModel from 'garaje/models/invite';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, ZONE_ADMIN } from 'garaje/utils/roles';
import { Permission } from 'garaje/utils/ui-permissions';
import _intersection from 'lodash/intersection';

const CAN_DELETE_WALKUP_ROLES = [GLOBAL_ADMIN, ZONE_ADMIN];

export default class InviteAbility extends Ability {
  @service declare authz: AuthzService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;

  declare model: InviteModel | GroupInviteModel | null;

  get canDelete(): boolean {
    const invite = this.model;
    if (invite) {
      if (this.#isInviteCreator || this.#isInviteHost || this.#isInviteAssistantOfHost) {
        return true;
      }
    }

    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_INVITE_DELETE);
  }

  get canEdit(): boolean {
    const invite = this.model;
    if (invite) {
      if (this.#isInviteCreator || this.#isInviteHost || this.#isInviteAssistantOfHost) {
        return true;
      }
    }

    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_INVITE_UPDATE);
  }

  // Determine if the current user may be able to review any Invite record at all
  get canReview(): boolean {
    const blocklistContacts = this.state.currentLocation?.blocklistContacts?.toArray() ?? [];
    const isSecurityContact = !!A(blocklistContacts).findBy('id', this.currentAdmin.id);

    return this.canViewAll || isSecurityContact;
  }

  get canDeleteConnectWalkup(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_DELETE_WALKUP_ROLES, roleNames));
  }

  get canViewAll(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_INVITE_READ);
  }

  get #isInviteAssistantOfHost(): boolean {
    // if not given an invite, don't bother checking invite-specific things
    if (!this.model) return false;
    if (!this.currentAdmin.employee) return false;

    const bossIds = this.currentAdmin.employee.hasMany('bosses').ids();

    return isPresent(_intersection(bossIds, this.#inviteHostIds));
  }

  get #isInviteCreator(): boolean {
    const invite = this.model;
    if (!invite || invite.isDestroying) return false;
    if (!this.currentAdmin.user) return false;

    const creatorId = invite.belongsTo('creator').id();
    const currentUserId = this.currentAdmin.user.id;

    return !!creatorId && creatorId === currentUserId;
  }

  get #isInviteHost(): boolean {
    const currentEmployeeId = this.currentAdmin.employee?.id;
    if (!currentEmployeeId) return false;

    return this.#inviteHostIds.includes(currentEmployeeId);
  }

  get #inviteHostIds(): string[] {
    const invite = this.model;
    if (!invite || invite.isDestroying) return [];

    const hostIds: string[] = [];
    const primaryHostId = invite.belongsTo('employee').id();
    if (primaryHostId) {
      hostIds.push(primaryHostId);
    }

    if (this.state.vrSubscription?.canAccessMultipleHosts) {
      // multiple hosts suppport is enabled; combine primary host & additional hosts
      hostIds.push(...invite.hasMany('additionalHosts').ids());
    }
    return hostIds;
  }
}
