import { A } from '@ember/array';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { isPresent as _isPresent } from '@ember/utils';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { all, task, restartableTask } from 'ember-concurrency';
import type UserModel from 'garaje/models/user';
import type CurrentLocationService from 'garaje/services/current-location';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type ImpressionsService from 'garaje/services/impressions';
import type StateService from 'garaje/services/state';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import macro from 'macro-decorators';

import type { DeviceAlertsRouteModel } from './route';

const isPresent = function (path: keyof DeviceAlertsController) {
  return macro(function (this: DeviceAlertsController) {
    return _isPresent(this[path]);
  });
};

export default class DeviceAlertsController extends Controller {
  declare model: DeviceAlertsRouteModel['currentLocation'];
  declare printerContacts: DeviceAlertsRouteModel['printerContacts'];

  @service declare state: StateService;
  @service declare store: StoreService;
  @service declare currentLocation: CurrentLocationService;
  @service declare flashMessages: FlashMessagesService;
  @service declare impressions: ImpressionsService;
  @service declare router: RouterService;

  @tracked deviceNotificationsHasChanges = false;

  @tracked printers: DeviceAlertsRouteModel['printers'] = [];
  @tracked ipads: DeviceAlertsRouteModel['ipads'] = [];

  @isPresent('ipads') hasDevices!: boolean;
  @isPresent('printers') hasPrinters!: boolean;

  get isVirtualFrontDesk(): boolean {
    const currentRoute = this.router.currentRouteName;
    return currentRoute.includes('virtual-front-desk');
  }

  @action
  onUpdateDeviceNotifications({ hasChanges }: { hasChanges: boolean }): void {
    this.deviceNotificationsHasChanges = hasChanges;
  }

  @action
  rollbackDeviceNotifications(): void {
    this.deviceNotificationsHasChanges = false;
    this.model.rollbackDeviceContacts();
  }

  searchUsersTask = restartableTask(async (term: string, selectedUsers: UserModel[]) => {
    const userRoles = await this.store.query('user-role', {
      filter: { name: term, location: this.currentLocation.location.id },
    });
    let users = await all(userRoles.map((role) => role.user));
    const selectedUserIds = selectedUsers.map((contact) => contact.id);
    // deduplicate since roles could point to same user
    // exclude selected users
    users = A(users)
      .uniqBy('id')
      .filter((user) => {
        return !selectedUserIds.includes(user.id);
      });
    return users;
  });

  togglePrinterNotificationsEnabledTask = task(async (enabled: boolean) => {
    const location = this.currentLocation.location;
    location.printerNotificationsEnabled = enabled;
    try {
      await location.save();
      const status = enabled ? 'enabled' : 'disabled';
      this.flashMessages.showAndHideFlash('success', `Printer notifications ${status}`);
      await this.impressions.postImpression.perform(
        IMPRESSION_NAMES.VR_NOTIFICATIONS_DEVICE_PRINTER_STATUS_EMAIL[
          <keyof typeof IMPRESSION_NAMES.VR_NOTIFICATIONS_DEVICE_PRINTER_STATUS_EMAIL>status.toUpperCase()
        ]
      );
    } catch (e) {
      const status = enabled ? 'enabling' : 'disabling';
      this.flashMessages.showAndHideFlash(
        'error',
        `There was an issue ${status} Printer notifications`,
        parseErrorForDisplay(e)
      );
    }
  });

  savePrinterContactsTask = task(async (users: UserModel[]) => {
    const location = this.currentLocation.location;
    location.printerContacts = users;
    try {
      await location.save();
      void this.impressions.postImpression.perform(IMPRESSION_NAMES.PRINTER_ALERTS_CONFIG_UPDATED);
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'There was an issue saving contact(s)', parseErrorForDisplay(e));
    }
  });
}
