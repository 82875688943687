import { set } from '@ember/object';
import Service, { service } from '@ember/service';
import type { AsyncBelongsTo } from '@ember-data/model';
import type CompanyModel from 'garaje/models/company';
import type EmployeesFilterModel from 'garaje/models/employees-filter';
import type GoogleAppModel from 'garaje/models/google-app';
import type { FilterType } from 'garaje/models/google-app';
import type LocationModel from 'garaje/models/location';
import type AjaxService from 'garaje/services/ajax';
import type IntegrationsManagerService from 'garaje/services/integrations-manager';
import type StateService from 'garaje/services/state';
import type StoreService from 'garaje/services/store';
import urlBuilder from 'garaje/utils/url-builder';
import moment from 'moment-timezone';
import { hash } from 'rsvp';

interface GoogleAppUser {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

interface GoogleAppUserPayloadItem {
  'first-name': string;
  'last-name': string;
  phones: { value: string }[];
}

interface GoogleAppUserPayload {
  data: GoogleAppUserPayloadItem[];
}

interface LocationFilterResponse {
  location?: string;
  locationId: string;
  results: unknown[];
}

type MergedUser = GoogleAppUser & GoogleAppUserPayloadItem;

export default class GoggleSyncManagerService extends Service {
  @service declare integrationsManager: IntegrationsManagerService;
  @service declare store: StoreService;
  @service declare state: StateService;
  @service declare ajax: AjaxService;

  loadFilter(googleApp: GoogleAppModel | AsyncBelongsTo<GoogleAppModel>): EmployeesFilterModel {
    const _googleApp = 'content' in googleApp ? googleApp.content! : googleApp;

    const filter = {
      attributes: _googleApp.filters,
      id: _googleApp.id,
      type: 'employees-filter',
    };

    const data: GoogleAppModel['filters'] & { locations?: unknown; query?: unknown } = filter.attributes;

    switch (data.type) {
      case 'locations':
        data.locations = data.data;
        break;
      case 'company':
        data.query = data.data?.['query'];
        break;
    }

    const employeeFilter = this.store.peekAll('employees-filter').firstObject;

    if (employeeFilter) {
      // Reset model so it doesn't show as dirty.
      employeeFilter.rollbackAttributes();
    }

    return <EmployeesFilterModel>this.store.push({ data: filter });
  }

  sync(): Promise<unknown> {
    const companyId = this.state.currentCompany.id;

    return this.ajax
      .request(urlBuilder.v3.employees.synchronizeUrl(companyId), {
        type: 'POST',
        headers: { Accept: 'application/vnd.api+json' },
        data: {},
      })
      .then((response: unknown) => {
        const payload = {
          data: {
            id: companyId,
            type: 'company',
            attributes: {
              employeesSyncState: 'synchronizing',
              employeesLastSyncAt: moment().toISOString(),
            },
          },
        };

        this.store.push(payload);

        return response;
      });
  }

  setFilter(
    company: CompanyModel | AsyncBelongsTo<CompanyModel>,
    filter: FilterType,
    data: Record<string, unknown> = {}
  ): Promise<GoogleAppModel> {
    const _company = 'content' in company ? company.content! : company;
    const googleApp = _company.googleApp.content!;

    set(googleApp.filters, 'type', filter);
    set(googleApp.filters, 'data', data);

    return this.integrationsManager.saveGoogleApp(googleApp).then((googleApp) => {
      this.loadFilter(googleApp);

      return googleApp;
    });
  }

  companyFilter(googleApp: GoogleAppModel | AsyncBelongsTo<GoogleAppModel>, query: string): Promise<MergedUser[]> {
    const _googleApp = 'content' in googleApp ? googleApp.content! : googleApp;
    const payload = { data: { query } };
    return this.getUsers(_googleApp.id, payload);
  }

  locationsFilter(
    googleApp: GoogleAppModel | AsyncBelongsTo<GoogleAppModel>,
    _locations: LocationModel[],
    filters: { locationId: string }[]
  ): Promise<LocationFilterResponse[]> {
    const _googleApp = 'content' in googleApp ? googleApp.content! : googleApp;
    const promises: Record<string, Promise<unknown[]>> = {};
    const store = this.store;

    const resultObjects = filters.map((filter) => {
      const location = store.peekRecord('location', filter.locationId);
      const payload = { data: filter };

      promises[filter.locationId] = this.getUsers(_googleApp.id, payload);

      return {
        location: location?.name,
        locationId: filter.locationId,
        results: <unknown[]>[],
      };
    });

    return hash(promises).then(function (queries) {
      resultObjects.forEach(function (obj) {
        obj.results = queries[obj.locationId]!;
      });

      return resultObjects;
    });
  }

  getUsers(id: string, payload: Record<string, unknown>): Promise<MergedUser[]> {
    return this.ajax
      .request(urlBuilder.v2.integrations.googleAppUsersUrl(id), { data: payload, contentType: 'application/json' })
      .then((response: GoogleAppUserPayload) => {
        const users = <MergedUser[]>response.data;

        users.forEach(function (user) {
          user.firstName = user['first-name'];
          user.lastName = user['last-name'];
          user.phoneNumber = user.phones.map((p) => p.value).join(', ');
        });

        return users;
      });
  }
}
