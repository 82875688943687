/* eslint-disable ember/use-ember-get-and-set */
import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import lookupValidator from 'ember-changeset-validations';
import { dropTask, type TaskForAsyncTaskFunction } from 'ember-concurrency';
import type AnnouncementTemplateModel from 'garaje/models/announcement-template';
import type AnnouncementTemplateCategoryModel from 'garaje/models/announcement-template-category';
import type LocationModel from 'garaje/models/location';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import { ANNOUNCEMENT_TEMPLATE_EMPLOYEE_AUDIENCES, ANNOUNCEMENT_TEMPLATE_VISITOR_AUDIENCES } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import announcementTemplateCategoryValidations from 'garaje/validations/announcement-template-category';
import compact from 'lodash/compact';
import xorBy from 'lodash/xorBy';
import { defer } from 'rsvp';

type SelectOptionType = {
  label: string;
  value: string;
};

type AnnouncementTemplateFormArgsType = {
  changeset: DetailedChangeset<AnnouncementTemplateModel>;
  saveTask: TaskForAsyncTaskFunction<unknown, () => Promise<void>>;
  deleteTask?: TaskForAsyncTaskFunction<unknown, () => Promise<void>>;
};

export default class AnnouncementTemplateForm extends Component<AnnouncementTemplateFormArgsType> {
  @service declare store: Store;
  @service declare state: StateService;
  @service declare flashMessages: FlashMessagesService;
  @service declare currentAdmin: CurrentAdminService;

  @tracked visitorAudienceOptions: SelectOptionType[] = ANNOUNCEMENT_TEMPLATE_VISITOR_AUDIENCES as SelectOptionType[];
  @tracked employeeAudienceOptions: SelectOptionType[] = ANNOUNCEMENT_TEMPLATE_EMPLOYEE_AUDIENCES as SelectOptionType[];
  @tracked shouldShowCategoryCreateModal: boolean = false;
  @tracked categoryChangeset!: DetailedChangeset<AnnouncementTemplateCategoryModel>;
  @tracked groups = [];

  // Returns true if deleting or saving
  get disableActions(): boolean {
    const { changeset, saveTask, deleteTask } = this.args;
    const noLocations = !changeset.locations.length;

    return saveTask.isRunning || deleteTask?.isRunning || noLocations;
  }

  get availableLocations(): SkinnyLocationModel[] {
    const skinnyLocations = this.store.peekAll('skinny-location').toArray();

    if (this.currentAdmin.isGlobalAdmin) {
      return skinnyLocations;
    }

    return skinnyLocations.filter((l) => this.authorizedLocationIds.includes(l.id));
  }

  get messageVariables(): string[] | undefined {
    return ['time', 'date', 'location_name'].map((variable) => `%{${variable}}`);
  }

  get selectedChannels(): SelectOptionType[] {
    const selectedChannels = this.args.changeset.defaultChannels;
    return this.channelOptions.filter((option) => selectedChannels.includes(option.value));
  }

  get selectedLocationIds(): string[] {
    if (!this.args.changeset.data.isDeleted) {
      const selectedLocations = this.args.changeset.locations;
      const selectedLocationIds = selectedLocations.map((l) => l.id);

      return selectedLocationIds;
    }
    return [];
  }

  get selectedLocationIdsString(): string {
    return this.selectedLocationIds.toString();
  }

  get channelOptions(): SelectOptionType[] {
    return this.attachIsEqual([
      {
        label: 'SMS',
        value: 'sms',
      },
      {
        label: 'Email',
        value: 'email',
      },
      {
        label: 'Push notification',
        value: 'push',
      },
    ]);
  }

  get categoryOptions(): AnnouncementTemplateCategoryModel[] {
    return this.store.peekAll('announcement-template-category').sortBy('name');
  }

  get employeeAudienceMultiSelected(): SelectOptionType[] {
    const employeeAudience = this.args.changeset.defaultEmployeeAudiences;
    return this.employeeAudienceOptions.filter((option) => employeeAudience.includes(option.value));
  }

  get employeeAudienceSelected(): SelectOptionType | undefined {
    const employeeAudience = this.args.changeset.defaultEmployeeAudiences[0];
    return this.employeeAudienceOptions.find((option) => option.value === employeeAudience);
  }

  get visitorAudienceMultiSelected(): SelectOptionType[] {
    const visitorAudience = this.args.changeset.defaultVisitorAudiences;
    return this.visitorAudienceOptions.filter((option) => visitorAudience?.includes(option.value));
  }

  get visitorAudienceSelected(): SelectOptionType | undefined {
    const visitorAudience = this.args.changeset.defaultVisitorAudiences[0];
    return this.visitorAudienceOptions.find((option) => option.value === visitorAudience);
  }

  get authorizedLocationIds(): string[] {
    const userLocations = this.state.currentUser.locationRoles.toArray();
    return userLocations.filter((l) => l.isLocationAdmin).map((l) => l.belongsTo('location').id());
  }

  // Checks if the user has access to edit this template
  get canEdit(): boolean {
    const isNew = this.args.changeset.isNew as unknown as boolean;

    if (isNew || this.currentAdmin.isGlobalAdmin) {
      return true;
    }

    const authorizedLocationIds = this.authorizedLocationIds;
    const locations = this.args.changeset.locations.toArray();

    return locations.every((l) => authorizedLocationIds.includes(l.id));
  }

  // Used for Ember Power Select to determine the equality of objects
  attachIsEqual(options: SelectOptionType[]): SelectOptionType[] {
    return options.map((option) => ({
      ...option,
      isEqual(other: SelectOptionType) {
        return this.value === other.value;
      },
    }));
  }

  channelSelected(channels: string[], channel: SelectOptionType): boolean {
    return channels.includes(channel.value);
  }

  @action
  updateDefaultChannels(defaultChannels: SelectOptionType[]): void {
    const selectedChannels = defaultChannels.map((c) => c.value);
    this.args.changeset.set('defaultChannels', selectedChannels);
  }

  @action
  updateDefaultEmployeeAudiences(defaultEmployeeAudiences?: SelectOptionType): void {
    const selected = defaultEmployeeAudiences ? [defaultEmployeeAudiences.value] : [];
    this.args.changeset.set('defaultEmployeeAudiences', selected);
  }

  @action
  updateDefaultEmployeeAudiencesMultiSelect(defaultEmployeeAudiences?: SelectOptionType[]): void {
    const selected = defaultEmployeeAudiences ? defaultEmployeeAudiences.map((audience) => audience.value) : [];
    this.args.changeset.set('defaultEmployeeAudiences', selected);
  }

  @action
  updateDefaultVisitorAudiences(defaultVisitorAudiences?: SelectOptionType): void {
    const selected = defaultVisitorAudiences ? [defaultVisitorAudiences.value] : [];
    this.args.changeset.set('defaultVisitorAudiences', selected);
  }

  @action
  updateDefaultVisitorAudiencesMultiSelect(defaultVisitorAudiences?: SelectOptionType[]): void {
    const selected = defaultVisitorAudiences ? defaultVisitorAudiences.map((audience) => audience.value) : [];
    this.args.changeset.set('defaultVisitorAudiences', selected);
  }

  @action
  updateMessage(message: string): void {
    this.args.changeset.set('message', message);
  }

  @action
  updateMarkAsSafe(e: Event): void {
    const target = e.target as HTMLInputElement;
    this.args.changeset.set('markAsSafe', target.checked || false);
  }

  @action
  async updateLocations(selectedSkinnyLocations: SkinnyLocationModel[]): Promise<void> {
    const selectedLocations = await Promise.all(
      selectedSkinnyLocations.map(async (skinnyLocation) => {
        return (await skinnyLocation.realLocation()) as LocationModel;
      })
    );

    const currentSelectedLocations = this.args.changeset.locations.toArray();
    const locations = compact(xorBy(selectedLocations, currentSelectedLocations, 'id'));
    this.args.changeset.set('locations', locations);
    this.args.changeset.set(
      'locationNames',
      locations.map((l) => l.name)
    );
  }

  @action
  updateCategory(category: AnnouncementTemplateCategoryModel): void {
    this.args.changeset.set('announcementTemplateCategory', category);
  }

  @action
  showCategoryCreateModal(): void {
    this.shouldShowCategoryCreateModal = true;
  }

  @action
  closeCategoryCreateModal(): void {
    this.shouldShowCategoryCreateModal = false;
  }

  @dropTask
  showCreateCategoryModalTask = {
    *perform(this: {
      context: AnnouncementTemplateForm;
      abort?: () => void;
      continue?: () => Promise<void>;
    }): Generator<Promise<boolean>, void, void> {
      const deferred = defer<boolean>();

      const announcementTemplateCategory = this.context.store.createRecord('announcement-template-category', {
        name: '',
        company: this.context.state.currentCompany,
      });

      const validations = announcementTemplateCategoryValidations;

      this.context.categoryChangeset = Changeset(
        announcementTemplateCategory,
        lookupValidator(validations),
        validations
      );

      this.abort = () => {
        this.context.categoryChangeset.rollback();
        void announcementTemplateCategory.unloadRecord();
        deferred.resolve(false);
      };

      this.continue = async () => {
        try {
          await this.context.categoryChangeset.validate();

          if (this.context.categoryChangeset.isInvalid) {
            return;
          }

          await this.context.categoryChangeset.save();

          this.context.args.changeset.set('announcementTemplateCategory', this.context.categoryChangeset._content);

          this.context.flashMessages.showAndHideFlash('success', 'Saved!');

          deferred.resolve(true);
        } catch (e) {
          this.context.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
        }
      };

      return yield deferred.promise;
    },
  };
}
