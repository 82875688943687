import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { get, action } from '@ember/object';
import { task } from 'ember-concurrency';
import { later } from '@ember/runloop';
import { resolve } from 'rsvp';
import { reads, equal, or } from 'macro-decorators';

import { CATEGORIES_LIST } from 'garaje/utils/integration-categories';

export default class IntegrationsIndexController extends Controller {
  @service state;
  @service featureFlags;
  @service integrationsManager;
  @service flashMessages;
  @service store;
  @service router;

  queryParams = ['scrollTo'];
  @tracked scrollTo = null; // null or a dom id, used to scroll to an element when the page loads

  @reads('state.currentLocation') currentLocation;
  @reads('state.currentCompany') currentCompany;
  @reads('currentCompany.saml.content') samlIntegration;
  @reads('currentCompany.googleApp.content') googleIntegration;
  @reads('currentCompany.oneLogin.content') oneLoginIntegration;
  @reads('currentCompany.oktaIntegration.content') oktaIntegration;
  @equal('currentCompany.scimIntegration.provider', 'onelogin') oneLoginSCIM;
  @equal('currentCompany.scimIntegration.provider', 'okta') oktaSCIM;
  @equal('currentCompany.scimIntegration.provider', 'azure') entraIdSCIM;
  @reads('currentCompany.scimIntegration.content') scimIntegration;
  @or(
    'model.vrSubscription.canAccessDirectorySync',
    'model.deliveriesSubscription.canAccessDirectorySync',
    'model.workplaceSubscription.canAccessDirectorySync'
  )
  canAccessDirectorySync;
  @or(
    'model.vrSubscription.canAccessSaml',
    'model.deliveriesSubscription.canAccessSaml',
    'model.workplaceSubscription.canAccessSaml'
  )
  canAccessSaml;

  get hasScimIntegration() {
    return this.featureFlags.isEnabled('scimIntegration', {
      created_at: get(this.currentCompany, 'createdAt'),
    });
  }

  get activeSyncPluginInstalls() {
    // find plugins in the sync category
    const syncPlugins = get(this.model, 'plugins').filterBy('category', 'sync');
    const pluginInstalls = get(this.model, 'pluginInstalls');

    // find active installs that belong to any plugin in the sync category
    const syncPluginInstalls = pluginInstalls.filter((install) => {
      return syncPlugins.findBy('id', get(install, 'plugin.id')) !== undefined;
    });

    return syncPluginInstalls.filter(function (install) {
      const status = get(install, 'status');
      return status === 'active' || status === 'pending';
    });
  }

  get currentSyncIntegration() {
    let currentIntegration = false;
    const activeSyncPluginInstalls = this.activeSyncPluginInstalls;

    if (this.googleIntegration) {
      currentIntegration = 'Google';
    }

    if (this.oneLoginIntegration || this.oneLoginSCIM) {
      currentIntegration = 'OneLogin';
    }

    if (this.oktaIntegration || this.oktaSCIM) {
      currentIntegration = 'Okta';
    }

    if (this.entraIdSCIM) {
      currentIntegration = 'Entra ID';
    }

    // This would say e.g. 'OKTA SCIM' if you installed the SCIM
    // version and then lost priviliges for SCIM.
    const featureFlags = this.featureFlags;
    if (!get(featureFlags, 'scimIntegration')) {
      if (this.scimIntegration) {
        currentIntegration += ' SCIM';
      }
    }

    if (activeSyncPluginInstalls.length) {
      currentIntegration = get(activeSyncPluginInstalls, 'firstObject.plugin.name');
    }

    return currentIntegration;
  }

  get hasSync() {
    return this.currentSyncIntegration !== false;
  }

  get categories() {
    return CATEGORIES_LIST.filter(
      ({ id, required }) => required || this.model.plugins.find(({ category }) => category === id)
    );
  }

  scrollToIntegration(selector) {
    later(function () {
      document.querySelector(selector).scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 200);
  }

  @task
  *installGoogleAppsTask() {
    const { currentCompany } = this.state;
    const integrationsManager = this.integrationsManager;
    // Clean any existing error this.store.unloadAll('google-app');
    this.store.unloadAll('google-app');

    yield integrationsManager
      .connectGoogleApps(currentCompany)
      .then(() => {
        currentCompany.reload();
        this.transitionToRoute('employees.sync-settings');
      })
      .catch((response) => {
        let message = "Sorry, we couldn't connect your account.";
        const [error] = response.errors;

        if (error) {
          message = error.messages.join(', ');
        }

        this.flashMessages.showAndHideFlash('error', message);
      });
  }

  @action
  goToPluginInstallConfig(integration) {
    this.router.transitionTo('integrations.enabled', { queryParams: { integration } });
  }

  @task
  *installOneLoginTask() {
    const { currentCompany } = this.state;
    this.store.createRecord('one-login', { company: currentCompany });
    yield this.transitionToRoute('integrations.enabled', { queryParams: { integration: 'one-login' } }).then(() => {
      this.scrollToIntegration('#one-login-config');
    });
  }

  @task
  *installOktaTask() {
    const { currentCompany } = this.state;
    this.store.createRecord('okta-integration', { company: currentCompany });
    yield this.transitionToRoute('integrations.enabled', { queryParams: { integration: 'okta' } }).then(() => {
      this.scrollToIntegration('#okta-config');
    });
  }

  @task
  *installScimTask(provider) {
    const { currentCompany } = this.state;
    this.store.createRecord('scim-integration', { company: currentCompany, provider });

    yield this.transitionToRoute('integrations.enabled', { queryParams: { integration: 'scim' } }).then(() => {
      this.scrollToIntegration('#scim-config');
    });
  }

  @task
  *installSamlTask() {
    const { currentCompany } = this.state;
    this.store.createRecord('saml', { company: currentCompany });
    yield this.transitionToRoute('integrations.enabled', { queryParams: { integration: 'saml' } }).then(() => {
      this.scrollToIntegration('.integrations-saml');
    });
  }

  @task
  *installWebhookTask() {
    const { currentLocation } = this.state;
    this.store.createRecord('webhook', { location: currentLocation });
    yield this.transitionToRoute('integrations.enabled', { queryParams: { integration: 'webhook' } }).then(() => {
      this.scrollToIntegration('#webhook-config');
    });
  }

  @task
  *installZapierTask() {
    const { currentCompany } = this.state;
    const integrationsManager = this.integrationsManager;
    return yield integrationsManager.connectZapier(currentCompany).catch(() => {
      let promise;

      // Don't reload zapier if is already in memory.
      if (get(currentCompany, 'zapierIntegration.content')) {
        promise = resolve({});
      } else {
        promise = integrationsManager.loadZapier();
      }

      return promise.then(() => {
        this.transitionToRoute('integrations.enabled', {
          queryParams: {
            integration: 'zapier',
          },
        });
      });
    });
  }

  @task
  *installIftttTask() {
    const { currentCompany } = this.state;
    const integrationsManager = this.integrationsManager;
    return yield integrationsManager.connectIfttt(currentCompany).catch(() => {
      let promise;

      if (get(currentCompany, 'iftttIntegration.content')) {
        promise = resolve({});
      } else {
        promise = integrationsManager.loadIfttt();
      }

      return promise.then(() => {
        this.transitionToRoute('integrations.enabled', { queryParams: { integration: 'ifttt' } });
      });
    });
  }
}
