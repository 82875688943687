import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { all, task, dropTaskGroup } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { RECURRING_OPTIONS } from 'garaje/pods/components/invites/select-recurring-invites/component';

/**
 * @param {Promise} callback
 * @param {Function} close
 * @param {Array<Invite>} invites
 * @param {String} action
 * @param {String} prompt
 */

export default class SendNotificationModalComponent extends Component {
  @service ajax;
  @service flashMessages;

  @dropTaskGroup saveTaskGroup;

  @tracked customMessage = '';

  get isShowing() {
    return isPresent(this.args.callback);
  }

  @task({ group: 'saveTaskGroup' })
  *sendNotificationTask() {
    try {
      yield this.args.callback(this.requestPayload);
      yield this.sendNotifications();
    } catch (e) {
      this.flashMessages.showFlash('error', parseErrorForDisplay(e));
      throw e;
    } finally {
      this.args.close?.();
    }
  }

  @task({ group: 'saveTaskGroup' })
  *doCallbackTask() {
    yield this.args.callback();
    this.args.close?.();
  }

  async sendNotifications() {
    let { invites } = this.args;

    if (this.args.scope && RECURRING_OPTIONS.THIS !== this.args.scope) {
      invites = await all(invites.mapBy('recurringInvite'));
    }

    return all(
      invites
        // Invite must have an email address or API will throw a 400 error
        .filter((invite) => isPresent(invite?.email))
        .map((invite) => invite.notifyInvite(this.requestPayload))
    );
  }

  get requestPayload() {
    return {
      data: {
        'custom-message': this.customMessage,
        action: this.args.action,
      },
    };
  }
}
