import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';

class CsvUploadModel extends Model {
  @belongsTo('csv-uploadable', { polymorphic: true }) declare csvUploadable: AsyncBelongsTo<Model>;

  @attr('string') declare destination: string;

  declare uploadStatus: ReturnType<typeof memberAction>;
}

CsvUploadModel.prototype.uploadStatus = memberAction({
  path: 'upload-status',
  type: 'get',
});

export default CsvUploadModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'csv-upload': CsvUploadModel;
  }
}
