/* eslint-disable ember/use-ember-get-and-set */
/* eslint-disable ember/no-get */
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Changeset } from 'ember-changeset';
import { type DetailedChangeset } from 'ember-changeset/types';
import lookupValidator from 'ember-changeset-validations';
import { validateInclusion } from 'ember-changeset-validations/validators';
import { task } from 'ember-concurrency';
import type { Task } from 'ember-concurrency';
import type ConfigModel from 'garaje/models/config';
import type GlobalIdScanPageModel from 'garaje/models/global-id-scan-page';
import type IdScanPageModel from 'garaje/models/id-scan-page';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { or, reads } from 'macro-decorators';

const IdScanValidations = {
  camera: validateInclusion({ list: ['front', 'back'], allowBlank: false }),
};

interface FlowsSectionsSecurityIdScanningComponentArgs {
  /**
   * ID Scanning feature activated
   */
  idScanningEnabled: boolean;
  /**
   * ID Scan Page model/record to display, modify (REQUIRED)
   */
  idScanPage: IdScanPageModel | GlobalIdScanPageModel;
  /**
   * Task to perform when enabling ID Scanning Page
   */
  updateAndSaveTask?: Task<void, [IdScanPageModel | GlobalIdScanPageModel, string, boolean]>;
  readOnly?: boolean;
  onChange?: (idScanPage?: DetailedChangeset<IdScanPageModel | GlobalIdScanPageModel>) => void;
  config: ConfigModel;
}

interface CtaState {
  text: string;
  isDisabled: boolean;
}

export default class FlowsSectionsSecurityIdScanningComponent extends Component<FlowsSectionsSecurityIdScanningComponentArgs> {
  @service declare state: StateService;
  @service declare metrics: MetricsService;
  @service declare flashMessages: FlashMessagesService;

  @reads('state.vrSubscription') vrSubscription!: StateService['vrSubscription'];
  @or('saveTask.isRunning', 'disableTask.isRunning', 'args.updateAndSaveTask.isRunning') isSaving!: boolean;

  @tracked isPanelOpen = false;
  @tracked changeset;

  constructor(owner: unknown, args: FlowsSectionsSecurityIdScanningComponentArgs) {
    super(owner, args);

    if (this.args.idScanPage) {
      this.changeset = Changeset(this.args.idScanPage, lookupValidator(IdScanValidations), IdScanValidations);
    }
  }

  get idRescanMonthOptions(): number[] {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  }

  get isOpen(): boolean {
    const {
      isPanelOpen,
      args: { idScanningEnabled, idScanPage },
    } = this;

    if (!isPanelOpen) return false;
    if (!idScanningEnabled) return false;
    if (!idScanPage?.enabled && this.disableTask.isIdle) return false;

    return true;
  }

  get ctaState(): CtaState | null {
    const {
      changeset,
      disableTask,
      isPanelOpen,
      saveTask,
      args: { idScanPage, updateAndSaveTask },
    } = this;

    if (!idScanPage) return null;
    if (disableTask.isRunning) return { text: 'Disabling', isDisabled: true };
    if (saveTask.isRunning) return { text: 'Saving', isDisabled: true };
    if (updateAndSaveTask?.isRunning) return { text: 'Enabling', isDisabled: true };

    if (idScanPage.enabled) {
      if (isPanelOpen) {
        return { text: 'Save', isDisabled: !changeset?.isDirty };
      } else if (this.args.readOnly) {
        return { text: 'View', isDisabled: false };
      }
      return { text: 'Edit', isDisabled: false };
    }

    return { text: 'Enable', isDisabled: !!this.args.readOnly };
  }

  get isGlobalFlow(): boolean {
    return get(this.args.idScanPage, 'isGlobal');
  }

  get isRescanEditingDisabled(): boolean {
    // disable if saving or a read only scenario
    if (this.isSaving || this.args.readOnly) return true;

    // always allow editing for global flows
    if (this.isGlobalFlow) return false;

    // for location flows ensure beenHereBefore is enabled
    return !get(this.args.config, 'beenHereBefore');
  }

  get effectiveRescanAfter(): string | null {
    if (this.isRescanEditingDisabled) {
      return null;
    } else {
      return this.changeset?.rescanAfter ?? null;
    }
  }

  flashError(message = 'Something went wrong, please try again.'): void {
    this.flashMessages.showAndHideFlash('error', message);
  }

  @action
  selectNumberOfMonths(number: number): void {
    this.changeset?.set('rescanAfterValue', number);
  }

  @action
  onUpgradeIdScanning(plan: string): void {
    this.metrics.trackEvent('Visitor Type - ID Scanning Upgrade Clicked', { plan });
  }

  @action
  handleCTAButton(): void {
    const {
      changeset,
      args: { idScanPage, updateAndSaveTask },
    } = this;

    if (!idScanPage) {
      throw new Error('No ID Scanning settings record specified');
    }

    if (idScanPage && !idScanPage.enabled) {
      void updateAndSaveTask?.perform(idScanPage, 'enabled', true);
    }

    if (this.isPanelOpen && changeset) {
      void this.saveTask.perform(changeset);
    }

    this.isPanelOpen = true;
  }

  @action
  update(event: Event): void {
    if (!this.changeset) return;
    const element = <HTMLInputElement>event.target;
    const name: string = element.name.slice(element.name.lastIndexOf('-') + 1);
    const selectedOption: string | null = element.value === 'everytime' ? null : element.value;
    this.changeset.set(name, selectedOption);
    this.args.onChange?.(this.changeset);
  }

  @action
  rollback(): void {
    this.changeset?.rollback();
  }

  saveTask = task({ drop: true }, async (changeset: DetailedChangeset<IdScanPageModel | GlobalIdScanPageModel>) => {
    const { isNew } = changeset;

    await changeset.validate();

    if (get(changeset, 'isInvalid')) return;

    try {
      await changeset.save();
      this.flashMessages.showAndHideFlash(
        'success',
        <boolean>(<unknown>isNew) ? 'Property successfully created!' : 'Saved!'
      );
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      this.flashError();
    }
  });

  disableTask = task({ drop: true }, async () => {
    const { idScanPage, updateAndSaveTask } = this.args;

    if (!idScanPage) {
      throw new Error('No ID Scanning settings record specified');
    }

    try {
      await updateAndSaveTask?.perform(idScanPage, 'enabled', false);

      this.rollback();
      this.isPanelOpen = false;
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      this.flashError();
    }
  });
}
