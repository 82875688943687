import { Select, SelectOption } from '@envoy/polarwind-react';
import { ReactNode } from 'react';
import { FieldValues, Path, useController } from 'react-hook-form';

export interface FormSelectProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  options: SelectOption[];
  required?: string;
  disabled?: boolean;
  preselectValue?: boolean;
  placeholder?: string;
  emptyNode?: ReactNode;
}

export function FormSelect<T extends FieldValues>(props: FormSelectProps<T>) {
  const {
    field,
    fieldState: { error },
  } = useController({ name: props.name, rules: { required: props.required } });

  return (
    <Select
      label={props.label}
      required={!!props.required}
      disabled={props.disabled}
      options={props.options}
      onChange={(value: string) => field.onChange(value)}
      value={field.value}
      error={error?.message}
      preselectValue={props.preselectValue}
      placeholder={props.placeholder}
      emptyNode={props.emptyNode}
    />
  );
}
