import Component from '@glimmer/component';
import { service } from '@ember/service';
import { and, alias, not } from 'macro-decorators';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { isArray } from '@ember/array';
import { set } from '@ember/object';
import { dropTask } from 'ember-concurrency';

/**
 * @param {object} flow flow model to render
 * @param {object[]} enabledFlows array of enabled flows models
 */
export default class FlowCardComponent extends Component {
  @service abilities;
  @service currentAdmin;
  @service featureFlags;
  @service flashMessages;
  @service('flow') employeeScreeningFlowService;

  @alias('employeeScreeningFlowService.activeEmployeeScreeningFlow.name') employeeScreeningFlowName;

  @and('notGlobalAdmin', 'currentAdmin.isLocationAdmin') isOnlyLocationAdmin;
  @not('currentAdmin.isGlobalAdmin') notGlobalAdmin;

  get canEditLocalFlow() {
    // If the split is not enabled then users without edit access will not be on this page
    return this.featureFlags.isEnabled('custom-roles-garaje-authz-vis3-flows')
      ? this.abilities.can('edit visitor-types')
      : true;
  }

  get canEditGlobalFlow() {
    return this.featureFlags.isEnabled('custom-roles-garaje-authz-vis3-flows')
      ? this.abilities.can('edit global-flows')
      : !this.isOnlyLocationAdmin;
  }

  /**
   * @param {object} flow flow to toggle
   */
  @dropTask
  *toggleEnabledTask(flow) {
    set(flow, 'enabled', !flow.enabled);
    try {
      yield flow.save({ propagable: true });
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      set(flow, 'enabled', !flow.enabled);
      let title = 'Server error. Please try again.';
      let body = '';

      if (e.isAdapterError) {
        title = parseErrorForDisplay(e);
      }

      if (isArray(e.errors) && e.errors.length === 1) {
        const error = e.errors[0];
        if (error.source && error.source.pointer === '/data/attributes/name') {
          title = error.title;
          body = error.detail;
        }
      }

      this.flashMessages.showFlash('error', title, body);
    }
  }

  get locationSettingsConnectRoute() {
    return 'manage.location-settings.index.connect';
  }
}
