import type EmberArray from '@ember/array';
import { A } from '@ember/array';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { parse, subDays } from 'date-fns';
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import type { DetailedChangeset } from 'ember-changeset/types';
import { all, task, dropTask, timeout, type Task, type TaskInstance } from 'ember-concurrency';
import { pluralize } from 'ember-inflector';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type ConfigModel from 'garaje/models/config';
import type EmployeeModel from 'garaje/models/employee';
import type FlowModel from 'garaje/models/flow';
import type GroupInviteModel from 'garaje/models/group-invite';
import type InviteModel from 'garaje/models/invite';
import type LocationModel from 'garaje/models/location';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import type SubscriptionModel from 'garaje/models/subscription';
import type { InvitesSpreadsheetOutputArgs } from 'garaje/pods/components/invites/spreadsheet-input/component';
import type CurrentLocationService from 'garaje/services/current-location';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type LocationFeatureFlagsService from 'garaje/services/location-feature-flags';
import { DATE_FNS_YYYY_MM_DD } from 'garaje/utils/date-fns-tz-utilities';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import zft from 'garaje/utils/zero-for-tests';
import type Handsontable from 'handsontable';
import { filterBy, reads, sortBy } from 'macro-decorators';
import Papa from 'papaparse';
import { defer } from 'rsvp';
import { localCopy } from 'tracked-toolbox';

const DEFAULT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
const BODY_CLASSES = Object.freeze(['overflow-hidden']);

interface onLocationSelectedTaskOutput {
  config: ConfigModel;
  flows: FlowModel[];
  location: LocationModel;
}

interface InvitesSpreadsheetGroupArgs {
  changeset: DetailedChangeset<GroupInviteModel>;
  enableInviteSelection?: boolean;
  flows: FlowModel[];
  handsontable: Handsontable;
  isEditInvite?: boolean;
  locationIsConnectedToProperty?: boolean;
  locations: LocationModel[];
  model: GroupInviteModel;
  onDateChanged?: (date: Date) => void;
  onFlowChanged?: (flow: FlowModel, changeset: DetailedChangeset<GroupInviteModel>) => void;
  onLocationSelectedTask: Task<onLocationSelectedTaskOutput, [location: LocationModel]>;
  onSave?: (date: Date | null, model?: GroupInviteModel) => void;
  vrSubscription: SubscriptionModel;
}

export default class InvitesSpreadsheetGroupComponent extends Component<InvitesSpreadsheetGroupArgs> {
  @service declare currentLocation: CurrentLocationService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare locationFeatureFlags: LocationFeatureFlagsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare store: StoreService;

  timeFormat = 'h:mm aaa';

  @tracked hotInstance?: Handsontable;
  @tracked isSpreadsheetVisible: boolean = false;
  @tracked loadingLocation: LocationModel | null = null;
  @tracked spreadsheetData: InvitesSpreadsheetOutputArgs | null = null;

  @tracked selectedInvites: InviteModel[] = [];
  @tracked invitesCount = 0;
  @tracked limit = 50;
  @tracked page = 1;
  @tracked sort = '';

  @localCopy('args.flows', A()) flows!: EmberArray<FlowModel>;
  @reads('args.changeset') changeset!: DetailedChangeset<GroupInviteModel>;
  @reads('args.model') model!: GroupInviteModel;
  @reads('model.location') location!: LocationModel;
  @reads('currentLocation.location.timezone', DEFAULT_TIMEZONE) defaultTimezone!: string;
  @reads('changeset.location.timezone', DEFAULT_TIMEZONE) timezone!: string;
  @reads('changeset.expectedArrivalTime') expectedArrivalTime!: Date | null;
  @reads('changeset.isNew') isNew!: boolean;
  @reads('spreadsheetData.validRows.length', 0) validRowCount!: number;
  @reads('spreadsheetData.filledRows.length', 0) totalRowCount!: number;
  @reads('changeset.flow.signInFieldPage.signInFields') signInFields!: SignInFieldModel[];
  @sortBy('signInFields', 'position') sortedSignInFields!: SignInFieldModel[];
  @filterBy('loadedInvites', 'hasDirtyAttributes') modifiedInvites!: InviteModel[];
  @filterBy('loadedInvites', 'isDeleted') deletedInvites!: InviteModel[];

  get flowOptions(): EmberArray<FlowModel> {
    return this.flows.rejectBy('isProtect').sortBy('position');
  }

  get currentlySelectedLocation(): LocationModel {
    const { args, loadingLocation, location } = this;
    const loading = loadingLocation ?? location;

    return args.onLocationSelectedTask?.isRunning ? loading : location;
  }

  get isForToday(): boolean {
    const { timezone, expectedArrivalTime } = this;

    if (!expectedArrivalTime) return true;

    const arrivalDay = formatInTimeZone(expectedArrivalTime, timezone, DATE_FNS_YYYY_MM_DD);
    const today = formatInTimeZone(new Date(), timezone, DATE_FNS_YYYY_MM_DD);

    return arrivalDay === today;
  }

  get fullDateString(): string {
    const { timezone, expectedArrivalTime } = this;

    if (!expectedArrivalTime) return '';

    return formatInTimeZone(expectedArrivalTime, timezone, "EEEE, MMM d 'at' h:mm aaa");
  }

  get minArrivalDate(): Date {
    const { timezone, defaultTimezone } = this;

    if (timezone === defaultTimezone) return new Date();

    // Adjustment for timezone (current location vs selected location)
    return utcToZonedTime(zonedTimeToUtc(new Date(), defaultTimezone), timezone);
  }

  get minArrivalTime(): string {
    const { timezone, timeFormat, expectedArrivalTime, isNew, isForToday } = this;
    const now = formatInTimeZone(new Date(), timezone, timeFormat);

    if (!expectedArrivalTime) return now;

    return isNew && isForToday ? now : '';
  }

  get selectedDate(): Date {
    const { timezone, defaultTimezone, expectedArrivalTime } = this;
    const date = expectedArrivalTime || new Date();
    const locationDay = formatInTimeZone(date, timezone, DATE_FNS_YYYY_MM_DD);
    const defaultTzDay = formatInTimeZone(date, defaultTimezone, DATE_FNS_YYYY_MM_DD);

    // Quirk in date picker component + Moment.js!
    // Adjust the day if the current location and selected location
    // are in different calendar days based on their respective timezones
    if (locationDay > defaultTzDay) return subDays(date, -1);
    if (locationDay < defaultTzDay) return subDays(date, 1);

    return date;
  }

  get selectedTime(): string {
    const { timezone, timeFormat, expectedArrivalTime } = this;

    if (!expectedArrivalTime) return '';

    return formatInTimeZone(expectedArrivalTime, timezone, timeFormat);
  }

  get isSubmitDisabled(): boolean {
    const {
      changeset: { isInvalid, isPristine },
      submitTask: { isRunning },
      validRowCount,
      isNew,
      modifiedInvites,
      deletedInvites,
    } = this;

    if (isRunning) return true;
    if (isInvalid) return true;
    if (deletedInvites.length > 0) return false;
    if (modifiedInvites.length > 0) return false;
    if (isPristine) return true;
    if (isNew && validRowCount === 0) return true;

    return false;
  }

  get submitButtonText(): string {
    const {
      isNew,
      changeset: { flow },
      submitTask: { isRunning },
    } = this;

    if (isNew) {
      if (isRunning) return 'Creating invites...';
      if (flow?.inviteApprovalsEnabled) return 'Submit invites for Approval';

      return 'Create Invites';
    }

    return isRunning ? 'Saving...' : 'Save';
  }

  get hostField(): SignInFieldModel | null {
    return A(this.signInFields).findBy('identifier', 'host') ?? null;
  }

  get emailSignInField(): SignInFieldModel | null {
    return A(this.signInFields).findBy('isEmail') ?? null;
  }

  get isEmailRequired(): boolean {
    if (
      !(
        this.featureFlags.isEnabled('visitors-required-email-field') ||
        this.locationFeatureFlags.isEnabled('visitors-required-email-field-by-location')
      )
    ) {
      return false;
    }

    return Boolean(this.emailSignInField?.required);
  }

  get validRowsWithEmail(): unknown[][] {
    const csvHeaders = this.spreadsheetData?.csvHeaders ?? [];
    const validRows = A(this.spreadsheetData?.validRows ?? []);
    const emailIdx = csvHeaders.indexOf('invitee_email');

    return validRows.filter((row: unknown[]): boolean => Boolean(row[emailIdx]));
  }

  get pageIndicator(): string {
    const { limit, page, invitesCount } = this;
    const start = (page - 1) * limit + 1;
    const end = Math.min(invitesCount, page * limit);

    return `${start}–${end} of ${invitesCount}`;
  }

  get isPreviousPage(): boolean {
    return this.page > 1;
  }

  get isNextPage(): boolean {
    return this.page < this.maximumPage;
  }

  get maximumPage(): number {
    return Math.ceil(this.invitesCount / this.limit);
  }

  get isFocusDisabledOnSpreadsheet(): boolean {
    if (this.closeAndDiscardSpreadsheetTask.isRunning) return true;
    if (!this.isSpreadsheetVisible) return true;

    return false;
  }

  get loadedInvites(): InviteModel[] {
    const { changeset, store } = this;

    if (!changeset.id) return [];

    return store.peekAll('invite').filter((i) => i.groupInviteId && i.groupInviteId === changeset.id);
  }

  get isSelectionRestorable(): boolean {
    return this.selectedInvites.some((i: InviteModel): boolean => Boolean(i.isDeleted));
  }

  get isSelectionDeletable(): boolean {
    return this.selectedInvites.some((i: InviteModel): boolean => !i.isDeleted);
  }

  get changeSummary(): string {
    const { changeset, validRowCount, deletedInvites } = this;
    const summary = [];
    const changes = changeset?.changes ?? [];

    if (changes.find((c): boolean => c['key'] !== 'csv')) {
      summary.push('updating group details');
    }

    if (validRowCount > 0) {
      summary.push(`adding ${pluralize(validRowCount, 'visitor')}`);
    }

    if (deletedInvites.length > 0) {
      summary.push(`removing ${pluralize(deletedInvites.length, 'visitor')}`);
    }

    if (summary.length <= 1) return summary.join();
    if (summary.length === 2) return summary.join(' and ');

    const last = summary.pop();

    return `${summary.join(', ')}, and ${last}`;
  }

  get invalidRowCount(): number {
    return this.totalRowCount - this.validRowCount;
  }

  #setDate(date: Date): void {
    this.changeset.expectedArrivalTime = date;
    this.args.onDateChanged?.(date);
  }

  #disableBodyScrolling(): void {
    // Prevent scrolling of main page content
    document.body.classList.add(...BODY_CLASSES);
  }

  #enableBodyScrolling(): void {
    // Enable scrolling of main page content
    document.body.classList.remove(...BODY_CLASSES);
  }

  #deleteInvites(invites: InviteModel[]): void {
    A(invites).invoke('deleteRecord');
  }

  #restoreInvites(invites: InviteModel[]): void {
    A(invites).invoke('rollbackAttributes');
  }

  #resetSpreadsheet(): void {
    this.changeset.csv = '';
    this.hotInstance?.clear();
    this.spreadsheetData = null;
    this.isSpreadsheetVisible = false;
  }

  @action
  showSpreadsheetModal(): void {
    this.isSpreadsheetVisible = true;
    this.#disableBodyScrolling();
  }

  @action
  hideSpreadsheetModal(): void {
    this.#enableBodyScrolling();
    this.isSpreadsheetVisible = false;
  }

  @action
  setDate(date: Date): void {
    const { timezone, defaultTimezone } = this;
    const locationDay = formatInTimeZone(date, timezone, DATE_FNS_YYYY_MM_DD);
    const defaultTzDay = formatInTimeZone(date, defaultTimezone, DATE_FNS_YYYY_MM_DD);
    let correctedDate = date;

    // Quirk in date picker component + Moment.js!
    // Adjust the day if the current location and selected location
    // are in different calendar days based on their respective timezones
    if (locationDay > defaultTzDay) correctedDate = subDays(date, 1);
    if (locationDay < defaultTzDay) correctedDate = subDays(date, -1);

    return this.#setDate(correctedDate);
  }

  @action
  didSelectTime(newTime: string): void {
    const { timezone, timeFormat, expectedArrivalTime } = this;
    const zonedDate = utcToZonedTime(expectedArrivalTime || new Date(), timezone);
    const newDate = parse(newTime, timeFormat, zonedDate);
    const date = zonedTimeToUtc(newDate, timezone);

    this.#setDate(date);
  }

  @action
  updateFlow(flow: FlowModel): void {
    const { changeset } = this;

    changeset.flow = flow;

    this.args.onFlowChanged?.(flow, changeset);
  }

  @action
  updateHost(_: unknown, employee: EmployeeModel | null): void {
    this.changeset.employee = employee;
  }

  @action
  updateAdditionalHosts(additionalHosts: EmployeeModel[]): void {
    // @ts-ignore
    this.changeset.additionalHosts = additionalHosts;
  }

  @action
  searchEmployees(term: string): TaskInstance<EmberArray<EmployeeModel>> | null {
    const { currentlySelectedLocation, searchEmployeesTask } = this;
    const extraFilters = { locations: currentlySelectedLocation.id };

    return searchEmployeesTask ? searchEmployeesTask.perform(term, extraFilters) : null;
  }

  @action
  updateSpreadsheetData(spreadsheetData: InvitesSpreadsheetOutputArgs): void {
    const { csvHeaders, validRows } = spreadsheetData;

    this.spreadsheetData = spreadsheetData;

    if (validRows.length > 0) {
      this.changeset.csv = Papa.unparse([csvHeaders, ...validRows]);
    } else {
      this.changeset.rollbackProperty('csv');
    }
  }

  @action
  prepareToSubmit(): void {
    void this.submitTask.perform();
  }

  // Cleanup scrolling prevention class on <body>
  @action
  handleWillDestroy(): void {
    this.#enableBodyScrolling();
  }

  @action
  deleteInvite(invite: InviteModel): void {
    this.#deleteInvites([invite]);
  }

  @action
  bulkDeleteInvites(invites: InviteModel[]): void {
    this.#deleteInvites(invites);
    this.selectedInvites = [];
  }

  @action
  restoreInvite(invite: InviteModel): void {
    this.#restoreInvites([invite]);
  }

  @action
  bulkRestoreInvites(invites: InviteModel[]): void {
    this.#restoreInvites(invites);
    this.selectedInvites = [];
  }

  @action
  gotoPreviousPage(): void {
    this.page = Math.max(this.page - 1, 1);

    void this.loadInvitesTask.perform();
  }

  @action
  gotoNextPage(): void {
    this.page = Math.min(this.page + 1, this.maximumPage);

    void this.loadInvitesTask.perform();
  }

  // @ts-ignore
  @employeesSearcherTask({
    filter: { deleted: false },
    prefix: true,
  }).restartable()
  searchEmployeesTask:
    | Task<EmberArray<EmployeeModel>, [term: string, extraFilters: { [key: string]: unknown } | null]>
    | undefined;

  closeAndDiscardSpreadsheetTask = task({ drop: true }, async (): Promise<void> => {
    if (this.validRowCount === 0) {
      this.isSpreadsheetVisible = false;

      return;
    }

    // eslint-disable-next-line @typescript-eslint/await-thenable -- encapsulated tasks do not have great TS support
    const isConfirmed = await this.confirmDiscardSpreadsheetTask.perform();

    if (!isConfirmed) return;

    this.#resetSpreadsheet();
  });

  submitTask = task({ drop: true }, async (): Promise<void> => {
    const {
      model,
      changeset,
      validRowsWithEmail,
      args: { isEditInvite, onSave },
      modifiedInvites,
      deletedInvites,
      flashMessages,
    } = this;

    await changeset.validate();

    if (changeset.isInvalid) return;

    const didAddVisitorsWithEmail = !changeset.notifyVisitor && validRowsWithEmail.length > 0;
    const didArrivalTimeChange =
      isEditInvite && changeset.changes.find((c): boolean => c['key'] === 'expectedArrivalTime');
    const didDeleteVisitorsWithEmail = isEditInvite && deletedInvites.some((i) => isPresent(i?.email));

    let confirmedNotifyUpdate: unknown = false;
    let updatePayload: unknown = undefined;
    let confirmedNotifyCancel: unknown = false;
    let cancellationPayload: unknown = undefined;

    if (didAddVisitorsWithEmail) {
      // eslint-disable-next-line @typescript-eslint/await-thenable -- encapsulated tasks do not have great TS support
      const notifyVisitor = await this.confirmPreregistrationEmailTask.perform();

      changeset.notifyVisitor = Boolean(notifyVisitor);
    }

    if (didArrivalTimeChange) {
      // eslint-disable-next-line @typescript-eslint/await-thenable -- encapsulated tasks do not have great TS support
      [confirmedNotifyUpdate, updatePayload] = await this.confirmGroupInviteUpdateNotification.perform();

      if (!confirmedNotifyUpdate) return;
    }

    if (didDeleteVisitorsWithEmail) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/await-thenable -- encapsulated tasks do not have great TS support
      [confirmedNotifyCancel, cancellationPayload] = await this.confirmInviteDeletionTask.perform();

      if (!confirmedNotifyCancel) return;
    }

    try {
      // Save the Group Invite record as needed
      if (changeset.isDirty) await changeset.save();

      // Commit any modifications, deletions among associated invites
      await all(A([...modifiedInvites, ...deletedInvites]).invoke('save'));

      // If sending a custom message to deleted recipients, trigger notify
      if (cancellationPayload) {
        await all(
          deletedInvites
            .filter((i) => isPresent(i?.email))
            .map((i) => i.notifyInvite({ ...(cancellationPayload ?? {}) }))
        );
      }

      // If sending a custom message about arrival time change, trigger notify
      if (updatePayload) {
        await model.notifyGroupInvite({ ...updatePayload });
      }

      onSave?.(changeset.expectedArrivalTime, changeset.data);

      flashMessages.showAndHideFlash('success', `Saved "${changeset.groupName}"`);

      this.page = 1;

      // Can't clear the spreadsheet while this task is running
      void this.resetSpreadsheetTask.perform();

      void this.loadInvitesTask.perform();
    } catch (err) {
      const error = parseErrorForDisplay(err) || 'Unknown error. Saving failed.';

      flashMessages.showFlash('error', error);
    }
  });

  resetSpreadsheetTask = task({ drop: true }, async (): Promise<void> => {
    // This task waits for submitTask to complete before proceeding
    while (this.submitTask.isRunning) await timeout(zft(100));

    this.#resetSpreadsheet();
  });

  @dropTask
  confirmPreregistrationEmailTask: {
    perform(): Generator<Promise<unknown>, unknown, unknown>;
  } = {
    *perform(this: { context: InvitesSpreadsheetGroupComponent; abort?: () => void; continue?: () => void }) {
      const deferred = defer();

      this.abort = () => deferred.resolve(false);
      this.continue = () => deferred.resolve(true);

      return yield deferred.promise;
    },
  };

  loadInvitesTask = task({ restartable: true }, async (): Promise<PaginatedRecordArray<InviteModel>> => {
    // Slow down for rapid page switching
    if (this.invitesCount > 0) await timeout(zft(200));

    const { limit, page, sort, model, location } = this;
    const offset = (page - 1) * limit;
    const include: string[] = [];
    const filter = { bulk_invite: model.id, location: location.id };
    const params: { [key: string]: unknown } = {
      filter,
      page: { offset, limit },
      include: include.join(),
    };

    if (sort) params['sort'] = sort;

    const invites = <PaginatedRecordArray<InviteModel>>await this.store.query('invite', params);

    this.invitesCount = invites.meta.total;

    return invites;
  });

  @dropTask
  confirmDiscardSpreadsheetTask: {
    perform(): Generator<Promise<unknown>, unknown, unknown>;
  } = {
    *perform(this: { context: InvitesSpreadsheetGroupComponent; abort?: () => void; continue?: () => void }) {
      const deferred = defer();

      this.abort = () => deferred.resolve(false);
      this.continue = () => deferred.resolve(true);

      return yield deferred.promise;
    },
  };

  @dropTask
  confirmInviteDeletionTask: {
    perform(): Generator<Promise<unknown>, unknown, unknown>;
  } = {
    *perform(this: {
      context: InvitesSpreadsheetGroupComponent;
      abort?: () => void;
      continue?: (payload: unknown) => void;
    }) {
      const deferred = defer();

      this.abort = () => deferred.resolve([false]);
      this.continue = (payload?: unknown) => deferred.resolve([true, payload]);

      return yield deferred.promise;
    },
  };

  @dropTask
  confirmGroupInviteUpdateNotification: {
    perform(): Generator<Promise<unknown>, unknown, unknown>;
  } = {
    *perform(this: {
      context: InvitesSpreadsheetGroupComponent;
      abort?: () => void;
      continue?: (payload: unknown) => void;
    }) {
      const deferred = defer();

      this.abort = () => deferred.resolve([false]);
      this.continue = (payload?: unknown) => deferred.resolve([true, payload]);

      return yield deferred.promise;
    },
  };
}
