import Model, { attr, hasMany } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('billing-v1')
class InvoiceEstimate extends Model {
  @hasMany('discount', { async: false }) discounts;
  @hasMany('tax', { async: false }) taxes;
  @hasMany('estimate-line-item', { async: false }) lineItems;

  // NEXT INVOICE ESTIMATE for LBB
  @attr('number') total;
  // Not applicable to LBB
  @attr('number') creditsApplied;
  // CURRENT INVOICE ESTIMATE for LBB
  @attr('number') amountDue;
  @attr('string') period;
  @attr('number') discountAmount;
}

export default InvoiceEstimate;
