import Controller from '@ember/controller';
import { get, set } from '@ember/object';
import { dropTask, restartableTask } from 'ember-concurrency';
import { reject } from 'rsvp';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import LocationValidations from 'garaje/validations/location';

export default class VisitorsSettingsPreRegistrationUpgradeController extends Controller {
  @service flashMessages;
  @service addressLookup;

  @tracked changeset;

  _buildChangeset(model) {
    const validator = lookupValidator(LocationValidations);
    this.changeset = new Changeset(model, validator, LocationValidations);
  }

  get addressIsEmpty() {
    const locationHasAddress = get(this.model, 'address');
    const addressHasBeenAdded = get(this.changeset, 'isDirty') && get(this.changeset, 'isValid');
    return !locationHasAddress && !addressHasBeenAdded;
  }

  @dropTask
  *enablePreRegistrationTask() {
    try {
      const changeset = this.changeset;
      set(changeset, 'preRegistrationEnabled', true);
      yield changeset.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.transitionToRoute('visitors.settings.invites');
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', error);
    }
  }

  @restartableTask
  *validateAndUpdateAddress() {
    const changeset = this.changeset;
    const addressLookup = this.addressLookup;

    try {
      // eslint-disable-next-line ember/no-get
      yield addressLookup.updateAddress(get(changeset, 'address'), changeset);
    } catch (error) {
      changeset.addError('address', error);
      yield reject(error);
    }
  }
}
