export enum PermissionType {
  COMPANY = 'companyPermissions',
  LOCATION = 'locationPermissions',
  ZONE = 'zonePermissions',
}

export type Permissions = {
  [PermissionType.COMPANY]: PermissionSets;
  [PermissionType.LOCATION]: PermissionSets;
  [PermissionType.ZONE]: PermissionSets;
};

export type PermissionSets = {
  [id: string]: Array<Permission>;
};

export enum Permission {
  ACCOUNT_MANAGEMENT_BILLING_SETTINGS_MANAGE = 'account_management.billing_settings.manage',
  ACCOUNT_MANAGEMENT_LOCATION_READ = 'account_management.location.read',
  ACCOUNT_MANAGEMENT_LOCATION_UPDATE = 'account_management.location.update',
  ACCOUNT_MANAGEMENT_DISABLED_LOCATION_READ = 'account_management.disabled_location.read',

  ACCOUNT_MANAGEMENT_BILLING_WORKPLACE_TRIAL_CREATE = 'account_management.billing_workplace_trial.create',

  ACCOUNT_MANAGEMENT_COMPANY_CONFIG_READ = 'account_management.company_configuration.read',
  ACCOUNT_MANAGEMENT_COMPANY_CONFIG_UPDATE = 'account_management.company_configuration.update',

  ANALYTICS_LOCAL_OCCUPANCY_DASH_READ = 'analytics.local_occupancy_dash.read',
  ANALYTICS_LOCAL_OCCUPANCY_DASH_DETAIL_READ = 'analytics.local_occupancy_dash.detail_read',
  ANALYTICS_LOCAL_OCCUPANCY_DASH_EXPORT = 'analytics.local_occupancy_dash.export',
  ANALYTICS_GLOBAL_OCCUPANCY_DASH_READ = 'analytics.global_occupancy_dash.read',
  ANALYTICS_GLOBAL_OCCUPANCY_DASH_DETAIL_READ = 'analytics.global_occupancy_dash.detail_read',
  ANALYTICS_GLOBAL_OCCUPANCY_DASH_EXPORT = 'analytics.global_occupancy_dash.export',
  ANALYTICS_GLOBAL_OCCUPANCY_SETTINGS_READ = 'analytics.global_occupancy_settings.read',
  ANALYTICS_GLOBAL_OCCUPANCY_SETTINGS_UPDATE = 'analytics.global_occupancy_settings.update',

  ANALYTICS_LOCAL_DESKS_DASH_READ = 'analytics.local_desks_dash.read',
  ANALYTICS_LOCAL_DESKS_DASH_EXPORT = 'analytics.local_desks_dash.export',
  ANALYTICS_GLOBAL_DESKS_DASH_READ = 'analytics.global_desks_dash.read',
  ANALYTICS_GLOBAL_DESKS_DASH_EXPORT = 'analytics.global_desks_dash.export',

  ANALYTICS_LOCAL_VISITORS_DASH_READ = 'analytics.local_visitors_dash.read',
  ANALYTICS_GLOBAL_VISITORS_DASH_READ = 'analytics.global_visitors_dash.read',

  ANALYTICS_LOCAL_ROOMS_DASH_READ = 'analytics.local_rooms_dash.read',
  ANALYTICS_LOCAL_ROOMS_DASH_EXPORT = 'analytics.local_rooms_dash.export',
  ANALYTICS_GLOBAL_ROOMS_DASH_READ = 'analytics.global_rooms_dash.read',
  ANALYTICS_GLOBAL_ROOMS_DASH_EXPORT = 'analytics.global_rooms_dash.export',

  ANALYTICS_LOCAL_DELIVERIES_DASH_READ = 'analytics.local_deliveries_dash.read',
  ANALYTICS_LOCAL_DELIVERIES_DASH_EXPORT = 'analytics.local_deliveries_dash.export',
  ANALYTICS_GLOBAL_DELIVERIES_DASH_READ = 'analytics.global_deliveries_dash.read',
  ANALYTICS_GLOBAL_DELIVERIES_DASH_EXPORT = 'analytics.global_deliveries_dash.export',

  ANALYTICS_LOCAL_PROTECT_DASH_READ = 'analytics.local_protect_dash.read',
  ANALYTICS_LOCAL_PROTECT_DASH_EXPORT = 'analytics.local_protect_dash.export',
  ANALYTICS_GLOBAL_PROTECT_DASH_READ = 'analytics.global_protect_dash.read',
  ANALYTICS_GLOBAL_PROTECT_DASH_EXPORT = 'analytics.global_protect_dash.export',

  ANALYTICS_OFFICE_ATTENDANCE_READ = 'analytics.office_attendance.read',

  ANALYTICS_MULTITENANCY_DASH_READ = 'analytics.multitenancy_dash.read',
  ANALYTICS_MULTITENANCY_DASH_EXPORT = 'analytics.multitenancy_dash.export',

  MAPS_LIVE_READ = 'maps.live_map.read',
  MAPS_LIVE_UPDATE = 'maps.live_map.update',
  MAPS_LIVE_CREATE = 'maps.live_map.create',
  MAPS_LIVE_DELETE = 'maps.live_map.delete',

  MAPS_FLOOR_READ = 'maps.floor.read',
  MAPS_FLOOR_UPDATE = 'maps.floor.update',
  MAPS_FLOOR_CREATE = 'maps.floor.create',
  MAPS_FLOOR_DELETE = 'maps.floor.delete',

  MAPS_AREA_MAP_READ = 'maps.area_map.read',
  MAPS_AREA_MAP_UPDATE = 'maps.area_map.update',
  MAPS_AREA_MAP_CREATE = 'maps.area_map.create',
  MAPS_AREA_MAP_DELETE = 'maps.area_map.delete',

  MAPS_ATTACHMENT_READ = 'maps.attachment.read',
  MAPS_ATTACHMENT_UPDATE = 'maps.attachment.update',
  MAPS_ATTACHMENT_CREATE = 'maps.attachment.create',
  MAPS_ATTACHMENT_DELETE = 'maps.attachment.delete',

  MAPS_FEATURE_READ = 'maps.feature.read',
  MAPS_FEATURE_UPDATE = 'maps.feature.update',
  MAPS_FEATURE_CREATE = 'maps.feature.create',
  MAPS_FEATURE_DELETE = 'maps.feature.delete',

  DESKS_DESK_READ = 'desks.desk.read',
  DESKS_DESK_UPDATE = 'desks.desk.update',
  DESKS_DESK_CREATE = 'desks.desk.create',
  DESKS_DESK_DELETE = 'desks.desk.delete',
  DESKS_DESK_IMPORT = 'desks.desk.import',
  DESKS_DESK_EXPORT = 'desks.desk.export',

  DESKS_NEIGHBORHOOD_READ = 'desks.neighborhood.read',
  DESKS_NEIGHBORHOOD_UPDATE = 'desks.neighborhood.update',
  DESKS_NEIGHBORHOOD_CREATE = 'desks.neighborhood.create',
  DESKS_NEIGHBORHOOD_DELETE = 'desks.neighborhood.delete',

  DESKS_DESK_SETTING_READ = 'desks.desk_setting.read',
  DESKS_DESK_SETTING_UPDATE = 'desks.desk_setting.update',
  DESKS_DESK_SETTING_CREATE = 'desks.desk_setting.create',
  DESKS_DESK_SETTING_DELETE = 'desks.desk_setting.delete',

  DESKS_AMENITY_READ = 'desks.amenity.read',
  DESKS_AMENITY_UPDATE = 'desks.amenity.update',
  DESKS_AMENITY_CREATE = 'desks.amenity.create',
  DESKS_AMENITY_DELETE = 'desks.amenity.delete',

  DESKS_RESERVATION_READ = 'desks.reservation.read',
  DESKS_RESERVATION_CREATE = 'desks.reservation.create',
  DESKS_RESERVATION_UPDATE = 'desks.reservation.update',
  DESKS_RESERVATION_DELETE = 'desks.reservation.delete',

  DESKS_RESERVATION_LOG_READ = 'desks.reservation_log.read',

  DESKS_TRIAL_REQUEST_CREATE = 'desks.trial_request.create',

  VISITORS_ENTRY_READ_MINE = 'visitors.entry.read_mine',
  VISITORS_ENTRY_READ = 'visitors.entry.read',
  VISITORS_ENTRY_CREATE = 'visitors.entry.create',
  VISITORS_ENTRY_UPDATE = 'visitors.entry.update',
  VISITORS_ENTRY_DELETE = 'visitors.entry.delete',
  VISITORS_ENTRY_EXPORT = 'visitors.entry.export',

  VISITORS_INVITE_READ_MINE = 'visitors.invite.read_mine',
  VISITORS_INVITE_READ = 'visitors.invite.read',
  VISITORS_INVITE_CREATE = 'visitors.invite.create',
  VISITORS_INVITE_UPDATE = 'visitors.invite.update',
  VISITORS_INVITE_DELETE = 'visitors.invite.delete',
  VISITORS_INVITE_EXPORT = 'visitors.invite.export',

  VISITORS_FLOW_INSPECT = 'visitors.flow.inspect',

  VISITORS_FLOW_CREATE = 'visitors.local_flow.create',
  VISITORS_FLOW_UPDATE = 'visitors.local_flow.update',
  VISITORS_FLOW_DELETE = 'visitors.local_flow.delete',

  VISITORS_GLOBAL_FLOW_CREATE = 'visitors.global_flow.create',
  VISITORS_GLOBAL_FLOW_UPDATE = 'visitors.global_flow.update',
  VISITORS_GLOBAL_FLOW_DELETE = 'visitors.global_flow.delete',

  VISITORS_EMPLOYEE_FLOW_CREATE = 'workplace.employee_flow.create',
  VISITORS_EMPLOYEE_FLOW_UPDATE = 'workplace.employee_flow.update',
  VISITORS_EMPLOYEE_FLOW_DELETE = 'workplace.employee_flow.delete',

  VISITORS_BLOCKLIST_CREATE = 'visitors.blocklist.create',
  VISITORS_BLOCKLIST_READ = 'visitors.blocklist.read',
  VISITORS_BLOCKLIST_UPDATE = 'visitors.blocklist.update',
  VISITORS_BLOCKLIST_DELETE = 'visitors.blocklist.delete',
  VISITORS_BLOCKLIST_UPLOAD = 'visitors.blocklist.upload',

  VISITORS_WATCHLIST_CREATE = 'visitors.watchlist.create',
  VISITORS_WATCHLIST_READ = 'visitors.watchlist.read',
  VISITORS_WATCHLIST_UPDATE = 'visitors.watchlist.update',
  VISITORS_WATCHLIST_DELETE = 'visitors.watchlist.delete',

  VISITORS_FULL_LEGAL_NAME_SETTING_READ = 'visitors.full_legal_name_setting.read',
  VISITORS_FULL_LEGAL_NAME_SETTING_UPDATE = 'visitors.full_legal_name_setting.update',

  VISITORS_DEVICE_CREATE = 'visitors.device.create',
  VISITORS_DEVICE_READ = 'visitors.device.read',
  VISITORS_DEVICE_UPDATE = 'visitors.device.update',
  VISITORS_DEVICE_DELETE = 'visitors.device.delete',

  VISITORS_PRINTER_CREATE = 'visitors.printer.create',
  VISITORS_PRINTER_READ = 'visitors.printer.read',
  VISITORS_PRINTER_UPDATE = 'visitors.printer.update',
  VISITORS_PRINTER_DELETE = 'visitors.printer.delete',
  // Necessary for gating Devices > Printers nav item, because Location Admin
  // based custom roles without View Devices permission set could still view
  // printers when gating by 'visitors.printer.read', since they actually get
  // 'visitors.printer.read' from the entries and invites update permission sets
  VISITORS_PRINTER_NAV = 'visitors.printer.nav',

  VISITORS_PRINTER_CONNECTION_DELETE = 'visitors.printer_connection.delete',
  VISITORS_PRINTER_CONNECTION_READ = 'visitors.printer_connection.read',

  VISITORS_PRINTER_PASSCODE_CREATE = 'visitors.printer_passcode.create',
  VISITORS_GLOBAL_PRINTER_PASSCODE_CREATE = 'visitors.global_printer_passcode.create',

  VISITORS_GLOBAL_PRINTER_CONNECTION_DELETE = 'visitors.global_printer_connection.delete',

  VISITORS_SECURITY_DESK_READ = 'visitors.security_desk.read',

  VISITORS_VFD_CONFIGURATION_READ = 'visitors.vfd_configuration.read',
  VISITORS_VFD_CONFIGURATION_UPDATE = 'visitors.vfd_configuration.update',

  USER_MANAGEMENT_ADMIN_READ = 'user_management.admin.read',
  USER_MANAGEMENT_ADMIN_CREATE = 'user_management.admin.create',
  USER_MANAGEMENT_ADMIN_UPDATE = 'user_management.admin.update',
  USER_MANAGEMENT_ADMIN_DELETE = 'user_management.admin.delete',

  USER_MANAGEMENT_CUSTOM_ROLE_READ = 'user_management.custom_role.read',
  USER_MANAGEMENT_CUSTOM_ROLE_CREATE = 'user_management.custom_role.create',
  USER_MANAGEMENT_CUSTOM_ROLE_UPDATE = 'user_management.custom_role.update',
  USER_MANAGEMENT_CUSTOM_ROLE_DELETE = 'user_management.custom_role.delete',

  USER_MANAGEMENT_EMPLOYEE_READ = 'user_management.employee.read',
  USER_MANAGEMENT_EMPLOYEE_CREATE = 'user_management.employee.create',
  USER_MANAGEMENT_EMPLOYEE_UPDATE = 'user_management.employee.update',
  USER_MANAGEMENT_EMPLOYEE_DELETE = 'user_management.employee.delete',
  USER_MANAGEMENT_EMPLOYEE_IMPORT = 'user_management.employee.import',
  USER_MANAGEMENT_EMPLOYEE_EXPORT = 'user_management.employee.export',
}
