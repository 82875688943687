import Report from 'garaje/models/report';
import _defaults from 'lodash/defaults';

export default class VisualComplianceReport extends Report {
  declare count: number | null;
  declare message: string | null;
  declare results: unknown[];
  /**
   * visual compliance matches do not require approve/deny review by an admin
   */
  declare reviewable: boolean;
  declare risk_country: string | null;
  declare status: string | null;

  constructor(attrs: Partial<VisualComplianceReport> = {}) {
    super(attrs);
    const props = { ...attrs };
    _defaults(props, {
      count: null,
      message: null,
      results: [],
      reviewable: false,
      risk_country: null,
      status: null,
    });
    Object.assign(this, props);
  }
}
