import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { get, set, setProperties, action } from '@ember/object';
import { format, endOfDay, formatISO, startOfDay, subMinutes, getUnixTime } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { task } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import normalizeResponse from 'garaje/utils/normalize-response';
import urlBuilder from 'garaje/utils/url-builder';
import { tracked } from '@glimmer/tracking';

/**
 * @param {Desk}                    desk
 * @param {string}                  text
 */
export default class AvailableDeskPopup extends Component {
  @service abilities;
  @service state;
  @service store;
  @service flashMessages;
  @service metrics;
  @service ajax;

  @tracked isAmenitiesExpanded = false;

  get isPartialDayEnabled() {
    return this.abilities.can('use partial day booking desk');
  }

  get formattedDate() {
    const displayDate = subMinutes(this.args.date, this.state.minutesBetweenTimezones);
    return format(displayDate, 'MMMM dd, yyyy');
  }

  get locationEndTime() {
    return this.state.getOfficeLocationTime(
      endOfDay(utcToZonedTime(this.args.date, this.state.currentLocation.timezone))
    );
  }

  get amenitiesToDisplay() {
    const { desk } = this.args;

    if (this.isAmenitiesExpanded) {
      return desk.amenities;
    }

    return desk.amenities.slice(0, 2);
  }

  @action
  toggleIsAmenitiesExpanded(e) {
    this.isAmenitiesExpanded = !this.isAmenitiesExpanded;
    e.stopPropagation();
  }

  loadInvitesTask = task({}, async () => {
    const { date } = this.args;
    try {
      const endTime = this.locationEndTime;
      const filter = {
        locationId: get(this.state.currentLocation, 'id'),
        dateTo: encodeURIComponent(formatISO(endTime)),
        dateFrom: encodeURIComponent(formatISO(date)),
        email: encodeURIComponent(this.state.currentUser.email),
      };
      const url = urlBuilder.v3.invites.fetchEmployeeInviteOnDate(filter);
      const response = await this.ajax.request(url, {
        type: 'GET',
        contentType: 'application/vnd.api+json',
        headers: { accept: 'application/vnd.api+json' },
      });

      const activeInvite = response.data.find((invite) => !invite.attributes['entry-signed-out-at']);
      if (activeInvite) {
        let invite = this.store.findRecord('invite', activeInvite.id);
        if (!invite) {
          const normalizedInvite = normalizeResponse(this.store, 'invite', { data: activeInvite });
          invite = this.store.createRecord('invite', normalizedInvite.data);
          setProperties(invite, {
            ...normalizedInvite.data.attributes,
          });
        }
        return invite;
      } else {
        return null;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('error:', e);
    }
  });

  createInviteTask = task({}, async () => {
    const startTime = this.args.date;
    const endTime = this.locationEndTime;
    const invite = await this.state.currentLocation.createInvite(
      startTime,
      this.state.currentUser.fullName,
      this.state.currentUser.email
    );
    set(invite, 'endTime', endTime);
    const inviteUserDatum = invite.userData[0];
    set(inviteUserDatum, 'value', 'Employee registration');
    setProperties(invite, {
      employeeScreeningFlow: true,
      flowName: 'Employee registration',
    });
    await invite.save();
    return invite;
  });

  loadOrCreateInviteTask = task({}, async () => {
    let invite = await this.loadInvitesTask.perform();
    if (!invite) {
      invite = await this.createInviteTask.perform();
    }
    return invite;
  });

  bookDeskTask = task({ drop: true }, async () => {
    const { currentUserReservation, desk, date, loadReservations, loadAvailableDesks, setEmployees } = this.args;
    try {
      let newReservation;
      if (currentUserReservation) {
        const invite = await currentUserReservation.invite;
        newReservation = await currentUserReservation.editReservation(
          invite,
          desk,
          currentUserReservation.startTime,
          currentUserReservation.endTime
        );
      } else {
        const invite = await this.loadOrCreateInviteTask.perform();
        const locationTime = this.state.getOfficeLocationTime(
          startOfDay(subMinutes(date, this.state.minutesBetweenTimezones))
        );
        const reservation = this.store.createRecord('reservation', {
          invite,
          desk,
          user: this.state.currentUser,
          location: this.state.currentLocation,
          startTime: getUnixTime(locationTime),
          endTime: getUnixTime(this.locationEndTime),
        });
        newReservation = await reservation.save();
      }

      this.metrics.trackEvent('Web Live Map - Book Desk', {
        current_desk_id: currentUserReservation ? get(currentUserReservation.desk, 'id') : null,
        current_reservation_id: currentUserReservation?.id,
        new_desk_id: currentUserReservation
          ? newReservation.data.relationships.desk.data.id
          : newReservation.belongsTo('desk').id(),
      });

      const employee = await this.fetchEmployeeTask.perform();
      setEmployees(employee);
      loadReservations();
      loadAvailableDesks();

      this.flashMessages.showAndHideFlash('success', 'Desk booked');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  fetchEmployeeTask = task({}, async () => {
    const employees = await this.store.query('employee', {
      filter: {
        locations: this.state.currentLocation.id,
        'email-in': this.state.currentUser.email,
        deleted: false,
      },
      include: 'user',
      // page: { limit, offset },
    });
    return employees.firstObject;
  });
}
