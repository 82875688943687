import Component from '@glimmer/component';

const DEFAULT_PAGE_SIZE = 30;
const PRINTER_MODE_FILTER_OPTIONS = [
  { label: 'All modes', value: '' },
  { label: 'Bluetooth mode', value: 'bluetooth' },
  { label: 'Network mode', value: 'ip' },
];
const PRINTER_STATUS_FILTER_OPTIONS = [
  { label: 'All printer statuses', value: '' },
  { label: 'Operational', value: 'connected' },
  { label: 'Offline', value: 'Not connected' },
];

/**
 * @param {String}                    locationId
 * @param {Function}                  changeLocationId
 * @param {Array<SkinnyLocation>}     locations
 * @param {Function}                  changePrinterModel
 * @param {String}                    printerModel
 * @param {Array<String>}             printerModels
 * @param {Function}                  changePrinterMode
 * @param {String}                    printerMode
 * @param {Function}                  changePrinterStatus
 * @param {String}                    printerStatus
 * @param {Number}                    pageNumber
 * @param {Number}                    recordCount
 * @param {Function}                  setPageNumber
 */
export default class GlobalDevicesPrintersFilters extends Component {
  printerModeFilterOptions = PRINTER_MODE_FILTER_OPTIONS;
  printerStatusFilterOptions = PRINTER_STATUS_FILTER_OPTIONS;
  defaultLocationFilter = { name: 'All locations', id: '' };

  get pageNumber() {
    return this.args.pageNumber ?? 1;
  }
  get pageSize() {
    return this.args.pageSize ?? DEFAULT_PAGE_SIZE;
  }
  get totalRecords() {
    return this.args.totalRecords ?? 0;
  }
  /*
   * Filters
   */
  get locationOptions() {
    return [this.defaultLocationFilter, ...this.args.locations];
  }

  get printerModelFilterOptions() {
    const models = this.args.printerModels;
    const modelFilters = [{ label: 'All device types', value: '' }];

    if (models) {
      models.forEach((model) => {
        modelFilters.addObject({ label: model, value: model });
      });
    }

    return modelFilters;
  }
}
