import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { subDays } from 'date-fns';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type AnnouncementModel from 'garaje/models/announcement';
import type CurrentLocationService from 'garaje/services/current-location';
import type StateService from 'garaje/services/state';

import { getAnnouncementQueryParams } from './announcement-queries';
import type CommunicationsMessageLogListControllerBase from './controller-base';

export const PAGE_SIZE = 25;
const SIX_MONTHS_IN_DAYS = 180;

export type AnnoucementsListModel = {
  announcements: PaginatedRecordArray<AnnouncementModel>;
  startDate: Date;
  endDate: Date;
};

export default class CommunicationsMessageLogRouteBase extends Route {
  @service declare store: Store;
  @service declare state: StateService;
  @service declare currentLocation: CurrentLocationService;

  async model(): Promise<AnnoucementsListModel | void> {
    const currentDate = new Date();

    const startDate = subDays(currentDate, SIX_MONTHS_IN_DAYS);
    const endDate = currentDate;

    const announcements = await this.loadAnnouncements(startDate, endDate);
    return { announcements, startDate, endDate };
  }

  async loadAnnouncements(startDate: Date, endDate: Date): Promise<PaginatedRecordArray<AnnouncementModel>> {
    try {
      const queryParams = getAnnouncementQueryParams(
        [this.currentLocation?.location?.id],
        startDate,
        endDate,
        PAGE_SIZE
      );
      const result = await this.store.query('announcement', queryParams);

      return result as PaginatedRecordArray<AnnouncementModel>;
    } catch (error) {
      throw new Error(`Failed to load announcements`);
    }
  }

  setupController(
    controller: CommunicationsMessageLogListControllerBase,
    model: AnnoucementsListModel,
    transition: Transition,
    loadLocations: boolean = true
  ): void {
    const { startDate, endDate } = model;
    super.setupController(controller, model, transition);
    void controller.initializeData.perform(startDate, endDate, loadLocations);
  }
}
