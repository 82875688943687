import Component from '@glimmer/component';
import { format, toDate, utcToZonedTime } from 'date-fns-tz';
import type AnnouncementModel from 'garaje/models/announcement';

type AnnouncementOverviewArgsType = {
  model: AnnouncementModel;
};

export default class AnnouncementOverview extends Component<AnnouncementOverviewArgsType> {
  get locationSentDate(): string {
    const createdAt = this.args.model.createdAt;
    const timezone = this.args.model.location.content?.timezone;

    return this.formatDate(toDate(createdAt), timezone);
  }

  formatDate(date: Date, timezone?: string): string {
    return format(utcToZonedTime(date, timezone || ''), "MMM dd, yyyy 'at' h:mm a zzz");
  }
}
