/* eslint-disable ember/no-get */
import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
import { get } from '@ember/object';
import { dependentKeyCompat } from '@ember/object/compat';
import { inject as service } from '@ember/service';
import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { memberAction } from 'ember-api-actions';
import { all, restartableTask, timeout } from 'ember-concurrency';
import type FlowModel from 'garaje/models/flow';
import type LocationModel from 'garaje/models/location';
import type { RecordArray } from 'garaje/utils/type-utils';
import zft from 'garaje/utils/zero-for-tests';

interface Contact {
  userId: string;
  fullName: string;
}

type FlowNameAndID = Pick<FlowModel, 'name' | 'id'>;

const ADMIN_ROLES = ['Global Admin', 'Location Admin', 'Receptionist' /*Front Desk Admin*/];

class VisitorSurveyConfigurationModel extends Model {
  @service declare store: Store;

  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;

  /*
    List of admin users by id as notification contacts

    - No User relation here, this is a v3 resource and we only edit the ids within the array

    @property contactIds
    @type Array<number>
  */
  @attr('array', { defaultValue: () => [] }) declare contactIds: string[];

  /*
    Contacts are used to read the display names of the notification contacts

    @property contacts
    @type Array<{userId:string,fullName:string}>
  */
  @attr('contacts') declare contacts: NativeArray<Contact>;

  @tracked visitorTypes: RecordArray<FlowModel> | NativeArray<FlowModel> = A();

  @dependentKeyCompat
  get allVisitorTypesEnabled(): boolean {
    const values = A(this.visitorTypes.filterBy('enabled').mapBy('visitorSurveyEnabled')).uniq();
    return values.length === 1 && values[0] === true;
  }

  get enabledVisitorTypes(): FlowNameAndID[] {
    return this.visitorTypes.reduce<FlowNameAndID[]>((acc, flow) => {
      if (flow.enabled && flow.visitorSurveyEnabled) {
        const { name, id } = flow;
        acc.push({ name, id });
      }
      return acc;
    }, []);
  }

  async loadVisitorTypes(locationId: string): Promise<void> {
    const filter = { location: locationId };
    const visitorTypes = await this.store.query('flow', { filter });
    this.visitorTypes = visitorTypes;
  }

  searchAdmins = restartableTask(async (locationId: string, term: string) => {
    await timeout(zft(250));
    const userRoles = await this.store.query('user-role', {
      filter: { query: term, location: locationId, roles: ADMIN_ROLES.join(',') },
    });
    const users = await all(userRoles.map((role) => get(role, 'user')));
    const currentIds = this.contactIds;
    const re = new RegExp(`.*${term}.*`, 'i');
    return A(users)
      .uniqBy('id')
      .reject(({ id }) => currentIds.includes(id))
      .filter(({ fullName }) => fullName.match(re));
  });

  get hasDirtyContacts(): boolean {
    const changedAttrs = this.changedAttributes();
    return Object.keys(changedAttrs).includes('contactIds');
  }

  declare getAnalyticsviewPreview: ReturnType<typeof memberAction>;
  declare getEmailviewPreview: ReturnType<typeof memberAction>;
  declare getWebviewPreview: ReturnType<typeof memberAction>;
}

/*
  GET /api/v3/visitor-survey-configurations/:id/analytics-email-preview
*/
VisitorSurveyConfigurationModel.prototype.getAnalyticsviewPreview = memberAction({
  path: 'analytics-email-preview',
  type: 'GET',
});

/*
  GET /api/v3/visitor-survey-configurations/:id/email-survey-preview
*/
VisitorSurveyConfigurationModel.prototype.getEmailviewPreview = memberAction({
  path: 'email-survey-preview',
  type: 'GET',
});

/*
  GET /api/v3/visitor-survey-configurations/:id/preview?selection=(positive|negative)
*/
VisitorSurveyConfigurationModel.prototype.getWebviewPreview = memberAction({
  path: 'preview',
  type: 'GET',
});

export default VisitorSurveyConfigurationModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'visitor-survey-configuration': VisitorSurveyConfigurationModel;
  }
}
