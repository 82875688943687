import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { parse } from 'date-fns';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import { task } from 'ember-concurrency';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { reads } from 'macro-decorators';

import { type EditCheckinRouteModel, type DateChangeset } from './route';

const TIME_FORMAT = 'h:mm aaa';

export default class PropertyVisitorsInvitesEditCheckinController extends Controller {
  declare model: EditCheckinRouteModel;

  @reads('model.checkedInAtChangeset') checkedInAtChangeset!: DateChangeset;

  @service declare flashMessages: FlashMessagesService;

  get currentArrivalTime(): string {
    return formatInTimeZone(this.checkedInAtChangeset.value, this.model.property.timezone, TIME_FORMAT);
  }

  saveTask = task(async (event: Event) => {
    event.preventDefault();

    try {
      await this.model.invite.checkIn({
        'checked-in-at': this.checkedInAtChangeset.value,
      });
      await this.checkedInAtChangeset.save();

      this.flashMessages.showAndHideFlash('success', 'Arrival status updated.');
      // router service transition to unnecessarily reloads the tenants route model
      this.transitionToRoute('property.visitors.invites');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  @action
  setTime(time: string): void {
    this.checkedInAtChangeset.value = zonedTimeToUtc(
      parse(time, TIME_FORMAT, new Date()),
      this.model.property.timezone
    );
  }
}
