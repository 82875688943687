import { task, timeout } from 'ember-concurrency';
import Service, { inject as service } from '@ember/service';
import { dasherize } from '@ember/string';
import zft from 'garaje/utils/zero-for-tests';
import { set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { APP } from 'garaje/utils/enums';

export default class PersonalizedChecklistsService extends Service {
  @service flashMessages;
  @service growth;
  @service linkManager;
  @service router;
  @service state;

  @tracked checklistData = [];
  @tracked checklistMetadata = {};
  @tracked customerTabs = [];
  @tracked parentData = {};
  @tracked personalizedChecklists = [];
  @tracked reload = false;
  @tracked tabs = [];
  @tracked tabMetadata = [];

  currentTab;

  personalizedChecklistHeaders = { accept: 'application/vnd.api+json' };
  TABS_METADATA = {
    pairIpad: {
      title: 'Set up iPad or QR code',
      description: 'Set up an iPad for visitor sign-in at your front desk.',
      route: 'visitors.devices.ipads',
      rank: 1,
      icon: {
        path: '/assets/images/visitors-ipad.svg',
        currentPath: '/assets/images/visitors-ipad-white.svg',
      },
      resources: {
        identifier: 'pair-ipad',
        title: 'Resources for you',
        imagePath: '/assets/images/resources-ipad.png',
        items: [
          {
            iconPath: '/assets/images/monitor-black.svg',
            text: 'Pairing the Visitors kiosk',
            linkAddress: 'https://youtu.be/r5x1siAbXc0',
          },
          {
            iconPath: '/assets/images/document-black.svg',
            text: 'Visitor sign-in kiosk',
            linkAddress: 'https://envoy.help/en/collections/1994643-visitor-sign-in-kiosk?q=quickstartguide',
          },
          {
            iconPath: '/assets/images/document-black.svg',
            text: 'Troubleshooting iPad status',
            linkAddress: 'https://envoy.help/en/collections/1994643-visitor-sign-in-kiosk?q=quickstartguide',
          },
        ],
      },
    },
    setupBranding: {
      title: 'Customize branding',
      description: 'Customize your branding by uploading logos and images.',
      route: 'manage.location-settings',
      rank: 2,
      icon: {
        path: '/assets/images/visitors-branding.svg',
        currentPath: '/assets/images/visitors-branding-white.svg',
      },
      resources: {
        identifier: 'setup-branding',
        title: 'Resources for you',
        imagePath: '/assets/images/resources-customize-branding.png',
        items: [
          {
            iconPath: '/assets/images/monitor-black.svg',
            text: 'Customize your visitor kiosk',
            linkAddress: 'https://youtu.be/RWzlqZO17Ks',
          },
          {
            iconPath: '/assets/images/document-black.svg',
            text: 'Customize your welcome screen',
            linkAddress: 'https://envoy.help/en/articles/3330019-welcome-screen?q=quickstartguide',
          },
          {
            iconPath: '/assets/images/document-black.svg',
            text: 'Touchless walk-ins',
            linkAddress: 'https://envoy.help/en/articles/4296435-touchless-walk-ins?q=quickstartguide',
          },
        ],
      },
    },
    customizeSignInFlow: {
      title: 'Build sign-in flows',
      description: 'Build the flows for your visitors to check in.',
      route: 'visitors.settings.visitor-types',
      rank: 3,
      icon: {
        path: '/assets/images/document-sign.svg',
        currentPath: '/assets/images/document-sign-white.svg',
      },
      resources: {
        identifier: 'customize-sign-in-flow',
        title: 'Resources for you',
        imagePath: '/assets/images/resources-sign-in-flow.png',
        items: [
          {
            iconPath: '/assets/images/monitor-black.svg',
            text: 'Customize your visitor sign-in flow',
            linkAddress: 'https://youtu.be/DZu8A9mh6q0',
          },
          {
            iconPath: '/assets/images/document-black.svg',
            text: 'Sign-in flows',
            linkAddress: 'https://envoy.help/en/collections/1930734-sign-in-flows?q=quickstartguide',
          },
          {
            iconPath: '/assets/images/document-black.svg',
            text: 'Setting up visitor types',
            linkAddress: 'https://envoy.help/en/articles/3329901-setting-up-visitor-types?q=quickstartguide',
          },
        ],
      },
    },
    setupCompany: {
      title: 'Add employees and admins',
      description: 'Invite employees and admins to Envoy. Admins will be notified that they’ve been invited.',
      route: 'employees',
      rank: 4,
      icon: {
        path: '/assets/images/group-sign-in.svg',
        currentPath: '/assets/images/group-sign-in-white.svg',
      },
      resources: {
        identifier: 'setup-company',
        title: 'Resources for you',
        imagePath: '/assets/images/resources-setup-company.png',
        items: [
          {
            iconPath: '/assets/images/monitor-black.svg',
            text: 'Set up your employee directory and admin roles',
            linkAddress: 'https://youtu.be/-uwBVs7Y3t0',
          },
          {
            iconPath: '/assets/images/document-black.svg',
            text: 'Using the Employee directory',
            linkAddress: 'https://envoy.help/en/articles/3851171-using-the-employee-directory?q=quickstartguide',
          },
        ],
      },
    },
    setupIntegration: {
      title: 'Set up integrations',
      description: 'Integrate tools teams already use to inform employees when their visitors arrive.',
      route: 'apps.index',
      rank: 5,
      icon: {
        path: '/assets/images/integration.svg',
        currentPath: '/assets/images/integration-white.svg',
      },
      resources: {
        identifier: 'setup-integration',
        title: 'Resources for you',
        imagePath: '/assets/images/resources-integrations.png',
        items: [
          {
            iconPath: '/assets/images/document-black.svg',
            text: 'Slack',
            linkAddress: 'https://envoy.help/en/articles/3453659-slack?q=quickstartguide',
          },
          {
            iconPath: '/assets/images/document-black.svg',
            text: 'Google Calendar Extension',
            linkAddress: 'https://envoy.help/en/articles/6739358-google-calendar-extension?q=quickstartguide',
          },
        ],
      },
    },
  };

  loadChecklistTask = task({ drop: true }, async () => {
    try {
      await this.growth.post
        .perform(this.personalizedChecklistHeaders, `personalized-checklists?app=${this.checklistMetadata.app}`)
        .then(async (personalizedChecklistResponse) => {
          if (this.reload) await timeout(zft(1000));

          const tabs = [];

          set(
            this,
            'personalizedChecklists',
            personalizedChecklistResponse?.data.map((item) => {
              const { title, description, metadata, completed } = item.attributes;
              const { parent } = metadata;

              if (!tabs.includes(parent)) {
                tabs.push(parent);
              }

              return { title, description, metadata, completed };
            })
          );

          this.customerTabs = tabs;
        });
    } catch (e) {
      this.flashMessages.showFlash('error', "We're sorry, something went wrong.");
    }
  });

  loadParentDataTask = task({ drop: true }, async () => {
    try {
      set(
        this,
        'parentData',
        this.personalizedChecklists.reduce((result, item) => {
          const { title, description, completed, metadata } = item;
          const { parent, route } = metadata;

          if (!result[parent]) {
            result[parent] = {
              route: this.TABS_METADATA[parent]?.route,
              children: [],
            };
          }

          const childObject = { title, description, route, completed };

          if ('flow_id' in metadata) {
            if (this.checklistMetadata?.flowId) childObject.flowId = this.checklistMetadata.flowId;
            else return result;
          }

          result[parent].children.pushObject(childObject);

          return result;
        }, {})
      );
    } catch (e) {
      this.flashMessages.showFlash('error', "We're sorry, something went wrong.");
    }
  });

  loadTabsTask = task({ drop: true }, async () => {
    try {
      this.tabs = [];
      const parentNames = Object.keys(this.parentData);
      parentNames.forEach((parentName) => {
        const parentData = this.parentData[parentName];
        const parentMetadata = this.TABS_METADATA[parentName];
        const children = parentData.children;
        const routeNames = [];
        routeNames.pushObject(parentMetadata.route);

        for (const child of children) {
          routeNames.pushObject(child.route);
        }

        const tab = {
          name: parentName,
          label: this.TABS_METADATA[parentName]?.title,
          class: '!text-base',
          routeNames: routeNames,
          link: this.parentData
            ? this.linkManager.createUILink({
                route: parentMetadata.route,
              })
            : 'settings',
        };
        if (parentMetadata.icon) {
          tab.icon = parentMetadata.icon;
          tab.icon.name = dasherize(parentName);
        }

        const index = this.TABS_METADATA[parentName].rank - 1;
        this.tabs[index] = tab;
      });
    } catch (e) {
      this.flashMessages.showFlash('error', "We're sorry, something went wrong.");
    }
  });

  loadActiveTabTask = task({ drop: true }, async () => {
    try {
      this.currentTab = 'loading';
      if (this.router.currentRoute.localName !== 'loading') {
        const tabData =
          this.tabs.find((tab) => tab?.routeNames?.any((routeName) => this.router.isActive(routeName))) ||
          this.tabs.firstObject;
        this.currentTab = tabData?.name;
      }
    } catch (e) {
      this.flashMessages.showFlash('error', "We're sorry, something went wrong.");
    }
  });

  loadTabChecklistTask = task({ drop: true }, async () => {
    try {
      if (this.currentTab === 'loading' || this.currentTab === undefined) {
        // wait for some time so that the route is loaded
        await timeout(zft(1500));
        await this.loadActiveTabTask.perform();
      }
      if (this.parentData && this.currentTab) {
        set(this, 'checklistData', this.parentData[this.currentTab]?.children);
        set(this, 'tabMetadata', this.TABS_METADATA[this.currentTab]);
      }
    } catch (e) {
      this.flashMessages.showFlash('error', "We're sorry, something went wrong.");
    }
  });

  loadDataTask = task({ drop: true }, async (checklistMetadata, reload) => {
    this.checklistMetadata = checklistMetadata;
    this.reload = reload;

    if (reload && checklistMetadata.app === APP.VISITORS) {
      if (!this.displayForVisitors) return;
    }

    // get all the company CC
    await this.loadChecklistTask.perform().then(async () => {
      // create parent child relationships for the CC
      await this.loadParentDataTask.perform().then(async () => {
        // create tabs with links
        await this.loadTabsTask.perform().then(async () => {
          this.tabs = this.tabs.filter((tab) => Object.keys(tab).length !== 0);

          // set active tab
          await this.loadActiveTabTask.perform().then(async () => {
            // load CC for a tab
            await this.loadTabChecklistTask.perform();
          });
        });
      });
    });
  });

  toggleVisitorsProductVideosEnabledTask = task({ drop: true }, async () => {
    this.currentUser.visitorsProductVideosEnabled = !this.visitorsProductVideosEnabled;

    try {
      await this.currentUser.save();

      if (!this.currentUser.visitorsProductVideosEnabled)
        this.flashMessages.showFlash(
          'success',
          'Toggle the setup guide from your user profile to re-enable onboarding'
        );
    } catch (e) {
      this.currentUser.rollbackAttributes();
    }
  });

  get displayForVisitors() {
    if (this.state.vrSubscription) {
      if (this.state.vrSubscription.cancelled || this.state.vrSubscription.isBasicUser) {
        return false;
      }
    } else {
      return false;
    }

    return true;
  }

  get currentUser() {
    return this.state.currentUser;
  }

  get visitorsProductVideosEnabled() {
    return this.state.currentUser.visitorsProductVideosEnabled;
  }
}
