/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { set } from '@ember/object';
import type {
  Desk,
  Employee,
  EmployeeAttendancePerformanceBasic,
} from 'garaje/graphql/generated/assigned-and-unassigned-employees-types';
import type {
  DepartmentName,
  EmployeeItem,
  DeskMapFeature,
  FeaturesOverview,
  ResourceItem,
  DesksSection,
  RoomsSection,
  PointsOfInterestSection,
  ScheduledAssignment,
} from 'garaje/graphql/generated/map-features-types';
import type DeskModel from 'garaje/models/desk';
import type MapFeatureModel from 'garaje/models/map-feature';
import zft from 'garaje/utils/zero-for-tests';

export function getFlattenedEmployees(
  employees: Array<EmployeeItem> | Record<DepartmentName, Array<EmployeeItem>>
): Array<EmployeeItem> {
  const flattenedEmployees: Array<EmployeeItem> = [];
  if (Array.isArray(employees)) {
    flattenedEmployees.push(...employees);
  } else {
    for (const department in employees) {
      // @ts-ignore
      flattenedEmployees.push(...getFlattenedEmployees(employees[department]));
    }
  }
  return flattenedEmployees;
}

const employeeMatchesSearchTerm = (employee: EmployeeItem, searchTerm: string): boolean =>
  employee.name.toLowerCase().includes(searchTerm.toLowerCase());

// higher level filter function that filters a list of employees on the search term and the attendance policy
const filterEmployees = (
  employees: EmployeeItem[],
  employeeMeetsPolicy: (employee: EmployeeItem) => boolean,
  searchTerm: string,
  isInsightsToggled: boolean
): EmployeeItem[] =>
  employees.filter((employee) => {
    return (
      (!searchTerm || employeeMatchesSearchTerm(employee, searchTerm)) &&
      (!isInsightsToggled || employeeMeetsPolicy(employee))
    );
  });

export const meetsAttendancePolicy = (employee: EmployeeItem): boolean =>
  employee.attendancePerformance?.insights.meetsAttendancePolicy === true;
export const missesAttendancePolicy = (employee: EmployeeItem): boolean =>
  employee.attendancePerformance?.insights.meetsAttendancePolicy == false;

export function filterAndSortGroupedEmployees(
  employees: Array<EmployeeItem> | Record<DepartmentName, Array<EmployeeItem>>,
  employeeMeetsPolicy: (employee: EmployeeItem) => boolean,
  searchTerm: string,
  isInsightsToggled: boolean
): Array<EmployeeItem> | Record<DepartmentName, Array<EmployeeItem>> {
  let filteredEmployees: EmployeeItem[] | Record<string, EmployeeItem[]>;
  if (Array.isArray(employees)) {
    filteredEmployees = filterEmployees(employees, employeeMeetsPolicy, searchTerm, isInsightsToggled);
  } else {
    // (ar) here we sort the departments alphabetically as we create the object to return
    const sortedDepartments = Object.keys(employees).sort();
    filteredEmployees = {} as Record<DepartmentName, Array<EmployeeItem>>;
    sortedDepartments.forEach((department) => {
      // @ts-ignore
      const filteredEmployeesForDepartment = filterEmployees(
        // @ts-ignore
        employees[department],
        employeeMeetsPolicy,
        searchTerm,
        isInsightsToggled
      );
      if (filteredEmployeesForDepartment.length > 0) {
        // @ts-ignore
        filteredEmployees[department] = filteredEmployeesForDepartment;
      }
    });
  }
  return filteredEmployees;
}

export function averageAttendanceDisplay(averageWeeklyAttendance: number): string {
  return '(' + averageWeeklyAttendance + 'x)';
}

export function deskCountDisplay(desks: Desk[] | undefined): string {
  if (!desks) {
    return '';
  }
  const desksCount = desks.length;
  return desksCount > 1 ? '(' + desksCount + ' desks)' : '';
}

export function buildCustomEmployeeItem(
  id: string,
  name: string,
  email: string,
  department: string,
  assignments: ScheduledAssignment[],
  assignedDesks: Desk[],
  attendancePerformance: EmployeeAttendancePerformanceBasic | null
): EmployeeItem {
  const averageWeeklyAttendance = attendancePerformance?.averageWeeklyAttendance ?? null;
  return {
    id: parseInt(id),
    name: name,
    display: name,
    email: email,
    department: department,
    assignedDesks: assignedDesks ? assignedDesks : [],
    attendancePerformance: attendancePerformance,
    assignments: assignments,
    isLastExpanded: true,
    iconClass: 'clock-icon-borderless',
    shouldShowIcon: assignments.length > 0,
    meta: [
      deskCountDisplay(assignedDesks),
      averageWeeklyAttendance !== null ? averageAttendanceDisplay(averageWeeklyAttendance) : '',
    ],
  };
}

export function buildEmployeeItem(employee: Employee, assignments: ScheduledAssignment[] | null): EmployeeItem {
  const averageWeeklyAttendance = employee.attendancePerformance?.averageWeeklyAttendance ?? null;
  return {
    id: parseInt(employee.id),
    name: employee.name,
    display: employee.name,
    email: employee.email,
    department: employee.department,
    assignedDesks: employee.assignedDesks ? employee.assignedDesks : [],
    assignments: assignments ?? [],
    attendancePerformance: employee.attendancePerformance,
    isLastExpanded: true,
    iconClass: 'clock-icon-borderless',
    shouldShowIcon: (assignments?.length !== undefined && assignments.length > 0) ?? false,
    meta: [
      deskCountDisplay(employee.assignedDesks),
      averageWeeklyAttendance !== null ? averageAttendanceDisplay(averageWeeklyAttendance) : '',
    ],
  };
}

export function getGqlType(type: string): string {
  return type.split('-').join('_').toUpperCase();
}

function removeFeatureFromCategory(gqlMapFeatures: FeaturesOverview, feature: MapFeatureModel, category: string) {
  const id = feature.id ?? feature.tempId;
  const sections: DesksSection | RoomsSection | PointsOfInterestSection =
    gqlMapFeatures[category as keyof FeaturesOverview];
  Object.keys(sections).forEach((section: string) => {
    (sections as any)[section] = (sections as any)[section].filter((el: ResourceItem) => el.featureId !== id);
  });
}

function isFeatureInSection(
  gqlMapFeatures: FeaturesOverview,
  feature: ResourceItem,
  category: string,
  section: string
) {
  const sections: DesksSection | RoomsSection | PointsOfInterestSection =
    gqlMapFeatures[category as keyof FeaturesOverview];
  return (sections as any)[section].some((el: ResourceItem) => el.featureId === feature.featureId);
}

export function addFeatureToOverview(
  gqlMapFeatures: FeaturesOverview,
  feature: MapFeatureModel,
  modifiedFeature: ResourceItem
): void {
  if (feature.type === 'desk') {
    if ((feature as unknown as DeskMapFeature).desk.enabled) {
      gqlMapFeatures.desks['hotel-desks'].push(modifiedFeature);
    } else {
      gqlMapFeatures.desks['disabled-desks'].push(modifiedFeature);
    }
    set(gqlMapFeatures, 'desks', { ...gqlMapFeatures.desks });
  } else if (feature.type === 'room') {
    if (feature.enabled) {
      gqlMapFeatures.rooms['enabled-rooms'].push(modifiedFeature);
    } else {
      gqlMapFeatures.rooms['disabled-rooms'].push(modifiedFeature);
    }
    set(gqlMapFeatures, 'rooms', { ...gqlMapFeatures.rooms });
  } else {
    const gqlType = getGqlType(feature.type);
    const poiSection: PointsOfInterestSection = gqlMapFeatures['points-of-interest'];
    if (poiSection[gqlType as keyof PointsOfInterestSection]) {
      poiSection[gqlType as keyof PointsOfInterestSection]?.push(modifiedFeature);
    } else {
      poiSection[gqlType as keyof PointsOfInterestSection] = [modifiedFeature];
    }

    set(gqlMapFeatures, 'points-of-interest', { ...gqlMapFeatures['points-of-interest'] });
  }
}

function updateFeatureInSection(
  gqlMapFeatures: FeaturesOverview,
  category: string,
  section: string,
  modifiedFeature: ResourceItem
) {
  const sections: DesksSection | RoomsSection | PointsOfInterestSection =
    gqlMapFeatures[category as keyof FeaturesOverview];

  (sections as any)[section] = (sections as any)[section].map((el: ResourceItem) => {
    if (el.featureId === modifiedFeature.featureId) {
      return modifiedFeature;
    }
    return el;
  });
}

function updateDeskFeatureInOverview(
  gqlMapFeatures: FeaturesOverview,
  feature: MapFeatureModel,
  modifiedFeature: ResourceItem,
  desk: DeskModel
) {
  if (desk.assignedTo) {
    if (isFeatureInSection(gqlMapFeatures, modifiedFeature, 'desks', 'assigned-desks')) {
      updateFeatureInSection(gqlMapFeatures, 'desks', 'assigned-desks', modifiedFeature);
    } else {
      removeFeatureFromCategory(gqlMapFeatures, feature, 'desks');
      gqlMapFeatures.desks['assigned-desks'].push(modifiedFeature);
    }
  } else if (desk.enabled) {
    if (isFeatureInSection(gqlMapFeatures, modifiedFeature, 'desks', 'hotel-desks')) {
      updateFeatureInSection(gqlMapFeatures, 'desks', 'hotel-desks', modifiedFeature);
    } else {
      removeFeatureFromCategory(gqlMapFeatures, feature, 'desks');
      gqlMapFeatures.desks['hotel-desks'].push(modifiedFeature);
    }
  } else {
    if (isFeatureInSection(gqlMapFeatures, modifiedFeature, 'desks', 'disabled-desks')) {
      updateFeatureInSection(gqlMapFeatures, 'desks', 'disabled-desks', modifiedFeature);
    } else {
      removeFeatureFromCategory(gqlMapFeatures, feature, 'desks');
      gqlMapFeatures.desks['disabled-desks'].push(modifiedFeature);
    }
  }

  set(gqlMapFeatures, 'desks', { ...gqlMapFeatures.desks });
}

function updateRoomFeatureInOverview(
  gqlMapFeatures: FeaturesOverview,
  feature: MapFeatureModel,
  modifiedFeature: ResourceItem
) {
  if (feature.enabled) {
    if (isFeatureInSection(gqlMapFeatures, modifiedFeature, 'rooms', 'enabled-rooms')) {
      updateFeatureInSection(gqlMapFeatures, 'rooms', 'enabled-rooms', modifiedFeature);
    } else {
      removeFeatureFromCategory(gqlMapFeatures, feature, 'rooms');
      gqlMapFeatures.rooms['enabled-rooms'].push(modifiedFeature);
    }
  }

  if (!feature.enabled) {
    if (isFeatureInSection(gqlMapFeatures, modifiedFeature, 'rooms', 'disabled-rooms')) {
      updateFeatureInSection(gqlMapFeatures, 'rooms', 'disabled-rooms', modifiedFeature);
    } else {
      removeFeatureFromCategory(gqlMapFeatures, feature, 'rooms');
      gqlMapFeatures.rooms['disabled-rooms'].push(modifiedFeature);
    }
  }

  set(gqlMapFeatures, 'rooms', { ...gqlMapFeatures.rooms });
}

function updatePointOfInterestFeatureInOverview(
  gqlMapFeatures: FeaturesOverview,
  feature: MapFeatureModel,
  modifiedFeature: ResourceItem
) {
  const gqlType = getGqlType(feature.type);
  const poiSection: PointsOfInterestSection = gqlMapFeatures['points-of-interest'];
  if ((poiSection as any)[gqlType]) {
    (poiSection as any)[gqlType] = (poiSection as any)[gqlType].map((el: ResourceItem) => {
      if (el.featureId === modifiedFeature.featureId) {
        return modifiedFeature;
      }
      return el;
    });
  }

  set(gqlMapFeatures, 'points-of-interest', { ...gqlMapFeatures['points-of-interest'] });
}

export function updateFeatureInOverview(
  gqlMapFeatures: FeaturesOverview,
  feature: MapFeatureModel,
  modifiedFeature: ResourceItem,
  desk: DeskModel
): void {
  if (feature.type === 'desk') {
    updateDeskFeatureInOverview(gqlMapFeatures, feature, modifiedFeature, desk);
  } else if (feature.type === 'room') {
    updateRoomFeatureInOverview(gqlMapFeatures, feature, modifiedFeature);
  } else {
    updatePointOfInterestFeatureInOverview(gqlMapFeatures, feature, modifiedFeature);
  }
}

export function deleteFeatureInOverview(
  gqlMapFeatures: FeaturesOverview,
  feature: MapFeatureModel,
  modifiedFeature: ResourceItem
): void {
  if (feature.type === 'desk') {
    removeFeatureFromCategory(gqlMapFeatures, feature, 'desks');
    set(gqlMapFeatures, 'desks', { ...gqlMapFeatures.desks });
  } else if (feature.type === 'room') {
    gqlMapFeatures.rooms['enabled-rooms'] = gqlMapFeatures.rooms['enabled-rooms'].filter(
      (el) => el.featureId !== modifiedFeature.featureId
    );
    gqlMapFeatures.rooms['disabled-rooms'] = gqlMapFeatures.rooms['disabled-rooms'].filter(
      (el) => el.featureId !== modifiedFeature.featureId
    );
    set(gqlMapFeatures, 'rooms', { ...gqlMapFeatures.rooms });
  } else {
    const gqlType = feature.type.split('-').join('_').toUpperCase();
    const poiSection: PointsOfInterestSection = gqlMapFeatures['points-of-interest'];
    if ((poiSection as any)[gqlType]) {
      (poiSection as any)[gqlType] = (poiSection as any)[gqlType].filter(
        (el: ResourceItem) => el.featureId !== modifiedFeature.featureId
      );
      set(gqlMapFeatures, 'points-of-interest', { ...gqlMapFeatures['points-of-interest'] });
    }
  }
}

export function expandSectionOnFeatureSelect(
  feature: MapFeatureModel,
  desk: DeskModel,
  selectedPanelView: string,
  flattenedAssignedEmployees: EmployeeItem[]
): string {
  let expandedPath = '';
  let element: Element | null = null;
  if (feature.type === 'desk' && feature.externalId && desk) {
    if (desk.assignedTo) {
      if (selectedPanelView === 'overview') {
        expandedPath = `resources/desks/assigned-desks`;
        element = document.querySelector(`div[data-test-last-node="${feature.name}"]`);
      }

      if (selectedPanelView === 'seating') {
        const employee = flattenedAssignedEmployees.find((it) => it.email === desk.assignedTo);
        if (employee) {
          expandedPath = `seating/assigned/${employee.department}`;
          element = document.querySelector(`div[data-test-last-node="${employee.name}"]`);
        }
      }
    } else {
      if (desk.enabled) {
        expandedPath = `resources/desks/hotel-desks`;
      } else {
        expandedPath = `resources/desks/disabled-desks`;
      }
    }
  } else {
    if (feature.type === 'room') {
      if (feature.enabled) {
        expandedPath = `resources/rooms/enabled-rooms`;
      } else {
        expandedPath = `resources/rooms/disabled-rooms`;
      }
    } else if (feature?.type) {
      element = document.querySelector(`div[data-test-last-node="${feature.name}"]`);
      expandedPath = `resources/points-of-interest/${getGqlType(feature.type)}`;
    }
  }

  if (element) {
    setTimeout(() => element!.scrollIntoView({ block: 'center' }), zft(50));
  }

  return expandedPath;
}
