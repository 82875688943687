import { dependentKeyCompat } from '@ember/object/compat';
import { service } from '@ember/service';
import { capitalize } from '@ember/string';
import { isBlank } from '@ember/utils';
import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';
import type CompanyModel from 'garaje/models/company';
import type PlanModel from 'garaje/models/plan';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import adapter from 'garaje/utils/decorators/adapter';
import includes from 'garaje/utils/decorators/includes';
import { App, DESK_COUNT } from 'garaje/utils/enums';
import { equal, gt, not, notEmpty } from 'macro-decorators';
import moment from 'moment-timezone';

@adapter('billing-v1')
class SubscriptionModel extends Model {
  @service declare featureFlags: FeatureFlagsService;

  @belongsTo('company', { async: true, inverse: null }) declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('plan') declare downgradePlan: PlanModel;

  /**
   * Plan type
   */
  @attr('string') declare app: App;
  /**
   * @deprecated
   */
  @attr('number') declare cachedLocationsQuantity: number;
  /**
   * Chargebee status active non_renewing in_trial (end date) cancelled (cancelled date) paused (dates? none)
   */
  @attr('boolean') declare cancelled: boolean;
  @attr('date') declare cancelledAt: Date;

  @attr('number') declare couponAmountOff: number;
  @attr('string') declare couponCode: string;
  @attr('number') declare couponPercentOff: number;
  @attr('boolean', { defaultValue: false }) declare delinquent: boolean; // Move to company
  // location belongsTo
  @attr('string') declare downgradeReason: string;
  @attr('string', { defaultValue: 'Infinity' }) declare employeeLimit: string;
  @attr({ defaultValue: () => [] }) declare features: string[];
  @attr('boolean') declare isManual: boolean;
  @attr('string', { defaultValue: 'Infinity' }) declare locationLimit: string;
  @attr('boolean') declare nonRenewing: boolean;
  @attr('boolean') declare onTrial: boolean;
  @attr('boolean') declare onExpiredTrial: boolean;
  @attr('date') declare nextBillingAt: Date;
  @attr('string') declare nextPeriod: string;
  @attr('boolean') declare paused: boolean;
  @attr('boolean') declare faked: boolean;
  @attr('string') declare period: string;
  @attr('number') declare periodEnd: number;
  @attr('number') declare periodStart: number;
  @attr('string') declare plan: string;
  @attr('object') declare flowPayload: Record<string, unknown>;
  @attr('string') declare procurementSource: string;
  @attr('number') declare quantity: number;
  @attr('string', { defaultValue: '' }) declare referral: string;
  @attr('boolean', { defaultValue: false }) declare requireFinanceTeamIntervention: boolean;
  @attr('string') declare stripeToken: string;
  @attr('boolean') declare subscribed: boolean;
  @attr('date') declare trialStartDate: Date;
  @attr('date') declare trialEndDate: Date;
  @attr('number') declare timesTrialExtended: number;
  @attr('number') declare desksCount: number;
  @attr('boolean') declare hasScheduledChanges: boolean;
  @attr('boolean') declare specialPricing: boolean;
  @attr('boolean') declare freeBundle: boolean;

  @attr('object') declare coupon: Record<string, unknown>;
  @includes('features', 'analytics') declare canAccessAnalytics: boolean;
  @includes('features', 'assistants') declare canAccessAssistants: boolean;
  @includes('features', 'blacklist') declare canAccessBlocklist: boolean;
  @includes('features', 'boss') declare canAccessBoss: boolean;
  @includes('features', 'box') declare canAccessBox: boolean;
  @includes('features', 'check_in_notification') declare canAccessCheckInNotification: boolean;
  @includes('features', 'custom_badge') declare canAccessCustomBadge: boolean;
  @includes('features', 'custom_notifications') declare canAccessCustomNotifications: boolean;
  @includes('features', 'custom_admin_roles') declare canManageCustomAdminRoles: boolean;
  @includes('features', 'directory_sync') declare canAccessDirectorySync: boolean;
  @includes('features', 'global_sign_in_flows') declare canAccessGlobalSignInFlows: boolean;
  @includes('features', 'group_sign_in') declare canAccessGroupSignIn: boolean;
  @includes('features', 'id_scanning') declare canAccessIdScanning: boolean;
  @includes('features', 'legal_name_validation') declare canAccessFullLegalName: boolean;
  @includes('features', 'microsoft_365') declare canAccessMicrosoft365: boolean;
  @includes('features', 'multi_location_invites') declare canAccessMultiLocationInvites: boolean;
  @includes('features', 'multiple_hosts') declare canAccessMultipleHosts: boolean;
  @includes('features', 'multiple_printers') declare canAccessMultiplePrinters: boolean;
  @includes('features', 'multiple_visitor_types') declare canAccessMultipleVisitorTypes: boolean;
  @includes('features', 'host_search_options') declare canAccessHostSearchOptions: boolean;
  @includes('features', 'nda_bcc') declare canAccessNdaBcc: boolean;
  @includes('features', 'pre_registration_required') declare canAccessPreRegistrationRequired: boolean;
  @includes('features', 'privacy_and_booking_controls') declare canAccessPrivacyAndBookingControls: boolean;
  @includes('features', 'rooms_analytics') declare canAccessRoomsAnalytics: boolean;
  @includes('features', 'salesforce') declare canAccessSalesforce: boolean;
  @includes('features', 'saml') declare canAccessSaml: boolean;
  @includes('features', 'scheduled_reports') declare canAccessScheduledReports: boolean;
  @includes('features', 'slack') declare canAccessSlack: boolean;
  @includes('features', 'slideshows') declare canAccessSlideshows: boolean;
  @includes('features', 'scim_admin_sync') declare canAccessScimAdminSync: boolean;
  @includes('features', 'sms_notification') declare canAccessSmsNotification: boolean;
  @includes('features', 'visitor_photos') declare canAccessVisitorPhotos: boolean;
  @includes('features', 'visual_compliance') declare canAccessVisualCompliance: boolean;
  @includes('features', 'visitor_badge_printing') declare canEnableBadgePrinting: boolean;
  @includes('features', 'sign_in_from_invite') declare canSignInFromInvite: boolean;
  @includes('features', 'sign_in_visitors') declare canSignInVisitors: boolean;
  @includes('features', 'id_check') declare canAccessIDCheck: boolean;
  @includes('features', 'visitor_survey') declare canAccessVisitorSurvey: boolean;
  @includes('features', 'welcome_guide') declare canAccessWelcomeGuide: boolean;
  @includes('features', 'locations_grouping') declare canAccessLocationsGrouping: boolean;
  @includes('features', 'global_analytics') declare canAccessGlobalAnalytics: boolean;
  @includes('features', 'conditional_sign_in_fields') declare canAccessConditionalSignInFields: boolean;
  @includes('features', 'conditional_alerts') declare canAccessConditionalAlerts: boolean;
  @includes('features', 'capacity_limits') declare canAccessCapacityLimits: boolean;
  @includes('features', 'whitegloved_from_email_name') declare hasWhiteglovedFromEmailName: boolean;
  @includes('features', 'occupancy_analytics') declare canAccessOccupancyAnalytics: boolean;
  @includes('features', 'protect_legacy_features') declare canAccessProtectLegacyFeatures: boolean;
  @includes('features', 'invite_approvals') declare canRequireInviteApproval: boolean;
  @includes('features', 'delegated_booking') declare canAccessDelegatedBooking: boolean;
  @includes('features', 'desk_reservation_delegated_management') declare canUseDbeam: boolean;
  @includes('features', 'presign_nda') declare hasPresignNda: boolean;
  @includes('features', 'access_control_auto_sign_in') declare canAccessAutoCheckIn: boolean;
  @includes('features', 'static_qr_codes') declare canAccessStaticQrCodes: boolean;
  @includes('features', 'emergency_notifications') declare canAccessEmergencyNotifications: boolean;
  @includes('features', 'facial_recognition') declare canAccessFacialRecognition: boolean;
  @includes('features', 'group_invites') declare canAccessGroupInvitesFeature: boolean;
  @includes('features', 'virtual_front_desk') declare canAccessVirtualFrontDesk: boolean;
  @includes('features', 'attendance_analytics') declare canAccessAttendanceAnalytics: boolean;
  @gt('trialDaysLeft', 0) declare hasTrialDaysLeft: boolean;
  @includes('features', 'video_in_legal_document') declare hasVideoInLegalDocument: boolean;
  @includes('features', 'nda_enabled') declare includesNda: boolean;
  @notEmpty('downgradePlan.id') declare isDowngrading: boolean;
  @equal('plan.name', 'standard') declare isLegacyPlan: boolean;
  @equal('period', 'monthly') declare isMonthly: boolean;
  @equal('period', 'yearly') declare isYearly: boolean;
  @equal('period', 'weekly') declare isWeekly: boolean;
  @equal('period', 'vr') declare isVr: boolean;
  @equal('app', 'deliveries') declare isDeliveries: boolean;
  @equal('app', 'desks') declare isDesks: boolean;
  @equal('app', 'empxp') declare isWorkplace: boolean;
  @equal('app', 'connect') declare isConnect: boolean;
  @equal('app', 'vfd') declare isVfd: boolean;

  @equal('plan', 'basic') declare isBasicPlan: boolean;
  @equal('plan', 'premium') declare isPremiumPlan: boolean;
  @equal('plan', 'premium-plus') declare isPremiumPlusPlan: boolean;
  @equal('plan', 'enterprise') declare isEnterprisePlan: boolean;
  /**
   * @deprecated: use `onTrial` instead, since this actually comes from chargebee
   */
  @not('isSubscribed') declare isOnTrial: boolean;

  get isStandardPlan(): boolean {
    return this.plan === 'standard' || this.plan === 'standard-1';
  }
  @dependentKeyCompat
  get isExpiredDeliveriesTrial(): boolean {
    const appIsDeliveries = this.app == App.DELIVERIES;
    const isNotBasicPlan = this.isBasicPlan == false;
    const zeroTrialDaysLeft = this.trialDaysLeft == 0;
    return appIsDeliveries && isNotBasicPlan && zeroTrialDaysLeft;
  }

  @dependentKeyCompat
  get canDowngradeSelfServe(): boolean {
    if (this.isManual) {
      return false;
    }

    if (this.isEmptyPlan) {
      return false;
    }

    if (this.isLegacyPlan) {
      return false;
    }

    if (this.isBasicUser) {
      return false;
    }

    return true;
  }

  @dependentKeyCompat
  get isBasicUser(): boolean {
    return !this.hasTrialDaysLeft && !this.isSubscribed;
  }

  @dependentKeyCompat
  get isSubscribed(): boolean {
    return this.subscribed && !this.isEmptyPlan;
  }

  @dependentKeyCompat
  get isUsable(): boolean {
    return this.isSubscribed || this.onTrial;
  }

  @dependentKeyCompat
  get isUsableWithBasic(): boolean {
    return (this.subscribed && !isBlank(this.plan)) || this.onTrial;
  }

  // In order to centralize the logic supporting "basic" plan name from chargebee,
  // We creates "isEmptyPlan" CP helps to identify the 'basic' plan from envoy-web
  @dependentKeyCompat
  get isEmptyPlan(): boolean {
    // @yining: we need to clean this up
    const plan = this.plan;
    if (plan === 'basic') {
      // pretend plan is empty
      return true;
    }
    // @yining: we need to clean this up

    return isBlank(plan);
  }

  @dependentKeyCompat
  get name(): string {
    const plan = this.plan;
    return plan && capitalize(plan);
  }

  @dependentKeyCompat
  get trialDaysLeft(): number {
    const currentDate = new Date();
    const trialEndDate = this.trialEndDate || currentDate;

    return Math.max(Math.ceil(moment(trialEndDate).diff(currentDate, 'days', true)), 0);
  }

  @dependentKeyCompat
  get trialLength(): number {
    if (this.trialStartDate && this.trialEndDate) {
      return Math.max(Math.ceil(moment(this.trialEndDate).diff(this.trialStartDate, 'days', true)), 0);
    }
    return 0;
  }

  @dependentKeyCompat
  get periodEndDate(): moment.Moment {
    return moment.unix(this.periodEnd);
  }

  get iconClass(): string {
    const plans = ['standard', 'standard-1', 'premium', 'premium-1', 'enterprise', 'enterprise-1'];

    const plan = this.plan;
    let icon = 'icon-legacyPlan';

    if (plans.indexOf(plan) >= 0) {
      icon = `icon-${plan}Plan`;
    }

    return icon;
  }

  get iconPath(): string {
    switch (this.app) {
      case App.VISITORS:
        return '/assets/images/app-icons/visitors.svg';
      case App.DELIVERIES:
        return '/assets/images/app-icons/deliveries.svg';
      case App.DESKS:
        return '/assets/images/app-icons/desks.svg';
      case App.ROOMS:
        return '/assets/images/app-icons/rooms.svg';
      case App.WORKPLACE:
        return '/assets/images/app-icons/workplace.svg';
      default:
        return '';
    }
  }

  get normalizedPlanName(): string {
    if (this.isPremiumPlusPlan) {
      return 'premium plus';
    }

    if (this.plan === 'standard-1') {
      return 'standard';
    }

    if (this.plan === 'standard' && this.isDeliveries) {
      return 'premium';
    }

    if (this.isVfd) {
      return '';
    }

    return this.plan;
  }

  get normalizedPlanId(): string {
    if (this.plan === 'standard' && this.app === App.DELIVERIES) {
      return 'deliveries-standard';
    } else if (this.plan === 'standard' && this.app === App.ROOMS) {
      return 'rooms-standard';
    } else if (this.plan === 'standard' && this.app === App.DESKS) {
      return 'desks-standard';
    } else if (this.app === App.WORKPLACE) {
      return `empxp-${this.plan}`;
    } else {
      return this.plan;
    }
  }

  get appName(): string {
    if (this.isWorkplace) {
      return 'workplace';
    } else if (this.isVfd) {
      return 'virtual front desk';
    }

    return this.app;
  }

  @dependentKeyCompat
  get unitType(): string | undefined {
    const unitTypeMap: Record<string, string> = {
      desks: 'desk license',
      visitors: 'location',
      deliveries: 'delivery area',
      rooms: 'room',
      workplace: 'employee license',
      empxp: 'employee license',
      vfd: 'virtual front desk license',
    };

    return unitTypeMap[this.app];
  }

  get maximumDesksForCurrentPlan(): number {
    if (this.app !== App.DESKS) {
      return 0;
    }

    // use backend-driven calculation, else if blank, fallback to assuming 25 * quantity
    // backend calculation takes custom addons into account. can be blank
    // if account has been given custom pricing that cannot be parsed
    const desksCount = this.desksCount || this.quantity * DESK_COUNT.PER_LICENSE;
    return this.isBasicPlan ? DESK_COUNT.PER_LICENSE : desksCount;
  }

  get canAccessGroupInviteRecords(): boolean {
    // TODO For [VIS-6197], when removing split:
    // Replace canAccessGroupInvitesFeature (above and in location-subscription)
    // with canAccessGroupInviteRecords
    if (!this.featureFlags.isEnabled('visitors-group-invite-updates-m1')) return false;

    return this.canAccessGroupInvitesFeature;
  }

  declare purchase: (options: {
    quantity: SubscriptionModel['quantity'];
    period: SubscriptionModel['period'];
    coupon: SubscriptionModel['coupon'];
  }) => Promise<void>;
}

SubscriptionModel.prototype.purchase = memberAction({ path: 'purchase', type: 'POST' });

export default SubscriptionModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    subscription: SubscriptionModel;
  }
}
