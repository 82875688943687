import SettingsWelcomeScreenMultipleLanguagesRoute from 'garaje/pods/visitors/settings/welcome-screen/multiple-languages/route';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default class VirtualFrontDeskSettingsMultipleLanguagesRoute extends SettingsWelcomeScreenMultipleLanguagesRoute {
  @service store;

  controllerName = 'visitors/settings/welcome-screen/multiple-languages';
  templateName = 'visitors/settings/welcome-screen/multiple-languages';

  titleToken = 'Multiple Languages';

  model() {
    const location = this.modelFor('current-location');
    const ipads = this.store.query('device', { filter: { location: location.id } });
    return hash({ location, ipads });
  }
}
