import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { gt } from 'macro-decorators';
import { action } from '@ember/object';
import { all, dropTask } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import csvFromArrayOfObjects from 'garaje/utils/object-array-to-csv';
import { EMPLOYEE_COUNT } from 'garaje/utils/enums';

/**
 * @param {Model<EmployeeLocation>}         currentEmployeeLocation Employee location relationship of the current employee and current location
 * @param {Model<Employee>}                 employee Current employee
 * @param {Array}                           employees List of employees to delete
 * @param {Boolean}                         showModal Action to add employee
 * @param {Function}                        afterEmployeeDeleted Action after employee is deleted
 */
export default class DeleteEmployeeModal extends Component {
  @service store;
  @service employeeUpload;
  @service flashMessages;
  @service router;
  @service state;

  @gt('args.employees.length', 1) multipleEmployees;

  get isGlobalOverview() {
    return this.router.currentRouteName?.includes('location-overview');
  }

  @action
  close() {
    if (this.args.employeesCsv) {
      const newCsv = this.args.employeesCsv.map((row) => row.employee);
      this.args.closeModal(newCsv);
    } else {
      this.args.closeModal();
    }
  }

  @action
  async uploadCsvToServer(file) {
    try {
      await this.employeeUpload.uploadEmployeesCSV(file);
      this.parsedObjects = null;
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
    this.args.afterEmployeeDeleted();
  }

  deleteEmployeeTask = dropTask(async () => {
    const flashMessages = this.flashMessages;
    if (this.args.employees) {
      await all(this.args.employees.invoke('destroyRecord'));
      await all(this.args.employees.invoke('unloadRecord'));
      this.args.afterEmployeeDeleted();
      flashMessages.showAndHideFlash('success', 'Employees removed');
    } else if (this.args.employeesCsv) {
      const newCsv = this.args.employeesCsv.filter((row) => !row.checked).map((row) => row.employee);
      if (newCsv.length <= EMPLOYEE_COUNT.EMPLOYEE_LIMIT) {
        const newFile = csvFromArrayOfObjects(newCsv);
        this.uploadCsvToServer(newFile);
      } else {
        this.args.afterEmployeeDeleted(newCsv);
      }
    } else {
      try {
        // @yininge: refactor delete record to an action
        const record = this.args.employee;
        await record.destroyRecord();
        await this.store.unloadRecord(record);
        this.args.closeModal();
        flashMessages.showAndHideFlash('success', 'Employee removed');
        this.args.afterEmployeeDeleted();
      } catch (error) {
        const errorText = parseErrorForDisplay(error);
        flashMessages.showAndHideFlash('error', errorText);
      }
    }
  });

  deleteEmployeeFromLocationTask = dropTask(async () => {
    const flashMessages = this.flashMessages;
    try {
      await this.args.currentEmployeeLocation.destroyRecord();
      this.args.closeModal();
      flashMessages.showAndHideFlash('success', 'Employee removed');
      this.args.afterEmployeeDeleted();
    } catch (error) {
      const errorText = parseErrorForDisplay(error);
      flashMessages.showAndHideFlash('error', errorText);
    }
  });
}
