import Service, { inject as service } from '@ember/service';
import type {
  ApproveDeviceUserCodeMutation,
  ApproveDeviceUserCodeMutationVariables,
  CheckCalendarPermissionsMutation,
  CheckCalendarPermissionsMutationVariables,
  CreateCompanyMutation,
  CreateCompanyMutationVariables,
  CreateLocationMutation,
  CreateLocationMutationVariables,
  DeleteRoomBackgroundImageMutation,
  DeleteRoomBackgroundImageMutationVariables,
  ErrorList,
  GetCompanyQuery,
  GetCompanyQueryVariables,
  GetDevicePairingStatusQuery,
  GetDevicePairingStatusQueryVariables,
  GetRoomQuery,
  GetRoomQueryVariables,
  GetRoomsLocationWithConfigsQuery,
  GetRoomsLocationWithConfigsQueryVariables,
  GetRoomsPaginatedQuery,
  GetRoomsPaginatedQueryVariables,
  GetRoomsQuery,
  GetRoomsQueryVariables,
  GetSpaceSaverConfigQuery,
  GetSpaceSaverConfigQueryVariables,
  LinkRoomsWithLocationMutation,
  LinkRoomsWithLocationMutationVariables,
  PutSpaceSaverCheckInExcludedRoomsMutation,
  PutSpaceSaverCheckInExcludedRoomsMutationVariables,
  PutSpaceSaverRoomResizerExcludedOrganizersMutation,
  PutSpaceSaverRoomResizerExcludedOrganizersMutationVariables,
  PutSpaceSaverRoomResizerExcludedRoomsMutation,
  PutSpaceSaverRoomResizerExcludedRoomsMutationVariables,
  SynchronizeCompanyRoomListMutation,
  SynchronizeCompanyRoomListMutationVariables,
  UnlinkConnectionMutation,
  UnlinkConnectionMutationVariables,
  UnpairDeviceMutation,
  UnpairDeviceMutationVariables,
  UpdateCompanyConfigMutation,
  UpdateCompanyConfigMutationVariables,
  UpdateLightedCaseTypeMutation,
  UpdateLightedCaseTypeMutationVariables,
  UpdateLocationRoomsCheckInWindowMutation,
  UpdateLocationRoomsCheckInWindowMutationVariables,
  UpdateRoomConfigMutation,
  UpdateRoomConfigMutationVariables,
  UpdateRoomsCompanyOnsiteRoomRecaptureEnabledMutation,
  UpdateRoomsCompanyOnsiteRoomRecaptureEnabledMutationVariables,
  UpdateRoomsCompanyRoomResizerEnabledMutation,
  UpdateRoomsCompanyRoomResizerEnabledMutationVariables,
  UpdateRoomsLocationNotificationEmailsMutation,
  UpdateRoomsLocationNotificationEmailsMutationVariables,
  UpdateRoomsLocationOnsiteRoomRecaptureEnabledOverrideMutation,
  UpdateRoomsLocationOnsiteRoomRecaptureEnabledOverrideMutationVariables,
  UpdateRoomsLocationRoomCheckInEnabledOverrideMutation,
  UpdateRoomsLocationRoomCheckInEnabledOverrideMutationVariables,
  UpdateRoomsLocationRoomResizerEnabledOverrideMutation,
  UpdateRoomsLocationRoomResizerEnabledOverrideMutationVariables,
  UpdateRoomsLocationShouldEmailGlobalAdminsMutation,
  UpdateRoomsLocationShouldEmailGlobalAdminsMutationVariables,
  UpdateRoomsLocationShouldEmailLocationAdminsMutation,
  UpdateRoomsLocationShouldEmailLocationAdminsMutationVariables,
} from 'garaje/graphql/generated/roomba-types';
import approveDeviceUserCode from 'garaje/graphql/mutations/roomba/approveDeviceUserCode';
import checkCalendarPermissions from 'garaje/graphql/mutations/roomba/checkCalendarPermissions';
import createCompany from 'garaje/graphql/mutations/roomba/createCompany';
import createLocation from 'garaje/graphql/mutations/roomba/createLocation';
import deleteRoomBackgroundImage from 'garaje/graphql/mutations/roomba/deleteRoomBackgroundImage';
import linkRoomsWithLocation from 'garaje/graphql/mutations/roomba/linkRoomsWithLocation';
import putSpaceSaverCheckInExcludedRooms from 'garaje/graphql/mutations/roomba/putSpaceSaverCheckInExcludedRooms';
import putSpaceSaverRoomResizerExcludedOrganizers from 'garaje/graphql/mutations/roomba/putSpaceSaverRoomResizerExcludedOrganizers';
import putSpaceSaverRoomResizerExcludedRooms from 'garaje/graphql/mutations/roomba/putSpaceSaverRoomResizerExcludedRooms';
import synchronizeCompanyRoomList from 'garaje/graphql/mutations/roomba/synchronizeCompanyRoomList';
import unlinkConnection from 'garaje/graphql/mutations/roomba/unlinkConnection';
import unpairDevice from 'garaje/graphql/mutations/roomba/unpairDevice';
import updateCompanyConfig from 'garaje/graphql/mutations/roomba/updateCompanyConfig';
import updateLightedCaseType from 'garaje/graphql/mutations/roomba/updateLightedCaseType';
import updateLocationRoomsCheckInWindow from 'garaje/graphql/mutations/roomba/updateLocationRoomsCheckInWindow';
import updateRoomConfig from 'garaje/graphql/mutations/roomba/updateRoomConfig';
import updateRoomsCompanyOnsiteRoomRecaptureEnabled from 'garaje/graphql/mutations/roomba/updateRoomsCompanyOnsiteRoomRecaptureEnabled';
import updateRoomsCompanyRoomResizerEnabled from 'garaje/graphql/mutations/roomba/updateRoomsCompanyRoomResizerEnabled';
import updateRoomsLocationCheckInEnabledOverride from 'garaje/graphql/mutations/roomba/updateRoomsLocationCheckInEnabledOverride';
import updateRoomsLocationNotificationEmails from 'garaje/graphql/mutations/roomba/updateRoomsLocationNotificationEmails';
import updateRoomsLocationOnsiteRoomRecaptureEnabledOverride from 'garaje/graphql/mutations/roomba/updateRoomsLocationOnsiteRoomRecaptureEnabledOverride';
import updateRoomsLocationRoomResizerEnabledOverride from 'garaje/graphql/mutations/roomba/updateRoomsLocationRoomResizerEnabledOverride';
import updateRoomsLocationShouldEmailGlobalAdmins from 'garaje/graphql/mutations/roomba/updateRoomsLocationShouldEmailGlobalAdmins';
import updateRoomsLocationShouldEmailLocationAdmins from 'garaje/graphql/mutations/roomba/updateRoomsLocationShouldEmailLocalAdmins';
import getCompany from 'garaje/graphql/queries/roomba/getCompany';
import getDevicePairingStatus from 'garaje/graphql/queries/roomba/getDevicePairingStatus';
import getRoom from 'garaje/graphql/queries/roomba/getRoom';
import getRooms from 'garaje/graphql/queries/roomba/getRooms';
import getRoomsLocationWithConfigs from 'garaje/graphql/queries/roomba/getRoomsLocationWithConfigs';
import getRoomsPaginated from 'garaje/graphql/queries/roomba/getRoomsPaginated';
import getSpaceSaverConfig from 'garaje/graphql/queries/roomba/getSpaceSaverConfig';
import type ApolloService from 'garaje/services/apollo-extension';

export default class GraphqlService extends Service {
  @service declare apolloExtension: ApolloService;
  @service declare state: Service;

  async getCompany(): Promise<GetCompanyQuery['company']> {
    try {
      return await this.apolloExtension.watchQuery<GetCompanyQuery, GetCompanyQueryVariables, 'company'>(
        {
          query: getCompany,
        },
        'company'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql getCompany query. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async getRooms(locationId: GetRoomsQueryVariables['filter']['locationId'] = null): Promise<GetRoomsQuery['rooms']> {
    try {
      return await this.apolloExtension.watchQuery<GetRoomsQuery, GetRoomsQueryVariables, 'rooms'>(
        {
          query: getRooms,
          fetchPolicy: 'network-only',
          variables: {
            filter: {
              ids: null,
              locationId,
            },
          },
        },
        'rooms'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql getRooms query. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async getRoomsPaginated(
    filter: GetRoomsPaginatedQueryVariables['filter'],
    sort: GetRoomsPaginatedQueryVariables['sort'],
    pagination: GetRoomsPaginatedQueryVariables['pagination'] = null
  ): Promise<GetRoomsPaginatedQuery['paginatedRooms']> {
    try {
      return await this.apolloExtension.watchQuery<
        GetRoomsPaginatedQuery,
        GetRoomsPaginatedQueryVariables,
        'paginatedRooms'
      >(
        {
          query: getRoomsPaginated,
          fetchPolicy: 'network-only',
          variables: {
            filter,
            sort,
            pagination,
          },
        },
        'paginatedRooms'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql getRoomsPaginated query. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async getRoom(id: GetRoomQueryVariables['id']): Promise<GetRoomQuery['room']> {
    try {
      return await this.apolloExtension.watchQuery<GetRoomQuery, GetRoomQueryVariables, 'room'>(
        {
          query: getRoom,
          fetchPolicy: 'network-only',
          variables: {
            id,
          },
        },
        'room'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql getRoom query. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async getRoomsLocationWithConfigs(
    id: GetRoomsLocationWithConfigsQueryVariables['id']
  ): Promise<GetRoomsLocationWithConfigsQuery['location']> {
    try {
      return await this.apolloExtension.watchQuery<
        GetRoomsLocationWithConfigsQuery,
        GetRoomsLocationWithConfigsQueryVariables,
        'location'
      >(
        {
          query: getRoomsLocationWithConfigs,
          variables: {
            id,
          },
        },
        'location'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql getRoomsLocationWithConfigs query. Error: ', JSON.stringify(e));
      throw e;
    }
  }
  async getDevicePairingStatus(
    id: GetDevicePairingStatusQueryVariables['id']
  ): Promise<GetDevicePairingStatusQuery['getDevicePairingStatus']> {
    try {
      return await this.apolloExtension.query<
        GetDevicePairingStatusQuery,
        GetDevicePairingStatusQueryVariables,
        'getDevicePairingStatus'
      >(
        {
          query: getDevicePairingStatus,
          fetchPolicy: 'network-only',
          variables: {
            id,
          },
        },
        'getDevicePairingStatus'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql getDevicePairingStatus query. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async linkRoomsWithLocation(
    roomIds: LinkRoomsWithLocationMutationVariables['roomIds'],
    locationId: LinkRoomsWithLocationMutationVariables['locationId']
  ): Promise<LinkRoomsWithLocationMutation['linkRoomsWithLocation']> {
    try {
      return await this.apolloExtension.mutate<
        LinkRoomsWithLocationMutation,
        LinkRoomsWithLocationMutationVariables,
        'linkRoomsWithLocation'
      >(
        {
          mutation: linkRoomsWithLocation,
          refetchQueries: [getRooms],
          variables: {
            roomIds,
            locationId,
          },
        },
        'linkRoomsWithLocation'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql linkRoomsWithLocation mutation. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async synchronizeCompanyRoomList(): Promise<ErrorList> {
    try {
      return await this.apolloExtension.mutate<
        SynchronizeCompanyRoomListMutation,
        SynchronizeCompanyRoomListMutationVariables,
        'synchronizeCompanyRoomList'
      >(
        {
          mutation: synchronizeCompanyRoomList,
          refetchQueries: [getRooms],
        },
        'synchronizeCompanyRoomList'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql synchronizeCompanyRoomList mutation. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async unlinkConnection({
    connectionId,
    shouldDeleteAssociatedRooms,
  }: {
    connectionId: UnlinkConnectionMutationVariables['connectionId'];
    shouldDeleteAssociatedRooms: UnlinkConnectionMutationVariables['shouldDeleteAssociatedRooms'];
  }): Promise<UnlinkConnectionMutation['unlinkConnection']> {
    try {
      return await this.apolloExtension.mutate<
        UnlinkConnectionMutation,
        UnlinkConnectionMutationVariables,
        'unlinkConnection'
      >(
        {
          mutation: unlinkConnection,
          refetchQueries: [getCompany],
          variables: {
            connectionId,
            shouldDeleteAssociatedRooms,
          },
        },
        'unlinkConnection'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql unlinkConnection mutation. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async unpairDevice(id: UnpairDeviceMutationVariables['id']): Promise<UnpairDeviceMutation['unpairDevice']> {
    try {
      return await this.apolloExtension.mutate<UnpairDeviceMutation, UnpairDeviceMutationVariables, 'unpairDevice'>(
        {
          mutation: unpairDevice,
          refetchQueries: [getRoom],
          variables: {
            id,
          },
        },
        'unpairDevice'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql unpairDevice mutation. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async updateCompanyConfig(input = {}): Promise<UpdateCompanyConfigMutation['updateCompanyConfig']> {
    try {
      return await this.apolloExtension.mutate<
        UpdateCompanyConfigMutation,
        UpdateCompanyConfigMutationVariables,
        'updateCompanyConfig'
      >(
        {
          mutation: updateCompanyConfig,
          // When company settings change, the location settings might change as well as they may inherit from the company
          refetchQueries: [getRoomsLocationWithConfigs],
          variables: {
            input,
          },
        },
        'updateCompanyConfig'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql updateCompanyConfig mutation. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async updateLightedCaseType(
    deviceId: UpdateLightedCaseTypeMutationVariables['deviceId'],
    caseType: UpdateLightedCaseTypeMutationVariables['caseType']
  ): Promise<UpdateLightedCaseTypeMutation['updateLightedCaseType']> {
    try {
      return await this.apolloExtension.mutate<
        UpdateLightedCaseTypeMutation,
        UpdateLightedCaseTypeMutationVariables,
        'updateLightedCaseType'
      >(
        {
          mutation: updateLightedCaseType,
          variables: {
            deviceId,
            caseType,
          },
        },
        'updateLightedCaseType'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql updateLightedCaseType mutation. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async updateRoomConfig(input = {}): Promise<UpdateRoomConfigMutation['updateRoomConfig']> {
    try {
      return await this.apolloExtension.mutate<
        UpdateRoomConfigMutation,
        UpdateRoomConfigMutationVariables,
        'updateRoomConfig'
      >(
        {
          mutation: updateRoomConfig,
          variables: {
            input,
          },
        },
        'updateRoomConfig'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql updateRoomConfig mutation. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async checkCalendarPermissions(
    input: CheckCalendarPermissionsMutationVariables
  ): Promise<CheckCalendarPermissionsMutation['checkCalendarPermissions']> {
    try {
      return await this.apolloExtension.mutate<
        CheckCalendarPermissionsMutation,
        CheckCalendarPermissionsMutationVariables,
        'checkCalendarPermissions'
      >(
        {
          mutation: checkCalendarPermissions,
          variables: input,
        },
        'checkCalendarPermissions'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql checkCalendarPermissions mutation. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async updateRoomsLocationShouldEmailGlobalAdmins(
    locationId: UpdateRoomsLocationShouldEmailGlobalAdminsMutationVariables['locationId'],
    shouldEmailGlobalAdmins: UpdateRoomsLocationShouldEmailGlobalAdminsMutationVariables['shouldEmailGlobalAdmins']
  ): Promise<UpdateRoomsLocationShouldEmailGlobalAdminsMutation['updateRoomsLocationShouldEmailGlobalAdmins']> {
    try {
      return await this.apolloExtension.mutate<
        UpdateRoomsLocationShouldEmailGlobalAdminsMutation,
        UpdateRoomsLocationShouldEmailGlobalAdminsMutationVariables,
        'updateRoomsLocationShouldEmailGlobalAdmins'
      >(
        {
          mutation: updateRoomsLocationShouldEmailGlobalAdmins,
          variables: {
            locationId,
            shouldEmailGlobalAdmins,
          },
        },
        'updateRoomsLocationShouldEmailGlobalAdmins'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error(
        'Error calling graphql updateRoomsLocationShouldEmailGlobalAdmins mutation. Error: ',
        JSON.stringify(e)
      );
      throw e;
    }
  }

  async updateRoomsLocationShouldEmailLocationAdmins(
    locationId: UpdateRoomsLocationShouldEmailLocationAdminsMutationVariables['locationId'],
    shouldEmailLocationAdmins: UpdateRoomsLocationShouldEmailLocationAdminsMutationVariables['shouldEmailLocationAdmins']
  ): Promise<UpdateRoomsLocationShouldEmailLocationAdminsMutation['updateRoomsLocationShouldEmailLocationAdmins']> {
    try {
      return await this.apolloExtension.mutate<
        UpdateRoomsLocationShouldEmailLocationAdminsMutation,
        UpdateRoomsLocationShouldEmailLocationAdminsMutationVariables,
        'updateRoomsLocationShouldEmailLocationAdmins'
      >(
        {
          mutation: updateRoomsLocationShouldEmailLocationAdmins,
          variables: {
            locationId,
            shouldEmailLocationAdmins,
          },
        },
        'updateRoomsLocationShouldEmailLocationAdmins'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error(
        'Error calling graphql updateRoomsLocationShouldEmailLocationAdmins mutation. Error: ',
        JSON.stringify(e)
      );
      throw e;
    }
  }

  async updateRoomsLocationNotificationEmails(
    locationId: UpdateRoomsLocationNotificationEmailsMutationVariables['locationId'],
    notificationEmails: UpdateRoomsLocationNotificationEmailsMutationVariables['notificationEmails']
  ): Promise<UpdateRoomsLocationNotificationEmailsMutation['updateRoomsLocationNotificationEmails']> {
    try {
      return await this.apolloExtension.mutate<
        UpdateRoomsLocationNotificationEmailsMutation,
        UpdateRoomsLocationNotificationEmailsMutationVariables,
        'updateRoomsLocationNotificationEmails'
      >(
        {
          mutation: updateRoomsLocationNotificationEmails,
          variables: {
            locationId,
            notificationEmails,
          },
        },
        'updateRoomsLocationNotificationEmails'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql updateRoomsLocationNotificationEmails mutation. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async updateRoomsLocationRoomResizerEnabledOverride(
    locationId: UpdateRoomsLocationRoomResizerEnabledOverrideMutationVariables['locationId'],
    roomResizerEnabledOverride: UpdateRoomsLocationRoomResizerEnabledOverrideMutationVariables['roomResizerEnabledOverride']
  ): Promise<UpdateRoomsLocationRoomResizerEnabledOverrideMutation['updateRoomsLocationRoomResizerEnabledOverride']> {
    try {
      return await this.apolloExtension.mutate<
        UpdateRoomsLocationRoomResizerEnabledOverrideMutation,
        UpdateRoomsLocationRoomResizerEnabledOverrideMutationVariables,
        'updateRoomsLocationRoomResizerEnabledOverride'
      >(
        {
          mutation: updateRoomsLocationRoomResizerEnabledOverride,
          refetchQueries: [getRoomsLocationWithConfigs],
          variables: {
            locationId,
            roomResizerEnabledOverride,
          },
        },
        'updateRoomsLocationRoomResizerEnabledOverride'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error(
        'Error calling graphql updateRoomsLocationRoomResizerEnabledOverride mutation. Error: ',
        JSON.stringify(e)
      );
      throw e;
    }
  }

  async updateRoomsLocationCheckInEnabledOverride(
    locationId: UpdateRoomsLocationRoomCheckInEnabledOverrideMutationVariables['locationId'],
    roomCheckInEnabledOverride: UpdateRoomsLocationRoomCheckInEnabledOverrideMutationVariables['roomCheckInEnabledOverride']
  ): Promise<UpdateRoomsLocationRoomCheckInEnabledOverrideMutation['updateRoomsLocationRoomCheckInEnabledOverride']> {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return await this.apolloExtension.mutate<
        UpdateRoomsLocationRoomCheckInEnabledOverrideMutation,
        UpdateRoomsLocationRoomCheckInEnabledOverrideMutationVariables,
        'updateRoomsLocationRoomCheckInEnabledOverride'
      >(
        {
          mutation: updateRoomsLocationCheckInEnabledOverride,
          refetchQueries: [getRoomsLocationWithConfigs],
          variables: {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            locationId,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            roomCheckInEnabledOverride,
          },
        },
        'updateRoomsLocationRoomCheckInEnabledOverride'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error(
        'Error calling graphql updateRoomsLocationCheckInEnabledOverride mutation. Error: ',
        JSON.stringify(e)
      );
      throw e;
    }
  }

  async updateRoomsLocationOnsiteRoomRecaptureEnabledOverride(
    locationId: UpdateRoomsLocationOnsiteRoomRecaptureEnabledOverrideMutationVariables['locationId'],
    onsiteRoomRecaptureEnabledOverride: UpdateRoomsLocationOnsiteRoomRecaptureEnabledOverrideMutationVariables['onsiteRoomRecaptureEnabledOverride']
  ): Promise<
    UpdateRoomsLocationOnsiteRoomRecaptureEnabledOverrideMutation['updateRoomsLocationOnsiteRoomRecaptureEnabledOverride']
  > {
    try {
      return await this.apolloExtension.mutate<
        UpdateRoomsLocationOnsiteRoomRecaptureEnabledOverrideMutation,
        UpdateRoomsLocationOnsiteRoomRecaptureEnabledOverrideMutationVariables,
        'updateRoomsLocationOnsiteRoomRecaptureEnabledOverride'
      >(
        {
          mutation: updateRoomsLocationOnsiteRoomRecaptureEnabledOverride,
          refetchQueries: [getRoomsLocationWithConfigs],
          variables: {
            locationId,
            onsiteRoomRecaptureEnabledOverride,
          },
        },
        'updateRoomsLocationOnsiteRoomRecaptureEnabledOverride'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error(
        'Error calling graphql updateRoomsLocationOnsiteRoomRecaptureEnabledOverride mutation. Error: ',
        JSON.stringify(e)
      );
      throw e;
    }
  }

  async updateRoomsCompanyOnsiteRoomRecaptureEnabled(
    companyId: UpdateRoomsCompanyOnsiteRoomRecaptureEnabledMutationVariables['companyId'],
    onsiteRoomRecaptureEnabled: UpdateRoomsCompanyOnsiteRoomRecaptureEnabledMutationVariables['onsiteRoomRecaptureEnabled']
  ): Promise<UpdateRoomsCompanyOnsiteRoomRecaptureEnabledMutation['updateRoomsCompanyOnsiteRoomRecaptureEnabled']> {
    try {
      return await this.apolloExtension.mutate<
        UpdateRoomsCompanyOnsiteRoomRecaptureEnabledMutation,
        UpdateRoomsCompanyOnsiteRoomRecaptureEnabledMutationVariables,
        'updateRoomsCompanyOnsiteRoomRecaptureEnabled'
      >(
        {
          mutation: updateRoomsCompanyOnsiteRoomRecaptureEnabled,
          refetchQueries: [getCompany, getRoomsLocationWithConfigs],
          variables: {
            companyId,
            onsiteRoomRecaptureEnabled,
          },
        },
        'updateRoomsCompanyOnsiteRoomRecaptureEnabled'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error(
        'Error calling graphql updateRoomsLocationOnsiteRoomRecaptureEnabledOverride mutation. Error: ',
        JSON.stringify(e)
      );
      throw e;
    }
  }

  async updateRoomsCompanyRoomResizerEnabled(
    companyId: UpdateRoomsCompanyRoomResizerEnabledMutationVariables['companyId'],
    roomResizerEnabled: UpdateRoomsCompanyRoomResizerEnabledMutationVariables['roomResizerEnabled']
  ): Promise<UpdateRoomsCompanyRoomResizerEnabledMutation['updateRoomsCompanyRoomResizerEnabled']> {
    try {
      return await this.apolloExtension.mutate<
        UpdateRoomsCompanyRoomResizerEnabledMutation,
        UpdateRoomsCompanyRoomResizerEnabledMutationVariables,
        'updateRoomsCompanyRoomResizerEnabled'
      >(
        {
          mutation: updateRoomsCompanyRoomResizerEnabled,
          refetchQueries: [getCompany, getRoomsLocationWithConfigs],
          variables: {
            companyId,
            roomResizerEnabled,
          },
        },
        'updateRoomsCompanyRoomResizerEnabled'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error(
        'Error calling graphql updateRoomsLocationOnsiteRoomRecaptureEnabledOverride mutation. Error: ',
        JSON.stringify(e)
      );
      throw e;
    }
  }

  // to be used temporarily for local development only
  async createCompany(): Promise<CreateCompanyMutation['createCompany']> {
    // TODO how do we handle shared services?
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
    const { currentCompany } = this.state as unknown as { currentCompany: { id: any } };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { id: companyId } = currentCompany;

    let pairingCode = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 6; i++) {
      pairingCode += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    try {
      return await this.apolloExtension.mutate<CreateCompanyMutation, CreateCompanyMutationVariables, 'createCompany'>(
        {
          mutation: createCompany,
          variables: {
            input: {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              id: companyId,
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              name: `company ${companyId}`,
              pairingCode,
            },
          },
        },
        'createCompany'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql createCompany mutation. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  // to be used temporarily for local development only
  async createLocation(
    input: CreateLocationMutationVariables['input']
  ): Promise<CreateLocationMutation['createLocation']> {
    try {
      return await this.apolloExtension.mutate<
        CreateLocationMutation,
        CreateLocationMutationVariables,
        'createLocation'
      >(
        {
          mutation: createLocation,
          variables: {
            input,
          },
        },
        'createLocation'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql createLocation mutation. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async approveDeviceUserCode(
    userCode: ApproveDeviceUserCodeMutationVariables['userCode'],
    roomId: ApproveDeviceUserCodeMutationVariables['roomId']
  ): Promise<ApproveDeviceUserCodeMutation['approveDeviceUserCode']> {
    try {
      return await this.apolloExtension.mutate<
        ApproveDeviceUserCodeMutation,
        ApproveDeviceUserCodeMutationVariables,
        'approveDeviceUserCode'
      >(
        {
          mutation: approveDeviceUserCode,
          variables: {
            userCode,
            roomId,
          },
        },
        'approveDeviceUserCode'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql approveDeviceUserCode mutation. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async deleteRoomBackgroundImage(
    roomId: DeleteRoomBackgroundImageMutationVariables['roomId']
  ): Promise<DeleteRoomBackgroundImageMutation['deleteRoomBackgroundImage']> {
    try {
      return await this.apolloExtension.mutate<
        DeleteRoomBackgroundImageMutation,
        DeleteRoomBackgroundImageMutationVariables,
        'deleteRoomBackgroundImage'
      >(
        {
          mutation: deleteRoomBackgroundImage,
          variables: {
            roomId,
          },
        },
        'deleteRoomBackgroundImage'
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql deleteRoomBackgroundImage mutation. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async putSpaceSaverRoomResizerExcludedRooms(
    companyId: string,
    roomIds: string[]
  ): Promise<PutSpaceSaverRoomResizerExcludedRoomsMutation['putSpaceSaverRoomResizerExcludedRooms']> {
    return await this.apolloExtension.mutate<
      PutSpaceSaverRoomResizerExcludedRoomsMutation,
      PutSpaceSaverRoomResizerExcludedRoomsMutationVariables,
      'putSpaceSaverRoomResizerExcludedRooms'
    >(
      {
        mutation: putSpaceSaverRoomResizerExcludedRooms,
        variables: {
          companyId,
          roomIds,
        },
        refetchQueries: [getSpaceSaverConfig],
      },
      'putSpaceSaverRoomResizerExcludedRooms'
    );
  }

  async putSpaceSaverRoomResizerExcludedOrganizers(
    companyId: string,
    organizerEmails: string[]
  ): Promise<PutSpaceSaverRoomResizerExcludedOrganizersMutation['putSpaceSaverRoomResizerExcludedOrganizers']> {
    return await this.apolloExtension.mutate<
      PutSpaceSaverRoomResizerExcludedOrganizersMutation,
      PutSpaceSaverRoomResizerExcludedOrganizersMutationVariables,
      'putSpaceSaverRoomResizerExcludedOrganizers'
    >(
      {
        mutation: putSpaceSaverRoomResizerExcludedOrganizers,
        variables: {
          companyId,
          organizerEmails,
        },
        refetchQueries: [getSpaceSaverConfig],
      },
      'putSpaceSaverRoomResizerExcludedOrganizers'
    );
  }

  async updateLocationRoomsCheckInWindow(
    locationId: string,
    roomsCheckInWindow: number,
    roomsPreCheckInWindow: number
  ): Promise<UpdateLocationRoomsCheckInWindowMutation['updateLocationRoomsCheckInWindow']> {
    return await this.apolloExtension.mutate<
      UpdateLocationRoomsCheckInWindowMutation,
      UpdateLocationRoomsCheckInWindowMutationVariables,
      'updateLocationRoomsCheckInWindow'
    >(
      {
        mutation: updateLocationRoomsCheckInWindow,
        variables: {
          locationId,
          roomsCheckInWindow,
          roomsPreCheckInWindow,
        },
        refetchQueries: [getRoomsLocationWithConfigs],
      },
      'updateLocationRoomsCheckInWindow'
    );
  }

  async putSpaceSaverCheckInExcludedRooms(
    companyId: string,
    roomIds: string[]
  ): Promise<PutSpaceSaverCheckInExcludedRoomsMutation['putSpaceSaverCheckInExcludedRooms']> {
    return await this.apolloExtension.mutate<
      PutSpaceSaverCheckInExcludedRoomsMutation,
      PutSpaceSaverCheckInExcludedRoomsMutationVariables,
      'putSpaceSaverCheckInExcludedRooms'
    >(
      {
        mutation: putSpaceSaverCheckInExcludedRooms,
        variables: {
          companyId,
          roomIds,
        },
        refetchQueries: [getSpaceSaverConfig],
      },
      'putSpaceSaverCheckInExcludedRooms'
    );
  }

  async getSpaceSaverConfig(): Promise<GetSpaceSaverConfigQuery['spaceSaverConfig']> {
    return await this.apolloExtension.watchQuery<
      GetSpaceSaverConfigQuery,
      GetSpaceSaverConfigQueryVariables,
      'spaceSaverConfig'
    >(
      {
        query: getSpaceSaverConfig,
      },
      'spaceSaverConfig'
    );
  }
}
