import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { all, restartableTask } from 'ember-concurrency';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias, and, equal, or, not } from 'macro-decorators';

export default class VisitorsSettingsNotificationsController extends Controller {
  @service abilities;
  @service currentAdmin;
  @service currentLocation;
  @service featureFlags;
  @service flashMessages;
  @service metrics;
  @service store;
  @service statsig;

  queryParams = ['feature'];

  @tracked feature = '';
  @tracked fallbackContactsHasChanges = false;
  @tracked showBlankState;

  @alias('currentLocation.hostNotificationsEnabled') hasNotificationEnabled;
  @not('hasNotificationEnabled') hasNotificationDisabled;
  @and('hasNotificationDisabled', 'showBlankState') hasNoNotifications;
  @equal('employees.meta.total', 0) hasNoEmployees;
  @or('fallbackContactsHasChanges', 'model.hasDirtyAttributes') hasDirtyChanges;
  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isLocationAdmin') isAdmin;

  get locationIsConnectedToProperty() {
    return this.connectedTenants.length > 0;
  }

  get showDeviceStatusNotificationsSection() {
    return (
      this.vrSubscription.canEnableBadgePrinting &&
      (this.abilities.can('update printer notifications') || this.abilities.can('update device notifications'))
    );
  }

  get showSetupGuide() {
    return this.isAdmin && this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper');
  }

  @restartableTask
  *searchUsersTask(term, selectedUsers) {
    const userRoles = yield this.store.query('user-role', {
      filter: { name: term, location: get(this.currentLocation, 'id') },
    });
    let users = yield all(userRoles.map((role) => get(role, 'user')));
    const selectedUserIds = selectedUsers.map((contact) => get(contact, 'id'));
    // deduplicate since roles could point to same user
    // exclude selected users
    users = users.uniqBy('id').filter((user) => {
      return !selectedUserIds.includes(get(user, 'id'));
    });
    return users;
  }

  @action
  enable() {
    const location = get(this.currentLocation, 'location');
    set(location, 'hostNotificationsEnabled', true);
    const promise = location.save();
    promise.then(() => {
      this.showBlankState = false;
    });
  }

  @action
  onUpdateFallbackContacts({ hasChanges }) {
    this.fallbackContactsHasChanges = hasChanges;
  }

  @action
  async rollbackFallbackContacts() {
    // Reset to clean state so confirm dialog is not sticky
    this.fallbackContactsHasChanges = false;
    this.feature = '';
    await this.reloadFallbackContacts(); // set by route
  }

  @action
  rollbackDirtyChanges() {
    if (this.model.hasDirtyAttributes) {
      this.model.rollbackAttributes();
    }
    if (this.fallbackContactsHasChanges) {
      this.send('rollbackFallbackContacts');
    }
  }

  @action
  trackTooltipShown() {
    this.statsig.logEvent('device-notifications-moved-tooltip-seen');
  }

  @action
  trackTooltipLinkClick() {
    this.statsig.logEvent('device-notifications-moved-tooltip-link-clicked');
  }
}
