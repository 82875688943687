import EmberObject from '@ember/object';
import Evented from '@ember/object/evented';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { run } from '@ember/runloop';
import { Promise } from 'rsvp';
import $ from 'jquery';

const EventedEmberObject = EmberObject.extend(Evented);

export default class Uploader extends EventedEmberObject {
  @service session;
  @service cookieAuth;

  url = null;
  method = 'POST';
  paramNamespace = null;
  paramName = 'file';
  @tracked isUploading = false;

  upload(files, extra = {}) {
    const data = this.createFormData(files, extra);
    const { url, method } = this;

    this.isUploading = true;
    return this.ajax(url, data, method);
  }

  createFormData(files, extra = {}) {
    const formData = new FormData();

    for (const prop in extra) {
      if (Object.prototype.hasOwnProperty.call(extra, prop)) {
        formData.append(this.toNamespacedParam(prop), extra[prop]);
      }
    }

    if (files.constructor === FileList || files.constructor === Array) {
      const paramKey = `${this.toNamespacedParam(this.paramName)}[]`;

      for (let i = 0; i < files.length; i++) {
        formData.append(paramKey, files[i]);
      }
    } else {
      formData.append(this.toNamespacedParam(this.paramName), files);
    }

    return formData;
  }

  toNamespacedParam(name) {
    return this.paramNamespace ? `${this.paramNamespace}[${name}]` : name;
  }

  didUpload(data) {
    this.isUploading = false;
    this.trigger('didUpload', data);
    return data;
  }

  didError(jqXHR, textStatus, errorThrown) {
    this.isUploading = false;

    const isObject = jqXHR !== null && typeof jqXHR === 'object';

    if (isObject) {
      jqXHR.then = null;
      if (!jqXHR.errorThrown) {
        if (typeof errorThrown === 'string') {
          jqXHR.errorThrown = new Error(errorThrown);
        } else {
          jqXHR.errorThrown = errorThrown;
        }
      }
    }

    this.trigger('didError', jqXHR, textStatus, errorThrown);

    return jqXHR;
  }

  didProgress(event) {
    event.percent = (event.loaded / event.total) * 100;
    this.trigger('progress', event);
  }

  abort() {
    this.isUploading = false;
    this.trigger('isAborting');
  }

  ajax(url, params = {}, method = this.method) {
    const headers = this.headers || { Accept: 'application/json; charset=utf-8' };

    if (this.session.isAuthenticated) {
      headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;
    }

    const ajaxSettings = Object.assign(
      {},
      {
        contentType: false,
        processData: false,
        headers,
        xhr: () => {
          const xhr = $.ajaxSettings.xhr();
          xhr.upload.onprogress = (e) => {
            this.didProgress(e);
          };
          this.one('isAborting', () => xhr.abort());
          return xhr;
        },
        url,
        data: params,
        method,
      },
      this.ajaxSettings
    );

    return this.ajaxPromise(this.cookieAuth.jQueryDecorator(url, method, ajaxSettings));
  }

  ajaxPromise(settings) {
    return new Promise((resolve, reject) => {
      settings.success = (data) => {
        run(null, resolve, this.didUpload(data));
      };

      settings.error = (jqXHR, responseText, errorThrown) => {
        run(null, reject, this.didError(jqXHR, responseText, errorThrown));
      };

      $.ajax(settings);
    });
  }
}
