import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { dropTask, type TaskInstance } from 'ember-concurrency';
import type PropertyPrinterModel from 'garaje/models/property-printer';
import type TenantModel from 'garaje/models/tenant';
import type ZoneModel from 'garaje/models/zone';
import type ConnectInvitesService from 'garaje/services/connect-invites';
import { type LoadInvitesParams } from 'garaje/services/connect-invites';
import type LocalStorageService from 'garaje/services/local-storage';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

import { CURRENT_PRINTER_KEY } from './controller';

const TENANT_PAGE_SIZE = 50;

export interface InvitesModel {
  currentProperty: ZoneModel;
  loadTenantsTask: TaskInstance<TenantModel[] | null | undefined>;
  loadPrintersTask?: TaskInstance<ArrayProxy<PropertyPrinterModel>>;
  loadDataTask: ConnectInvitesService['loadData'];
  loadDataInForegroundTask: ConnectInvitesService['loadDataInForeground'];
}

export default class PropertyVisitorsInvitesRoute extends Route {
  @service declare connectInvites: ConnectInvitesService;
  @service declare store: Store;
  @service declare localStorage: LocalStorageService;

  queryParams = {
    dateFrom: { refreshModel: true },
    dateTo: { refreshModel: true },
    pageNumber: { refreshModel: true },
    pageSize: { refreshModel: true },
    search: { refreshModel: true },
    sortBy: { refreshModel: true },
    sortDirection: { refreshModel: true },
    status: { refreshModel: true },
    suiteIds: { refreshModel: true },
    tenantIds: { refreshModel: true },
  };

  async model(params: Omit<LoadInvitesParams, 'property'>, transition: Transition): Promise<InvitesModel> {
    const currentProperty = <ZoneModel>this.modelFor('property');

    if (
      !this.connectInvites.loadUiHooks.lastSuccessful ||
      (<{ args: [string] }>(<unknown>this.connectInvites.loadUiHooks.lastSuccessful)).args[0] !== currentProperty.id
    ) {
      // only load list of available hooks once per-property. This avoids reloading the list when
      // performing a search or sort on the page, which refires this model hook.
      await this.connectInvites.loadUiHooks.perform(currentProperty.id);
    }

    // don't `await` this, just kick it off and let it run
    void this.connectInvites.loadDataInForeground.perform({ ...params, property: currentProperty.id });

    if (!currentProperty.badge) await currentProperty.loadBadgeTask.perform().catch(throwUnlessTaskDidCancel);

    return {
      currentProperty,
      loadDataTask: this.connectInvites.loadData,
      loadDataInForegroundTask: this.connectInvites.loadDataInForeground,
      loadTenantsTask: this.loadTenantsTask.perform(currentProperty, transition),
      loadPrintersTask: this.loadPrintersTask.perform(currentProperty),
    };
  }

  async activate(): Promise<void> {
    await this.connectInvites.loadDataInForeground.last?.catch(throwUnlessTaskDidCancel);
    void this.connectInvites.startPolling.perform();
  }

  deactivate(): void {
    this.connectInvites.stopPolling();
  }

  loadTenantsTask = dropTask(
    async (property: ZoneModel, transition: Transition): Promise<TenantModel[] | null | undefined> => {
      // don't bother reloading the tenants list if we've already loaded it for this property
      const propertyNotChanged =
        (<{ args: [ZoneModel] } | undefined>(<unknown>this.loadTenantsTask.lastSuccessful))?.args[0]?.id ===
        property.id;

      // only refresh the tenant list if we're coming from a separate route
      const routeNotChanged = !!transition.from?.name.startsWith(this.routeName) || !transition.from;

      if (propertyNotChanged && routeNotChanged) {
        return this.loadTenantsTask.lastSuccessful?.value;
      }

      let tenants: TenantModel[] = [];
      let offset = 0;
      let sizeChanged = false;
      do {
        const result = await this.store.query('tenant', {
          filter: {
            property: property.id,
          },
          page: {
            limit: TENANT_PAGE_SIZE,
            offset,
          },
          sort: 'name',
        });
        tenants = tenants.concat(result.slice());
        offset += <number>result.length;
        sizeChanged = <number>result.length > 0;

        const totalTenants = (<ArrayProxy<TenantModel> & { meta?: { total: number } }>(<unknown>result)).meta?.total;
        // stop looping if we've loaded as many records as we expect, or the `meta` of the result is bad
        // (The latter case is more common in tests, especially ones that override the `/a/multi-tenancy/api/v1/tenants`
        // endpoint and don't include the pagination stuff.)
        if (!totalTenants || offset >= totalTenants) break;
      } while (sizeChanged); // try to avoid an infinite loop if things get out-of-sync

      return tenants;
    }
  );

  loadPrintersTask = dropTask(async (property: ZoneModel) => {
    const printers = await this.store.query('property-printer', {
      filter: {
        property: property.id,
      },
      include: 'devices',
    });

    if (printers.length) this.setupPrinters(printers);

    return printers;
  });

  setupPrinters(printers: ArrayProxy<PropertyPrinterModel>): void {
    const selectedPrinterId = this.localStorage.getItem(CURRENT_PRINTER_KEY);
    if (!selectedPrinterId) {
      // if no printer has been saved to local storage, save the first one
      this.localStorage.setItem(CURRENT_PRINTER_KEY, this.getFirstPrinter(printers)?.id);
    } else {
      const selectedPrinter = printers.findBy('id', selectedPrinterId);

      // if printer is not found, possibly from other session, select the first one and save to local storage
      if (!selectedPrinter) {
        this.localStorage.setItem(CURRENT_PRINTER_KEY, this.getFirstPrinter(printers)?.id);
      }
    }
  }

  getFirstPrinter(printers: ArrayProxy<PropertyPrinterModel>): PropertyPrinterModel | undefined {
    // prefer active printers if available, otherwise return the first one
    return printers.rejectBy('isInactive', true).sortBy('name').firstObject ?? printers.firstObject;
  }
}
