import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { scheduleOnce } from '@ember/runloop';
import { action, get, set } from '@ember/object';
import urlBuilder from 'garaje/utils/url-builder';
import { loadSrcDoc } from 'garaje/utils/script-loader';
import { task, timeout, dropTask } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { or } from 'macro-decorators';
import zft from 'garaje/utils/zero-for-tests';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';

export default class VisitorsSettingsInvitesController extends Controller {
  @service ajax;
  @service currentAdmin;
  @service flashMessages;
  @service featureFlags;
  @service impressions;
  @service state;
  @service statsig;

  @tracked editEmailVisible = true;
  @tracked previewEmailObject = null;
  @tracked testEmailSent = false;
  @tracked securityDeskLinkCallback;
  @tracked preRegistrationLinkCallback;
  @tracked lastMarkdown;

  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isLocationAdmin') isAdmin;

  get hasAnyFlowsWithIdScanningEnabled() {
    let flows = this.model.allFlowsForCurrentLocation;
    flows = flows && flows.length ? flows.filterBy('enabled', true) : [];
    // eslint-disable-next-line ember/no-get
    flows = flows.filter((vt) => get(vt, 'idScanPage.enabled') === true);
    return flows.length > 0;
  }

  get canShowAddEmployeesTooltip() {
    if (
      this.model.currentLocation.preRegistrationEnabled &&
      this.state?._companyMeta['employees-count'] === 1 &&
      this.featureFlags.isEnabled('growth_employee_directory_empty_state')
    ) {
      return true;
    }
    return false;
  }

  loadSrcDoc() {
    return loadSrcDoc().then((didLoad) => {
      if (didLoad) {
        const [iframe] = document.getElementsByClassName('preRegistrationIframe');

        if (iframe) {
          set(window.srcDoc, iframe, this.previewEmailObject.body);
        }
      }
    });
  }

  get showSetupGuide() {
    return this.isAdmin && this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper');
  }

  @dropTask
  *toggleNearVisitScreeningEnabled(value) {
    set(this.model.currentLocation, 'nearVisitScreeningEnabled', value);
    yield this.saveLocationTask.perform(this.model.currentLocation);
    yield this.impressions.postImpression.perform(
      IMPRESSION_NAMES.VR_INVITES_NEAR_VISIT_REGISTRATION[value ? 'ENABLED' : 'DISABLED']
    );
  }

  @dropTask
  *toggleCCReceptionistEnabled(value) {
    set(this.model.currentLocation, 'ccReceptionistEnabled', value);
    yield this.saveLocationTask.perform(this.model.currentLocation);
    yield this.impressions.postImpression.perform(
      IMPRESSION_NAMES.VR_INVITES_INVITE_NOTIFICATION[value ? 'ENABLED' : 'DISABLED']
    );
  }

  @dropTask
  *togglePreRegistration(shouldEnablePreRegistration) {
    const { currentLocation } = this.model;

    if (shouldEnablePreRegistration) {
      set(currentLocation, 'preRegistrationEnabled', true);
    } else {
      set(currentLocation, 'preRegistrationEnabled', false);
      if (currentLocation.preRegistrationRequiredEnabled) {
        set(currentLocation, 'preRegistrationRequiredEnabled', false);
      }
    }

    yield this.saveLocationTask.perform(currentLocation);
    yield this.impressions.postImpression.perform(
      IMPRESSION_NAMES.VR_INVITES_PRE_REGISTRATION[shouldEnablePreRegistration ? 'ENABLED' : 'DISABLED']
    );
  }

  @dropTask
  *togglePreRegistrationRequiredEnabled(value) {
    set(this.model.currentLocation, 'preRegistrationRequiredEnabled', value);
    yield this.saveLocationTask.perform(this.model.currentLocation);
    yield this.impressions.postImpression.perform(
      IMPRESSION_NAMES.VR_INVITES_PRE_REGISTRATION_REQUIRED[value ? 'ENABLED' : 'DISABLED']
    );
  }

  @dropTask
  *saveLocationTask(location) {
    try {
      yield location.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      location.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @task
  *sendTestEmailTask() {
    const locationId = get(this.model.currentLocation, 'id');
    const url = urlBuilder.v2.sendInvitePreviewUrl(locationId);
    const promise = this.ajax.request(url, {
      type: 'POST',
      data: {
        notes: get(this.model.currentLocation, 'preRegistrationNotes'),
      },
    });
    try {
      yield promise;
    } catch (e) {
      // Response is not expected to be valid JSON, so ignore syntax error
      if (!(e instanceof SyntaxError)) {
        const errorText = parseErrorForDisplay(e);
        this.flashMessages.showAndHideFlash('error', errorText);
      }
    }
    this.flashMessages.showAndHideFlash('success', 'Sent!');
    this.testEmailSent = true;
    yield timeout(zft(2500));
    this.testEmailSent = false;
  }

  @task
  *enableSecurityDeskLink() {
    try {
      const { currentLocation } = this.model;
      set(currentLocation, 'securityDeskLinkEnabled', true);
      yield currentLocation.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      yield this.impressions.postImpression.perform(IMPRESSION_NAMES.VR_INVITES_SECURITY_DESK_LINK['ENABLED']);
    } catch (e) {
      this.flashMessages.showAndHideFlash('warning', 'Error saving!');
    }
  }

  @task
  *disableSecurityDeskLink() {
    try {
      const { currentLocation } = this.model;
      set(currentLocation, 'securityDeskLinkEnabled', false);
      yield currentLocation.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      yield this.impressions.postImpression.perform(IMPRESSION_NAMES.VR_INVITES_SECURITY_DESK_LINK['DISABLED']);
    } catch (e) {
      this.flashMessages.showAndHideFlash('warning', 'Error saving!');
    }
  }

  @action
  preRegistrationNotesUpdated(notes) {
    set(this.model.currentLocation, 'preRegistrationNotes', notes);
  }

  @action
  regeneratePreRegistrationLink() {
    if (
      window.confirm('Are you sure want to regenerate the employee invitation link? Existing link will stop working.')
    ) {
      this.preRegistrationLinkCallback = this.model.currentLocation.generatePreRegistrationLink();
    }
  }

  @action
  regenerateSecurityDeskLink() {
    this.securityDeskLinkCallback = this.model.currentLocation.generateSecurityDeskLink();
  }

  @action
  getEmailPreview() {
    const preRegistrationNotes = get(this.model.currentLocation, 'preRegistrationNotes');

    if (this.lastMarkdown !== preRegistrationNotes) {
      this.lastMarkdown = preRegistrationNotes;
      this.previewEmailObject = null;
    }

    const locationId = get(this.model.currentLocation, 'id');

    this.ajax
      .request(urlBuilder.v2.invitePreviewUrl(locationId), {
        type: 'POST',
        data: { notes: preRegistrationNotes },
      })
      .then((response) => {
        this.previewEmailObject = response;
      })
      .finally(() => {
        scheduleOnce('afterRender', this, 'loadSrcDoc');
      });
  }

  @action
  trackTooltipShown() {
    this.statsig.logEvent('employee-invite-tooltip-seen');
  }

  @action
  trackTooltipLinkClick() {
    this.statsig.logEvent('employee-invite-tooltip-directory-clicked');
  }
}
