import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import lookupValidator from 'ember-changeset-validations';
import type InviteModel from 'garaje/models/invite';
import buildInviteValidations from 'garaje/validations/invite';

interface NewInviteChangesetOptions {
  hostFieldIsRequired?: boolean;
  checkEmailRequired?: boolean;
  validateCapacity?: (key: string, value: unknown) => Promise<boolean>;
}

export default function (invite: InviteModel, options: NewInviteChangesetOptions = {}): DetailedChangeset<InviteModel> {
  const validations = buildInviteValidations(options);
  const validator = lookupValidator(validations);
  return Changeset(invite, validator, validations);
}
