import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class SettingsCapacityRoute extends Route {
  title = 'Protect · Capacity management · Envoy';

  @service abilities;
  @service state;
  @service transitionConfirm;
  @service router;

  get showUpsell() {
    return !this.state.visitorsSubscription.canAccessCapacityLimits;
  }

  buildRouteInfoMetadata() {
    return {
      hideBanners: this.showUpsell,
    };
  }

  beforeModel() {
    if (this.abilities.can('visit workplace')) {
      return this.router.transitionTo('workplace.settings');
    }
    if (this.abilities.cannot('visit capacity for protect')) {
      this.router.transitionTo('protect');
    }
  }

  model() {
    return {
      currentLocation: this.state.currentLocation,
      showUpsell: this.showUpsell,
    };
  }

  @routeEvent
  routeWillChange(transition) {
    const { isDirty, rollback } = this.controller;

    if (isDirty()) {
      this.transitionConfirm.displayConfirmTask.perform(transition, {
        continue() {
          rollback();
        },
      });
    }
    return true;
  }
}
