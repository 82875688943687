import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type { Company } from 'garaje/graphql/generated/roomba-types';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import type RoombaMetricsService from 'garaje/services/roomba-metrics';

export default class TimeDisplaySettings extends Component<{ company: Company }> {
  @service declare flashMessages: FlashMessagesService;
  @service declare roombaGraphql: RoombaGraphqlService;
  @service declare roombaMetrics: RoombaMetricsService;

  @tracked devicesTimeFormat = String(this.args.company.devicesTimeFormat);

  updateTimeFormat = task({ drop: true }, async (value: 'STANDARD' | 'MILITARY') => {
    if (this.devicesTimeFormat === value) {
      return;
    }
    this.roombaMetrics.trackEvent('devices_time_format_update', {
      time_format: value,
      company_id: this.args.company.id,
    });
    const initialTimeFormat = this.devicesTimeFormat;
    this.devicesTimeFormat = value;
    try {
      await this.roombaGraphql.updateCompanyConfig({
        devicesTimeFormat: value,
      });
    } catch (e) {
      this.devicesTimeFormat = initialTimeFormat;
      this.flashMessages.showAndHideFlash('error', 'Error saving company settings');
      return;
    }
    this.flashMessages.showAndHideFlash('success', 'Saved!');
  });
}
