import { Multiselect, SelectOption } from '@envoy/polarwind-react';
import { FieldValues, Path, useController } from 'react-hook-form';

export interface FormMultiselectProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  options: SelectOption[];
  required?: string;
  disabled?: boolean;
  placeholder?: string;
}

export function FormMultiselect<T extends FieldValues>(props: FormMultiselectProps<T>) {
  const {
    field,
    fieldState: { error },
  } = useController({ name: props.name, rules: { required: props.required } });

  return (
    <Multiselect
      label={props.label}
      required={!!props.required}
      disabled={props.disabled}
      options={props.options}
      onChange={(value: string[]) => field.onChange(value)}
      value={field.value}
      error={error?.message}
      placeholder={props.placeholder}
    />
  );
}
