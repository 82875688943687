import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class LegacyNewEmployeeRoute extends Route {
  @service router;

  redirect() {
    this.router.transitionTo('employees.directory.add-employee.new');
  }
}
