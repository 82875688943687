import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type Store from '@ember-data/store';

export default class CommunicationsTemplatesRoute extends Route {
  @service declare store: Store;

  async model(): Promise<void> {
    await this.store.findAll('announcement-template-category', { reload: true });
  }
}
