import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

export default class LocationOverviewRoute extends Route {
  @service abilities;
  @service('locations') locationsService;
  @service('skinnyLocations') skinnyLocationsService;

  model() {
    if (this.abilities.can('view locations-grouping')) {
      return this.skinnyLocationsService.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
    }
    return this.locationsService.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
  }
}
