import Model, { attr, hasMany } from '@ember-data/model';

export default class MailerBlock extends Model {
  // Relationships
  @hasMany('mailer-section-block') mailerSectionBlocks;
  // Attributes
  @attr('array') requiredAttributes;
  @attr('string') title;
  @attr('boolean') deactivatable;
  @attr('boolean') sortable;
  @attr('boolean') editable;
}
