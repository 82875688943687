import type AnnouncementTemplateModel from 'garaje/models/announcement-template';
import type AnnouncementTemplateCategoryModel from 'garaje/models/announcement-template-category';
import { type TemplateViewData } from 'garaje/pods/components/announcement-template/announcement-template-list/component';

/**
 * Formats an array of location objects into a string.
 *
 * @param {Object[]} locationNames - The array of location objects.
 * @param {string} locationNames[].name - The name of the location.
 * @returns {string} A formatted string representing the locations.
 *
 * - Returns 'No locations' if the array is empty.
 * - Returns the number of locations if the first location name exceeds the display limit (15 characters).
 * - Returns a comma-separated list of location names if it fits within the display limit.
 * - Otherwise, returns a truncated list with the count of remaining locations.
 */
export function formatLocations(locationNames: string[]): { locationsToDisplay: string; remainingLocations: string[] } {
  if (locationNames.length === 0) {
    return { locationsToDisplay: 'No locations', remainingLocations: [] };
  }

  const displayLimit = 15;

  if (locationNames[0] && locationNames[0].length > displayLimit) {
    return { locationsToDisplay: '', remainingLocations: locationNames };
  }
  const locationString = locationNames.join(', ');
  if (locationString.length <= displayLimit) {
    return { locationsToDisplay: locationString, remainingLocations: [] };
  }

  let truncatedString = '';
  let count = 0;

  for (let i = 0; i < locationNames.length; i++) {
    const loc = locationNames[i];
    if ((truncatedString + loc).length > displayLimit) {
      break;
    }
    truncatedString += (count > 0 ? ', ' : '') + loc;
    count++;
  }

  const remainingLocations = locationNames.splice(count);
  return { locationsToDisplay: truncatedString, remainingLocations };
}

export function getTemplateViewData(
  template: AnnouncementTemplateModel,
  category: AnnouncementTemplateCategoryModel
): TemplateViewData {
  const { locationsToDisplay, remainingLocations } = formatLocations(template.locationNames);
  const locationIds = template.hasMany('locations').ids();

  return {
    id: template.id,
    name: template.name,
    title: template.title,
    message: template.message,
    defaultChannels: template.defaultChannels,
    defaultEmployeeAudiences: template.defaultEmployeeAudiences,
    defaultVisitorAudiences: template.defaultVisitorAudiences,
    published: template.published,
    markAsSafe: template.markAsSafe,
    createdAt: template.createdAt,
    updatedAt: template.updatedAt,
    categoryName: category ? category.name : 'No category',
    locationsToDisplay,
    remainingLocations,
    locationId: locationIds,
  };
}
