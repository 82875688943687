import { action } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type DetailedChangeset } from 'ember-changeset/types';
import { dropTask } from 'ember-concurrency';
import { pluralize } from 'ember-inflector';
import { type Select } from 'ember-power-select/components/power-select';
import type BulkConnectInviteModel from 'garaje/models/bulk-connect-invite';
import type ConnectInviteModel from 'garaje/models/connect-invite';
import type PropertyPrinterModel from 'garaje/models/property-printer';
import type TenantModel from 'garaje/models/tenant';
import type ZoneModel from 'garaje/models/zone';
import type AjaxService from 'garaje/services/ajax';
import { type PrintOptions } from 'garaje/services/badge-printing';
import type BadgePrintingService from 'garaje/services/badge-printing';
import type ConnectInvitesService from 'garaje/services/connect-invites';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MessageBusService from 'garaje/services/message-bus';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { reads } from 'macro-decorators';

type ConnectInviteChangeset = DetailedChangeset<ConnectInviteModel>;
type BulkConnectInviteChangeset = DetailedChangeset<BulkConnectInviteModel>;

interface EditInviteArgs {
  /**
   * connect invite changeset to edit
   */
  inviteChangeset?: DetailedChangeset<ConnectInviteModel>;
  /**
   * bulk connect invite changeset used to create connect invites
   */
  bulkInviteChangeset?: DetailedChangeset<BulkConnectInviteModel>;
  /**
   * function to call when the invite is saved
   */
  onSave?: (invite: ConnectInviteChangeset | BulkConnectInviteChangeset) => unknown;
  /**
   * function to call when the modal is closed
   */
  onClose?: () => unknown;
  /**
   * list of tenants to select from
   */
  tenants: TenantModel[];
  /**
   * current property
   */
  property: ZoneModel;
  /**
   * selected property printer
   */
  printer?: PropertyPrinterModel;
}

export default class EditInvite extends Component<EditInviteArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare badgePrinting: BadgePrintingService;
  @service declare messageBus: MessageBusService;
  @service declare connectInvites: ConnectInvitesService;
  @service declare ajax: AjaxService;

  @tracked arrivalTime = new Date();

  get isBulk(): boolean {
    return !!this.args.bulkInviteChangeset;
  }

  get invite(): ConnectInviteChangeset | BulkConnectInviteChangeset {
    return this.inviteChangeset ?? this.bulkInviteChangeset;
  }

  @reads('args.inviteChangeset') inviteChangeset!: ConnectInviteChangeset;
  @reads('args.bulkInviteChangeset') bulkInviteChangeset!: BulkConnectInviteChangeset;

  @tracked _printBadge?: boolean;
  @tracked pendingPrintJobs: PrintOptions[] = [];

  get canPrint(): boolean {
    return !!this.args.printer && !!this.isBulk;
  }

  get printBadge(): boolean {
    if (typeof this._printBadge == 'boolean') return this._printBadge;

    const badge = this.args.property.badge;
    return !!badge?.autoPrintEnabled;
  }
  set printBadge(value: boolean) {
    this._printBadge = value;
  }

  get showGroupInput(): boolean {
    return (
      (this.isBulk && this.bulkInviteChangeset?.fullNames.length > 1) ||
      (!this.isBulk && !isEmpty(this.inviteChangeset?.groupName))
    );
  }

  saveTask = dropTask(async (event: SubmitEvent) => {
    event.preventDefault();

    await this.invite.validate();
    if (this.invite.isInvalid) return;

    let secondaryMessage: string | undefined;

    try {
      await this.invite.save();

      if (this.printBadge === true && this.args.printer && this.args.property.badge && this.isBulk) {
        const { printer } = this.args;
        const { badge } = this.args.property;

        const inviteIds = this.bulkInviteChangeset._content.hasMany('connectInvites').ids();
        this.pendingPrintJobs = inviteIds.map((id) => {
          return {
            printer,
            badge,
            visit: { id, type: 'connect-invite' },
          };
        });
        secondaryMessage = `Printing ${pluralize(this.bulkInviteChangeset.fullNames.length, 'badge', {
          withoutCount: true,
        })}...`;
      }

      await this.triggerAndPrintTask.perform();

      this.flashMessages.showAndHideFlash(
        'success',
        this.isBulk
          ? `${pluralize(this.bulkInviteChangeset.fullNames.length, 'Visitor', { withoutCount: true })} added`
          : 'Saved',
        secondaryMessage
      );

      this.args.onSave?.(this.invite);
      this.pendingPrintJobs = [];
      this.onClose();
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  @action
  setup(): void {
    if (this.invite) {
      this.arrivalTime = this.invite.checkedInAt ?? this.invite.expectedArrivalTime;
    }
  }

  @action
  onClose(): void {
    this.args.onClose?.();
  }

  @action
  onCreateFullName(name: string, { searchText }: Select): void {
    if (!searchText.trim()) return;
    this.#addFullName(name);
  }

  @action
  onInputFullNames(value: string, { actions }: Select): void {
    const splitName = value.split(',');

    if (splitName.length > 1) {
      if (splitName[0]?.trim()) this.#addFullName(splitName[0]);
      scheduleOnce('actions', actions, 'search', '');
    }
  }

  @action
  onBlurFullNames({ searchText }: Select, event: FocusEvent): void {
    // do not add item on blur if the user is still focused on the input. Happens when switching between browser tabs
    const stillFocused = event.target === document.activeElement;
    if (searchText.trim() && !stillFocused) this.#addFullName(searchText);
  }

  @action
  onChangeFullNames(fullNames: string[]): void {
    this.bulkInviteChangeset.fullNames = fullNames;
    if (this.bulkInviteChangeset.fullNames.length <= 1) this.bulkInviteChangeset.groupName = null;
  }

  @action
  setDateTimes(dateTime: Date): void {
    this.arrivalTime = dateTime;
    this.invite.checkedInAt = this.arrivalTime;
    this.invite.expectedArrivalTime = this.arrivalTime;
  }

  @action
  onKeydownFullNames({ searchText }: Select, event: KeyboardEvent): void {
    if (event.key === 'Tab' && searchText.trim()) {
      this.#addFullName(searchText);
    }
  }

  triggerAndPrintTask = dropTask(async (): Promise<void> => {
    if (this.isBulk) {
      await this.connectInvites.triggerCheckinHooks(
        this.bulkInviteChangeset._content.hasMany('connectInvites').ids(),
        this.args.property
      );
    }

    if (this.pendingPrintJobs.length) {
      await this.badgePrinting.print(this.pendingPrintJobs);
    }
  });

  #addFullName(name: string) {
    const fullNames = [...this.bulkInviteChangeset.fullNames];
    fullNames.push(name);
    this.bulkInviteChangeset.fullNames = fullNames;
  }
}
