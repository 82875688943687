import Service, { service } from '@ember/service';
import { camelize } from '@ember/string';
import { type AjaxServiceClass } from 'ember-ajax/services/ajax';
import { enqueueTask } from 'ember-concurrency';
import config from 'garaje/config/environment';
import urlBuilder from 'garaje/utils/url-builder';
import type { SingleResponse } from 'jsonapi/response';

interface FetchChannelsParams {
  locationId?: string;
  userId: string;
  zoneId?: string;
}

interface FetchChannelsAPIParams {
  location_id?: string;
  user_id: string;
  zone_id?: string;
}

interface PubnubChannelAttributes {
  'channel-devices': string;
  'channel-entry': string;
  'channel-integrations': string;
  'channel-invite': string;
  'cipher-key': string;
  'channel-connect-invites': string;
}

export interface PubnubChannel {
  channelDevices: string;
  channelEntry: string;
  channelIntegrations: string;
  channelInvite: string;
  cipherKey: string;
  channelConnectInvites: string;
}

export class PubnubInstance {
  #pubnub: Pubnub | undefined;

  constructor(cipherKey: string) {
    const mirageEnabledInDev = config.environment === 'development' && config.mirageEnabled;
    if (mirageEnabledInDev) return;

    if (!config.subscribeKey) {
      if (config.environment !== 'test') {
        console.warn('PubnubService: missing ENV.subscribeKey; not initializing pubnub service'); // eslint-disable-line no-console
      }
      return;
    }
    this.#pubnub = window.PUBNUB.init({
      subscribe_key: config.subscribeKey,
      cipher_key: cipherKey,
      origin: 'pubsub.pubnub.com',
      ssl: true,
    });
  }

  subscribe(...args: Parameters<Pubnub['subscribe']>): void {
    this.#pubnub?.subscribe(...args);
  }

  unsubscribe(...args: Parameters<Pubnub['unsubscribe']>): void {
    this.#pubnub?.unsubscribe(...args);
  }
}

export default class PubnubService extends Service {
  @service declare ajax: AjaxServiceClass;

  #instances = new Map<string, PubnubInstance>();
  #instanceNamesToKeys = new Map<string, string>();

  fetchChannels = enqueueTask(async ({ locationId, userId, zoneId }: FetchChannelsParams) => {
    const params: FetchChannelsAPIParams = {
      user_id: userId,
    };
    if (locationId) {
      params.location_id = locationId;
    }
    if (zoneId) {
      params.zone_id = zoneId;
    }
    const { data } = await this.ajax.request<SingleResponse<PubnubChannelAttributes>>(urlBuilder.v2.pubnubChannels(), {
      data: params,
    });
    const config: Partial<PubnubChannel> = {};

    // Fix bugsnag error: undefined is not an object (evaluating 'Object.keys(s.attributes)')
    const attributes = data?.attributes ?? {};
    Object.keys(attributes).forEach(function (key) {
      config[<keyof PubnubChannel>camelize(key)] = data.attributes[<keyof PubnubChannelAttributes>key];
    });

    return <PubnubChannel>config;
  });

  initialize(cipherKey: string, instanceName: string): PubnubInstance {
    if (this.#instances.has(cipherKey)) {
      return <PubnubInstance>this.#instances.get(cipherKey); // eslint-disable-line ember/use-ember-get-and-set
    }

    const pubnubInstance = new PubnubInstance(cipherKey);
    this.#instances.set(cipherKey, pubnubInstance); // eslint-disable-line ember/use-ember-get-and-set
    if (instanceName) {
      this.#instanceNamesToKeys.set(instanceName, cipherKey); // eslint-disable-line ember/use-ember-get-and-set
    }

    return pubnubInstance;
  }

  instanceFor(name: string): PubnubInstance | null | undefined {
    if (!this.#instanceNamesToKeys.has(name)) {
      return null;
    }
    const cipherKey = this.#instanceNamesToKeys.get(name); // eslint-disable-line ember/use-ember-get-and-set
    return this.#instances.get(<string>cipherKey); // eslint-disable-line ember/use-ember-get-and-set
  }
}
