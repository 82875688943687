import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';

// NOTE: this file is extended by a vfd settings route
export default class SettingsWelcomeScreenMultipleLanguagesRoute extends Route {
  @service state;

  titleToken = 'Multiple languages';

  model() {
    const location = this.modelFor('current-location');
    const { ipads } = this.modelFor('visitors.settings');
    return hash({ location, ipads });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.isEditing = false;
      controller.isShowingAddedMessage = false;
    }
  }
}
