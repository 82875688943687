import Component from '@glimmer/component';
import { action } from '@ember/object';
import { subMinutes, fromUnixTime } from 'date-fns';
import { format } from 'date-fns-tz';
import { inject as service } from '@ember/service';

/**
 * @param {Feature}        feature
 * @param {Array}          desks
 * @param {Array}          reservations
 * @param {Array}          employees   
 * @param {Date}           selectedDate

 */
export default class DeskPopup extends Component {
  @service state;
  @service store;

  get selectedDateFormatted() {
    const { selectedDate } = this.args;
    return format(subMinutes(selectedDate, this.state.minutesBetweenTimezones), 'MMMM dd, yyyy');
  }

  @action
  getReservationByDeskId(deskId) {
    return this.args.reservations.find((res) => res.belongsTo('desk').id() == deskId);
  }

  @action
  getDeskById(deskId) {
    return this.store.peekRecord('desk', deskId);
  }

  @action
  getEmployeeByEmail(email) {
    const employees = this.store.peekAll('employee');
    return employees.find((employee) => employee.email === email);
  }

  @action
  partialDayFormat(reservation) {
    const startTime = format(
      subMinutes(fromUnixTime(reservation.startTime), this.state.minutesBetweenTimezones),
      'h:mma'
    );
    const endTime = format(subMinutes(fromUnixTime(reservation.endTime), this.state.minutesBetweenTimezones), 'h:mma');
    return `${startTime} - ${endTime}`;
  }

  @action
  showScheduleSharingDisabled(employee, desk) {
    return (
      !(desk.assignedTo === employee.email && employee.belongsTo('user').id() === this.state.currentUser.id) &&
      !(this.args.currentUserReservation?.belongsTo('user').id() === employee.belongsTo('user').id()) &&
      (employee.hideFromEmployeeSchedule || !this.state.currentLocation.employeeScheduleEnabled)
    );
  }
}
