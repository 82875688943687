import Component from '@glimmer/component';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { equal } from '@ember/object/computed';

/**
 * @param {Array<EstimateLineItem>}           addons
 * @param {Boolean}                           isLoading
 * @param {String}                            locationType
 * @param {String}                            period
 * @param {Class<Plan>}                       plan
 * @param {Number}                            planUnitAmount
 * @param {Number}                            planDiscountAmount
 * @param {String}                            product
 * @param {Number}                            quantity
 */
export default class OrderConfirmationProduct extends Component {
  @equal('args.period', 'monthly') periodIsMonthly;
  @equal('args.product', 'desks') isDesks;
  @equal('args.product', 'empxp') isWorkplace;

  get iconClass() {
    let icon = 'icon-vrPlan';

    switch (this.args.product) {
      case 'deliveries':
        icon = 'icon-deliveriesPlan';
        break;
      case 'desks':
        icon = 'icon-desksPlan';
        break;
      case 'empxp':
        icon = 'icon-workplacePlan';
        break;
    }
    return icon;
  }

  get planName() {
    if (this.args.product === 'deliveries') {
      return 'Premium';
    }

    if (this.args.product === 'desks') {
      return 'Standard';
    }

    return this.args.plan.name;
  }

  get yearlyTotalCostInCents() {
    if (this.args.period === 'monthly') {
      return this.args.quantity * this.args.planUnitAmount * 12 - this.args.planDiscountAmount;
    } else {
      return this.args.quantity * this.args.planUnitAmount - this.args.planDiscountAmount;
    }
  }

  get monthlyUnitCostInCents() {
    if (this.args.period === 'yearly') {
      return this.args.planUnitAmount / 12;
    } else {
      return this.args.planUnitAmount;
    }
  }

  get minSeatOrderValue() {
    // Workplace self serve has a 25 seat minimum for purchase or upgrade
    if (this.isWorkplace) {
      return 25;
    }
    return 1;
  }
}
