/* eslint-disable ember/no-get */
import { A } from '@ember/array';
import { get } from '@ember/object';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';
import { copy } from 'ember-copy';
import type LocationModel from 'garaje/models/location';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import type ZoneModel from 'garaje/models/zone';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import { reads } from 'macro-decorators';

const OTHER_KEY = 'other';

const filterByName = (name: string, searchText: string) => {
  return new RegExp(searchText, 'i').test(name);
};

interface LocationGroup {
  /**
   * array of active locations
   */
  active?: SkinnyLocationModel[];
  /**
   * array of active locations
   */
  inactive?: SkinnyLocationModel[];

  /**
   * property if it exists
   */
  property?: ZoneModel;
  /**
   * property name if it exists
   */
  name?: string;
  /**
   * selected location if it exists
   */
  selectedLocation?: SkinnyLocationModel;
}

interface PropertiesWithLocationsComponentArgs {
  /**
   * Triggers the basic dropdown to close
   */
  closeMenu: () => void;
  /**
   * Currently selected location
   */
  currentLocation?: LocationModel;
  /**
   * Currently selected Zone
   */
  currentZone: ZoneModel | null;

  /**
   * Array of locations to show in a list
   */
  locations?: SkinnyLocationModel[];
  /**
   * Value to filter down list by
   */
  searchText: string;
  /**
   * Action to transition to location
   */
  switchLocationFromMenu: (location: SkinnyLocationModel) => void;
  /**
   * Action to transition to zone
   */
  switchZoneFromMenu: (zone: ZoneModel) => void;
  /**
   * Array of zones to show in a list
   */
  zones: ZoneModel[];
}

export default class PropertiesWithLocationsComponent extends Component<PropertiesWithLocationsComponentArgs> {
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;

  @reads('currentAdmin.isGlobalAdmin') isGlobalAdmin!: CurrentAdminService['isGlobalAdmin'];

  /**
   * Zone that should be rendered first whether the zone is selected or a location below it is selected
   */
  get selectedZone(): ZoneModel | undefined {
    const { currentLocation, currentZone } = this.args;
    return this.args.zones?.find(
      (zone) => zone.id === currentZone?.id || zone.id === get(currentLocation ?? {}, 'property.id')
    );
  }

  /**
   *  Groups locations by property ID.
   *
   * @returns  Object literal containing all locations grouped by their property (zone) id or the "other" category.
   */
  get locationsByZoneId(): Record<string, LocationGroup> {
    return (this.args.locations ?? []).reduce<Record<string, LocationGroup>>((hash, location) => {
      const key = <string | undefined>get(location, 'property.id') ?? OTHER_KEY;
      if (!hash[key]) {
        const name = <string | undefined>get(location, 'property.name');
        hash[key] = { active: [], inactive: [], property: location.property, name };
      }
      hash[key]?.[isBlank(location.disabledToEmployeesAt) ? 'active' : 'inactive']?.push(location);
      return hash;
    }, {});
  }

  /**
   * array of grouped locations, sorted and filtered
   */
  get displayLocations(): LocationGroup[] {
    const { zones, searchText } = this.args;
    const { selectedZone } = this;
    const locationsByZoneId = copy(this.locationsByZoneId);
    const otherGroup = locationsByZoneId[OTHER_KEY];
    let displayLocations: LocationGroup[] = [];
    let currentGroup: LocationGroup | undefined;

    zones?.forEach((property) => {
      const group: LocationGroup = locationsByZoneId[property.id] ?? {
        property,
        name: property.name,
      };

      this.sortAndFilterLocationsForGroup(group);
      if (get(group.property, 'id') === selectedZone?.id || group.selectedLocation) {
        currentGroup = group;
      } else {
        if (filterByName(property.name, searchText) || group.active?.length) displayLocations.push(group);
      }
    });

    if (otherGroup) this.sortAndFilterLocationsForGroup(otherGroup);

    // sort properties by name
    displayLocations = A(displayLocations).sortBy('name');
    // add selected property as first node or the other group if a location is selected in that grouping
    if (currentGroup || otherGroup?.selectedLocation) displayLocations.unshift((currentGroup ?? otherGroup)!);
    // add grouping of "other" locations as last node unless a location is selected from this group
    if (otherGroup && !otherGroup.selectedLocation && (otherGroup.active?.length || otherGroup.inactive?.length))
      displayLocations.push(otherGroup);

    displayLocations = displayLocations.filter((location) => this.showLocationToEmployees(location));

    return displayLocations;
  }

  showLocationToEmployees(location: LocationGroup): boolean {
    // @ts-ignore
    // TODO these are fabricated groups not locations. This will not work.
    return isBlank(location.disabledToEmployeesAt) || this.isGlobalAdmin;
  }

  /**
   *  filters and sorts groups based on the current searchText and currentLocation
   *
   * @param group - group to mutate
   */
  sortAndFilterLocationsForGroup(group: LocationGroup): void {
    const currentLocationId = this.args.currentLocation?.id;
    const sortAndFilter = (locations?: SkinnyLocationModel[]) => {
      return A(
        locations?.filter((location) => {
          if (location.id === currentLocationId) {
            group.selectedLocation = location;
            return location;
          }

          return filterByName(
            get(location, 'property.id') ? location.name : location.nameWithCompanyName,
            this.args.searchText
          );
        })
      ).sortBy('name');
    };

    group.active = sortAndFilter(group.active);
    group.inactive = sortAndFilter(group.inactive);
  }
}
