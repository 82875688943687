import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

export default class extends Route {
  @service skinnyLocations;
  @service store;

  async model(params) {
    const include = [
      'agreement-page',
      'agreement-page.agreements',
      'badge',
      'id-scan-page',
      'locations',
      'photo-page',
      'sign-in-field-page',
      'sign-in-field-page.sign-in-fields',
      'sign-in-field-page.sign-in-fields.sign-in-field-actions',
      'summary-page',
      'visitor-document-contacts',
      'visual-compliance-configuration',
    ].join(',');

    const globalFlow = await this.store.findRecord('global-flow', params.global_flow_id, { include, reload: true });

    // skinnyLocations must be loaded before computing `globalFlow.translationEnabledLocales`
    // which is referenced everywhere we manage translations on global flows
    this.skinnyLocations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);

    return { globalFlow };
  }
}
