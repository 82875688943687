import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { get } from '@ember/object';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

export default class AnalyticsOccupancyLocationReportsIndexRoute extends Route {
  title = 'Occupancy · Analytics · Envoy';

  @service abilities;
  @service router;
  @service state;
  @service workplaceMetrics;

  queryParams = {
    location_id: { refreshModel: true },
  };

  setupController() {
    super.setupController(...arguments);
  }

  async activate() {
    super.activate(...arguments);
    const { location_id } = this.paramsFor(this.routeName);
    const protectedController = this.controllerFor('protected');
    if (location_id && this.state.currentLocation.id != location_id) {
      protectedController.send('switchLocation', location_id, 'analytics.reports.index');
      //if the location_id does not match with an active location that the user has access to, we redirect them to unauthorized - try again tests
      await this.locations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
      const matchingLocation = get(this.locations, 'allActive').filter((entry) => entry.id === location_id);
      if (matchingLocation.length == 0) {
        this.router.transitionTo('unauthorized');
      }
    }
  }

  redirect() {
    const cannotUserVisitOccupancyReportAnalytic = this.abilities.cannot('visit occupancy-location-report');
    if (cannotUserVisitOccupancyReportAnalytic) {
      this.router.transitionTo('unauthorized');
    }
  }

  deactivate() {
    super.deactivate();
    const { location_id } = this.paramsFor(this.routeName);
    if (location_id) {
      this.transitionTo({ queryParams: { location_id: null } });
    }
  }
}
