import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 * @param {Object}                      changeset
 * @param {Boolean}                     disabled
 * @param {Class<Agreement>}            model
 * @param {Boolean}                     propagable
 * @param {Boolean}                     editingEmail
 * @param {Function}                    trackLegalDocument
 * @param {Function}                    updateAndSaveTask
 * @param {Function}                    updateEditingEmail
 */
export default class SkipSigning extends Component {
  @action
  toggleOptional(value) {
    this.args.updateAndSaveTask
      .perform(this.args.model, 'optional', value, this.args.propagable)
      .then(() => this.args.trackLegalDocument());
  }
}
