/* eslint-disable ember/no-computed-properties-in-native-classes */
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import Service, { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import type StoreService from '@ember-data/store';
import Ember from 'ember';
import type SyncBatchModel from 'garaje/models/sync-batch';
import type CurrentLocationService from 'garaje/services/current-location';
import type { RecordArray } from 'garaje/utils/type-utils';

import type FlashMessagesService from './flash-messages';

const { escapeExpression } = (<typeof Handlebars>(<unknown>Ember.Handlebars)).Utils;

export default class SyncingService extends Service {
  @service declare store: StoreService;
  @service declare currentLocation: CurrentLocationService;
  @service declare flashMessages: FlashMessagesService;

  /*
   * This method needs to be called to initilize async data
   */
  loadSyncingHistory(): Promise<void> {
    const syncBatches = this.syncBatches;
    const flashMessages = this.flashMessages;

    return syncBatches.then(function (syncBatches) {
      if (!syncBatches.firstObject) return;

      const lastSyncBatchStatus = syncBatches.firstObject.status;
      const lastSyncBatchProvider = syncBatches.firstObject.providerName;
      const lastSyncBatchMessage = escapeExpression(syncBatches.firstObject.message);
      if (lastSyncBatchStatus === 'failed') {
        flashMessages.showFlash(
          'error',
          htmlSafe(
            `There was an issue with syncing ${lastSyncBatchProvider}${
              (lastSyncBatchMessage && '<br>') || ''
            }${lastSyncBatchMessage}`
          )
        );
      }
    });
  }

  @computed('currentLocation.{id,location.id}')
  get syncBatches(): Promise<RecordArray<SyncBatchModel>> {
    const locationId = this.currentLocation.location?.id;
    return this.store.query('sync-batch', { filter: { location: locationId } });
  }

  @alias('syncBatches.firstObject') lastSyncBatch?: SyncBatchModel;
}
