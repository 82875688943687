import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { and, notEmpty } from 'macro-decorators';

/**
 * @param {Function}                  onChange
 */
export default class PaymentMethod extends Component {
  @service stripev3;

  @tracked nameOnCard = '';
  @tracked stripeError = null;
  @tracked stripeComplete = false;
  @tracked stripeElement = null;
  @tracked isStripeCardFocused = false;
  @tracked elements;

  options = {
    style: {
      base: {
        fontFamily: '"Cera Pro", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontVariant: 'lining-nums',
        fontSize: '16px',
        '::placeholder': {
          color: '#c9cbd4', // colors.carbon.20
        },
      },
    },
  };

  @notEmpty('nameOnCard') hasName;
  @and('hasName', 'stripeComplete') isPaymentMethodComplete;

  constructor() {
    super(...arguments);
    this.loadStripeTask.perform();
  }

  @action
  onInsert(ccNameElement) {
    return ccNameElement.focus();
  }

  @task
  *loadStripeTask() {
    yield this.stripev3.load();
    const configuredElements = this.stripev3.elements({
      fonts: [
        {
          family: 'Cera Pro',
          src: `url('https://envoy-fonts.s3.amazonaws.com/cera-pro/316471_9_0.eot?#iefix') format('embedded-opentype'),
                url('https://envoy-fonts.s3.amazonaws.com/cera-pro/316471_9_0.woff2') format('woff2'),
                url('https://envoy-fonts.s3.amazonaws.com/cera-pro/316471_9_0.woff') format('woff'),
                url('https://envoy-fonts.s3.amazonaws.com/cera-pro/316471_9_0.ttf') format('truetype')`,
        },
      ],
    });
    this.elements = configuredElements;
  }

  @action
  handleChange() {
    const complete = this.isPaymentMethodComplete;
    if (typeof this.args.onChange === 'function') {
      this.args.onChange({
        complete,
        createStripeToken: () => {
          if (complete) {
            return this.stripev3.createToken(this.stripeElement, { name: this.nameOnCard });
          } else {
            throw new Error(
              'You called createStripeToken before the payment method fields were completed. Only call createStripeToken when the `complete` property is true'
            );
          }
        },
      });
    }
  }

  @action
  handleNameOnCardChange(name) {
    this.nameOnCard = name;
    this.handleChange();
  }

  @action
  handleStripeChange(stripeElement, { error, complete }) {
    this.stripeError = error;
    this.stripeComplete = complete;
    this.stripeElement = stripeElement;
    this.handleChange();
  }
}
