import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { get, set } from '@ember/object';
import updateAndSaveTask from 'garaje/utils/decorators/update-and-save-task';
import { dropTask } from 'ember-concurrency';

@updateAndSaveTask
class VisitorsSettingsVisitorTypesFlowVisitorPhotosController extends Controller {
  @service featureFlags;
  @service currentAdmin;

  @dropTask
  *updateFeature(photoPage, value) {
    yield this.updateAndSaveTask.perform(photoPage, 'enabled', value);

    // if user turns off photos, badge showPhoto is set to false.
    if (!value) {
      const { badge } = this.model;

      if (get(badge, 'showPhoto')) {
        set(badge, 'showPhoto', false);
        yield badge.save();
      }
    }
  }
}

export default VisitorsSettingsVisitorTypesFlowVisitorPhotosController;
