import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';

export default class VisitorTypesFlowRoute extends Route {
  @service abilities;
  @service('global-setting-batch') globalSettingBatchService;
  @service state;
  @service router;
  @service store;

  model(params) {
    const { vrSubscription, currentLocation } = this.state;
    const allFlowsForCurrentLocation = this.state.loadFlows({ reload: false, locationId: currentLocation.id });
    let include = 'sign-in-field-page,agreement-page,agreement-page.agreements,badge,photo-page,summary-page';
    if (this.abilities.can('configure data privacy settings')) {
      include += ',visitor-document-contacts,invite-approval-contacts';
    }

    const flow = this.store.findRecord('flow', params.flow_id, {
      include,
      reload: true,
    });

    return hash({
      allFlowsForCurrentLocation,
      currentLocation,
      flow,
      vrSubscription,
    });
  }

  afterModel({ currentLocation, flow }, _transition) {
    if (this.abilities.can('propagate global-setting-batch') && !flow.isGlobalChild) {
      this.globalSettingBatchService.createGlobalSettingBatch({ parent: flow, signInFields: [] });
    }

    // if we change location, we want to make sure that the flow the
    // user is looking at, belongs to "currentLocation".
    if (flow.belongsTo('location').id() !== get(currentLocation, 'id')) {
      return this.router.transitionTo('visitors.settings.visitor-types.index');
    }
  }
}
