import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StateService from 'garaje/services/state';

export default class LocationOverviewVirtualFrontDeskRoute extends Route {
  @service declare router: RouterService;
  @service declare state: StateService;

  redirect(): void {
    if (
      !this.state.visitorsSubscription?.canAccessVirtualFrontDesk &&
      !this.state.vfdSubscription?.canAccessVirtualFrontDesk
    ) {
      void this.router.transitionTo('location-overview.virtual-front-desk.landing-page');
    } else {
      void this.router.transitionTo('location-overview.virtual-front-desk.locations');
    }
  }
}
