import { isArray } from '@ember/array';
import ActiveModelAdapter from 'active-model-adapter';
import config from 'garaje/config/environment';
import { inject as service } from '@ember/service';

export default class extends ActiveModelAdapter {
  @service cookieAuth;
  @service session;

  namespace = 'api/v1';
  host = config.apiHost;
  useFetch = false;

  handleResponse(status, _headers, _payload, _requestData) {
    if (status === 401 && this.session.isAuthenticated) {
      this.session.invalidate();
    }
    return super.handleResponse(...arguments);
  }

  // This method is used by the store to determine if the store should
  //  reload all records from the adapter when records are requested by
  //  `store.findAll`.
  //
  //
  shouldReloadAll() {
    return true;
  }

  //
  // 08-21-2015 | Adolfo Builes
  // Keep default behavior for now, once we are in ED 2.0 we can
  // re-evaluate if we want the new one
  //
  shouldBackgroundReloadRecord() {
    return false;
  }

  //
  // This is a workaround  because the following PR
  // https://github.com/emberjs/data/pull/3194/files
  // changed the way in which errors are represented internally.
  // So we need to put them in json-api format.
  //
  parseErrorResponse(responseText) {
    let errors = super.parseErrorResponse(responseText);

    if (errors) {
      if (isArray(errors.error)) {
        // error is an array, join them
        errors = {
          errors: errors.error,
        };
      } else if (typeof errors.error === 'string') {
        errors = {
          errors: [errors.error],
        };
      }
    }

    return errors;
  }

  ajaxOptions(url, type, options = {}) {
    let hash = super.ajaxOptions(url, type, options);
    hash = this.cookieAuth.decorate(url, type, hash);
    return hash;
  }

  ajax(url, method, hash = {}) {
    hash.xhrFields = { withCredentials: true };
    return super.ajax(url, method, hash);
  }
}
