import Component from '@glimmer/component';
import type EmployeeModel from 'garaje/models/employee';

interface ManagerInputArgs {
  disabled?: boolean;
  manager: EmployeeModel;
  update: (manager: EmployeeModel) => void;
  searchEmployees: (params: unknown) => unknown;
}

export default class ManagerInput extends Component<ManagerInputArgs> {}
