/* eslint-disable ember/no-computed-properties-in-native-classes */
import { getOwner } from '@ember/application';
import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
import { computed, get, set } from '@ember/object';
import { alias, oneWay, readOnly, gt, or, reads } from '@ember/object/computed';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import { service } from '@ember/service';
import { camelize } from '@ember/string';
import { isBlank, isEmpty, isPresent } from '@ember/utils';
import { hasMany, belongsTo, attr } from '@ember-data/model';
import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import type Model from '@ember-data/model';
import type StoreService from '@ember-data/store';
import { memberAction } from 'ember-api-actions';
import type { EmberDataRequestType } from 'ember-api-actions/utils/types';
import type AgreementPageModel from 'garaje/models/agreement-page';
import type AnnouncementModel from 'garaje/models/announcement';
import type BadgeModel from 'garaje/models/badge';
import type CompanyModel from 'garaje/models/company';
import type ConfigModel from 'garaje/models/config';
import type ConnectLocationConfigurationModel from 'garaje/models/connect-location-configuration';
import type DeliveryAreaModel from 'garaje/models/delivery-area';
import type DeviceModel from 'garaje/models/device';
import type EmergencyNotificationConfigurationModel from 'garaje/models/emergency-notification-configuration';
import type EmployeeScreeningFlowModel from 'garaje/models/employee-screening-flow';
import type EntryModel from 'garaje/models/entry';
import type FloorModel from 'garaje/models/floor';
import type FlowModel from 'garaje/models/flow';
import type GdprConfigurationModel from 'garaje/models/gdpr-configuration';
import type GroupModel from 'garaje/models/group';
import type GroupInviteModel from 'garaje/models/group-invite';
import type InviteModel from 'garaje/models/invite';
import type LocationRoleModel from 'garaje/models/location-role';
import type LocationSubscriptionModel from 'garaje/models/location-subscription';
import type LocationsSetupGuideStep from 'garaje/models/locations-setup-guide-step';
import type MailerTemplateVersionModel from 'garaje/models/mailer-template-version';
import NotificationMessage from 'garaje/models/notification-message';
import type PrinterModel from 'garaje/models/printer';
import PropagableModel from 'garaje/models/propagable';
import type ReservationModel from 'garaje/models/reservation';
import type SamlModel from 'garaje/models/saml';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import type TenantModel from 'garaje/models/tenant';
import type TicketCategoryModel from 'garaje/models/ticket-category';
import type TicketConfigurationModel from 'garaje/models/ticket-configuration';
import type UserModel from 'garaje/models/user';
import UserDatum from 'garaje/models/user-datum';
import type VfdConfigurationModel from 'garaje/models/vfd-configuration';
import type VisitorGuideModel from 'garaje/models/visitor-guide';
import type VisitorSurveyConfigurationModel from 'garaje/models/visitor-survey-configuration';
import type VisualComplianceConfigurationModel from 'garaje/models/visual-compliance-configuration';
import type WebhookModel from 'garaje/models/webhook';
import type ZoneModel from 'garaje/models/zone';
import type AmsSerializer from 'garaje/serializers/ams';
import type AjaxService from 'garaje/services/ajax';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type MetricsService from 'garaje/services/metrics';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import type StateService from 'garaje/services/state';
import { FlowType, APP, NON_ASSIGNABLE_FLOWS, TenantConnectionStatus } from 'garaje/utils/enums';
import type { RecordArray } from 'garaje/utils/type-utils';
import urlBuilder from 'garaje/utils/url-builder';
import type { SinglePayload } from 'jsonapi/response';
import type RSVP from 'rsvp';

const generateLink = function (linkFn: (id: string) => string, attribute: keyof LocationModel) {
  return function (this: LocationModel) {
    return this.ajax.request(linkFn.call(this, this.id), { type: 'POST' }).then((data: LocationModel) => {
      const response = data;
      const key = <keyof LocationModel>(
        (<AmsSerializer>getOwner(this).lookup('serializer:ams')).keyForAttribute(attribute)
      );
      void set(this, attribute, response[key]);
    });
  };
};

export interface KioskForceUpgradeMessage {
  body?: string;
  title?: string;
  dismissableOnGaraje?: boolean;
  dismissable?: boolean;
}

export interface Field {
  name: string;
  componentName: string | undefined;
}

interface Logo {
  url: string;
  smallUrl: string;
  thumbUrl: string;
}

interface UserDocumentRequirement {
  identifier: string;
  'required-minimum': number;
  'user-document-template-configuration-ids': number[];
}

interface Watchlist {
  list: string | null;
  notifyEmail: string | null;
  notifyPhoneNumber: string | null;
}

// This is the list of attributes with its humanize version that, when changed,
// can be propagable to other locations
const OVERWRITABLE_SETTINGS = {
  preRegistrationEnabled: 'pre-registration',
  preRegistrationRequiredEnabled: 'pre-registration required',
  ccReceptionistEnabled: 'invite notifications',
  preRegistrationNotes: 'invite email template',
  emailNotificationEnabled: 'host notification method: email',
  smsNotificationEnabled: 'host notification method: SMS',
  slackNotificationEnabled: 'host notification method: slack',
  callNotificationEnabled: 'host notification method: call',
  hostNotificationsEnabled: 'host notifications',
  autoSignOutAtMinutesSinceMidnight: 'auto sign-out',
  color: 'accent color',
  locale: 'language',
  logo: 'logo',
  address: 'address',
  name: 'location name',
  addressLineTwo: 'suite #',
};

const ONBOARDING_CHECKS = {
  taskName: 'loadLocationsTask',
  attributes: ['color'],
};

class LocationModel extends PropagableModel {
  declare _pinnedDeviceContacts: UserModel[];
  declare _pinnedIdScanContacts: UserModel[];
  declare localFlows: RecordArray<FlowModel>;

  @service declare ajax: AjaxService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare metrics: MetricsService;
  @service declare skinnyLocations: SkinnyLocationsService;
  @service('state') declare stateService: StateService;
  @service declare store: StoreService;

  constructor(properties?: Record<string, unknown> | undefined) {
    super(properties);
    set(this, 'localFlows', this.store.peekAll('flow'));
  }

  save(...parameters: Parameters<Model['save']>): RSVP.Promise<this> {
    const save = super.save(...parameters);
    // when we create/update a location we need to reload skinny locations so that it picks up the changes
    void save.then(() => this.skinnyLocations.loadAllTask.perform(true));
    return save;
  }

  // needed for mirage link to zone for skinny-location. Will not be accessible with v2 locations.
  @belongsTo('zone') declare property: AsyncBelongsTo<ZoneModel>;

  @hasMany('announcement') declare announcements: AsyncHasMany<AnnouncementModel>;
  @hasMany('delivery-areas') declare deliveryAreas: AsyncHasMany<DeliveryAreaModel>;
  @hasMany('device') declare devices: AsyncHasMany<DeviceModel>;
  @hasMany('group') declare groups: AsyncHasMany<GroupModel>;
  @hasMany('reservation') declare reservations: AsyncHasMany<ReservationModel>;
  @hasMany('mailer-template-version') declare mailerTemplateVersions: AsyncHasMany<MailerTemplateVersionModel>;

  // TODO: highlighting changes for location-billing
  @hasMany('location-subscription', { async: true })
  // @ts-ignore remove once location subscription is in TS
  declare locationSubscriptions: AsyncHasMany<LocationSubscriptionModel>;

  @computed('locationSubscriptions.[]')
  get deliveriesSubscription(): LocationSubscriptionModel | undefined {
    return this.locationSubscriptions.find((sub) => sub.app === APP.DELIVERIES);
  }

  @computed('locationSubscriptions.[]')
  get roomsSubscription(): LocationSubscriptionModel | undefined {
    return this.locationSubscriptions.find((sub) => sub.app === APP.ROOMS);
  }

  @computed('locationSubscriptions.[]')
  get visitorsSubscription(): LocationSubscriptionModel | undefined {
    return this.locationSubscriptions.find((sub) => sub.app === APP.VISITORS);
  }

  @computed('locationSubscriptions.@each.app')
  get desksSubscription(): LocationSubscriptionModel | undefined {
    return this.locationSubscriptions.find((sub) => sub.app === APP.DESKS);
  }

  @computed('locationSubscriptions.@each.app')
  get workplaceSubscription(): LocationSubscriptionModel | undefined {
    return this.locationSubscriptions.find((sub) => sub.app === APP.WORKPLACE);
  }

  @computed('locationSubscriptions.[]')
  get renewalDate(): Date | null {
    const renewalSub = this.locationSubscriptions.find((sub) => sub.nextBillingAt);
    return renewalSub ? renewalSub.nextBillingAt : null;
  }

  // employee screening setting
  @attr('boolean') declare employeeScreeningEnabled: boolean;

  @attr('boolean') declare employeeScheduleEnabled: boolean;

  // visitor capacity settings
  @attr('number') declare capacityLimit: number;
  @gt('capacityLimit', 0) capacityLimitEnabled!: boolean;
  @hasMany('user', { inverse: null }) declare capacityContacts: AsyncHasMany<UserModel>;

  // /settings/locations stuff
  @attr('string') declare name: string;
  @attr('boolean', { defaultValue: false }) declare primary: boolean;
  @attr('boolean') declare securityDeskLinkEnabled: boolean;
  @belongsTo('company', { async: true }) declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('webhook', { async: true }) declare webhook: AsyncBelongsTo<WebhookModel>;
  @belongsTo('badge', { async: true }) declare badge: AsyncBelongsTo<BadgeModel>;
  @hasMany('printer', { async: true }) declare printers: AsyncHasMany<PrinterModel>;
  @computed('printers.firstObject')
  get printer(): PrinterModel | undefined {
    // support printer attribute for backward compatibility
    return this.printers.firstObject;
  }
  @belongsTo('saml', { async: true }) declare saml: AsyncBelongsTo<SamlModel>;
  @attr('string') declare samlLocationId: string;
  @attr('boolean', { defaultValue: false }) declare disabled: boolean;
  @attr('date', { defaultValue: null }) declare disabledToEmployeesAt: Date | null;
  @alias('disabled') visitorsDisabled!: this['disabled'];
  @attr('string') declare locale: string;
  @attr('string') declare timezone: string;
  /**
   * Location.logo
   * TODO - Is this object deprecated?
   */
  @attr('immutable') declare logo: Logo | null;
  @attr('immutable') declare logoUrl: string;
  @attr('immutable') declare slideUrl: string;
  @attr('immutable') declare hideLogo: boolean;
  @attr('string', { defaultValue: '#c50000' }) declare color: string;
  @attr('immutable', { defaultValue: '#c50000' }) declare buttonColor: string;
  @attr('immutable', { defaultValue: '#ffffff' }) declare buttonTextColor: string;
  @attr('string') declare apiKey: string;
  /**
   * watchlist
   *
   * {
   *  list: "String",
   *  notifyEmail: "String",
   *  notifyPhoneNumber: "string"
   * }
   */
  @attr('immutable', {
    defaultValue: () => ({
      list: null,
      notifyEmail: null,
      notifyPhoneNumber: null,
    }),
  })
  declare watchlist: Watchlist;
  @attr('boolean') declare visitorGuideEnabled: boolean;
  @belongsTo('visitor-guide', { async: true }) declare visitorGuide: AsyncBelongsTo<VisitorGuideModel>;
  @attr('boolean') declare visitorSurveyEnabled: boolean;
  @belongsTo('visitor-survey-configuration', { async: true })
  declare visitorSurveyConfiguration: AsyncBelongsTo<VisitorSurveyConfigurationModel>;

  // Defaulting to a "dismissed/complete" state unless backend tells us otherwise
  @attr('boolean', { defaultValue: true }) declare deliveriesOnboardingComplete: boolean;
  @attr('boolean', { defaultValue: true }) declare visitorsOnboardingComplete: boolean;

  // Allowed values: 'defer_to_flow_settings', 'all_visitors' or 'only_signed_nda_visitors'
  @attr('string', { defaultValue: 'all_visitors' }) declare welcomeEmailPreference:
    | 'defer_to_flow_settings'
    | 'all_visitors'
    | 'only_signed_nda_visitors';

  // FF sign_out_improvements adds auto_sign_out_at_time as number of minutes from midnight
  @attr('number') declare autoSignOutAtMinutesSinceMidnight: number;
  @attr('boolean', { defaultValue: false }) declare autoSignOutAtMidnight: boolean;

  @attr('boolean') declare blocklistEnabled: boolean;

  @hasMany('user', { inverse: null }) declare blocklistContacts: AsyncHasMany<UserModel>;
  @hasMany('user', { inverse: null }) declare deviceContacts: AsyncHasMany<UserModel>;
  @hasMany('user', { inverse: null }) declare idScanContacts: AsyncHasMany<UserModel>;
  @hasMany('user', { inverse: null }) declare printerContacts: AsyncHasMany<UserModel> | UserModel[];
  @hasMany('user', { inverse: null }) declare multiTenancyVisitorContacts: AsyncHasMany<UserModel>;
  @hasMany('user', { inverse: null }) declare connectWalkInApprovalContacts: AsyncHasMany<UserModel> | UserModel[];
  @attr('boolean') declare visualComplianceEnabled: boolean;
  @belongsTo('visual-compliance-configuration', { async: true })
  declare visualComplianceConfiguration: AsyncBelongsTo<VisualComplianceConfigurationModel>;

  @attr('boolean') declare fallbackNotificationsEnabled: boolean;
  @attr('boolean') declare multiTenancyVisitorNotificationsEnabled: boolean;
  @attr('boolean') declare deviceNotificationsEnabled: boolean;
  @attr('boolean') declare printerNotificationsEnabled: boolean;
  @attr('array', {
    defaultValue: () => [],
  })
  declare enabledLocales: string[];
  @attr('boolean') declare multipleLanguagesEnabled: boolean;

  @attr('string') declare pubnubCipherKey: string;
  @attr('string') declare pubnubChannelEntry: string;
  @attr('string') declare pubnubChannelInvite: string;
  @attr('string') declare pubnubChannelIntegrations: string;
  @attr('string') declare pubnubChannelDevices: string;

  // Protect
  @attr('number') declare registrationEligibilityStartOffset: number;
  @attr('number') declare registrationEligibilityEndOffset: number;
  @attr('number') declare scheduleEligibilityOffset: number;
  @attr('array', {
    defaultValue: () => [],
  })
  declare userDocumentRequirements: UserDocumentRequirement[];
  @or('capacityLimitEnabled', 'employeeScreeningEnabled') isProtectSetup!: boolean;
  @hasMany('floor') declare floors: AsyncHasMany<FloorModel>;
  @hasMany('ticket-category', { async: true }) declare ticketCategories: AsyncHasMany<TicketCategoryModel>;
  @belongsTo('ticket-configuration', { async: true })
  declare ticketConfiguration: AsyncBelongsTo<TicketConfigurationModel>;

  // Dashboard
  @hasMany('invite', { async: true }) declare invites: AsyncHasMany<InviteModel>;
  @hasMany('entry', { async: true }) declare entries: AsyncHasMany<EntryModel>;

  /**
   * Location.dashboardFields
   *
   * ```[{
   *   name: "string",
   *   componentName: "string"
   * }]```
   */
  @attr('array', {
    defaultValue: () => [],
  })
  declare dashboardFields: NativeArray<Field>;

  /**
   * Location.inviteDashboardFields
   *
   * ```[{
   *   name: "string",
   *   componentName: "string"
   * }]```
   */
  @attr('array', {
    defaultValue: () => [],
  })
  declare inviteDashboardFields: NativeArray<Field>;

  @hasMany('location-role') declare locationRoles: AsyncHasMany<LocationRoleModel>;
  @belongsTo('gdpr-configuration') declare gdprConfiguration: AsyncBelongsTo<GdprConfigurationModel>;
  @belongsTo('vfd-configuration') declare vfdConfiguration: AsyncBelongsTo<VfdConfigurationModel>;

  @reads('groups.firstObject') group!: this['groups']['firstObject'];

  @computed('company.id', 'id')
  get locationAdmin(): Promise<UserModel> {
    const ObjectPromiseProxy = ObjectProxy.extend(PromiseProxyMixin);
    const promise = this.store
      .query('location-role', {
        filter: {
          location: this.id,
          roles: 'Location Admin',
        },
      })
      .then((locationRoles) => {
        const admin = locationRoles.firstObject;

        if (admin) {
          return admin.user;
        } else {
          return this.store
            .query('company-role', {
              filter: {
                // eslint-disable-next-line ember/no-get
                company: get(this, 'company.id'),
                roles: 'Global Admin',
              },
            })
            .then((adminRoles) => {
              const firstAdminRole = adminRoles.firstObject;
              return firstAdminRole?.user;
            });
        }
      });

    return ObjectPromiseProxy.create({ promise });
  }

  // Host notification
  @attr('boolean', { defaultValue: false }) declare emailNotificationEnabled: boolean;
  @oneWay('emailNotificationEnabled') emailEnabled!: boolean;
  @attr('boolean', { defaultValue: false }) declare smsNotificationEnabled: boolean;
  @oneWay('smsNotificationEnabled') smsEnabled!: this['smsNotificationEnabled'];
  @attr('boolean', { defaultValue: false }) declare callNotificationEnabled: boolean;
  @attr('boolean', { defaultValue: false }) declare slackNotificationEnabled: boolean;
  @readOnly('slackNotificationEnabled') slackEnabled!: this['slackNotificationEnabled'];
  @attr('boolean', {
    defaultValue: true,
  })
  declare hostNotificationsEnabled: boolean;
  @attr('boolean', { defaultValue: false }) declare notifyReceptionistsOnHostReplyEnabled: boolean;
  @oneWay('hostNotificationsEnabled') pushEnabled!: this['hostNotificationsEnabled'];
  @oneWay('hostNotificationsEnabled') invitePushEnabled!: this['hostNotificationsEnabled'];
  @alias('hostNotificationsEnabled') hasNotificationEnabled!: this['hostNotificationsEnabled'];
  @attr('string') declare office365WebhookUrl: string;
  @attr('boolean', { defaultValue: false }) declare office365NotificationEnabled: boolean;

  // address fields
  @attr('string') declare address: string;
  @attr('string') declare addressLineOne: string;
  @attr('string') declare addressLineTwo: string;
  @attr('string') declare city: string;
  @attr('string') declare state: string;
  @attr('string') declare zip: string;
  @attr('string') declare country: string;
  @attr('number') declare latitude: number;
  @attr('number') declare longitude: number;

  // @ts-ignore custom attrs options
  @attr('immutable-array', {
    deserializeItem(item: unknown) {
      return new NotificationMessage(item);
    },
    serializeItem(item: NotificationMessage) {
      return item.serialize();
    },
    defaultValue: () => [],
  })
  declare customNotifications: NotificationMessage[];

  // @ts-ignore custom attrs options
  @attr('immutable-array', {
    deserializeItem(item: unknown) {
      return new NotificationMessage(item);
    },
    serializeItem(item: NotificationMessage) {
      return item.serialize();
    },
    defaultValue: () => [],
  })
  declare defaultNotifications: NotificationMessage[];

  @attr('date') declare lastEntryAt: Date;
  @attr('date') declare createdAt: Date;

  // Pre-registration
  @attr('boolean', { defaultValue: true }) declare preRegistrationEnabled: boolean;
  @attr('boolean', { defaultValue: false }) declare ccReceptionistEnabled: boolean;
  @attr('boolean', { defaultValue: false }) declare preRegistrationRequiredEnabled: boolean;
  @attr('boolean', { defaultValue: false }) declare nearVisitScreeningEnabled: boolean;
  @attr('boolean', { defaultValue: false }) declare hostApprovalEnabled: boolean;

  @attr('boolean') declare touchlessSigninEnabled: boolean;
  @attr('boolean') declare staticQrEnabled: boolean;

  invitesTemplateUrl(): string {
    const path = 'csv-template';
    const adapter = this.store.adapterFor('location');
    const url = adapter.buildURL('location', this.id);

    return `${url}/${path}`;
  }

  @attr('string') declare preRegistrationLink: string;
  @attr('string') declare securityDeskLink: string;

  @attr('string') declare preRegistrationNotes: string;

  @hasMany('flow') declare flows: AsyncHasMany<FlowModel>;

  @computed('flows.[]')
  get hasNonProtectFlows(): Promise<boolean> {
    return this.flows.then((flows) => {
      return <number>flows.length > 1 && flows.any((f) => !f.isProtect);
    });
  }
  // /settings/forms stuff
  @belongsTo('config', { async: true }) declare config: AsyncBelongsTo<ConfigModel>;

  @hasMany('locations-setup-guide-step') declare locationsSetupGuideSteps: AsyncHasMany<LocationsSetupGuideStep>;

  // ipad version
  @attr('string') declare currentVersion: string;

  // Sorts primary locations above non-primary locations
  @computed('primary')
  get sortablePrimary(): number {
    return this.primary ? 0 : 1;
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  generateApiKey = generateLink(urlBuilder.v2.generateApiKeyUrl, 'apiKey');
  // eslint-disable-next-line @typescript-eslint/unbound-method
  generatePreRegistrationLink = generateLink(urlBuilder.v1.generatePreRegistrationLinkUrl, 'preRegistrationLink');
  // eslint-disable-next-line @typescript-eslint/unbound-method
  generateSecurityDeskLink = generateLink(urlBuilder.v1.generateSecurityDeskLinkUrl, 'securityDeskLink');

  // copy settings from existing location
  @attr('string') declare masterLocationId: string;

  // most common email domain
  @attr('string') declare domain: string;

  @attr('string') declare companyNameOverride: string | null;

  // TODO: @heroiceric
  // This is problematic because company is an async relationship
  @computed('company.name', 'companyNameOverride', 'primary')
  get companyName(): string | undefined | null {
    if (isBlank(this.companyNameOverride)) {
      // eslint-disable-next-line ember/use-ember-get-and-set
      return this.company.get('name');
    } else {
      return this.companyNameOverride;
    }
  }

  set companyName(v: string) {
    let newName: string | null = v;
    if (typeof v === 'string') {
      newName = newName.trim();
    }

    if (isBlank(newName)) {
      newName = null;
    }

    set(this, 'companyNameOverride', newName);
  }

  @computed('flows.@each.agreementPage')
  get agreementPages(): NativeArray<AsyncBelongsTo<AgreementPageModel>> {
    return A(this.flows.mapBy('agreementPage')).compact();
  }

  @computed('agreementPages.@each.enabled')
  get hasAgreementPage(): AsyncBelongsTo<AgreementPageModel> | undefined {
    // @ts-ignore this is problematic because agreementPages is an async relationship
    return this.agreementPages.findBy('enabled', true);
  }

  @computed('companyName', 'company.name', 'name')
  get nameWithCompanyName(): string {
    const name = this.name;
    let companyName = this.companyName;

    if (isBlank(companyName)) {
      // eslint-disable-next-line ember/use-ember-get-and-set
      companyName = this.company.get('name');
    }

    return isBlank(companyName) ? name : `${companyName} ${name}`;
  }

  @computed('multipleLanguagesEnabled', 'enabledLocales.[]')
  get languagesAvailable(): boolean {
    const multipleLanguagesEnabled = this.multipleLanguagesEnabled;
    const enabledLocales = this.enabledLocales || [];
    return multipleLanguagesEnabled && enabledLocales.length > 0;
  }

  @attr() declare employeesCsvUploadStatus: string;

  hasDirtyFlows(): boolean {
    return this.flows.any((flow) => !!flow.hasDirtyAttributes);
  }

  @computed('hasDirtyAttributes')
  get isOnlyEmployeesCSVUploadStatusDirty(): boolean {
    if (!this.hasDirtyAttributes) {
      return false;
    }
    const changedAttributes = this.changedAttributes();
    return !!(Object.keys(changedAttributes).length === 1 && this.changedAttributes()['employeesCsvUploadStatus']);
  }

  @computed('id', 'location.id')
  get skinnyLocation(): Promise<SkinnyLocationModel> | SkinnyLocationModel {
    const skinnyLocation = this.store.peekRecord('skinny-location', this.id);
    if (skinnyLocation) {
      return skinnyLocation;
    }
    return this.store.findRecord('skinny-location', this.id);
  }

  rollbackAttributes(): void {
    const cacheCsv = this.employeesCsvUploadStatus;
    super.rollbackAttributes();
    if (cacheCsv) {
      set(this, 'employeesCsvUploadStatus', cacheCsv);
    }
  }

  matchupInviteOrEntryFieldsWithLocationConfig<T extends EntryModel | InviteModel>(model: T): Promise<T> {
    return this.stateService.loadFlows({ reload: false }).then((loadedFlows) => {
      const flows = loadedFlows.filter(
        ({ employeeCentric, type }) => !NON_ASSIGNABLE_FLOWS.includes(type) && !employeeCentric
      );
      let pov = 'Purpose of visit';

      if ('povKey' in model && model.povKey) {
        pov = model.povKey;
      }

      const flowField = model.userData.findBy('field', pov);
      // We only want to extend new invites/entries and leave the
      // data untouch for existing ones.
      if (!flowField && !model.id) {
        const selectedFlow = flows[0]!;

        const { userData } = model;
        userData.addObject(new UserDatum({ field: pov, value: selectedFlow.name }));
        // need to reassign to key to detect changes
        model.userData = userData;

        model.flowName = selectedFlow.name;
        model.flow = selectedFlow;
      }

      return model;
    });
  }

  createInvite(date: Date | string, name: string, email: string): Promise<InviteModel> | InviteModel {
    const invite = this.store.createRecord('invite', {
      location: this,
      expectedArrivalTime: date,
      fullName: name,
      email: email,
    });
    this.metrics.trackEvent('Creating invite for location', {
      invite,
      location: this,
    });
    const employee = this.currentAdmin.employee;

    if (employee) {
      invite.employee = employee;
      invite.inviterName = employee.name;
    }
    return this.featureFlags.isEnabled('visitors-no-default-visitor-type')
      ? invite
      : this.matchupInviteOrEntryFieldsWithLocationConfig(invite);
  }

  createEmployeeInvite(date: Date, name: string, email: string): Promise<InviteModel> {
    const invite = this.store.createRecord('invite', {
      location: this,
      expectedArrivalTime: date,
      fullName: name,
      email: email,
    });

    this.metrics.trackEvent('Creating invite for location', {
      invite,
      location: this,
    });

    const employee = this.currentAdmin.employee;

    if (employee) {
      invite.employee = employee;
      invite.inviterName = employee.name;
    }
    return this.matchupInviteOrEntryFieldsWithLocationConfig(invite);
  }

  createEntry(date: Date): Promise<EntryModel> {
    const signInUser = this.currentAdmin.user;
    const entry = this.store.createRecord('entry', {
      signedInAt: date,
      location: this,
      signInUser,
    });

    return this.matchupInviteOrEntryFieldsWithLocationConfig(entry);
  }

  createGroupInvite({ date }: { date: Date | string }): GroupInviteModel {
    const { employee = null } = this.currentAdmin;
    const groupInvite = this.store.createRecord('group-invite', {
      location: this,
      expectedArrivalTime: date,
      employee,
    });

    this.metrics.trackEvent('Creating group invite for location', {
      groupInvite,
      location: this,
    });

    return groupInvite;
  }

  findEntry(id: string, include = []): Promise<EntryModel> {
    return this.store.findRecord('entry', id, { reload: true, include: include.join() }).then((entry) => {
      return this.matchupInviteOrEntryFieldsWithLocationConfig(entry);
    });
  }

  // use: employeeScreeningFlow: alias('skinnyLocation.employeeScreeningFlow'),
  @computed('localFlows.@each.type', 'skinnyLocation.employeeScreeningFlow.id')
  get employeeScreeningFlow(): EmployeeScreeningFlowModel | FlowModel | null | undefined {
    // this is async. Need to cast to EmployeeScreeningFlowModel
    // eslint-disable-next-line ember/no-get
    const flow = <EmployeeScreeningFlowModel>get(this.skinnyLocation, 'employeeScreeningFlow');
    // eslint-disable-next-line ember/no-get
    if (isPresent(flow) && get(flow, 'id')) {
      return flow;
    }
    if (isEmpty(this.localFlows)) {
      return null;
    }
    return this.localFlows.findBy('type', FlowType.EMPLOYEE_SCREENING);
  }

  // use: hasEmployeeScreeningFlow: alias('employeeScreeningFlow.id'),
  @computed('employeeScreeningFlow.locations.[]', 'id', 'skinnyLocation.employeeScreeningFlow.id')
  get hasEmployeeScreeningFlow(): boolean {
    // eslint-disable-next-line ember/no-get
    if (isPresent(get(this, 'skinnyLocation.employeeScreeningFlow.id'))) {
      return true;
    }
    if (!this.employeeScreeningFlow) {
      return false;
    }
    const locationIds = this.employeeScreeningFlow.hasMany('locations').ids();
    return locationIds.includes(this.id);
  }

  firstFlow(): Promise<FlowModel | undefined> {
    /*
     API V2 is not consistently returning the flows relationship, we
     can't rely on get(this, 'flows')
     */
    return this.store.query('flow', { filter: { location: this.id } }).then(function (flows) {
      // Companies will have 1 flow by default.
      return flows.sortBy('position').get('firstObject'); // eslint-disable-line ember/use-ember-get-and-set
    });
  }

  pingDevices = memberAction({
    urlType: <EmberDataRequestType>'PING',
    type: 'POST',
    path: 'ping_request',
  });

  kioskForceUpgradeMessage = memberAction<void, SinglePayload<KioskForceUpgradeMessage>>({
    path: 'kiosk-force-upgrade-message',
    type: 'get',
    urlType: <EmberDataRequestType>'v3',
  });

  getWelcomeEmailPreview = memberAction({
    path: 'welcome-email-preview',
    type: 'get',
    urlType: <EmberDataRequestType>'v3',
  });

  getEmergencyNotificationConfiguration = memberAction({
    path: 'emergency-notification-configuration',
    type: 'get',
    urlType: <EmberDataRequestType>'v3',
    after: function (response: SinglePayload<EmergencyNotificationConfigurationModel>) {
      const serializer = this.store.serializerFor('emergency-notification-configuration');
      const EmergencyNotificationConfiguration = this.store.modelFor('emergency-notification-configuration');
      const normalized = serializer.normalizeSingleResponse(
        this.store,
        EmergencyNotificationConfiguration,
        response,
        response.data.id,
        'findRecord'
      );

      return <EmergencyNotificationConfigurationModel>this.store.push(normalized);
    },
  });

  hasDirtyWelcomeEmailPreference(): boolean {
    const changedAttrs = this.changedAttributes();
    return Object.keys(changedAttrs).includes('welcomeEmailPreference');
  }

  async pinIdScanContacts(): Promise<void> {
    const idScanContacts = await this.idScanContacts;
    set(this, '_pinnedIdScanContacts', idScanContacts.toArray());
  }

  rollbackIdScanContacts(): void {
    void this.idScanContacts.setObjects(A(this._pinnedIdScanContacts));
  }

  async updateDeviceContacts(arrayOfUsers: UserModel[]): Promise<void> {
    const deviceContacts = await this.deviceContacts;
    deviceContacts.setObjects(A(arrayOfUsers));
  }

  async pinDeviceContacts(): Promise<void> {
    const deviceContacts = await this.deviceContacts;
    set(this, '_pinnedDeviceContacts', deviceContacts.toArray());
  }

  rollbackDeviceContacts(): void {
    void this.deviceContacts.setObjects(A(this._pinnedDeviceContacts));
  }

  getPropertyConnections(): Promise<RecordArray<TenantModel>> {
    return this.store.query('tenant', {
      filter: {
        location: this.id,
        status: TenantConnectionStatus.CONNECTED,
      },
    });
  }

  async getConnectConfiguration(): Promise<ConnectLocationConfigurationModel | undefined> {
    return (
      await this.store.query('connect-location-configuration', {
        filter: {
          location: this.id,
        },
      })
    ).firstObject;
  }

  async isConnectedToProperty(): Promise<boolean> {
    const tenants = await this.getPropertyConnections();
    return <number>tenants.length > 0;
  }

  async getVfdConfiguration(): Promise<VfdConfigurationModel | undefined> {
    let skinnyLocation = this.store.peekRecord('skinny-location', this.id);
    if (!skinnyLocation) {
      await this.skinnyLocations.loadAllTask.perform();
      skinnyLocation = this.store.peekRecord('skinny-location', this.id);
    }
    return skinnyLocation?.vfdConfiguration;
  }

  toJSON(): Record<string, unknown> {
    const {
      data: { attributes },
    } = <SinglePayload<LocationModel>>this.serialize();

    return Object.entries(attributes).reduce((carry: Record<string, unknown>, [key, value]) => {
      carry[camelize(key)] = value;

      return carry;
    }, {});
  }
}

LocationModel.reopenClass({ OVERWRITABLE_SETTINGS, ONBOARDING_CHECKS });

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    location: LocationModel;
  }
}

export default LocationModel;
