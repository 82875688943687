import Model, { attr } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('billing-v1')
class DiscountModel extends Model {
  /**
   * Cents
   */
  @attr('number') declare amount: number;
  @attr('string') declare description: string;
  @attr('string') declare outline: string;
  @attr('date') declare validTill: Date;
}

export default DiscountModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    discount: DiscountModel;
  }
}
