import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { later } from '@ember/runloop';
import zft from 'garaje/utils/zero-for-tests';
import { alias } from 'macro-decorators';

export default class EmployeeRegistrationController extends Controller {
  @service abilities;
  @service flow;
  @service state;
  @service currentAdmin;
  @service router;

  @tracked copied = '';

  @alias('flow.openedModal') openedModal;
  @alias('flow.selectedLocation') selectedLocation;
  @alias('flow.selectedFlow') selectedFlow;
  @alias('flow.locations') selectedLocations;
  @alias('currentAdmin.isGlobalAdmin') isGlobalAdmin;
  @alias('currentAdmin.isLocationAdmin') isLocationAdmin;

  @action
  closeModal() {
    this.openedModal = '';
  }

  @action
  create() {
    this.openedModal = '';
    later(
      this,
      function () {
        this.openedModal = 'create-flow';
      },
      zft(600)
    );
  }

  @action
  confirmSelection() {
    this.flow.assignFlowToLocation.perform();
  }

  @action
  confirmDelete() {
    this.flow.confirmDelete();
    const transitionRouteString = this.abilities.can('visit settings for workplace')
      ? 'workplace.settings.employees.registration'
      : 'protect.settings.employees.registration';
    this.router.transitionTo(transitionRouteString);
  }
}
