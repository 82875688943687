import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type DeviceModel from 'garaje/models/device';
import type LocationModel from 'garaje/models/location';
import type LocationSubscriptionModel from 'garaje/models/location-subscription';
import type PrinterModel from 'garaje/models/printer';
import type SubscriptionModel from 'garaje/models/subscription';
import type StateService from 'garaje/services/state';
import type { RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

export interface VisitorsDevicesIpadsRouteModel {
  ipads: RecordArray<DeviceModel>;
  location: LocationModel;
  printers: RecordArray<PrinterModel>;
  subscription: LocationSubscriptionModel | SubscriptionModel;
}

export default class VisitorsDevicesIpadsRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare store: StoreService;

  titleToken = 'iPads';

  beforeModel(): void {
    if (this.abilities.cannot('visit devices')) {
      void this.router.transitionTo('unauthorized');
    }
  }

  model(): Promise<VisitorsDevicesIpadsRouteModel> {
    const subscription = this.state.vrSubscription!;
    const location = this.state.currentLocation;
    const locationId = location.id;
    const ipads = this.store.query('device', { filter: { location: locationId }, sort: 'name', include: 'printer' });
    const printers = this.store.query('printer', { filter: { location: locationId }, include: 'location.devices' });

    return hash({
      ipads,
      location,
      printers,
      subscription,
    });
  }
}
