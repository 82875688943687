import type NativeArray from '@ember/array/-private/native-array';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type EntryModel from 'garaje/models/entry';
import type FlowModel from 'garaje/models/flow';
import type PluginModel from 'garaje/models/plugin';
import type PluginInstallModel from 'garaje/models/plugin-install';
import type PrinterModel from 'garaje/models/printer';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import type SignInFieldPageModel from 'garaje/models/sign-in-field-page';
import type StateService from 'garaje/services/state';
import type VisitorsOnboardingService from 'garaje/services/visitors-onboarding';
import type { RecordArray } from 'garaje/utils/type-utils';
import moment from 'moment-timezone';
import { all, hash } from 'rsvp';

import type VisitorsEntriesController from './controller';

export interface VisitorsEntriesRouteParams {
  startDate: string | null;
  endDate: string | null;
  date: string | null;
  query: string;
  filter: string;
  selectedFlow: string; // DEPRECATED - remove once "visitors-filter-logs-by-multiple-flows" feature flag is 100% rolled out
  selectedFlows?: string; // TODO: once "visitors-filter-logs-by-multiple-flows" is 100% rolled out, make this required, not optional
  refresh?: string;
  sort: string;
}

export interface VisitorsEntriesRouteModel {
  currentLocationId: string;
  startDate: string | null;
  endDate: string | null;
  date: string | null;
  entries: EntryModel[];
  filter: string;
  flows: FlowModel[];
  locationFlowIds: string[];
  pluginInstalls: RecordArray<PluginInstallModel>;
  plugins: RecordArray<PluginModel>;
  printers: RecordArray<PrinterModel> | undefined;
  query: string;
  selectedFlow?: string; // DEPRECATED - remove once "visitors-filter-logs-by-multiple-flows" feature flag is 100% rolled out
  selectedFlows?: string; // TODO: once "visitors-filter-logs-by-multiple-flows" is 100% rolled out, make this required, not optional
  sort: string;
  customFields: NativeArray<SignInFieldModel>[];
  signInFieldPages: SignInFieldPageModel[];
}

class VisitorsEntriesRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service declare visitorsOnboarding: VisitorsOnboardingService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  titleToken = 'Entries';

  memoizedModel: VisitorsEntriesRouteModel | null = null;

  queryParams = {
    startDate: {
      refreshModel: true,
    },
    endDate: {
      refreshModel: true,
    },
    date: {
      refreshModel: true,
    },
    query: {
      refreshModel: true,
    },
    filter: {
      refreshModel: true,
    },
    selectedFlows: {
      as: 'visitor_types',
      refreshModel: true,
    },
    selectedFlow: {
      // DEPRECATED - remove once "visitors-filter-logs-by-multiple-flows" feature flag is 100% rolled out
      as: 'visitor_type',
      refreshModel: true,
    },
    refresh: {
      refreshModel: true,
    },
    sort: {
      refreshModel: true,
    },
  };

  beforeModel(transition: Transition): void {
    if (!(isPresent(this.state.vrSubscription) && this.abilities.can('visit dashboard'))) {
      if (this.abilities.can('visit dashboard')) {
        void this.router.transitionTo('visitors.start-trial');
      } else if (this.abilities.can('visit billing')) {
        void this.router.transitionTo('billing');
      } else if (this.abilities.can('visit security-desk')) {
        void this.router.transitionTo('security-desk');
      } else if (this.abilities.can('visit security')) {
        void this.router.transitionTo('security');
      } else {
        void this.router.transitionTo('unauthorized');
      }
    }

    const queryParams: { date?: string | null; startDate?: string | null; endDate?: string | null } = {};
    const dateParam = transition.to.queryParams['date'];
    const startDateParam = transition.to.queryParams['startDate'];
    const endDateParam = transition.to.queryParams['endDate'];
    const hasInvalidDateParam = isPresent(dateParam) && !moment(dateParam).isValid();
    const hasInvalidStartDateParam = isPresent(startDateParam) && !moment(startDateParam).isValid();
    const hasInvalidEndDateParam = isPresent(endDateParam) && !moment(endDateParam).isValid();
    const isDateInvalid = hasInvalidDateParam || hasInvalidStartDateParam || hasInvalidEndDateParam;

    if (hasInvalidDateParam) queryParams.date = null;
    if (hasInvalidStartDateParam) queryParams.startDate = null;
    if (hasInvalidEndDateParam) queryParams.endDate = null;
    if (isDateInvalid) void this.router.transitionTo('visitors.entries', { queryParams });
  }

  async model({
    startDate,
    endDate,
    date,
    query,
    filter,
    selectedFlow, // DEPRECATED - remove once "visitors-filter-logs-by-multiple-flows" feature flag is 100% rolled out
    selectedFlows,
    sort,
  }: VisitorsEntriesRouteParams): Promise<VisitorsEntriesRouteModel> {
    const { vrSubscription } = this.state;
    const currentLocation = this.state.currentLocation;
    const entries: EntryModel[] = [];

    if (query && this.memoizedModel && this.memoizedModel.currentLocationId === currentLocation?.id) {
      return Object.assign(this.memoizedModel, {
        startDate,
        endDate,
        date,
        query,
        filter,
        selectedFlow, // DEPRECATED - remove once "visitors-filter-logs-by-multiple-flows" feature flag is 100% rolled out
        selectedFlows,
        sort,
        entries,
      });
    }

    const connectedTenants = await currentLocation.getPropertyConnections();
    const isConnectedToProperty = <number>connectedTenants.length > 0;

    const currentLocationId = currentLocation.id;
    const flows = this.state.loadFlows({
      includePropertyFlows: isConnectedToProperty, // if this location is connected to a property, include flows added by the property
      locationId: currentLocationId,
      reload: false,
    });
    const loadSteps = this.visitorsOnboarding.loadSteps();

    // Parallel requests
    const { flows: loadedFlows } = await hash({ flows, loadSteps });

    const byLocation = { filter: { location: currentLocationId } };
    const plugins = this.store.query('plugin', byLocation);
    const pluginInstalls = this.store.query('plugin-install', byLocation);
    const locationFlowIds = loadedFlows.map((flow) => flow.id);
    const signInFieldPages = await all(loadedFlows.map((flow) => flow.signInFieldPage));
    const customFields = await all(signInFieldPages.map((signInFieldPage) => signInFieldPage?.customFields));

    // Printers endpoint returns 403 for unauthorized employees which end up on users
    // getting sad cloud upon sign in
    let printers: Promise<RecordArray<PrinterModel>> | undefined;
    if (this.abilities.can('visit device') && vrSubscription?.canEnableBadgePrinting) {
      printers = this.store.query('printer', {
        filter: { location: currentLocationId, enabled: true },
        sort: 'status',
      });
    }

    return hash({
      currentLocationId,
      startDate,
      endDate,
      date,
      entries,
      filter,
      flows,
      locationFlowIds,
      pluginInstalls,
      plugins,
      printers,
      query,
      selectedFlow,
      sort,
      customFields,
      signInFieldPages,
    });
  }

  afterModel(model: VisitorsEntriesRouteModel): void {
    this.memoizedModel = model;
  }

  redirect(_model: VisitorsEntriesRouteModel, transition: Transition): void {
    this.visitorsOnboarding.gateRoute(this, transition);
  }

  setupController(
    controller: VisitorsEntriesController,
    model: VisitorsEntriesRouteModel,
    transition: Transition
  ): void {
    super.setupController(controller, model, transition);

    void this.visitorsOnboarding.loadImpressionsTask.perform();
  }

  resetController(controller: VisitorsEntriesController, isExiting: boolean): void {
    if (isExiting) {
      controller.connectedTenants = null;
      controller.clearSearch();
      void controller.pollEventReports.cancelAll();
      controller.startDate = null;
      controller.endDate = null;
      controller.date = '';
    }
  }
}

export default VisitorsEntriesRoute;
