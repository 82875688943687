import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { type Task } from 'ember-concurrency';
import type DeviceModel from 'garaje/models/device';
import type PrinterModel from 'garaje/models/printer';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type IpadIconService from 'garaje/services/ipad-icon';
import deviceStatus from 'garaje/utils/decorators/device-status';
import { type App } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { localCopy } from 'tracked-toolbox';

interface IpadDetailsArgs {
  context?: App;
  currentAppVersion: string;
  deleteIpadTask: Task<void, []>; // this component receives a curried task with the device already passed
  isPrinterEnabled: boolean;
  model: DeviceModel;
  printers: PrinterModel[];
}

@deviceStatus
class IpadDetails extends Component<IpadDetailsArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare ipadIcon: IpadIconService;

  @localCopy('args.model') declare model: DeviceModel;
  @localCopy('args.isPrinterEnabled') declare isPrinterEnabled: boolean;
  @localCopy('args.currentAppVersion') declare currentAppVersion: string;

  declare isStatusBad: boolean; // from @deviceStatus decorator

  progressImgPath = '/assets/images/orange-progress-small.svg';

  get ipadIconURI(): string {
    const deviceType = this.model.deviceType;
    const isSleeping = this.model.isSleeping;
    const isStatusBad = this.isStatusBad;

    return this.ipadIcon.getIconURI(deviceType, isSleeping, isStatusBad);
  }

  assignPrinterTask = dropTask(async (printer: PrinterModel) => {
    const model = this.model;
    model.printer = printer;
    try {
      await model.save();
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  @action
  printerSelected(printer: PrinterModel): void {
    void this.assignPrinterTask.perform(printer);
  }
}

export default IpadDetails;
