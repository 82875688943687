import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class LocationOverviewSignInFlowsShowBadgeRoute extends Route {
  @service featureFlags;
  @service state;
  @service store;
  @service transitionConfirm;
  @service skinnyLocations;

  model() {
    const model = this.modelFor('location-overview.sign-in-flows.show');
    const enableBadgeCustomContent = this.featureFlags.isEnabled('badge-custom-content');
    const uiHooks = enableBadgeCustomContent
      ? this.store.query('ui-hook', {
          locationIds: get(this.skinnyLocations, 'currentCompanyLocations').mapBy('id'),
          triggerNames: ['badge_text_field', 'badge_barcode', 'badge_qr_code'],
        })
      : [];

    return hash({
      ...model,
      badge: model.globalFlow.badge,
      uiHooks,
      enableBadgeCustomContent,
    });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.isEditingBadgeCSS = false;
    }
  }

  @routeEvent
  routeWillChange(transition) {
    const { badge } = get(this.controller, 'model');
    const confirmTask = get(this.transitionConfirm, 'displayConfirmTask');

    if (get(badge, 'hasDirtyAttributes')) {
      confirmTask.perform(transition, {
        continue() {
          badge.rollbackAttributes();
        },
      });
    }
  }
}
