import Route from '@ember/routing/route';
import { get, set, setProperties } from '@ember/object';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';
import { keepLatestTask, didCancel } from 'ember-concurrency';
import { routeEvent } from 'garaje/utils/decorators/route';

class SettingsNotificationsRoute extends Route {
  @service abilities;
  @service state;
  @service('global-setting-batch') globalSettingBatchService;
  @service transitionConfirm;
  @service router;
  @service store;

  titleToken = 'Notification Settings';

  beforeModel() {
    if (this.abilities.cannot('visit advance-settings')) {
      this.router.transitionTo('billing');
    }
  }

  async model() {
    const { currentLocation, vrSubscription } = this.state;
    const config = get(currentLocation, 'config');
    const fallbackContacts = this.fetchFallbackContactsTask.perform();

    if (this.abilities.can('propagate global-setting-batch')) {
      const globalSettingBatchService = this.globalSettingBatchService;
      globalSettingBatchService.createGlobalSettingBatch({ parent: currentLocation });
    }

    const employees = this.store.query('employee', {
      page: { offset: 0, limit: 1 },
      filter: {
        deleted: false,
        locations: currentLocation.id,
      },
    });

    const connectedTenants = await currentLocation.getPropertyConnections();

    let connectLocationConfiguration;

    if (connectedTenants.length) {
      // will create the value in BE if it doesn't exist
      connectLocationConfiguration = (
        await this.store.query('connect-location-configuration', {
          filter: {
            location: currentLocation.id,
          },
        })
      ).firstObject;
    }

    return RSVP.hash({
      connectedTenants,
      connectLocationConfiguration,
      currentLocation,
      config,
      employees,
      vrSubscription,
      fallbackContacts: get(fallbackContacts, 'value'),
      plugins: this.store.query('plugin', {
        filter: { location: currentLocation.id },
      }),
      pluginInstalls: this.store.query('plugin-install', {
        filter: { location: currentLocation.id },
      }),
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    setProperties(controller, {
      model: model.currentLocation,
      employees: model.employees,
      fallbackContacts: model.fallbackContacts,
      vrSubscription: model.vrSubscription,
      fallbackContactsTask: this.fetchFallbackContactsTask,
      reloadFallbackContacts: this.reloadFallbackContacts.bind(this),
      installedPlugins: model.pluginInstalls.filterBy('status', 'active'),
      connectedTenants: model.connectedTenants,
      connectLocationConfiguration: model.connectLocationConfiguration,
    });

    if (!get(controller, 'hasNotificationEnabled')) {
      set(controller, 'showBlankState', true);
    }
  }

  deactivate() {
    super.deactivate(...arguments);
    setProperties(this.controller, { showBlankState: false, feature: '' });
  }

  @keepLatestTask({ cancelOn: 'deactivate' })
  *fetchFallbackContactsTask() {
    return yield this.store.query('fallbackContact', {
      filter: {
        location: get(this, 'state.currentLocation.id'),
      },
    });
  }

  reloadFallbackContacts() {
    return this.fetchFallbackContactsTask.perform().catch((e) => {
      if (!didCancel(e)) {
        throw e;
      }
    });
  }

  @routeEvent
  routeWillChange(transition) {
    const controller = this.controller;
    if (!controller.hasDirtyChanges) {
      return true;
    } else {
      this.transitionConfirm.displayConfirmTask.perform(transition, {
        continue() {
          controller.send('rollbackDirtyChanges');
        },
      });
    }
  }
}

export default SettingsNotificationsRoute;
