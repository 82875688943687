import Controller from '@ember/controller';
import { action, get, set } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { all, dropTask, task } from 'ember-concurrency';
import { alias, filter, filterBy } from 'macro-decorators';
import { defer } from 'rsvp';
import _isEqual from 'lodash/isEqual';
import { isPresent } from '@ember/utils';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import urlBuilder from 'garaje/utils/url-builder';
import { createDeskStorePayload } from 'garaje/utils/bulk-map-operations/payload-utils';

export default class MapsDraftsAreaMapShowController extends Controller {
  @service flashMessages;
  @service featureFlags;
  @service featureConfig;
  @service store;
  @service state;
  @service router;
  @service ajax;
  @service metrics;
  @service currentAdmin;
  @service resourceOverview;
  @service abilities;

  @alias('state.currentLocation') currentLocation;

  @filter('desks', (desk) => desk.hasDirtyAttributes || desk.hasDirtyRelationships) dirtyDesks;
  @filterBy('mapFeatures', 'hasDirtyAttributes') dirtyFeatures;
  @filter('mapFeatures', (feature) => feature.hasError || !feature.name) errorFeatures;

  @tracked employees = [];
  @tracked rooms = [];
  @tracked deliveryAreas = [];
  @tracked desks = [];
  @tracked amenities = [];
  @tracked neighborhoods = [];
  @tracked resourceInsights = [];

  @tracked employeesPage = 0;
  @tracked employeesCount = 0;
  @tracked limit = 25;
  @tracked selectedFeature;
  @tracked mapFeatures = [];
  @tracked deletableFeatures = [];
  @tracked draftName;
  @tracked selectedNodeId = null;
  @tracked selectedEmployeeEmail = null;
  @tracked shouldShowEmployeeForm = false;

  @action
  setEmployeeFormVisibility(shouldShowEmployeeForm) {
    if (!shouldShowEmployeeForm) {
      this.selectedEmployeeEmail = null;
    }
    this.shouldShowEmployeeForm = shouldShowEmployeeForm;
  }

  get saveDisabled() {
    return (
      !(isPresent(this.dirtyDesks) || isPresent(this.dirtyFeatures) || isPresent(this.deletableFeatures)) ||
      isPresent(this.errorFeatures)
    );
  }

  get publishDisabled() {
    return isPresent(this.errorFeatures);
  }

  @action
  reloadMapsRoute() {
    this.send('refreshMapsRoute');
  }

  @action
  resetController() {
    this.employees = [];
    this.employeesPage = 0;
    this.employeesCount = 0;
    this.selectedFeature = null;

    this.selectedNodeId = null;
    this.selectedEmployeeEmail = null;

    // Exclude newly-created-but-unsaved records; they'll be marked as dirty but calling .rollbackAttributes()
    // on them will clear their `isNew` flag.
    const dirtyFeatures = this.mapFeatures.filter((feature) => feature.hasDirtyAttributes && !feature.isNew);
    dirtyFeatures.invoke('rollbackAttributes');
    this.deletableFeatures.invoke('rollbackAttributes');

    this.selectedFeature = null;
    this.mapFeatures = this.mapFeatures.filter((feature) => !feature.isNew);
    this.deletableFeatures = [];
    window.removeEventListener('keydown', this.keyDownHandler);
  }

  @dropTask
  renameDraftModalTask = {
    *perform() {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = async () => {
        set(this.context.model.draft, 'name', this.context.draftName);
        await this.context.model.draft.save();
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };

  _setNumberOfDesksInNeighborhoods() {
    const neighborhoodMap = {};

    this.desks.forEach((desk) => {
      const neighborhoodName = desk?.neighborhoodName;

      if (neighborhoodName) {
        if (neighborhoodMap[neighborhoodName]) {
          neighborhoodMap[neighborhoodName] += 1;
        } else {
          neighborhoodMap[neighborhoodName] = 1;
        }
      }
    });

    this.neighborhoods.forEach((neighborhood) => {
      if (neighborhoodMap[neighborhood.name]) {
        set(neighborhood, 'numberOfDesks', neighborhoodMap[neighborhood.name]);
      } else {
        set(neighborhood, 'numberOfDesks', 0);
      }
    });
  }

  loadResourcesDataTask = task({ drop: true }, async () => {
    const currentLocationId = this.currentLocation.id;
    [this.rooms, this.deliveryAreas, this.desks, this.neighborhoods, this.amenities, this.resourceInsights] = await all(
      [
        this.ajax
          .request(urlBuilder.roomba.getAvailableRooms(currentLocationId), {
            headers: { accept: 'application/vnd.api+json' },
            contentType: 'application/vnd.api+json',
          })
          .then(({ data }) =>
            data.map((room) => {
              return this.store.push(this.store.normalize('room', room));
            })
          ),
        this.store.query('delivery-area', { filter: { location: currentLocationId } }),
        this.store
          .query('desk', {
            filter: {
              'location-id': currentLocationId,
              'floor-id': get(this.model.mapFloor, 'id'),
              'include-draft-desks': true,
            },
            include: 'amenities',
          })
          .then((desks) => desks.toArray()),
        this.store
          .query('neighborhood', { filter: { 'location-id': currentLocationId } })
          .then((neighborhoods) => neighborhoods.toArray()),
        this.store.query('amenity', { filter: { 'location-id': currentLocationId } }),
        this.resourceOverview.getResourceInsightsTask.perform(this.model.areaMap.id, this.model.mapFloor.id),
        this.loadEmployeesTask.perform(),
      ]
    );

    await this.loadAssignedEmployeesTask.perform();
    this._setNumberOfDesksInNeighborhoods();
  });

  get employeeQuery() {
    const { limit, employeesPage } = this;
    const offset = employeesPage * limit;
    return {
      page: { limit, offset },
      filter: {
        locations: this.currentLocation.id,
        deleted: false,
      },
      include: 'assistants',
      sort: 'name',
    };
  }

  get hasMoreEmployeePages() {
    return this.employeesPage * this.limit < this.employeesCount;
  }

  loadEmployeesTask = task({ enqueue: true }, async () => {
    const employees = await this.store.query('employee', this.employeeQuery);
    this.employeesCount = employees.meta.total;
    this.employees.pushObjects(employees.toArray());
    this.employees = this.employees.filter((el) => el !== 'loadMore').uniqBy('email');
    this.employeesPage++;

    if (this.hasMoreEmployeePages) {
      this.employees.pushObject('loadMore');
    }
  });

  loadMoreEmployeesTask = task({ drop: true }, async () => {
    if (!this.hasMoreEmployeePages) return;
    try {
      await this.loadEmployeesTask.perform();
    } catch (e) {
      this.employeesPage--;
    }
  });

  loadAssignedEmployeesTask = task({ restartable: true }, async () => {
    const employeesPromises = [];
    let employeeResponses = [];
    const limit = 100;
    const assignedToEmails = this.model.desksInLocation
      .filter((desk) => desk.assignedTo)
      .map((desk) => desk.assignedTo);
    const userEmails = assignedToEmails;
    let offset = 0;
    let page = 0;
    while (offset < userEmails.length) {
      const userEmailsPaginated = userEmails.slice(offset, offset + limit);
      const fetchedEmployeesPromise = this.store.query('employee', {
        filter: {
          locations: this.state.currentLocation.id,
          'email-in': userEmailsPaginated.join(','),
          deleted: false,
        },
        include: 'user',
        page: { limit, offset: 0 },
      });
      page++;
      offset = page * limit;
      employeesPromises.push(fetchedEmployeesPromise);
    }
    employeeResponses = await all(employeesPromises);
    const employees = [];
    employeeResponses.forEach((response) => response.forEach((employee) => employees.push(employee)));
    this.employees.pushObjects(employees);
    this.employees = this.employees.filter((el) => el !== 'loadMore').uniqBy('email');
    if (this.hasMoreEmployeePages) {
      this.employees.pushObject('loadMore');
    }
  });

  trackSaveEventPayload() {
    const saveEventObject = {
      deleted: { features: {} },
      created: { features: {} },
      updated: { features: {} },
    };

    const getFeatureObj = ({ name, geometry, enabled }) => {
      return {
        name,
        geometry,
        enabled,
      };
    };

    this.deletableFeatures.forEach((feature) => {
      if (saveEventObject.deleted.features[feature.type]) {
        saveEventObject.deleted.features[feature.type].push(feature.id);
      } else {
        saveEventObject.deleted.features[feature.type] = [feature.id];
      }
    });
    const deletedDeskIds = this.dirtyDesks.filter((desk) => desk.isDeleted).map((desk) => desk.id);
    saveEventObject.deleted['desks'] = deletedDeskIds;

    const newFeatures = this.dirtyFeatures.filterBy('isNew');
    newFeatures.forEach((feature) => {
      const featureObj = getFeatureObj(feature);
      if (saveEventObject.created.features[feature.type]) {
        saveEventObject.created.features[feature.type].push(featureObj);
      } else {
        saveEventObject.created.features[feature.type] = [featureObj];
      }
    });

    const newDesks = this.dirtyDesks.filterBy('isNew');
    saveEventObject.created['desks'] = newDesks.map(({ name, neighborhood, assignedTo, enabled, xPos, yPos }) => ({
      name,
      neighborhood,
      assignedTo,
      enabled,
      xPos,
      yPos,
    }));

    const getUpdatedAttributes = (record) => {
      const updatedObj = { id: record.id };
      const changed = record.changedAttributes();
      for (const attr in changed) {
        updatedObj[attr] = changed[attr][1];
      }
      return updatedObj;
    };

    const updatedFeatures = this.dirtyFeatures.filter((feature) => !feature.isNew && !feature.isDeleted);
    updatedFeatures.forEach((feature) => {
      const featureObj = getUpdatedAttributes(feature);
      if (saveEventObject.updated.features[feature.type]) {
        saveEventObject.updated.features[feature.type].push(featureObj);
      } else {
        saveEventObject.updated.features[feature.type] = [featureObj];
      }
    });

    const updatedDesks = this.dirtyDesks.filter((desk) => !desk.isNew && !desk.isDeleted);
    saveEventObject.updated['desks'] = updatedDesks.map((desk) => getUpdatedAttributes(desk));

    return saveEventObject;
  }

  saveTask = task({ drop: true }, async () => {
    const saveEventPayload = this.trackSaveEventPayload();
    try {
      const shouldSetDeskLocations = this.dirtyDesks.length || this.desks.filterBy('isDeleted').length;

      if (this.featureFlags.isEnabled('bulk-map-operations')) {
        // When creating or deleting desks the backend will create/delete the associated feature
        // This is why mapFeatures are fetched after the desks are created/deleted
        await this.deleteDesksTask.perform();
        // to avoid name conflict issues in the backend, we need to delete desks first
        await all([this.createDesksTask.perform(), this.updateDesksTask.perform()]);
        this.desks = this.store
          .peekAll('desk')
          .toArray()
          .filter((desk) => desk.belongsTo('floor').id() === this.model.mapFloor.id);
      } else {
        await all(this.deletableFeatures.filter((feature) => feature.type !== 'desk').invoke('destroyRecord'));
        await all(this.desks.filterBy('isDeleted').invoke('save'));
        if (this.featureFlags.isEnabled('scheduled-desk-assignment')) {
          await all(this.dirtyDesks.invoke('saveWithAssignments'));
        } else {
          await all(this.dirtyDesks.invoke('save'));
        }
      }

      if (this.featureFlags.isEnabled('bulk-map-operations')) {
        await this.deleteFeaturesTask.perform();
        await all([this.createFeaturesTask.perform(), this.updateFeaturesTask.perform()]);
      } else {
        // When desks are saved the features are updated in the backend so we filter out the desk feature to reduce the number of requests
        await all(this.dirtyFeatures.filter((feature) => feature.type !== 'desk').invoke('save'));
      }

      const promiseArray = [];
      promiseArray.push(
        this.store.query('map-feature', {
          filter: {
            'area-map': this.model.areaMap.id,
          },
        })
      );

      set(this.model.draft, 'lastUpdatedBy', this.currentAdmin.employee.id);
      promiseArray.push(this.model.draft.save());

      if (shouldSetDeskLocations) {
        promiseArray.push(this.state.setDeskLocationsTask.perform());
      }

      // gqlMapFeaturesAndEmployees
      const [mapFeatures, _draft, _setDeskLocations] = await all(promiseArray);
      this.mapFeatures = mapFeatures.toArray();
      this.deletableFeatures = [];
      this.selectedFeature = null;
      this.selectedNodeId = null;
      this.selectedEmployeeEmail = null;
      this.setEmployeeFormVisibility(false);

      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showFlash('error', parseErrorForDisplay(e));
    }

    this.metrics.trackEvent('Draft - Saved', saveEventPayload);
  });

  updateDesksTask = task({ drop: true }, async () => {
    if (this.updateDesksPayload.length === 0) {
      return;
    }
    const url = urlBuilder.rms.bulkDesksUpdateEndpoint();
    const response = await this.ajax.request(url, {
      accept: 'application/vnd.api+json',
      contentType: 'application/json',
      type: 'POST',
      data: JSON.stringify({ 'atomic:operations': this.updateDesksPayload }),
    });
    response.data.forEach((desk) => {
      this.store.pushPayload('desk', createDeskStorePayload(desk));
    });
  });

  generateDeskPayload(desk) {
    const neighborhoodId = desk?.belongsTo('neighborhood')?.id();

    const payload = {
      data: {
        type: 'resource',
        attributes: {
          name: desk.name,
          'neighborhood-id': neighborhoodId,
          enabled: desk.enabled,
          'assigned-to': desk.assignedTo,
          'resource-type-id': 1,
        },
        relationships: {
          amenities: {
            data: desk
              .hasMany('amenities')
              .ids()
              .map((amenityId) => ({
                type: 'amenities',
                id: amenityId,
              })),
          },
          draft: {
            data: {
              type: 'draft',
              id: this.model.draft.id,
            },
          },
          floor: {
            data: {
              type: 'floors',
              id: desk.floorId ?? desk.belongsTo('floor').id(),
            },
          },
          location: {
            data: {
              type: 'locations',
              id: this.currentLocation.id,
            },
          },
          neighborhood: {
            data: neighborhoodId
              ? {
                  type: 'neighborhoods',
                  id: neighborhoodId,
                }
              : null,
          },
        },
      },
    };

    // Only include geometry if both xPos and yPos are defined and not null
    if (desk.xPos != null && desk.yPos != null) {
      payload.data.attributes.geometry = {
        type: 'Point',
        coordinates: [desk.xPos, desk.yPos],
      };
    }

    return payload;
  }

  get updateDesksPayload() {
    return this.dirtyDesks
      .filter((desk) => !desk.isNew && !desk.isDeleted)
      .map((desk) => {
        const payload = this.generateDeskPayload(desk);
        payload.op = 'update';
        payload.data.id = desk.id;
        return payload;
      });
  }

  get createDesksPayload() {
    return this.dirtyDesks
      .filter((desk) => desk.isNew)
      .map((desk) => {
        const payload = this.generateDeskPayload(desk);
        payload.op = 'add';
        return payload;
      });
  }

  createDesksTask = task({ drop: true }, async () => {
    if (this.createDesksPayload.length === 0) {
      return;
    }
    const url = urlBuilder.rms.bulkDesksCreateEndpoint();

    // attempt to create multiple desks via the bulk-desk-creation endpoint
    const response = await this.ajax.request(url, {
      accept: 'application/vnd.api+json',
      contentType: 'application/json',
      type: 'POST',
      data: JSON.stringify({ 'atomic:operations': this.createDesksPayload }),
    });
    this.selectedFeature = null;
    this.dirtyDesks
      .filter((desk) => desk.isNew)
      .forEach((dirtyDesk) => {
        dirtyDesk.unloadRecord();
      });
    response.data.forEach((desk) => {
      this.store.pushPayload('desk', createDeskStorePayload(desk));
    });
  });

  get deleteDesksPayload() {
    return this.desks
      .filter((desk) => desk.isDeleted && !desk.isNew)
      .map((desk) => {
        return {
          op: 'remove',
          data: {
            id: desk.id,
          },
        };
      });
  }

  deleteDesksTask = task({ drop: true }, async () => {
    if (this.deleteDesksPayload.length === 0) {
      return;
    }
    const url = urlBuilder.rms.bulkDesksDeleteEndpoint();

    await this.ajax
      .request(url, {
        accept: 'application/vnd.api+json',
        contentType: 'application/json',
        type: 'POST',
        data: JSON.stringify({ 'atomic:operations': this.deleteDesksPayload }),
      })
      .then(() => {
        this.desks
          .filter((desk) => desk.isDeleted)
          .forEach((deletedDesk) => {
            deletedDesk.unloadRecord();
          });
      });
  });

  get createNonDeskFeaturesPayload() {
    // Desks are filtered from the payload because the backend will manage feature creation when new desks are saved
    return this.dirtyFeatures
      .filter((feature) => feature.isNew && feature.type !== 'desk')
      .map((feature) => {
        // If the feature type is a polygon, we need to close the linestring
        const geometryType = feature.geometry.type;
        let coordinates = feature.geometry.coordinates;
        const { length, 0: start, [length - 1]: end } = coordinates;

        if (geometryType === 'Polygon' && !_isEqual(start, end)) {
          coordinates = [...coordinates, start];
        }
        return {
          op: 'add',
          data: {
            type: 'feature',
            attributes: {
              geometry: {
                coordinates: coordinates,
                type: geometryType,
              },
              enabled: feature.enabled,
              type: feature.type.toUpperCase().replace(/-/g, '_'),
              externalId: feature.externalId,
              notes: feature.notes,
              name: feature.name,
            },
            relationships: {
              'map-floor': {
                data: {
                  type: 'map-floors',
                  id: get(feature.mapFloor, 'id'),
                },
              },
              'area-map': {
                data: {
                  type: 'area-maps',
                  id: get(feature.areaMap, 'id'),
                },
              },
            },
          },
        };
      });
  }

  createFeaturesTask = task({ drop: true }, async () => {
    if (this.createNonDeskFeaturesPayload.length === 0) {
      return;
    }
    const url = urlBuilder.maps.bulkFeaturesCreateEndpoint();
    await this.ajax.request(url, {
      accept: 'application/vnd.api+json',
      contentType: 'application/json',
      type: 'POST',
      data: JSON.stringify({ 'atomic:operations': this.createNonDeskFeaturesPayload }),
    });

    this.dirtyFeatures
      .filter((feature) => feature.isNew)
      .forEach((dirtyFeature) => {
        dirtyFeature.unloadRecord();
      });
  });

  get updateNonDeskFeaturesPayload() {
    return this.dirtyFeatures
      .filter((feature) => !feature.isNew && !feature.isDeleted && feature.type !== 'desk')
      .map((feature) => {
        // If the feature type is a polygon, we need to close the linestring
        const geometryType = feature.geometry.type;
        let coordinates = feature.geometry.coordinates;
        const { length, 0: start, [length - 1]: end } = coordinates;

        if (geometryType === 'Polygon' && !_isEqual(start, end)) {
          coordinates = [...coordinates, start];
        }

        return {
          op: 'update',
          data: {
            type: 'feature',
            attributes: {
              id: feature.id,
              geometry: {
                coordinates: coordinates,
                type: geometryType,
              },
              enabled: feature.enabled,
              type: feature.type.toUpperCase().replace(/-/g, '_'),
              externalId: feature.externalId,
              notes: feature.notes,
              name: feature.name,
            },
          },
        };
      });
  }

  updateFeaturesTask = task({ drop: true }, async () => {
    if (this.updateNonDeskFeaturesPayload.length === 0) {
      return;
    }
    const url = urlBuilder.maps.bulkFeaturesUpdateEndpoint();
    await this.ajax.request(url, {
      accept: 'application/vnd.api+json',
      contentType: 'application/json',
      type: 'POST',
      data: JSON.stringify({ 'atomic:operations': this.updateNonDeskFeaturesPayload }),
    });
  });

  get deleteNonDeskFeaturesPayload() {
    // Desks are filtered from the payload because the backend will manage feature deletion when new desks are saved
    return this.deletableFeatures
      .filter((feature) => feature.type !== 'desk')
      .map((feature) => {
        return {
          op: 'remove',
          data: {
            type: 'features',
            id: feature.id,
          },
        };
      });
  }

  deleteFeaturesTask = task({ drop: true }, async () => {
    if (this.deleteNonDeskFeaturesPayload.length === 0) {
      return;
    }
    const url = urlBuilder.maps.bulkFeaturesDeleteEndpoint();
    await this.ajax.request(url, {
      accept: 'application/vnd.api+json',
      contentType: 'application/json',
      type: 'POST',
      data: JSON.stringify({ 'atomic:operations': this.deleteNonDeskFeaturesPayload }),
    });

    this.dirtyFeatures
      .filter((feature) => feature.isDeleted)
      .forEach((dirtyFeature) => {
        dirtyFeature.unloadRecord();
      });
  });
}
