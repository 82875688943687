import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Ember from 'ember';
import { isBlank } from '@ember/utils';
import { action, get, set } from '@ember/object';
import { not } from 'macro-decorators';
import { inject as service } from '@ember/service';
import LOCALE_OPTIONS, { isRTLLocale } from 'garaje/utils/locale-options';
import fixVideoUrl from 'garaje/utils/fix-video-url';
import { sanitizeMarkdown } from 'garaje/utils/sanitize-markdown';

const MINIMUM_IPAD_VERSION_NEEDED = '3.3.6';

/**
 * @param {Object}                                      translationLanguagesAvailable
 * @param {Object}                                      translationEnabledLocales
 * @param {Object}                                      defaultLocale
 * @param {Object}                                      changeset
 * @param {String}                                      flowName
 * @param {Class<Flow>}                                 flow
 * @param {Boolean}                                     isAppUpdateRequired
 * @param {Class<Agreement|GlobalAgreement>}            model
 * @param {Array}                                       signInFields
 * @param {Boolean}                                     propagable
 * @param {Object}                                      tracking
 * @param {Function}                                    trackLegalDocument
 * @param {Class<Subscription>}                         vrSubscription
 * @param {Boolean}                                     isDisabled
 * @param {Function}                                    updateAndSaveTask
 */
export default class EditText extends Component {
  @service currentLocation;
  @service featureFlags;

  @tracked selectedLocale;
  @tracked textWasEdited = false;
  @tracked isEditing = true;
  @tracked localeOptions = LOCALE_OPTIONS;
  @tracked ndaTextFormatted;

  iPadVersionRequired = MINIMUM_IPAD_VERSION_NEEDED;

  @not('hasInvalidVideoUrl') hasValidVideoUrl;

  constructor() {
    super(...arguments);
    this.selectedLocale = this.args.defaultLocale;
  }

  get isRTLLocale() {
    return isRTLLocale(this.selectedLocale);
  }

  get today() {
    const date = new Date();
    return date.toLocaleDateString(this.selectedLocale, { month: 'long', day: 'numeric', year: 'numeric' });
  }

  get isSaveDisabled() {
    const isRunning = this.args.updateAndSaveTask.isRunning;
    if (this.textWasEdited && !isRunning) {
      return false;
    } else {
      const isPristine = get(this.args.changeset, 'isPristine');
      const isInvalid = get(this.args.changeset, 'isInvalid');
      return isPristine || isInvalid || isRunning;
    }
  }

  get availableLocales() {
    const { defaultLocale, translationEnabledLocales, flow } = this.args;
    let locales = translationEnabledLocales;

    if (flow.isGlobal) {
      locales = [translationEnabledLocales, defaultLocale].flat().uniq();
    }

    return this.localeOptions.filter((option) => locales.includes(option.value));
  }

  get messageValue() {
    if (this.args.defaultLocale === this.selectedLocale) {
      return get(this.args.changeset, 'body');
    }
    const customTranslations = get(this.args.changeset, 'customTranslations');
    if (isBlank(customTranslations['body']) || typeof customTranslations['body'][this.selectedLocale] !== 'string') {
      return '';
    }
    return get(this.args.changeset, 'customTranslations')['body'][this.selectedLocale];
  }

  get variables() {
    const variables = ['date', 'company', 'name', 'field purpose of visit'];

    this.args.signInFields?.sortBy('position').forEach((field) => {
      if (get(field, 'isCustom')) {
        variables.push(`field ${get(field, 'name')}`);
      } else if (get(field, 'kind') === 'email') {
        variables.push('email');
      } else if (get(field, 'kind') === 'phone') {
        variables.push('phone');
      }
    });

    return variables.map((v) => `::${v.toUpperCase()}::`);
  }

  get hasInvalidVideoUrl() {
    const errors = get(this.args.changeset, 'errors');
    return errors.some(({ key }) => key === 'videoUrl');
  }

  get canRequireResign() {
    const changes = Object.keys(this.args.changeset.change || {});
    return changes.includes('body') || changes.includes('videoUrl');
  }

  _changeBodyLocale(value) {
    if (this.args.defaultLocale === this.selectedLocale) {
      set(this.args.changeset, 'body', value);
    } else {
      let customTranslations = Object.assign({}, get(this.args.changeset, 'customTranslations'));
      if (isBlank(customTranslations.body)) {
        customTranslations = { body: {} };
      }
      customTranslations.body[this.selectedLocale] = value;
      set(this.args.changeset, 'customTranslations', customTranslations);
    }
  }

  useEmbeddedVideoUrl() {
    const url = get(this.args.changeset, 'videoUrl');
    if (url && this.hasValidVideoUrl) {
      const fixed = fixVideoUrl(url);
      if (url !== fixed) {
        set(this.args.changeset, 'videoUrl', fixed);
      }
    }
  }

  @action
  bodyChanged(value) {
    this.textWasEdited = true;
    set(this.args.changeset, 'requireResign', true);
    if (this.args.translationLanguagesAvailable) {
      this._changeBodyLocale(value);
    } else {
      set(this.args.changeset, 'body', value);
    }
  }

  @action
  applyChangeset(changeset) {
    changeset.execute();
    changeset.rollback();
  }

  @action
  formatNDAText(model) {
    const companyName = get(this.currentLocation, 'companyName');
    const date = this.today;
    const defaultFields = ['DATE', 'COMPANY', 'NAME', 'EMAIL', 'PHONE'];

    const escapedCustomFields = this.args.signInFields.filterBy('isCustom').map((field) => {
      return get(field, 'name')
        .toUpperCase()
        .replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    });

    const fieldsToMatch = defaultFields
      .concat(escapedCustomFields.map((field) => `FIELD ${field}`))
      .concat(['FIELD PURPOSE OF VISIT'])
      .join('|');

    let text = get(model, 'body');
    if (get(this.currentLocation, 'locale') !== this.selectedLocale) {
      const customTranslations = get(model, 'customTranslations');
      text = customTranslations.body[this.selectedLocale];
    }

    const formattedText = sanitizeMarkdown(text)
      .replace(new RegExp(`__(${fieldsToMatch})__`, 'g'), '**::$1::**')
      .replace(/::DATE::/g, date)
      .replace(/::COMPANY::/g, Ember.Handlebars.Utils.escapeExpression(companyName))
      .replace(/::FIELD PURPOSE OF VISIT::/g, Ember.Handlebars.Utils.escapeExpression(this.args.flowName))
      .replace(/::(NAME|EMAIL|PHONE)::/g, 'Visitor:$1')
      .replace(new RegExp(`::FIELD (${escapedCustomFields.join('|')})::`, 'g'), 'Visitor:$1');

    // Passed to <MarkdownToHtml>
    this.ndaTextFormatted = formattedText;
  }

  @action
  onToPreviewing() {
    const { eventName } = this.args.tracking;
    set(this.args.tracking, 'eventName', 'Legal Docs - Legal Document Previewed');
    this.args.trackLegalDocument(this.args.changeset);
    set(this.args.tracking, 'eventName', eventName);
  }

  @action
  onToEditing() {
    set(this.args.tracking, 'eventName', 'Legal Docs - Legal Document Edited');
  }

  @action
  performSave() {
    this.useEmbeddedVideoUrl();
    this.applyChangeset(this.args.changeset);
    this.args.updateAndSaveTask.perform(this.args.model, false, null, this.args.propagable).then(() => {
      this.textWasEdited = false;
      this.args.trackLegalDocument(this.args.model);
    });
  }

  @action
  updateName(name) {
    set(this.args.changeset, 'name', name);
  }

  @action
  updateVideoUrl(url) {
    set(this.args.changeset, 'videoUrl', url);
  }

  @action
  updateRequireResign(checked) {
    set(this.args.changeset, 'requireResign', checked);
  }
}
