import Service, { inject as service } from '@ember/service';
import { get, set } from '@ember/object';
import { singularize } from 'ember-inflector';

import popupFlow from 'garaje/utils/popup-flow';
import childWindow from 'garaje/utils/child-window';
import urlBuilder from 'garaje/utils/url-builder';

/* eslint-disable camelcase */

const GOOGLE_APPS_ERRORS = {
  insufficientAccess: 'oauth.insufficient_access',
  otherProviderEnabled: 'oauth.other_provider_enabled',
};

// eslint-disable-next-line ember/no-classic-classes
export default Service.extend({
  GOOGLE_APPS_ERRORS,
  syncManager: service(),
  googleSyncManager: service(),
  state: service(),
  ajax: service(),
  childWindow,
  store: service(),

  pushIntegration(type, data) {
    return this.store.push(this.store.normalize(type, data));
  },
  loadZapier() {
    const { currentCompany } = this.state;
    return this.loadIntegration(currentCompany.id);
  },
  loadIfttt() {
    const { currentCompany } = this.state;
    return this.loadIntegration(currentCompany.id);
  },
  loadIntegration(companyId) {
    return this.ajax
      .request(urlBuilder.v2.integrations.enabled(), {
        type: 'GET',
        data: {
          filter: {
            company: companyId,
          },
        },
      })
      .then((response) => {
        response.data.forEach((integration) => {
          this.pushIntegration(singularize(integration.type), integration);
        });
      });
  },
  authManager(url, width, height) {
    return popupFlow(url, width, height, 'oauth.connected');
  },

  // Google Apps
  saveGoogleApp(googleApp) {
    return googleApp.save();
  },
  connectGoogleApps(company) {
    const promise = this.authManager(urlBuilder.googleAuthUrl(), 750, 750);

    return promise.then((data) => {
      const { details } = data;

      const googleApp = this.store.createRecord('google-app', {
        company,
        email: details.email,
        enabled: true,
        accessToken: details.access_token,
        refreshToken: details.refresh_token,
        expiresAt: details.expires_at,
        googleId: details.google_id,
      });
      return googleApp.save().catch((error) => {
        // Unload the record on error here direcly once we upgrade ember-data and remove
        // the isNew check in the template
        // Ember Data expected the primary data returned from a 'findRecord' response to be an object but instead it found an array.
        // googleApp.unloadRecord();

        throw error;
      });
    });
  },
  disconnectGoogleApps(company) {
    const connection = get(company, 'googleApp.content');

    return connection.destroyRecord();
  },
  enableGoogleApps(company) {
    const connection = get(company, 'googleApp.content');
    set(connection, 'enabled', true);

    return this.saveGoogleApp(connection);
  },
  disableGoogleApps(company) {
    const connection = get(company, 'googleApp.content');
    set(connection, 'enabled', false);

    return this.saveGoogleApp(connection);
  },

  // Salesforce
  connectSalesforce(company) {
    const store = this.store;
    return this.authManager(urlBuilder.salesforceAuthUrl(), 750, 750)
      .then((data) => {
        const integration = store.createRecord('salesforce-integration', {
          company,
          salesforceAccessGrantId: data.salesforce_access_grant_id,
        });
        return integration.save();
      })
      .catch((error) => {
        store.unloadAll('salesforce-integration');
        throw error;
      });
  },
  disconnectSalesforce(company) {
    const integration = get(company, 'salesforceIntegration.content');

    return integration.destroyRecord();
  },

  // zapier
  connectZapier() {
    const url = 'https://zapier.com/zapbook/envoy/';
    return this.childWindow(url, 1100, 800);
  },
  connectIfttt() {
    const url = 'https://ifttt.com/envoy';
    return this.childWindow(url, 1100, 800);
  },

  // pubnub messages

  /* eslint-disable no-case-declarations */
  handleEvent(payload, location) {
    const company = get(location, 'company.content');

    switch (payload.provider) {
      case 'google-apps':
        this.syncManager.syncMessage(payload, company);
        break;
      case 'one-login':
        this.syncManager.syncMessage(payload, company);
        break;
      case 'okta':
        switch (payload.state) {
          case 'preview_completed':
            this.syncManager.trigger('okta#preview_completed', payload.fetch_okta_employees_url);
            break;
          default:
            this.syncManager.syncMessage(payload, company);
            break;
        }
        break;
      case 'scim':
        this.syncManager.updateScimIntegrationState(payload, company);
        break;
      case 'zapier':
        switch (payload.state) {
          case 'bearer_token_issued':
            this.loadZapier();
            break;
        }
        break;
    }
  },
  /* eslint-enable no-case-declarations */

  // helpers
  getIntegrations(location) {
    const company = get(location, 'company.content');
    const integrations = [
      get(location, 'webhook.content'),
      get(location, 'office365WebhookUrl'),
      get(location, 'company.saml.content'),
      get(location, 'company.salesforceIntegration.content'),
      get(company, 'zapierIntegration.content'),
      get(company, 'iftttIntegration.content'),
      get(company, 'googleApp.content'),
      get(company, 'oneLogin.content'),
      get(company, 'oktaIntegration.content'),
      get(company, 'slackIntegration.content'),
      get(company, 'scimIntegration.content'),
      get(company, 'gtalkEnabled') || null,
    ];

    return integrations.compact();
  },
  hasDirtyIntegrations(location) {
    const integrations = this.getIntegrations(location);

    return integrations.any(function (integration) {
      let isDirty = false;

      if (integration.get) {
        isDirty = get(integration, 'isNew') || get(integration, 'hasDirtyAttributes');
      }

      return isDirty;
    });
  },
  cleanIntegrations(location) {
    const integrations = this.getIntegrations(location);

    return integrations.forEach(function (integration) {
      if (get(integration, 'isNew')) {
        integration.unloadRecord();
      } else if (get(integration, 'hasDirtyAttributes')) {
        integration.rollbackAttributes();
      }
    });
  },
});
