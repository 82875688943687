/* eslint-disable ember/no-computed-properties-in-native-classes */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { reads } from '@ember/object/computed';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';
import { DEFAULT_FLOW_NAME } from 'garaje/utils/enums';

export default class SideBarLocationMenuComponent extends Component {
  @service isOpen;
  @service featureFlags;
  @service state;
  @service store;
  @service('flow') employeeScreeningFlow;
  @service visitorsOnboarding;
  @service abilities;
  @service currentAdmin;
  @service flashMessages;
  @service router;

  @reads('employeeScreeningFlow.activeEmployeeScreeningFlow') activeEmployeeScreeningFlow;
  @reads('state.currentLocation') currentLocation;
  @reads('state.vrSubscription') vrSubscription;
  @reads('state.workplaceSubscription') workplaceSubscription;
  @reads('state.deliveriesSubscription') deliveriesSubscription;
  @reads('state.desksSubscription') desksSubscription;
  @reads('currentAdmin.isGlobalAdmin') isGlobalAdmin;
  @reads('currentAdmin.isLocationAdmin') isLocationAdmin;
  @reads('state.currentCompany') currentCompany;

  @tracked hasFloorWithMap = false;

  employeeRegistrationFilter = DEFAULT_FLOW_NAME.EMPLOYEE_SCREENING;

  get canViewWelcomePage() {
    if (
      this.state.currentUser.visitorsProductVideosEnabled &&
      this.featureFlags.isEnabled('webinar-signup') &&
      (this.isGlobalAdmin || this.isLocationAdmin)
    ) {
      return true;
    }
    return false;
  }

  get showApprovalsLink() {
    return this.abilities.can('review invites');
  }

  get shouldHideNavItems() {
    return !this.state.hasSubscriptions && this.featureFlags.isEnabled('growth_hide_nav_items_before_trial');
  }

  get canViewDeviceAlertsNav() {
    return this.abilities.can('update printer notifications') || this.abilities.can('update device notifications');
  }

  hasFloorWithMapTask = task({ drop: true }, async () => {
    try {
      const floors = await this.store.query('floor', { filter: { 'location-id': this.state.currentLocation.id } });
      const floorsWithFloorPlan = floors.filter((floor) => {
        return floor.floorPlanUrl;
      });
      this.hasFloorWithMap = isPresent(floorsWithFloorPlan);
    } catch (error) {
      this.flashMessages.showFlash(
        'error',
        'Some of our backend systems are currently down.  Desks-related features may not work properly.  This should be resolved soon.'
      );
      this.hasFloorWithMap = false;
    }
  });
}
