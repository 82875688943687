import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type PrinterModel from 'garaje/models/abstract/printer';
import type DeviceModel from 'garaje/models/device';

interface PrinterInfoConnectionStatusArgs {
  goToIPadPage: () => unknown;
  printer: PrinterModel;
}

export default class PrinterInfoConnectionStatus extends Component<PrinterInfoConnectionStatusArgs> {
  @service declare router: RouterService;

  get connectedOnlineDevices(): DeviceModel[] {
    const devices = this.args.printer.devices;
    return devices.filter(
      (device) =>
        device.printerStatus && device.isIpadSeenRecently && device.printerStatus.toLowerCase() === 'connected'
    );
  }

  get connectedDevices(): DeviceModel[] {
    const devices = this.args.printer.devices;
    return devices.filter((device) => device.printerStatus && device.printerStatus.toLowerCase() === 'connected');
  }

  get notConnectedDevicesCount(): number {
    const devices = <number>this.args.printer.devices.length;
    const connectedOnlineDevices = this.connectedOnlineDevices.length;
    return devices - connectedOnlineDevices;
  }

  get displayPrinterDetails(): boolean {
    const details = this.args.printer.status;
    const status = this.args.printer.printerStatus;
    return !!details && details.toLowerCase() !== 'connected' && !['checkIpads', 'notInUse'].includes(status);
  }

  @action
  transitToIPadPage(): void {
    const goToIPadPage = this.args.goToIPadPage;
    if (goToIPadPage) {
      goToIPadPage();
    } else {
      void this.router.transitionTo('visitors.devices.ipads');
    }
  }
}
