import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';
import type CompanyModel from 'garaje/models/company';
import type LocationModel from 'garaje/models/location';
import type PluginModel from 'garaje/models/plugin';
import adapter from 'garaje/utils/decorators/adapter';

type PluginInstallStatus = 'active' | 'disabled' | 'failing' | 'pending' | 'uninstalled';

@adapter('v2')
class PluginInstallModel extends Model {
  @belongsTo('company') declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('location') declare location: LocationModel;
  @belongsTo('plugin') declare plugin: PluginModel;

  @attr() declare config: unknown;
  @attr('number') declare configureStep: number;
  @attr('number') declare releaseId: number;
  @attr('string') declare status: PluginInstallStatus;
  @attr('array') declare authorizedScopes: string[];
  @attr('string') declare updatedAt: string;

  declare setupAction: ReturnType<typeof memberAction>;
  declare authorizeScopesAction: ReturnType<typeof memberAction>;
}

PluginInstallModel.prototype.setupAction = memberAction({
  path: 'setup',
});
PluginInstallModel.prototype.authorizeScopesAction = memberAction({
  path: 'authorize-scopes',
  type: 'post',
});

export default PluginInstallModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'plugin-install': PluginInstallModel;
  }
}
