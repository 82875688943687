import { warn } from '@ember/debug';
import bugsnag from 'bugsnag-js';
import type Report from 'bugsnag-js/types/report';
import config from 'garaje/config/environment';
import LogRocket from 'logrocket';

interface BugSnagClient {
  user?: {
    id: string;
    name: string;
  };
  metaData?: {
    userData: {
      company?: {
        id: string;
        name: string;
      };
      location?: {
        id: string;
        name: string;
      };
      zone?: {
        id: string;
        name: string;
      };
    };
  };
  notify(message: string | Error, options: { severity: string }): void;
}

const beforeSend = (report: Report) => {
  LogRocket.getSessionURL((sessionURL) => {
    report.updateMetaData('LogRocket', {
      urlAtTime: sessionURL,
    });
  });
};

const bugsnagFactory = (apiKey: string): BugSnagClient => {
  if (apiKey && config.environment === 'production') {
    const bugsnagClient = bugsnag({ apiKey, beforeSend });
    // @ts-ignore
    bugsnagClient.app.version = config.bugsnagConfig.appVersion;
    return bugsnagClient as BugSnagClient;
  }

  warn(
    `Bugsnag is disabled in ${config.environment}.\n` +
      `To enable it, you will need to add a \`BUGSNAG_TOKEN\` entry containing the API key to your \`.env\` file.`,
    { id: 'logger' }
  );

  return {
    notify() {},
  };
};

// Use module exports to create an importable bugsnag singleton
export default bugsnagFactory(config.bugsnagConfig.apiKey);
