import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AddEmployeeRoute extends Route {
  @service abilities;
  @service router;

  beforeModel() {
    if (this.abilities.cannot('manage employees')) {
      this.router.transitionTo('unauthorized');
    }
  }
}
