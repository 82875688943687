"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatsigInitializationTimeoutError = exports.StatsigSDKKeyMismatchError = exports.StatsigInvalidArgumentError = exports.StatsigUninitializedError = void 0;
var StatsigUninitializedError = /** @class */ (function (_super) {
    __extends(StatsigUninitializedError, _super);
    function StatsigUninitializedError(message) {
        var _this = _super.call(this, message !== null && message !== void 0 ? message : 'Call and wait for initialize() to finish first.') || this;
        Object.setPrototypeOf(_this, StatsigUninitializedError.prototype);
        return _this;
    }
    return StatsigUninitializedError;
}(Error));
exports.StatsigUninitializedError = StatsigUninitializedError;
var StatsigInvalidArgumentError = /** @class */ (function (_super) {
    __extends(StatsigInvalidArgumentError, _super);
    function StatsigInvalidArgumentError(message) {
        var _this = _super.call(this, message) || this;
        Object.setPrototypeOf(_this, StatsigInvalidArgumentError.prototype);
        return _this;
    }
    return StatsigInvalidArgumentError;
}(Error));
exports.StatsigInvalidArgumentError = StatsigInvalidArgumentError;
var StatsigSDKKeyMismatchError = /** @class */ (function (_super) {
    __extends(StatsigSDKKeyMismatchError, _super);
    function StatsigSDKKeyMismatchError(message) {
        var _this = _super.call(this, message) || this;
        Object.setPrototypeOf(_this, StatsigSDKKeyMismatchError.prototype);
        return _this;
    }
    return StatsigSDKKeyMismatchError;
}(Error));
exports.StatsigSDKKeyMismatchError = StatsigSDKKeyMismatchError;
var StatsigInitializationTimeoutError = /** @class */ (function (_super) {
    __extends(StatsigInitializationTimeoutError, _super);
    function StatsigInitializationTimeoutError(timeoutMs) {
        var _this = _super.call(this, "The initialization timeout of ".concat(timeoutMs, "ms has been hit before the network request has completed.")) || this;
        Object.setPrototypeOf(_this, StatsigInitializationTimeoutError.prototype);
        return _this;
    }
    return StatsigInitializationTimeoutError;
}(Error));
exports.StatsigInitializationTimeoutError = StatsigInitializationTimeoutError;
