import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { Ability } from 'ember-can';
import type LocationModel from 'garaje/models/location';
import type LocationRoleModel from 'garaje/models/location-role';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';
import { getLegacyRoleName } from 'garaje/utils/custom-roles';
import { GLOBAL_ADMIN, isLocationRole, isCompanyRole, LOCATION_ADMIN, RECEPTIONIST } from 'garaje/utils/roles';
import { Permission } from 'garaje/utils/ui-permissions';
import _intersection from 'lodash/intersection';

const CAN_MANAGE_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN];

const CAN_VISIT_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST];

const isLocationAdminAtLocation = (currentAdmin: CurrentAdminService, locationId?: string) => {
  return holdsAdminRolesAtLocation(currentAdmin, locationId, [LOCATION_ADMIN]);
};

const holdsAdminRolesAtLocation = (
  currentAdmin: CurrentAdminService,
  locationId: string | undefined,
  adminRoles: string[]
) => {
  return currentAdmin.locationRoles
    .filter((role) => adminRoles.includes(role.roleName))
    .map((role) => role.belongsTo('location').id())
    .includes(locationId!);
};

export default class AdminRoleAbility extends Ability {
  declare model: string;

  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;
  @service declare authz: AuthzService;

  @tracked role: LocationRoleModel | null = null; // used for admin roles edit dialog

  @tracked roles: Record<string, LocationModel[]> = {}; // used for admin roles table

  // userId (each user/row in the admins table) are used to stop an
  // admin from editing / deleting their own roles
  @tracked userId = null; // used for admin roles table delete + edit dialog

  get canManage(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_MANAGE_ROLES, roleNames));
  }

  get canEdit(): boolean {
    return this.#canEdit(this.role);
  }

  // can the currentAdmin see the edit icon on the admins table
  get canEditRoles(): boolean {
    if (!this.roles) {
      return false;
    }

    const { roleNames } = this.currentAdmin;
    // global admins can edit all other admins
    if (roleNames.includes(GLOBAL_ADMIN)) {
      return true;
    }

    return Object.keys(this.roles).some((roleName) => {
      const locations = this.roles[roleName];
      const roleNameToCheck = getLegacyRoleName(roleName);

      if (isCompanyRole(roleNameToCheck)) {
        return this.#canEdit({ roleName: roleNameToCheck });
      } else if (locations) {
        // check if currentAdmin can manage at least one loc
        return locations.some((location) => {
          return this.#canEdit(
            {
              roleName: roleNameToCheck,
              location,
            },
            location.id
          );
        });
      } else {
        return false;
      }
    });
  }

  // can the currentAdmin see the delete icon on the admins table
  get canDeleteRoles(): boolean {
    const currentAdmin = this.currentAdmin;
    const { roleNames } = currentAdmin;

    let canDelete = false;

    // admins cannot delete themselves
    if (this.userId && this.currentAdmin.id === this.userId) {
      canDelete = false;
    } else if (roleNames.includes(GLOBAL_ADMIN)) {
      canDelete = true;
    } else if (roleNames.includes(LOCATION_ADMIN)) {
      canDelete = this.canEditRoles;
    }

    return canDelete;
  }

  get canVisit(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_VISIT_ROLES, roleNames));
  }

  get canVisitLocation(): boolean {
    const { roleNames } = this.currentAdmin;
    const locationId = this.model;
    return (
      roleNames.includes(GLOBAL_ADMIN) || holdsAdminRolesAtLocation(this.currentAdmin, locationId, CAN_VISIT_ROLES)
    );
  }

  get canCreateRole(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.USER_MANAGEMENT_ADMIN_CREATE);
  }

  get canUseProperty(): boolean {
    return !!this.state.connectSubscription;
  }

  #canEdit(role: Partial<LocationRoleModel> | null, locationId = role?.belongsTo?.('location').id()): boolean {
    const roleNameToEdit = role?.roleName ?? null;
    const currentAdminRoleNames = this.currentAdmin.roleNames;
    const currentLocation = this.state.currentLocation;

    const userId = this.userId;

    if (currentAdminRoleNames.includes(GLOBAL_ADMIN)) {
      // currentAdmin cannot edit their own global admin role
      return !(userId && this.currentAdmin.id === userId && roleNameToEdit === GLOBAL_ADMIN);
    } else if (currentAdminRoleNames.includes(LOCATION_ADMIN)) {
      if (roleNameToEdit) {
        // is currentAdmin editing their own roles
        if (userId && this.currentAdmin.id === userId && roleNameToEdit === LOCATION_ADMIN) {
          // currentAdmin cannot edit their location admin role at the current location
          return !(isLocationAdminAtLocation(this.currentAdmin, locationId) && locationId === currentLocation.id);
        } else {
          // currentAdmin is editing another user
          return isLocationRole(roleNameToEdit) && isLocationAdminAtLocation(this.currentAdmin, locationId);
        }
      } else if (role) {
        // role name doesn't exist
        return isLocationAdminAtLocation(this.currentAdmin, locationId);
      }
    }

    return false;
  }
}
