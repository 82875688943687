import LogRocket from 'logrocket';

type LogRocketStringCategory = {
  category: string;
  min: number;
  max: number;
};

const employeeCountCategories: LogRocketStringCategory[] = [
  { category: '1-150', min: 1, max: 150 },
  { category: '151-500', min: 151, max: 500 },
  { category: '501-2000', min: 501, max: 2000 },
  { category: '2001-5000', min: 2001, max: 5000 },
  { category: '5001-10000', min: 5001, max: 10000 },
  { category: '10001-5000', min: 10001, max: 50000 },
  { category: '50001+', min: 50001, max: Infinity },
];

const activeLocationCountCategories: LogRocketStringCategory[] = [
  { category: '1-10', min: 1, max: 10 },
  { category: '11-25', min: 11, max: 25 },
  { category: '26-50', min: 26, max: 50 },
  { category: '51-100', min: 51, max: 100 },
  { category: '101-150', min: 101, max: 150 },
  { category: '151-200', min: 151, max: 200 },
  { category: '201+', min: 201, max: Infinity },
];

export function getActiveEmployeeCategory(employeeCount?: number | null): string {
  if (typeof employeeCount !== 'number' || isNaN(employeeCount)) {
    return 'unknown';
  }

  const foundCategory = employeeCountCategories.find(({ min, max }) => employeeCount >= min && employeeCount <= max);
  return foundCategory ? foundCategory.category : 'unknown';
}

export function getActiveLocationsCategory(activeLocationsCount?: number | null): string {
  if (typeof activeLocationsCount !== 'number' || isNaN(activeLocationsCount)) {
    return 'unknown';
  }
  const foundCategory = activeLocationCountCategories.find(
    ({ min, max }) => activeLocationsCount >= min && activeLocationsCount <= max
  );
  return foundCategory ? foundCategory.category : 'unknown';
}

export function logLCPToLogRocket(): void {
  if ('PerformanceObserver' in window) {
    const observer = new PerformanceObserver((entryList) => {
      const entries = entryList.getEntriesByType('largest-contentful-paint');
      if (entries.length > 0) {
        const lcpEntry = entries[entries.length - 1];
        if (lcpEntry) {
          const roundedMsTime = Math.floor(lcpEntry?.startTime);
          const roundedSecTime = parseFloat((lcpEntry?.startTime / 1000).toFixed(2));
          LogRocket.track('[dashboard] App Load LCP', {
            startTimeMs: roundedMsTime,
            startTimeSec: roundedSecTime,
            requestedPage: window?.location?.href,
          });
        }
      }
      observer.disconnect();
    });

    observer.observe({ type: 'largest-contentful-paint', buffered: true });
  }
}
