import { belongsTo, hasMany } from '@ember-data/model';
import type { SyncHasMany, AsyncBelongsTo } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';

import AbstractConfig from './abstract/abstract-config';
import type FlowModel from './flow';
import type WelcomeScreenSlideModel from './welcome-screen-slide';
import type ZoneModel from './zone';

export default class PropertyDeviceConfigModel extends AbstractConfig {
  @belongsTo('zone')
  declare property: AsyncBelongsTo<ZoneModel>;

  @hasMany('flow', { async: false })
  declare flows: SyncHasMany<FlowModel>;

  @hasMany('welcome-screen-slide', { async: false })
  declare welcomeScreenSlides: SyncHasMany<WelcomeScreenSlideModel>;
}

PropertyDeviceConfigModel.prototype.deleteWelcomeImage = memberAction({
  type: 'DELETE',
  path: 'attachments/welcome-image',
});

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'property-device-config': PropertyDeviceConfigModel;
  }
}
