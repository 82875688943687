import Component from '@glimmer/component';
import { action, setProperties, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { dropTask, restartableTask } from 'ember-concurrency';
import { filter } from 'macro-decorators';
import { htmlSafe } from '@ember/template';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { inject as service } from '@ember/service';
import { getUnixTime, fromUnixTime, subMinutes, endOfDay, startOfDay, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { DESK_ICON, CURRENT_DESK_ICON, RESERVED_DESK_ICON } from 'garaje/utils/map-icons';
import { getPartialTimes } from 'garaje/utils/hour-options';

/**
 * @param {Task}        searchTask
 * @param {Task}        loadMoreTask
 * @param {Task}        reservationModalTask
 * @param {Boolean}     hasMorePages
 * @param {Array}       selectedDates
 * @param {Array}       selectedDesks
 * @param {Function}    setSelectedDesk
 */

export default class ReservationModalMap extends Component {
  @service flashMessages;
  @service store;
  @service state;

  @tracked isMapReady = false;
  @tracked mapHeight;
  @tracked mapWidth;
  @tracked floors = [];
  @tracked currentFloor;
  @tracked desks = [];
  @tracked reservations = [];
  @tracked selectedDayIdx = 0;

  /**
   * @returns {Array<Desk>} All desks that have a position (are placed on the map)
   */
  @filter('desks', (desk) => desk.placed && !desk.isDeleted) placedDesks;

  @filter('floors', (floor) => floor.floorPlanUrl) floorsWithMap;

  get placedDesksWithIcons() {
    const { selectedDesks } = this.args;
    return this.placedDesks.map((desk) => {
      const reservation = this.deskIdToReservationMap[desk.id];
      if (selectedDesks[this.selectedDayIdx]?.id === desk.id) {
        this.setDeskIcon(desk, CURRENT_DESK_ICON);
      } else if (this.isCurrentlyReservedDesk(desk)) {
        this.setDeskIcon(desk, RESERVED_DESK_ICON);
      } else if (reservation) {
        reservation.user.then((user) => {
          const monogramHtml = this.getUserInitialsHtml(user.fullName, reservation);
          setProperties(desk, {
            iconClassname: '',
            iconSize: [30, 30],
            iconHtml: monogramHtml,
          });
        });
      } else {
        this.setDeskIcon(desk, DESK_ICON);
      }
      return desk;
    });
  }

  get deskIds() {
    return this.desks.map((desk) => desk.id);
  }

  get selectedDay() {
    return this.args.selectedDates[this.selectedDayIdx];
  }

  get selectedDayFormatted() {
    const { selectedTime, allDayEnabled, canUsePartialDay } = this.args;
    const localTime = subMinutes(this.selectedDay, this.state.minutesBetweenTimezones);
    if (canUsePartialDay && !allDayEnabled) {
      const date = format(localTime, 'EEEE, LLL dd, yyyy');
      const midnight = startOfDay(utcToZonedTime(this.selectedDay, this.state.currentLocation.timezone));
      const [partialStart, partialEnd] = getPartialTimes(midnight, selectedTime);
      return `${date} from ${format(partialStart, 'h:mmaaa')} to ${format(partialEnd, 'h:mmaaa')}`;
    }
    return format(localTime, 'EEEE, LLL dd, yyyy');
  }

  get currentSelectedDesk() {
    return this.args.selectedDesks[this.selectedDayIdx];
  }

  isCurrentlyReservedDesk(desk) {
    const { allDayEnabled, currentReservation, selectedTime } = this.args;
    if (currentReservation) {
      const isSameDesk = get(currentReservation, 'desk.id') === desk.id;
      const isSameDayReservation =
        getUnixTime(
          this.state.getOfficeLocationTime(startOfDay(subMinutes(this.selectedDay, this.state.minutesBetweenTimezones)))
        ) ===
        getUnixTime(
          this.state.getOfficeLocationTime(
            startOfDay(subMinutes(fromUnixTime(currentReservation.startTime), this.state.minutesBetweenTimezones))
          )
        );
      if (allDayEnabled) {
        return isSameDesk && isSameDayReservation;
      } else {
        const midnight = startOfDay(utcToZonedTime(this.selectedDay, this.state.currentLocation.timezone));
        const [partialStart, partialEnd] = getPartialTimes(midnight, selectedTime);
        const isOverlappingCurrentRes =
          getUnixTime(this.state.getOfficeLocationTime(partialStart)) < currentReservation.endTime &&
          getUnixTime(this.state.getOfficeLocationTime(partialEnd)) > currentReservation.startTime;
        return isSameDesk && isSameDayReservation && isOverlappingCurrentRes;
      }
    }
    return false;
  }

  setDeskIcon(desk, icon) {
    setProperties(desk, {
      iconClassname: icon.iconClassname,
      iconSize: icon.iconSize,
      iconHtml: '',
    });
  }

  @dropTask
  *loadFloorsTask() {
    const { isEditing, currentReservation } = this.args;
    const floors = yield this.state.currentLocation.floors;
    this.floors = floors;
    if (floors) {
      if (isEditing) {
        const floorWithReservation = yield get(currentReservation, 'desk.floor');
        this.currentFloor = floorWithReservation.floorPlanUrl ? floorWithReservation : this.floorsWithMap.firstObject;
      } else {
        this.currentFloor = this.floorsWithMap.firstObject;
      }
    } else {
      this.currentFloor = null;
    }
  }

  @dropTask
  *onModalLoad() {
    yield this.loadFloorsTask.perform();
    yield this.loadAvailableDesksTask.perform();
    yield this.loadReservationsTask.perform();
    yield this.loadMapImageTask.perform();
  }

  @restartableTask
  *loadReservationsTask() {
    const { allDayEnabled, selectedTime } = this.args;
    try {
      const midnight = this.state.getOfficeLocationTime(
        startOfDay(utcToZonedTime(this.selectedDay, this.state.currentLocation.timezone))
      );
      const [partialStart, partialEnd] = getPartialTimes(midnight, selectedTime);
      const endLocationTime = this.state.getOfficeLocationTime(
        endOfDay(utcToZonedTime(midnight, this.state.currentLocation.timezone))
      );
      const startTime = allDayEnabled ? midnight : partialStart;
      const endTime = allDayEnabled ? endLocationTime : partialEnd;

      const filter = {
        'location-id': get(this.state.currentLocation, 'id'),
        'start-date': getUnixTime(startTime),
        'end-date': getUnixTime(endTime),
        'floor-id': this.currentFloor.id,
      };

      const reservationParams = {
        filter,
        include: 'desk',
      };
      const reservations = yield this.store.query('reservation', reservationParams);
      this.reservations = reservations.toArray();
    } catch (e) {
      this.flashMessages.showFlash('Error fetching reservations', parseErrorForDisplay(e));
      // eslint-disable-next-line no-console
      console.log({ e });
    }
  }

  @restartableTask
  *loadAvailableDesksTask() {
    const { selectedEmployee } = this.args;
    const params = {};

    params.filter = {
      'location-id': get(this.state.currentLocation, 'id'),
      'accessible-for': selectedEmployee.email,
    };
    const desks = yield this.store.query('desk', params);
    this.desks = desks.filter((desk) => get(desk, 'floor.id') === this.currentFloor?.id && desk.enabled);
  }

  @dropTask
  *loadMapImageTask() {
    yield new Promise((resolve, reject) => {
      const image = new Image();
      image.src = this.currentFloor.floorPlanUrl;
      image.onerror = (err) => {
        // eslint-disable-next-line no-console
        console.error('error loading map', err);
        reject();
      };
      image.onload = (ev) => {
        const { height: mapHeight, width: mapWidth } = ev.target;
        setProperties(this, {
          mapHeight,
          mapWidth,
        });
        resolve();
      };
    });
  }

  @dropTask
  *onFloorChange(floor) {
    this.currentFloor = floor;
    yield this.loadAvailableDesksTask.perform();
    yield this.loadReservationsTask.perform();
    yield this.loadMapImageTask.perform();
  }

  @action
  toggleIsMapReady() {
    this.isMapReady = true;
  }

  @action
  onDeskSelect(desk) {
    const { setSelectedDesk, currentReservation } = this.args;
    if (!this.deskIdToReservationMap[desk.id] || currentReservation?.belongsTo('desk').id() === desk.id) {
      const selected = this.desks.find((deskCheck) => deskCheck.id === desk.id);
      setSelectedDesk(selected, this.selectedDayIdx);
    }
  }

  @dropTask
  *onNextTask() {
    this.selectedDayIdx += 1;
    yield this.loadReservationsTask.perform();
  }

  @dropTask
  *onBackTask() {
    const { setCurrentPage } = this.args;
    if (this.selectedDayIdx === 0) {
      setCurrentPage('employee');
      this.isMapReady = false;
    } else {
      this.selectedDayIdx -= 1;
      yield this.loadReservationsTask.perform();
    }
  }

  monogramInitials(fullName) {
    let nameAttr = fullName ?? '';

    if (/\+\d+/.test(nameAttr)) {
      nameAttr = nameAttr.split('+')[0];
    }

    const nameArray = nameAttr.split(' ');
    let name = '';
    for (let i = 0; i < nameArray.length; i++) {
      name += nameArray[i].slice(0, 1);
    }
    return name.slice(0, 4); // max 4 chars
  }

  lengthClass(name) {
    const monogramInitials = this.monogramInitials(name);

    switch (monogramInitials.length) {
      case 2:
        return 'two';
      case 3:
        return 'three';
      case 4:
        return 'four';
    }
    return '';
  }

  safeStyle() {
    const bg = `background-color: hsl(224, 8%, 48%)`;
    return htmlSafe(bg);
  }

  getUserInitialsHtml(userFullName) {
    return htmlSafe(`<div aria-hidden="true" class="table-cell align-middle py-2" style="width:30px; min-width:30px">
      <div
        data-test-entry-monogram
        class="monogram small ${this.lengthClass(userFullName)}"
        style="${this.safeStyle()}"
      >
        ${this.monogramInitials(userFullName)}
      </div>
    </div>`);
  }

  get deskIdToReservationMap() {
    const map = {};
    this.reservations.forEach((reservation) => {
      const deskId = get(reservation, 'desk.id');
      if (deskId) {
        map[deskId] = reservation;
      }
    });
    return map;
  }

  @action
  onMapClick() {
    const { setSelectedDesk } = this.args;
    setSelectedDesk(null, this.selectedDayIdx);
  }
}
