import type ArrayProxy from '@ember/array';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type Router from '@ember/routing/router-service';
import { debounce } from '@ember/runloop';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { task, dropTask, keepLatestTask } from 'ember-concurrency';
import type AnnouncementTemplateModel from 'garaje/models/announcement-template';
import type AnnouncementTemplateCategoryModel from 'garaje/models/announcement-template-category';
import type GroupModel from 'garaje/models/group';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import { type TemplateViewData } from 'garaje/pods/components/announcement-template/announcement-template-list/component';
import type MetricsService from 'garaje/services/metrics';
import { getTemplateViewData } from 'garaje/utils/announcements';
import { defer } from 'rsvp';

import { type CommunicationsTemplateListModel } from './route';

// keys for search
type SearchableField = 'name' | 'title' | 'message' | 'categoryName' | 'locationsToDisplay';
const searchableFields: SearchableField[] = ['name', 'title', 'message', 'categoryName', 'locationsToDisplay'];

export default class CommunicationsTemplateListController extends Controller {
  declare model: CommunicationsTemplateListModel;

  @service declare store: Store;
  @service declare router: Router;
  @service declare metrics: MetricsService;

  @tracked selectedCategory: AnnouncementTemplateCategoryModel | null = null;
  @tracked selectedLocationIds: string[] = [];

  @tracked searchQuery = '';
  @tracked filteredTemplateViewData: TemplateViewData[] = [];

  @tracked templateViewData: TemplateViewData[] = [];
  @tracked templatesWithLocations = new Map();

  @tracked totalCount = 0;
  @tracked currentPage = 1;
  @tracked pageSize = 25;

  get hasMorePages(): boolean {
    return this.pageSize * this.currentPage < this.totalCount;
  }

  loadMore = keepLatestTask(async () => {
    if (!this.hasMorePages) {
      return;
    }

    const offset = this.currentPage * this.pageSize;
    this.currentPage++;
    const newTemplates = await this.store.query('announcement-template', {
      page: {
        offset: offset,
        limit: this.pageSize,
      },
      sort: '-createdAt',
    });

    if (Number(newTemplates.length) > 0) {
      const newTemplateViewData = await this.processNewTemplates(newTemplates.toArray());
      this.templateViewData.push(...newTemplateViewData);
      this.filteredTemplateViewData = this.templateViewData;
      await this.performSearch(); // Update filtered results
    }
  });

  updateSearchQuery = (value: string): void => {
    this.searchQuery = value;
    debounce(this, this.performSearch, 300);
  };

  get selectedLocationIdsString(): string {
    return this.selectedLocationIds.join(',');
  }

  async processNewTemplates(templates: AnnouncementTemplateModel[]): Promise<TemplateViewData[]> {
    return await Promise.all(
      templates.map(async (template) => {
        const category = await template.announcementTemplateCategory;
        return getTemplateViewData(template, category);
      })
    );
  }

  initializeData = task({ drop: true }, async () => {
    if (this.model.announcementTemplates) {
      await this.generateTableDataWithCategoryNamesAndLocations.perform();
      this.currentPage = 1;
      this.totalCount = this.model.announcementTemplates?.meta?.total || 0;
    } else {
      throw new Error('No announcement templates found');
    }
  });

  performSearch = async (): Promise<void> => {
    const query = this.searchQuery.toLowerCase().trim();
    const selectedLocationIds = this.selectedLocationIds;
    if (!query && !this.selectedCategory && selectedLocationIds.length === 0) {
      this.filteredTemplateViewData = this.templateViewData;
    } else {
      this.filteredTemplateViewData = this.templateViewData.filter((data) => {
        const matchesSearch =
          !query ||
          searchableFields.some((key) => {
            const value = data[key];
            return typeof value === 'string' && value.toLowerCase().includes(query);
          });

        const matchesCategory = !this.selectedCategory || data.categoryName === this.selectedCategory.name;

        const matchesLocation =
          selectedLocationIds.length === 0 ||
          (data.locationId && data.locationId.some((id) => selectedLocationIds.includes(id)));

        return matchesSearch && matchesCategory && matchesLocation;
      });
    }
    await this.checkIfMoreDataNeeded();
  };

  async checkIfMoreDataNeeded(): Promise<void> {
    if (this.filteredTemplateViewData.length < this.pageSize && this.hasMorePages) {
      await this.loadMore.perform();
    }
  }

  generateTableDataWithCategoryNamesAndLocations = task({ drop: true }, async (): Promise<void> => {
    const templatesWithCategoriesAndLocations = await Promise.all(
      this.model.announcementTemplates.map(async (template: AnnouncementTemplateModel) => {
        const category = await template.announcementTemplateCategory;
        return getTemplateViewData(template, category);
      })
    );
    this.templateViewData = templatesWithCategoriesAndLocations.sort((a, b) => {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    });
    this.filteredTemplateViewData = this.templateViewData;
  });

  get availableLocations(): SkinnyLocationModel[] {
    return this.store.peekAll('skinny-location').toArray();
  }

  get groups(): GroupModel[] {
    return this.store.peekAll('group').toArray();
  }

  get categoryOptions(): ArrayProxy<AnnouncementTemplateCategoryModel> {
    return this.store.peekAll('announcement-template-category').sortBy('name');
  }

  @action
  async updateCategories(category: AnnouncementTemplateCategoryModel | null): Promise<void> {
    this.selectedCategory = category;
    await this.performSearch();
  }

  @action
  async updateLocationsFilter(options: SkinnyLocationModel[]): Promise<void> {
    // already selected ids
    let ids = this.selectedLocationIds;

    // add an id of a clicked location if it's not already selected
    options.forEach(function (option) {
      const optionId = option.id;
      ids = ids.includes(optionId)
        ? ids.filter(function (id) {
            return id !== optionId;
          })
        : ids.concat(optionId);
    });

    this.selectedLocationIds = ids;

    await this.performSearch();
  }

  async updateGroups(): Promise<void> {}
  @dropTask
  createTemplateTask = {
    *perform(this: {
      context: CommunicationsTemplateListController;
      abort?: () => void;
      continue?: (template?: string) => void;
    }): Generator<Promise<boolean>, void, void> {
      const deferred = defer<boolean>();

      this.abort = () => deferred.resolve(false);

      this.continue = (example_id?: string) => {
        deferred.resolve(true);
        this.context.metrics.trackEvent('Selected pre-built announcement template', { id: example_id });
        void this.context.router.transitionTo('location-overview.communications.templates.new', {
          queryParams: { example_id },
        });
      };

      return yield deferred.promise;
    },
  };
}
