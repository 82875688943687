import Service from '@ember/service';
import type { Attributes, ComponentClass, FunctionComponent } from 'react';
import React from 'react';
import type { Root } from 'react-dom/client';
import { createRoot } from 'react-dom/client';

export default class ReactRendererService extends Service {
  render<P extends object>(
    component: FunctionComponent<P> | ComponentClass<P>,
    elementId: string,
    props?: Attributes & P
  ): Root {
    const renderedComponent = React.createElement<P>(component, props, null);

    const element = document.getElementById(elementId);

    if (!element) {
      throw new Error(`Element with id ${elementId} was not found.`);
    }

    const root = createRoot(element);

    // Issue with React's inbuilt typing, so we need to force an any assignment here
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
    root.render(renderedComponent as any);

    return root;
  }

  rerender<P extends object>(
    component: FunctionComponent<P> | ComponentClass<P>,
    root: Root,
    props?: Attributes & P
  ): void {
    const renderedComponent = React.createElement<P>(component, props, null);

    // Issue with React's inbuilt typing, so we need to force an any assignment here
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
    root.render(renderedComponent as any);
  }

  unmount(root: Root): void {
    root.unmount();
  }
}
