import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';

import type UserDocumentModel from './user-document';
import type UserDocumentLinkable from './user-document-linkable';

class UserDocumentLinkModel extends Model {
  @belongsTo('user-document') declare userDocument: AsyncBelongsTo<UserDocumentModel> | UserDocumentModel;
  @belongsTo('user-document-linkable', { polymorphic: true })
  declare userDocumentLinkable: AsyncBelongsTo<UserDocumentLinkable> | UserDocumentLinkable;

  @attr('string') declare approvalStatus: string;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;

  formattedStatuses: { [key: string]: string } = {
    approved: 'Approved',
    denied: 'Denied',
    review: 'Pending review',
  };

  declare approve: (this: Model, payload: unknown) => Promise<Promise<Model>>;
  declare reset: (this: Model, payload: unknown) => Promise<Promise<Model>>;
  declare deny: (this: Model, payload: unknown) => Promise<Promise<Model>>;

  get formattedApprovalStatus(): string {
    return this.formattedStatuses[this.approvalStatus] || 'Not submitted';
  }

  get statusText(): string {
    return this.formattedStatuses[this.approvalStatus] || '';
  }
}

UserDocumentLinkModel.prototype.approve = memberAction({
  type: 'POST',
  path: 'approve',
  after: async function () {
    return this.reload();
  },
});

UserDocumentLinkModel.prototype.reset = memberAction({
  type: 'POST',
  path: 'review',
  after: async function () {
    return this.reload();
  },
});

UserDocumentLinkModel.prototype.deny = memberAction({
  type: 'POST',
  path: 'deny',
  after: async function () {
    return this.reload();
  },
});

export default UserDocumentLinkModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-document-link': UserDocumentLinkModel;
  }
}
