import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { alias } from 'macro-decorators';

export default class LocationBillingIndexController extends Controller {
  @service('location-billing') billingService;
  @service flashMessages;
  @service router;

  @alias('billingService.blockSelfServe') blockSelfServe;
  @alias('billingService.selectedPeriod') selectedPeriod;
  @alias('billingService.renewalDate') renewalDate;
  @alias('billingService.allLocations') locations;
  @alias('billingService.subscribed') subscribed;
  @alias('billingService.paymentSource') paymentSource;

  @tracked forceEditingCC = false;
  @tracked transitionOnSuccess = false;

  @action
  finalizePaymentMethod() {
    this.forceEditingCC = false;
    if (this.transitionOnSuccess) {
      this.router.transitionTo('location-billing.subscriptions');
    }
  }

  @action
  manageSubscriptions() {
    if (this.paymentSource.id) {
      this.transitionToRoute('location-billing.subscriptions');
    } else {
      this.forceEditingCC = true;
      this.transitionOnSuccess = true;
    }
  }
}
