import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type EntryModel from 'garaje/models/entry';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { Permission } from 'garaje/utils/ui-permissions';
import _intersection from 'lodash/intersection';

export default class EntryAbility extends Ability {
  @service declare authz: AuthzService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;

  declare model: EntryModel | undefined;

  get canCreate(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_ENTRY_CREATE);
  }

  get canDelete(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_ENTRY_DELETE);
  }

  get canEdit(): boolean {
    const entry = this.model;
    if (entry) {
      if (this.#isEntryHost || this.#isEntryAssistant) return true;
    }

    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_ENTRY_UPDATE);
  }

  get canViewAll(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_ENTRY_READ);
  }

  get #isEntryAssistant(): boolean {
    if (!this.model) return false;
    if (!this.currentAdmin.employee) return false;

    const bossIds = this.currentAdmin.employee.hasMany('bosses').ids();

    return isPresent(_intersection(bossIds, this.#entryHostIds));
  }

  get #isEntryHost(): boolean {
    if (!this.model) return false;
    if (!this.currentAdmin.employee) return false;

    const employeeId = this.currentAdmin.employee.id;

    return this.#entryHostIds.includes(employeeId);
  }

  get #entryHostIds(): string[] {
    const entry = this.model;
    if (!entry) return [];

    const hostIds: string[] = [];
    const primaryHostId = entry.belongsTo('employee').id();
    if (primaryHostId) {
      hostIds.push(primaryHostId);
    }

    if (this.state.vrSubscription?.canAccessMultipleHosts) {
      // multiple hosts suppport is enabled; combine primary host & additional hosts
      hostIds.push(...entry.hasMany('additionalHosts').ids());
    }
    return hostIds;
  }
}
