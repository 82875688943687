// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { isBlank, isEmpty } from '@ember/utils';
import { get, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { timeout, task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { arrayToSentence } from 'garaje/helpers/array-to-sentence';
import config from 'garaje/config/environment';
import zft from 'garaje/utils/zero-for-tests';

/**
 * @param {String}                      title Title of the form
 * @param {EmberObject}                 changeset
 * @param {Model<Subscription>}         vrSubscription
 * @param {Function}                    searchEmployees Action to search employees
 * @param {Function}                    addEmployee Action to add employee
 * @param {Function}                    addToLocation Action to add from other location to current location
 * @param {Task}                        afterAddEmployeeTask Task to perform after add employee
 */
export default class AddNewEmployeeForm extends Component {
  @service state;
  @service flashMessages;
  @service ajax;
  @service router;
  @service store;
  @service featureFlags;
  @service skinnyLocations;
  @service workplaceMetrics;

  @tracked doesEmployeeExist = false;
  @tracked employeeInCurrentLocation = false;
  @tracked employeeId = null;
  @tracked selectedEmployeeLocations = [];
  @tracked remainingEmployeeLocations = this.availableLocations;
  @tracked employeeLocationsError = false;

  defaultCountry = config.defaultCountry;

  @(employeesSearcherTask().restartable())
  searchAllEmployeesTask;

  _resetForSearch() {
    this.employeeId = null;
    this.doesEmployeeExist = false;
    this.employeeInCurrentLocation = false;
  }

  get isGlobalOverview() {
    return this.router.currentRouteName?.includes('location-overview');
  }

  get availableLocations() {
    return this.skinnyLocations.manageableByCurrentAdmin.map((loc) => {
      const disabled = !isEmpty(loc.disabledToEmployeesAt);
      let nameWithCompanyName = loc.nameWithCompanyName;
      if (disabled) {
        nameWithCompanyName += ' (Disabled)';
      }

      return {
        location: loc,
        disabled,
        nameWithCompanyName,
      };
    });
  }

  @action
  onEmployeeLocationAdd(selectedLoc) {
    this.selectedEmployeeLocations = [...this.selectedEmployeeLocations, selectedLoc];

    if (this.selectedEmployeeLocations.length > 0) {
      this.employeeLocationsError = false;
    }

    this.remainingEmployeeLocations = this.remainingEmployeeLocations.filter(
      (employeeLoc) => employeeLoc.location.id !== selectedLoc.location.id
    );
  }

  @action
  onEmployeeLocationDelete(locToDelete) {
    this.remainingEmployeeLocations = [...this.remainingEmployeeLocations, locToDelete].sort((locA, locB) => {
      if (locA.nameWithCompanyName < locB.nameWithCompanyName) {
        return -1;
      }
      if (locA.nameWithCompanyName > locB.nameWithCompanyName) {
        return 1;
      }
      return 0;
    });
    this.selectedEmployeeLocations = this.selectedEmployeeLocations.filter(
      (employeeLoc) => employeeLoc.location.id !== locToDelete.location.id
    );
  }

  @action
  searchAllEmployees(term) {
    return this.searchAllEmployeesTask.perform(term, {}, { withoutLocation: true });
  }

  searchEmployeesTask = task({ restartable: true }, async (term) => {
    if (isBlank(term) || term.length < 3) {
      return [];
    } else {
      await timeout(zft(250));
      const params = { filter: { query: term } };

      if (!this.isGlobalOverview) {
        params.filter.locations = this.state.currentLocation.id;
      }

      return await this.searchEmployees(params);
    }
  });

  saveTask = task({ drop: true }, async (changeset) => {
    this.workplaceMetrics.trackEvent('EMPLOYEE_DIRECTORY_NEW_EMPLOYEE_SAVE_BUTTON_CLICKED');
    await changeset.validate();

    const isValid = get(changeset, 'isValid');
    const hasSelectedLocations = this.selectedEmployeeLocations.length > 0;
    const hasEmployeeLocations = this.isGlobalOverview ? hasSelectedLocations : true;

    if (!hasEmployeeLocations) {
      this.employeeLocationsError = true;
      this.flashMessages.showFlash('error', 'Please add locations for this employee');
    } else if (!isValid) {
      const errorTypes = [];

      if (get(this, 'changeset.error.name.validation')) {
        errorTypes.push('name');
      }
      if (get(this, 'changeset.error.email.validation')) {
        errorTypes.push('properly formatted email');
      }
      if (get(this, 'changeset.error.phoneNumber.validation')) {
        errorTypes.push('properly formatted phone number');
      }

      const error = `Please have a ${arrayToSentence(errorTypes)}`;
      this.flashMessages.showFlash('error', error);
    } else {
      try {
        if (this.isGlobalOverview && hasSelectedLocations) {
          changeset.locations = [];
        }

        const savedEmployee = await this.addEmployee(changeset);

        if (hasSelectedLocations) {
          this.selectedEmployeeLocations.forEach(async (loc) => {
            await this.saveEmployeeLocation.perform(loc.location, savedEmployee);
          });
        }

        await this.afterAddEmployeeTask.perform(savedEmployee);
        this.flashMessages.showAndHideFlash('success', 'Saved employee!');
      } catch (e) {
        changeset.rollback();
        const errorText = parseErrorForDisplay(e);
        this.flashMessages.showAndHideFlash('error', errorText);
      }
    }
  });

  saveEmployeeLocation = task(async (location, employee) => {
    const employeeLocation = this.store.createRecord('employee-location', {
      location,
      employee,
      manuallyAdded: true,
    });
    await employeeLocation.save();
  });

  checkIfEmployeeExistsTask = task({ restartable: true }, async (email) => {
    if (isBlank(email)) {
      return;
    } else if (email.length < 3) {
      return;
    } else {
      await timeout(zft(250));
      this._resetForSearch();
      try {
        const { currentCompany, currentLocation } = this.state;
        const params = {
          filter: { email, deleted: false, company: currentCompany.id },
          include: 'employee-locations',
        };
        let result = await this.searchEmployees(params, 1);
        result = result.filterBy('email', email);
        const employee = get(result, 'firstObject');

        if (employee) {
          if ((await employee.employeeLocations).length > 0) {
            this.employeeId = employee.id;
            this.doesEmployeeExist = true;

            // Employee exists but may be on this current location
            if (await employee.isEmployedAtLocation(currentLocation.id)) {
              this.employeeInCurrentLocation = true;
            }
          } else {
            this.store.unloadRecord(employee);
          }
        }
      } catch (error) {
        // this end point returns 404 not found if it cannnot find a match
        if (get(error, 'errors.firstObject.status') === '404') {
          this._resetForSearch();
        } else {
          const errorText = parseErrorForDisplay(error);
          this.flashMessages.showAndHideFlash('error', errorText);
        }
      }
    }
  });
}
