import Controller from '@ember/controller';
import { action } from '@ember/object';
import urlBuilder from 'garaje/utils/url-builder';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AnalyticsVisitorsController extends Controller {
  @service state;
  @service messageBus;

  @tracked showModal = false;
  @tracked exportIframeUrl;

  // eslint-disable-next-line ember/classic-decorator-hooks
  init() {
    super.init(...arguments);

    this.messageBus.on('embedded-app-message', this.handleMessage);
  }

  get iframeUrl() {
    const {
      currentCompany: { id: companyId },
      currentLocation: { id: locationId },
    } = this.state;
    return urlBuilder.embeddedVisitorsLocationAnalyticsUrl(companyId, locationId);
  }

  @action
  toggleShowModal() {
    this.showModal = !this.showModal;
  }

  @action
  handleMessage(message) {
    const locationId = message.query?.location_id;
    const companyId = message.query?.company_id;
    const flowId = message.query?.flow_id;
    const start = message.query?.start;
    const end = message.query?.end;
    const label = message.label;

    if (message.event === 'showEntryExportModal') {
      this.exportIframeUrl = urlBuilder.embeddedEntryExportModalUrl(companyId, locationId, flowId, start, end, label);
      this.toggleShowModal();
    } else if (message.event === 'showInviteExportModal') {
      this.exportIframeUrl = urlBuilder.embeddedInviteExportModalUrl(companyId, locationId, flowId, start, end, label);
      this.toggleShowModal();
    } else if (message.event === 'closeExportModal') {
      this.toggleShowModal();
    }
  }
}
