import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import urlBuilder from 'garaje/utils/url-builder';
import { tracked } from '@glimmer/tracking';

export default class DeliveriesAnalyticsController extends Controller {
  @service state;
  @service currentLocation;

  queryParams = ['deliveryAreaId'];

  // workaround for performance issue when tracking queryParams
  // https://github.com/emberjs/ember.js/issues/18715
  @tracked _deliveryAreaId = null;

  get deliveryAreaId() {
    return this._deliveryAreaId;
  }

  set deliveryAreaId(value) {
    this._deliveryAreaId = value;
  }

  get iframeUrl() {
    const {
      currentCompany: { id: companyId },
      currentLocation: { id: locationId },
    } = this.state;

    return urlBuilder.embeddedDeliveriesAnalyticsUrl(companyId, locationId, this.deliveryAreaId);
  }
}
