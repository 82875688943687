import Service, { inject as service } from '@ember/service';
import fetch from 'fetch';
import urlBuilder from 'garaje/utils/url-builder';

// eslint-disable-next-line ember/no-classic-classes
export default Service.extend({
  cookies: service(),

  invalidateEnvoyAuth() {
    const url = urlBuilder.envoyLogoutUrl();
    const csrfToken = this.cookies.read('csrf_token');
    const headers = { 'X-CSRF-Token': csrfToken };
    const options = {
      headers,
      method: 'DELETE',
      credentials: 'include',
    };

    return fetch(url, options);
  },
});
