import Route from '@ember/routing/route';

import type { ProfileDocumentUploadRouteModel } from '../route';

export default class ProfileDocumentUploadReviewRoute extends Route {
  afterModel({ userDocument, userDocumentTemplate }: ProfileDocumentUploadRouteModel): void {
    const requiredTemplateAttachment = userDocument?.checkRequiredTemplateAttachments();

    if (requiredTemplateAttachment) {
      void this.transitionTo(
        'profile.document.upload.attachment',
        userDocumentTemplate!.identifier,
        requiredTemplateAttachment.identifier
      );
    }
  }
}
