import Route from '@ember/routing/route';

class EmployeesDirectortyIndexRoute extends Route {
  resetController(controller, isExiting) {
    if (isExiting) {
      this.send('resetQueryParams');
    }
  }
}

export default EmployeesDirectortyIndexRoute;
