import type NativeArray from '@ember/array/-private/native-array';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { type TaskInstance } from 'ember-concurrency';
import type TenantModel from 'garaje/models/tenant';
import type ConnectInvitesService from 'garaje/services/connect-invites';
import { TenantConnectionStatus } from 'garaje/utils/enums';
import { reads } from 'macro-decorators';

export default class PropertyVisitorsInvitesEditController extends Controller {
  @service declare connectInvites: ConnectInvitesService;

  @reads('model.loadTenantsTask.value') tenants?: NativeArray<TenantModel>;

  get connectedTenants(): TenantModel[] | undefined {
    return this.tenants?.filter((tenant) => tenant.status === TenantConnectionStatus.CONNECTED);
  }

  @action
  onClose(): void {
    // router service transition to unnecessarily reloads the tenants route model
    this.transitionToRoute('property.visitors.invites');
  }

  @action
  onSave(): TaskInstance<void> {
    return this.connectInvites.refreshData.perform();
  }
}
