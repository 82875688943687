export const getStatusCode = function (error) {
  let code = null;
  if (error && Number.isInteger(error.code)) {
    code = error.code;
  } else if (error && error.errors && error.errors.length > 0) {
    code = error.errors[0].status || null;
  }
  return code;
};

export const getErrorDescription = function (error) {
  let description = null;
  if (error && error.message) {
    description = error.message;
  } else if (error && error.description) {
    description = error.description;
  } else if (error && error.errors && error.errors.length > 0) {
    description = error.errors[0].title || null;
  }
  return description;
};

export const getErrorDetail = function (error) {
  let detail = null;
  if (error && error.errors && error.errors.length > 0) {
    detail = error.errors[0].detail || null;
  }
  return detail;
};
