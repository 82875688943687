import type NativeArray from '@ember/array/-private/native-array';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type { Identifier } from 'garaje/models/user-document-template';
import type UserDocumentTemplateAttachmentModel from 'garaje/models/user-document-template-attachment';
import type FlashMessagesService from 'garaje/services/flash-messages';

import type { ProfileDocumentUploadAttachmentRouteModel } from './route';

export default class ProfileDocumentUploadAttachmentController extends Controller {
  declare model: ProfileDocumentUploadAttachmentRouteModel;

  @service declare router: RouterService;
  @service declare flashMessages: FlashMessagesService;

  validFileTypes = /image\/(gif|jpe?g|png)/;

  get templateTitle(): string {
    return this.model?.userDocumentTemplate?.title ?? 'document';
  }

  get instructions(): string {
    // TODO: explore alternatives for custom document upload instructions?
    // For example, SARS-CoV-2 test results do not render additional copy.
    const instructionsMap: Partial<Record<Identifier, string>> = {
      'covid-19-vaccine-card': 'You can upload both a front side and a back side (if applicable).',
    };

    return instructionsMap[this.model?.userDocumentTemplate!.identifier] || '';
  }

  get isRequired(): boolean {
    return Boolean(this.model?.userDocumentTemplateAttachment?.required);
  }

  get sortedUserDocumentTemplateAttachments(): NativeArray<UserDocumentTemplateAttachmentModel> | undefined {
    return this.model.userDocumentTemplate?.userDocumentTemplateAttachments.sortBy('position');
  }

  get attachmentIndex(): number {
    return (
      this.sortedUserDocumentTemplateAttachments!.findIndex(
        (attachment) => attachment === this.model.userDocumentTemplateAttachment
      ) ?? 0
    );
  }

  get nextAttachment(): UserDocumentTemplateAttachmentModel | undefined {
    return this.sortedUserDocumentTemplateAttachments?.[this.attachmentIndex + 1];
  }

  get previousAttachment(): UserDocumentTemplateAttachmentModel | undefined {
    return this.sortedUserDocumentTemplateAttachments?.[this.attachmentIndex - 1];
  }

  get attachmentPrefix(): string {
    const id = this.model?.userDocumentTemplate?.id;

    return id ? `user-documents/${id}/users` : '';
  }

  @action
  onUploadComplete(fileId: string): void {
    const { userDocumentAttachment } = this.model;

    if (!userDocumentAttachment) return;

    userDocumentAttachment.file = fileId;

    this.flashMessages.showAndHideFlash('success', 'Upload success!', { showAboveModalOverlay: true });
  }

  @action
  onFilePreview(src: string): void {
    const { userDocumentAttachment } = this.model;

    if (!userDocumentAttachment) return;

    userDocumentAttachment.fileUrl = src;
  }

  @action
  onReset(): void {
    const { userDocumentAttachment } = this.model;

    if (!userDocumentAttachment) return;

    userDocumentAttachment.file = null;
    userDocumentAttachment.fileUrl = null;
  }

  @action
  onError(): void {
    this.flashMessages.showAndHideFlash('error', 'Image upload failed', { showAboveModalOverlay: true });
  }

  @action
  next(): void {
    const { nextAttachment, router } = this;
    const { userDocumentTemplate } = this.model;
    if (nextAttachment) {
      void router.transitionTo(
        'profile.document.upload.attachment',
        userDocumentTemplate!.identifier,
        nextAttachment.identifier
      );
    } else {
      void router.transitionTo('profile.document.upload.review', userDocumentTemplate!.identifier);
    }
  }

  @action
  skip(): void {
    this.model.userDocumentAttachment?.deleteRecord();
    this.next();
  }

  @action
  previous(): void {
    const { previousAttachment, router } = this;
    const { userDocumentTemplate } = this.model;
    if (previousAttachment) {
      void router.transitionTo(
        'profile.document.upload.attachment',
        userDocumentTemplate!.identifier,
        previousAttachment.identifier
      );
    } else {
      void router.transitionTo('profile.document', userDocumentTemplate!.identifier);
    }
  }
}
