import Ember from 'ember';
import Service from '@ember/service';
import Papa from 'papaparse';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export default class ExporterService extends Service {
  @service csvAjax;

  @task
  *exportCsv(filename, models) {
    const { document, URL } = window;
    const csvData = models.map((model) => {
      try {
        return model.toDataForCsvExport();
      } catch (error) {
        throw new Error('Model must implement toDataForCsvExport()', error);
      }
    });

    const anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = URL.createObjectURL(
      new Blob([Papa.unparse(csvData)], {
        type: 'text/csv',
      })
    );

    document.body.appendChild(anchor);
    anchor.click();
    anchor.remove();
    yield null;
  }

  csvFromUrl(url, settings, filename) {
    this.csvAjax
      .request(url, settings)
      .then((data) => {
        const url = `data:text/csv;charset=UTF-8,${encodeURIComponent(data)}`;
        const anchor = document.createElement('a');
        anchor.download = filename;
        anchor.href = url;
        anchor.setAttribute('data-test-csv-export-link', true);

        if (!Ember.testing) {
          document.body.appendChild(anchor);
          anchor.click();
          anchor.remove();
        } else {
          document.getElementById('ember-testing').appendChild(anchor);
        }

        return;
      })
      .catch((error) => {
        throw error;
      });
  }
}
