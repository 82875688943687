import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { belongsTo, hasMany } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';
import type LocationModel from 'garaje/models/location';
import type VisitorGuideSectionModel from 'garaje/models/visitor-guide-section';
import type { SinglePayload, SingleResponse } from 'jsonapi/response';

class VisitorGuideModel extends Model {
  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;
  @hasMany('visitor-guide-section', { async: true })
  declare visitorGuideSections: AsyncHasMany<VisitorGuideSectionModel>;

  get hasEdits(): boolean {
    return this.visitorGuideSections.any((s) => !!s.hasDirtyAttributes);
  }

  rollbackSections(): void {
    if (this.hasEdits) {
      this.visitorGuideSections.forEach((s) => s.rollbackAttributes());
    }
  }

  declare getHTMLPreview: ReturnType<typeof memberAction>;
}

VisitorGuideModel.prototype.getHTMLPreview = memberAction({
  path: 'preview',
  type: 'POST',
  before() {
    const payload = <SinglePayload<VisitorGuideModel>>this.serialize();
    const sections = (<VisitorGuideModel>this).visitorGuideSections.reduce<
      Array<SingleResponse<VisitorGuideSectionModel>>
    >((acc, section) => {
      if (!section.enabled) {
        return acc;
      }
      const { data } = <SingleResponse<VisitorGuideSectionModel>>section.serialize();
      data.attributes.title = section.title; // title is read only
      data.attributes.iconType = section.iconType; // iconType is read only
      acc.push({ data });
      return acc;
    }, []);
    payload['data']['relationships']['visitor-guide-sections'] = sections;
    return payload;
  },
});

export default VisitorGuideModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'visitor-guide': VisitorGuideModel;
  }
}
