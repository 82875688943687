import { stop } from 'liquid-fire';
/*
 Calls the transition passed in the arguments and executes the
 callback once the transition is over.

 This is useful if we want to do things like focusing an input after
 the transition is finished.

 this.transition(
    this.fromValue('login/login-wizard/email-search'),
    this.use(
      'with-callback',
      'toLeft',
      (c)=> {
        $(c.newElement).find('input').focus();
      }
    ),
    this.reverse(
      'with-callback',
      'toRight',
      (c) => {
        $(c.newElement).find('input').focus();
      }
    )
 );
*/

export default function (transition, callback, opts) {
  stop(this.oldElement);

  // jscs:disable requireSpread
  return this.lookup(transition)
    .apply(this, [opts || {}])
    .then(
      function () {
        callback(this);
      }.bind(this)
    );
  // jscs:enable requireSpread
}
