import type Transition from '@ember/routing/transition';
import { getAnnouncementQueryParams } from 'garaje/helpers/communications/message-log/list/announcement-queries';
import CommunicationsMessageLogRouteBase, {
  type AnnoucementsListModel,
  PAGE_SIZE,
} from 'garaje/helpers/communications/message-log/list/route-base';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type AnnouncementModel from 'garaje/models/announcement';

import type CommunicationsMessageLogListController from './controller';

export default class CommunicationsMessageLogRoute extends CommunicationsMessageLogRouteBase {
  // overriding to fetch data from all locations instead of just current location.
  async loadAnnouncements(startDate: Date, endDate: Date): Promise<PaginatedRecordArray<AnnouncementModel>> {
    try {
      const queryParams = getAnnouncementQueryParams([], startDate, endDate, PAGE_SIZE, 0);
      const result = await this.store.query('announcement', queryParams);

      return result as PaginatedRecordArray<AnnouncementModel>;
    } catch (error) {
      throw new Error(`Failed to load announcements`);
    }
  }

  setupController(
    controller: CommunicationsMessageLogListController,
    model: AnnoucementsListModel,
    transition: Transition
  ): void {
    const { startDate, endDate } = model;
    super.setupController(controller, model, transition, false);
    void controller.initializeData.perform(startDate, endDate, false);
  }
}
