import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import lookupValidator from 'ember-changeset-validations';
import { dropTask } from 'ember-concurrency';
import type AgreementModel from 'garaje/models/agreement';
import type InviteModel from 'garaje/models/invite';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type LocationFeatureFlagsService from 'garaje/services/location-feature-flags';
import { createCapacityValidator } from 'garaje/utils/locations-capacity';
import buildInviteValidations from 'garaje/validations/invite';
import { alias } from 'macro-decorators';
import moment from 'moment-timezone';
import { defer } from 'rsvp';

import type VisitorsInvitesIndexController from '../index/controller';

import type { VisitorsInvitesShowRouteModel } from './route';

export default class VisitorsInvitesShowController extends Controller {
  declare model: VisitorsInvitesShowRouteModel;

  @service declare store: StoreService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare locationFeatureFlags: LocationFeatureFlagsService;

  @tracked changeset!: DetailedChangeset<InviteModel>;

  @controller('visitors.invites.index') pregistered!: VisitorsInvitesIndexController; // pregistered and date are used to build the navigation header
  @alias('pregistered.date') date!: this['pregistered']['date'];

  setupChangeset(): void {
    const { store, model } = this;

    const validations = buildInviteValidations({
      validateCapacity: createCapacityValidator(store, model.location),
      checkEmailRequired:
        this.featureFlags.isEnabled('visitors-required-email-field') ||
        this.locationFeatureFlags.isEnabled('visitors-required-email-field-by-location'),
    });
    const validator = lookupValidator(validations);

    this.changeset = Changeset(model.invite, validator, validations);
  }

  get legalDocumentDescriptions(): Record<string, string> {
    if (!this.shouldShowLegalDocumentDescriptions) {
      return {};
    }

    /**
     * This grabs all of the agreements for which the invite has signed agreeableNdas that fall in the following
     * two categories:
     * 1. Signed agreements that are on an agreementPage that is different from the invite's currently assigned
     *    flow's agreementPage. These represent agreements that may have been signed during pre-registration,
     *    and later the flow was disabled or deleted or switched, or the agreementPage was disabled.
     * 2. Signed agreements on the currently assigned flow's agreementPage that are disabled. We exclude enabled
     *    agreements on the current agreementPage here because we concat these in the next reduce function below.
     *    We include discarded signed agreements in the invite payload so these should be available in the store.
     */
    const previouslySignedAgreements = this.model.invite.agreeableNdas.reduce<AgreementModel[]>(
      (array, agreeableNda) => {
        const isOnAnotherAgreementPage = this.model.agreements?.every(({ id }) => id !== agreeableNda.agreement.id);
        const isDisabled = agreeableNda.agreement.enabled === false;

        if (agreeableNda.signedAt && (isOnAnotherAgreementPage || isDisabled)) {
          return [...array, agreeableNda.agreement];
        }

        return array;
      },
      []
    );

    return previouslySignedAgreements
      .concat((this.model.agreements ?? []).filter((agreement) => agreement.enabled))
      .reduce<Record<string, string>>((obj, agreement) => {
        const agreeableNda = this.model.invite.agreeableNdas.find((nda) => nda.agreement.id === agreement.id);
        /**
         * There may be agreeableNdas that have signed agreements (i.e. an "NDA" PDF) but that don't have a
         * relationship to an agreement (i.e., a legal document template) and therefore have no agreement name.
         * These signed agreements are from before visitor type flows existed. We assign them a generic
         * "Non-disclosure Agreement" name because NDAs used to be the only kind of agreement we supported.
         */
        const agreementName = typeof agreement.name === 'undefined' ? 'Non-disclosure Agreement' : agreement.name;

        if (agreeableNda && agreeableNda.signedAt) {
          const guestUpdatedAt = moment(this.model.invite.guestUpdatedAt);
          const agreementSignedAt = moment(agreeableNda.signedAt);
          const wasPreviouslySigned = guestUpdatedAt.diff(agreementSignedAt, 'days') !== 0;
          const description = wasPreviouslySigned ? `signed ${agreementSignedAt.format('MMM D, YYYY')}` : 'signed';

          obj[agreementName] = description;
        } else {
          obj[agreementName] = 'pending';
        }

        return obj;
      }, {});
  }

  get shouldShowLegalDocumentDescriptions(): boolean {
    return (
      this.model.invite.agreeableNdas.any((agreeableNda) => !!agreeableNda.signedAt) ||
      !!this.model.agreements?.any((agreement) => agreement.enabled)
    );
  }

  get locationIsConnectedToProperty(): boolean {
    return this.model.connectedTenants.toArray().length > 0;
  }

  @action
  modelDidSave(date: string): void {
    const queryParams: { date?: string } = {};

    if (date) {
      queryParams.date = moment(date).format('YYYY-MM-DD');
    }

    this.transitionToRoute('visitors.invites.index', { queryParams });
  }

  get modalElement(): HTMLElement | null {
    return document.getElementById('modal');
  }

  @dropTask
  showConfirmationModalTask = {
    *perform(this: { abort?: () => void; continue?: () => void }): Generator<Promise<unknown>, unknown, unknown> {
      const deferred = defer();

      this.abort = () => deferred.reject();
      this.continue = () => deferred.resolve();

      return yield deferred.promise;
    },
  };
}
