import Component from '@glimmer/component';
import { get, set } from '@ember/object';

/**
 * This component handles all the conditional children for a given field
 * @param {Changeset<SignInField>}            field                         Current / paren `sign-in-fiel` changeset
 * @param {Array<Changesets>}                 changesetFields               Top level changesetFields to filter
 * @param {Function}                          deleteFieldAction             Action to delete a given field
 * @param {Boolean}                           isRTL                         Is right to left enabled?
 * @param {Function}                          toggleRecalculateContainer    Action triggered when sorting
 * @param {Boolean}                           isDisabled
 * @param {Function}                          getDefaultStoreResponse
 */

export default class SignInFieldsConditionalsComponent extends Component {
  /**
   * @return {Array<Changeset>}
   */
  get conditionalChangesetFields() {
    const { changesetFields, field } = this.args;
    const changeSets = [];
    get(field, 'actionableSignInFields').forEach((actionableSignInField) => {
      if (get(actionableSignInField, 'isDeleted')) {
        return;
      }
      changeSets.push(
        changesetFields.find((changesetField) => get(changesetField, '_content') === actionableSignInField)
      );
    });
    return changeSets;
  }

  /**
   * @return {Array<Object>}
   */
  get groups() {
    return get(this.args.field, 'options')
      .sortBy('position')
      .map((opt) => {
        return { opt, fields: [] };
      });
  }

  /**
   * @return {Array<Object>}
   */
  get groupByOptions() {
    const { groups } = this;
    const grouped = [...groups];
    grouped.forEach((group) => {
      const fields = this.conditionalChangesetFields.filter((condChangeSetField) => {
        return get(condChangeSetField, 'actionableSignInFieldActions').any(
          (signInFieldAction) => signInFieldAction.dropdownOption === group.opt
        );
      });
      set(group, 'fields', fields);
    });
    return grouped;
  }
}
