import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type EmergencyNotificationConfigurationModel from 'garaje/models/emergency-notification-configuration';
import type LocationModel from 'garaje/models/location';
import type StateService from 'garaje/services/state';
import { hash } from 'rsvp';

export interface EmergencyResponseSettingsRouteModel {
  currentLocation: LocationModel;
  emergencyNotificationConfiguration?: EmergencyNotificationConfigurationModel;
}
export default class EmergencyResponseSettingsRoute extends Route {
  @service declare state: StateService;
  @service declare abilities: AbilitiesService;

  async model(): Promise<EmergencyResponseSettingsRouteModel> {
    let emergencyNotificationConfiguration;
    const currentLocation = this.state.currentLocation;

    if (this.abilities.can('edit-features communications')) {
      emergencyNotificationConfiguration = currentLocation.getEmergencyNotificationConfiguration({});
    }
    return hash({
      currentLocation,
      emergencyNotificationConfiguration,
    });
  }
}
