import Service from '@ember/service';
import type EmployeeScreeningFlowModel from 'garaje/models/employee-screening-flow';
import { allSettled } from 'rsvp';

import withEmployeeRegistrationFunctionality from './flow/employee-registration';

@withEmployeeRegistrationFunctionality
class FlowService extends Service {
  async initService(): Promise<void> {
    await allSettled([this.initEmployeeRegistrationState()]);
  }

  declare initEmployeeRegistrationState: () => Promise<void>;
  declare getFlowWithIncludes: (id: string) => Promise<EmployeeScreeningFlowModel>;
}

export default FlowService;

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    flow: FlowService;
  }
}
