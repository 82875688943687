import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { easeIn } from 'ember-animated/easings/cosine';
import type { Sprite } from 'ember-animated/motions/move';
import move from 'ember-animated/motions/move';
import { dropTask } from 'ember-concurrency';
import type DeviceModel from 'garaje/models/device';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type VisitorsOnboardingService from 'garaje/services/visitors-onboarding';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { filterBy } from 'macro-decorators';

export default class VisitorsDevicesIpadsIndexController extends Controller {
  @service declare flashMessages: FlashMessagesService;
  @service declare visitorsOnboarding: VisitorsOnboardingService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare router: RouterService;

  @filterBy('model.ipads', 'isIpad') actualIpads!: DeviceModel[];
  @filterBy('actualIpads', 'isVisible') connectedIpads!: DeviceModel[];
  @tracked showIpadDemoModal = false;

  deleteIpadTask = dropTask(async (ipad: DeviceModel) => {
    try {
      await ipad.destroyRecord();
      if (this.visitorsOnboarding.showVideoWalkthrough) {
        void this.visitorsOnboarding.loadIpadsTask.perform();
      }
      this.flashMessages.showAndHideFlash('success', 'iPad deleted successfully');
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  get isVirtualFrontDesk(): boolean {
    const currentRoute = this.router.currentRouteName;
    return currentRoute.includes('virtual-front-desk');
  }

  get newIpadRoute(): string {
    return this.isVirtualFrontDesk ? 'virtual-front-desk.devices.ipads.new' : 'visitors.devices.ipads.new';
  }

  get isUserEligibleForModal(): boolean {
    return this.featureFlags.isEnabled('growth_ipad_demo');
  }

  get isLocationOrGlobalAdmin(): boolean {
    const { isGlobalAdmin, isLocationAdmin } = this.currentAdmin;
    return isGlobalAdmin || isLocationAdmin;
  }

  @action
  showModal(): void {
    if (this.featureFlags.isEnabled('growth_ipad_demo')) {
      this.showIpadDemoModal = true;
    }
  }

  @action
  closeModal(): void {
    if (this.featureFlags.isEnabled('growth_ipad_demo')) {
      this.showIpadDemoModal = false;
    }
  }

  /* eslint-disable require-yield */
  *transition({
    keptSprites,
    removedSprites,
  }: {
    keptSprites: Sprite[];
    removedSprites: Sprite[];
  }): Generator<never, void, unknown> {
    keptSprites.forEach(move);
    removedSprites.forEach((sprite) => {
      sprite.endAtPixel({ x: window.innerWidth });
      move(sprite, { easing: easeIn });
    });
  }
  /* eslint-enable require-yield */
}
