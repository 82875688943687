import Service, { inject as service } from '@ember/service';
import type {
  Audience,
  Audiences,
  DeliveryMethod,
  Location,
  MessageInput,
  SentMessage,
  Template,
  TemplateCategory,
} from '@envoy/components-communication';
import { AudienceType } from '@envoy/components-communication';
import {
  type GetSkinnyLocationsQuery,
  type GetSkinnyLocationsVariables,
  type CreateAnnouncementMutation,
  type CreateAnnouncementVariables,
  type GetAnnouncementTemplateNamesForLocationByCategoryQuery,
  type PopulateAnnouncementTemplateQuery,
  type PopulateAnnouncementTemplateVariables,
  type GetAnnouncementAudienceSizesQuery,
  type GetAnnouncementAudienceSizesVariables,
  type GetAnnouncementTemplateNamesForLocationByCategoryVariables,
} from 'garaje/graphql/generated/announcement-types';
import createAnnouncementMutation from 'garaje/graphql/mutations/CreateAnnouncementMutation';
import announcementAudienceSizesQuery from 'garaje/graphql/queries/AnnouncementAudienceSizesQuery';
import announcementTemplateNamesForLocationByCategoryQuery from 'garaje/graphql/queries/AnnouncementTemplateNamesForLocationByCategory';
import populateAnnouncementTemplateQuery from 'garaje/graphql/queries/PopulateAnnouncementTemplateQuery';
import skinnyLocationsQuery from 'garaje/graphql/queries/SkinnyLocationsQuery';

import type ApolloService from './apollo-extension';

enum EmployeeAudienceType {
  Self = 'self',
  AllEmployeesWithReservation = 'allEmployeesWithReservation',
  AllEmployeesCheckedIn = 'allEmployeesCheckedIn',
  AllEmployeesAtLocation = 'allEmployeesAtLocation',
  AllEmployeesAtDefaultLocation = 'allEmployeesAtDefaultLocation',
}

enum VisitorAudienceType {
  AllVisitorsCheckedIn = 'allVisitorsCheckedIn',
  AllVisitorsWithReservation = 'allVisitorsWithReservation',
}

const audienceTypeMap: Record<EmployeeAudienceType | VisitorAudienceType, AudienceType> = {
  [EmployeeAudienceType.Self]: AudienceType.Self,
  [EmployeeAudienceType.AllEmployeesWithReservation]: AudienceType.AllEmployeesWithReservation,
  [EmployeeAudienceType.AllEmployeesCheckedIn]: AudienceType.AllEmployeesCheckedIn,
  [EmployeeAudienceType.AllEmployeesAtLocation]: AudienceType.AllEmployeesAtLocation,
  [EmployeeAudienceType.AllEmployeesAtDefaultLocation]: AudienceType.AllEmployeesAtDefaultLocation,
  [VisitorAudienceType.AllVisitorsCheckedIn]: AudienceType.AllVisitorsCheckedIn,
  [VisitorAudienceType.AllVisitorsWithReservation]: AudienceType.AllVisitorsWithReservation,
};

export default class CommunicationDataService extends Service {
  @service declare apolloExtension: ApolloService;

  async getTemplateCategories(locationId: string): Promise<TemplateCategory[]> {
    try {
      const result = await this.apolloExtension.query<
        GetAnnouncementTemplateNamesForLocationByCategoryQuery,
        GetAnnouncementTemplateNamesForLocationByCategoryVariables,
        'announcementTemplateNamesForLocationByCategory'
      >(
        {
          query: announcementTemplateNamesForLocationByCategoryQuery,
          variables: {
            locationId,
          },
          fetchPolicy: 'network-only',
        },
        'announcementTemplateNamesForLocationByCategory'
      );

      return result;
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql getTemplateCategories query. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async getTemplate(locationId: string, templateId: string): Promise<Template> {
    try {
      const populateAnnouncementTemplate = await this.apolloExtension.query<
        PopulateAnnouncementTemplateQuery,
        PopulateAnnouncementTemplateVariables,
        'populateAnnouncementTemplate'
      >(
        {
          query: populateAnnouncementTemplateQuery,
          variables: {
            announcementTemplateId: templateId,
            locationId,
          },
        },
        'populateAnnouncementTemplate'
      );

      return {
        title: populateAnnouncementTemplate.title,
        message: populateAnnouncementTemplate.message,
        deliveryMethods: populateAnnouncementTemplate.defaultChannels as DeliveryMethod[],
        employeeGroup: (populateAnnouncementTemplate.defaultEmployeeAudiences[0] as AudienceType) || '',
        visitorGroup: (populateAnnouncementTemplate.defaultVisitorAudiences[0] as AudienceType) || '',
        markAsSafe: populateAnnouncementTemplate.markAsSafe,
      };
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql getTemplate query. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async getAudiences(locationId: string): Promise<Audiences> {
    try {
      const result = await this.apolloExtension.query<
        GetAnnouncementAudienceSizesQuery,
        GetAnnouncementAudienceSizesVariables,
        'announcementAudienceSizes'
      >(
        {
          query: announcementAudienceSizesQuery,
          variables: {
            locationId,
          },
        },
        'announcementAudienceSizes'
      );

      const { employeeAudienceCount, visitorAudienceCount } = result[0]!;

      return {
        employees: [
          this.getEmployeeAudienceValue(EmployeeAudienceType.Self, employeeAudienceCount),
          this.getEmployeeAudienceValue(EmployeeAudienceType.AllEmployeesCheckedIn, employeeAudienceCount),
          this.getEmployeeAudienceValue(EmployeeAudienceType.AllEmployeesWithReservation, employeeAudienceCount),
          this.getEmployeeAudienceValue(EmployeeAudienceType.AllEmployeesAtLocation, employeeAudienceCount),
          this.getEmployeeAudienceValue(EmployeeAudienceType.AllEmployeesAtDefaultLocation, employeeAudienceCount),
        ],
        visitors: [
          this.getVisitorAudienceValue(VisitorAudienceType.AllVisitorsCheckedIn, visitorAudienceCount),
          this.getVisitorAudienceValue(VisitorAudienceType.AllVisitorsWithReservation, visitorAudienceCount),
        ],
      };
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql getAudiences query. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  async sendMessage(input: MessageInput): Promise<SentMessage> {
    const announcementInput = {
      title: input.title,
      message: input.message,
      channels: input.deliveryMethods,
      audiences: [
        input.employeeGroup && { locationId: input.locationId, type: input.employeeGroup },
        input.visitorGroup && { locationId: input.locationId, type: input.visitorGroup },
      ].filter((audience) => !!audience),
      actions: input.markAsSafe ? ['MARK_AS_SAFE'] : [],
      type: input.critical ? 'EMERGENCY' : 'ANNOUNCEMENT',
    };

    const announcement = await this.apolloExtension.mutate<
      CreateAnnouncementMutation,
      CreateAnnouncementVariables,
      'createAnnouncement'
    >(
      {
        mutation: createAnnouncementMutation,
        variables: {
          announcementInput,
        },
      },
      'createAnnouncement'
    );

    const {
      statistics: {
        sentCount: { employeesCount, visitorsCount },
      },
    } = announcement;

    return {
      id: announcement.id,
      sentCount: employeesCount + visitorsCount,
    };
  }

  async getLocations(): Promise<Location[]> {
    try {
      const result = await this.apolloExtension.query<
        GetSkinnyLocationsQuery,
        GetSkinnyLocationsVariables,
        'locations'
      >(
        {
          query: skinnyLocationsQuery,
          variables: {},
        },
        'locations'
      );

      return result;
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error calling graphql getTemplateCategories query. Error: ', JSON.stringify(e));
      throw e;
    }
  }

  private getEmployeeAudienceValue(type: EmployeeAudienceType, counts: Record<EmployeeAudienceType, number>): Audience {
    return {
      id: audienceTypeMap[type],
      count: counts[type],
    };
  }

  private getVisitorAudienceValue(type: VisitorAudienceType, counts: Record<VisitorAudienceType, number>): Audience {
    return {
      id: audienceTypeMap[type],
      count: counts[type],
    };
  }
}
