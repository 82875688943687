import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { A, isArray } from '@ember/array';
import { localCopy } from 'tracked-toolbox';
import { or, collect, map, mapBy, filterBy } from 'macro-decorators';
import { tracked } from '@glimmer/tracking';

const REQUIREMENT_IDENTIFIER = 'covid-19';

// * UDTC => UserDocumentTemplateConfiguration

/**
 * Settings panel for indicating how many SARS-CoV-2 documents (if any) must be approved for an Employee to
 * schedule time at the office.
 *
 * @param {UDTC*}         vaccineDocumentTrackingConfiguration    Required - a *persisted* UDTC record associated to the "covid-19-vaccine-card" document template
 * @param {UDTC*}         covidTestDocumentTrackingConfiguration  Required - a *persisted* UDTC record associated to the "covid-19-test-result" document template
 * @param {Array<Object>} userDocumentRequirements                The current array value of userDocumentRequirements (from a "skinny location")
 * @param {Function}      onRequirementsUpdate                    The function to call when there is an update to the userDocumentRequirements. Sends the updated requirements as the first argument
 * @param {Boolean}       isDisabled                              Enable/Disable the toggle / inputs
 */
export default class FlowsDocumentApprovalRequirementCovid19Component extends Component {
  @service abilities;
  @service featureFlags;

  @tracked confirmationModalToShow;

  @collect('vaccineDocumentTrackingConfiguration', 'covidTestDocumentTrackingConfiguration') allConfigs;
  @filterBy('allConfigs', 'active') activeConfigs;
  @mapBy('allConfigs', 'id') allConfigIds;
  @mapBy('activeConfigs', 'id') activeConfigIds;
  @map('allConfigIds', (id) => parseInt(id, 10) || null) allConfigIdInts;
  @map('activeConfigIds', (id) => parseInt(id, 10)) activeConfigIdInts;
  @or('args.vaccineDocumentTrackingConfiguration.active', 'args.covidTestDocumentTrackingConfiguration.active')
  isActiveConfig;

  @collect('eitherDocumentOption', 'bothDocumentsOption', 'vaccineDocumentOption', 'testResultDocumentOption')
  allOptions;
  @filterBy('allOptions', 'active') options;

  @localCopy('args.vaccineDocumentTrackingConfiguration', {}) vaccineDocumentTrackingConfiguration;
  @localCopy('args.covidTestDocumentTrackingConfiguration', {}) covidTestDocumentTrackingConfiguration;
  @localCopy('args.userDocumentRequirements', []) userDocumentRequirements;

  get shouldRender() {
    if (!this.featureFlags.isEnabled('protectSchedulingLimits')) return false;
    if (this.abilities.cannot('view covid document requirements for workplace')) return false;

    return this.isActiveConfig;
  }

  get isInputDisabled() {
    return this.cannotToggle || this.args.isDisabled;
  }

  get cannotToggle() {
    return (
      this.abilities.cannot('toggle proof of vaccination for workplace') &&
      this.abilities.cannot('toggle proof of covid negative test for workplace')
    );
  }

  get defaultRequirement() {
    return {
      identifier: REQUIREMENT_IDENTIFIER,
      'required-minimum': 0,
      'user-document-template-configuration-ids': this.allConfigIdInts.compact(),
    };
  }

  get relevantRequirement() {
    const relevantRequirement = this.findRelevantRequirement();

    return Object.assign(this.defaultRequirement, relevantRequirement);
  }

  get requiredMinimum() {
    return this.relevantRequirement['required-minimum'];
  }

  get configurationIds() {
    return this.relevantRequirement['user-document-template-configuration-ids'];
  }

  get selectedOption() {
    const { allOptions, relevantRequirement, requirementSignature } = this;
    const relevantSignature = requirementSignature(relevantRequirement);

    return allOptions.find((opt) => relevantSignature === requirementSignature(opt.value));
  }

  get eitherDocumentOption() {
    return {
      active: this.activeConfigs.length > 1,
      label: 'either vaccination or test results documents',
      value: Object.assign(this.defaultRequirement, {
        'required-minimum': 1,
        'user-document-template-configuration-ids': this.allConfigIdInts,
      }),
    };
  }

  get bothDocumentsOption() {
    const testDocConfig = this.covidTestDocumentTrackingConfiguration;

    return {
      active: this.activeConfigs.length > 1,
      disabled: testDocConfig?.id && testDocConfig?.hideIfVaccinated,
      label: 'both vaccination and test results documents',
      value: Object.assign(this.defaultRequirement, {
        'required-minimum': 2,
        'user-document-template-configuration-ids': this.allConfigIdInts,
      }),
    };
  }

  get vaccineDocumentOption() {
    const config = this.vaccineDocumentTrackingConfiguration;

    return {
      active: config?.id && config?.active,
      label: 'vaccination documents',
      value: Object.assign(this.defaultRequirement, {
        'required-minimum': 1,
        'user-document-template-configuration-ids': [parseInt(config?.id, 10)],
      }),
    };
  }

  get testResultDocumentOption() {
    const config = this.covidTestDocumentTrackingConfiguration;

    return {
      active: config?.id && config?.active,
      disabled: config?.id && config?.hideIfVaccinated,
      label: 'test results documents',
      value: Object.assign(this.defaultRequirement, {
        'required-minimum': 1,
        'user-document-template-configuration-ids': [parseInt(config?.id, 10)],
      }),
    };
  }

  get isIncompatibleRequirement() {
    const { selectedOption: { active, disabled } = {} } = this;

    return !active || disabled;
  }

  findRelevantRequirement() {
    const { userDocumentRequirements, allConfigIdInts } = this;
    const configIds = allConfigIdInts.compact();

    return isArray(userDocumentRequirements)
      ? userDocumentRequirements.find((req) => {
          if (req.identifier) return req.identifier === REQUIREMENT_IDENTIFIER;

          const configurationIds = isArray(req['user-document-template-configuration-ids'])
            ? req['user-document-template-configuration-ids']
            : [];
          const intersection = configurationIds.filter((id) => configIds.includes(id));

          return intersection.length > 0;
        })
      : null;
  }

  requirementsWithoutRelevantRequirement() {
    const { userDocumentRequirements } = this;
    const relevantRequirement = this.findRelevantRequirement();

    if (!isArray(userDocumentRequirements)) {
      return A();
    }

    return A([...userDocumentRequirements]).without(relevantRequirement);
  }

  computeInitialRequirement() {
    return Object.assign(this.defaultRequirement, {
      'required-minimum': 1,
      'user-document-template-configuration-ids': this.activeConfigIdInts,
    });
  }

  computeUpdatedRequirements(requirement) {
    const requirements = this.requirementsWithoutRelevantRequirement();

    requirements.push(requirement);

    return requirements;
  }

  requirementSignature(requirement) {
    return [requirement['required-minimum'], requirement['user-document-template-configuration-ids'].join(',')].join(
      ':'
    );
  }

  @action
  toggleStatus() {
    if (this.requiredMinimum) {
      const requirements = this.computeUpdatedRequirements({ ...this.defaultRequirement });

      this.args.onRequirementsUpdate?.(requirements);
    } else {
      this.confirmationModalToShow = 'enable';
    }
  }

  @action
  confirmRequirement() {
    this.confirmationModalToShow = null;

    const requirements = this.computeUpdatedRequirements(this.computeInitialRequirement());

    this.args.onRequirementsUpdate?.(requirements);
  }

  @action
  selectApprovalRequirement(option) {
    const requirements = this.computeUpdatedRequirements(option.value);

    this.args.onRequirementsUpdate?.(requirements);
  }
}
