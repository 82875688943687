import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import urlBuilder from 'garaje/utils/url-builder';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import { A } from '@ember/array';
import { action, get, set } from '@ember/object';
import { all, resolve, defer } from 'rsvp';
import { isBlank, isPresent, isNone } from '@ember/utils';
import { service } from '@ember/service';
import { timeout, dropTask, restartableTask, enqueueTask } from 'ember-concurrency';
import moment from 'moment-timezone';
import { DEFAULT_FLOW_NAME, NON_ASSIGNABLE_FLOWS } from 'garaje/utils/enums';
import config from 'garaje/config/environment';
import zft from 'garaje/utils/zero-for-tests';
import { alias, and, equal, notEmpty } from 'macro-decorators';
import { format, utcToZonedTime } from 'date-fns-tz';
import { addMinutes, endOfDay, startOfDay, subDays, addDays } from 'date-fns';

const HEADER_ENUMS = {
  signedInHeader: 'Signed In',
  signedOutHeader: 'Reservation End Time',
  reservationStartTimeHeader: 'Reservation Start Time',
  name: 'Name',
  employeeRegistration: 'Employee Registration',
  expectedArrivalTime: 'Expected Arrival Time',
  date: 'Date',
};

const EVENT_REPORT_POLLING_TIMEOUT = zft(10000);
const NUMBER_OF_POLLING_TRIES = zft(3);
const PRIMARY_HEADER = [
  { name: HEADER_ENUMS.name, componentName: 'custom-column', sort: 'full_name' },
  { name: 'Entry status', componentName: 'custom-column' },
  { name: HEADER_ENUMS.employeeRegistration, componentName: 'employee-registration-column' },
  { name: 'Arrived At', componentName: 'arrived-at-column' },
  { name: 'Pre-registration', componentName: 'pre-registration-column' },
  { name: 'Desk', componentName: 'custom-column' },
  { name: 'Security', componentName: 'plugin-activity-column' },
  { name: HEADER_ENUMS.reservationStartTimeHeader, componentName: 'reservation-start-time-column' },
  { name: HEADER_ENUMS.date, componentName: 'entry-date-column' },
];

const SIGN_IN_AND_SIGN_OUT_HEADER = [
  { name: HEADER_ENUMS.signedInHeader, componentName: 'signed-in-column', sort: 'signed-in-at' },
  { name: HEADER_ENUMS.signedOutHeader, componentName: 'signed-out-column', sort: 'sign_out_time' },
];

const EXPECTED_ARRIVAL_TIME_HEADER = [
  {
    name: HEADER_ENUMS.expectedArrivalTime,
    componentName: 'expected-arrival-time-column',
  },
];

const DEFAULT_HEADERS = [HEADER_ENUMS.name, HEADER_ENUMS.employeeRegistration];
const DAYS_OF_WEEK_ARRAY = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export default class WorkplaceEntriesController extends Controller {
  @service abilities;
  @service asyncExportManager;
  @service currentAdmin;
  @service currentLocation; // Needed for `printer` CP
  @service featureFlags;
  @service flashMessages;
  @service logger;
  @service messageBus;
  @service metrics;
  @service session;
  @service state;
  @service store;
  @service workplaceMetrics;

  queryParams = ['date', 'query', 'filter', 'refresh', 'sort', { selectedFlow: { as: 'visitor_type' } }];

  @tracked refresh = ''; // Flag to force a model refresh
  @tracked date = '';
  @tracked query = '';
  @tracked inputQuery = null;
  @tracked selectedFlow = DEFAULT_FLOW_NAME.EMPLOYEE_SCREENING;
  @tracked entriesCount = 0;
  @tracked sort = '-signed-in-at';
  @tracked invitesSort = 'expected_arrival_time';
  @tracked filter = 'all';
  @tracked showExportModal = false;
  @tracked startDate;
  @tracked endDate;
  @tracked mobileCalendarVisible = false;
  @tracked page = 1;
  @tracked employees = A([]);
  @tracked employeesPage = 0;
  @tracked totalLoadedEntries;
  @tracked totalLoadedInvites;
  @tracked hasEntries;
  @tracked selectedEntries = [];
  @tracked eventReports = [];
  @tracked datesWithEntries = [];
  @tracked bosses = [];
  @tracked connectedTenants = null;
  @tracked showCalendar = false;
  @tracked selectedDateRange = 'Today';
  dateRangeFilterOptions = [
    'Today',
    'Yesterday',
    'Tomorrow',
    'Past 7 days',
    'Past 14 days',
    'Past 30 days',
    'Next 7 days',
    'Next 14 days',
    'Next 30 days',
    'Custom range',
  ];

  constructor() {
    super(...arguments);
    this.messageBus.on('embedded-app-message', this, this.handleMessage);
  }

  @alias('currentLocation.preRegistrationEnabled') preRegistrationEnabled;
  @equal('hasEntries', false) noHistoricVisitors;
  @equal('dateWithDefault', moment().format('YYYY-MM-DD')) isToday;
  @notEmpty('query') showEntryFullDate;
  @notEmpty('query') showClearButton;
  @and('page', 'loadEntries.isIdle', 'mutateQuery.isIdle') showCount;

  get locationIsConnectedToProperty() {
    return this.connectedTenants?.length > 0;
  }

  get isAfterToday() {
    return moment(this.dateWithDefault).isAfter(moment(), 'day');
  }

  get selectedDateRangeOption() {
    return this.isToday ? 'Today' : `Current day (${this.selectedDate.format('MMM D, YYYY')})`;
  }

  get currentQuery() {
    // If a search query typed in, use it.
    // Fallback to query param.
    const { inputQuery, query } = this;

    return isNone(inputQuery) ? query : inputQuery;
  }

  get isSearching() {
    return this.filter !== 'all' || isPresent(this.query);
  }

  get exportButtonText() {
    return this.isSearching ? 'Export results' : 'Export';
  }

  get exportIframeUrl() {
    const { currentCompany } = this.state;
    const locationId = get(this.currentLocation, 'id');
    let modalLabel;
    let selectedFlowId;
    const defaultEndDate = moment().format('YYYY-MM-DD');
    const defaultStartDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
    const flows = this.model.flows;
    if (this.selectedFlow === '') {
      selectedFlowId = this.model.locationFlowIds.join(',');
      modalLabel = 'All employees types';
    } else {
      if (this.selectedFlow === DEFAULT_FLOW_NAME.EMPLOYEE_SCREENING) {
        selectedFlowId = get(flows.findBy('type', 'Flows::EmployeeScreening'), 'id');
      } else {
        selectedFlowId = get(flows.findBy('name', this.selectedFlow), 'id');
      }
      modalLabel = this.selectedFlow;
    }
    return urlBuilder.embeddedInviteExportModalUrl(
      currentCompany.id,
      locationId,
      selectedFlowId,
      defaultStartDate,
      defaultEndDate,
      modalLabel
    );
  }

  /*
  A computed property that provides a fallback to today's date if the date QP is empty.

  This allows users to bookmark a live "today" view that is just `/entries`, since the empty QP
  will force the app to show the current day's view rather than forcing the URL to always contain
  a static date.
   */
  get dateWithDefault() {
    return this.date || moment().format('YYYY-MM-DD');
  }

  /*
   Instance of "moment" representing the string in the property date.
   This value is used to render the envoy-calendar, and do operations
   like next and previous day.
   */
  get selectedDate() {
    return moment(this.dateWithDefault, 'YYYY-MM-DD');
  }

  set selectedDate(value) {
    this.date = value.format('YYYY-MM-DD');
    this.loadEntries.cancelAll();
    // eslint-disable-next-line no-setter-return
    return value;
  }

  @action
  didSelectDateRange(startDate, endDate) {
    this.workplaceMetrics.trackEvent('EMPLOYEE_LOG_DATE_RANGE_SELECT_CLICKED');

    this.startDate = startDate;
    this.endDate = endDate;
    this.selectedDateRange = 'Custom range';
    this.loadEntriesRestartableTask.perform();
  }

  get startDateFormat() {
    const timeZone = this.state.currentLocation.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedTime = utcToZonedTime(new Date(this.startDate), timeZone);
    return format(new Date(zonedTime), 'MM/dd/yyyy', { timeZone: timeZone });
  }

  get endDateFormat() {
    const timeZone = this.state.currentLocation.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedTime = utcToZonedTime(new Date(this.endDate), timeZone);
    return format(zonedTime, 'MM/dd/yyyy', { timeZone: timeZone });
  }

  @action
  setStartDate(date) {
    this.startDate = date;
  }

  @action
  setEndDate(date) {
    this.endDate = date;
  }

  @action
  setStartAndEndTime() {
    this.startDate = addMinutes(startOfDay(new Date()), this.state.minutesBetweenTimezones);
    this.endDate = addMinutes(endOfDay(new Date()), this.state.minutesBetweenTimezones);
  }

  get showSignedOut() {
    return this.filter !== 'signed-in';
  }

  /*
    We should only show signed in and signed out for employees who
    have shown up.
  */
  get showSignedInAndOutHeaders() {
    return this.filter !== 'no-show';
  }

  get showExpectedArrivalTimeHeader() {
    return this.filter == 'no-show' || this.filter == 'all';
  }

  get filterOptions() {
    const options = [];
    const employee = get(this.currentAdmin, 'employee');
    if (employee) {
      this.bosses.forEach(function (boss) {
        options.push({
          name: `${get(boss, 'name')} Employees`,
          filter: boss.id,
          employee: boss,
        });
      });
    }
    // Only include all employees filter if user is an admin or if is
    // an employee with bosses
    if (get(this.currentAdmin, 'isAdminLike') || get(this.bosses, 'length') > 0) {
      options.unshift({ name: 'All Reservations', filter: 'all' });
    }
    options.push(
      { name: 'Signed In', filter: 'signed-in' },
      { name: 'Signed Out', filter: 'signed-out' },
      { name: 'Scheduled Employees', filter: 'no-show' }
    );
    return options;
  }

  get selected() {
    const filter = this.filter;
    const filterOptions = this.filterOptions;
    const selectedOption = filterOptions.findBy('filter', filter);
    return selectedOption || get(filterOptions, 'firstObject');
  }

  get signedInEntries() {
    return get(this.model, 'entries').filter((entry) => get(entry, 'signInTime'));
  }

  get signedOutEntries() {
    return get(this.model, 'entries').filter((entry) => get(entry, 'signOutTime'));
  }

  get noShowInvites() {
    return get(this.model, 'entries').filter((entry) => 'arrived' in entry && !get(entry, 'arrived'));
  }

  get allInvitesAndEntries() {
    return get(this.model, 'entries').filter((entry) => !('arrived' in entry && get(entry, 'arrived')));
  }

  get relevantEntries() {
    switch (this.filter) {
      case 'all':
        return this.allInvitesAndEntries;
      case 'no-show':
        return this.noShowInvites;
      case 'signed-in':
        return this.signedInEntries;
      default:
        return this.signedOutEntries;
    }
  }

  get relevantReviewableEntries() {
    return this.relevantEntries.filter((entry) => {
      return (
        entry.needsApprovalReview &&
        this.abilities.can('review entry-approval', {
          context: 'location',
          report: entry.approvalStatus.failedReport,
        })
      );
    });
  }

  get hasMorePages() {
    const currentTotal = this.page * this.limit;
    return currentTotal <= this.entriesCount;
  }

  get signInFieldPages() {
    return this.model.signInFieldPages;
  }

  get customFieldHeaders() {
    return this.model.customFields
      .compact()
      .reduce((acc, val) => acc.concat(val), [])
      .uniqBy('name')
      .map((customField) => {
        return {
          name: get(customField, 'label'),
          componentName: 'custom-column',
        };
      });
  }

  get primaryHeaders() {
    const headers = PRIMARY_HEADER;
    const removeHeaders = {
      Notification: !get(this.currentLocation, 'hostNotificationsEnabled'),
      'Arrived At': !this.locationIsConnectedToProperty,
      Security: !this.hasSecurityColumn,
      'Pre-registration': !get(this.currentLocation, 'preRegistrationEnabled'),
      Desks: this.abilities.can('see entry log for desk'),
    };
    return headers.filter(({ name }) => !removeHeaders[name]);
  }

  get hasSecurityColumn() {
    return this._hasActivePluginForCategory('security');
  }

  get entryDashboardFields() {
    if (this.selectedFlow.toLowerCase() != 'employee registration') {
      return get(this.currentLocation, 'dashboardFields');
    }
    const dashboardFields = get(this.currentLocation, 'dashboardFields');
    return [
      ...dashboardFields.toArray(),
      { name: 'Employee Registration', componentName: 'employee-registration-column' },
    ];
  }

  getDefaultHeaders(headers) {
    const defaultHeaders = DEFAULT_HEADERS;
    switch (this.filter) {
      case 'no-show':
        defaultHeaders.push([HEADER_ENUMS.expectedArrivalTime]);
        break;
      default:
        defaultHeaders.push([HEADER_ENUMS.signedInHeader, HEADER_ENUMS.signedOutHeader]);
        break;
    }
    return headers.map(({ name, componentName, sort }) => {
      return {
        name,
        componentName,
        // always show name
        show: defaultHeaders.includes(name) ? true : isPresent(this.entryDashboardFields.findBy('name', name)),
        // name should not be adding / removing from dashboard fields
        disabled: DEFAULT_HEADERS.includes(name),
        sort,
      };
    });
  }

  get fieldOptions() {
    // this CP transform raw dashboardFields into list of headers and their property
    // entry headers always displayed in the same order (which defined here)
    // Note: field has to be populated when sortable is true
    // In addition, column-name HAS TO be specify because backend is checking it too
    const primaryHeaders = this.primaryHeaders;
    const customFieldHeaders = this.customFieldHeaders;
    // order has to be primary > custom > sign in and sign out
    let headers = [
      ...primaryHeaders,
      ...customFieldHeaders,
      ...(this.showSignedInAndOutHeaders ? SIGN_IN_AND_SIGN_OUT_HEADER : []),
      ...(this.showExpectedArrivalTimeHeader ? EXPECTED_ARRIVAL_TIME_HEADER : []),
    ];
    headers = headers.map(({ name, componentName, sort }) => {
      return {
        name,
        componentName,
        // always show name
        show: name === 'Name' ? true : isPresent(this.entryDashboardFields.findBy('name', name)),
        // name should not be adding / removing from dashboard fields
        disabled:
          name === 'Name'
            ? true
            : (() =>
                this.selectedFlow.toLowerCase() === 'employee registration' &&
                name.toLowerCase() === 'employee registration')(),
        sort,
      };
    });
    return headers;
  }

  get flowOptions() {
    const options = [{ name: 'All Employees' }];
    if (get(this.currentLocation, 'employeeScreeningEnabled')) {
      options.push({ name: DEFAULT_FLOW_NAME.EMPLOYEE_SCREENING });
    }

    get(this.currentLocation, 'flows')
      .filter(({ employeeCentric, type, name }) => !NON_ASSIGNABLE_FLOWS.includes(type) && employeeCentric && name)
      .sortBy('name')
      .forEach((flow) => options.push(flow));
    return options.filter(Boolean).uniqBy('name');
  }

  get modalElement() {
    return document.getElementById('modal');
  }

  get canUsePartialDay() {
    return this.abilities.can('use partial day booking desk') && this.featureFlags.isEnabled('dbeam-partial-day');
  }

  selectedDidChange(selected) {
    this.selectedEntries = selected;
  }

  selectAllEntries() {
    this.selectedEntries = [...this.relevantEntries];
  }

  @action
  toggleModal() {
    this.showExportModal = !this.showExportModal;
    this.workplaceMetrics.trackEvent('WORKPLACE_EMPLOYEE_LOG_EXPORT_MODAL_BUTTON_CLICKED');
  }

  @action
  exportOrShowModal() {
    if (this.isSearching) {
      this.exportEntries(false);
    } else {
      const date = this.selectedDate.clone();
      this.startDate = date.startOf('day').format();
      this.endDate = date.endOf('day').format();
      this.showExportModal = true;
    }
  }

  @action
  exportEntries(isExportLink = false) {
    this.workplaceMetrics.trackEvent('WORKPLACE_EMPLOYEES_LOG_EXPORT_BUTTON_CLICKED', params);
    this.showExportModal = false;
    let params = this.asyncEntryExportParams();
    if (isExportLink) {
      params = { ...params, action_origin: 'export_link' };
    } else {
      if (params.filter == 'all') {
        params = { ...params, action_origin: 'export_button_without_filters' };
      } else {
        params = { ...params, action_origin: 'export_button_with_filters' };
      }
    }
    return this.asyncExportManager.exportEntriesTask.perform(params);
  }

  sortEntries(field, direction) {
    // set sort
    const dir = direction === 'asc' ? '' : '-';
    this.sort = `${dir}${field}`;
    this.metrics.trackEvent('Entries Sorted', { sort_field_name: field });
  }

  @dropTask
  *signOutEntries(entries) {
    this.workplaceMetrics.trackEvent('EMPLOYEE_LOG_BULK_SIGN_OUT_CLICKED', {
      entries: entries.map((entry) => entry.id),
    });
    yield this.showSignOutConfirmationTask.perform(entries);
    this.clearAll();
  }

  @dropTask
  *signOutEntry(entry) {
    yield this.showSignOutConfirmationTask.perform([entry]);
  }

  @dropTask
  showSignOutConfirmationTask = {
    entries: [],

    *perform(entries) {
      this.entries = entries;

      const deferred = defer();

      this.abort = () => deferred.reject();
      this.continue = () => deferred.resolve(true);

      return yield deferred.promise;
    },
  };

  // We currently manage the list of entries manually so have to take care of this
  // Not sure if there is a better solution
  removeEntryFromList(entry) {
    set(
      this.model,
      'entries',
      this.model.entries.filter((e) => e.id !== entry.id)
    );
    A(this.selectedEntries).removeObject(entry);
    this.calculateEntriesCount(-1);
  }

  deleteEntries(entries) {
    this.showDeleteConfirmationTask.perform(entries).then((result) => {
      if (result.deleteGroup) {
        this.deleteGroupEntries(entries);
      }
      entries.forEach((entry) => {
        if (!entry.signInTime) {
          this.deleteInvite.perform(entry);
        }
        this.removeEntryFromList(entry);
      });
      this.clearAll();
    });
  }

  deleteGroupEntries(entries) {
    const parents = entries.filter((entry) => entry.hasAdditionalGuests);
    const parentIds = parents.mapBy('id');
    // This API is still a WIP - we might end up with something better
    // than this
    this.model.entries.forEach((entry) => {
      if (!!entry.signInTime && parentIds.includes(entry.belongsTo('groupParent').id())) {
        this.removeEntryFromList(entry);
      }
    });
  }

  deleteEntry(entry) {
    this.showDeleteConfirmationTask.perform([entry]).then((result) => {
      if (!entry.signInTime) {
        this.deleteInvite.perform(entry);
      }
      if (result.deleteGroue) {
        this.deleteGroupEntries([entry]);
      }
      this.removeEntryFromList(entry);
    });
  }

  @dropTask
  *deleteInvite(invite) {
    return yield invite.destroyRecord();
  }

  @dropTask
  showDeleteConfirmationTask = {
    entries: [],

    *perform(entries) {
      this.entries = entries;

      const deferred = defer();

      this.abort = () => deferred.reject();
      this.continue = (args) => deferred.resolve(args);
      return yield deferred.promise;
    },
  };

  @action
  updateInputQuery(email) {
    this.workplaceMetrics.trackEvent('EMPLOYEE_LOG_SEARCHED_EMPLOYEES');

    // Set input value immediately.
    // This reduces risk of rendered input reverting to a previous value.
    this.inputQuery = email;
    this.mutateQuery.perform(this.inputQuery);
  }

  @restartableTask
  *mutateQuery(email) {
    yield timeout(zft(500));

    // "Stop if you've heard this one before"
    // If the current query is the same as the new one, do not proceed!
    // This can happen if the search input modified and reverted rapidly
    // (e.g. with a quick "undo")
    if (this.query === email) return;

    this.loadEntries.cancelAll();
    this.query = email;
    this.page = 0;
    this.metrics.trackEvent('Entries Searched', { search_value: this.query });
  }

  calculateEntriesCount(extra = 0) {
    if (this.filter === 'all' || this.filter === 'no-show') {
      this.entriesCount = this.totalLoadedInvites;
    } else {
      this.entriesCount = this.totalLoadedEntries;
    }
    this.entriesCount += extra;
  }

  entryParams(_include = []) {
    const include = ['platform-jobs', ..._include];
    const offset = (this.page - 1) * this.limit;
    const filter = {
      location: get(this.currentLocation, 'id'),
      visitor_type: this.selectedFlow,
    };

    if (isPresent(this.query)) {
      filter.query = this.query;
    }

    const featureFlagEnabled = this.abilities.can('see features for dbeam');
    if (featureFlagEnabled) {
      filter['start-date'] = moment(this.startDate).startOf('day').format();
      filter['end-date'] = moment(this.endDate).endOf('day').format();
    } else {
      filter['start-date'] = moment(this.dateWithDefault).startOf('day').format();
      filter['end-date'] = moment(this.dateWithDefault).endOf('day').format();
    }

    if (this.filter === 'signed-in') {
      filter.status = 'not-signed-out';
    }

    if (this.filter === 'signed-out') {
      filter.status = this.filter;
    }

    if (get(this.selected, 'employee')) {
      filter.employee = get(this.selected, 'employee.id');
    }

    return {
      include: include.join(),
      filter,
      page: { limit: this.limit, offset },
      sort: this.sort,
    };
  }

  @dropTask
  *loadBosses() {
    try {
      const employee = get(this.currentAdmin, 'employee');
      if (!employee) {
        return;
      }

      try {
        yield employee.hasMany('bosses').load();
      } catch (e) {
        this.workplaceMetrics.logMonitorError({
          event: 'EMPLOYEE_LOG_FAILED_TO_LOAD_BOSSES',
          error: e,
        });
      }
      const bossesIds = employee.hasMany('bosses') && employee.hasMany('bosses').ids();

      if (!bossesIds.length) {
        return;
      }
      const employeeBosses = yield this.store.query('employee', {
        filter: { id: bossesIds.join(','), deleted: false },
      });
      const bosses = employeeBosses.filter((boss) => get(boss, 'id') !== get(employee, 'id')); // prevent self bossing.
      this.bosses = bosses;
    } catch (e) {
      this.logger.error(e);
    }
  }

  @dropTask
  *loadEntries() {
    this.workplaceMetrics.trackEvent('EMPLOYEE_LOG_LOADING_ENTRIES');
    try {
      this.page++;

      const entryParams = this.entryParams();
      const entries = yield this.store.query('entry', entryParams);
      const invites = yield this._loadInvitesTask.perform();
      this.entries = entries;
      this.invites = invites;
      if (entries.length) {
        try {
          yield this.store.query('reservation', {
            filter: {
              'entry-id': entries.mapBy('id').join(','),
            },
            include: 'desk',
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Error fetching reservations', e);
        }
      }
      yield this.pollEventReports.cancelAll();
      this.pollEventReports.perform();
      this.beginPropertyChanges();
      get(this.model, 'entries').pushObjects(this.entries.toArray());
      get(this.model, 'entries').pushObjects(this.invites.toArray());
      // A side effect of manually managing the entries in our model
      // is that pubnub will occasionally resolve in a way that results
      // in duplicate entires in the model.
      //
      // Here we explicitly dedup the model. A better long term solution
      // will involve a larger refactor.
      set(this.model, 'entries', get(this.model, 'entries').uniqBy('id'));
      this.totalLoadedEntries = entries.meta.total;
      this.totalLoadedInvites = invites.meta.total;
      this.hasEntries = entries.meta['has-entries'];
      this.endPropertyChanges();
      this.calculateEntriesCount();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log({ e });
      // TODO: handle error state here. try...catch is quite big.
      // throw e;
      this.page--;
    }
  }

  inviteParams(_include = []) {
    const include = ['platform-jobs', ..._include];
    const offset = (this.page - 1) * this.limit;
    const limit = this.limit;
    const filter = {
      location: get(this.currentLocation, 'id'),
      query: this.query,
      date: this.dateWithDefault,
      visitor_type: this.selectedFlow,
    };

    if (isPresent(this.query)) {
      filter.query = this.query;
    }

    const featureFlagEnabled = this.abilities.can('see features for dbeam');
    if (featureFlagEnabled) {
      filter['start-date'] = moment(this.startDate).startOf('day').format();
      filter['end-date'] = moment(this.endDate).endOf('day').format();
    } else {
      filter['date'] = this.dateWithDefault;
    }

    // Invite Filtering in envoy-web doesn't support all as a flag
    if (this.filter !== 'all') {
      filter.status = this.filter;
    }

    if (get(this.selected, 'employee')) {
      filter.employee = get(this.selected, 'employee.id');
    }

    return {
      filter,
      page: { offset, limit },
      sort: this.invitesSort,
      include: include.join(),
    };
  }

  @enqueueTask
  *_loadInvitesTask() {
    const inviteParams = this.inviteParams();
    const invites = yield this.store.query('invite', inviteParams);

    if (invites.length) {
      try {
        yield this.store.query('reservation', {
          filter: {
            'invite-id': invites.mapBy('id').join(','),
          },
          include: 'desk',
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error fetching reservations', e);
      }
    }
    return invites;
  }

  buildEmployeeQuery() {
    return {
      page: {
        limit: this.limit,
        offset: this.employeesPage * this.limit,
      },
      filter: {
        'locations.id': get(this.currentLocation, 'id'),
        deleted: false,
      },
      include: 'user,employee-locations',
      sort: 'name',
    };
  }

  @enqueueTask
  *loadEmployeesTask() {
    const employees = yield this.store.query('employee', this.buildEmployeeQuery());
    this.employeesCount = employees.meta.total;
    if (this.employees.length) {
      // The last option is for loading more
      this.employees.pop();
    }
    this.employees.pushObjects(employees.toArray());
    const employeePromises = this.selectedEntries.map((entry) => {
      return this.store.findRecord('employee', entry.employee_id);
    });

    const selectedEmployees = yield all(employeePromises);
    this.employees.pushObjects(selectedEmployees);
    this.employees = this.employees.uniqBy('email');
    this.employeesPage++;
    if (this.hasMoreEmployeePages) {
      this.employees.pushObject('loadMore');
    }
  }

  @restartableTask
  *searchEmployeesTask(term) {
    this.workplaceMetrics.trackEvent('EMPLOYEE_LOG_SEARCHED_EMPLOYEES');

    const extraFilters = {
      'locations.id': get(this.currentLocation, 'id'),
    };
    return yield this._searchEmployeesTask.perform(term, extraFilters);
  }

  @(employeesSearcherTask({
    filter: {
      deleted: false,
    },
    include: 'user,employee-locations',
    sort: 'name',
  }).restartable())
  _searchEmployeesTask;

  get hasMoreEmployeePages() {
    return this.employeesPage * this.limit < this.employeesCount;
  }

  @dropTask
  *loadMoreEmployeesTask() {
    if (this.hasMoreEmployeePages) {
      try {
        yield this.loadEmployeesTask.perform();
      } catch (e) {
        this.employeesPage--;
      }
    }
  }

  get workplaceDays() {
    let workplaceDays = this.store.peekAll('workplaceDay').filter((day) => {
      return day.belongsTo('deskLocation').id() === this.state.currentLocationEnabledDesks.id;
    });
    if (!workplaceDays.length) {
      workplaceDays = this.defaultWorkplaceDays();
    }
    return workplaceDays;
  }

  defaultWorkplaceDays() {
    return DAYS_OF_WEEK_ARRAY.map((dayOfWeek) => {
      return {
        active: true,
        dayOfWeek: dayOfWeek,
        startTime: '00:00:00',
        endTime: '23:59:59',
      };
    });
  }

  @restartableTask
  *pollEventReports() {
    if (!get(this.currentLocation, 'hostNotificationsEnabled')) {
      return;
    }
    const store = this.store;
    const location = get(this.currentLocation, 'id');
    let keepPolling = false;
    let maxPolls = NUMBER_OF_POLLING_TRIES;
    do {
      const entryEventReportIdentifiers = store.peekAll('entry').map((entry) => `vr:entry:${get(entry, 'id')}`);
      const loadedEventReportsIdentifiers = store
        .peekAll('eventReport')
        .filterBy('hasTerminalStatus', true)
        .mapBy('identifier');
      const eventReportIdentifiers = entryEventReportIdentifiers.filter((eventReportIdentifier) => {
        return !loadedEventReportsIdentifiers.includes(eventReportIdentifier);
      });
      const identifierURI = eventReportIdentifiers.join(',');
      if (isBlank(identifierURI) || isBlank(location)) {
        break;
      }
      const newEventReportsQueryResult = yield store.query('event-report', {
        filter: { identifier: identifierURI, location },
      });
      const newEventReports = newEventReportsQueryResult.toArray();
      if (get(newEventReports, 'length')) {
        const loadedReports = this.eventReports;
        const newEventIds = newEventReports.mapBy('id');
        // Since it's possible that we could have an old event report in "pending" status that has since
        // changed to a "terminal" status, we always take the latest version of any duplicate event reports
        const updatedEventReportList = loadedReports
          .filter((report) => {
            return !newEventIds.includes(get(report, 'id'));
          })
          .concat(newEventReports);
        this.eventReports = updatedEventReportList;
      }
      // Only poll as long as there are event reports that are either `queued` or `inProgress`.
      const hasPendingEvents = newEventReports.some((event) => get(event, 'inProgress') || get(event, 'queued'));
      // The events creation for a new entry is async, so the intial request for a just created entry
      // could be empty, so we give `NUMBER_OF_POLLING_TRIES`
      maxPolls--;
      keepPolling = hasPendingEvents || maxPolls > 1;
      yield timeout(EVENT_REPORT_POLLING_TIMEOUT);
    } while ((config.environment !== 'test' || config.testLongPolling) && keepPolling);
  }

  @restartableTask
  *loadEntriesRestartableTask() {
    yield timeout(zft(250));
    this.page = 0;
    this.entries = [];
    this.invites = [];
    set(this.model, 'entries', []);
    yield this.loadEntries.perform();
  }

  /*
   *  Date Range Picker functions
   */

  @action
  onDateRangeSelect(option) {
    this.workplaceMetrics.trackEvent('EMPLOYEE_LOG_DATE_RANGE_SELECT_CLICKED');

    this.selectedDateRange = option;
    let start = startOfDay(new Date());
    let end = endOfDay(new Date());
    switch (option) {
      case 'Yesterday':
        start = subDays(start, 1);
        end = subDays(end, 1);
        break;
      case 'Tomorrow':
        start = addDays(start, 1);
        end = addDays(end, 1);
        break;
      case 'Past 7 days':
        start = subDays(start, 7);
        break;
      case 'Past 14 days':
        start = subDays(start, 14);
        break;
      case 'Past 30 days':
        start = subDays(start, 30);
        break;
      case 'Next 7 days':
        end = addDays(end, 7);
        break;
      case 'Next 14 days':
        end = addDays(end, 14);
        break;
      case 'Next 30 days':
        end = addDays(end, 30);
        break;
      case 'Custom range':
        return;
    }
    this.startDate = addMinutes(start, this.state.minutesBetweenTimezones);
    this.endDate = addMinutes(end, this.state.minutesBetweenTimezones);
    this.loadEntriesRestartableTask.perform();
  }

  @dropTask
  reservationModalTask = {
    *perform() {
      this.context.workplaceMetrics.trackEvent('EMPLOYEE_LOG_CREATE_RESERVATION_LINK_CLICKED');

      const deferred = defer();
      this.abort = () => {
        this.context.workplaceMetrics.trackEvent('EMPLOYEE_LOG_CREATE_RESERVATION_FLOW_ABORTED');

        deferred.resolve(false);
      };
      this.continue = () => {
        this.context.workplaceMetrics.trackEvent('EMPLOYEE_LOG_CREATE_RESERVATION_FLOW_COMPLETED');

        this.context.loadEntriesRestartableTask.perform();
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };

  clearAll() {
    this.selectedEntries.clear();
  }

  _loadMore() {
    // Because of the way loadMore works -- there are scenarios where
    // this can be called if destroyed
    if (this.isDestroyed) {
      return;
    }
    if (this.hasMorePages) {
      return this.loadEntries.perform();
    } else {
      return resolve();
    }
  }

  loadMore() {
    return this._loadMore();
  }

  @action
  toggleDashboardField({ name, componentName, show }) {
    // componentName is no longer used, but the backend is still validating
    let dashboardFields = get(this.currentLocation, 'dashboardFields');
    if (show) {
      dashboardFields.pushObject({ name, componentName });
    } else {
      dashboardFields = dashboardFields.filter((field) => field.name !== name);
    }
    set(this.currentLocation, 'dashboardFields', A(dashboardFields));
    this.currentLocation.save();
  }

  asyncEntryExportParams() {
    let params = {};
    const filter = this.filter;
    const location = get(this.currentLocation, 'location');
    let query = this.query;
    const employee = get(this.selected, 'employee');
    if (isBlank(query)) {
      query = null;
      const startDate = this.startDate;
      const endDate = this.endDate;
      if (isPresent(startDate) && isPresent(endDate)) {
        params.endDate = moment(endDate).endOf('day').toDate();
        params.startDate = moment(startDate).startOf('day').toDate();
      }
    }
    params = Object.assign(params, {
      employee,
      filter,
      location,
      query,
      sort: 'signed-in-at',
    });
    return params;
  }

  _hasActivePluginForCategory(name) {
    if (!this.model || !this.model.plugins || !this.model.pluginInstalls) {
      return false;
    }
    const ids = this.model.plugins.filterBy('category', name).mapBy('id');
    return this.model.pluginInstalls.toArray().some((install) => {
      const active = get(install, 'status') !== 'uninstalled';
      return active && ids.includes(get(install, 'plugin.id'));
    });
  }

  @action
  clearSearch() {
    this.page = 0;
    this.selectedFlow = DEFAULT_FLOW_NAME.EMPLOYEE_SCREENING;
    this.inputQuery = null;
    this.query = '';
  }
  async handleMessage(message) {
    if (message.event === 'showEntryExportModal') {
      this.toggleModal();
    } else if (message.event === 'closeExportModal') {
      this.toggleModal();
    }
  }

  @action
  chooseToday() {
    this.query = '';
    this.selectedDate = moment();
    this.metrics.trackEvent('Entries Date Changed', {
      date_nav_method: 'go_to_today',
      date_selected: this.selectedDate.toISOString(),
    });
  }

  @action
  closeModal() {
    this.startDate = null;
    this.endDate = null;
  }

  @action
  goBack(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    this.query = '';
    /*
      `moment.subtract` and `moment.add` mutates the value instead of
      returning a copy with the new one, it leads to unexpected
      behaviors specially when we are using CP or observers.
      */
    const date = moment(this.selectedDate);

    this.selectedDate = date.subtract(1, 'day');
    this.metrics.trackEvent('Entries Date Changed', {
      date_nav_method: 'left_arrow',
      date_selected: this.selectedDate.toISOString(),
    });
  }

  @action
  goForward(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    this.query = '';
    const date = moment(this.selectedDate);

    this.selectedDate = date.add(1, 'day');
    this.metrics.trackEvent('Entries Date Changed', {
      date_nav_method: 'right_arrow',
      date_selected: this.selectedDate.toISOString(),
    });
  }

  @action
  didSelectDate(date) {
    this.selectedDate = date;
    this.metrics.trackEvent('Entries Date Changed', {
      date_nav_method: 'calendar_selection',
      date_selected: date.toISOString(),
    });
  }

  @action
  trackGoToToday() {
    this.metrics.trackEvent('Entries Date Changed', {
      date_nav_method: 'go_to_today',
      date_selected: moment().toISOString(),
    });
  }

  @action
  toggleCalendarVisible() {
    this.mobileCalendarVisible = !this.mobileCalendarVisible;
  }

  @action
  selectFlow(flow) {
    this.beginPropertyChanges();
    const name = isPresent(flow.id) || flow.name === DEFAULT_FLOW_NAME.EMPLOYEE_SCREENING ? flow.name : '';
    this.selectedFlow = name;
    this.endPropertyChanges();
  }

  @action
  selectFilter(option) {
    this.beginPropertyChanges();
    this.filter = option.filter;
    this.endPropertyChanges();
    this.metrics.trackEvent('Entries Filtered', { filter_name: option.name });
  }

  @action
  setDateRange(start, end) {
    this.startDate = start;
    this.endDate = end;
  }
}
