import Component from '@glimmer/component';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { equal } from '@ember/object/computed';
import { gt } from 'macro-decorators';

/**
 * @param {Boolean}           disableMonthlyPrice
 * @param {Boolean}           yearlyTextOnly
 * @param {Task}              fetchEstimateTask
 * @param {Boolean}           isLoading
 * @param {String}            period monthly|yearly
 * @param {Function<Action>}  setNewPeriod
 * @param {Number}            planUnitAmount
 * @param {Number}            planUnitAmountForOtherPeriod
 * @param {Number}            planDiscountAmount
 * @param {Number}            planDiscountAmountForOtherPeriod
 * @param {Number}            quantity
 * @param {String}            selectedPlan
 */
export default class OrderConfirmationBillingPeriod extends Component {
  @equal('args.selectedPlan', 'enterprise') isEnterprisePlan;
  @equal('args.period', 'monthly') periodIsMonthly;
  @equal('args.period', 'yearly') periodIsYearly;
  @gt('yearlySavingsInPercentage', 0) hasDiscounts;

  get hasNoYearlySavings() {
    return this.yearlySavingsInCents === 0;
  }
  get monthlyPriceInCents() {
    const amount = this.args.period === 'monthly' ? this.args.planUnitAmount : this.args.planUnitAmountForOtherPeriod;

    return amount * this.args.quantity;
  }

  get yearlyPriceInCents() {
    const amount = this.args.period === 'monthly' ? this.args.planUnitAmountForOtherPeriod : this.args.planUnitAmount;

    return (amount * this.args.quantity) / 12;
  }

  get yearlyDiscountedPriceInCents() {
    const amount = this.args.period === 'monthly' ? this.args.planUnitAmountForOtherPeriod : this.args.planUnitAmount;
    const discountAmount =
      this.args.period === 'monthly' ? this.args.planDiscountAmountForOtherPeriod : this.args.planDiscountAmount;

    return (amount * this.args.quantity - discountAmount) / 12;
  }

  get yearlySavingsInCents() {
    return (this.monthlyPriceInCents - this.yearlyPriceInCents) * 12;
  }

  get yearlySavingsInDollars() {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
      .format(this.yearlySavingsInCents / 100)
      .replace(/.00$/, '');
  }

  get yearlySavingsInPercentage() {
    return 100 - Math.ceil((this.yearlyDiscountedPriceInCents * 100) / this.yearlyPriceInCents);
  }
}
