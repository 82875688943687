import Route from '@ember/routing/route';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';
import { v4 as uuid } from 'uuid';
import EmberObject from '@ember/object';

export default class extends Route {
  @service metrics;
  @service state;

  model() {
    const { globalFlow } = this.modelFor('location-overview.sign-in-flows.show');
    const agreementPage = globalFlow.agreementPage;
    const agreements = get(agreementPage, 'agreements');
    const signInFields = get(globalFlow.signInFieldPage, 'signInFields');
    const tracking = EmberObject.create({
      jobId: uuid(),
      currentCompanyId: this.state.currentCompany.id,
    });

    return hash({
      agreements,
      agreementPage,
      flow: globalFlow,
      signInFields,
      tracking,
    });
  }

  @action
  trackAddLegalDocStarted() {
    const model = this.modelFor(this.routeName);
    this.metrics.trackEvent('Global Flow - Legal Docs - Go To Add Legal Document Button Clicked', {
      button_text: 'add another document',
      company_id: model.tracking.currentCompanyId,
      job_id: model.tracking.jobId,
    });
    set(model.tracking, 'eventName', 'Global Flow - Legal Docs - Add Legal Document Save Clicked');
  }

  @action
  trackEditLegalDocStarted() {
    const model = this.modelFor(this.routeName);
    set(model.tracking, 'eventName', 'Global Flow - Legal Docs - Legal Document Edited');
  }
}
