import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { get, set } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { and, gt, not } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { all, dropTask, restartableTask, task } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

const integration_name = 'Visual Compliance';
const message_code_type = 'integration';

/**
 * @param {Class<Location>}                                 location
 * @param {Class<VisualComplianceConfiguration>}            configuration
 */
export default class VisualComplianceConfigure extends Component {
  @service currentLocation;
  @service flashMessages;
  @service metrics;
  @service store;

  @and('secno', 'password') canConnect;
  @and('args.configuration.secno', 'validCredentials') isConnected;
  @not('args.configuration.credentialsInvalid') validCredentials;
  @gt('connectTask.performCount', 0) didAttemptConnect;

  @tracked contacts = [];
  @tracked secno;
  @tracked confirmDisconnect;
  @tracked password;

  get activeFlows() {
    return this.args.location.flows.filter((flow) => flow.visualComplianceEnabled);
  }

  get inactiveFlows() {
    return this.args.location.flows.filter((flow) => !flow.visualComplianceEnabled);
  }

  constructor() {
    super(...arguments);
    this.secno = this.args.configuration?.secno;
    this.loadContactsTask.perform();
  }

  @dropTask
  *connectTask() {
    this.metrics.trackJobEvent('Security - Integration Login Button Clicked', {
      integration_name,
      button_text: 'Connect account',
    });
    if (!this.canConnect) return;
    try {
      set(this.args.configuration, 'secno', this.secno);
      set(this.args.configuration, 'password', this.password);
      this.metrics.trackJobEvent('Security - Integration Login Requested', {
        integration_name,
      });
      yield this.args.configuration.save();
      if (this.validCredentials) {
        this.metrics.trackJobEvent('Security - Integration Account Connected', { integration_name });
        this.flashMessages.showAndHideFlash('success', 'Connected!');
        this.metrics.trackJobEvent('Viewed Flash Message', {
          type: 'success',
          integration_name,
          message_title: 'Connected!',
          message_code: 'integration_login_success',
          message_code_type,
        });
      }
    } catch (e) {
      set(this.args.configuration, 'secno', '');
      set(this.args.configuration, 'password', '');
      const message_title = 'Error connecting Visual Compliance';
      const message_code = parseErrorForDisplay(e);
      this.flashMessages.showFlash('error', message_title, message_code);
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'error',
        integration_name,
        message_title,
        message_code,
        message_code_type,
      });
    }
  }

  @dropTask
  *disconnectTask() {
    this.metrics.trackJobEvent('Security - Integration Logout Button Clicked', {
      integration_name,
      button_text: 'Connect Visual Compliance',
    });
    set(this.args.configuration, 'secno', '');
    set(this.args.configuration, 'password', '');
    this.secno = '';
    this.password = '';
    try {
      this.metrics.trackJobEvent('Security - Integration Logout Requested', {
        integration_name,
      });
      yield this.args.configuration.save();
      this.metrics.trackJobEvent('Security - Integration Account Disconnected', { integration_name });
      this.flashMessages.showAndHideFlash('success', 'Disconnected!');
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'success',
        integration_name,
        message_title: 'Disconnected!',
        message_code: 'integration_logout_success',
        message_code_type,
      });
    } catch (e) {
      this.args.configuration.rollbackAttributes();
      this.secno = this.args.configuration.secno;
      const message_title = 'Error disconnecting Visual Compliance';
      const message_code = parseErrorForDisplay(e);
      this.flashMessages.showFlash('error', message_title, message_code);
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'error',
        integration_name,
        message_title,
        message_code,
        message_code_type,
      });
    } finally {
      this.confirmDisconnect = false;
    }
  }

  @task
  *loadContactsTask() {
    try {
      const contacts = yield all(this.args.configuration.contactIds.map((id) => this.store.findRecord('user', id)));
      this.contacts = contacts;
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error loading contacts', parseErrorForDisplay(e));
    }
  }

  @restartableTask
  *searchUsersTask(term) {
    const re = new RegExp(`.*${term}.*`, 'i');
    const userRoles = yield get(this.currentLocation, 'searchUsers').perform(term);
    const users = yield all(userRoles.map((role) => get(role, 'user')));
    return users
      .uniqBy('id') // dedup since roles could point to same user
      .reject(({ id }) => this.args.configuration.contactIds.map((n) => n.toString()).includes(id)) // remove existing contacts, compare as strings
      .filter(({ fullName }) => fullName.match(re));
  }

  @restartableTask
  *saveUpdatedContactsTask(users) {
    const previousContacts = [...this.contacts];
    this.contacts = users;
    set(this.args.configuration, 'contactIds', users.mapBy('id'));
    try {
      this.metrics.trackJobEvent('Security - Integration Settings Update Requested', { integration_name });
      yield this.args.configuration.save();
      this.metrics.trackJobEvent('Security - Integration Settings Updated', {
        integration_name,
        visual_compliance_contact_ids: this.args.configuration.contactIds.map(parseInt),
        visual_compliance_contacts_modified: true,
      });
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'success',
        integration_name,
        message_title: 'Saved!',
        message_code: 'integration_update_success',
        message_code_type,
      });
    } catch (e) {
      this.contacts = previousContacts;
      yield this.args.configuration.rollbackAttributes();
      const message_title = 'Error';
      const message_code = parseErrorForDisplay(e);
      this.flashMessages.showFlash('error', message_title, message_code);
      this.metrics.trackJobEvent('Viewed Flash Message', {
        type: 'error',
        integration_name,
        message_title,
        message_code,
        message_code_type,
      });
    }
  }
}
