import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { localCopy } from 'tracked-toolbox';
import { buildWaiter } from '@ember/test-waiters';
import config from 'garaje/config/environment';

const testWaiter = buildWaiter('employees-profile-photo-uploader-component-waiter');
const isTesting = config.environment === 'test';
const DEFAULT_SIZE = 512;
const DEFAULT_SCALE = 1;

/**
 * @param {String}            currentPhoto          URL for the user/employee's current photo
 * @param {Number}            outputSize            Size of square output image in px (default: 512)
 * @param {Number}            scale                 How much to zoom in on image (default: 1, min: 1)
 * @param {String|RegExp}     validType             Allowed file types
 * @param {String}            validationError       Custom validation failure message
 * @param {Function}          onError               Action for handling errors
 * @param {Function}          onFileSelected        Action triggered by the original file selection
 * @param {Function}          onFilePreview         Action triggered after the file is read into a data URL
 * @param {Function}          onFilePrepared        Action triggered after the image is resized/compressed
 */
export default class EmployeesProfilePhotoUploaderInputComponent extends Component {
  // Reference to canvas to render adjusted profile photo
  _canvas;

  // Reference a blank canvas to use as "drag image" (override default "ghost element")
  _dragImage;

  // Reference to async test token
  token = false;

  // References for Firefox alternative event handling functions
  _handleMousemove;
  _handleMouseup;

  @tracked isValid = true;
  @tracked isFile = false;
  @tracked fileData;
  @tracked previewSrc;
  @tracked isProcessing = false;

  @tracked dragX = 0;
  @tracked dragY = 0;
  @tracked positionX = 0;
  @tracked positionY = 0;

  @localCopy('args.currentPhoto', '') currentPhoto;
  @localCopy('args.outputSize', DEFAULT_SIZE) outputSize;
  @localCopy('args.scale', DEFAULT_SCALE) scale;
  @localCopy('args.validType', /^image\/[^\/]+$/) validType;
  @localCopy('args.validationError', 'Please select an image file') validationError;

  // Action!
  @localCopy('args.onError') onError;
  @localCopy('args.onFileSelected') onFileSelected;
  @localCopy('args.onFilePreview') onFilePreview;
  @localCopy('args.onFilePrepared') onFilePrepared;

  get canvas() {
    return (this._canvas ||= document.createElement('canvas'));
  }

  get validTypeReg() {
    const { validType } = this;

    if (validType instanceof RegExp) {
      return validType;
    }

    return new RegExp(validType);
  }

  get safeOutputSize() {
    // Size must be at least 1px
    return Math.max(1, parseInt(this.outputSize, 10) || DEFAULT_SIZE);
  }

  get safeOutputScale() {
    // Scale must be at least 1
    return Math.max(1, Number(this.scale) || DEFAULT_SCALE);
  }

  get dragImage() {
    if (this._dragImage) return this._dragImage;

    const dragImage = document.createElement('canvas');

    dragImage.width = 1;
    dragImage.height = 1;

    return (this._dragImage = dragImage);
  }

  get handleMousemove() {
    return (this._handleMousemove ||= (event) => {
      // If mousemove without button pressed, stop watching
      if (`${event.buttons}` !== '1') {
        return this.removeDocumentMouseEventHandlers();
      }

      if (event.mozInputSource) this.repositionPhoto(event);
    });
  }

  get handleMouseup() {
    return (this._handleMouseup ||= (_) => {
      this.removeDocumentMouseEventHandlers();
    });
  }

  @action
  fileSelected(files) {
    this.isValid = false;
    this.isFile = false;

    const { validationError, onFileSelected, onError } = this;

    if (!files?.length) return;

    const file = files.item(0);
    const reader = new FileReader();

    if (!this.validate(file)) {
      onError?.(`${validationError}`);

      return;
    }

    this.token = isTesting ? testWaiter.beginAsync() : false;
    this.isFile = true;

    reader.onload = () => {
      const { result } = reader;

      this.fileData = result;
      this.renderFilePreview(this.fileData);
    };

    reader.readAsDataURL(file);
    onFileSelected?.(file);
  }

  @action
  handleScaleChange() {
    const { fileData, isProcessing } = this;

    // If no file is selected or a preview rendering process is already
    // running, don't start a new rendering process.
    if (!fileData || isProcessing) return;

    this.renderFilePreview(fileData);
  }

  @action
  handleDragStart(event) {
    event.stopPropagation();

    if (event.mozInputSource) return;

    const { dragImage } = this;

    // Fixes a Chrome quirk
    document.body?.appendChild(dragImage);

    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setDragImage?.(dragImage, 0, 0);

    this.dragX = event.x;
    this.dragY = event.y;
  }

  @action
  handleDrag(event) {
    // Firefox doesn't send valid x, y coordinates with drag events
    if (event.x || event.y) this.repositionPhoto(event);
  }

  @action
  handleDragEnd(event) {
    event.preventDefault();
    event.stopPropagation();

    // Cleanup
    document.body?.removeChild(this.dragImage);
  }

  /*
    Firefox does not provide pointer coordinates (i.e. event.x and event.y) with `drag` events.

    When we detect Firefox mouse events, fallback to mousedown/mousemove/mouseup
    for adjusting profile photo positioning.

    Why not use mousemove events in all browsers? Mousemove has its own quirks like
    allowing text on the page to be highlighted and dragged.
  */
  @action
  handleMousedown(event) {
    // Firefox drag events do not include pointer coordinates
    // Fallback to mousemove events in Firefox (event.mozInputSource === 1).
    if (!event.mozInputSource) return;

    event.target.draggable = false;

    this.dragX = event.x;
    this.dragY = event.y;

    this.addDocumentMouseEventHandlers();
  }

  addDocumentMouseEventHandlers() {
    document.addEventListener('mousemove', this.handleMousemove);
    document.addEventListener('mouseup', this.handleMouseup);
  }

  removeDocumentMouseEventHandlers() {
    document.removeEventListener('mousemove', this.handleMousemove);
    document.removeEventListener('mouseup', this.handleMouseup);
  }

  repositionPhoto(event) {
    const { fileData, isProcessing, dragX, dragY } = this;

    // If no file is selected or a preview rendering process is already
    // running, don't start a new rendering process.
    if (!fileData || isProcessing) return;

    this.positionX = this.positionX + event.x - dragX;
    this.positionY = this.positionY + event.y - dragY;

    this.dragX = event.x;
    this.dragY = event.y;

    this.renderFilePreview(fileData);
  }

  validate(file) {
    const typeValid = this.validType ? this.validTypeReg.test(file.type) : true;

    this.isValid = typeValid;

    return this.isValid;
  }

  renderFilePreview(data) {
    const imageTag = new Image();

    imageTag.onload = () => {
      this.compressPicture(data, imageTag);
    };

    this.isProcessing = true;
    imageTag.src = data;
    this.onFilePreview?.(data);
  }

  compressPicture(data, img) {
    const {
      safeOutputSize: outputSize,
      safeOutputScale: scale,
      positionX,
      positionY,
      onFilePrepared,
      onError,
      token,
      previewSrc,
    } = this;
    const outputImageAspectRatio = 1; // SQUARE

    if (previewSrc) URL.revokeObjectURL(previewSrc);

    try {
      const { canvas } = this;
      const { naturalWidth, naturalHeight } = img;
      const inputImageAspectRatio = naturalWidth / naturalHeight;

      let dWidth = outputSize * scale;
      let dHeight = outputSize * scale;

      if (inputImageAspectRatio > outputImageAspectRatio) {
        dWidth = naturalWidth * (outputSize / naturalHeight) * scale;
      } else if (inputImageAspectRatio < outputImageAspectRatio) {
        dHeight = naturalHeight * (outputSize / naturalWidth) * scale;
      }

      // Default: centered on X-axis, "rule of 3rds" on Y-axis
      const defaultX = (outputSize - dWidth) * 0.5;
      const defaultY = (outputSize - dHeight) * 0.3;
      const repositionedX = defaultX + positionX;
      const repositionedY = defaultY + positionY;
      const outputX = Math.min(0, Math.max(repositionedX, outputSize - dWidth));
      const outputY = Math.min(0, Math.max(repositionedY, outputSize - dHeight));

      // Keep the photo "in bounds"
      this.positionX = this.positionX + outputX - repositionedX;
      this.positionY = this.positionY + outputY - repositionedY;

      canvas.width = outputSize;
      canvas.height = outputSize;

      const ctx = canvas.getContext('2d');

      ctx.clearRect(0, 0, outputSize, outputSize);
      ctx.drawImage(img, outputX, outputY, dWidth, dHeight);

      canvas.toBlob(
        (blob) => {
          const file = new File([blob], `photo-${Date.now()}.png`, {
            type: blob.type,
          });

          this.previewSrc = URL.createObjectURL(file);

          onFilePrepared?.(file);

          if (token) testWaiter.endAsync(token);
          this.isProcessing = false;
        },
        'image/png',
        0.8
      );
    } catch (error) {
      onError?.(error);
      this.isProcessing = false;
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);

    if (this.previewSrc) {
      URL.revokeObjectURL(this.previewSrc);
    }

    this.removeDocumentMouseEventHandlers();

    delete this._handleMousemove;
    delete this._handleMouseup;
    delete this._canvas;
    delete this._dragImage;
    delete this.fileData;
  }
}
