import Service, { inject as service } from '@ember/service';
import { addDays, endOfDay, isSameDay, startOfDay, fromUnixTime } from 'date-fns';
import type {
  EmployeeRegistrationPartialDayQuery,
  EmployeeRegistrationPartialDayQueryVariables,
  ChangeDeskReservationMutation,
  ChangeDeskReservationMutationVariables,
  CheckInReservationMutation,
  CheckInReservationMutationVariables,
  DeleteInviteReservationMutation,
  DeleteInviteReservationMutationVariables,
  DesksOnFloorForLocationQueryVariables,
  DeleteInviteMutation,
  DeleteInviteMutationVariables,
  ReleaseDeskReservationMutation,
  ReleaseDeskReservationMutationVariables,
  AvailableDesksForLocationQuery,
  AvailableDesksForLocationQueryVariables,
  DesksOnFloorForLocationQuery,
  SignOutEntryReservationMutation,
  SignOutEntryReservationMutationVariables,
  SignOutEntryMutation,
  SignOutEntryMutationVariables,
  SignInInviteMutation,
  SignInInviteMutationVariables,
  CreateInviteReservationMutationVariables,
  ReservationByIdQuery,
  CreateInviteReservationMutation,
  ReservationByIdQueryVariables,
  EmployeeRegistrationDatesQuery,
  EmployeeRegistrationDatesQueryVariables,
  InviteFragmentFragment,
  ReservationFragmentFragment,
} from 'garaje/graphql/generated/employee-schedule-types';
import { DeskStatusEnum } from 'garaje/graphql/generated/employee-schedule-types';
import changeDeskReservationMutation from 'garaje/graphql/mutations/employee-schedule/ChangeDeskReservationMutation';
import checkInReservationMutation from 'garaje/graphql/mutations/employee-schedule/CheckInReservationMutation';
import createInviteReservationMutation from 'garaje/graphql/mutations/employee-schedule/CreateInviteReservationMutation';
import deleteInviteMutation from 'garaje/graphql/mutations/employee-schedule/DeleteInviteMutation';
import deleteInviteReservationMutation from 'garaje/graphql/mutations/employee-schedule/DeleteInviteReservationMutation';
import deleteInviteReservationsMutation from 'garaje/graphql/mutations/employee-schedule/DeleteInviteReservationsMutation';
import releaseDeskReservationMutation from 'garaje/graphql/mutations/employee-schedule/ReleaseDeskReservationMutation';
import signInInviteMutation from 'garaje/graphql/mutations/employee-schedule/SignInInviteMutation';
import signOutEntryMutation from 'garaje/graphql/mutations/employee-schedule/SignOutEntryMutation';
import signOutEntryReservationMutation from 'garaje/graphql/mutations/employee-schedule/SignOutEntryReservationMutation';
import availableDesksForLocationQuery from 'garaje/graphql/queries/employee-schedule/AvailableDesksForLocationQuery';
import desksOnFloorForLocationQuery from 'garaje/graphql/queries/employee-schedule/DesksOnFloorForLocationQuery';
import employeeRegistrationQuery from 'garaje/graphql/queries/employee-schedule/EmployeeRegistrationDatesQuery';
import employeeRegistrationPartialDayQuery from 'garaje/graphql/queries/employee-schedule/EmployeeRegistrationPartialDayQuery';
import reservationByIdQuery from 'garaje/graphql/queries/employee-schedule/ReservationByIdQuery';
import type ApolloService from 'garaje/services/apollo-extension';
import type StateService from 'garaje/services/state';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import { DEFAULT_FLOW_NAME } from 'garaje/utils/enums';

export default class ScheduleGraphqlService extends Service {
  @service declare apolloExtension: ApolloService;
  @service declare state: StateService;
  @service declare workplaceMetrics: WorkplaceMetricsService;

  isDbeamEnabled(): boolean {
    // TODO: replace with feature flag service
    return false;
  }

  async fetchRegistrations({
    startDate,
    length = 30,
  }: {
    startDate: Date;
    length: number;
  }): Promise<EmployeeRegistrationDatesQuery['employeeRegistration']> {
    const { id: locationId } = this.state.currentLocation;
    const variables = {
      includeEmployeeInvite: this.isDbeamEnabled(),
      locationId,
      startDate,
      endDate: addDays(startDate, length - 1),
    };
    try {
      return await this.apolloExtension.query<
        EmployeeRegistrationDatesQuery,
        EmployeeRegistrationDatesQueryVariables,
        'employeeRegistration'
      >(
        {
          query: employeeRegistrationQuery,
          variables,
        },
        'employeeRegistration'
      );
    } catch (e) {
      this.workplaceMetrics.logMonitorError({
        event: 'ERROR_FETCHING_REGISTRATIONS',
        debugExtras: { variables },
        error: e,
      });
      throw e;
    }
  }

  async fetchPartialDayRegistrations({
    startDate,
    length = 30,
  }: {
    startDate: Date;
    length: number;
  }): Promise<EmployeeRegistrationPartialDayQuery['employeeRegistrationPartialDay']> {
    const { id: locationId } = this.state.currentLocation;
    const variables = {
      includeEmployeeInvite: this.isDbeamEnabled(),
      locationId,
      startDate,
      endDate: addDays(startDate, length - 1),
    };
    try {
      return await this.apolloExtension.query<
        EmployeeRegistrationPartialDayQuery,
        EmployeeRegistrationPartialDayQueryVariables,
        'employeeRegistrationPartialDay'
      >(
        {
          query: employeeRegistrationPartialDayQuery,
          variables: variables,
        },
        'employeeRegistrationPartialDay'
      );
    } catch (e) {
      this.workplaceMetrics.logMonitorError({
        event: 'ERROR_FETCHING_PARTIAL_DAY_REGISTRATIONS',
        debugExtras: { variables },
        error: e,
      });
      throw e;
    }
  }

  /**
   *  Changes a desk reservation to a new desk
   */
  async changeDesk({
    currentDeskId,
    newDeskId,
    reservationId,
  }: {
    currentDeskId: number;
    newDeskId: number;
    reservationId: number;
  }): Promise<ChangeDeskReservationMutation['changeDeskReservation']> {
    const variables = {
      currentDeskID: currentDeskId,
      newDeskID: newDeskId,
      reservationID: reservationId,
    };
    try {
      return await this.apolloExtension.mutate<
        ChangeDeskReservationMutation,
        ChangeDeskReservationMutationVariables,
        'changeDeskReservation'
      >(
        {
          mutation: changeDeskReservationMutation,
          variables: variables,
        },
        'changeDeskReservation'
      );
    } catch (e) {
      this.workplaceMetrics.logMonitorError({
        event: 'ERROR_CHANGING_DESK',
        debugExtras: { variables },
        error: e,
      });
      throw e;
    }
  }

  /**
   * @param {Object}  {
   *   reservationId: Number    Required
   * }
   * Checks in a desk reservation
   */
  async checkInReservation({
    reservationId,
  }: {
    reservationId: number;
  }): Promise<CheckInReservationMutation['checkInReservation']> {
    const variables = {
      reservationID: reservationId,
    };
    try {
      return await this.apolloExtension.mutate<
        CheckInReservationMutation,
        CheckInReservationMutationVariables,
        'checkInReservation'
      >(
        {
          mutation: checkInReservationMutation,
          variables: variables,
        },
        'checkInReservation'
      );
    } catch (e) {
      this.workplaceMetrics.logMonitorError({
        event: 'ERROR_CHECKING_IN_RESERVATION',
        debugExtras: { variables },
        error: e,
      });
      throw e;
    }
  }

  /**
   * @param {Object}  {
   *   inviteId: Number         Required
   *   reservationId: Number    Optional
   * }
   *
   * Unscheduling releases a desk reservation and also deletes the invite
   */
  async unschedule({
    reservationId,
    inviteId,
  }: {
    reservationId: number;
    inviteId: number;
  }): Promise<DeleteInviteMutation['deleteInvite'] | undefined> {
    const variables = reservationId ? { inviteId, reservationId } : { inviteId };
    try {
      if (reservationId) {
        return (await this.apolloExtension.mutate<
          DeleteInviteReservationMutation,
          DeleteInviteReservationMutationVariables,
          // @ts-ignore we screwed up here adn this is never in the mutation
          'deleteInviteReservation'
        >(
          {
            mutation: deleteInviteReservationMutation,
            variables: variables,
          },
          'deleteInviteReservation'
        )) as undefined;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return await this.apolloExtension.mutate<DeleteInviteMutation, DeleteInviteMutationVariables, 'deleteInvite'>(
          {
            mutation: deleteInviteMutation,
            variables: variables,
          },
          'deleteInvite'
        );
      }
    } catch (e) {
      this.workplaceMetrics.logMonitorError({
        event: 'ERROR_UNSCHEDULING',
        debugExtras: { variables },
        error: e,
      });
      throw e;
    }
  }

  /**
   * @param {Object}  {
   *   inviteId: Number                     Required
   *   reservationIds: Array of numbers     Optional
   * }
   *
   * Unscheduling releases a desk reservation and also deletes the invite
   */
  async unschedulePartialDay({
    reservationIds = [],
    inviteId,
  }: {
    reservationIds: number[];
    inviteId: number;
  }): Promise<DeleteInviteMutation['deleteInvite'] | undefined> {
    const variables = {
      inviteId,
      reservationIds,
    };
    try {
      return (await this.apolloExtension.mutate<
        DeleteInviteReservationMutation,
        DeleteInviteReservationMutationVariables,
        // @ts-ignore also screwing up here, fixme!
        'deleteInviteReservations'
      >(
        {
          mutation: deleteInviteReservationsMutation,
          variables: variables,
        },
        'deleteInviteReservations'
      )) as undefined;
    } catch (e) {
      this.workplaceMetrics.logMonitorError({
        event: 'ERROR_UNSCHEDULING_PARTIAL_DAY',
        debugExtras: { variables },
        error: e,
      });
      throw e;
    }
  }

  /**
   * Releasing a desk does not delete the invite
   */
  async releaseDeskReservation({
    reservationId,
  }: {
    reservationId: number;
  }): Promise<ReleaseDeskReservationMutation['releaseDeskReservation']> {
    try {
      return await this.apolloExtension.mutate<
        ReleaseDeskReservationMutation,
        ReleaseDeskReservationMutationVariables,
        'releaseDeskReservation'
      >(
        {
          mutation: releaseDeskReservationMutation,
          variables: {
            reservationId,
          },
        },
        'releaseDeskReservation'
      );
    } catch (e) {
      this.workplaceMetrics.logMonitorError({
        event: 'ERROR_RELEASING_DESK_RESERVATION',
        debugExtras: { reservationId },
        error: e,
      });
      throw e;
    }
  }

  /**
   * @param {Object}  {
   *   date: Date     Required
   * }
   *
   * Releasing a desk does not delete the invite
   */
  // When using `this.moment.utc(date)`, many timezones (all except Pacific, Alaska, and Samoa) will include desks for two different days (the correct day and the day before).
  // However, when using `this.moment.moment(date)`, those timezones will only return one day... the wrong one (the day before the selected day).
  // There is probably a solution that solves this more precisely, and I encourage others to look for it.
  async fetchRegistrationByDate({
    date,
  }: {
    date: Date;
  }): Promise<EmployeeRegistrationDatesQuery['employeeRegistration']['registrationDates'][0] | undefined> {
    const { id: locationId } = this.state.currentLocation;
    const variables = {
      includeEmployeeInvite: this.isDbeamEnabled(),
      locationId,
      startDate: startOfDay(date),
      endDate: endOfDay(date),
    };

    try {
      const response = await this.apolloExtension.query<
        EmployeeRegistrationDatesQuery,
        EmployeeRegistrationDatesQueryVariables,
        'employeeRegistration'
      >(
        {
          query: employeeRegistrationQuery,
          variables: variables,
        },
        'employeeRegistration'
      );

      return response.registrationDates?.find((rd) => isSameDay(rd.date, date));
    } catch (e) {
      this.workplaceMetrics.logMonitorError({
        event: 'ERROR_FETCHING_REGISTRATION_BY_DATE',
        debugExtras: { variables },
        error: e,
      });
      throw e;
    }
  }

  async fetchPartialDayRegistrationByDate({
    date,
  }: {
    date: Date;
  }): Promise<
    EmployeeRegistrationPartialDayQuery['employeeRegistrationPartialDay']['registrationDates'][0] | undefined
  > {
    const { id: locationId } = this.state.currentLocation;
    const variables = {
      includeEmployeeInvite: this.isDbeamEnabled(),
      locationId,
      startDate: startOfDay(date),
      endDate: endOfDay(date),
    };
    try {
      const response = await this.apolloExtension.query<
        EmployeeRegistrationPartialDayQuery,
        EmployeeRegistrationPartialDayQueryVariables,
        'employeeRegistrationPartialDay'
      >(
        {
          query: employeeRegistrationPartialDayQuery,
          variables: variables,
        },
        'employeeRegistrationPartialDay'
      );

      return response.registrationDates?.find((rd) => isSameDay(rd.date, date));
    } catch (e) {
      this.workplaceMetrics.logMonitorError({
        event: 'ERROR_FETCHING_PARTIAL_DAY_REGISTRATION_BY_DATE',
        debugExtras: { variables },
        error: e,
      });
      throw e;
    }
  }
  /**
   * @param {Object}  {
   *  date: Date    Required
   * }
   * @returns {Array<gqlDesk>}  List of graphql desks
   *
   * Releasing a desk does not delete the invite
   */
  async fetchAvailableDesks({
    date,
  }: {
    date: Date;
  }): Promise<AvailableDesksForLocationQuery['availableDesksForLocation']> {
    const { id: locationId } = this.state.currentLocation;
    const variables = {
      locationId,
      date: date,
    };
    try {
      return await this.apolloExtension.query<
        AvailableDesksForLocationQuery,
        AvailableDesksForLocationQueryVariables,
        'availableDesksForLocation'
      >(
        {
          query: availableDesksForLocationQuery,
          fetchPolicy: 'network-only',
          variables: variables,
        },
        'availableDesksForLocation'
      );
    } catch (e) {
      this.workplaceMetrics.logMonitorError({
        event: 'ERROR_FETCHING_AVAILABLE_DESKS',
        debugExtras: { variables },
        error: e,
      });
      throw e;
    }
  }

  /**
   * @param {Object}  {
   *  date: Date    Required
   * }
   * * @param {Object}  {
   *  floorId: Number    Required
   * }
   * @returns {Array<gqlDesk>}  List of graphql desks
   *
   * Releasing a desk does not delete the invite
   */
  async fetchAvailableDesksWithAmenities({
    reservation,
    floorId,
  }: {
    reservation: ReservationFragmentFragment;
    floorId: number;
  }): Promise<DesksOnFloorForLocationQuery['desksOnFloorForLocation']['desks']> {
    const { id: locationId } = this.state.currentLocation;
    const variables = {
      locationId,
      // for some reason this was working before
      floorId: floorId as unknown as string,
      startTime: fromUnixTime(reservation.startTime!),
      endTime: fromUnixTime(reservation.endTime!),
      filters: [DeskStatusEnum.Available],
    };
    try {
      const queryResponse = await this.apolloExtension.query<
        DesksOnFloorForLocationQuery,
        DesksOnFloorForLocationQueryVariables,
        'desksOnFloorForLocation'
      >(
        {
          query: desksOnFloorForLocationQuery,
          fetchPolicy: 'network-only',
          variables: variables,
        },
        'desksOnFloorForLocation'
      );
      return queryResponse.desks;
    } catch (e) {
      this.workplaceMetrics.logMonitorError({
        event: 'ERROR_FETCHING_AVAILABLE_DESKS_WITH_AMENITIES',
        debugExtras: { variables },
        error: e,
      });
      throw e;
    }
  }

  /**
   * @param {Object}  {
   *  entryId:        Number    Required
   *  signedOutAt:  Date        Required
   *  reservationId:  Number    Optional
   *
   * @returns {gqlEntry}  Graphql representation of an entry
   *
   * Sign out an entry
   */
  async signOutEntry({
    entryId,
    reservationId,
    signedOutAt,
  }: {
    entryId: number;
    reservationId: number;
    signedOutAt: Date;
  }): Promise<SignOutEntryMutation['signOutEntry'] | SignOutEntryReservationMutation['signOutEntry']> {
    const variables = reservationId
      ? { id: entryId, reservationId, signedOutAt }
      : {
          id: entryId,
          signedOutAt,
        };

    try {
      if (reservationId) {
        return await this.apolloExtension.mutate<
          SignOutEntryReservationMutation,
          SignOutEntryReservationMutationVariables,
          'signOutEntry'
        >(
          {
            mutation: signOutEntryReservationMutation,
            variables: variables,
          },
          'signOutEntry'
        );
      } else {
        return await this.apolloExtension.mutate<SignOutEntryMutation, SignOutEntryMutationVariables, 'signOutEntry'>(
          {
            mutation: signOutEntryMutation,
            variables: variables,
          },
          'signOutEntry'
        );
      }
    } catch (e) {
      this.workplaceMetrics.logMonitorError({
        event: 'ERROR_SIGNING_OUT_ENTRY',
        debugExtras: { variables },
        error: e,
      });
      throw e;
    }
  }

  /**
   * @param {Object}  {
   *  inviteId:       Number        Required
   *  reservationId:  Number        Optional
   * }
   *
   * @returns {gqlEntry}  Graphql representation of an entry
   *
   * Signing in an invite returns an entry
   */
  async signInInvite({
    inviteId,
    reservationId,
  }: {
    inviteId: number;
    reservationId: number;
  }): Promise<SignInInviteMutation['signInInvite']> {
    const variables = {
      inviteID: inviteId,
      reservationId,
    };
    try {
      return await this.apolloExtension.mutate<SignInInviteMutation, SignInInviteMutationVariables, 'signInInvite'>(
        {
          mutation: signInInviteMutation,
          variables: variables,
        },
        'signInInvite'
      );
    } catch (e) {
      this.workplaceMetrics.logMonitorError({
        event: 'ERROR_SIGNING_IN_INVITE',
        debugExtras: { variables },
        error: e,
      });
      throw e;
    }
  }

  /**
   * @param {Object}  {
   *   date: Date
   *   inviteId: Number
   *   deskId: Number
   * }
   *
   * Creates a desk reservation and invite
   * This is the v2 endpoint. trying to move to this
   */
  async createInviteReservation({
    screeningCard,
    inviteId = null,
    deskId = null,
  }: {
    screeningCard: InviteFragmentFragment;
    inviteId: number | null;
    deskId: number | null;
  }): Promise<CreateInviteReservationMutation['createInviteReservation']> {
    const { currentUser, currentLocation } = this.state;
    const { id: location } = currentLocation;
    const variables = {
      includeEmployeeInvite: this.isDbeamEnabled(),
      ...(inviteId ? { inviteId } : {}),
      ...(deskId ? { deskId } : {}),
      ...(!inviteId
        ? {
            invite: {
              fullName: currentUser.fullName,
              email: currentUser.email,
              location,
              userData: [
                {
                  field: 'Purpose of visit',
                  value: DEFAULT_FLOW_NAME.EMPLOYEE_SCREENING,
                },
              ],
              expectedArrivalTime: screeningCard.expectedArrivalTime,
            },
          }
        : {}),
    };

    try {
      return await this.apolloExtension.mutate<
        CreateInviteReservationMutation,
        CreateInviteReservationMutationVariables,
        'createInviteReservation'
      >(
        {
          mutation: createInviteReservationMutation,
          variables: variables,
        },
        'createInviteReservation'
      );
    } catch (e) {
      this.workplaceMetrics.logMonitorError({
        event: 'ERROR_CREATING_INVITE_RESERVATION',
        debugExtras: { variables },
        error: e,
      });
      throw e;
    }
  }

  async fetchReservationById({
    reservationId,
  }: {
    reservationId: string;
  }): Promise<ReservationByIdQuery['reservationById']> {
    try {
      return await this.apolloExtension.query<ReservationByIdQuery, ReservationByIdQueryVariables, 'reservationById'>(
        {
          query: reservationByIdQuery,
          variables: { reservationId },
        },
        'reservationById'
      );
    } catch (e) {
      this.workplaceMetrics.logMonitorError({
        event: 'ERROR_FETCHING_RESERVATION_BY_ID',
        debugExtras: { reservationId },
        error: e,
      });
      throw e;
    }
  }
}
