import Service, { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { all, task } from 'ember-concurrency';
import type {
  AssignedAndUnassignedEmployees,
  AssignmentFilterInput,
  Desk,
  GetAssignedAndUnassignedEmployeesInput,
  GetAssignedAndUnassignedEmployeesQuery,
  GetAssignmentsQuery,
  GQLAssignment,
  SpaceDashboardInput,
} from 'garaje/graphql/generated/assigned-and-unassigned-employees-types';
import { WeekDay } from 'garaje/graphql/generated/assigned-and-unassigned-employees-types';
import type {
  DepartmentName,
  DeskMapFeature,
  EmployeeItem,
  EmployeesOverview,
  EmployeesOverviewSidePanel,
  FeaturesOverview,
  GetMapFeaturesQuery,
  GetMapFeaturesQueryVariables,
  MapFeature,
  ResourceItem,
  RoomMapFeature,
  ScheduledAssignment,
} from 'garaje/graphql/generated/map-features-types';
import type {
  GetResourceUtilizationQuery,
  ResourceUtilization,
  ResourceUtilizationInput,
} from 'garaje/graphql/generated/resource-utilization-types';
import { FeaturesWithUtilization } from 'garaje/graphql/generated/resource-utilization-types';
import getAssignedAndUnassignedEmployeesQuery from 'garaje/graphql/queries/AssignedAndUnassignedEmployeesQuery';
import assignmentsQuery from 'garaje/graphql/queries/AssignmentsQuery';
import getMapFeaturesQuery from 'garaje/graphql/queries/MapFeaturesQuery';
import resourceInsightsQuery from 'garaje/graphql/queries/ResourceInsightsQuery';
import type AreaMapModel from 'garaje/models/area-map';
import type MapFloorModel from 'garaje/models/map-floor';
import type ApolloService from 'garaje/services/apollo-extension';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { formatTimestampAbbreviated } from 'garaje/utils/format-timestamp';
import { buildEmployeeItem } from 'garaje/utils/resource-overview';
import compact from 'lodash/compact';

export default class ResourceOverviewService extends Service {
  @service declare featureFlags: FeatureFlagsService;
  @service declare apolloExtension: ApolloService;
  @service declare store: StoreService;
  @service declare state: StateService;
  @service declare abilities: AbilitiesService;

  @tracked gqlMapFeaturesApiResponse: Array<DeskMapFeature | RoomMapFeature | MapFeature> = [];
  @tracked gqlEmployeesApiResponse: EmployeesOverview | null = null;
  @tracked gqlAssignmentsApiResponse: GQLAssignment[] | null = null;

  async fetchExistingAssignmentsForEmployee(employeeId: string): Promise<ScheduledAssignment[]> {
    const employee = this.store.peekRecord('employee', employeeId);
    const existingScheduledAssignments = (await employee?.assignments)?.toArray() ?? [];
    if (existingScheduledAssignments.length === 0) {
      return [];
    } else {
      return existingScheduledAssignments?.map((it) => {
        return {
          // create a local id so the user is able to select unsaved assignments in the side panel
          id: it.id ?? `temp_${Math.random().toString(36).substr(2, 9)}`,
          name: it.employee.name,
          start: it.startTime,
          employeeId: it.employee.id,
          deskId: it.belongsTo('desk').id(),
          display: it.employee.name,
          isLastExpanded: true,
          meta: [formatTimestampAbbreviated(it.startTime)],
        };
      });
    }
  }

  async loadAssignmentDesks(deskIds: string[]): Promise<void> {
    const deskIdsNotInStore = compact(deskIds).filter((id) => !this.store.peekRecord('desk', id));
    if (deskIdsNotInStore.length > 0) {
      await this.store.query('desk', {
        filter: {
          id: deskIdsNotInStore.join(','),
        },
      });
    }
  }

  createGQLDesk(id: string, name: string, floorName: string): Desk {
    return {
      id: id,
      name: name,
      mapFeature: {
        // @ts-ignore
        floor: {
          name: floorName,
          __typename: 'MapFloor',
        },
        __typename: 'DeskMapFeature',
      },
      __typename: 'Desk',
    };
  }

  assignOrCreateDepartment(employee: EmployeeItem, section: Record<string, EmployeeItem[]>): void {
    if (employee.department in section) {
      // (ar) the ! is needed here because for some reason the type system cannot tell that we confirmed that the key exists
      section[employee.department]!.push(employee);
    } else {
      section[employee.department] = [employee];
    }
  }

  applyDepartmentGroupings(
    employees: Array<EmployeeItem>
  ): Record<DepartmentName, Array<EmployeeItem>> | Array<EmployeeItem> {
    const employeesHaveDepartments = employees.some((employee) => employee.department !== null);
    let employeeGroupings: Record<DepartmentName, Array<EmployeeItem>> | Array<EmployeeItem>;
    if (!employeesHaveDepartments) {
      employeeGroupings = [];
      employees.forEach((employee) => {
        (employeeGroupings as Array<EmployeeItem>).push(employee);
      });
    } else {
      employeeGroupings = {};
      employees.forEach((employee) => {
        if (!employee.department) {
          employee.department = 'No department';
        }
        this.assignOrCreateDepartment(employee, employeeGroupings as Record<string, EmployeeItem[]>);
      });
    }
    return employeeGroupings;
  }

  get employeesData(): EmployeesOverviewSidePanel {
    if (!this.gqlEmployeesApiResponse) {
      return {
        unassigned: [],
        assigned: [],
      };
    } else {
      // (ar) this.gqlEmployeesApiResponse stores the raw response from the getAssignedAndUnassignedEmployees query
      const unassignedEmployees = this.gqlEmployeesApiResponse.unassigned;
      const assignedEmployees = this.gqlEmployeesApiResponse.assigned;
      const allEmployees = [...unassignedEmployees, ...assignedEmployees].filter((it) => it !== null);
      const employeeAssignments =
        this.gqlAssignmentsApiResponse
          ?.map((gqlAssignment) => {
            const employeeName = allEmployees.find((employee) => {
              return employee.id === gqlAssignment.employee?.id;
            })?.name;
            if (!employeeName) {
              return null;
            }
            return {
              id: gqlAssignment.id,
              name: employeeName,
              start: gqlAssignment.startTime,
              employeeId: gqlAssignment.employee.id,
              deskId: gqlAssignment.deskId,
              display: employeeName,
              isLastExpanded: true,
            } as ScheduledAssignment;
          })
          .filter((it) => it !== null) ?? [];

      const employeeAssignmentMap = employeeAssignments.reduce((map, item) => {
        if (!map.has(item.employeeId)) {
          // eslint-disable-next-line ember/use-ember-get-and-set
          map.set(item.employeeId, []);
        }
        // eslint-disable-next-line ember/use-ember-get-and-set
        map.get(item.employeeId)!.push(item);
        return map;
      }, new Map<string, ScheduledAssignment[]>());

      return {
        unassigned: this.applyDepartmentGroupings(
          unassignedEmployees.map((it) => {
            // eslint-disable-next-line ember/use-ember-get-and-set
            return buildEmployeeItem(it, employeeAssignmentMap.get(it.id) ?? null);
          })
        ),
        assigned: this.applyDepartmentGroupings(
          assignedEmployees.map((it) => {
            // eslint-disable-next-line ember/use-ember-get-and-set
            return buildEmployeeItem(it, employeeAssignmentMap.get(it.id) ?? null);
          })
        ),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        meta: this.gqlEmployeesApiResponse.meta,
      };
    }
  }

  get featuresData(): FeaturesOverview {
    const overview: FeaturesOverview = {
      desks: {
        'assigned-desks': [],
        'hotel-desks': [],
        'disabled-desks': [],
      },
      rooms: {
        'enabled-rooms': [],
        'disabled-rooms': [],
      },
      'points-of-interest': {},
    };

    this.gqlMapFeaturesApiResponse.forEach((feature) => {
      if (feature.__typename === 'DeskMapFeature') {
        const deskInfo: ResourceItem = {
          id: feature.desk?.id,
          featureId: feature.id,
          name: feature.name,
          display: feature.name,
          isLastExpanded: true,
        };
        if (feature.desk?.assignedEmployee) {
          overview.desks['assigned-desks'].push(deskInfo);
        } else if (feature.desk?.enabled) {
          overview.desks['hotel-desks'].push(deskInfo);
        } else if (feature.desk?.enabled === false) {
          overview.desks['disabled-desks'].push(deskInfo);
        }
      } else if (feature.__typename === 'RoomMapFeature') {
        const roomInfo = {
          id: feature.room?.id,
          featureId: feature.id,
          name: feature.name,
          display: feature.name,
          isLastExpanded: true,
        };
        if (feature.enabled) {
          overview.rooms['enabled-rooms'].push(roomInfo);
        } else {
          overview.rooms['disabled-rooms'].push(roomInfo);
        }
      } else {
        const poiInfo = {
          featureId: feature.id,
          name: feature.name,
          display: feature.name,
          isLastExpanded: true,
        };
        const existingFeatureType =
          overview['points-of-interest'][feature.type as keyof FeaturesOverview['points-of-interest']];
        if (existingFeatureType) {
          existingFeatureType.push(poiInfo);
        } else {
          overview['points-of-interest'][feature.type as keyof FeaturesOverview['points-of-interest']] = [poiInfo];
        }
      }
    });

    const orderedPois = Object.keys(overview['points-of-interest'])
      .sort()
      .reduce((obj, key) => {
        obj[key as keyof FeaturesOverview['points-of-interest']] =
          overview['points-of-interest'][key as keyof FeaturesOverview['points-of-interest']];
        return obj;
      }, {} as FeaturesOverview['points-of-interest']);

    overview['points-of-interest'] = orderedPois;
    return overview;
  }

  getResourceInsightsTask = task(
    { drop: true },
    async (areaMapId: string, floorId: string): Promise<ResourceUtilization | null> => {
      if (this.state.currentCompany) {
        try {
          // create the query inputs
          const startDate = new Date();
          startDate.setDate(startDate.getDate() - 90);
          const weekDaysInFilter: WeekDay[] = [WeekDay.MON, WeekDay.TUE, WeekDay.WED, WeekDay.THU, WeekDay.FRI];
          const spaceDashboardInput: SpaceDashboardInput = {
            filter: {
              companyIds: [this.state.currentCompany.id],
              start: startDate.toISOString(),
              end: new Date().toISOString(),
            },
            setting: {
              weekdays: weekDaysInFilter,
            },
          };
          const resourceUtilizationInput = {
            areaMapID: areaMapId,
            floorID: floorId,
            featureTypes: ['DESK', 'ROOM'],
            spaceDashboardInput,
          } as ResourceUtilizationInput;

          // execute the query
          const response = await this.apolloExtension.query<
            GetResourceUtilizationQuery,
            ResourceUtilizationInput,
            'areaMap'
          >(
            {
              query: resourceInsightsQuery,
              fetchPolicy: 'network-only',
              variables: resourceUtilizationInput,
            },
            'areaMap'
          );

          const utilization: ResourceUtilization = [];
          // iterate over the response and extract the data we need into the format we want
          response.floors.forEach((floor) => {
            floor.features.forEach((feature) => {
              if (feature.__typename === 'DeskMapFeature') {
                utilization.push({
                  type: FeaturesWithUtilization.DESK,
                  id: feature.desk.id,
                  utilization: feature.desk.utilization?.percentUtilized ?? 0,
                });
              } else if (feature.__typename === 'RoomMapFeature') {
                utilization.push({
                  type: FeaturesWithUtilization.ROOM,
                  id: feature.room.id,
                  utilization: feature.room.utilization?.percentUtilized ?? 0,
                });
              }
            });
          });
          return utilization;
        } catch (e) {
          // eslint-disable-next-line
          console.error('Error calling graphql getResourceInsights query. Error: ', e);
          throw e;
        }
      }
      return null;
    }
  );

  getGQLAssignmentsTask = task({ drop: true }, async (): Promise<GQLAssignment[]> => {
    const assignmentInput: AssignmentFilterInput = {
      locationId: this.state.currentLocation.id,
    };
    return (await this.apolloExtension.query<
      GetAssignmentsQuery,
      { assignmentsInput: AssignmentFilterInput },
      'assignments'
    >(
      {
        query: assignmentsQuery,
        fetchPolicy: 'network-only',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        variables: { assignmentsInput: assignmentInput },
      },
      'assignments'
    )) as unknown as Array<GQLAssignment>;
  });

  // disabling this linting rule is needed to allow for the nullability of EmployeeOverview
  getGQLEmployeesTask = task({ drop: true }, async (draftId: string | undefined): Promise<EmployeesOverview> => {
    if (this.state.currentLocation && this.state.currentCompany) {
      try {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 90);
        // (ar) in the future, we will likely want to allow the user to select which days of the week to show,
        // and when we do those inputs will be used here
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const weekdaysInFilter: WeekDay[] = [
          WeekDay.SUN,
          WeekDay.MON,
          WeekDay.TUE,
          WeekDay.WED,
          WeekDay.THU,
          WeekDay.FRI,
          WeekDay.SAT,
        ];
        const employeeInput: GetAssignedAndUnassignedEmployeesInput = {
          locationId: this.state.currentLocation.id,
          ...(draftId !== undefined && { draftId }),
        };
        const spaceInput: SpaceDashboardInput = {
          filter: {
            companyIds: [this.state.currentCompany.id],
            locationIds: [this.state.currentLocation.id],
            start: startDate.toISOString(),
            end: new Date().toISOString(),
          },
          setting: {
            weekdays: weekdaysInFilter,
          },
        };
        const result = (await this.apolloExtension.query<
          GetAssignedAndUnassignedEmployeesQuery,
          { employeeInput: GetAssignedAndUnassignedEmployeesInput; spaceInput: SpaceDashboardInput },
          'getAssignedAndUnassignedEmployees'
        >(
          {
            query: getAssignedAndUnassignedEmployeesQuery,
            fetchPolicy: 'network-only',
            variables: {
              employeeInput,
              spaceInput,
            },
          },
          'getAssignedAndUnassignedEmployees'
          // need a typecast here, since the type system thinks assignedAndUnassignedEmployees is a function and not an object
        )) as unknown as AssignedAndUnassignedEmployees;

        if (result) {
          return {
            unassigned: result.unassignedEmployees.map((employee) => {
              return { ...employee, isLastExpanded: true };
            }),
            assigned: result.assignedEmployees.map((employee) => {
              return { ...employee, isLastExpanded: true };
            }),
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            meta: result.meta,
          };
        }
      } catch (e) {
        // eslint-disable-next-line
        console.error('Error calling graphql getAssignedAndUnassigned query. Error: ', e);
        throw e;
      }
    }
    return {
      unassigned: [],
      assigned: [],
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      meta: { employeesPopulatedByScim: false },
    };
  });

  getGQLMapFeaturesTask = task({ drop: true }, async (areaMap: AreaMapModel, floor: MapFloorModel) => {
    const ids = areaMap.hasMany('mapFloors').ids();
    if (ids[0]) {
      try {
        const fetchedMapFeatures = await this.apolloExtension.query<
          GetMapFeaturesQuery,
          GetMapFeaturesQueryVariables,
          'areaMap'
        >(
          {
            query: getMapFeaturesQuery,
            fetchPolicy: 'network-only',
            variables: {
              areaMapID: areaMap.id,
              floorID: floor.id,
            },
          },
          'areaMap'
        );

        if (fetchedMapFeatures?.floors?.length && fetchedMapFeatures?.floors[0]) {
          return fetchedMapFeatures.floors[0].features;
        }
      } catch (e) {
        // eslint-disable-next-line
        console.error('Error calling graphql getMapFeatures query. Error: ', e);
        throw e;
      }
    }
    return [];
  });

  getAllGQLDataTask = task(
    { drop: true },
    async (areaMap: AreaMapModel, floor: MapFloorModel, draftId: string | undefined) => {
      const mapFeatures = this.getGQLMapFeaturesTask.perform(areaMap, floor);
      const employees = this.getGQLEmployeesTask.perform(draftId);
      const assignments = this.getGQLAssignmentsTask.perform();

      let featureResponse: Array<MapFeature>;
      let employeeResponse: EmployeesOverview;
      let assignmentsResponse: GQLAssignment[];
      // eslint-disable-next-line prefer-const
      [featureResponse, employeeResponse, assignmentsResponse] = await all([mapFeatures, employees, assignments]);
      this.gqlMapFeaturesApiResponse = featureResponse ?? [];
      this.gqlEmployeesApiResponse = employeeResponse ?? null;
      this.gqlAssignmentsApiResponse = assignmentsResponse ?? null;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return [this.featuresData, this.employeesData, this.gqlAssignmentsApiResponse];
    }
  );
}
