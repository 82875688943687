import { htmlSafe } from '@ember/template';
import { type SafeString } from '@ember/template/-private/handlebars';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AbstractGdprConfigurationModel from 'garaje/models/abstract/abstract-gdpr-configuration';

interface IpadPreviewArgs {
  color: string;
  config: AbstractGdprConfigurationModel;
  selectedPreview: string;
  policyDisclosurePreview: string;
  hasMultipleVisitorTypes: boolean;
}

export default class IpadPreview extends Component<IpadPreviewArgs> {
  @tracked policyDisclosurePreview;

  constructor(owner: unknown, args: IpadPreviewArgs) {
    super(owner, args);
    this.policyDisclosurePreview = this.args.policyDisclosurePreview ?? '';
  }

  get shouldShowSignInPreviewText(): boolean {
    return this.args.config.optOutVisitorProtocolEnabled || this.args.config.policyDisclosureEnabled;
  }

  get previewText(): SafeString | string {
    const { optOutVisitorProtocolEnabled: consentEnabled, policyDisclosureEnabled: disclosureEnabled } =
      this.args.config;

    if (consentEnabled && disclosureEnabled) {
      return htmlSafe(
        'By signing in, you consent to our <span class="underline">data policy</span>. <span class="underline">I don\'t consent &rarr;</span>'
      );
    }

    if (consentEnabled && !disclosureEnabled) {
      return htmlSafe(
        'By signing in, you consent to data collection. <span class="underline">I don\'t consent &rarr;</span>'
      );
    }

    if (!consentEnabled && disclosureEnabled) {
      return htmlSafe(
        'By signing in, you confirm that you understand <span class="underline">how we process</span> your personal data.'
      );
    }
    return '';
  }
}
