/* eslint-disable ember/no-computed-properties-in-native-classes */
import { readOnly } from '@ember/object/computed';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, LOCATION_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_VISIT_ROLES = Object.freeze([GLOBAL_ADMIN, LOCATION_ADMIN]);

// TODO: Remove this file once ./communication.ts is used everywhere.
export default class EmergencyNotification extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;
  @service declare featureFlags: FeatureFlagsService;

  @readOnly('state.workplaceSubscription') workplaceSubscription!: StateService['workplaceSubscription'];
  @readOnly('state.visitorsSubscription') visitorsSubscription!: StateService['visitorsSubscription'];
  @readOnly('state.emnoSubscription') emnoSubscription!: StateService['emnoSubscription'];

  // TODO: Incorporate FC instead here
  get canVisit(): boolean {
    return this.canSeeFeatures;
  }

  get canSeeFeatures(): boolean {
    return canUseEmergencyNotifications({
      workplaceSubscription: this.workplaceSubscription,
      visitorsSubscription: this.visitorsSubscription,
      emnoSubscription: this.emnoSubscription,
      emNoRolloutFeatureFlag: this.featureFlags.isEnabled('emergency-notification-garaje'),
      emNoAllowlistFeatureFlag: this.featureFlags.isEnabled('emergency-notification-allowlist'),
    });
  }

  get canEditFeatures(): boolean {
    const { roleNames: roles } = this.currentAdmin;
    return this.canSeeFeatures && isPresent(_intersection(CAN_VISIT_ROLES, roles));
  }
}

/**
 * @param emNoRolloutFeatureFlag Usually: `this.featureFlags.isEnabled('emergency-notification-garaje')`
 * @param emNoAllowlistFeatureFlag Usually: `this.featureFlags.isEnabled('emergency-notifcation-allowlist')`
 */
export function canUseEmergencyNotifications({
  workplaceSubscription,
  visitorsSubscription,
  emnoSubscription,
  emNoRolloutFeatureFlag,
  emNoAllowlistFeatureFlag,
}: {
  workplaceSubscription: StateService['workplaceSubscription'];
  visitorsSubscription: StateService['visitorsSubscription'];
  emnoSubscription: StateService['emnoSubscription'];
  emNoRolloutFeatureFlag: boolean;
  emNoAllowlistFeatureFlag: boolean;
}): boolean {
  return (
    ((workplaceSubscription?.canAccessEmergencyNotifications ||
      visitorsSubscription?.canAccessEmergencyNotifications ||
      emnoSubscription?.canAccessEmergencyNotifications) &&
      emNoRolloutFeatureFlag) ||
    emNoAllowlistFeatureFlag
  );
}
