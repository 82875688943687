import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { get, set, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { isEmpty } from '@ember/utils';
import { SLACK_V2_PLUGIN_KEY } from 'garaje/utils/enums';

/**
 * @param {ApprovalStatus}            title
 * @param {Model<Location>}           location
 * @param {Object}                    pluginPreferences
 */
export default class IntegrationsToggle extends Component {
  @service flashMessages;
  @service featureFlags;
  @service state;

  @tracked title;

  slackV2PluginKey = SLACK_V2_PLUGIN_KEY;

  constructor() {
    super(...arguments);
    this.title = this.args.title ?? 'Integrations';
  }

  get canAccessCall() {
    return get(this.args.location, 'company.callEnabled');
  }

  get canAccessSlack() {
    return this.state.vrSubscription.canAccessSlack;
  }

  get hasOldSlack() {
    return !isEmpty(get(this.args.location, 'company.slackIntegration.id'));
  }

  get newSlack() {
    return this.args.pluginPreferences?.filterBy('plugin.key', this.slackV2PluginKey);
  }

  get hasNewSlack() {
    return !isEmpty(this.newSlack);
  }

  get hasSlackEnabled() {
    return this.hasOldSlack || this.hasNewSlack;
  }

  @dropTask
  *saveTask(property, value, model = null) {
    model = model || this.args.location;
    set(model, property, value);

    try {
      yield model.save();

      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      model.rollbackAttributes();
    }
  }

  @action
  changeValue(model, property, value) {
    this.saveTask.perform(property, value, model);

    return true;
  }
}
