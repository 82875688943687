import { action, set } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';
import { dropTask } from 'ember-concurrency';
import { defer } from 'rsvp';
import { cached, tracked } from '@glimmer/tracking';
import { formatTimestamp, formatTimestampAbbreviated } from 'garaje/utils/format-timestamp';

class EmployeeForm extends Component {
  @service store;
  @service state;

  @alias('args.selectedEmployee.assignedDesks') assignedDesks;
  @tracked searchedDesks = [];
  @tracked selectedDeskForAssignment = null;
  @tracked selectedAssignment = null;
  @tracked deskError = [];
  @tracked calendarDate = null;
  @tracked showDeleteButton = false;

  @action
  onDeskAssignmentSelect(option) {
    this.selectedDeskForAssignment = option;
  }

  @action
  removeErrors() {
    this.deskError = [];
  }

  @cached
  get desksOnFloor() {
    const { desks } = this.args;
    const deskIds = desks.map((it) => it.id);
    // return all matching desks from the store
    return this.store.peekAll('desk').filter((desk) => {
      return deskIds.includes(desk.id);
    });
  }

  @action
  searchDesks(term) {
    this.searchedDesks = this.desksOnFloor.filter((desk) => {
      return desk.name.toLowerCase().includes(term.toLowerCase());
    });
  }

  get desksToShow() {
    if (this.searchedDesks.length) {
      return this.searchedDesks;
    }
    return this.desksOnFloor;
  }

  @action
  showModal(assignment) {
    this.showDeleteButton = true;
    this.selectedAssignment = assignment;
    this.selectedDeskForAssignment = assignment.desk;
    this.processEmployeeAssignmentTask.perform(false);
  }

  @action
  setDate(date) {
    this.calendarDate = date;
  }

  @action
  async removeSelectedAssignmentFromDesk(desk, assignment) {
    // desk.assignmentDetails represent assignments that have been created by the user but haven't been saved
    if (desk.assignmentDetails === null) {
      // setting this value to an empty list will remove all pending assignments on this desk
      set(desk, 'assignmentDetails', []);
    } else {
      desk.assignmentDetails = desk.assignmentDetails.filter((it) => {
        return (
          parseInt(desk.id) !== parseInt(assignment.desk?.id) ||
          parseInt(it['employee-id'] ?? '') !== parseInt(assignment?.employee?.id)
        );
      });
    }
    const assignments = (await desk.assignments) || [];
    const filteredAssignments = assignments.filter((it) => {
      return parseInt(it.employee?.id) !== parseInt(assignment?.employee?.id);
    });
    set(desk, 'assignments', filteredAssignments);
  }

  updateDeskWithAssignment(desk, assignment) {
    // add assigment to the new desk
    const existingAssignmentsOnDesk = this.store.peekRecord('desk', desk.id).assignmentDetails ?? [];
    const newAssignment = {
      'employee-id': assignment.employeeId,
      'start-time': assignment.start,
      'scheduled-by': assignment.scheduledBy,
      'should-send-notification': assignment.shouldSendNotification,
    };
    existingAssignmentsOnDesk.push(newAssignment);
    desk.assignmentDetails = existingAssignmentsOnDesk;
  }

  @action
  async updateDeskScheduledAssignment(selectedDeskForAssignment, assignment, shouldCreateNew) {
    const { selectedEmployee, updateSelectedEmployee, updateAssignmentsPanel } = this.args;
    this.updateDeskWithAssignment(selectedDeskForAssignment, assignment);

    let newAssignments;
    if (!shouldCreateNew) {
      // remove assignment from the old desk
      await this.removeSelectedAssignmentFromDesk(this.selectedAssignment.desk, this.selectedAssignment);
      // update the GQL employee with the new assignment and remove that employee's old assignment
      newAssignments = selectedEmployee.assignments.filter((it) => it.id !== this.selectedAssignment.id);
    } else {
      newAssignments = selectedEmployee.assignments;
    }
    const newGqlAssignment = {
      id: assignment.id ?? `temp_${Math.random().toString(36).substr(2, 9)}`,
      name: selectedEmployee.name,
      start: assignment.start,
      employeeId: selectedEmployee.id,
      deskId: selectedDeskForAssignment.id,
      display: selectedEmployee.name,
      isLastExpanded: true,
      meta: [formatTimestampAbbreviated(assignment.start)],
    };
    newAssignments.push(newGqlAssignment);
    updateAssignmentsPanel(selectedEmployee.id, newAssignments);
    updateSelectedEmployee(selectedEmployee, 'assignments', newAssignments);
  }

  @action
  resetModal() {
    this.showDeleteButton = false;
    this.selectedAssignment = null;
    this.selectedDeskForAssignment = null;
    this.deskError = [];
    this.calendarDate = null;
  }

  @action
  triggerAssignmentDelete() {
    this.processEmployeeAssignmentTask.last.delete();
  }

  @dropTask
  processEmployeeAssignmentTask = {
    *perform(shouldCreateNew) {
      const deferred = defer();
      this.abort = () => {
        this.context.resetModal();
        deferred.resolve(false);
      };
      this.delete = () => {
        const assignmentId = this.context.selectedAssignment.id;
        this.context.args.deleteAssignment(
          this.context.selectedAssignment.desk.id,
          this.context.args.selectedEmployee?.id
        );
        this.context.args.updateSelectedEmployee(
          this.context.args.selectedEmployee,
          'assignments',
          this.context.args.selectedEmployee.assignments.filter((it) => {
            return it.id !== assignmentId;
          })
        );
        this.context.resetModal();
        deferred.resolve(true);
      };
      this.continue = async () => {
        const currentEmployee = this.context.store
          .peekAll('employee')
          .toArray()
          .find((employee) => employee.belongsTo('user').id() === this.context.state.currentUser.id);
        const assignment = {
          employeeId: this.context.args.selectedEmployee?.id ?? null,
          deskId: this.context.selectedDeskForAssignment.id,
          start: this.context.calendarDate,
          scheduledBy: currentEmployee?.id ?? null,
          shouldSendNotification: true,
        };

        const employee = this.context.store.peekRecord('employee', this.context.args.selectedEmployee.id);
        if (employee) {
          this.context.store.createRecord('assignment', {
            startTime: this.context.calendarDate.toISOString(),
            shouldSendNotification: true,
            scheduledBy: currentEmployee?.id,
            desk: this.context.selectedDeskForAssignment,
            employee: employee,
          });
        }
        await this.context.updateDeskScheduledAssignment(
          this.context.selectedDeskForAssignment,
          assignment,
          shouldCreateNew
        );
        this.context.resetModal();
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };

  get selectedDesks() {
    return this.assignedDesks || [];
  }

  get assignments() {
    const { selectedEmployee } = this.args;
    return (
      selectedEmployee?.assignments?.map((it) => {
        let employee = null;
        if (it.employeeId) {
          employee = this.store.peekRecord('employee', it.employeeId);
        }
        return {
          // TODO: What if the desk is not in the store? Could be scheduled to a desk in another floor
          id: it.id,
          desk: this.store.peekRecord('desk', it.deskId),
          employee: employee,
          start: formatTimestamp(it.start),
          startDate: it.start,
          name: it.name,
        };
      }) || []
    );
  }

  @action
  isValid(userInput) {
    return this.store.peekAll('desk').some((desk) => {
      return desk.name.toLowerCase().includes(userInput.toLowerCase());
    });
  }

  @action
  validateDesk() {
    if (!this.selectedDeskForAssignment) {
      this.deskError.push('Desk is required');
      return;
    }
    const desk = this.store.peekRecord('desk', this.selectedDeskForAssignment.id);
    if (!desk) {
      this.deskError.push('Desk not found');
    }
  }

  get unassignedDesks() {
    const { desks } = this.args;
    return desks.filter((desk) => {
      const deskRecord = this.store.peekRecord('desk', desk.id);
      return deskRecord.assignedTo === null;
    });
  }

  get desksToDisplay() {
    return [...this.selectedDesks, ...this.unassignedDesks];
  }

  get employeeHasInsight() {
    const { selectedEmployee } = this.args;

    if (!selectedEmployee?.attendancePerformance) {
      return false;
    }

    if (!selectedEmployee.attendancePerformance?.onsiteMission?.mission) {
      const DEFAULT_ATTENDANCE = 3;
      return selectedEmployee.attendancePerformance.averageWeeklyAttendance >= DEFAULT_ATTENDANCE;
    }

    if (this.assignedDesks.length) {
      return (
        selectedEmployee.attendancePerformance.averageWeeklyAttendance <
        selectedEmployee.attendancePerformance.onsiteMission.mission
      );
    }

    if (!this.assignedDesks.length) {
      return (
        selectedEmployee.attendancePerformance.averageWeeklyAttendance >=
        selectedEmployee.attendancePerformance.onsiteMission.mission
      );
    }

    return false;
  }

  get employeeInsightText() {
    const { selectedEmployee } = this.args;
    if (!selectedEmployee.attendancePerformance) {
      return 'This employee comes in more than our recommended 3 day onsite attendance. Do you want to assign them a desk?';
    }

    const onsiteMission = selectedEmployee.attendancePerformance.onsiteMission.mission;
    if (this.assignedDesks.length) {
      return `This employee comes in less than the required ${onsiteMission} day onsite attendance.`;
    } else {
      return `This employee comes more than the required ${onsiteMission} day onsite attendance. Do you want to assign them a desk?`;
    }
  }

  @action
  async onDeskSelected(selectedDesks) {
    const { updateEmployeeOverview, updateResourceOverview, selectedEmployee } = this.args;
    const unassignedDesks = selectedEmployee.assignedDesks.filter(
      (assignedDesk) => !selectedDesks.any((desk) => assignedDesk.id === desk.id)
    );

    unassignedDesks.forEach((unassignedDesk) => {
      const deskRecord = this.store.peekRecord('desk', unassignedDesk.id);
      if (deskRecord) {
        set(deskRecord, 'assignedTo', null);
        // find map feature associated with the desk
        const feature = this.store
          .peekAll('map-feature')
          .find((mapFeature) => mapFeature.externalId === unassignedDesk.id);
        updateResourceOverview('update', feature);
      }
    });

    selectedDesks.forEach((desk) => {
      const deskRecord = this.store.peekRecord('desk', desk.id);
      if (deskRecord) {
        set(deskRecord, 'assignedTo', selectedEmployee.email);
        // find map feature associated with the desk
        const feature = this.store.peekAll('map-feature').find((mapFeature) => mapFeature.externalId === desk.id);
        updateResourceOverview('update', feature);
      }
    });
    // set selected desks on gql employee
    set(selectedEmployee, 'assignedDesks', selectedDesks);

    if (selectedDesks.length) {
      updateEmployeeOverview(selectedEmployee.id, selectedDesks);
    } else {
      updateEmployeeOverview(selectedEmployee.id, selectedDesks);
    }
  }
}

export default EmployeeForm;
