/**
 * @deprecated Use immutable transform directly
 * @todo - Clean up remaining object transform useage and delete this
 *
 * This transform does not allow for attributes to be nullable. It remains only to preserve
 * compatability with endpoints which may expect null or undefined Model attribute values to
 * serialize and deserialize into empty objects.
 */
import ImmutableTransform from 'garaje/transforms/immutable';
import $ from 'jquery';

export default ImmutableTransform.extend({
  deserialize(value) {
    return this._super($.isPlainObject(value) ? value : {});
  },
  serialize(value) {
    return this._super($.isPlainObject(value) ? value : {});
  },
});
