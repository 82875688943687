import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { get } from '@ember/object';
import { service } from '@ember/service';
import { restartableTask, task } from 'ember-concurrency';
import { RoleId, RoleScopeType } from 'garaje/utils/custom-roles';
import { COMPANY_ROLES, LOCATION_ROLES, ZONE_SECURITY, ZONE_ADMIN } from 'garaje/utils/roles';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { adminRoute } from 'garaje/utils/admin-routing';
import { APP } from 'garaje/utils/enums';

export default class AdminRolesBulkController extends Controller {
  @service flashMessages;
  @service ajax;
  @service skinnyLocations;
  @service currentAdmin;
  @service store;
  @service abilities;
  @service router;
  @service featureFlags;
  @service state;
  @service authz;
  @service('setupGuideStepper') setupGuideStepperService;

  queryParams = ['user_id'];

  @tracked roleAssignments = [];
  @tracked user_id = null;
  @tracked selectedRole = {};
  @tracked selectedLocations = [];
  @tracked selectedZones = [];
  @tracked selectedEmployee = null;
  @tracked employeeUser = null;
  @tracked userRoles = [];

  get availableRoles() {
    const existingCompanyRoles = this.employeeUser
      ? this.employeeUser.companyRoles.filter(Boolean).mapBy('roleName')
      : [];
    const locationRoles = LOCATION_ROLES;
    const companyRolesDisabled = !this.currentAdmin.isGlobalAdmin || existingCompanyRoles.length > 0;
    const propertyRolesDisabled = !this.currentAdmin.isGlobalAdmin;

    const availableRoles = [
      {
        groupName: 'Company roles',
        options: COMPANY_ROLES.map((roleName) => ({
          roleName,
          disabled: companyRolesDisabled,
          alreadyAssigned: existingCompanyRoles.includes(roleName),
        })),
      },
      {
        groupName: 'Location roles',
        options: locationRoles.map((roleName) => ({ roleName })),
      },
    ];

    if (this.abilities.can('use property admin-role')) {
      const zoneRoles = [ZONE_SECURITY, ZONE_ADMIN];

      availableRoles.push({
        groupName: 'Property roles',
        options: zoneRoles.map((roleName) => ({ roleName, disabled: propertyRolesDisabled })),
      });
    }

    return availableRoles;
  }

  get roleOptions() {
    // determine if user has any already assigned company roles
    const existingCompanyRoles = [];
    this.roleAssignments?.forEach((roleAssignment) => {
      if (
        roleAssignment.roleScopeType === RoleScopeType.COMPANY &&
        roleAssignment.roleScopeId.toString() === this.state.currentCompany?.id
      ) {
        existingCompanyRoles.push(roleAssignment.roleId);
      }
    });

    const authzRoles = this.authz.roles;

    // break roles by roleScopeType
    const companyRoles = [];
    const locationRoles = [];
    const zoneRoles = [];
    authzRoles.forEach((authzRole) => {
      if (!authzRole.deactivated) {
        switch (authzRole.roleScope) {
          case RoleScopeType.COMPANY:
            if (authzRole.id === RoleId.ANALYTICS_VIEWER) {
              // only show role for workplace premium accounts
              const { workplaceSubscription } = this.state;
              if (workplaceSubscription && !workplaceSubscription.isBasicPlan) {
                companyRoles.push(authzRole);
              }
            } else if (authzRole.id !== RoleId.COMPANY_START_FROM_SCRATCH) {
              companyRoles.push(authzRole);
            }
            break;
          case RoleScopeType.LOCATION:
            if (authzRole.id !== RoleId.EMPLOYEE && authzRole.id !== RoleId.LOCATION_START_FROM_SCRATCH) {
              locationRoles.push(authzRole);
            }
            break;
          case RoleScopeType.ZONE:
            zoneRoles.push(authzRole);
            break;
        }
      }
    });

    const companyRolesDisabled = !this.currentAdmin.isGlobalAdmin || existingCompanyRoles.length > 0;
    const propertyRolesDisabled = !this.currentAdmin.isGlobalAdmin;

    const availableRoles = [
      {
        groupName: 'Company roles',
        options: companyRoles.map((role) => ({
          role,
          disabled: companyRolesDisabled,
          alreadyAssigned: existingCompanyRoles.includes(role.id),
        })),
      },
      {
        groupName: 'Location roles',
        options: locationRoles.map((role) => ({ role })),
      },
    ];

    if (this.abilities.can('use property admin-role')) {
      availableRoles.push({
        groupName: 'Property roles',
        options: zoneRoles.map((role) => ({ role, disabled: propertyRolesDisabled })),
      });
    }

    return availableRoles;
  }

  get showLocationsList() {
    return this.selectedRole.role?.roleScope === RoleScopeType.LOCATION;
  }

  get showPropertiesList() {
    return this.selectedRole.role?.roleScope === RoleScopeType.ZONE;
  }

  get readyToInvite() {
    return this.selectedEmployee && this.selectedRole?.role;
  }

  @(employeesSearcherTask({
    filter: { deleted: false },
  }).restartable())
  searchEmployeesTask;

  @restartableTask
  *searchEmployeesWithOptionsTask(term) {
    const results = yield this.searchEmployeesTask.perform(
      term,
      {},
      { withoutLocation: this.router.currentRouteName?.includes('location-overview') }
    );
    return results;
  }

  fetchRolesTask = task({ drop: true }, async () => {
    if (!this.employeeUser) {
      return;
    }

    try {
      const roleAssignments = await this.store.query('role-assignment', {
        filter: { 'user-id': get(this.employeeUser, 'id') },
      });
      this.roleAssignments = [...roleAssignments.toArray()];
    } catch (e) {
      // Prevent the roles from being sticky on error, e.g. viewing a different user
      this.roleAssignments = [];
    }
  });

  saveRoleTask = task({ drop: true }, async () => {
    let user = await get(this.selectedEmployee, 'user');
    if (!user) {
      await this.selectedEmployee.createUser();
      user = await get(this.selectedEmployee, 'user');
    }

    try {
      const { role } = this.selectedRole;
      switch (role.roleScope) {
        case RoleScopeType.COMPANY:
          await this.store
            .createRecord('role-assignment', {
              userId: get(user, 'id'),
              roleId: role.id,
              roleScopeId: this.state.currentCompany.id,
              roleScopeType: RoleScopeType.COMPANY,
            })
            .save();
          break;
        case RoleScopeType.LOCATION:
          await user.bulkCreateRoles(
            get(user, 'id'),
            role.id,
            this.selectedLocations.map((location) => location.id),
            RoleScopeType.LOCATION
          );
          break;
        case RoleScopeType.ZONE:
          await user.bulkCreateRoles(
            get(user, 'id'),
            role.id,
            this.selectedZones.map((zone) => zone.id),
            RoleScopeType.ZONE
          );
          break;
      }

      const path = adminRoute(this.router.currentRouteName);

      if (this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper')) {
        this.setupGuideStepperService.loadSetupStepsTask.perform(APP.VISITORS);
      }

      this.flashMessages.showAndHideFlash('success', 'Saved!');
      if (user) {
        this.transitionToRoute(`${path}.edit`, get(user, 'id'));
      } else {
        this.transitionToRoute(path);
      }
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  reset() {
    this.roleAssignments = [];
    this.selectedEmployee = null;
    this.employeeUser = null;
    this.selectedRole = {};
    this.selectedLocations = [];
    this.selectedZones = [];
    this.userRoles = [];
    this.user_id = null;
  }

  @action
  closeModal() {
    const path = adminRoute(this.router.currentRouteName);
    return this.transitionToRoute(path);
  }

  @action
  async selectEmployee(employee) {
    let user = await get(employee, 'user');

    let roleAssignments = [];
    if (!user) {
      user = await this.store.peekAll('user').findBy('email', employee.email);
    }
    if (user) {
      roleAssignments = await this.store.query('role-assignment', { filter: { 'user-id': get(user, 'id') } });
    }

    const hasRoles = roleAssignments?.length > 0;

    if (hasRoles) {
      const path = adminRoute(this.router.currentRouteName);
      this.transitionToRoute(`${path}.edit`, user.id);
    } else {
      this.employeeUser = user;
      this.selectedEmployee = employee;
      this.fetchRolesTask.perform();
    }
  }

  /**
   * Each time a role is selected we need to reset the `selectedLocations` and add
   * the locations where the user is already an admin
   *
   * @param {string} selectedRole the selected role
   */
  @action
  selectRole(selectedRole) {
    const role = selectedRole.role;
    const isZoneRole = role.roleScope === RoleScopeType.ZONE;
    const items = isZoneRole ? this.model.zones : this.skinnyLocations.manageableByCurrentAdmin;
    const selectedKey = isZoneRole ? 'selectedZones' : 'selectedLocations';

    this.selectedRole = selectedRole;
    if (items?.length === 1) {
      this[selectedKey] = [items.firstObject];
    } else {
      const alreadySelectedItems = [];
      this.roleAssignments.forEach((roleAssignment) => {
        if (roleAssignment.roleId === role.id) {
          items.forEach((item) => {
            if (item.id === roleAssignment.roleScopeId.toString()) {
              alreadySelectedItems.push(item);
            }
          });
        }
      });

      this[selectedKey] = alreadySelectedItems;
    }
  }

  /**
   * Handles the selection or removal of a location
   *
   * @param {Location} location the location
   */
  @action
  onLocationToggled(location) {
    if (this.selectedLocations.findIndex((selected) => selected.id === location.id) < 0) {
      this.selectedLocations = [location, ...this.selectedLocations];
    } else {
      const newSelectedLocations = this.selectedLocations.filter((selected) => selected.id !== location.id);
      this.selectedLocations = newSelectedLocations;
    }
  }

  /**
   * Handles the selection or removal of a zone
   *
   * @param {object} zone the zone
   */
  @action
  onZoneToggled(zone) {
    if (this.selectedZones.findIndex((selected) => selected.id === zone.id) < 0) {
      this.selectedZones = [zone, ...this.selectedZones];
    } else {
      const newSelectedZones = this.selectedZones.filter((selected) => selected.id !== zone.id);
      this.selectedZones = newSelectedZones;
    }
  }
}
