import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type {
  Company,
  GetRoomsLocationWithConfigsQuery,
  GetRoomsQuery,
  GetSpaceSaverConfigQuery,
} from 'garaje/graphql/generated/roomba-types';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import type RoombaMetricsService from 'garaje/services/roomba-metrics';

// import uniq from 'lodash/uniq';

interface RoombaSettingsRoomCheckInComponentArgs {
  company: Company;
  rooms: GetRoomsQuery['rooms'];
  excludedRooms: GetSpaceSaverConfigQuery['spaceSaverConfig'];
  location: GetRoomsLocationWithConfigsQuery['location'];
}

type CheckInWindowOption = { name: string; value: number };

function formatCheckInWindowOption(timeInSeconds: number): CheckInWindowOption {
  return timeInSeconds === 60
    ? { name: '1 min', value: 60 }
    : { name: `${Math.floor(timeInSeconds / 60)} mins`, value: timeInSeconds };
}
export default class RoombaSettingsRoomCheckInComponent extends Component<RoombaSettingsRoomCheckInComponentArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare roombaGraphql: RoombaGraphqlService;
  @service declare roombaMetrics: RoombaMetricsService;
  @service declare featureFlags: FeatureFlagsService;

  @tracked declare selectedCheckInWindow: CheckInWindowOption;
  @tracked isSettingsAndExceptionsDialogOpen = false;
  @tracked isDialogOpen = false;

  constructor(owner: RoombaSettingsRoomCheckInComponent, args: RoombaSettingsRoomCheckInComponentArgs) {
    super(owner, args);
    this.selectedCheckInWindow = formatCheckInWindowOption(this.args.location.roomsPreCheckInWindow || 300);
  }

  @action
  openDialog(): void {
    this.isDialogOpen = true;
  }

  @action
  closeDialog(): void {
    this.isDialogOpen = false;
  }

  /**
   * @task `toggleRoomLocationCheckInEnabledTask`
   */
  toggleRoomLocationCheckInEnabledTask = task({ drop: true }, async () => {
    const { company, location } = this.args;
    try {
      const roomCheckInEnabledSetting = !location.roomCheckInEnabled;
      this.roombaMetrics.trackEvent(`room_check_in_${roomCheckInEnabledSetting ? 'enabled' : 'disabled'}`, {
        company_id: company.id,
        location_id: location.id,
      });

      await this.roombaGraphql.updateRoomsLocationCheckInEnabledOverride(location.id, roomCheckInEnabledSetting);
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error saving location settings');
    } finally {
      this.closeDialog();
    }
  });

  /**
   * @task `toggleRoomCompanyCheckInEnabledTask`
   */
  toggleRoomCompanyCheckInEnabledTask = task({ drop: true }, async () => {
    const { company, location } = this.args;
    try {
      const roomCheckInEnabledSetting = !location.roomCheckInEnabled;
      this.roombaMetrics.trackEvent(`room_check_in_${roomCheckInEnabledSetting ? 'enabled' : 'disabled'}`, {
        company_id: company.id,
      });

      await this.roombaGraphql.updateCompanyConfig({
        roomCheckInEnabled: roomCheckInEnabledSetting,
      });
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error saving company settings');
    } finally {
      this.closeDialog();
    }
  });

  get shouldShowCheckInSettingsAndExceptions(): boolean {
    return this.args.company.roomCheckInEnabled || this.args.location.roomCheckInEnabled;
  }

  get checkInTimeOptions(): { name: string; value: number }[] {
    return Array.from({ length: 20 }, (_, index) => formatCheckInWindowOption((index + 1) * 60));
  }

  @action
  async selectCheckInTimeOption(option: CheckInWindowOption): Promise<void> {
    this.selectedCheckInWindow = option;
    await this.updateLocationCheckInWindow.perform();
  }

  updateLocationCheckInWindow = task({ drop: true }, async () => {
    this.roombaMetrics.trackEvent('location_check_in_window_updated', {
      checkInWindow: this.selectedCheckInWindow.name,
    });
    await this.roombaGraphql.updateLocationRoomsCheckInWindow(
      this.args.location.id,
      this.selectedCheckInWindow.value,
      this.selectedCheckInWindow.value
    );
  });

  @action
  openSettingsAndExceptionsDialog(): void {
    this.isSettingsAndExceptionsDialogOpen = true;
  }

  @action
  closeSettingsAndExceptionsDialog(): void {
    this.isSettingsAndExceptionsDialogOpen = false;
  }

  get excludedRooms(): {
    id: string;
    name: string;
    locationId: string | null;
  }[] {
    if (!this.args.excludedRooms?.checkInExcludedRooms) {
      return [];
    }
    return this.args.excludedRooms.checkInExcludedRooms.filter((room) => room.locationId === this.args.location.id);
  }

  saveRoomsTask = task({ restartable: true }, async (selectedRooms: GetRoomsQuery['rooms']) => {
    const existingCompanyExcludedRooms =
      this.args.excludedRooms?.checkInExcludedRooms
        .filter((room) => room.locationId !== this.args.location.id)
        .map((room): string => room.id) || [];
    const currentLocationExcludedRooms = selectedRooms.map((room) => room.id);
    const roomIdsToExclude = new Set([...existingCompanyExcludedRooms, ...currentLocationExcludedRooms]);
    this.roombaMetrics.trackEvent('room_check_in_excluded_rooms_updated', {
      selectedRooms: selectedRooms.map((room) => room.name),
    });
    await this.roombaGraphql.putSpaceSaverCheckInExcludedRooms(this.args.company.id, [...roomIdsToExclude]);
  });
}
