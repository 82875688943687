import { assert, warn } from '@ember/debug';
import { get } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { camelize } from '@ember/string';
import { isPresent } from '@ember/utils';
import { dropTask } from 'ember-concurrency';
import { all, hash } from 'rsvp';

const standardFeatures = [
  'ndaEnabled',
  'visitorBadgePrinting',
  'spark', //
  'chatter', //
  'employees',
];

const premiumFeatures = [
  'visitorPhotos',
  'smsNotification',
  'googleSync',
  'okta',
  'oneLogin',
  'activeDirectory',
  'salesforce',
  'box',
  'dropbox',
  'slideshow',
  'saml',
  'assistants',
  'plusOne',
  'mailChimp',
  'customCSS',
  'customBadges',
  'dashboardSignIn',
  'unlimitedLocations',
  'analytics',
];

// The maximum number of employees a company can have before they're considered to use the "unlimited_employees" feature
const UNLIMITED_EMPLOYEE_THRESHOLD = 50;

function hasCustomNotifications(location) {
  const notificationTypes = ['delegatedMessage', 'hostMessage', 'message'];
  for (const notificationType of notificationTypes) {
    for (const customNotification of location.customNotifications) {
      if (isPresent(customNotification[notificationType])) {
        return true;
      }
    }
  }
  return false;
}

async function hasSlideshow(locations) {
  if (locations.any((location) => !get(location, 'config.id'))) return false;

  const configs = await all(locations.map((location) => get(location, 'config')));
  await all(configs.map((config) => config.slideshows));

  return configs.compact().any((config) => get(config, 'slideshows.length') > 0);
}

function isEnabledForAnyLocation(locations, key) {
  return locations.any((location) => location[key]);
}

async function isEnabledFromFlow(flows, key) {
  const objects = await all(flows.mapBy(key));
  return objects.compact().any((obj) => obj.enabled);
}

async function isEnabledFromIntegration(company, key) {
  const integration = await get(company, key);
  if (!integration) {
    return false;
  }
  // if it's possible for the integration to be enabled, we want to take that into account
  if (get(integration.constructor, 'attributes').has('enabled')) {
    return get(integration, 'enabled');
  } else {
    return true;
  }
}

/**
  This service tells us which features are currently used by the user.
  It's used by the downdgrade controller to show the user what features
  they will be losing if they downgrade.
*/
export default class FeatureUsageService extends Service {
  @service state;
  @service store;

  #features = {
    // We can't check for these so we assume people are using them
    analytics: true,
    unlimitedLocations: true,
    dashboardSignIn: true,
  };

  #featuresCalculated = false;

  @dropTask
  *calculate() {
    const { currentCompany: company } = this.state;

    const companyMeta = yield this.state._getCompanyMeta.perform(company);
    this.#features['unlimitedEmployees'] = companyMeta['employees-count'] > UNLIMITED_EMPLOYEE_THRESHOLD;

    const locations = yield this.store.findAll('location', {
      include:
        'config,flows,flows.agreement-page,flows.sign-in-field-page,flows.summary-page,flows.badge,flows.photo-page',
    });

    const flows = locations.toArray().flatMap((location) => location.flows.toArray());
    const enabledFlows = flows.filterBy('enabled');

    // consider the company to use multiple_visitor_types if they have more than one enabled flow
    this.#features['multipleVisitorTypes'] = enabledFlows.length > 1;

    this.#features['visitorBadgePrinting'] = yield isEnabledFromFlow(flows, 'badge');
    this.#features['visitorPhotos'] = yield isEnabledFromFlow(flows, 'photoPage');
    this.#features['ndaEnabled'] = yield isEnabledFromFlow(flows, 'agreementPage');

    this.#features['customEmailTemplates'] = yield this.hasCustomEmailTemplates();
    this.#features['customNotifications'] = locations.any((location) => hasCustomNotifications(location));
    this.#features['slideshows'] = yield hasSlideshow(locations);

    this.#features['customizedBranding'] = this.#features['customEmailTemplates'] || this.#features['slideshows'];

    this.#features['slack'] = yield isEnabledFromIntegration(company, 'slackIntegration');
    this.#features['googleApp'] = yield isEnabledFromIntegration(company, 'googleApp');
    this.#features['okta'] = yield isEnabledFromIntegration(company, 'oktaIntegration');
    this.#features['oneLogin'] = yield isEnabledFromIntegration(company, 'oneLogin');
    this.#features['salesforce'] = yield isEnabledFromIntegration(company, 'salesforceIntegration');

    this.#features['capacityLimits'] = yield isEnabledForAnyLocation(locations, 'capacityLimitEnabled');
    this.#features['smsNotification'] = yield isEnabledForAnyLocation(locations, 'smsEnabled');

    this.#features['saml'] = company.samlConnected;

    const badges = yield all(flows.mapBy('badge'));
    this.#features['customCSS'] = badges.find((badge) => badge && get(badge, 'defaultCss') !== get(badge, 'css'));

    const { plugins: _, pluginInstalls } = yield hash({
      plugins: this.store.findAll('plugin'), // load this here so pluginInstall.belongsTo('plugin').value() resolves
      pluginInstalls: this.store.findAll('plugin-install'),
    });

    pluginInstalls.forEach((pluginInstall) => {
      if (pluginInstall.status === 'uninstalled') {
        return;
      }
      const key = pluginInstall.belongsTo('plugin').value().get('key'); // eslint-disable-line ember/use-ember-get-and-set
      if (key === 'salesforce-chatter') {
        this.features['chatter'] = true;
      } else if (key === 'ad') {
        this.#features['activeDirectory'] = true;
      } else if (key === 'mailchimp') {
        this.#features['mailChimp'] = true;
      } else if (key === 'dropbox') {
        this.features['dropbox'] = true;
      } else if (key === 'cisco-spark') {
        this.#features['spark'] = true;
      }
    });

    this.#featuresCalculated = true;
  }

  async hasCustomEmailTemplates() {
    // get all the MailerTemplateVersion records (scoped to the company)
    const mailerTemplateVersions = await this.store.findAll('mailer-template-version');

    // only look at non-default ones
    const customMailerTemplateVersions = mailerTemplateVersions.filter((mtv) => mtv.isDefault === false);

    // if any of the non-default MailerTemplateVersions are associated with a flow, conside the feature in-use
    return customMailerTemplateVersions.any((mtv) => mtv.flows.length > 0);
  }

  calculateFeatures(allFeatures) {
    return allFeatures.filter((featureName) => {
      return this.#features[featureName];
    });
  }

  get premiumFeatures() {
    return this.calculateFeatures(premiumFeatures);
  }

  get standardFeatures() {
    return this.calculateFeatures(standardFeatures);
  }

  get notUsedStandardFeatures() {
    const usedFeatures = this.standardFeatures;
    return standardFeatures.reject((f) => usedFeatures.includes(f));
  }

  get notUsedPremiumFeatures() {
    const usedFeatures = this.premiumFeatures;
    return premiumFeatures.reject((f) => usedFeatures.includes(f));
  }

  // Returns a promise which resolves to a tuple of { usedFeatures, notUsedFeatures }
  async features(plan) {
    await this.calculate.perform();

    let usedFeatures, notUsedFeatures;
    // going down to standard, so could only be using premiumFeatures
    if (plan === 'standard-1') {
      usedFeatures = this.premiumFeatures;
      notUsedFeatures = this.notUsedPremiumFeatures;
      // going down to basic, so could be losing either premium or premium + standard
    } else {
      // if going to basic we are definitely losing standardFeatures
      usedFeatures = this.standardFeatures;
      notUsedFeatures = this.notUsedStandardFeatures;
      // but if we are coming down from premium we are also losing premium features
      if (this.state.vrSubscription.isPremiumPlan) {
        usedFeatures.push(...this.premiumFeatures);
        notUsedFeatures.push(...this.notUsedPremiumFeatures);
      }
    }
    return { usedFeatures, notUsedFeatures };
  }

  isUsed(featureName) {
    assert('must call calculate() before checking for usage', this.#featuresCalculated);

    featureName = camelize(featureName);
    warn(
      `FeatureUsageService: Feature "${featureName}" is not a known or checked feature`,
      Object.prototype.hasOwnProperty.call(this.#features, featureName),
      { id: 'garaje.feature-usage-service.unknown-feature' }
    );

    return this.#features[featureName];
  }
}
