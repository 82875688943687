import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { Permission } from 'garaje/utils/ui-permissions';

export default class VirtualFrontDeskAbility extends Ability {
  @service declare authz: AuthzService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;
  @service declare currentAdmin: CurrentAdminService;

  get canUpdateSettings(): boolean {
    // Cannot access Virtual Front Desk if it's not enabled for the Visitors subscription
    if (
      !this.state.visitorsSubscription?.canAccessVirtualFrontDesk &&
      !this.state.vfdSubscription?.canAccessVirtualFrontDesk
    )
      return false;

    // Otherwise, access depends on having the "visitors.vfd-configuration.update" permission
    return this.authz.hasPermissionAtCurrentCompany(Permission.VISITORS_VFD_CONFIGURATION_UPDATE);
  }

  get canVisit(): boolean {
    // Cannot access Virtual Front Desk if feature flag is disabled
    if (this.featureFlags.isEnabled('growth-vfd-ga')) {
      return this.authz.hasPermissionAtCurrentCompany(Permission.VISITORS_VFD_CONFIGURATION_READ);
    } else {
      return false;
    }
  }

  get canViewLocationMenuNav(): boolean {
    return (
      (this.currentAdmin.isGlobalAdmin || this.currentAdmin.isLocationAdmin) &&
      this.featureFlags.isEnabled('growth-vfd-ga')
    );
  }

  get canViewCallLog(): boolean {
    return this.canVisit;
  }
}
