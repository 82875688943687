import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { get } from '@ember/object';
import urlBuilder from 'garaje/utils/url-builder';
import { inject as service } from '@ember/service';
import { not } from 'macro-decorators';

class RecurringInvite extends Model {
  @service() ajax;

  @belongsTo('user') creator;
  @belongsTo('employee') employee;
  @belongsTo('flow') flow;
  @belongsTo('location') location;
  @belongsTo('recurringInvite') parentRecurringInvite;
  @hasMany('visitor-document', { async: false }) visitorDocuments;
  @hasMany('location', { inverse: null }) childLocations;

  @attr('string') email;
  @attr('string') fullName;
  @attr('number', { defaultValue: 0 }) additionalGuests;
  @attr('string') privateNotes;
  @attr('string') propertyNotes;
  @attr('locality', { defaultValue: () => ({ placeId: '' }) }) locality;
  /**
   * @type {string}
   */
  @attr('string') recurringRule;
  @attr('boolean', { defaultValue: false }) skipGuestNotification;
  @attr('date') startTime;
  @attr('array', { defaultValue: () => [] }) locationNames;
  @attr('userData', { defaultValue: () => [] }) userData;

  // SQ-4574: Invert boolean in model to set same value in component
  @not('skipGuestNotification') sendGuestEmail;

  notifyInvite(data) {
    return this.ajax.request(urlBuilder.v3.recurringInvites.sendNotification(this.id), {
      contentType: 'application/json',
      data,
      type: 'POST',
    });
  }

  visitorDocumentForTemplate(userDocumentTemplate) {
    return this.visitorDocumentForIdentifier(get(userDocumentTemplate, 'identifier'));
  }

  visitorDocumentForIdentifier(identifier) {
    return this.visitorDocuments.findBy('identifier', identifier);
  }
}

export default RecurringInvite;
