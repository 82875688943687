import type NativeArray from '@ember/array/-private/native-array';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type { DetailedChangeset } from 'ember-changeset/types';
import { dropTask } from 'ember-concurrency';
import type UserDocumentModel from 'garaje/models/user-document';
import type UserDocumentAttachmentModel from 'garaje/models/user-document-attachment';
import type UserDocumentTemplateAttachmentModel from 'garaje/models/user-document-template-attachment';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

import type { ProfileDocumentUploadRouteModel } from '../route';

export default class ProfileDocumentUploadReviewController extends Controller {
  declare model: ProfileDocumentUploadRouteModel;

  @service declare router: RouterService;
  @service declare flashMessages: FlashMessagesService;
  @service declare state: StateService;
  @service declare store: StoreService;

  @tracked attachments = [];

  get sortedUserDocumentTemplateAttachments(): NativeArray<UserDocumentTemplateAttachmentModel> | undefined {
    return this.model.userDocumentTemplate?.userDocumentTemplateAttachments.sortBy('position');
  }

  saveTask = dropTask(async () => {
    try {
      const { userDocument } = this.model;

      await this.saveNewTask.perform(userDocument!);

      this.flashMessages.showAndHideFlash('success', 'Your documents have been successfully submitted', {
        showAboveModalOverlay: true,
      });

      void this.router.transitionTo('profile.document.upload.confirm', this.model.userDocumentTemplate!.identifier);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Error saving userDocument "${this.model.userDocumentTemplate?.identifier}"`, e);
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showFlash('error', errorText, { showAboveModalOverlay: true });
    }
  });

  saveNewTask = dropTask(async (userDocument: DetailedChangeset<UserDocumentModel>) => {
    await userDocument.save();

    // create link to company
    const link = this.store.createRecord('user-document-link');
    link.userDocument = userDocument._content;
    link.userDocumentLinkable = this.state.currentCompany;

    await link.save();

    // remove newly created attachments from store as they were loaded in via save for new user documents
    void this.store.peekAll('user-document-attachment').filterBy('isNew').invoke('destroyRecord');
  });

  @action
  getAttachment(templateAttachmentId: string): UserDocumentAttachmentModel | undefined {
    return this.model.userDocument?.getAttachment(templateAttachmentId);
  }

  @action
  previous(): void {
    void this.router.transitionTo(
      'profile.document.upload.attachment',
      this.model.userDocumentTemplate!.identifier,
      this.sortedUserDocumentTemplateAttachments!.lastObject!.identifier
    );
  }
}
