import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { reads } from 'macro-decorators';
import { restartableTask, task, timeout } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';
import { allSettled } from 'rsvp';
import zft from 'garaje/utils/zero-for-tests';

export default class VisitorsLocationGatingModalBillingAdminDeactivateLocations extends Component {
  @service flashMessages;
  @service impressions;
  @service state;
  @service store;

  @tracked filteredLocations = [];
  @tracked selectedLocations = [];
  @tracked nonSelectedLocations = [];
  @tracked showInfo = true;
  @tracked showWarning = false;
  @tracked showSuccess = false;
  @tracked isDisabled = false;
  @tracked locationName = '';

  numberOfLocationsToDeactivate = 0;

  @reads('args.activeLocations') activeLocations;

  sortField = 'nameWithCompanyName';
  sortDirection = 'asc';

  constructor() {
    super(...arguments);
    this.filteredLocations = this.activeLocations;

    this.numberOfLocationsToDeactivate =
      this.args.locationsToDeactivateCount ?? this.activeLocationsCount - this.args.subscription.quantity;
  }

  get locationsForDeactivationCount() {
    return this.numberOfLocationsToDeactivate < this.selectedLocations.length
      ? 0
      : this.numberOfLocationsToDeactivate - this.selectedLocations.length;
  }

  get activeLocationsCount() {
    return this.activeLocations.length;
  }

  get sortedActiveLocations() {
    return this.activeLocations.sortBy(this.sortField);
  }

  @restartableTask
  *filterByName(locationName) {
    yield timeout(zft(250));
    locationName = locationName.toLowerCase();

    const searchableArray = [...this.activeLocations];
    if (locationName.length > 0) {
      this.filteredLocations = searchableArray.filter((location) =>
        location.nameWithCompanyName.toLowerCase().includes(locationName)
      );
    } else {
      this.filteredLocations = searchableArray;
    }
  }

  @action
  selectedDidChange(selected) {
    this.isDisabled = false;
    this.nonSelectedLocations = [];

    this.showInfo = true;
    this.showWarning = false;

    if (selected.length === this.numberOfLocationsToDeactivate) {
      this.showInfo = false;
      this.showSuccess = true;
      this.setNonSelectedLocations();
    }

    this.selectedLocations = selected;
  }

  @action
  setNonSelectedLocations() {
    this.nonSelectedLocations = this.activeLocations.filter((loc) => !this.selectedLocations.includes(loc));
  }

  logDeactivateClickedTask = task({ drop: true }, async () => {
    try {
      await this.impressions.postImpression.perform(
        IMPRESSION_NAMES.LOCATION_GATING_MODAL_DEACTIVATE_LOCATIONS_CLICKED[this.args.plan.toUpperCase()]
      );
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error', parseErrorForDisplay(e));
    }
  });

  bulkDeactivateLocations = task({ drop: true }, async () => {
    await this.logDeactivateClickedTask.perform();

    if (this.selectedLocations.length < this.numberOfLocationsToDeactivate) {
      this.isDisabled = true;

      this.showInfo = false;
      this.showWarning = true;
      this.showSuccess = false;
      return;
    }

    this.selectedLocations.forEach((location) => set(location, 'disabled', true));
    await allSettled(this.selectedLocations.invoke('save'));
    this.selectedLocations.filterBy('isError').invoke('rollbackAttributes');

    if (this.args.subscription.cancelled) await this.downgradeToBasicTask.perform();

    if (this.locationsForDeactivationCount === 0) {
      this.args.close();
      this.selectedLocations.clear();
      this.filteredLocations.clear();
    }

    this.flashMessages.showAndHideFlash('success', 'Locations deactivated');
  });

  downgradeToBasicTask = task({ drop: true }, async () => {
    const company = this.state.currentCompany;
    company.planIntent = 'basic';

    try {
      await this.store.findAll('plan');
      this.args.subscription.downgradePlan = await this.store.peekRecord('plan', 'basic');
      await this.args.subscription.save();

      await company.save();
      await this.args.subscription.reload();
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });
}
