// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import clamp from 'clamp';
import Ember from 'ember';
import { computed } from '@ember/object';
import { set } from '@ember/object';

const { escapeExpression } = Ember.Handlebars.Utils;

/**
 * @param {Number}                    lines the maximum # of lines the text should be displayed on
 * @param {Number}                    lineHeight the line-height of the lines of text
 * @param {String}                    text the content being "clamped"
 * @param {String}                    truncationChar string to append to truncated text (default: '…')
 * @param {Function}                  handleTruncate a callback function executed after truncation
 * @param {Boolean}                   useNative
 */
// eslint-disable-next-line ember/no-classic-classes
export default Component.extend({
  clampedText: null,
  truncationChar: '…',
  useNative: false,

  attributeBindings: Object.freeze(['style']),

  style: computed('lineHeight', function () {
    return `line-height: ${this.lineHeight};`;
  }),

  init() {
    this._super(...arguments);

    if (typeof this.handleTruncate !== 'function') {
      this.handleTruncate = () => {};
    }
  },

  // eslint-disable-next-line ember/no-component-lifecycle-hooks
  didRender() {
    this._super(...arguments);

    const { element, text, lines, useNative, truncationChar } = this;

    element.innerHTML = escapeExpression(text);

    const { clamped: clampedText } = clamp(element, {
      clamp: lines,
      truncationChar,
      useNativeClamp: !!useNative,
    });

    // Check for both clamped value and indication of native line-clamp
    if (clampedText || element.scrollHeight > element.clientHeight) {
      set(this, 'clampedText', clampedText);
      this.handleTruncate(true);
    } else {
      this.handleTruncate(false);
    }
  },
});
