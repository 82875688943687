import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { subMinutes, startOfMonth, addMonths, subMonths, isToday, isYesterday, isTomorrow } from 'date-fns';
import { format } from 'date-fns-tz';

/**
 * @param {Date}                selectedDate
 * @param {Function}            onDateSelect
 */
export default class MapDateSelect extends Component {
  @service state;
  @service store;

  @tracked showCalendar = false;
  @tracked showingDate = new Date();

  @action
  goBackOneMonth() {
    this.showingDate = subMonths(this.showingDate, 1);
  }

  @action
  goToNextMonth() {
    this.showingDate = addMonths(this.showingDate, 1);
  }

  @action
  onDateSelect(date) {
    const { onDateSelect } = this.args;
    this.showCalendar = false;
    onDateSelect(date);
  }

  get formatedDate() {
    const { selectedDate } = this.args;
    const displayDate = subMinutes(selectedDate, this.state.minutesBetweenTimezones);

    if (isToday(displayDate)) {
      return 'Today';
    }
    if (isYesterday(displayDate)) {
      return 'Yesterday';
    }
    if (isTomorrow(displayDate)) {
      return 'Tomorrow';
    }
    return format(displayDate, 'MM/dd/yyyy');
  }

  get currentMonth() {
    return this.state.getOfficeLocationTime(startOfMonth(this.showingDate));
  }
}
