import { action } from '@ember/object';
import Component from '@glimmer/component';

export interface MessageLogEntry {
  id: string;
  sentAt: string;
  sender: string;
  messageTitle: string;
  messageBody: string;
  location?: string;
  category?: string;
  employeeCount: number;
  visitorCount: number;
  markedAsSafeCount: number;
}

interface MessageLogListArgs {
  messageLogHeaders: string[];
  messageLogEntries: MessageLogEntry[];
}

export default class MessageLogList extends Component<MessageLogListArgs> {
  @action calculateTotalCount(num1: number, num2: number): number {
    return num1 + num2;
  }
}
