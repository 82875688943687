import Controller from '@ember/controller';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class LocationOverviewGlobalDevicesController extends Controller {
  queryParams = ['showList'];
  @tracked showList = true;

  @service declare router: RouterService;

  get showNav() {
    return !this.router.currentRouteName.includes('virtual-front-desk');
  }
}
