import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class LocationOverviewSignInFlowsRoute extends Route {
  @service router;
  @service globalOverviewAccess;

  beforeModel() {
    if (!this.globalOverviewAccess.canVisitFlows) {
      this.router.transitionTo('unauthorized');
    }
  }
}
