import { A } from '@ember/array';
import type MutableArray from '@ember/array/mutable';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type Link from 'ember-link/link';
import type LinkManagerService from 'ember-link/services/link-manager';
import { RoomsOrderBy, SortDirection } from 'garaje/graphql/generated/roomba-types';
import type {
  GetRoomsPaginatedQuery,
  PaginationInput,
  Room,
  RoomsPageFilterInput,
  RoomsSortOrder,
} from 'garaje/graphql/generated/roomba-types';
import { MODELS_PER_PAGE } from 'garaje/pods/components/roomba/record-select/table/component';
import type FeatureConfigService from 'garaje/services/feature-config';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import type RoombaMetricsService from 'garaje/services/roomba-metrics';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';

import { ROOMS_UNASSIGNED_AT_CURRENT_LOCATION_PER_PAGE } from './route';

export type TableField = {
  displayName: string;
  name: string;
};

export default class RoombaSettingsCalendarsController extends Controller {
  @service declare flashMessages: FlashMessagesService;
  @service declare roombaGraphql: RoombaGraphqlService;
  @service declare linkManager: LinkManagerService;
  @service declare roombaMetrics: RoombaMetricsService;
  @service declare state: StateService;
  @service declare featureConfig: FeatureConfigService;

  @tracked rooms: GetRoomsPaginatedQuery['paginatedRooms']['items'] = [];
  @tracked totalRoomsCount: GetRoomsPaginatedQuery['paginatedRooms']['totalCount'] = 0;

  @tracked buildingsFilter: string[] = [];
  @tracked floorFilter: string[] = [];
  @tracked isBillingNoticeDialogOpen = false;
  @tracked showUnpairRoomsDialog = false;

  @tracked unassignedRoomsCurrentSortDirection = 'asc';
  @tracked unassignedRoomsCurrentSortField = 'displayName';
  @tracked unassignedRoomsPageNumber = 1;
  @tracked unassignedRoomsTotalCount = 0;
  @tracked assignedRoomsTotalCount = 0;
  @tracked assignedRoomsPageNumber = 1;

  @tracked nextPageOffset = 0;

  unassignedRoomFields: TableField[] = [
    { displayName: 'Display name', name: 'displayName' },
    { displayName: 'Location', name: 'location.name' },
    { displayName: 'Building', name: 'building.name' },
    { displayName: 'Floor', name: 'floor' },
  ];

  assignedRoomFields: TableField[] = [
    { displayName: 'Display name', name: 'displayName' },
    { displayName: 'Building', name: 'building.name' },
    { displayName: 'Floor', name: 'floor' },
  ];

  @tracked selectedUnassignedRooms = A<Room>();
  @tracked selectedAssignedRooms = A<Room>();

  get locationName(): string | undefined {
    return this.state.currentLocation?.nameWithCompanyName;
  }

  get unlimitedRoomsEnabled(): boolean {
    return this.featureConfig.isEnabled('rooms.unlimitedRooms');
  }

  get roomsUnassignedAtCurrentLocation(): GetRoomsPaginatedQuery['paginatedRooms']['items'] {
    return this.rooms.filter(
      (room) =>
        this.state.currentLocation?.id !== room.location?.id &&
        (this.buildingsFilter.length === 0 ||
          (room.building?.name && this.buildingsFilter.includes(room.building.name))) &&
        (this.floorFilter.length === 0 || (room.floor && this.floorFilter.includes(room.floor)))
    );
  }

  get roomsAssignedAtCurrentLocation(): GetRoomsPaginatedQuery['paginatedRooms']['items'] {
    return this.rooms.filter(
      (room) =>
        this.state.currentLocation?.id === room.location?.id &&
        (this.buildingsFilter.length == 0 ||
          (room.building?.name && this.buildingsFilter.includes(room.building.name))) &&
        (this.floorFilter.length === 0 || (room.floor && this.floorFilter.includes(room.floor)))
    );
  }

  get assignedAtCurrentLocationRoomsCount(): number {
    return this.roomsAssignedAtCurrentLocation.length;
  }

  get buildings(): string[] {
    return uniq(
      compact(
        this.rooms
          .filter((room) => {
            return (
              (this.buildingsFilter.length === 0 ||
                (room.building?.name && !this.buildingsFilter.includes(room.building.name))) &&
              (this.floorFilter.length === 0 || (room.floor && this.floorFilter.includes(room.floor)))
            );
          })
          .map((room) => room.building?.name)
      )
    ).sort();
  }

  get floors(): string[] {
    return uniq(
      compact(
        this.rooms
          .filter((room) => {
            return (
              (this.buildingsFilter.length === 0 ||
                (room.building?.name && this.buildingsFilter.includes(room.building.name))) &&
              (this.floorFilter.length === 0 || (room.floor && !this.floorFilter.includes(room.floor)))
            );
          })
          .map((room) => room.floor)
      )
    ).sort();
  }

  get backLink(): Link {
    return this.linkManager.createUILink({ route: 'roomba.settings' });
  }

  get roomsAssignedToAnyLocation(): GetRoomsPaginatedQuery['paginatedRooms']['items'] {
    return this.rooms.filter((room) => !!room.location?.id);
  }

  get roomsSubscriptionQuantity(): number {
    return this.featureConfig.getLimit('rooms.unlimitedRooms') || 0;
  }

  get showBillingBanner(): boolean {
    return (
      this.roomsSubscriptionQuantity !== undefined &&
      this.roomsAssignedToAnyLocation.length >= this.roomsSubscriptionQuantity
    );
  }

  saveRoomSelectionTask = task(
    { drop: true },
    async (selectedRooms: MutableArray<Room>, locationId: string | null = null) => {
      try {
        await this.roombaGraphql.linkRoomsWithLocation(
          selectedRooms.map((room) => room.id),
          locationId
        );

        this.rooms = [];

        await this.updateRoomsListWithAssigned();
        this.nextPageOffset = 0;
        await this.updateRoomsListWithPageOfUnassigned();

        selectedRooms.clear();
      } catch (e) {
        this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
      }
    }
  );

  @action
  assignLocation(): void {
    if (!this.roomsSubscriptionQuantity || !this.state.currentLocation) {
      return;
    }
    this.roombaMetrics.trackEvent('calendar_settings_assign_rooms_button_clicked', {
      numberOfRoomsToAssign: this.selectedUnassignedRooms.length,
      locationId: this.state.currentLocation.id,
    });

    const newTotalAssignedRooms = new Set(
      [...this.roomsAssignedToAnyLocation, ...this.selectedUnassignedRooms].map((room) => room.id)
    ).size;

    if (newTotalAssignedRooms > this.roomsSubscriptionQuantity && !this.unlimitedRoomsEnabled) {
      this.openBillingNoticeDialog();
      return;
    }

    void this.saveRoomSelectionTask.perform(this.selectedUnassignedRooms, this.state.currentLocation.id);
  }

  @action
  unassignLocation(): void {
    this.closeUnpairRoomsDialog();
    this.roombaMetrics.trackEvent('calendar_settings_unassign_rooms_button_clicked', {
      numberOfRoomsToUnassign: this.selectedAssignedRooms.length,
      locationId: this.state.currentLocation?.id,
    });
    void this.saveRoomSelectionTask.perform(this.selectedAssignedRooms);
  }

  onBuildingSelectTask = task({ restartable: true }, async (buildings: string[]) => {
    this.buildingsFilter = buildings;
    this.unassignedRoomsPageNumber = 1;
    this.assignedRoomsPageNumber = 1;
    this.nextPageOffset = 0;
    await this.updateRoomsListWithAssigned();
    await this.updateRoomsListWithPageOfUnassigned();
  });

  onFloorSelectTask = task({ restartable: true }, async (floors: string[]) => {
    this.floorFilter = floors;
    this.unassignedRoomsPageNumber = 1;
    this.assignedRoomsPageNumber = 1;
    await this.updateRoomsListWithAssigned();
    await this.updateRoomsListWithPageOfUnassigned();
  });

  @action
  monitorClick(): void {
    this.roombaMetrics.trackEvent('calendar_settings_continue_button_clicked');
  }

  @action
  openBillingNoticeDialog(): void {
    this.roombaMetrics.trackEvent('calendar_settings_billing_notice_dialog_shown', {
      locationId: this.state.currentLocation?.id,
    });
    this.isBillingNoticeDialogOpen = true;
  }

  @action
  closeBillingNoticeDialog(): void {
    this.isBillingNoticeDialogOpen = false;
  }

  @action
  tryUnassignLocation(): void {
    if (this.countPairedRoomsToUnassign > 0) {
      this.showUnpairRoomsDialog = true;
      return;
    }
    this.unassignLocation();
  }

  @action
  openUnpairRoomsDialog(): void {
    this.showUnpairRoomsDialog = true;
  }

  @action
  closeUnpairRoomsDialog(): void {
    this.showUnpairRoomsDialog = false;
  }

  get countPairedRoomsToUnassign(): number {
    return this.selectedAssignedRooms.filter((room) => !!room.devices.length).length;
  }

  get pairedRoomsText(): string {
    if (!this.countPairedRoomsToUnassign || !this.selectedAssignedRooms.length) {
      return '';
    }

    let pairedRoomsText = '';
    if (this.countPairedRoomsToUnassign > 1) {
      // multiple selected multiple paired
      pairedRoomsText += `${this.countPairedRoomsToUnassign} meeting rooms from your selection are paired to a device. Unassigning these rooms from this location will unpair the devices and make the rooms unavailable for booking from the tablet or Envoy mobile app.
      You may reassign the rooms to this location at any time if you have sufficient room licenses available.`;
    } else {
      if (this.selectedAssignedRooms.length > 1) {
        // multiple selected, 1 paired
        pairedRoomsText += `1 meeting room from your selection is paired to a device. `;
      } else {
        // 1 selected and paired
        pairedRoomsText += `This meeting room is paired to a device. `;
      }
      pairedRoomsText += `Unassigning this room from this location will unpair the device and make the room unavailable for booking from the tablet or Envoy mobile app.
      You may reassign the room to this location at any time if you have sufficient room licenses available.`;
    }

    return pairedRoomsText;
  }

  @action
  async unassignedRoomsPageChanged(unassignedRoomsPageNumber: number): Promise<void> {
    this.unassignedRoomsPageNumber = unassignedRoomsPageNumber;

    if (MODELS_PER_PAGE * unassignedRoomsPageNumber <= this.roomsUnassignedAtCurrentLocation.length) {
      return;
    }

    this.nextPageOffset += ROOMS_UNASSIGNED_AT_CURRENT_LOCATION_PER_PAGE;
    await this.updateRoomsListWithPageOfUnassigned();
  }

  @action
  async unassignedRoomsSortChanged(sortField: string, sortDirection: 'asc' | 'desc'): Promise<void> {
    if (this.rooms.length === this.totalRoomsCount) {
      return;
    }

    this.rooms = this.roomsAssignedAtCurrentLocation;
    this.unassignedRoomsCurrentSortField = sortField;
    this.unassignedRoomsCurrentSortDirection = sortDirection;
    this.nextPageOffset = 0;

    await this.updateRoomsListWithPageOfUnassigned();
  }

  @action
  setAssignedRoomsPageChanged(pageNumber: number): void {
    this.assignedRoomsPageNumber = pageNumber;
  }

  async updateRoomsListWithAssigned(): Promise<void> {
    const sort = this.buildRoomsSortOrder();
    const filter: RoomsPageFilterInput = {
      locationId: { _eq: this.state.currentLocation?.id || null, _neq: null, _in: null, _is_null: null, _nin: null },
      displayName: null,
      buildingId: null,
      floorName: { _in: this.floorFilter, _eq: null, _is_null: null, _neq: null, _nin: null },
      buildingName: { _in: this.buildingsFilter, _eq: null, _is_null: null, _neq: null, _nin: null },
    };

    const { items: roomsAssignedToCurrentLocation, totalCount: assignedRoomsTotalCount } =
      await this.roombaGraphql.getRoomsPaginated(filter, sort);
    this.rooms = uniqBy([...this.rooms, ...roomsAssignedToCurrentLocation], 'id');
    this.assignedRoomsTotalCount = assignedRoomsTotalCount;
  }

  async updateRoomsListWithPageOfUnassigned(): Promise<void> {
    const sort = this.buildRoomsSortOrder();
    const filter: RoomsPageFilterInput = {
      locationId: { _neq: this.state.currentLocation?.id || null, _eq: null, _in: null, _is_null: null, _nin: null },
      displayName: null,
      buildingId: null,
      floorName: { _in: this.floorFilter, _eq: null, _is_null: null, _neq: null, _nin: null },
      buildingName: { _in: this.buildingsFilter, _eq: null, _is_null: null, _neq: null, _nin: null },
    };

    const pagination: PaginationInput = {
      limit: ROOMS_UNASSIGNED_AT_CURRENT_LOCATION_PER_PAGE,
      offset: this.nextPageOffset,
    };

    const { items: unassignedRoomsNextPage, totalCount: unassignedRoomsTotalCount } =
      await this.roombaGraphql.getRoomsPaginated(filter, sort, pagination);
    this.rooms = uniqBy([...this.rooms, ...unassignedRoomsNextPage], 'id');
    this.unassignedRoomsTotalCount = unassignedRoomsTotalCount;
  }

  private buildRoomsSortOrder(): RoomsSortOrder {
    let sortBy: RoomsOrderBy;
    switch (this.unassignedRoomsCurrentSortField) {
      case 'location.name':
        sortBy = RoomsOrderBy.LocationName;
        break;
      case 'building.name':
        sortBy = RoomsOrderBy.BuildingName;
        break;
      case 'floor':
        sortBy = RoomsOrderBy.FloorName;
        break;
      case 'displayName':
      default:
        sortBy = RoomsOrderBy.DisplayName;
        break;
    }

    const sortDirection = this.unassignedRoomsCurrentSortDirection === 'asc' ? SortDirection.Asc : SortDirection.Desc;
    return { by: sortBy, direction: sortDirection };
  }
}
