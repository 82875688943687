/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller from '@ember/controller';
import { action, get } from '@ember/object';
import { filter } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import {
  getUnixTime,
  addSeconds,
  startOfDay,
  subMinutes,
  isSameDay,
  format,
  isAfter,
  isToday,
  fromUnixTime,
} from 'date-fns';
import { task } from 'ember-concurrency';
import type DeskModel from 'garaje/models/desk';
import type EmployeeModel from 'garaje/models/employee';
import type FloorModel from 'garaje/models/floor';
import type MapFeatureModel from 'garaje/models/map-feature';
import type MapFloorModel from 'garaje/models/map-floor';
import type NeighborhoodModel from 'garaje/models/neighborhood';
import type ReservationModel from 'garaje/models/reservation';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureConfigService from 'garaje/services/feature-config';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { customPluralize } from 'garaje/utils/custom-pluralize';
import { SECONDS_IN_A_DAY } from 'garaje/utils/enums';
import { getPartialTimes } from 'garaje/utils/hour-options';
import type { Maybe } from 'graphql/jsutils/Maybe';
import type { Map } from 'leaflet';
import { all } from 'rsvp';
import { cached } from 'tracked-toolbox';

import { type ModelResponse } from './route';

interface additionalDetailsResponse {
  reservations: ReservationModel[];
  assignedDesks: DeskModel[];
  selectedResourceFilter: string;
}

export default class MapsLiveShowController extends Controller {
  @service declare state: StateService;
  @service declare store: Store;
  @service declare featureFlags: FeatureFlagsService;
  @service declare featureConfig: FeatureConfigService;
  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;
  @service declare currentAdmin: CurrentAdminService;

  declare model: ModelResponse;

  DEFAULT_RESOURCE_FILTER = 'all-resources';
  DESKS_FILTER = 'desks';

  @tracked mapFeatures: MapFeatureModel[] = [];
  @tracked selectedFeature: Maybe<MapFeatureModel>;
  @tracked neighborhoods: NeighborhoodModel[] = [];
  @tracked selectedNeighborhoods: NeighborhoodModel[] = [];
  @tracked selectedTime: Maybe<number>;
  @tracked selectedEmployee: Maybe<EmployeeModel>;
  @tracked selectedResourceFilter = this.DEFAULT_RESOURCE_FILTER;
  @tracked employees: EmployeeModel[] = [];
  @tracked reservations: ReservationModel[] = [];
  @tracked availableDesks: DeskModel[] = [];
  @tracked leafletMap: Maybe<Map> = null;

  queryParams = ['selectedTime', 'selectedResourceFilter'];

  @filter('mapFeatures', ['reservations'], function (this: MapsLiveShowController, value): boolean {
    const feature = value as MapFeatureModel;

    if (feature.type !== 'desk') {
      return feature.enabled;
    }

    const desksIsEnabled = this.featureConfig.isEnabled('desks');

    if (!desksIsEnabled) {
      return false;
    }

    const featureHasReservation = this.reservationsOnCurrentFloor.some((res) => {
      return res.belongsTo('desk').id() == feature.externalId;
    });

    const desk = this.model.desksInLocation.find((desk) => feature.externalId == desk.id);
    const featureHasAssignedDesk = desk?.assignedTo && !featureHasReservation;

    if (!this.featureConfig.isEnabled('deskReservations.view') && featureHasReservation && !featureHasAssignedDesk) {
      return false;
    }

    return featureHasAssignedDesk || featureHasReservation || desk?.enabled || false;
  })
  displayableMapFeatures!: MapFeatureModel[];

  get placedResourceTypes(): string[] {
    let featureTypeList: string[] = [];

    this.displayableMapFeatures.forEach((feature) => {
      if (!featureTypeList.includes(customPluralize(feature.type))) {
        featureTypeList.push(customPluralize(feature.type));
      }
    });

    featureTypeList.sort();

    featureTypeList = featureTypeList.filter((type) => type !== this.DESKS_FILTER);

    if (this.featureConfig.isEnabled('deskReservations.booking') && this.featureConfig.isEnabled('desks')) {
      // Sort desks to the top of the list
      featureTypeList.unshift(this.DESKS_FILTER);
    }

    featureTypeList.unshift(this.DEFAULT_RESOURCE_FILTER);

    return featureTypeList;
  }

  get hasEmployee(): boolean {
    return !!this.currentAdmin.employee?.id;
  }

  @action
  trackAdminWithoutEmployee(): void {
    this.metrics.trackEvent('Admin without Employee attempted to access edit map', { user_id: this.currentAdmin.id });
  }

  get mapFeaturesToDisplay(): MapFeatureModel[] {
    if (this.selectedResourceFilter === this.DEFAULT_RESOURCE_FILTER) {
      return this.displayableMapFeatures.filter((feature) => {
        if (feature.type !== 'desk') {
          return true;
        }
        return this.shouldDisplayDesk(feature) && this.featureConfig.isEnabled('desks');
      });
    }

    if (this.selectedResourceFilter === this.DESKS_FILTER) {
      if (this.isAfterBookingHours) return [];
      return this.displayableMapFeatures.filter((feature) => {
        if (feature.type !== 'desk') {
          return false;
        }
        return this.shouldDisplayDesk(feature);
      });
    }

    return this.displayableMapFeatures.filter(
      (feature) => customPluralize(feature.type) === this.selectedResourceFilter
    );
  }

  shouldDisplayDesk(feature: MapFeatureModel): boolean {
    const featureHasReservation = this.reservationsOnCurrentFloor.some((res) => {
      return res.belongsTo('desk').id() == feature.externalId;
    });

    const desk = this.model.desksInLocation.find((desk) => feature.externalId == desk.id);
    const featureHasAssignedDesk = desk?.assignedTo && !featureHasReservation;

    if (!desk) {
      return false;
    }

    const featureHasAvailableDesk =
      this.availableDesks.includes(desk) &&
      (this.selectedResourceFilter === this.DESKS_FILTER ||
        this.selectedResourceFilter === this.DEFAULT_RESOURCE_FILTER);

    const neighborhoodName = desk?.neighborhoodName || '';

    const featureIsInNeighborhood =
      isEmpty(this.selectedNeighborhoods) || this.selectedNeighborhoods.map((n) => n.name).includes(neighborhoodName);

    return featureIsInNeighborhood && (featureHasAssignedDesk || featureHasReservation || featureHasAvailableDesk);
  }

  get selectedDate(): Date {
    if (this.selectedTime) {
      return this.state.getOfficeLocationTime(
        startOfDay(subMinutes(fromUnixTime(this.selectedTime), this.state.minutesBetweenTimezones))
      );
    }

    return this.state.getOfficeLocationTime(startOfDay(new Date()));
  }

  get selectedForToday(): boolean {
    return isToday(subMinutes(this.selectedDate, this.state.minutesBetweenTimezones));
  }

  get isAfterBookingHours(): boolean {
    if (!this.selectedForToday) {
      return false;
    }

    const today = format(subMinutes(this.selectedDate, this.state.minutesBetweenTimezones), 'EEEE');
    const currentWorkplaceDay = this.model.workplaceDays.find((day) => day.dayOfWeek === today);

    if (!currentWorkplaceDay) {
      return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, partialEnd] = getPartialTimes(startOfDay(new Date()), {
      startTime: currentWorkplaceDay.startTime,
      endTime: currentWorkplaceDay.endTime,
    });

    if (!partialEnd) {
      return false;
    }

    return isAfter(new Date(), this.state.getOfficeLocationTime(partialEnd));
  }

  get assignedDesks(): DeskModel[] {
    const reservedDeskIds = this.reservationsOnCurrentFloor.map((res) => {
      return res.belongsTo('desk').id();
    });

    return this.model.desksInLocation.filter((desk) => desk.assignedTo && !reservedDeskIds.includes(desk.id));
  }

  get additionalDetails(): additionalDetailsResponse {
    return {
      reservations: this.reservationsOnCurrentFloor,
      assignedDesks: this.assignedDesks,
      selectedResourceFilter: this.selectedResourceFilter,
    };
  }

  get sortedMapFloors(): MapFloorModel[] {
    const mapFloors = this.model?.mapFloors;

    return mapFloors.sortBy('ordinality').filterBy('rasterImageUrl');
  }

  get searchedEmployees(): EmployeeModel[] {
    const employees = this.store
      .peekAll('employee')
      .toArray()
      .filter((employee) => !employee.hideFromEmployeeSchedule);

    employees.sort((empA, empB) => {
      if (empA && empB) {
        return empA.name.localeCompare(empB.name);
      } else {
        return 0;
      }
    });

    return employees;
  }

  @cached
  get reservationsOnCurrentFloor(): ReservationModel[] {
    const timeToCompare = isSameDay(new Date(), subMinutes(this.selectedDate, this.state.minutesBetweenTimezones))
      ? new Date()
      : this.selectedDate;

    const desksWithReservations = this.model.desksInLocation.filter(
      (desk) => this.getAllFutureReservationsOnDesk(desk.id).length
    );

    const resOnFloor: ReservationModel[] = [];

    desksWithReservations.forEach((desk) => {
      const allReservationsOnDesk = this.getAllFutureReservationsOnDesk(desk.id);
      let upcomingReservation;
      let timeUntilUpcomingReservation = Infinity;

      const currentRes = allReservationsOnDesk.find((reservation) => {
        const isCurrentReservation =
          reservation.startTime <= getUnixTime(timeToCompare) && reservation.endTime > getUnixTime(timeToCompare);

        if (isCurrentReservation) {
          return reservation;
        }

        const timeUntilReservation = reservation.startTime - getUnixTime(timeToCompare);

        if (timeUntilReservation > 0 && timeUntilReservation < timeUntilUpcomingReservation) {
          upcomingReservation = reservation;
          timeUntilUpcomingReservation = timeUntilReservation;
        }

        return false;
      });

      if (currentRes) {
        resOnFloor.push(currentRes);
      } else if (upcomingReservation) {
        resOnFloor.push(upcomingReservation);
      }
    });

    return resOnFloor;
  }

  get currentUserReservation(): ReservationModel | undefined {
    return this.reservations.find((reservation) => reservation.userEmail === this.state.currentUser?.email);
  }

  getAllFutureReservationsOnDesk(deskId: string): ReservationModel[] {
    return this.reservations.filter((res) => {
      return res.belongsTo('desk').id() === deskId && res.endTime > getUnixTime(this.selectedDate);
    });
  }

  @action
  onAvailablePopupCancel(map: Map): void {
    this.selectedFeature = null;
    map.closePopup();
  }

  @action
  onResourceFilterSelect(filter: string): void {
    this.selectedResourceFilter = filter;
    this.selectedEmployee = null;

    if (!this.mapFeaturesToDisplay.length) {
      this.flashMessages.showAndHideFlash('warning', 'No desks available to book');
    }

    this.metrics.trackEvent('Element tapped', {
      element_identifier: 'map_filter',
      filter_type: filter,
      screen: 'map',
    });
  }

  @action
  async onEmployeeSelect(employee: EmployeeModel): Promise<void> {
    const employeeDesk = this.getEmployeeDesk(employee);

    this.selectedEmployee = employee;
    this.selectedResourceFilter = this.DEFAULT_RESOURCE_FILTER;
    this.selectedFeature = this.displayableMapFeatures.find(
      (feature) => feature.type === 'desk' && feature.externalId == employeeDesk?.id
    );

    const floorId = employeeDesk?.belongsTo('floor')?.id();

    if (!floorId) {
      return;
    }

    const floor = await this.store.findRecord('floor', floorId);

    if (floor.id !== this.model?.mapFloor?.id) {
      this.onMapFloorChange(floor);
    }
  }

  @action
  getEmployeeAttrById(userId: string, attr: string): string {
    const employee = this.employees.find((employee) => employee.belongsTo('user').id() == userId);
    return get(employee, attr) as string;
  }

  @action
  getEmployeeByUserEmail(userEmail: string): EmployeeModel | undefined {
    return this.employees.find((employee) => employee.email === userEmail);
  }

  @action
  getEmployeeByUserId(userId: string): EmployeeModel | undefined {
    return this.employees.find((employee) => employee.belongsTo('user').id() == userId);
  }

  getEmployeeDesk(employee: EmployeeModel): DeskModel | null {
    const assignedDesk = this.model.desksInLocation.find((desk) => desk.assignedTo === employee.email);

    if (assignedDesk) {
      return assignedDesk;
    }

    const reservationForEmployee = this.reservationsOnCurrentFloor.find(
      (res) => res.belongsTo('user').id() === employee.belongsTo('user').id()
    );
    if (reservationForEmployee) {
      return this.store.peekRecord('desk', reservationForEmployee.belongsTo('desk').id());
    }

    return null;
  }

  @action
  onDateSelect(date: string): void {
    this.selectedTime = getUnixTime(new Date(date));

    void this.loadResoucesDataTask.perform();
  }

  @action
  onMapFloorChange(mapFloor: FloorModel): void {
    this.selectedFeature = null;

    this.transitionToRoute('spaces.maps.live.show', mapFloor.id, {
      queryParams: { selectedResourceFilter: this.selectedResourceFilter, selectedTime: this.selectedTime },
    });
  }

  @action
  setSelectedDesk(): void {
    if (this.selectedEmployee) {
      const userDesk = this.getEmployeeDesk(this.selectedEmployee) as DeskModel;

      this.selectedFeature = this.displayableMapFeatures.find(
        (feature) => feature.type === 'desk' && feature.externalId == userDesk.id
      );
    }
  }

  @action
  setEmployees(employee: EmployeeModel): void {
    const isEmployeeFetched = this.employees.find((fetchedEmployee) => fetchedEmployee.id === employee.id);

    if (!isEmployeeFetched) {
      this.employees.push(employee);
    }
  }

  loadAvailableDesksTask = task({ restartable: true }, async () => {
    const employeeById = this.getEmployeeByUserId(this.state.currentUser?.id || '');

    const currentEmployee =
      employeeById ??
      (() => {
        // (ar) this call relies on the DD integration, which needs to be debugged for the log statement to show up
        this.metrics.logMonitorError({
          event: 'COULD_NOT_FETCH_EMPLOYEE_BY_USER_ID',
          debugExtras: { user: this.state.currentUser },
        });
        return this.getEmployeeByUserEmail(this.state.currentUser?.email || '');
      })();

    if (currentEmployee) {
      const params = {
        filter: {
          'location-id': this.state.currentLocation?.id,
          'available-for': currentEmployee.email,
          'accessible-for': currentEmployee.email,
          'available-from': getUnixTime(this.selectedDate),
          'available-to': getUnixTime(addSeconds(this.selectedDate, SECONDS_IN_A_DAY - 1)),
        },
        include: 'amenities',
      };
      try {
        this.availableDesks = (await this.store.query('desk', params)).toArray();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error({ e });
        this.metrics.logMonitorError({
          event: 'MAPS_LOAD_AVAILABLE_DESK_QUERY_ERROR',
          debugExtras: { user: this.state.currentUser, employee: currentEmployee },
          error: e,
        });
        this.flashMessages.showAndHideFlash('warning', 'Unable to show available desks');
      }
    } else {
      this.metrics.logMonitorError({
        event: 'MAPS_LOAD_AVAILABLE_DESKS_NO_EMPLOYEE',
        debugExtras: { user: this.state.currentUser },
      });
      this.flashMessages.showAndHideFlash('warning', 'Unable to show available desks');
    }
  });

  loadEmployeesTask = task({ restartable: true }, async () => {
    const employeesPromises = [];
    let employeeResponses = [];
    const emailLimit = 50;
    const resUserEmails = this.reservationsOnCurrentFloor.map((res) => res.userEmail);
    const assignedToEmails = this.model.desksInLocation
      .filter((desk) => desk.assignedTo && !resUserEmails.includes(desk.assignedTo))
      .map((desk) => desk.assignedTo);
    const userEmails = [...resUserEmails, ...assignedToEmails, this.state.currentUser?.email];
    let offset = 0;
    let page = 0;
    while (offset < userEmails.length) {
      const userEmailsPaginated = userEmails.slice(offset, offset + emailLimit);
      const fetchedEmployeesPromise = this.store.query('employee', {
        filter: {
          locations: this.state.currentLocation?.id,
          'email-in': userEmailsPaginated.join(','),
          deleted: false,
        },
        include: 'user',
        page: { limit: 100, offset: 0 },
      });
      page++;
      offset = page * emailLimit;
      employeesPromises.push(fetchedEmployeesPromise);
    }
    employeeResponses = await all(employeesPromises);
    const employees: EmployeeModel[] = [];
    employeeResponses.forEach((response) => response.forEach((employee) => employees.push(employee)));
    this.employees = employees;
  });

  loadNeighborhoodsTask = task({ restartable: true }, async () => {
    try {
      this.neighborhoods = await this.store
        .query('neighborhood', { filter: { 'location-id': this.state.currentLocation?.id } })
        .then((neighborhoods) => neighborhoods.toArray());
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({ e });
    }
  });

  loadReservationsTask = task({ restartable: true }, async () => {
    try {
      const filter = {
        'location-id': this.state.currentLocation?.id,
        'start-date': getUnixTime(this.selectedDate),
        'end-date': getUnixTime(addSeconds(this.selectedDate, SECONDS_IN_A_DAY - 1)),
      };
      const reservationParams = {
        filter,
        page: { limit: 999, offset: 0 },
      };
      const reservationResponse = await this.store.query('reservation', reservationParams);
      this.reservations = reservationResponse.toArray();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({ e });
    }
  });

  loadResoucesDataTask = task({ drop: true }, async () => {
    await this.loadReservationsTask.perform();
    await this.loadEmployeesTask.perform();
    await all([this.loadAvailableDesksTask.perform(), this.loadNeighborhoodsTask.perform()]);
  });
}
