import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, hasMany } from '@ember-data/model';
import type MailerSectionModel from 'garaje/models/mailer-section';
import type MailerSectionBlockModel from 'garaje/models/mailer-section-block';
import type MailerTemplateSectionModel from 'garaje/models/mailer-template-section';
import type MailerTemplateVersionModel from 'garaje/models/mailer-template-version';

export default class MailerTemplateModel extends Model {
  // Relationships
  @hasMany('mailer-template-section') declare mailerTemplateSections: AsyncHasMany<MailerTemplateSectionModel> | null;
  @hasMany('mailer-template-version') declare mailerTemplateVersions: AsyncHasMany<MailerTemplateVersionModel>;

  // Attributes
  @attr('string') declare description: string;
  @attr('string') declare subject: string;
  @attr('string') declare subtitle: string;
  @attr('string') declare title: string;
  @attr('boolean') declare active: boolean;
  @attr('string') declare identifier: string;

  get mailerSectionBlocks(): MailerSectionBlockModel[] | null {
    if (!this.mailerSections) return null;
    return this.mailerSections.reduce<MailerSectionBlockModel[]>((acc, mailerSection) => {
      return [...acc, ...(<MailerSectionModel>(<unknown>mailerSection)).mailerSectionBlocks.toArray()];
    }, []);
  }

  get mailerSections(): Array<AsyncBelongsTo<MailerSectionModel>> | null {
    if (!this.mailerTemplateSections) return null;
    return this.mailerTemplateSections.mapBy('mailerSection');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'mailer-template': MailerTemplateModel;
  }
}
