import Helper from '@ember/component/helper';

// eslint-disable-next-line ember/no-classic-classes
export default Helper.extend({
  compute(params) {
    const isNew = params[0];
    const updatedAttributes = params[1];
    const attributes = params.slice(2).reduce((flat, toFlatten) => flat.concat(toFlatten), []);
    return isNew && attributes.some((attr) => updatedAttributes.includes(attr));
  },
});
