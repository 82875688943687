import Model, { attr } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';
import { type SingleResponse } from 'jsonapi/response';

class AbstractConfig extends Model {
  @attr('string') declare welcomeImage: string | null;
  @attr('string', { defaultValue: '#FFFFFF' }) declare welcomeBackgroundColor: string;
  @attr('boolean') declare welcomeBackgroundColorEnabled: boolean;

  declare deleteWelcomeImage: (options?: unknown) => Promise<SingleResponse<AbstractConfig>>;
  declare refreshConfig: (options?: unknown) => Promise<unknown>;
}

AbstractConfig.prototype.deleteWelcomeImage = memberAction({
  type: 'DELETE',
  path: 'attachments/welcome-image',
});

AbstractConfig.prototype.refreshConfig = memberAction({
  type: 'POST',
  path: 'refresh',
});

export default AbstractConfig;
