import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { equal, reads } from 'macro-decorators';

export default class DeliveriesLogTutorialController extends Controller {
  @service state;

  @reads('state.deliveriesSubscription') deliveriesSubscription;
  @reads('deliveriesSubscription.isBasicPlan') isBasicPlan;
  @equal('deliveriesSubscription.plan', 'standard') isPremiumPlan;
}
