import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { SendMessage } from '@envoy/components-communication';
import Component from '@glimmer/component';
import type AbilitiesService from 'ember-can/services/abilities';
import type CommunicationDataService from 'garaje/services/communication-data-service';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type ReactAnalytics from 'garaje/services/react-analytics';
import type ReactRendererService from 'garaje/services/react-renderer';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import type { Root } from 'react-dom/client';

interface MessageLogNewArgs {
  locationId?: string;
  redirectRoute: string;
}

export default class CommunicationsMessageLogNewComponent extends Component<MessageLogNewArgs> {
  @service declare reactRenderer: ReactRendererService;
  @service declare reactAnalytics: ReactAnalytics;
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessagesService;
  @service declare communicationDataService: CommunicationDataService;
  @service declare abilities: AbilitiesService;
  @service declare workplaceMetrics: WorkplaceMetricsService;

  private root?: Root;

  @action
  injectComponent(): void {
    this.root = this.reactRenderer.render(SendMessage, 'react__communications__send-message', {
      dataAccess: this.communicationDataService,
      analytics: this.reactAnalytics,
      locationId: this.args.locationId,
      showEmployeeGroup: this.abilities.can('access workplace premium plus emergency features communications'),
      showVisitorGroup: this.abilities.can('access visitors emergency features communications'),
      onSent: () => this.onSent(),
      onError: (errorMessage, error) => this.onError(errorMessage, error),
      createTemplateUrl: '/communications/templates',
    });
  }

  @action
  destroyComponent(): void {
    if (this.root) {
      this.reactRenderer.unmount(this.root);
      this.root = undefined;
    }
  }

  @action
  onSent(): void {
    void this.router.transitionTo(this.args.redirectRoute);
    this.flashMessages.showAndHideFlash('success', 'Sent successfully');
  }

  @action
  onError(errorMessage: string, error: unknown): void {
    this.flashMessages.showAndHideFlash('error', errorMessage);

    this.workplaceMetrics.logMonitorError({
      event: 'COMMUNICATIONS_SEND_MESSAGE_COMPONENT_ERROR',
      debugExtras: { errorMessage },
      error,
    });
  }
}
